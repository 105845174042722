import { Layout } from "antd";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import {
  FaCalendarCheck,
  FaDonate,
  FaMedal,
  FaPhotoVideo,
  FaPodcast,
  FaTasks,
} from "react-icons/fa";
import { GiTatteredBanner } from "react-icons/gi";
import { HiLightBulb } from "react-icons/hi";

import * as Sentry from "@sentry/react";
import i18next from "i18next";
import { BiPoll } from "react-icons/bi";
import { BsFillFolderSymlinkFill, BsImages } from "react-icons/bs";
import { GrGamepad } from "react-icons/gr";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { SiPinboard } from "react-icons/si";
import { VscTag, VscTools } from "react-icons/vsc";
import { Outlet, useNavigate } from "react-router";
import styled from "styled-components";
import {
  Article,
  Dietary,
  FAQ,
  Influencer,
  Milestones,
  MonthlyInfo,
  Offers,
  Popular,
  Recommended,
} from "../assets/icons/icons";
import { ErrorFallback } from "../components";
import { BJContainer } from "../components/theme/atoms/BJContainer";
import SiderNavigationBar from "../components/theme/components/SiderNavBar";
import {
  addCompetition,
  appTakeOvers,
  applinks,
  articleCategories,
  articleVerifiers,
  articles,
  articlesSub,
  banners,
  blogPost,
  checkList,
  checkListCustomOffers,
  content,
  contentCollections,
  contentMenu,
  dailyDoula,
  dailyDoulaChild,
  dailyDoulaOperationCenter,
  dailyDoulaPregnancy,
  dietary,
  dietaryCategoriesSub,
  dietaryContentSub,
  dietarySub,
  faq,
  faqCategories,
  faqSub,
  highlight,
  images,
  influencers,
  levels,
  loyalty,
  milestones,
  milestonesChild,
  milestonesPregnancy,
  miniJourneyRoute,
  missions,
  monthlyInformation,
  offers,
  podcasts,
  polls,
  popular,
  popularChild,
  popularPregnancy,
  recommended,
  recommendedMonths,
  recommendedWeeks,
  sponsors,
  toolsMenu,
  toolsMenuBanners,
  toolsMenuSections,
  weekly,
} from "../routes/routeConsts";
import { ReactComponent as Logo } from "./../assets/icons/blog-vlog.svg";
const { Content } = Layout;

export const menuItems = () => {
  const title = i18next.t("blogpost:title");

  const menuItemList: MenuItemInfo[] = [
    {
      displayName: "Articles",
      url: articles,
      icon: <Article />,
      subMenu: [
        {
          key: "articlesSub",
          value: articlesSub,
          displayName: "Articles",
          regionSupport: true,
        },
        {
          key: "articlecategories",
          value: articleCategories,
          displayName: "Categories",
          regionSupport: true,
        },
        {
          key: "verifiers",
          value: articleVerifiers,
          displayName: "Verifiers",
          regionSupport: true,
        },
      ],
      subMenuKey: articlesSub,
      regionSupport: true,
    },
    {
      displayName: "Content",
      url: contentCollections,
      icon: <HiOutlineDocumentDuplicate />,
      subMenu: [
        {
          key: contentCollections,
          value: contentCollections,
          displayName: "Content Collections",
          regionSupport: true,
        },
        {
          key: "contentMenu",
          value: contentMenu,
          displayName: "Content menu",
          regionSupport: true,
        },
      ],
      subMenuKey: contentCollections,
      regionSupport: true,
    },

    {
      displayName: `${title}`,
      url: blogPost,
      icon: <Logo width={16} height={16} />,
      regionSupport: true,
    },
    // {
    // due to this issue we had to hide the blog categories https://favro.com/organization/b331165aa9b44b344533b1ab/5829d990133a2dc5f7ee3d88?card=Bab-13549
    //   displayName: "Blog categories",
    //   url: blogCategories,
    //   icon: <BlogCategories />,
    //   regionSupport: true,
    // },
    {
      displayName: "Influencers",
      url: influencers,
      icon: <Influencer />,
      regionSupport: true,
    },
    {
      displayName: "Checklists",
      url: checkList,
      icon: <FaTasks />,
      subMenu: [
        {
          key: checkList,
          value: checkList,
          displayName: "CheckLists",
          regionSupport: true,
        },
        {
          key: checkListCustomOffers,
          value: checkListCustomOffers,
          displayName: "Custom Checklist Offers",
          regionSupport: true,
        },
      ],
      subMenuKey: checkList,
      regionSupport: true,
    },
    // { displayName: "Child", url: `child`, icon: <Child /> },//commented temporarly  as no implementation yet
    {
      displayName: "Offers",
      url: offers,
      icon: <Offers />,
      regionSupport: true,
    },
    {
      displayName: "Dietary Advices",
      url: dietary,
      icon: <Dietary />,
      regionSupport: true,
      subMenu: [
        { key: "dietarySub", value: dietarySub, displayName: "Dietary" },
        {
          key: "dietarycategories",
          value: dietaryCategoriesSub,
          displayName: "Categories",
          regionSupport: true,
        },
        {
          key: "dietarycontent",
          value: dietaryContentSub,
          displayName: "Content",
          regionSupport: true,
        },
      ],
      subMenuKey: dietarySub,
    },
    {
      displayName: "Milestones",
      url: milestones,
      icon: <Milestones />,
      regionSupport: true,
      subMenu: [
        {
          key: "milestonesPregnancySub",
          value: milestonesPregnancy,
          displayName: "Pregnancy",
          regionSupport: true,
        },
        {
          key: "milestonesChildSub",
          value: milestonesChild,
          displayName: "Child",
          regionSupport: true,
        },
      ],
      subMenuKey: milestones,
    },
    {
      displayName: "Popular",
      url: popular,
      icon: <Popular />,
      subMenu: [
        {
          key: "popularPregnancy",
          value: popularPregnancy,
          displayName: "Pregnancy",
          regionSupport: true,
        },
        {
          key: "popularChild",
          value: popularChild,
          displayName: "Child",
          regionSupport: true,
        },
      ],
      subMenuKey: popular,
    },
    {
      displayName: "Recommended content",
      url: recommended,
      icon: <Recommended />,
      subMenu: [
        {
          key: "months",
          value: recommendedMonths,
          displayName: "Months",
          regionSupport: true,
        },
        {
          key: "weeks",
          value: recommendedWeeks,
          displayName: "Weeks",
          regionSupport: true,
        },
      ],
      subMenuKey: recommended,
    },
    {
      displayName: "FAQ",
      url: faq,
      icon: <FAQ />,
      subMenu: [
        {
          key: "faq",
          value: faq,
          displayName: "Faq",
          regionSupport: true,
        },
        {
          key: "faqcategories",
          value: faqCategories,
          displayName: "Categories",
          regionSupport: true,
        },
      ],
      subMenuKey: faqSub,
      regionSupport: true,
    },
    {
      displayName: "Monthly info",
      url: monthlyInformation,
      icon: <MonthlyInfo />,
      regionSupport: true,
    },

    {
      displayName: "Weekly info",
      url: weekly,
      icon: <MonthlyInfo />,
      regionSupport: true,
    },
    {
      displayName: "Podcasts",
      url: podcasts,
      icon: <FaPodcast />,
      regionSupport: true,
    },
    { displayName: "Info", url: content, icon: <FaPhotoVideo /> },
    {
      displayName: "Highlights",
      url: highlight,
      icon: <SiPinboard />,
      regionSupport: true,
    },
    {
      displayName: "Daily Doula",
      url: dailyDoula,
      icon: <HiLightBulb />,
      subMenu: [
        {
          key: "pregnancyDailyDoulaSub",
          value: dailyDoulaPregnancy,
          displayName: "Pregnancy",
          regionSupport: true,
        },
        {
          key: "childDailyDoulaSub",
          value: dailyDoulaChild,
          displayName: "Child",
          regionSupport: true,
        },
      ],
      subMenuKey: dailyDoula,
    },
    { displayName: "Tags", url: `tags`, icon: <VscTag /> },
    {
      displayName: "Sponsors",
      url: sponsors,
      icon: <FaDonate />,
      regionSupport: true,
    },

    {
      displayName: "Banners",
      url: banners,
      icon: <GiTatteredBanner />,
      regionSupport: true,
    },
    {
      displayName: "Mini journey",
      url: miniJourneyRoute,
      icon: <GiTatteredBanner />,
      regionSupport: true,
    },
    {
      displayName: "App Links",
      url: applinks,
      icon: <BsFillFolderSymlinkFill />,
      regionSupport: true,
    },
    {
      displayName: "App takeover",
      url: appTakeOvers,
      icon: <AiOutlineFundProjectionScreen />,
      regionSupport: true,
    },
    {
      displayName: "Competitions",
      url: addCompetition,
      icon: <GrGamepad />,
      regionSupport: true,
    },
    {
      displayName: "Image upload",
      url: images,
      icon: <BsImages />,
    },
    {
      displayName: "Polls",
      url: polls,
      icon: <BiPoll />,
      regionSupport: true,
    },
    {
      displayName: "Tools",
      url: toolsMenu,
      icon: <VscTools />,
      subMenu: [
        {
          key: "toolsMenuBanners",
          value: toolsMenuBanners,
          displayName: "Banners",
          regionSupport: true,
        },
        {
          key: "toolsMenuSections",
          value: toolsMenuSections,
          displayName: "Menu sections",
          regionSupport: true,
        },
      ],
      subMenuKey: toolsMenu,
    },
    {
      displayName: "Loyalty",
      url: loyalty,
      icon: <FaMedal />,
      subMenu: [
        {
          key: "loyaltyMissions",
          displayName: "Missions",
          value: missions,
          regionSupport: true,
        },
        {
          key: "loyaltyLevels",
          displayName: "Levels",
          value: levels,
          regionSupport: true,
        },
      ],
      subMenuKey: loyalty,
    },
  ];
  return menuItemList;
};

export const CMSLayout = () => {
  const navigate = useNavigate();
  const items: MenuItemInfo[] = menuItems();

  return (
    <Layout>
      <StyledLayout>
        <SiderNavigationBar items={items} />
        <StyledLayoutContent>
          <Content>
            <BJContainer>
              <Sentry.ErrorBoundary
                fallback={ErrorFallback}
                onReset={() => {
                  navigate("./");
                }}
              >
                <Outlet />
              </Sentry.ErrorBoundary>
            </BJContainer>
          </Content>
        </StyledLayoutContent>
      </StyledLayout>
      {/* <StyledFooter>Baby Journey ©2021 Created by Baby Journey</StyledFooter>  */}
    </Layout>
  );
};

const StyledLayout = styled(Layout)`
  background-color: ${props => props.theme.white};
`;

const StyledLayoutContent = styled(Layout)`
  padding: 1rem 1rem 1rem;
`;

// const StyledFooter = styled(Footer)`
//   text-align: center;
//   background-color: ${props => props.theme.primary};
//   padding: 1rem;
//   /* position: sticky; */
//   bottom: 0;
//   z-index: 999;
// `;
