import { Col, Row, Form, Typography } from "antd";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { commonErrors } from "../../language";
import { FormModal } from "../../components";
import {
  AspectRatio,
  formValidationError,
  getFormattedErrorMessage,
} from "../../utils";
import BJInput from "../../components/theme/atoms/BJInput";
import { DropAndCrop } from "../../components/DropAndCrop";
import { useHighlights } from "../../context";
interface Props {
  showFlowModal: boolean;
  onHideFlowModal: () => void;
  flow?: Flow;
  onUpdateFlow: (flow: Flow) => void;
  onDeleteFlow: (flow: Flow) => void;
}

type FormValues = {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  blurhash?: string;
};

const schema = yup.object().shape({
  title: yup.string().required(`Title: ${commonErrors.requiredError}`),
  description: yup
    .string()
    .required(`Description: ${commonErrors.requiredError}`),
  imageUrl: yup.string().required(`Image URL: ${commonErrors.requiredError}`),
});

export const HighlightFlowModal = ({
  showFlowModal,
  onHideFlowModal,
  flow,
  onUpdateFlow,
  onDeleteFlow,
}: Props) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });
  const { uploadHighlightFlowImage } = useHighlights();

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    reset({
      title: flow?.title ?? "",
      imageUrl: flow?.image ?? "",
      blurhash: flow?.blurhash ?? "",
      description: flow?.description ?? "",
    });
  }, [flow, reset, showFlowModal]);

  const onSubmit = async (data: FormValues) => {
    try {
      const { title, description, imageUrl, blurhash } = data;
      flow = {
        ...flow,
        title: title,
        description: description,
        image: imageUrl,
        blurhash,
      };
      onUpdateFlow(flow);
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
    }
  };

  const onDelete = async () => {
    if (flow !== null) {
      onDeleteFlow(flow);
    } else {
      throw new Error("Category not found");
    }
  };

  const isDirty = !!Object.keys(formState.dirtyFields).length;
  const title = watch("title");

  const handleUploadImageUrl = (url: string | null) => {
    setValue("imageUrl", url, { shouldDirty: true });
  };

  const handleUploadBlurhash = (url: string | null) => {
    setValue("blurhash", url, { shouldDirty: true });
  };

  return (
    <FormModal
      messageOnSubmit={false}
      formId={"flowModal"}
      key={"flowModal"}
      error={error}
      onHide={onHideFlowModal}
      enableSave={isDirty}
      show={showFlowModal}
      onDelete={onDelete}
      enableDelete={!!flow}
      modalTitle={flow ? `ID - ${flow?.title}` : "Add flow item"}
      recordIdentifier={title}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      errors={errors as any}
    >
      <Row gutter={10}>
        <Col span={24}>
          <Form.Item
            label="Title"
            required
            validateStatus={errors.title && "error"}
            help={
              <Typography.Paragraph type="danger">
                {errors.title?.message}
              </Typography.Paragraph>
            }
          >
            <Controller
              control={control}
              name="title"
              render={({ field }) => <BJInput autoFocus {...field} />}
            />
          </Form.Item>
          <Form.Item
            required
            label="Description"
            validateStatus={errors.description && "error"}
            help={
              <Typography.Paragraph type="danger">
                {errors.description?.message}
              </Typography.Paragraph>
            }
          >
            <Controller
              control={control}
              name="description"
              render={({ field }) => <BJInput {...field} />}
            />
          </Form.Item>
          <Form.Item
            required
            label="Image URL"
            validateStatus={errors.imageUrl && "error"}
            help={
              <Typography.Paragraph type="danger">
                {errors.imageUrl?.message}
              </Typography.Paragraph>
            }
          >
            <DropAndCrop
              title="Flow item image"
              initialUrl={flow?.image}
              setUploadUrl={handleUploadImageUrl}
              uploadImage={uploadHighlightFlowImage}
              lockedRatio={AspectRatio.Free}
              defaultCropBoxWidth={100}
              defaultCropBoxHeight={100}
              setBlurhash={handleUploadBlurhash}
            />
          </Form.Item>
        </Col>
      </Row>
    </FormModal>
  );
};
