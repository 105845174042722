import { useCallback, useEffect, useState } from "react";
import {
  useBirthStories,
  useBlogPosts,
  useChildBlogPosts,
  useInfluencers,
} from "../context";
import { useGeneralBlogPosts } from "../context/GeneralBlogPostsContext";
import { BlogTypes } from "../utils";

export const usePostList = () => {
  const { influencerMap } = useInfluencers();
  const { blogPosts, loading: loadingBlogPosts } = useBlogPosts();
  const { blogPosts: childBlogPosts, loading: loadingChildBlogPosts } =
    useChildBlogPosts();
  const { birthStories, loading: loadingBirthStories } = useBirthStories();
  const { generalBlogPosts, loading: loadingGeneralBlogPosts } =
    useGeneralBlogPosts();

  const [posts, setPosts] = useState<Post[]>([]);

  const mapBlogPost = useCallback(
    (blogPost: BlogPost): Post => {
      const influencer = influencerMap.get(blogPost.influencerId);
      const post: Post = {
        id: blogPost.id,
        influencerId: blogPost.influencerId,
        influencer: influencer || null,
        title: blogPost.title,
        blurhash: blogPost?.blurhash,
        description: blogPost.description,
        week: blogPost.week,
        month: null,
        url: blogPost.url,
        imageUrl: blogPost.imageUrl,
        tagWords: blogPost.tagWords ?? [],
        tags: blogPost.tags ?? [],
        type: BlogTypes.BlogPost,
        typeName: "Blog post (Pregnancy)",
        featured: blogPost.featured ?? 0,
        promoted: blogPost.promoted,
        categories: null,
        translations: blogPost.translations ?? {},
        isVlog: blogPost.isVlog,
      };
      return post;
    },
    [influencerMap]
  );

  const mapChildBlogPost = useCallback(
    (blogPost: ChildBlogPost): Post => {
      const influencer = influencerMap.get(blogPost.influencerId);

      const post: Post = {
        id: blogPost.id,
        influencerId: blogPost.influencerId,
        influencer: influencer || null,
        week: null,
        month: blogPost.month,
        url: blogPost.url,
        imageUrl: blogPost.imageUrl,
        blurhash: blogPost?.blurhash,
        type: BlogTypes.ChildBlogPost,
        typeName: "Blog post (Child)",
        featured: blogPost.featured ?? 0,
        promoted: blogPost.promoted,
        categories: null,
        translations: blogPost.translations ?? {},
        isVlog: blogPost.isVlog,
      };
      return post;
    },
    [influencerMap]
  );

  const mapBirthStory = useCallback(
    (birthStory: BirthStory): Post => {
      const influencer = influencerMap.get(birthStory.influencerId);

      return {
        id: birthStory.id,
        influencerId: birthStory.influencerId,
        influencer: influencer || null,
        title: birthStory.title,
        description: birthStory.description,
        week: null,
        month: null,
        url: birthStory.url,
        imageUrl: birthStory.imageUrl,
        blurhash: birthStory?.blurhash,
        tagWords: birthStory.tagWords ?? [],
        tags: birthStory.tags ?? [],
        type: BlogTypes.BirthStory,
        typeName: "Birth story",
        featured: birthStory.featured ?? 0,
        promoted: birthStory.promoted,
        categories: null,
        translations: birthStory.translations,
        isVlog: birthStory.isVlog,
      };
    },
    [influencerMap]
  );

  const mapGeneralBlogPost = useCallback(
    (generalBlogPost: GeneralBlogPost): Post => {
      const influencer = influencerMap.get(generalBlogPost.influencerId);

      return {
        id: generalBlogPost.id,
        influencerId: generalBlogPost.influencerId,
        influencer: influencer || null,
        week: null,
        month: null,
        url: generalBlogPost.url,
        imageUrl: generalBlogPost.imageUrl,
        tags: generalBlogPost.tags ?? [],
        type: BlogTypes.GeneralBlogPost,
        blurhash: generalBlogPost?.blurhash,
        typeName: "Blog posts (General)",
        featured: generalBlogPost.featured ?? 0,
        promoted: generalBlogPost.promoted,
        categories: null,
        categoryIds: generalBlogPost.categoryIds,
        authorName: generalBlogPost?.authorName,
        translations: generalBlogPost.translations ?? {},
        isVlog: generalBlogPost.isVlog,
      };
    },
    [influencerMap]
  );

  useEffect(() => {
    const _posts = [
      ...blogPosts.map(mapBlogPost),
      ...childBlogPosts.map(mapChildBlogPost),
      ...birthStories.map(mapBirthStory),
      ...generalBlogPosts.map(mapGeneralBlogPost),
    ];
    setPosts(_posts);
  }, [
    blogPosts,
    childBlogPosts,
    birthStories,
    generalBlogPosts,
    mapBlogPost,
    mapChildBlogPost,
    mapBirthStory,
    mapGeneralBlogPost,
  ]);

  const loading =
    loadingBlogPosts ||
    loadingChildBlogPosts ||
    loadingBirthStories ||
    loadingGeneralBlogPosts;

  return { posts, loading };
};
