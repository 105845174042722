import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AiTranslator, FormModal } from "../../components";
import { BJInputFormItem } from "../../components/theme";
import { ContentListType, formValidationError } from "../../utils";
import { ContentSelector } from "../Popular/ContentSelector";
import { v4 as uuidv4 } from "uuid";
import { Checkbox } from "antd";
import { useCountry } from "../../context";
import { commonErrors } from "../../language";

const { requiredError2: requiredError } = commonErrors;

interface Props {
  show: boolean;
  answer: Answer | null;
  withPercentage?: boolean;
  withCorrectAnswer?: boolean;
  onHide: () => void;
  onDelete: (id: Answer["id"]) => void;
  onAdd: (answer: Answer) => void;
  onUpdate: (answer: Answer) => void;
}

type FormValues = {
  isCorrectAnswer: boolean | null;
  moreContent: {
    translations: {
      [locale: string]: {
        title: string;
      };
    };
  };
  translations: {
    [locale: string]: {
      title: string;
    };
  };
};

export const AnswerModal = ({
  show,
  answer,
  withPercentage,
  withCorrectAnswer,
  onHide,
  onAdd,
  onDelete: onDeleteAnswer,
  onUpdate,
}: Props) => {
  const { currentCountry, primaryLocale } = useCountry();
  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup
            .string()
            .required(
              `Title (${String(item.key).toUpperCase()}): ${requiredError}`
            ),
        });
        return acc;
      }, {} as any)
    ),
  });

  const {
    control,
    handleSubmit,
    formState,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const [error, setError] = useState<string | null>(null);
  const [contentCollectionItems, setContentCollectionItems] = useState<
    CommonContentV2[]
  >([]);

  useEffect(() => {
    if (answer) {
      reset({
        isCorrectAnswer: answer.correctAnswer,
        translations: answer.translations,
        moreContent: answer.moreContent,
      });
    } else {
      reset({
        isCorrectAnswer: false,
        translations: {},
        moreContent: {
          translations: {},
        },
      });
      setContentCollectionItems([]);
    }
    setError(null);
  }, [answer, reset, show]);

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const _answers: Answer = {
      id: answer ? answer.id : uuidv4(),
      translations: data.translations,
      sortOrder: answer ? answer.sortOrder : 0,
      correctAnswer: data.isCorrectAnswer,
      moreContent: {
        translations: data.moreContent?.translations,
        content: contentCollectionItems,
        contentMap: contentCollectionItems.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {} as { [key: string]: CommonContentV2 }),
      },
      percentageAnswered: answer ? answer?.percentageAnswered : 0,
    };
    if (answer) {
      onUpdate(_answers);
      onHide();
      return;
    }
    onAdd(_answers);
    onHide();
  };

  const onDelete = async () => {
    if (answer) {
      onDeleteAnswer(answer.id);
    }
    onHide();
  };

  const compareContent = Object.keys(
    answer?.moreContent?.contentMap || {}
  ).some(
    key =>
      !contentCollectionItems.some(
        item => item.id === answer?.moreContent?.contentMap[key].id
      )
  );
  const { translations, moreContent } = watch();

  const onSetTranslatedValue = (value: { data: string[] }) => {
    const data = value.data.map((item: any) => item);

    const clonedTitle = data[0]
      .split(",")[0]
      ?.replace("[", "")
      ?.replace(/['"]+/g, "")
      ?.trim();

    const clonedMCTranslations = data[0]
      .split(",")[1]
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      ?.trim();

    let title;
    let moreContentTranslations;

    [
      translations?.[primaryLocale?.key]?.title,
      moreContent?.translations?.[primaryLocale?.key]?.title,
    ].forEach((item, index) => {
      if (data[0].includes("None")) {
        if (index === 0 && !clonedTitle.includes("None")) {
          title = clonedTitle;
        } else if (index === 1 && !clonedMCTranslations.includes("None")) {
          moreContentTranslations = clonedMCTranslations;
        }
      } else {
        if (index === 0 && item !== undefined) {
          title = clonedTitle;
        } else if (index === 1 && item !== undefined) {
          if (clonedMCTranslations === undefined) {
            moreContentTranslations = data[0];
            return;
          }
          moreContentTranslations = clonedMCTranslations;
        }
      }
    });

    setValue("translations.en.title", title);
    setValue("moreContent.translations.en.title", moreContentTranslations);
  };

  const isDirty =
    !!Object.keys(formState.dirtyFields).length ||
    Object.keys(answer?.moreContent?.contentMap || {}).length !==
      contentCollectionItems.length ||
    compareContent;

  return (
    <FormModal
      onHide={onHide}
      messageOnSubmit={false}
      enableSave={isDirty}
      show={show}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      error={error}
      errors={errors as any}
      onDelete={onDelete}
      modalTitle={
        answer?.translations[primaryLocale?.key]
          ? answer?.translations[primaryLocale?.key]?.title
          : "New Answer"
      }
      enableDelete={!!answer}
      size={"xl"}
      localeSupported
    >
      {locale => (
        <>
          <AiTranslator
            locale={locale}
            data={[
              translations?.sv?.title?.length > 0
                ? translations?.sv?.title?.trim()
                : undefined,
              moreContent?.translations?.sv?.title?.length > 0
                ? moreContent?.translations?.sv?.title?.trim()
                : undefined,
            ]}
            onSetValue={onSetTranslatedValue}
          />
          <BJInputFormItem
            control={control}
            error={!!errors.translations?.[locale?.key]?.title}
            label={`Title (${locale?.label})`}
            message={errors.translations?.[locale?.key]?.title?.message}
            fieldName={`translations.${locale?.key}.title`}
            key={`translations.${locale?.key}.title`}
            required
          />
          {withPercentage && (
            <BJInputFormItem
              control={control}
              error={!!errors.translations?.[locale?.key]?.title}
              label={`More content (${locale?.label})`}
              message={
                errors.moreContent?.translations?.[locale?.key]?.title?.message
              }
              fieldName={`moreContent.translations.${locale?.key}.title`}
              key={`moreContent.translations.${locale?.key}.title`}
            />
          )}
          {withPercentage && (
            <ContentSelector
              headerTitle={"Select content"}
              displayButtons
              setManagedContent={setContentCollectionItems}
              initialContent={answer?.moreContent?.contentMap}
              contentListType={ContentListType.AllContent}
            />
          )}
          {withCorrectAnswer && (
            <Controller
              control={control}
              name="isCorrectAnswer"
              render={({ field: { onChange, value } }) => (
                <Checkbox onChange={onChange} checked={value}>
                  Is correct
                </Checkbox>
              )}
            />
          )}
        </>
      )}
    </FormModal>
  );
};
