import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";

export class PodcastSeasonsService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  getSeasonsCollection = (podcastId: string) =>
    `${CollectionPaths.Podcasts[this.countryCode]}/${podcastId}/seasons`;

  static toPodcastSeason =
    (podcastId: Podcast["id"]) =>
    (doc: any): PodcastSeason => {
      const data = doc.data();
      return {
        id: doc.id,
        podcastId,
        ...data,
        translations: Object.entries(
          data.translations as PodcastSeason["translations"]
        ).reduce((acc, [key, value]) => {
          return (acc[key] = { ...value, tagWords: value.tagWords || [] }), acc;
        }, {} as PodcastSeason["translations"]),
      };
    };

  subscribe = (
    podcastId: Podcast["id"],
    callback: (err: Error | null, season: PodcastSeason[]) => void
  ) =>
    firebase
      .firestore()
      .collection(this.getSeasonsCollection(podcastId))
      .onSnapshot(
        async snapshot => {
          const _season = snapshot.docs.map(
            PodcastSeasonsService.toPodcastSeason(podcastId)
          );
          callback(null, _season);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  getSeasons = (podcastId: Podcast["id"]) => {
    return new Promise<PodcastSeason[]>((resolve, reject) => {
      firebase
        .firestore()
        .collection(this.getSeasonsCollection(podcastId))
        .get()
        .then(snapshot => {
          const _session = snapshot.docs.map(
            PodcastSeasonsService.toPodcastSeason(podcastId)
          );
          resolve(_session);
        })
        .catch(error => {
          logException(error);
          reject(error);
        });
    });
  };

  create = (podcastId: Podcast["id"], data: Omit<PodcastSeason, "id">) =>
    firebase
      .firestore()
      .collection(this.getSeasonsCollection(podcastId))
      .add(data);

  update = (
    podcastId: Podcast["id"],
    podcastSeasonId: PodcastEpisode["id"],
    data: Partial<PodcastSeason>
  ) =>
    firebase
      .firestore()
      .collection(this.getSeasonsCollection(podcastId))
      .doc(podcastSeasonId)
      .update({ ...data });

  delete = (podcastId: Podcast["id"], podcastSeasonId: PodcastSeason["id"]) =>
    firebase
      .firestore()
      .collection(this.getSeasonsCollection(podcastId))
      .doc(podcastSeasonId)
      .delete();

  updateSortOrders = (podcastId: Podcast["id"], seasons: PodcastSeason[]) => {
    const batch = firebase.firestore().batch();
    seasons.forEach(season => {
      const ref = firebase
        .firestore()
        .collection(this.getSeasonsCollection(podcastId))
        .doc(season.id);
      batch.update(ref, { sortOrder: season.sortOrder });
    });
    return batch.commit();
  };
}
