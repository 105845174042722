import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { commonErrors } from "../../language";
import { FormModal } from "../../components";
import { DeepLinkType, formValidationError } from "../../utils";
import { useBlogCategories, useCountry } from "../../context";
import { BJInputFormItem, BJSwitchFormItem } from "../../components/theme";

interface Props {
  category: BlogCategory | null;
  show: boolean;
  onHide: () => void;
}

interface FormValues {
  promoted: boolean;
  translations: {
    [locale: string]: string;
  };
}

export const CategoryModal = ({ category, show, onHide }: Props) => {
  const { currentCountry, primaryLocale } = useCountry();
  const {
    createBlogPostCategory,
    updateBlogPostCategory,
    deleteBlogPostCategory,
  } = useBlogCategories();

  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup
          .string()
          .required(
            `Name (${String(item.key).toUpperCase()}): ${
              commonErrors.requiredError2
            }`
          );
        return acc;
      }, {} as any)
    ),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (category !== null) {
      reset({
        translations: category?.translations,
        promoted: category?.promoted,
      });
    } else {
      reset({
        translations: {},
        promoted: false,
      });
    }
  }, [category, reset, show]);

  const onDelete = async () => {
    if (category !== null) {
      await deleteBlogPostCategory(category?.id);
    } else {
      throw new Error("Category not found");
    }
  };

  const onSubmit = async (data: FormValues) => {
    if (category) {
      await updateBlogPostCategory({ ...data, id: category.id });
    } else {
      await createBlogPostCategory(data);
    }
  };
  const isDirty = !!Object.keys(dirtyFields).length;

  return (
    <FormModal
      onHide={onHide}
      enableSave={isDirty}
      show={show}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      onDelete={onDelete}
      enableDelete={!!category}
      modalSubTitle={category ? `ID - ${category?.id}` : ""}
      modalTitle={
        category?.translations[primaryLocale?.key] || "Add new category"
      }
      recordIdentifier={category?.id}
      deepLink={{
        type: DeepLinkType.ArticleCategories,
        id: category?.id,
        displayLinkText: false,
        countryCode: currentCountry?.abb,
      }}
      localeSupported
    >
      {locale => {
        return (
          <>
            <BJInputFormItem
              label={`Name (${locale?.label ?? ""})`}
              fieldName={`translations.${locale?.key}`}
              key={`translations.${locale?.key}`}
              control={control}
              error={!!errors?.translations?.[locale?.key]}
              message={errors?.translations?.[locale?.key]?.message}
              required={true}
            />
            <BJSwitchFormItem
              label="Promote"
              fieldName={"promoted"}
              control={control}
            />
          </>
        );
      }}
    </FormModal>
  );
};
