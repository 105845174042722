import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import firebase from "../firebase";
import { AppTakeOversService } from "../services";
import { useCountry } from "./CountryContext";

type ContextState = {
  loading: boolean;
  appTakeOvers: AppTakeOver[];
  activeAppTakeOvers: AppTakeOver[];
  error: Error | null;
  onCreate: (
    appTakeOver: AppTakeOver
  ) => Promise<firebase.firestore.DocumentReference | null>;
  onUpdate: (id: string, appTakeOver: AppTakeOver) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  uploadAppTakeOverImage: (file: Blob | ArrayBuffer, fileName: string) => any;
};

const AppTakeOverContext = createContext<ContextState>({
  appTakeOvers: [],
  activeAppTakeOvers: [],
  loading: false,
  error: null,
  onCreate: async () => null,
  onUpdate: async () => null,
  onDelete: async () => null,
  uploadAppTakeOverImage: async () => null,
});

export const AppTakeOverProvider = ({ ...rest }) => {
  const [appTakeOvers, setAppTakeOvers] = useState<AppTakeOver[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const appTakeOversService = useMemo(
    () => new AppTakeOversService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    setLoading(true);

    const unsub = appTakeOversService.subscribe((_error, _appTakeOver) => {
      setAppTakeOvers(_appTakeOver);
      setError(_error);
      setLoading(false);
    });

    return unsub;
  }, [appTakeOversService]);

  const activeAppTakeOvers = useMemo(
    () => appTakeOvers.filter(_CC => _CC.isActive),
    [appTakeOvers]
  );

  const onCreate = useCallback(
    async (appTakeOver: AppTakeOver) =>
      await appTakeOversService.create(appTakeOver),
    [appTakeOversService]
  );

  const onUpdate = useCallback(
    async (id: string, appTakeOver: AppTakeOver) =>
      await appTakeOversService.update(id, appTakeOver),
    [appTakeOversService]
  );

  const onDelete = useCallback(
    async (id: string) => await appTakeOversService.delete(id),
    [appTakeOversService]
  );

  const uploadAppTakeOverImage = useCallback(
    async (file: Blob | ArrayBuffer, fileName: string) =>
      await appTakeOversService.uploadAppTakeOverImage(file, fileName),
    [appTakeOversService]
  );

  const value = useMemo(
    () => ({
      appTakeOvers,
      activeAppTakeOvers,
      loading,
      error,
      onCreate,
      onUpdate,
      onDelete,
      uploadAppTakeOverImage,
    }),
    [
      appTakeOvers,
      activeAppTakeOvers,
      loading,
      error,
      onCreate,
      onUpdate,
      onDelete,
      uploadAppTakeOverImage,
    ]
  );

  return <AppTakeOverContext.Provider value={value} {...rest} />;
};

export const useAppTakeOvers = () => {
  const context = React.useContext(AppTakeOverContext);
  if (context === undefined) {
    throw new Error(
      "useAppTakeOver must be used within an appTakeOverProvider"
    );
  }
  return context;
};

export const useAppTakeOver = (appTakeOverId: AppTakeOver["id"]) => {
  const [appTakeOver, setAppTakeOver] = useState<AppTakeOver | null>(null);
  const [loading, setLoading] = useState(true);

  const { appTakeOvers, loading: loadingAppTakeOver } = useAppTakeOvers();

  const getById = useCallback(
    (id: AppTakeOver["id"]) => {
      const appTakeOver = appTakeOvers.find(o => o.id === id);
      return appTakeOver || null;
    },
    [appTakeOvers]
  );

  useEffect(() => {
    if (loadingAppTakeOver) {
      return;
    }

    setAppTakeOver(getById(appTakeOverId));
    setLoading(false);
  }, [loadingAppTakeOver, getById, appTakeOverId]);

  return { appTakeOver, loading };
};
