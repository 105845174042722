import React, { createContext, useEffect, useMemo, useState } from "react";

import { ChecklistService } from "../services";
import { useCountry } from "./CountryContext";

type DocumentReference<T> = firebase.default.firestore.DocumentReference<T>;
type DocumentData = firebase.default.firestore.DocumentData;

type ContextState = {
  checklists: Checklist[];
  loading: boolean;
  error: Error | null;
  onDelete: (id: string) => Promise<void>;
  onUpdate: (id: Checklist["id"], data: Partial<Checklist>) => Promise<void>;
  onCreate: (
    data: Partial<Checklist>
  ) => Promise<DocumentReference<DocumentData>>;
};

const ChecklistsContext = createContext<ContextState>({
  checklists: [],
  loading: false,
  error: null,
  onCreate: () => null,
  onUpdate: () => null,
  onDelete: () => null,
});

export const ChecklistsProvider = ({ ...rest }) => {
  const [checklists, setChecklists] = useState<Checklist[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const checklistService = useMemo(() => {
    return currentCountry?.abb
      ? new ChecklistService(currentCountry.abb)
      : null;
  }, [currentCountry]);

  useEffect(() => {
    if (!checklistService) {
      return;
    }

    setLoading(true);

    const unsub = checklistService.subscribe((_error, _checklists) => {
      setChecklists(_checklists);
      setError(_error);
      setLoading(false);
    });

    return unsub;
  }, [checklistService]);

  const value = useMemo(
    () => ({
      checklists,
      loading,
      error,
      onCreate: checklistService?.create,
      onUpdate: checklistService?.update,
      onDelete: checklistService?.delete,
    }),
    [
      checklists,
      loading,
      error,
      checklistService?.create,
      checklistService?.update,
      checklistService?.delete,
    ]
  );

  return <ChecklistsContext.Provider value={value} {...rest} />;
};

export const useChecklists = () => {
  const context = React.useContext(ChecklistsContext);
  if (context === undefined) {
    throw new Error("useChecklists must be used within an ChecklistsProvider");
  }
  return context;
};

export const useChecklist = (checklistId: Checklist["id"]) => {
  const [checklist, setChecklist] = useState<Checklist | null>(null);
  const [loading, setLoading] = useState(true);
  const { checklists, loading: lodaingChecklists } = useChecklists();

  useEffect(() => {
    if (lodaingChecklists) {
      return;
    }

    const _checklist = checklists.find(p => p.id === checklistId);
    if (_checklist) {
      setChecklist(_checklist);
    }
    setLoading(false);
  }, [lodaingChecklists, checklists, checklistId]);

  return { checklist, loading };
};
