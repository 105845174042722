import { Col, Row, Typography } from "antd";
import { FilterButton } from "../../components";
import { BJContainer } from "../../components/theme/atoms/BJContainer";

interface Props {
  filters: IListFilters;
  onChangeFilter: (filterName: keyof IListFilters, value: string) => void;
  onCheckAll: (filtername: keyof IListFilters) => void;
  resetFilter?: any;
}

export const Filter = ({
  filters,
  onChangeFilter,
  onCheckAll,
  resetFilter,
}: Props) => {
  return (
    <BJContainer>
      <Row wrap gutter={[20, 20]}>
        <Col>
          <FilterButton
            label="Veckor:"
            filter={filters.weeks}
            onPressItem={item => onChangeFilter("weeks", item)}
            onCheckAll={() => onCheckAll("weeks")}
          />
        </Col>

        <Col>
          <FilterButton
            label="Månader:"
            filter={filters.months}
            onPressItem={item => onChangeFilter("months", item)}
            onCheckAll={() => onCheckAll("months")}
          />
        </Col>
        <Col>
          <FilterButton
            label="Influencers:"
            filter={filters.influencers}
            onPressItem={item => onChangeFilter("influencers", item)}
            onCheckAll={() => onCheckAll("influencers")}
          />
        </Col>
        <Col>
          <FilterButton
            label="Type:"
            filter={filters.types}
            onPressItem={item => onChangeFilter("types", item)}
            onCheckAll={() => onCheckAll("types")}
          />
        </Col>
        <Col>
          <Typography.Link onClick={resetFilter}>Reset Filter</Typography.Link>
        </Col>
      </Row>
    </BJContainer>
  );
};
