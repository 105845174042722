import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, Form, Switch } from "antd";
import { formValidationError, isEqualArrays } from "../../../utils";
import { FormEdit, FormEditType } from "../../../components";
import { commonErrors } from "../../../language";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BJInputFormItem } from "../../../components/theme";
import { contentCollections, cms } from "../../../routes/routeConsts";
import { SortContentItem } from "./Components/SortContentItem";
import { useCountry } from "../../../context";

type FormValues = {
  imageUrl?: string;
  description: string;
  isActive: boolean;
  translations: {
    [locale: string]: {
      title: string;
    };
  };
};
const { requiredError } = commonErrors;

export const ContentSectionPage = ({
  maxSortOrder,
  contentMenu,
  onUpdateDone,
  createSection,
  updateSection,
  deleteSection,
}: {
  maxSortOrder?: number;
  contentMenu?: ContentMenu;
  onUpdateDone?: () => void;
  createSection: (data: Partial<ContentMenu>) => Promise<any>;
  updateSection: (id: string, data: Partial<ContentMenu>) => Promise<void>;
  deleteSection: (id: string) => Promise<void>;
}) => {
  const { currentCountry } = useCountry();

  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup
            .string()
            .required(`Title (${item.label}): ${requiredError}`),
        });
        return acc;
      }, {} as any)
    ),
  });

  const [items, setItems] = useState<ContentItem[]>([]);

  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    control,
  } = useForm<FormValues>({ resolver: yupResolver(schema) });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    contentMenu == null
      ? reset({
          translations: currentCountry?.locales.reduce(
            (acc, item) => (acc[item.key] = { title: "" }),
            {} as any
          ),
        })
      : reset({ ...contentMenu });
    setItems(contentMenu?.menuItems ?? []);
    setError(null);
  }, [contentMenu, currentCountry?.locales, reset]);

  const onSubmit = async (data: FormValues) => {
    const translations: ContentMenu["translations"] = {};

    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        title: value.title?.trim(),
      };
    }

    const updateData: Partial<ContentMenu> = {
      isActive: data.isActive,
      menuItems: items,
      translations,
    };

    if (contentMenu) {
      await updateSection(contentMenu.id, updateData);
    } else {
      await createSection({
        ...updateData,
        sortOrder: maxSortOrder + 1,
      });
    }
    onUpdateDone();
  };

  const onRemove = async () => {
    if (contentMenu) {
      await deleteSection(contentMenu.id);
      onUpdateDone();
    }
  };

  const isDirty =
    !!Object.keys(dirtyFields).length ||
    !isEqualArrays(contentMenu?.menuItems ?? [], items);

  return (
    <FormEdit
      error={error}
      backRoutePath={`${cms}/${contentCollections}/${contentCollections}`}
      onRemove={onRemove}
      hasValidationErrors={Object.keys(errors).length !== 0}
      enableSave={isDirty}
      id={contentMenu?.id}
      editType={contentMenu?.id ? FormEditType.EDIT : FormEditType.ADD}
      loading={false}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      renderButtonsOnFooter
      errors={errors as any}
    >
      <Row>
        <Col span={24}>
          {currentCountry.locales
            .sort((a, b) => (a.primary === b.primary ? 0 : b.primary ? 1 : -1))
            .map(locale => {
              return (
                <BJInputFormItem
                  key={`translations.${locale.key}.title`}
                  control={control}
                  fieldName={`translations.${locale.key}.title`}
                  error={!!errors?.translations?.[locale.key]?.title}
                  label={`Title in ${locale.label}`}
                  message={errors?.translations?.[locale.key]?.title?.message}
                  required={true}
                />
              );
            })}
          <Form.Item label="Active" key="active">
            <Controller
              control={control}
              name="isActive"
              render={({ field: { onChange, value } }) => (
                <Switch onChange={onChange} checked={value} />
              )}
            />
          </Form.Item>
          {contentMenu?.menuItems && (
            <SortContentItem
              onSorted={(sortedContent: ContentItem[]) => {
                setItems(sortedContent);
              }}
              contentSection={contentMenu}
            />
          )}
        </Col>
      </Row>
    </FormEdit>
  );
};
