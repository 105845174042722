import { Control, Path } from "react-hook-form";
import { BJSelectFormItem } from "./BJFormSelectItem";
import { useCountry } from "../../../../context";

export const BJSelectCountryFormItem = <T,>({
  error,
  message,
  control,
  fieldName,
  required,
  handleChange,
  disabled = false,
}: {
  error: boolean;
  message: string;
  control: Control<T>;
  fieldName: Path<T>;
  required?: boolean;
  disabled?: boolean;
  handleChange?: (value: string) => void;
}) => {
  const { countries, currentCountry } = useCountry();

  const selectOptions = countries.map(c => ({
    key: c.abb,
    value: c.abb,
    display: c.name,
  }));

  return (
    <BJSelectFormItem
      size="large"
      control={control}
      error={error}
      label="Country"
      message={message}
      optionsList={selectOptions}
      fieldName={fieldName}
      required={!!required}
      disabled={disabled}
      handleChange={handleChange}
      defaultValue={currentCountry?.abb?.toLowerCase()}
    />
  );
};
