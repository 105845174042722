import firebase, { firestore } from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";
import { uploadImage } from "./serviceUtils";

const contentCollectionFileStoragePath = "contentCollections";

export class ContentCollectionsService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  static toContentCollection = (doc: any): ContentCollection => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
    };
  };

  subscribe = (
    callback: (
      err: Error | null,
      contentCollections: ContentCollection[]
    ) => void
  ) => {
    return firestore
      .collection(CollectionPaths.ContentCollections[this.countryCode])
      .onSnapshot(
        snapshot => {
          const contentCollections = snapshot.docs.map(
            ContentCollectionsService.toContentCollection
          );
          callback(null, contentCollections);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );
  };

  create = (data: ContentCollection) =>
    firestore
      .collection(CollectionPaths.ContentCollections[this.countryCode])
      .add(data);

  update = (id: string, data: Partial<ContentCollection>) => {
    return firestore
      .collection(CollectionPaths.ContentCollections[this.countryCode])
      .doc(id)
      .update(data);
  };

  updateBatch = (data: Partial<ContentCollection>[]) => {
    const batch = firebase.firestore().batch();
    data.forEach(({ id, ...rest }) => {
      const ref = firestore
        .collection(CollectionPaths.ContentCollections[this.countryCode])
        .doc(id);
      batch.update(ref, rest);
    });
    return batch.commit();
  };

  delete = (id: string) =>
    firestore
      .collection(CollectionPaths.ContentCollections[this.countryCode])
      .doc(id)
      .delete();

  static uploadContentCollectionImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => {
    const url = uploadImage(file, contentCollectionFileStoragePath, fileName);
    return url;
  };

  updateSortOrder = (contentCollections: ContentCollection[]) => {
    const batch = firebase.firestore().batch();
    contentCollections.forEach(contentCollection => {
      const ref = firestore
        .collection(CollectionPaths.ContentCollections[this.countryCode])
        .doc(contentCollection.id);
      batch.update(ref, {
        sortOrder: contentCollection.sortOrder,
        priority: "",
      });
    });
    return batch.commit();
  };
}
