import { useMemo, useState } from "react";
import { List, Space, Typography } from "antd";
import styled from "styled-components";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";
import { useCountry } from "../../context";
import { useOffers } from "../../context";
import { BiTrash } from "react-icons/bi";
import { OfferSortableList } from "./OfferSortableList";
import { AddOfferModal } from "../Popular/modal/AddOffersModal";

interface ChecklistOffersProps {
  checklistOffers: ChecklistOffer[];
  setOffers: (offers: ChecklistOffer[]) => void;
}

export const ChecklistOffers: React.FC<ChecklistOffersProps> = ({
  checklistOffers,
  setOffers,
}) => {
  const { primaryLocale } = useCountry();
  const [showAddOfferModal, setShowAddOfferModal] = useState(false);
  const [sortList, setSortList] = useState(false);
  const { offers } = useOffers();

  const onPressAddOffer = () => {
    setShowAddOfferModal(true);
  };

  const customChecklistOffers = checklistOffers
    .map(o => ({
      ...o,
      offer: offers.find(offer => offer.id === o.id),
    }))
    .reduce((p, c) => [...p, c], [])
    .sort((a, b) => a.sortOrder - b.sortOrder);

  const onAddOffer = (offers: CommonContent[]) => {
    const offersData = offers.map((o, index) => ({
      id: o.id,
      sortOrder: index,
    }));
    setOffers(offersData);
    setShowAddOfferModal(false);
  };

  const onPressDeleteOffer = (id: ChecklistOffer["id"]) => {
    const updatedChecklistOffers = checklistOffers.filter(o => !(o.id === id));
    setOffers(updatedChecklistOffers);
  };

  const onUpdateSortOrder = (offers: ChecklistOffer[]) => {
    setOffers(offers);
    setSortList(false);
  };

  const selectedContent = useMemo(() => {
    return (customChecklistOffers as CommonContentV2[]) ?? [];
  }, [customChecklistOffers]);

  return (
    <>
      <AddOfferModal
        show={showAddOfferModal}
        onHide={() => setShowAddOfferModal(false)}
        maxNoOfContentLimit={3}
        selectedContent={selectedContent}
        onAdd={onAddOffer}
        includePreviousContent={false}
      />
      <div className="d-flex justify-content-between align-items-center mt-2">
        <p className="m-0">Offers</p>
        <Space>
          {!sortList && (
            <>
              <BJButton
                size="small"
                buttonType={ButtonTypes.Save}
                onClick={() => onPressAddOffer()}
              >
                Add Offer
              </BJButton>

              <BJButton
                size="small"
                disabled={offers.length <= 1}
                buttonType={ButtonTypes.Primary}
                onClick={() => setSortList(prev => !prev)}
              >
                Sort Offers
              </BJButton>
            </>
          )}
        </Space>
      </div>
      {sortList && (
        <OfferSortableList
          initialItems={customChecklistOffers}
          onCancel={() => setSortList(false)}
          onSave={onUpdateSortOrder}
        />
      )}
      <Spacer size={20} />
      {!sortList && customChecklistOffers && customChecklistOffers.length > 0 && (
        <List bordered>
          {customChecklistOffers.map(o => {
            return (
              <List.Item key={o.id}>
                <Typography key={`${o.id}`}>
                  {o.offer?.translations?.[primaryLocale.key]?.title}
                </Typography>
                <BJButton
                  size="small"
                  buttonType={ButtonTypes.Delete}
                  onClick={() => onPressDeleteOffer(o.id)}
                >
                  <BiTrash />
                </BJButton>
              </List.Item>
            );
          })}
        </List>
      )}
    </>
  );
};

const Spacer = styled.div<{ size: number }>`
  height: ${({ size = 1 }) => size}px;
`;
