import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { adminMenuItemList, menuItems } from "../components";
import { NotificationMenuItems } from "../Layouts/PushNotificationsLayout";

export const useDisplayCountrySelector = () => {
  const location = useLocation();
  const cmsMenuItems = menuItems();

  const filterAllowedPaths = [
    ...cmsMenuItems,
    ...NotificationMenuItems,
    ...adminMenuItemList,
  ]
    .filter(item =>
      item.subMenu
        ? item.subMenu?.filter(s => s.regionSupport).length > 0
        : item.regionSupport
    )
    .flatMap(m => (m.subMenu ? m.subMenu.map(s => s.value) : m.url));
  const isDisplayCountrySelector = useMemo(() => {
    const splittedPath = location.pathname.split("/");
    const lastPath = splittedPath[splittedPath.length - 1];
    const answer = filterAllowedPaths.some(path =>
      lastPath
        ? lastPath.includes(path)
        : splittedPath[splittedPath.length - 2].includes(path)
    );

    return answer;
  }, [filterAllowedPaths, location.pathname]);

  return isDisplayCountrySelector;
};
