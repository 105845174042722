import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useBanners, useCountry } from "../../context";
import { BannersService } from "../../services";
import { FormEdit, FormEditType } from "../../components/FormEdit";
import {
  BJSortableList,
  SortContentType,
} from "../../components/theme/molecules/BJSortableList";
import { arrayMove } from "react-sortable-hoc";

type SortProps = {
  oldIndex: number;
  newIndex: number;
};

export const ChangeBannerList = () => {
  const { banners: allBanners, loading: bannersLoading } = useBanners();
  const { handleSubmit } = useForm();
  const [isDirty, setIsDirty] = useState(false);
  const [banners, setBanners] = useState<Banner[]>([]);
  const { currentCountry } = useCountry();

  const bannersService = useMemo(
    () => new BannersService(currentCountry.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    const processed = allBanners
      .sort((a: Banner, b: Banner) => a.sortOrder - b.sortOrder)
      .map((banner, index) => ({ ...banner, sortOrder: index }));

    setBanners(processed);
  }, [allBanners]);

  const saveOrder = async () => {
    await bannersService.updateSortOrder(
      banners.map((banners, index) => ({ ...banners, sortOrder: index }))
    );
    setIsDirty(false);
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortProps) => {
    if (oldIndex === newIndex) {
      return;
    }
    const sortableBanners = arrayMove([...banners], oldIndex, newIndex);
    setBanners(sortableBanners);
    setIsDirty(true);
  };

  return (
    <FormEdit
      enableSave={isDirty}
      title={"Change Banners order"}
      editType={FormEditType.VIEW}
      loading={bannersLoading}
      onSubmit={handleSubmit(saveOrder)}
    >
      <BJSortableList
        items={banners.map(
          x =>
            ({
              id: x.id,
              title: x.internalName,
              noImage: true,
            } as SortContentType)
        )}
        onSortEnd={onSortEnd}
      />
    </FormEdit>
  );
};
