import { useCountry } from "../context";

export const CountryGuard = ({
  component,
}: {
  component: React.ComponentType;
}) => {
  const { currentCountry } = useCountry();
  const Component = component;

  return <Component key={currentCountry?.abb} />;
};
