import { DatePicker, Form, Typography } from "antd";
import { Control, Controller, Path } from "react-hook-form";
import { BJFormLabelInfo } from "../../atoms/BJInfo";
import { Moment } from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
const { RangePicker } = DatePicker;

export const BJFormDateRangePicker = <T,>({
  extra,
  showInfo,
  error,
  message,
  label,
  required,
  fieldName,
  control,
  ...props
}: {
  extra?: string;
  showInfo?: boolean;
  error: boolean;
  message: string;
  label: string;
  required?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  control: Control<T>;
  fieldName: Path<T>;
} & RangePickerProps) => {
  return (
    <Form.Item
      label={showInfo ? <BJFormLabelInfo info={extra} label={label} /> : label}
      required={required}
      validateStatus={error && "error"}
      {...(message
        ? {
            help: (
              <Typography.Paragraph type="danger">
                {message}
              </Typography.Paragraph>
            ),
          }
        : undefined)}
      {...(extra && !showInfo
        ? {
            extra: (
              <Typography.Paragraph type="warning">
                {extra}
              </Typography.Paragraph>
            ) as JSX.Element,
          }
        : undefined)}
    >
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { onChange, value } }) => {
          const formattedValue = value as Moment[]; // Explicit cast here
          const rangeValue: [Moment, Moment] | undefined =
            formattedValue?.length >= 2
              ? [formattedValue[0], formattedValue[1]]
              : undefined;
          return (
            <RangePicker value={rangeValue} onChange={onChange} {...props} />
          );
        }}
      />
    </Form.Item>
  );
};
