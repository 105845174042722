import { useNavigate } from "react-router-dom";
import { useMonthlyInformation, useCountry } from "../../context";
import { useEffect, useState } from "react";
import BJList from "../../components/theme/components/BJList";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";

type datasourceType = MonthInformation;

export const MonthlyInformationListPage = () => {
  const navigate = useNavigate();
  const { monthlyInformation, loading, weeklyInformation } =
    useMonthlyInformation();
  const [originalTable, setOriginalTable] = useState<datasourceType[]>([]);
  const [weeklyOriginalTable, setWeeklyOriginalTable] = useState<
    datasourceType[]
  >([]);
  const { primaryLocale } = useCountry();

  useEffect(() => {
    const monthlyInfoTableSource: datasourceType[] = monthlyInformation.map(
      monthly => ({
        ...monthly,
        key: monthly.id,
      })
    );

    const weeklyInfoTableSource: datasourceType[] = weeklyInformation.map(
      weekly => ({
        ...weekly,
        key: weekly.id,
      })
    );

    setOriginalTable(monthlyInfoTableSource);
    setWeeklyOriginalTable(weeklyInfoTableSource);
  }, [monthlyInformation, weeklyInformation]);

  const onClickRow = (record: datasourceType) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const handleNewRecord = (type: string) => {
    navigate(`types/${type}`);
  };

  const getColumns = (title: string): BJColumnType<datasourceType>[] => {
    return [
      {
        title: title.charAt(0).toUpperCase() + title.slice(1),
        dataIndex: title,
        key: title,
        width: 2,
        ellipsis: true,
        defaultSortOrder: "ascend",
        sortDirections: ["ascend", "descend"],
        sorter: {
          compare: Sorter.NUMBER,
        },
      },
    ];
  };

  const downloadData = () => {
    const csvData = [
      [
        "Id",
        "Month",
        "Development Body",
        "Development Intro",
        "Parent Body",
        "Parent Intro",
        "TryIt Body",
        "TryIt Intro",
      ],
    ];
    monthlyInformation.forEach(t => {
      csvData.push([
        t.id.toString(),
        t.month.toString(),
        t.development?.translations?.[primaryLocale.key]?.body?.replace(
          /"/g,
          '""'
        ),
        t.development?.translations?.[primaryLocale.key]?.intro?.replace(
          /"/g,
          '""'
        ),
        t.parent?.translations?.[primaryLocale.key]?.body?.replace(/"/g, '""'),
        t.parent?.translations?.[primaryLocale.key]?.intro?.replace(/"/g, '""'),
        t.tryIt?.translations?.[primaryLocale.key]?.body?.replace(/"/g, '""'),
        t.tryIt?.translations?.[primaryLocale.key]?.intro?.replace(/"/g, '""'),
      ]);
    });
    return csvData;
  };

  return (
    <>
      <BJList
        loading={loading}
        filterOnlyDisplayList
        title={"Weekly information"}
        OriginalList={weeklyOriginalTable}
        columns={getColumns("week")}
        onClickRow={onClickRow}
        onclick={() => handleNewRecord("week")}
        downloadData={downloadData()}
        defaultPageSize={13}
        addButtonCaption={"New Week"}
      />
      <BJList
        loading={loading}
        filterOnlyDisplayList
        title={"Monthly information"}
        addButtonCaption={"New Month"}
        OriginalList={originalTable}
        columns={getColumns("month")}
        onClickRow={onClickRow}
        onclick={() => handleNewRecord("month")}
        downloadData={downloadData()}
      />
    </>
  );
};
