import { Form, Slider, Typography } from "antd";
import { SliderRangeProps } from "antd/lib/slider";
import { BJFormLabelInfo } from "../atoms/BJInfo";

interface BJSliderProps extends SliderRangeProps {
  extra?: string;
  showInfo?: boolean;
  selectedRange?: [number, number];
  title?: string;
  error: boolean;
  message: string;
  label: string;
  required?: boolean;
  multiple?: boolean;
  disabled?: boolean;
}

export const BJSlider = ({
  extra,
  showInfo,
  error,
  message,
  label,
  required,
  ...props
}: BJSliderProps) => {
  return (
    <>
      <Form.Item
        label={
          showInfo ? <BJFormLabelInfo info={extra} label={label} /> : label
        }
        // name={fieldName}
        required={required}
        validateStatus={error && "error"}
        {...(message
          ? {
              help: (
                <Typography.Paragraph type="danger">
                  {message}
                </Typography.Paragraph>
              ),
            }
          : undefined)}
        {...(extra && !showInfo
          ? {
              extra: (
                <Typography.Paragraph type="warning">
                  {extra}
                </Typography.Paragraph>
              ) as JSX.Element,
            }
          : undefined)}
      >
        <Slider
          {...props}
          marks={props.selectedRange?.reduce(
            (a: { [key: number]: number }, c: number) => ({
              ...a,
              [c]: c,
            }),
            {}
          )}
        />
      </Form.Item>
    </>
  );
};
