import firebase, { firestore } from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";

export class ToolsMenuSectionService extends CountryCodeService {
  constructor(locale: string) {
    super(locale);
  }

  static toContentMenu = (
    doc: firebase.firestore.QueryDocumentSnapshot<ContentMenu>
  ): ContentMenu => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      translations: data?.translations ?? {},
    };
  };

  subscribe = (
    callback: (err: Error | null, contentMenus: ContentMenu[]) => void
  ) =>
    firestore
      .collection(CollectionPaths.ToolsMenuSection[this.countryCode])
      .withConverter<ContentMenu>(null)
      .onSnapshot(
        snapshot => {
          const menuItems = snapshot.docs.map(
            ToolsMenuSectionService.toContentMenu
          );
          callback(null, menuItems);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  // //added
  getTools = (menuItems: ContentMenu[]) => {
    return menuItems.flatMap(menuItem => menuItem || []);
  };

  create = (data: Partial<ContentMenu>) =>
    firestore
      .collection(CollectionPaths.ToolsMenuSection[this.countryCode])
      .add(data);

  update = (id: string, data: Partial<ContentMenu>) => {
    return firestore
      .collection(CollectionPaths.ToolsMenuSection[this.countryCode])
      .doc(id)
      .update(data);
  };

  updateMenuItems = (id: string, data: ContentMenu) => {
    return firestore
      .collection(CollectionPaths.ToolsMenuSection[this.countryCode])
      .doc(id)
      .update(data);
  };

  delete = (id: string) =>
    firestore
      .collection(CollectionPaths.ToolsMenuSection[this.countryCode])
      .doc(id)
      .delete();

  updateSortOrder = (menuItems: ContentMenu[]) => {
    const batch = firebase.firestore().batch();
    menuItems.forEach(menuItem => {
      const ref = firestore
        .collection(CollectionPaths.ToolsMenuSection[this.countryCode])
        .doc(menuItem.id);
      batch.update(ref, { sortOrder: menuItem.sortOrder, priority: "" });
    });
    return batch.commit();
  };
}
