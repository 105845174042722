import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ContentCollectionsService } from "../services";
import { useCountry } from "./CountryContext";
import { getMax } from "../utils";

type ContextState = {
  contentCollections: ContentCollection[];
  activeContentCollections: ContentCollection[];
  loading: boolean;
  error: Error | null;
  contentCollectionsService: ContentCollectionsService | null;
  getCCForContentId: (contentId: string) => ContentCollection[];
  updateCCWithContentId: (
    updatedCCIds: string[],
    content: CommonContentV2
  ) => void;
};

const ContentCollectionContext = createContext<ContextState>({
  contentCollections: [],
  activeContentCollections: [],
  loading: false,
  error: null,
  contentCollectionsService: null,
  getCCForContentId: () => [],
  updateCCWithContentId: () => null,
});

export const ContentCollectionProvider = ({ ...rest }) => {
  const [contentCollections, setContentCollections] = useState<
    ContentCollection[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const { currentCountry } = useCountry();

  const contentCollectionsService = useMemo(() => {
    return currentCountry?.abb
      ? new ContentCollectionsService(currentCountry.abb)
      : null;
  }, [currentCountry]);

  useEffect(() => {
    if (!contentCollectionsService) {
      return;
    }
    setLoading(true);

    const unsub = contentCollectionsService.subscribe(
      (_error, _contentCollection) => {
        setContentCollections(_contentCollection);
        setError(_error);
        setLoading(false);
      }
    );

    return unsub;
  }, [contentCollectionsService]);

  const activeContentCollections = useMemo(
    () => contentCollections.filter(_CC => _CC.isActive),
    [contentCollections]
  );

  const getCCForContentId = useCallback(
    (contentId: string) => {
      return contentCollections.filter(_CC => _CC.contentMap?.[contentId]);
    },
    [contentCollections]
  );

  const updateCCWithContentId = useCallback(
    async (updatedCCIds: string[], content: CommonContentV2) => {
      const originalCC = getCCForContentId(content.id);
      const tempArray: ContentCollection[] = [];

      // Remove content from content collections where it's no longer included
      for (const c of originalCC) {
        if (!updatedCCIds.includes(c.id)) {
          const { [content.id]: _, ...rest } = c.contentMap;
          tempArray.push({
            ...c,
            content: c.content.filter(({ id }) => id !== content.id),
            contentMap: rest,
          });
        }
      }

      // Add content to content collections where it's not already included
      for (const id of updatedCCIds) {
        if (!originalCC.some(c => c.id === id)) {
          const cc = contentCollections.find(c => c.id === id);
          const highestSortOrder = getMax(cc.content, "sortOrder");
          if (cc) {
            tempArray.push({
              ...cc,
              content: [
                ...cc.content,
                { ...content, sortOrder: highestSortOrder + 1 },
              ],
              contentMap: {
                ...cc.contentMap,
                [content.id]: { ...content, sortOrder: highestSortOrder + 1 },
              },
            });
          }
        }
      }

      return await contentCollectionsService?.updateBatch(tempArray);
    },

    [contentCollections, contentCollectionsService, getCCForContentId]
  );

  const value = useMemo(
    () => ({
      contentCollections,
      activeContentCollections,
      loading,
      error,
      contentCollectionsService,
      getCCForContentId,
      updateCCWithContentId,
    }),
    [
      contentCollections,
      activeContentCollections,
      loading,
      error,
      contentCollectionsService,
      getCCForContentId,
      updateCCWithContentId,
    ]
  );

  return <ContentCollectionContext.Provider value={value} {...rest} />;
};

export const useContentCollections = () => {
  const context = React.useContext(ContentCollectionContext);
  if (context === undefined) {
    throw new Error(
      "useContentCollection must be used within an contentCollectionProvider"
    );
  }
  return context;
};

export const useContentCollection = (
  contentCollectionId: ContentCollection["id"]
) => {
  const [contentCollection, setContentCollection] =
    useState<ContentCollection | null>(null);
  const [loading, setLoading] = useState(true);

  const {
    contentCollections,
    loading: loadingContentCollection,
    contentCollectionsService,
  } = useContentCollections();

  const getById = useCallback(
    (id: ContentCollection["id"]) => {
      const contentCollection = contentCollections.find(o => o.id === id);
      return contentCollection || null;
    },
    [contentCollections]
  );

  useEffect(() => {
    if (loadingContentCollection) {
      return;
    }

    setContentCollection(getById(contentCollectionId));
    setLoading(false);
  }, [loadingContentCollection, getById, contentCollectionId]);

  return { contentCollection, loading, contentCollectionsService };
};
