import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useArticles, useContentCollections } from "../../context";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";
import BJList from "../../components/theme/components/BJList";
import { changeorder } from "../../routes";
import { Switch } from "antd";
import { DeepLinkType } from "../../utils";
import { DeepLinkForList } from "../../components/theme";
import { useCountry } from "../../context";

interface DatasourceType extends Partial<FullArticle> {
  categoryTitle: string | undefined;
  restricted: boolean;
  deepLink?: DeepLinkProps;
}

export const ArticleListPage = () => {
  const navigate = useNavigate();
  const { articles, loading } = useArticles();
  const [originalTable, setOriginalTable] = useState<DatasourceType[]>([]);
  const { currentCountry, primaryLocale } = useCountry();
  const { getCCForContentId } = useContentCollections();

  useEffect(() => {
    const articleTableSource: DatasourceType[] = articles.map(article => {
      return {
        id: article?.id,
        title: article?.translations?.[primaryLocale.key]?.title,
        body: article?.translations?.[primaryLocale.key]?.body,
        key: article.id,
        categoryTitle:
          article.category?.translations?.[primaryLocale.key]?.title,
        restricted: article?.restricted,
        deepLink: {
          type: DeepLinkType.Article,
          id: article?.id,
          countryCode: currentCountry?.abb,
        },
        contentCollectionName: getCCForContentId(article.id)
          .map(cc => cc.translations?.[primaryLocale.key]?.title)
          .join(", "),
      };
    });
    setOriginalTable(articleTableSource);
  }, [articles, currentCountry?.abb, getCCForContentId, primaryLocale?.key]);

  const onClickRow = (record: DatasourceType) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const handleNewArticle = () => {
    navigate("new");
  };

  const columns: BJColumnType<DatasourceType>[] = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 2,
      ellipsis: true,
      onFilter: (value, record) => record.title.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Content Collection",
      dataIndex: "contentCollectionName",
      key: "contentCollectionName",
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Restricted",
      dataIndex: "restricted",
      key: "restricted",
      width: 0.5,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (value: boolean) => (
        <Switch checked={value} size="small" disabled />
      ),
    },
    DeepLinkForList(),
  ];

  const downloadData = () => {
    const csvData = [["Id", "Title", "Intro", "Body", "Category"]];
    articles.forEach(t => {
      csvData.push([
        t.id.toString(),
        t.translations?.[primaryLocale.key]?.title,
        t.translations?.[primaryLocale.key]?.intro?.replace(/"/g, '""'),
        t.translations?.[primaryLocale.key]?.body?.replace(/"/g, '""'),
        t.category?.translations?.sv?.title,
      ]);
    });
    return csvData;
  };

  return (
    <>
      <BJList
        key={`articleList-${currentCountry?.abb}`}
        loading={loading}
        onChangeOrder={() => {
          navigate(changeorder);
        }}
        filterOnlyDisplayList
        addButtonCaption={"New Article"}
        title={"Article"}
        OriginalList={originalTable}
        columns={columns}
        onClickRow={onClickRow}
        onclick={handleNewArticle}
        recordCountSuffix={"Articles"}
        downloadData={downloadData()}
      />
    </>
  );
};
