import { Divider } from "antd";
import { LevelInformationForm } from "./LevelInformationForm";
import { LoyaltyPopupForm } from "./LoyaltyPopupForm";

export const LoyaltyLevelsInfoPage = () => {
  return (
    <>
      <LevelInformationForm />
      <Divider style={{ marginBlockEnd: 48 }} />
      <LoyaltyPopupForm />
    </>
  );
};
