import { Tag, AutoComplete } from "antd";
import styled from "styled-components";
import { SyncOutlined } from "@ant-design/icons";
import { compareTexts, ContentType, TAGS_MAX_LENGTH } from "../../../utils";
import { Typography } from "antd";
import { useTags } from "../../../context";
import { TagsService } from "../../../services";
import { useReducer, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

interface Props {
  contentTags: string[];
  contentType: ContentType;
  onChange: (tags: string[]) => void;
}

type TagState = {
  saving?: boolean;
  typing?: string;
  error?: string;
  loading?: boolean;
  searchableTags?: { value: string }[];
  options?: { value: string }[];
  allTags?: Tags[];
  contentTags?: { value: string }[];
};

enum TagActions {
  TYPING = "typing",
  SAVING = "saving",
  OPTIONS = "options",
  LOADING = "loading",
  ALL_TAGS = "allTags",
  CONTENT_TAGS = "contentTags",
  ERROR = "error",
}

type Action =
  | { type: TagActions.TYPING; payload: string }
  | { type: TagActions.ERROR; payload: string }
  | { type: TagActions.LOADING; payload: boolean }
  | { type: TagActions.SAVING; payload: boolean }
  | { type: TagActions.OPTIONS; payload: { value: string }[] | [] }
  | { type: TagActions.ALL_TAGS; payload: Tags[] | [] }
  | { type: TagActions.CONTENT_TAGS; payload: { value: string }[] | [] };

const { Paragraph } = Typography;

function typingReducer(state: TagState, action: Action): TagState {
  const { type, payload } = action;
  switch (type) {
    case TagActions.TYPING:
      return { ...state, typing: payload as string };
    case TagActions.ERROR:
      return { ...state, error: payload as string };
    case TagActions.SAVING:
      return { ...state, saving: payload as boolean };
    case TagActions.LOADING:
      return { ...state, loading: payload as boolean };
    case TagActions.OPTIONS:
      return { ...state, options: payload as { value: string }[] };
    case TagActions.ALL_TAGS:
      return { ...state, allTags: payload as Tags[] };
    case TagActions.CONTENT_TAGS:
      return { ...state, contentTags: payload as { value: string }[] };
    default:
      return state;
  }
}

export const BJTags = ({ contentTags, onChange }: Props) => {
  const { tags, loadingTags: loading } = useTags();
  const { t } = useTranslation();
  const theme = useTheme();
  const onselectFiredRef = useRef(false);

  const [state, dispatch] = useReducer(typingReducer, {
    typing: "",
    saving: false,
    loading: loading,
    options: [],
    allTags: tags,
    contentTags: [],
    searchableTags: [],
  });

  useEffect(() => {
    if (contentTags?.length > 0) {
      dispatch({
        type: TagActions.CONTENT_TAGS,
        payload: contentTags.map(x => ({ value: x })),
      });
    }
  }, [contentTags]);

  useEffect(() => {
    if (loading) {
      dispatch({
        type: TagActions.LOADING,
        payload: true,
      });
    } else {
      dispatch({
        type: TagActions.ALL_TAGS,
        payload: tags,
      });
      dispatch({
        type: TagActions.LOADING,
        payload: false,
      });
    }
  }, [tags, loading]);

  const handleSelectInputChange = async (inputValue: string) => {
    if (!inputValue) return;
    const value = inputValue.trim();
    dispatch({ type: TagActions.TYPING, payload: value });
    const alreadyGlobalTag = state.allTags.some(x =>
      compareTexts(x.name, value)
    );
    if (!alreadyGlobalTag) {
      //if not in global tags ,persist it and add it to tagsInContent
      const updatedTags = [...state.contentTags, { value }];
      await persistToGlobalTagStorage(value);
      dispatch({
        type: TagActions.CONTENT_TAGS,
        payload: updatedTags,
      });
      const updatedTagValues = updatedTags.map(x => x.value);
      onChange(updatedTagValues);
    } else {
      if (!state.contentTags.some(x => compareTexts(x.value, value))) {
        const newTags: {
          value: string;
        }[] = [...state.contentTags, { value: value }];
        dispatch({
          type: TagActions.OPTIONS,
          payload: newTags,
        });
        onChange(newTags.map(x => x.value).filter(x => x) as string[]);
      } else {
        dispatch({
          type: TagActions.ERROR,
          payload: t("tags:error"),
        });
        console.log(
          "this tag already exists in tags collection and is added to currect collection"
        );
      }
    }

    dispatch({ type: TagActions.TYPING, payload: "" });
  };

  const persistToGlobalTagStorage = async (mapped: string) => {
    dispatch({ type: TagActions.SAVING, payload: true });
    await TagsService.addTags(mapped);
    dispatch({ type: TagActions.SAVING, payload: false });
  };

  const handleClose = (value: string) => {
    const newTagsInContent = state.contentTags.filter(
      x => !compareTexts(x.value, value)
    );
    dispatch({ type: TagActions.CONTENT_TAGS, payload: newTagsInContent });
    onChange(newTagsInContent.map(value => value.value));
  };

  const onSelect = (data: string) => {
    dispatch({ type: TagActions.TYPING, payload: data });
    handleSelectInputChange(data);
    onselectFiredRef.current = true;
  };

  const clearErrors = () => {
    if (state.error) dispatch({ type: TagActions.ERROR, payload: "" });
  };

  const onSearch = (data: string) => {
    clearErrors();
    dispatch({ type: TagActions.TYPING, payload: data });
    const filteredTags = state.allTags.filter(x =>
      x?.name?.toLocaleLowerCase().includes(data?.toLocaleLowerCase())
    );
    const textComparedTags = filteredTags.filter(
      y => !state.contentTags.some(x => compareTexts(x.value, y.name))
    );
    dispatch({
      type: TagActions.OPTIONS,
      payload: textComparedTags.map(x => ({ value: x.name })),
    });
  };

  // this change is to check usage of tags in content
  return <></>;

  return (
    <>
      <StyledHeader>Tags</StyledHeader>
      <TagContainer key={"tags-component"}>
        {state.contentTags.map(tag => (
          <StyledTag
            color={
              TAGS_MAX_LENGTH <= state.contentTags?.length
                ? theme.red
                : theme.primary
            }
            key={tag.value}
            closable
            onClose={e => {
              e.preventDefault();
              handleClose(tag.value);
            }}
          >
            {tag.value}
          </StyledTag>
        ))}
        {state.typing && state.saving && (
          <StyledTag icon={<SyncOutlined spin />} color="processing">
            {state.typing}
          </StyledTag>
        )}
        {!state.saving && (
          <AutoComplete
            autoFocus
            value={state.typing}
            disabled={
              TAGS_MAX_LENGTH <= state.contentTags?.length || state.saving
            }
            size="large"
            options={state.options}
            bordered={false}
            style={{ width: 200 }}
            onSelect={onSelect}
            onSearch={onSearch}
            onFocus={clearErrors}
            onKeyDown={e => {
              if (e.key === "Enter") {
                e.preventDefault();
                if (
                  !state.options.some(x =>
                    compareTexts(x.value, state.typing)
                  ) &&
                  !onselectFiredRef.current
                ) {
                  handleSelectInputChange(state.typing);
                }
                onselectFiredRef.current = false;
              }
            }}
            placeholder={t("tags:autoCompletePlaceHolder")}
          />
        )}
      </TagContainer>
      <WordWrapper>
        <Typography.Text type="secondary">
          {t("tags:limitWarning", { count: TAGS_MAX_LENGTH })}
        </Typography.Text>
        {state.error && (
          <Typography.Paragraph type="danger">{`${state.error}`}</Typography.Paragraph>
        )}
      </WordWrapper>
    </>
  );
};

const WordWrapper = styled.div`
  padding-left: 0.4rem;
  padding-top: 0.1rem;
  margin-bottom: 0.6rem;
`;

const StyledHeader = styled(Paragraph)`
  padding-bottom: 0.5rem;
`;

const TagContainer = styled.div`
  display: inline-block;
  border: 0.063rem solid #ccc;
  border-radius: 0.938rem;
  width: 100%;
`;

const StyledTag = styled(Tag)`
  margin: 0.225rem;
  font-size: 1rem;
  color: inherit;
  padding: 0.225rem;

  .ant-tag {
    background: red;
  }
  .ant-tag-close-icon {
    position: relative;
    top: -0.2rem;
    color: ${props => props.theme.black};
  }
  .ant-tag-close-icon:hover {
    color: ${props => props.theme.hover};
  }
`;
