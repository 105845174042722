import firebase from "firebase/compat/app";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { scrapped } from "../hooks/useScraper";
import { cloudFunctionNames } from "../utils";

export const uploadImage = async (
  file: Blob | ArrayBuffer,
  filePath: string,
  fileName: string
) => {
  const storageRef = firebase.storage().ref(filePath);
  const fileRef = storageRef.child(fileName);
  const ref = await fileRef.put(file);
  const url = await ref.ref.getDownloadURL();
  return url;
};

export const scrapeData = async (url: string) => {
  try {
    const scrapeMeta = httpsCallable(functions, cloudFunctionNames.scrapeMeta);
    const result = await scrapeMeta({
      url,
    });
    return result.data as scrapped;
  } catch (error) {
    console.log("error occured while scraping url :", error);
    throw error;
  }
};
