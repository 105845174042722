import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";

export class WeeklyInformationService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  static toWeeklyInformation = (doc: any): WeeklyInformation => {
    const data = doc.data();
    return {
      id: doc.id,
      week: data.week,
      child: data.child || null,
      mother: data.mother || null,
      partner: data.partner || null,
    };
  };

  subscribe = (
    callback: (
      err: Error | null,
      weeklyInformation: WeeklyInformation[]
    ) => void
  ) =>
    firebase
      .firestore()
      .collection(CollectionPaths.WeeklyInformation[this.countryCode])
      .onSnapshot(
        snapshot => {
          const weeklyInformation = snapshot.docs.map(doc =>
            WeeklyInformationService.toWeeklyInformation(doc)
          );
          callback(null, weeklyInformation);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  create = (data: Omit<WeeklyInformation, "id">) =>
    firebase
      .firestore()
      .collection(CollectionPaths.WeeklyInformation[this.countryCode])
      .add({ ...data });

  update = (
    id: string,
    data: Omit<WeeklyInformation, WeeklyInformation["id"]>
  ) => {
    return firebase
      .firestore()
      .collection(CollectionPaths.WeeklyInformation[this.countryCode])
      .doc(id)
      .update({ ...data });
  };

  delete = (id: string) =>
    firebase
      .firestore()
      .collection(CollectionPaths.WeeklyInformation[this.countryCode])
      .doc(id)
      .delete();
}
