import styled, { css } from "styled-components";
import { ReactComponent as ContentCirclePlus } from "./../../../../assets/icons/contentPlusCircle.svg";
import PencilBackground from "./../../../../assets/icons/PencilSimple.png";
interface Props {
  key: string | number;
  isNewItem?: boolean;
  icon: string;
  description: string;
  onClick: () => void;
}
export const ContentItem = ({
  key,
  isNewItem,
  icon,
  description,
  onClick,
}: Props) => {
  return (
    <ContentItemComp key={key} onClick={onClick} isNewItem={isNewItem}>
      <IconDiv>
        {isNewItem ? <PlusIcon width={"3rem"} /> : <StyledImage src={icon} />}
      </IconDiv>
      <TextDiv>
        <StyledPara>{description}</StyledPara>
      </TextDiv>
    </ContentItemComp>
  );
};
const PlusIcon = styled(ContentCirclePlus)`
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const ContentItemComp = styled.div<Partial<Props>>`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  cursor: pointer;
  height: 6rem;
  background-color: ${props => props.theme.primary};
  ${props => {
    return (
      !props.isNewItem &&
      css`
        &:hover {
          img,
          p {
            opacity: 0.1;
          }
          background-image: url(${PencilBackground}) !important;
          background-color: ${props => props.theme.button.hover};
          background-position: center;
          background-repeat: no-repeat;
          z-index: 1;
          background-size: 3rem 3rem;
        }
      `
    );
  }}
`;

const StyledPara = styled.p`
  font-size: 0.75rem;
  text-align: center;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  z-index: 0.4;
  height: 80%;
`;

const IconDiv = styled.div<Partial<Props>>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledImage = styled.img<Partial<Props>>`
  width: 3rem;
  height: 3rem;
  object-fit: contain;
`;

const TextDiv = styled.div<Partial<Props>>`
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  display: flex;
  justify-content: center;
  z-index: 0.5;
  height: 2rem;
`;
