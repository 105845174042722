import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCountry, useOffers } from "../../context";
import { Sorter } from "../../components/theme/util/sorter";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import BJList from "../../components/theme/components/BJList";
import { changeorder, newRoute, topFiveOffers } from "../../routes";
import { DeepLinkForList } from "../../components/theme";
import { DeepLinkType } from "../../utils";

type datasourceType = { deepLink?: DeepLinkProps } & Offer;

export const OfferListPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location?.pathname || "/offers";
  const { offers, loading } = useOffers();
  const [originalTable, setOriginalTable] = useState<datasourceType[]>([]);
  const { currentCountry } = useCountry();

  const defaultLocale = useMemo(() => {
    const [defaultLocale] = currentCountry?.locales.filter(
      locale => locale.primary
    );
    return defaultLocale;
  }, [currentCountry?.locales]);

  useEffect(() => {
    const offerTableSource: datasourceType[] = offers
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map(offer => ({
        ...offer,
        key: offer.id,
        title: offer?.translations?.[defaultLocale.key]?.title,
        deepLink: {
          type: DeepLinkType.Offer,
          id: offer?.id,
          countryCode: currentCountry?.abb,
        },
      }));
    setOriginalTable(offerTableSource);
  }, [offers, currentCountry?.abb]);

  const handleNewOffer = () => {
    navigate(newRoute);
  };

  const onClickRow = (record: datasourceType) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const columns: BJColumnType<datasourceType>[] = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 2,
      ellipsis: true,
      onFilter: (value, record) => record.title.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    DeepLinkForList(),
  ];

  const downloadData = () => {
    const csvData = [["Id", "Title", "Intro", "Body", "Company"]];
    offers.forEach(t => {
      csvData.push([
        t.id.toString(),
        t.title,
        t.intro?.replace(/"/g, '""'),
        t.body?.replace(/"/g, '""'),
        t.company,
      ]);
    });
    return csvData;
  };

  return (
    <BJList
      onChangeOrder={() => {
        navigate(changeorder);
      }}
      addTopFiveOffers={() => {
        navigate(topFiveOffers);
      }}
      loading={loading}
      currentPath={currentPath}
      filterOnlyDisplayList
      addButtonCaption={"New Offer"}
      title={"Offer"}
      OriginalList={originalTable}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewOffer}
      recordCountSuffix={"Offers"}
      downloadData={downloadData()}
    />
  );
};
