import { ReactNode, useEffect, useMemo, useState } from "react";
import { BackButton, ConfirmationModal, CenteredSpinner } from "./";
import { commonErrors } from "../language";
import BJButton, { ButtonTypes } from "./theme/atoms/Button";
import Layout from "antd/lib/layout/layout";
import Row from "antd/lib/row";
import {
  Space,
  Spin,
  Typography,
  Col,
  Divider,
  Form,
  Alert,
  Radio,
} from "antd";
import { BJNotification } from "./theme/atoms/BJNotification";
import { NotificationType } from "./theme/util/notificationObj";
import styled from "styled-components";
import { generateFormError } from "../utils";
import { BJFlex } from "./styled";
import { BJDeeplink } from "./theme";
import { logException } from "../utils/exceptionLogger";
import { useCountry } from "../context";
import { FieldError } from "react-hook-form";
import isEmpty from "lodash/isEmpty";

const { Text } = Typography;

export enum FormEditType {
  ADD,
  EDIT,
  VIEW,
}

type EditType = {
  onRemove?: () => Promise<void>;
  enableSave: boolean;
  title?:
    | string
    | {
        [locale: string]: {
          title: string;
        };
      };
  id?: string;
  subTitle?: string;
  error?: string | null;
  hasValidationErrors?: boolean;
  editType: FormEditType;
  loading: boolean;
  onSubmit?: () => void;
  recordIdentifier?: string;
  backRoutePath?: string;
  successMessage?: string;
  successMessageTitle?: string;
  deleteMessageTitle?: string;
  deleteMessage?: string;
  deepLink?: DeepLinkProps;
  additionalButtons?: JSX.Element[];
  textToOverrideSave?: string;
  deleteButtonText?: string;
  deleteConfirmationMessage?: string;
  renderButtonsOnFooter?: boolean;
  localeSupported?: boolean;
  errors?: object;
  hideBackButton?: boolean;
};

type PropsWithChildren<T> = T & {
  children?: ReactNode | ((language: Locale) => ReactNode);
};

export const FormEdit: React.FC<PropsWithChildren<EditType>> = ({
  enableSave,
  onRemove,
  children,
  error: FormError,
  title: titleProp,
  editType,
  id,
  loading,
  onSubmit,
  subTitle,
  recordIdentifier,
  backRoutePath,
  successMessage,
  successMessageTitle,
  deleteMessageTitle,
  deleteMessage,
  deepLink,
  additionalButtons,
  textToOverrideSave,
  deleteButtonText,
  deleteConfirmationMessage,
  renderButtonsOnFooter = false,
  localeSupported,
  errors,
  hideBackButton,
}) => {
  const { currentCountry, primaryLocale } = useCountry();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [language, setLanguage] = useState(primaryLocale?.key);

  useEffect(() => {
    if (primaryLocale?.key) {
      setLanguage(primaryLocale?.key);
    }
  }, [primaryLocale?.key]);

  const formErrors = useMemo(() => {
    const errMessages: string[] = [];
    if (!errors) {
      return errMessages;
    }
    for (const [, errorValue] of Object.entries(errors)) {
      const fields = errorValue as FieldError;
      if (fields && fields.message) {
        errMessages.push(fields.message.toString());
      } else {
        for (const [, fieldValue] of Object.entries(fields)) {
          if (fieldValue) {
            for (const [, nestedValue] of Object.entries(fieldValue)) {
              if (nestedValue && nestedValue.message) {
                errMessages.push(nestedValue.message.toString());
              } else {
                // for nested values that have an additional nesting level
                for (const [, innerValue] of Object.entries<any>(nestedValue)) {
                  if (innerValue && innerValue.message) {
                    errMessages.push(innerValue.message.toString());
                  } else {
                    for (const [, nestedValue] of Object.entries<any>(
                      innerValue
                    )) {
                      if (nestedValue && nestedValue.message) {
                        errMessages.push(nestedValue.message.toString());
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return errMessages;
  }, [errors]);

  const onFinish = async () => {
    setSaving(true);
    setError(null);
    try {
      await onSubmit!();
      if (isEmpty(errors)) {
        BJNotification({
          type: NotificationType.Success,
          message: successMessageTitle ?? "Update record",
          description:
            successMessage ?? `${recordIdentifier ?? "Record"} updated`,
        });
      }
      setSaving(false);
    } catch (err) {
      logException(err);
      const serverErrorMessage = generateFormError(err);
      setError(serverErrorMessage);
      BJNotification({
        type: NotificationType.Error,
        message: "Error",
        description: serverErrorMessage,
      });
    } finally {
      setSaving(false);
    }
  };

  const onDelete = async () => {
    try {
      setShowDeleteModal(false);
      setDeleting(true);
      if (onRemove) await onRemove();
      BJNotification({
        type: NotificationType.Success,
        message: deleteMessageTitle ?? "Record deleted",
        description:
          deleteMessage ??
          `${recordIdentifier ?? "Record"} Deleted successfully`,
      });
      setDeleting(false);
    } catch (err) {
      logException(err);
      const serverErrorMessage = generateFormError(err);
      setError(serverErrorMessage);
      BJNotification({
        type: NotificationType.Error,
        message: "Error deleting record",
        description: serverErrorMessage,
      });
    } finally {
      setDeleting(false);
    }
  };

  const onChangeLanguage = (language: string) => {
    setLanguage(language);
  };

  const title =
    typeof titleProp === "object" ? titleProp[language]?.title : titleProp;

  const renderButtons = () => (
    <Sticky
      justify={renderButtonsOnFooter ? "end" : "space-between"}
      align="middle"
    >
      {!renderButtonsOnFooter ? (
        !hideBackButton && <BackButton routePath={backRoutePath ?? `../`} />
      ) : (
        <Divider />
      )}

      {localeSupported && (
        <Radio.Group
          onChange={e => onChangeLanguage(e.target.value)}
          buttonStyle={"solid"}
          size={"middle"}
          value={language}
        >
          {currentCountry.locales
            .sort((a, b) => (a.primary === b.primary ? 0 : b.primary ? 1 : -1))
            .map(locale => {
              return (
                <Radio.Button key={locale?.key} value={locale?.key}>
                  {locale?.label}
                </Radio.Button>
              );
            })}
        </Radio.Group>
      )}

      <Space direction="horizontal">
        {editType === FormEditType.EDIT && onRemove && (
          <BJButton
            className="mr-2"
            buttonType={ButtonTypes.Delete}
            onClick={() => setShowDeleteModal(true)}
            size="large"
          >
            {deleting ? <Spin /> : deleteButtonText ?? "Delete"}
          </BJButton>
        )}
        <BJButton
          buttonType={ButtonTypes.Save}
          disabled={saving || !enableSave}
          loading={saving}
          size="large"
          htmlType="submit"
        >
          {textToOverrideSave ?? "Save"}
        </BJButton>
        {additionalButtons && [...additionalButtons]}
      </Space>
    </Sticky>
  );

  const renderErrors = () => {
    if (formErrors && formErrors?.length) {
      return (
        <BJFlex centerJustify>
          <WrappedCol>
            <Alert
              showIcon
              message="Error"
              description={formErrors.map((err, index) => (
                <div key={`error_alert_${index}`}>{err}</div>
              ))}
              type="error"
            />
          </WrappedCol>
        </BJFlex>
      );
    } else if (error || FormError) {
      return (
        <BJFlex centerJustify>
          <WrappedCol>
            <Alert showIcon message={error} type="error" />
            {!error && FormError && (
              <Alert showIcon message={FormError} type="error" />
            )}
          </WrappedCol>
        </BJFlex>
      );
    } else {
      return;
    }
  };

  return (
    <Layout>
      <ConfirmationModal
        show={showDeleteModal}
        text={deleteConfirmationMessage ?? commonErrors.onRemoveContentError}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={onDelete}
      />

      <Form name="Basic" layout="vertical" onFinish={onFinish}>
        {!renderButtonsOnFooter && renderButtons()}

        {loading || deleting ? (
          <StyledSpinnerContainer>
            <CenteredSpinner />
          </StyledSpinnerContainer>
        ) : (
          <>
            {!renderButtonsOnFooter && <Divider />}
            <Row>
              <Col span={24}>
                {title && (
                  <Typography.Title level={2}>{title}</Typography.Title>
                )}
                {id && <Text type="secondary">ID : {id}</Text>}
                {subTitle && <Text type="secondary">{subTitle}</Text>}
                {deepLink && deepLink.id ? <BJDeeplink {...deepLink} /> : null}
              </Col>
            </Row>
            {renderErrors()}
            {typeof children === "function"
              ? children(
                  currentCountry.locales.filter(
                    locale => locale.key === language
                  )[0]
                )
              : children}
            {renderButtonsOnFooter && renderButtons()}
          </>
        )}
      </Form>
    </Layout>
  );
};
//Navbar top
const Sticky = styled(Row)`
  position: sticky;
  top: 0;
  width: 100%;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  z-index: 2;
  border-radius: 25px;
`;

const WrappedCol = styled(Col)`
  padding: 1rem;
`;
const StyledSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
`;
