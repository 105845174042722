import { useEffect, useState } from "react";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";
import { Col, List, Row, Space, Tooltip } from "antd";
import { BiBookAlt, BiMessageRoundedDetail } from "react-icons/bi";
import { FaGripLines, FaHeadphonesAlt } from "react-icons/fa";
import { ImBlocked } from "react-icons/im";
import { MdRemoveCircle } from "react-icons/md";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import styled from "styled-components";
import Link from "antd/lib/typography/Link";
import { CommonContentType } from "../../utils";
import { Dietary, FAQ, Offers } from "../../assets/icons/icons";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { arrayMove } from "../../utils/moveArrayItems";
import { BJSortableList } from "../../components/theme/molecules/BJSortableList";
import { useCountry } from "../../context";
import { VscTools } from "react-icons/vsc";
interface Props {
  onSave: (content: CommonContentV2[]) => void;
  onClose: () => void;
  contentProp: CommonContentV2[];
}

export const ContentOrderList = ({ onSave, onClose, contentProp }: Props) => {
  const [content, setContent] = useState<CommonContentV2[]>([]);
  useEffect(() => {
    setContent(contentProp);
  }, [contentProp]);

  return (
    <>
      <Row justify={"end"}>
        <Space>
          <BJButton
            buttonType={ButtonTypes.Delete}
            onClick={() => {
              onClose();
            }}
          >
            Back
          </BJButton>
          <BJButton
            buttonType={ButtonTypes.Save}
            onClick={() => {
              onSave(content);
              onClose();
            }}
          >
            Change
          </BJButton>
        </Space>
      </Row>
      <BJSortableList
        items={content}
        render={content => (
          <ContentListItem
            key={content.id}
            value={content}
            sortable={true}
            removable={false}
          />
        )}
        onSortEnd={({ oldIndex, newIndex }) => {
          if (oldIndex === newIndex) {
            return;
          }
          const sortedContents = arrayMove([...content], oldIndex, newIndex);
          setContent(
            sortedContents.map((content, index) => ({
              ...content,
              sortOrder: index,
            }))
          );
        }}
      />
    </>
  );
};

interface ContentListItemProps {
  value: CommonContentV2;
  sortable?: boolean;
  removable: boolean;
  onPressRemove?: (content: CommonContentV2) => void;
}

const getIcon = () => {
  const iconsByContent = {
    article: <BiBookAlt size={20} />,
    blogPost: <BiMessageRoundedDetail size={20} />,
    faq: <FAQ />,
    offer: <Offers />,
    dietaryAdvice: <Dietary />,
    contentCollection: <HiOutlineDocumentDuplicate />,
    podcastEpisode: <FaHeadphonesAlt />,
    tool: <VscTools />,
  };
  return iconsByContent;
};

export const ContentListItem = ({
  value,
  sortable = false,
  removable,
  onPressRemove,
}: ContentListItemProps) => {
  const { primaryLocale } = useCountry();
  const record = {
    title: value.translations?.[primaryLocale?.key]?.title,
    name: value.translations?.[primaryLocale?.key]?.name,
    question: value?.translations?.[primaryLocale?.key]?.question,
    type: value?.type,
    restricted: value?.restricted,
    key: value?.id,
    icon: getIcon()[value?.type],
  };

  return (
    <StyledListItem sortable={sortable} key={record.key}>
      {sortable && (
        <Col>
          <FaGripLines />
        </Col>
      )}
      <Col span={12}>{record?.question || record?.name || record?.title}</Col>
      <Col span={8}>{record.type}</Col>
      <Col span={1}>
        <Tooltip placement="topLeft" title={record.type}>
          {record.icon}
        </Tooltip>
      </Col>
      <Col span={1}>
        {record.restricted && (
          <Tooltip placement="topLeft" title={"Restricted content"}>
            <ImBlocked color={"red"} />
          </Tooltip>
        )}
      </Col>

      {removable && (
        <Col>
          <Link onClick={() => onPressRemove(value)}>
            <Tooltip placement="topLeft" title={"Remove content"}>
              <MdRemoveCircle color={"red"} />
            </Tooltip>
          </Link>
        </Col>
      )}
    </StyledListItem>
  );
};

export const SortableItem = SortableElement(
  ({ value }: { value: CommonContentV2 }) => (
    <ContentListItem value={value} sortable={true} removable={false} />
  )
);

const SortableList = SortableContainer(
  ({ items }: { items: CommonContentV2[] }) => (
    <StyledList>
      {items.map((item, index) => (
        <SortableItem key={item.id} index={index} value={item} />
      ))}
    </StyledList>
  )
);

const StyledList = styled(List)`
  margin-top: "1rem";
`;
const StyledListItem = styled(List.Item)<{
  sortable: boolean;
}>`
  cursor: ${props => (props.sortable ? "move" : "cursor")};
  size: 30;
  display: flex;
  flex: 1;
`;
