import { remoteConfig } from "../firebase";

import { logException } from "../utils/exceptionLogger";

export enum CONFIG_KEYS {
  CIO_NOTIFICATION_TOPICS = "CIO_NOTIFICATION_TOPICS",
}

export const REMOTECONFIG_EXPIRATION_DEV = 60;
export const REMOTECONFIG_EXPIRATION_DEFAULT = 3600;

export const initRemoteConfig = async () => {
  try {
    await remoteConfig.fetch();
    await remoteConfig.activate();
  } catch (error) {
    logException(error);
    console.error("Error during remote config setup: ", error);
  }
};

export const getRemoteConfig = async (key: CONFIG_KEYS) => {
  try {
    await initRemoteConfig();

    const config = remoteConfig.getValue(key);
    const result = JSON.parse(config.asString());

    return result;
  } catch (error) {
    logException(error);
    console.error("Error during parsing of data from remote config: ", error);

    return [];
  }
};
