import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ChildBlogPostService } from "../services";
import firebase from "../firebase";
import { useCountry } from "./CountryContext";

type ContextState = {
  blogPosts: ChildBlogPost[];
  loading: boolean;
  error: Error | null;
  updateChildBlogPost: (data: BlogPost) => Promise<void>;
  createChildBlogPost: (
    data: BlogPost
  ) => Promise<firebase.firestore.DocumentReference | null>;
  deleteChildBlogPost: (id: string) => Promise<void>;
  writeChildBlogPost: (data: BlogPost) => Promise<string>;
};

const ChildBlogPostContext = createContext<ContextState>({
  blogPosts: [],
  loading: false,
  error: null,
  createChildBlogPost: async () => null,
  updateChildBlogPost: async () => null,
  deleteChildBlogPost: async () => null,
  writeChildBlogPost: async () => null,
});

export const ChildBlogPostsProvider = ({ ...rest }) => {
  const [blogPosts, setBlogPosts] = useState<ChildBlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const childBlogPostService = useMemo(
    () => new ChildBlogPostService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    setLoading(true);

    const unsub = childBlogPostService.subscribe((_error, _blogPosts) => {
      setBlogPosts(_blogPosts);
      setError(_error);
      setLoading(false);
    });

    return unsub;
  }, [childBlogPostService]);

  const updateChildBlogPost = useCallback(
    async (data: BlogPost) => await childBlogPostService.update(data),
    [childBlogPostService]
  );

  const createChildBlogPost = useCallback(
    async (data: BlogPost) => await childBlogPostService.create(data),
    [childBlogPostService]
  );

  const writeChildBlogPost = useCallback(
    async (data: BlogPost) => await childBlogPostService.write(data),
    [childBlogPostService]
  );

  const deleteChildBlogPost = useCallback(
    async (id: string) => await childBlogPostService.delete(id),
    [childBlogPostService]
  );

  const value = useMemo(
    () => ({
      blogPosts,
      loading,
      error,
      createChildBlogPost,
      updateChildBlogPost,
      deleteChildBlogPost,
      writeChildBlogPost,
    }),
    [
      blogPosts,
      loading,
      error,
      createChildBlogPost,
      updateChildBlogPost,
      deleteChildBlogPost,
      writeChildBlogPost,
    ]
  );

  return <ChildBlogPostContext.Provider value={value} {...rest} />;
};

export const useChildBlogPosts = () => {
  const context = React.useContext(ChildBlogPostContext);
  if (context === undefined) {
    throw new Error(
      "useChildBlogPosts must be used within an ChildBlogPostsProvider"
    );
  }
  return context;
};

export const useChildBlogPost = (blogPostId: BlogPost["id"]) => {
  const { blogPosts, loading } = useChildBlogPosts();

  const [blogPost, setBlogPost] = useState<ChildBlogPost | null>(null);

  useEffect(() => {
    const _blogPost = blogPosts.find(p => p.id === blogPostId);
    if (_blogPost !== undefined) {
      setBlogPost(_blogPost);
    }
  }, [blogPosts, blogPostId]);

  return { blogPost, loading };
};
