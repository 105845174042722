import { useEffect } from "react";
import { commonErrors } from "../../language";
import { useForm } from "react-hook-form";
import { FormModal } from "../../components";
import { formValidationError } from "../../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BJInputFormItem } from "../../components/theme";

interface Props {
  locale: Locale;
  show: boolean;
  task: Item | null;
  onHide: () => void;
  onUpdate: (task: Item, locale: Locale) => void;
  onDelete: (id: Item["sortOrder"], locale: Locale) => void;
  onAdd: (task: Item, locale: Locale) => void;
}

const schema = yup.object().shape({
  title: yup.string().required(commonErrors.requiredError),
});

export const UpdateInfoBoxItemModal = ({
  locale,
  show,
  task,
  onHide,
  onUpdate,
  onDelete: onDeleteTask,
  onAdd,
}: Props) => {
  const {
    handleSubmit,
    control,
    formState,
    reset,
    formState: { errors },
  } = useForm<Item>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (task !== null) {
      reset({
        title: task.title,
      });
    } else {
      reset({ title: "" });
    }
  }, [reset, show, task]);

  const onSubmit = async (data: Item) => {
    if (task !== null) {
      const updated = {
        ...task,
        title: data.title,
      };
      onUpdate(updated, locale);
    } else {
      const task: Item = {
        title: data.title,
        sortOrder: 999,
      };
      onAdd(task, locale);
    }
  };

  const onDelete = async () => {
    if (task !== null) {
      onDeleteTask(task?.sortOrder, locale);
    }
  };

  const isDirty = !!Object.keys(formState.dirtyFields).length;

  return (
    <FormModal
      onHide={onHide}
      enableSave={isDirty}
      show={show}
      messageOnSubmit={false}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      error={null}
      onDelete={onDelete}
      enableDelete={!!task}
      modalSubTitle={task ? `Id - ${task?.sortOrder}` : ""}
      modalTitle={task ? task.title : "Update task"}
    >
      <BJInputFormItem
        control={control}
        error={!!errors?.title}
        label={"Feature"}
        message={errors.title?.message}
        required={true}
        autoFocus
        fieldName={"title"}
      />
    </FormModal>
  );
};
