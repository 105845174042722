import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { PollService } from "../services";
import { useCountry } from "./CountryContext";

type ContextState = {
  polls: Poll[];
  loading: boolean;
  error: Error | null;
  getById: (id: Poll["id"]) => Poll | null;
  pollService: PollService | null;
};

const PollsContext = createContext<ContextState>({
  polls: [],
  loading: false,
  error: null,
  getById: () => null,
  pollService: null,
});

export const PollsProvider = ({ ...rest }) => {
  const [polls, setPolls] = useState<Poll[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const pollService = useMemo(() => {
    return currentCountry?.abb ? new PollService(currentCountry.abb) : null;
  }, [currentCountry]);

  useEffect(() => {
    if (!pollService) {
      return;
    }
    const unSubscribePoll = pollService.subscribe((_error, _polls) => {
      setPolls(_polls);
      setError(_error);
      setLoading(false);
    });

    return () => {
      unSubscribePoll();
    };
  }, [pollService]);

  const getById = useCallback(
    (id: Poll["id"]) => {
      const _poll = polls.find(a => a.id === id);
      return _poll || null;
    },
    [polls]
  );

  const value = useMemo(
    () => ({
      polls,
      loading,
      error,
      getById,
      pollService,
    }),
    [polls, loading, error, getById, pollService]
  );

  return <PollsContext.Provider value={value} {...rest} />;
};

export const usePolls = () => {
  const context = useContext(PollsContext);
  if (context === undefined) {
    throw new Error("usePolls must be used within an PollsProvider");
  }
  return context;
};

export const usePoll = (id: Poll["id"]) => {
  const [poll, setPoll] = useState<Poll | null>(null);
  const [loading, setLoading] = useState(true);

  const { getById, loading: loadingPolls, pollService } = usePolls();

  useEffect(() => {
    if (loadingPolls) {
      return;
    }

    setPoll(getById(id));
    setLoading(false);
  }, [getById, id, loadingPolls]);

  return { poll, loading, pollService };
};
