import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { formValidationError, isEqualArrays } from "../../utils";
import { CheckboxButton } from "../../components";
import { FormModal } from "../../components/FormModal";
import { useBlogCategories, useCountry } from "../../context";
import { InfluencerService } from "../../services";
import { commonErrors, influencerModalMessage } from "../../language";
import { Form, Row, Typography } from "antd";
import { BJInputFormItem } from "../../components/theme";
import Checkbox from "antd/lib/checkbox/Checkbox";

interface Props {
  show: boolean;
  influencer: Influencer | null;
  onHide: () => void;
}

type FormValues = {
  name: string;
  selectedCategories: Array<string>;
};

export const InfluencerModal = ({ show, influencer, onHide }: Props) => {
  const { blogCategories } = useBlogCategories();
  const { currentCountry, primaryLocale } = useCountry();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [deleteBlogPosts, setDeleteBlogPosts] = useState(true);
  const [deleteBirthStories, setDeleteStories] = useState(true);
  const [deleteChildBlogPosts, setDeleteChildBlogPosts] = useState(true);
  const [deleteGeneralBlogPosts, setDeleteGeneralBlogPosts] = useState(true);
  const [deleteFromUsers, setDeleteFromUsers] = useState(true);

  const schema = yup.object().shape({
    name: yup.string().required(`Name: ${commonErrors.requiredError2}`),
    selectedCategories: yup
      .array()
      .min(1, `Categories: ${commonErrors.selectAtleastOneItem}`),
  });

  const influencerService = useMemo(() => {
    return currentCountry?.abb
      ? new InfluencerService(currentCountry?.abb)
      : null;
  }, [currentCountry]);

  const {
    handleSubmit,
    reset,
    setValue,
    formState,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!influencer) {
      reset({ name: "" });
      setSelectedCategories([]);
    } else {
      reset({ name: influencer?.name });
      setSelectedCategories(influencer?.categories);
    }
  }, [reset, influencer, show]);

  useEffect(() => {
    setValue("selectedCategories", selectedCategories);
  }, [selectedCategories, setValue]);

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const selectedCategoriesKeys = selectedCategories;
    if (selectedCategoriesKeys.length < 1) {
      throw Error(influencerModalMessage.categoryError);
    }

    if (influencer) {
      await influencerService.update(influencer!.id, {
        name: data.name,
        categories: selectedCategoriesKeys,
      });
    } else {
      await influencerService.create({
        name: data.name,
        categories: selectedCategoriesKeys,
      });
    }
  };

  const onDelete = async () => {
    if (influencer) {
      await influencerService.delete({
        isDeleteBlogPosts: deleteBlogPosts,
        isDeleteBirthStories: deleteBirthStories,
        isDeleteChildBlogPosts: deleteChildBlogPosts,
        isDeleteGeneralBlogPosts: deleteGeneralBlogPosts,
        idDeleteFromUserColl: deleteFromUsers,
        influencerId: influencer.id,
        countryCode: currentCountry?.abb,
      });
    } else {
      throw Error("Record not found");
    }
  };

  const onToggleCategory = (category: BlogCategory) => {
    let categories = [...selectedCategories];
    if (categories.includes(category.id)) {
      categories = categories.filter(x => x !== category.id);
    } else {
      categories.push(category.id);
    }
    setSelectedCategories(categories);
  };

  const isDirty =
    !!Object.keys(formState.dirtyFields).length ||
    !isEqualArrays(
      influencer?.categories || [],
      Array.from(selectedCategories.values())
    );
  const { name } = watch();

  return (
    <FormModal
      onHide={onHide}
      enableSave={isDirty}
      show={show}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      onDelete={onDelete}
      enableDelete={!!influencer}
      modalSubTitle={influencer ? `Id - ${influencer?.id}` : ""}
      modalTitle={influencer ? "Update influencer" : "Add influencer"}
      recordIdentifier={name}
      deleteOptions={
        <Row justify="center">
          <Checkbox
            checked={deleteBlogPosts}
            onChange={() => setDeleteBlogPosts(!deleteBlogPosts)}
          >
            Blog posts
          </Checkbox>
          <Checkbox
            checked={deleteBirthStories}
            onChange={() => setDeleteStories(!deleteBirthStories)}
          >
            Birth Stories
          </Checkbox>
          <Checkbox
            checked={deleteChildBlogPosts}
            onChange={() => setDeleteChildBlogPosts(!deleteChildBlogPosts)}
          >
            Child blog posts
          </Checkbox>
          <Checkbox
            checked={deleteGeneralBlogPosts}
            onChange={() => setDeleteGeneralBlogPosts(!deleteGeneralBlogPosts)}
          >
            General blog posts
          </Checkbox>
          <Checkbox
            checked={deleteFromUsers}
            onChange={() => setDeleteFromUsers(!deleteFromUsers)}
          >
            Delete from user`s list followed influencers
          </Checkbox>
        </Row>
      }
      errors={errors as any}
    >
      <BJInputFormItem
        label={"Name"}
        fieldName={"name"}
        key={"name"}
        control={control}
        error={!!errors?.name}
        message={errors?.name?.message}
        required
      />
      <Form.Item
        required
        label="Categories"
        validateStatus={errors.selectedCategories && "error"}
        extra={
          <Typography.Paragraph type="danger">
            {(errors.selectedCategories as any)?.message}
          </Typography.Paragraph>
        }
      >
        <CategoriesGrid>
          {blogCategories.map(category => (
            <CheckboxButton
              key={category.id}
              label={category.translations[primaryLocale.key]}
              checked={selectedCategories.some(x => x === category.id)}
              onChange={() => onToggleCategory(category)}
            />
          ))}
        </CategoriesGrid>
      </Form.Item>
    </FormModal>
  );
};

const CategoriesGrid = styled.div`
  display: grid;
  grid-row-gap: 0.75rem;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
`;
