import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import firebase from "../firebase";
import { useCountry } from "./CountryContext";
import BlogCategoriesService from "../services/BlogCategoriesService";

type ContextState = {
  blogCategories: BlogCategory[];
  blogCategoriesMap: Map<BlogCategory["id"], BlogCategory>;
  loading: boolean;
  error: Error | null;
  createBlogPostCategory: (
    data: Omit<BlogCategory, "id">
  ) => Promise<firebase.firestore.DocumentReference | null>;
  updateBlogPostCategory: (data: BlogCategory) => Promise<void>;
  deleteBlogPostCategory: (id: string) => Promise<void>;
};

const BlogCategoriesContext = createContext<ContextState>({
  blogCategories: [],
  blogCategoriesMap: new Map(),
  loading: false,
  error: null,
  createBlogPostCategory: async () => null,
  updateBlogPostCategory: async () => null,
  deleteBlogPostCategory: async () => null,
});

export const BlogCategoriesProvider = ({ ...rest }) => {
  const [blogCategories, setBlogCategories] = useState<BlogCategory[]>([]);
  const [blogCategoriesMap, setBlogCategoriesMap] = useState<
    Map<BlogCategory["id"], BlogCategory>
  >(new Map());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const blogCategoriesService = useMemo(
    () =>
      currentCountry?.abb
        ? new BlogCategoriesService(currentCountry.abb)
        : null,
    [currentCountry?.abb]
  );

  useEffect(() => {
    if (!blogCategoriesService) {
      return;
    }
    setLoading(true);

    const unsub = blogCategoriesService.subscribe((error, categories) => {
      setBlogCategories(categories);
      const categoriesMap: Map<BlogCategory["id"], BlogCategory> = new Map();
      categories.forEach((category: any) =>
        categoriesMap.set(category.id, category)
      );

      setBlogCategoriesMap(categoriesMap);
      setError(error);
      setLoading(false);
    });

    return () => unsub();
  }, [blogCategoriesService]);

  const createBlogPostCategory = useCallback(
    (data: Omit<BlogCategory, "id">) => {
      if (!blogCategoriesService) {
        return null;
      }
      return blogCategoriesService.create(data);
    },
    [blogCategoriesService]
  );

  const updateBlogPostCategory = useCallback(
    (data: BlogCategory) => {
      if (!blogCategoriesService) {
        return null;
      }
      return blogCategoriesService.update(data);
    },
    [blogCategoriesService]
  );

  const deleteBlogPostCategory = useCallback(
    (id: string) => {
      if (!blogCategoriesService) {
        return null;
      }
      return blogCategoriesService.delete(id);
    },
    [blogCategoriesService]
  );

  const value = useMemo(
    () => ({
      blogCategories,
      blogCategoriesMap,
      loading,
      error,
      createBlogPostCategory,
      updateBlogPostCategory,
      deleteBlogPostCategory,
    }),
    [
      blogCategories,
      blogCategoriesMap,
      loading,
      error,
      createBlogPostCategory,
      updateBlogPostCategory,
      deleteBlogPostCategory,
    ]
  );

  return <BlogCategoriesContext.Provider value={value} {...rest} />;
};

export const useBlogCategories = () => {
  const context = React.useContext(BlogCategoriesContext);
  if (context === undefined) {
    throw new Error(
      "useBlogCategories must be used within an BlogCategoriesProvider"
    );
  }
  return context;
};
