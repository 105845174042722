import firebase, { firestore } from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";
import { uploadImage } from "./serviceUtils";
import { DocumentData, QueryDocumentSnapshot } from "@firebase/firestore-types";

const contentMenuFileStoragePath = "contentMenu";

export class ContentMenuService extends CountryCodeService {
  constructor(locale: string) {
    super(locale);
  }

  toContentMenu = (doc: QueryDocumentSnapshot<DocumentData>): ContentMenu => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      translations: data?.translations ?? {},
    };
  };

  subscribe = (
    callback: (err: Error | null, contentMenus: ContentMenu[]) => void
  ) =>
    firestore
      .collection(CollectionPaths.ContentMenu[this.countryCode])
      .onSnapshot(
        snapshot => {
          const contentMenu = snapshot.docs.map(item => {
            return this.toContentMenu(item);
          });

          callback(null, contentMenu);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  create = (data: Partial<ContentMenu>) =>
    firestore
      .collection(CollectionPaths.ContentMenu[this.countryCode])
      .add(data);

  update = (id: string, data: Partial<ContentMenu>) => {
    return firestore
      .collection(CollectionPaths.ContentMenu[this.countryCode])
      .doc(id)
      .update(data);
  };

  updateContentMenuItems = (id: string, data: ContentMenu) => {
    return firestore
      .collection(CollectionPaths.ContentMenu[this.countryCode])
      .doc(id)
      .update(data);
  };

  delete = (id: string) =>
    firestore
      .collection(CollectionPaths.ContentMenu[this.countryCode])
      .doc(id)
      .delete();

  uploadContentMenuImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => {
    const url = uploadImage(file, contentMenuFileStoragePath, fileName);
    return url;
  };

  updateSortOrder = (contentMenus: ContentMenu[]) => {
    const batch = firebase.firestore().batch();
    contentMenus.forEach(contentMenu => {
      const ref = firestore
        .collection(CollectionPaths.ContentMenu[this.countryCode])
        .doc(contentMenu.id);
      batch.update(ref, { sortOrder: contentMenu.sortOrder, priority: "" });
    });
    return batch.commit();
  };
}
