import { Divider, Tabs } from "antd";
import React, { SetStateAction } from "react";
import { BJSwitchFormItem } from "../../components/theme";
import { WeeksOrMonthsPicker } from "../../components";
import { Control, UseFormSetValue } from "react-hook-form";
import { FormValues } from "./types";
import { PickerDisplayType, PickerType } from "../../utils/timeUtils";

const { TabPane } = Tabs;

type Props = {
  control: Control<FormValues, object>;
  setValue: UseFormSetValue<FormValues>;
  displayOfferText?: string;
  pickerWeeks: PickerDisplayType[];
  pickerMonths: PickerDisplayType[];
  checkListChildOffer: timeElementType[];
  checkListPregnancyOffer: timeElementType[];
  setCheckListChildOffer: (value: SetStateAction<timeElementType[]>) => void;
  setCheckListPregnancyOffer: (
    value: SetStateAction<timeElementType[]>
  ) => void;
};

const OfferChecklist: React.FC<Props> = ({
  control,
  setValue,
  displayOfferText,
  pickerWeeks,
  pickerMonths,
  checkListChildOffer,
  checkListPregnancyOffer,
  setCheckListChildOffer,
  setCheckListPregnancyOffer,
}) => {
  return (
    <>
      <Divider orientation="left">Offer on Check list</Divider>
      <Tabs defaultActiveKey="1">
        <TabPane tab=" Pregnancy " key="1">
          <WeeksOrMonthsPicker
            render={() => (
              <BJSwitchFormItem
                horizontal
                control={control}
                label={displayOfferText}
                fieldName={"showCheckListPregnancyOffer"}
              />
            )}
            times={pickerWeeks}
            selectAllCaption={"Select all weeks"}
            pickerType={PickerType.weeks}
            selectedTimes={checkListPregnancyOffer ?? []}
            ignorePosition
            onChange={value => {
              setCheckListPregnancyOffer(value);
              setValue("dirty", true, { shouldDirty: true });
            }}
          />
        </TabPane>
        <TabPane tab="Child" key="2">
          <WeeksOrMonthsPicker
            times={pickerMonths}
            selectAllCaption={"Select all months"}
            render={() => (
              <BJSwitchFormItem
                horizontal
                control={control}
                label={displayOfferText}
                fieldName={"showCheckListChildOffer"}
              />
            )}
            pickerType={PickerType.months}
            ignorePosition
            selectedTimes={checkListChildOffer ?? []}
            onChange={value => {
              setCheckListChildOffer(value);
              setValue("dirty", true, { shouldDirty: true });
            }}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default OfferChecklist;
