import { useMemo, useState } from "react";
import { ChecklistService } from "../services";
import { useCountry } from "../context";

export const useChecklists = () => {
  const [isLoading, setIsLoading] = useState(false);
  const countryCode = useCountry()?.currentCountry?.abb;
  const checklistsService = useMemo(
    () => new ChecklistService(countryCode),
    [countryCode]
  );

  const uploadChecklistSponsorImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => {
    setIsLoading(true);
    const result = await checklistsService?.uploadChecklistSponsorImage(
      file,
      fileName
    );
    setIsLoading(false);
    return result;
  };

  const uploadChecklistTaskImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => {
    setIsLoading(true);
    const result = await checklistsService?.uploadChecklistTaskImage(
      file,
      fileName
    );
    setIsLoading(false);
    return result;
  };

  return {
    uploadChecklistTaskImage,
    uploadChecklistSponsorImage,
    isLoading,
  };
};
