import { DatePicker, DatePickerProps, Form, Typography } from "antd";
import { Control, Controller, Path } from "react-hook-form";
import { BJFormLabelInfo } from "../../atoms/BJInfo";
import moment from "moment";

export const BJFormDatePicker = <T,>({
  extra,
  showInfo,
  error,
  message,
  label,
  required,
  fieldName,
  control,
  ...props
}: {
  extra?: string;
  showInfo?: boolean;
  error: boolean;
  message: string;
  label: string;
  required?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  control: Control<T>;
  fieldName: Path<T>;
} & DatePickerProps) => {
  return (
    <Form.Item
      label={showInfo ? <BJFormLabelInfo info={extra} label={label} /> : label}
      required={required}
      validateStatus={error && "error"}
      {...(message
        ? {
            help: (
              <Typography.Paragraph type="danger">
                {message}
              </Typography.Paragraph>
            ),
          }
        : undefined)}
      {...(extra && !showInfo
        ? {
            extra: (
              <Typography.Paragraph type="warning">
                {extra}
              </Typography.Paragraph>
            ) as JSX.Element,
          }
        : undefined)}
    >
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            value={value ? moment(value) : undefined}
            onChange={onChange}
            {...props}
          />
        )}
      />
    </Form.Item>
  );
};
