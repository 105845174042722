import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { CountryCodeService } from "./CountryCodeService";

type CreateCategoryData = {
  icon: string;
  show: boolean;
  sortOrder: number;
  template: string;
  translations: {
    [locale: string]: {
      title: string;
      description: string;
    };
  };
};

export class ArticleCategoryService extends CountryCodeService {
  constructor(locale: string) {
    super(locale);
  }

  create = (data: CreateCategoryData) =>
    firebase
      .firestore()
      .collection(CollectionPaths.ArticleCategories[this.countryCode])
      .add({ ...data });

  update = (data: ArticleCategory) =>
    firebase
      .firestore()
      .collection(CollectionPaths.ArticleCategories[this.countryCode])
      .doc(data.id)
      .update({ ...data });

  delete = (id: ArticleCategory["id"]) =>
    firebase
      .firestore()
      .collection(CollectionPaths.ArticleCategories[this.countryCode])
      .doc(id)
      .delete();
}
