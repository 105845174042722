import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCompetitions, useCountry } from "../../context";
import { FormEdit, FormEditType } from "../../components/FormEdit";
import {
  BJSortableList,
  SortContentType,
} from "../../components/theme/molecules/BJSortableList";
import { arrayMove } from "../../utils/moveArrayItems";

type SortProps = {
  oldIndex: number;
  newIndex: number;
};

export const ChangeCompetitionList = () => {
  const {
    competitions: allCompetitions,
    loading: competitionsLoading,
    updateSortOrder,
  } = useCompetitions();
  const { handleSubmit } = useForm();
  const [isDirty, setIsDirty] = useState(false);
  const [competitions, setCompetitions] = useState<Competition[]>([]);
  const { primaryLocale } = useCountry();

  useEffect(() => {
    setCompetitions(
      allCompetitions
        .sort((a: Competition, b: Competition) => a.sortOrder - b.sortOrder)
        .map((competitions, index) => ({ ...competitions, sortOrder: index }))
    );
  }, [allCompetitions]);

  const saveOrder = async () => {
    await updateSortOrder(
      competitions.map((competitions, index) => ({
        ...competitions,
        sortOrder: index,
      }))
    );
    setIsDirty(false);
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortProps) => {
    if (oldIndex == newIndex) {
      return;
    }
    const sortableCompetitions = arrayMove(
      [...competitions],
      oldIndex,
      newIndex
    );
    setCompetitions(sortableCompetitions);
    setIsDirty(true);
  };

  return (
    <FormEdit
      enableSave={isDirty}
      title={"Change Competition order"}
      editType={FormEditType.VIEW}
      loading={competitionsLoading}
      onSubmit={handleSubmit(saveOrder)}
    >
      <BJSortableList
        items={competitions.map(
          competitions =>
            ({
              id: competitions.id,
              title: competitions?.translations?.[primaryLocale.key]?.title,
              noImage: true,
            } as SortContentType)
        )}
        onSortEnd={onSortEnd}
      />
    </FormEdit>
  );
};
