import { ContentService } from "../../services/ContentService";
import { FormEdit, FormEditType } from "../../components";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Col, Form, Row, Typography } from "antd";
import BJInput from "../../components/theme/atoms/BJInput";
import { useNavigate } from "react-router-dom";
import { commonErrors } from "../../language";
import { formValidationError } from "../../utils";

const { requiredError } = commonErrors;
const schema = yup.object().shape({
  contentTitleName: yup.string().required(requiredError),
});

type FormValues = {
  contentTitleName: string;
};

export const AddNewContent = () => {
  const navigate = useNavigate();

  const {
    formState,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data: FormValues) => {
    const contentId = await ContentService.addNewContent(data.contentTitleName);
    navigate(`../${contentId}`);
  };
  const isDirty = !!Object.keys(formState.dirtyFields).length;
  const { contentTitleName } = watch();
  return (
    <FormEdit
      enableSave={isDirty}
      title={"New Content"}
      editType={FormEditType.ADD}
      loading={false}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      recordIdentifier={contentTitleName}
    >
      <Row gutter={{ md: 20 }}>
        <Col md={24} lg={12}>
          <Form.Item
            label="Name"
            validateStatus={errors.contentTitleName && "error"}
            extra={
              <Typography.Paragraph type="danger">
                {errors.contentTitleName?.message}
              </Typography.Paragraph>
            }
            required
          >
            <Controller
              control={control}
              name="contentTitleName"
              render={({ field: { name, value, onChange } }) => (
                <BJInput
                  name={name}
                  value={value ?? undefined}
                  onChange={onChange}
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
    </FormEdit>
  );
};
