import { useEffect, useState } from "react";
import { useArticles } from "../../../context";
import { ArticleCategoryModal } from "./ArticleCategoryModal";
import BJList from "../../../components/theme/components/BJList";
import { BJColumnsType } from "../../../components/theme/molecules/BJTable";
import { Sorter } from "../../../components/theme/util/sorter";
import { Switch } from "antd";
import { DeepLinkForList } from "../../../components/theme";
import { DeepLinkType } from "../../../utils";
import { useCountry } from "../../../context";
import { capitalizeFirstLetter } from "../../../utils";

interface datasourceType extends ArticleCategory {
  deepLink?: DeepLinkProps;
  [titleWithLocale: string]:
    | string
    | undefined
    | DeepLinkProps
    | ArticleCategory[keyof ArticleCategory];
}

export const ArticleCategoryList = () => {
  const { categories } = useArticles();
  const { currentCountry } = useCountry();
  const [originalTable, setOriginalTable] = useState<datasourceType[]>([]);

  useEffect(() => {
    const categoryTableSource: datasourceType[] = categories.map(category => ({
      ...category,
      ...Object.entries(category.translations).reduce(
        (acc, [locale, values]) => (
          (acc[`title${capitalizeFirstLetter(locale)}`] = values.title), acc
        ),
        {} as any
      ),
      key: category.id,
      deepLink: { type: DeepLinkType.ArticleCategories, id: category?.id },
    }));
    setOriginalTable(categoryTableSource);
  }, [categories]);

  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] =
    useState<ArticleCategory | null>(null);

  const columns: BJColumnsType<datasourceType> = [
    ...currentCountry?.locales?.map(locale => ({
      title: `Name (${locale?.label})`,
      dataIndex: `title${capitalizeFirstLetter(locale?.key)}`,
      key: `title${capitalizeFirstLetter(locale?.key)}`,
      width: 1,
      ellipsis: true,
      onFilter: (value: string | number | boolean, record: datasourceType) =>
        record.id.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    })),
    {
      title: "Restricted",
      dataIndex: "restricted",
      key: "restricted",
      width: 0.5,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (value: boolean) => (
        <Switch checked={value} size="small" disabled />
      ),
    },
    {
      title: "Visible",
      dataIndex: "show",
      key: "show",
      width: 0.5,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (value: boolean) => (
        <Switch checked={value} size="small" disabled />
      ),
    },
    DeepLinkForList(),
  ];

  const onClickRow = (record: datasourceType) => {
    return {
      onClick: () => {
        setSelectedCategory(record);
      },
    };
  };

  useEffect(() => {
    if (selectedCategory) {
      setShowAddCategoryModal(true);
    }
  }, [selectedCategory]);

  return (
    <>
      <BJList
        key={`verifierList-${currentCountry.abb}`}
        addButtonCaption={"New category"}
        title={"Categories"}
        OriginalList={originalTable}
        columns={columns}
        recordCountSuffix={"Categories"}
        onClickRow={onClickRow}
        onclick={() => {
          setShowAddCategoryModal(true);
        }}
      />
      <ArticleCategoryModal
        show={showAddCategoryModal}
        onHide={() => {
          setShowAddCategoryModal(false);
          setSelectedCategory(null);
        }}
        category={selectedCategory}
      />
    </>
  );
};
