import { useEffect, useState } from "react";
import { useCountry, useTools } from "../../../context/ToolsContext";
import { BJColumnType } from "../../../components/theme/molecules/BJTable";
import { useFilterContent } from "../../../hooks/contentCollections/useFilterContent";
import { CommonContentType, getFormattedErrorMessage } from "../../../utils";
import { BJSelectListModal } from "../../../components/theme/components/BJSelectListModal";

interface Props {
  show: boolean;
  excludedContentSelectionId?: string;
  maxNoOfContentLimit: number;
  onHide: () => void;
  selectedContent: CommonContent[];
  onAdd: (content: CommonContent[]) => void;
}
interface DatasourceType {
  id: string;
  title: string;
  description: string;
}

export const AddToolsModal = ({
  show: showModal,
  onHide,
  maxNoOfContentLimit,
  excludedContentSelectionId,
  selectedContent,
  onAdd,
}: Props) => {
  const { tools, loading } = useTools();
  const [dataSource, setDataSource] = useState<DatasourceType[]>([]);
  const { primaryLocale } = useCountry();

  useEffect(() => {
    if (loading) return;

    const allMenuItems = tools.flatMap(tool => tool.menuItems || []);

    const processedDataSource = allMenuItems.map(item => ({
      ...item,
      key: item.id,
      title: item.translations[primaryLocale?.key]?.title,
      description: item.translations[primaryLocale?.key]?.description,
    }));

    setDataSource(processedDataSource as DatasourceType[]);
  }, [tools, loading, excludedContentSelectionId, primaryLocale]);

  const {
    error,
    updatedContent,
    setError,
    selectedContentCollections,
    setSelectedContentCollections,
  } = useFilterContent<DatasourceType>(
    selectedContent,
    maxNoOfContentLimit,
    CommonContentType.TOOL,
    dataSource
  );

  const onSubmit = async () => {
    try {
      onAdd(updatedContent);
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
    }
  };

  const columns: BJColumnType<DatasourceType>[] = [
    {
      title: "Tool Name",
      dataIndex: "title",
      key: "name",
      width: 1,
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 2,
      ellipsis: true,
    },
  ];

  return (
    <BJSelectListModal
      recordCountSuffix={"Tools"}
      show={showModal}
      onHide={onHide}
      modalWidth={600}
      modalTitle={"Select Tools"}
      dataSource={dataSource}
      columns={columns}
      error={""}
      selectedRecords={selectedContentCollections}
      updateSelectedRecords={setSelectedContentCollections}
      disable={updatedContent.length > maxNoOfContentLimit}
      maxNoOfContentLimit={maxNoOfContentLimit}
      onSubmit={onSubmit}
    />
  );
};
