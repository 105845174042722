import { useState, useEffect, useCallback, useMemo } from "react";
import { FaGripLines } from "react-icons/fa";
import { Button, List, Spin, Row, Space } from "antd";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { PodcastSeasonsService } from "../../services";
import styled from "styled-components";
import { useCountry } from "../../context";

interface Props {
  initialSeasons: PodcastSeason[];
  podcastId: FullPodcast["id"];
  onAdd: () => void;
  onSelect: (episode: PodcastSeason) => void;
}

export const SeasonList = ({
  initialSeasons,
  podcastId,
  onAdd: onAddSeasons,
  onSelect,
}: Props) => {
  const [isDirty, setIsDirty] = useState(false);
  const [seasons, setSeasons] = useState<PodcastSeason[]>([]);
  const [isOrderMode, setIsOrderMode] = useState(false);
  const [saving, setSaving] = useState(false);

  const { primaryLocale, currentCountry } = useCountry();

  const podcastSeasonsService = useMemo(
    () => new PodcastSeasonsService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  const setSortedSeasons = useCallback(() => {
    const sortedSeasons = initialSeasons
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((t, i) => ({ ...t, sortOrder: i }));
    setSeasons(sortedSeasons);
  }, [initialSeasons]);

  useEffect(() => {
    setSortedSeasons();
  }, [setSortedSeasons]);

  const onSave = useCallback(async () => {
    setSaving(true);
    await podcastSeasonsService.updateSortOrders(podcastId, seasons);
    setIsDirty(false);
    setIsOrderMode(false);
    setSaving(false);
  }, [seasons, podcastId]);

  return (
    <>
      {isOrderMode ? (
        <>
          <div>
            <br />
            <h6>Change seasons order</h6>
          </div>

          <Row>
            <Space size="middle">
              <Button
                type="default"
                size="middle"
                onClick={() => {
                  setSortedSeasons();
                  setIsOrderMode(false);
                }}
                disabled={saving}
              >
                Cancel
              </Button>
              {isDirty ? (
                <Button type="primary" size="middle" onClick={onSave}>
                  {saving ? <Spin size="small" /> : "Save"}
                </Button>
              ) : (
                <Button disabled type="default" size="middle">
                  No changes
                </Button>
              )}
            </Space>
          </Row>
          <br />
          <SortableList
            items={seasons.sort((a, b) => a.sortOrder - b.sortOrder)}
            onSortEnd={({ oldIndex, newIndex }) => {
              if (oldIndex === newIndex) {
                return;
              }
              const _seasons = [...seasons];

              const sortOrder = _seasons[oldIndex].sortOrder;
              _seasons[oldIndex].sortOrder = _seasons[newIndex].sortOrder;
              _seasons[newIndex].sortOrder = sortOrder;

              setSeasons(_seasons);
              setIsDirty(true);
            }}
          />
        </>
      ) : (
        <>
          <div>
            <br />
            <h6>Seasons</h6>
            <Space size="middle">
              <Button type="primary" size="middle" onClick={onAddSeasons}>
                Add
              </Button>
              {seasons.length !== 0 ? (
                <Button size="middle" onClick={() => setIsOrderMode(true)}>
                  Change order
                </Button>
              ) : null}
            </Space>
          </div>
          <br />
          <ListBorder>
            <List>
              {seasons.map(season => (
                <StyledListItem
                  onClick={() => onSelect(season)}
                  key={season.id}
                >
                  {season.translations[primaryLocale?.key]?.title}
                </StyledListItem>
              ))}
            </List>
          </ListBorder>
        </>
      )}
    </>
  );
};

const SortableItem = SortableElement(({ value }: { value: PodcastSeason }) => {
  const { primaryLocale } = useCountry();
  return (
    <List.Item>
      <span>{value?.translations[primaryLocale?.key]?.title}</span>
      <FaGripLines />
    </List.Item>
  );
});

const SortableList = SortableContainer(
  ({ items }: { items: PodcastSeason[] }) => (
    <ListBorder>
      <List>
        {items.map((item, index) => (
          <SortableItem key={item.id} index={index} value={item} />
        ))}
      </List>
    </ListBorder>
  )
);
const ListBorder = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`;
const StyledListItem = styled(List.Item)`
  cursor: pointer;
`;
