import { yupResolver } from "@hookform/resolvers/yup";
import { Space } from "antd";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { FormEdit, FormEditType } from "../../components/FormEdit";
import { BJInputFormItem } from "../../components/theme/molecules/formItems/BJFormInput";
import { BJSelectFormItem } from "../../components/theme/molecules/formItems/BJFormSelectItem";
import {
  useRecommendedContent,
  useRecommendedContentByMonths,
  useRecommendedContentByWeeks,
  useRecommendedContents,
} from "../../context/RecommendedContext";
import { curatedPageMessages } from "../../language";
import {
  ContentListType,
  PopularType,
  formValidationError,
  getFormattedErrorMessage,
  getOrdinal,
  isCuratedContentLimitExceeded,
  isEqualArrays,
  monthValidationMaxValue,
  monthValidationMinValue,
  weekValidationMaxValue,
} from "../../utils";
import { monthContentLimit, weekContentLimit } from "../../utils/contentLimit";
import { ContentSelector } from "../Popular/ContentSelector";

type FormValues = {
  monthOrWeek: number;
  recommendedType: "week" | "month";
};

export const RecommendedPage = () => {
  const navigate = useNavigate();
  const { "*": path, id } = useParams<string>();
  const [, type] = path.match(/recommended\/(weeks|months)\/(.*)/) || [];
  const { onCreate, onUpdate, onDelete } = useRecommendedContents();
  const { recommendedContent, loading } = useRecommendedContent(id);
  const {
    recommendedContents: monthlyArticles,
    loading: loadingRecommendedMonths,
  } = useRecommendedContentByMonths();
  const {
    recommendedContents: weeklyArticles,
    loading: loadingRecommendedWeeks,
  } = useRecommendedContentByWeeks();

  const [error, setError] = useState<string | null>(null);
  const [isContentLimitExceeded, setIsContentLimitExceeded] = useState(false);

  const [managedContent, setManagedContent] = useState<PopularContent[]>([]);
  const schema = yup.object().shape({
    recommendedType: yup
      .string()
      .required(curatedPageMessages.requiredValidationText),
    monthOrWeek: yup
      .number()
      .min(monthValidationMinValue, curatedPageMessages.numberTypeErrorMessage)
      .required(curatedPageMessages.requiredValidationText)
      .typeError(curatedPageMessages.numberTypeErrorMessage)
      .test(
        "RecordNotInRange",
        curatedPageMessages.numberNotInRangeErrorMessage,
        function (value: number | undefined): boolean {
          const recommendedType = this.parent.recommendedType;
          const notInRange =
            recommendedType === PopularType.MONTH
              ? value! > monthValidationMaxValue
              : value! > weekValidationMaxValue;
          return !notInRange;
        }
      )
      .test(
        "recordAvailable",
        curatedPageMessages.recordAvailableError,
        function (value: number | undefined): boolean {
          const recommendedType = this.parent.recommendedType;
          if (recommendedContent !== null) return true;
          const notAvailable = !(recommendedType === PopularType.MONTH
            ? monthlyArticles.some(x => x.month === value!)
            : weeklyArticles.some(x => x.week === value!));
          return notAvailable;
        }
      ),
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    formState,
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const recommendedType = watch("recommendedType");
  const defaultRecommendedType = type === "weeks" ? "week" : "month";
  useEffect(() => {
    if (recommendedContent) {
      reset({
        monthOrWeek:
          recommendedContent.type === PopularType.WEEK
            ? recommendedContent.week!
            : recommendedContent.month!,
        recommendedType: recommendedContent.type,
      });
    } else {
      reset({
        recommendedType: recommendedType,
        monthOrWeek: 0,
      });
    }
  }, [recommendedType, recommendedContent, reset]);

  useEffect(() => {
    const noOfContent = managedContent.length;
    const isLimitExceeded = isCuratedContentLimitExceeded(
      recommendedType,
      noOfContent
    );
    setIsContentLimitExceeded(isLimitExceeded);
    setError(
      isLimitExceeded ? curatedPageMessages.contentLimitErrorMessage : null
    );
  }, [managedContent.length, recommendedType]);

  const onSubmit: SubmitHandler<FormValues> = async data => {
    if (isContentLimitExceeded) {
      return;
    }
    try {
      const contentsData = managedContent
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(
          (
            {
              id,
              imageUrl,
              squareImageUrl,
              stretchedImageUrl,
              imageBlurhash,
              blurhash,
              stretchedBlurhash,
              squareBlurhash,
              type,
              podcastId,
              blogPostUrl,
              restricted,
              translations,
            },
            index
          ) => {
            const mappedContent: PopularContent = {
              id,
              imageUrl,
              squareImageUrl,
              stretchedImageUrl,
              imageBlurhash,
              blurhash,
              stretchedBlurhash,
              squareBlurhash,
              type,
              sortOrder: index,
              podcastId,
              blogPostUrl,
              restricted,
              translations,
            };
            return mappedContent;
          }
        );
      const dataToSend = {
        week: recommendedType === PopularType.WEEK ? data.monthOrWeek : null,
        month: recommendedType === PopularType.MONTH ? data.monthOrWeek : null,
        content: contentsData,
        contentMap: contentsData.reduce((acc, item) => {
          acc[item?.id] = item;
          return acc;
        }, {} as { [key: string]: CommonContentV2 }),
      };

      if (recommendedContent) {
        await onUpdate(recommendedContent.id, { ...dataToSend });
      } else {
        const { id: createContentId } = await onCreate({
          ...dataToSend,
        });
        return navigate(`../${createContentId}`);
      }
      setError(null);
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
      console.log(err);
    }
  };

  const onRemove = async () => {
    if (recommendedContent) {
      await onDelete(recommendedContent.id);
      return navigate("./..");
    }
  };

  const isDirty =
    !!Object.keys(formState.dirtyFields).length ||
    !isEqualArrays(recommendedContent?.content ?? [], managedContent);
  const title =
    recommendedContent?.type === PopularType.MONTH
      ? getOrdinal(recommendedContent?.month) + " Month"
      : getOrdinal(recommendedContent && recommendedContent.week) + " Week";

  return (
    <>
      <FormEdit
        onSubmit={handleSubmit(onSubmit, formValidationError)}
        onRemove={onRemove}
        error={error}
        enableSave={isDirty && !isContentLimitExceeded}
        title={recommendedContent ? title : "New recommended"}
        id={recommendedContent?.id}
        editType={recommendedContent?.id ? FormEditType.EDIT : FormEditType.ADD}
        loading={loading || loadingRecommendedMonths || loadingRecommendedWeeks}
      >
        {!recommendedContent && (
          <Space size={"large"}>
            <BJSelectFormItem
              size="large"
              control={control}
              error={!!errors.recommendedType}
              label={"Recommended type"}
              message={errors.recommendedType?.message}
              required={true}
              optionsList={[
                { key: "month", value: "month", display: "Month" },
                { key: "week", value: "week", display: "Week" },
              ]}
              fieldName={"recommendedType"}
              defaultValue={defaultRecommendedType}
            />
            <BJInputFormItem
              control={control}
              error={!!errors.monthOrWeek}
              label={recommendedType ?? ""}
              message={errors.monthOrWeek?.message}
              required={true}
              autoFocus
              fieldName={"monthOrWeek"}
            />
          </Space>
        )}
        {
          <ContentSelector
            displayButtons={true}
            maxContentLimit={
              recommendedType === PopularType.WEEK
                ? weekContentLimit
                : monthContentLimit
            }
            setManagedContent={setManagedContent}
            initialContent={recommendedContent?.content ?? []}
            contentListType={ContentListType.RecommendedContent}
          />
        }
      </FormEdit>
    </>
  );
};
