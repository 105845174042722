import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";

export class DietaryAdviceContentService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  static toDietaryAdviceContent = (
    doc: firebase.firestore.DocumentData
  ): DietaryAdviceContent => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      translations: data.translations ?? {},
    };
  };

  subscribe = (
    callback: (err: Error | null, snapshot: DietaryAdviceContent | null) => void
  ) =>
    firebase
      .firestore()
      .collection(CollectionPaths.DietaryAdvicesContent[this.countryCode])
      .onSnapshot(
        snapshot => {
          const docs = snapshot.empty
            ? []
            : snapshot.docs.map(
                DietaryAdviceContentService.toDietaryAdviceContent
              );
          callback(null, docs[0]);
        },
        err => {
          logException(err);
          callback(err, null);
        }
      );

  create = (data: Omit<DietaryAdviceContent, "id">) =>
    firebase
      .firestore()
      .collection(CollectionPaths.DietaryAdvicesContent[this.countryCode])
      .add({ ...data });

  update = (data: DietaryAdviceContent) =>
    firebase
      .firestore()
      .collection(CollectionPaths.DietaryAdvicesContent[this.countryCode])
      .doc(data.id)
      .update({ ...data });

  deleteContent = (id: DietaryAdviceContent["id"]) =>
    firebase
      .firestore()
      .collection(CollectionPaths.DietaryAdvicesContent[this.countryCode])
      .doc(id)
      .delete();
}
