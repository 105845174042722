import moment from "moment";
import { commonErrors, RemoteNotificationMessages } from "../language";
import { UserSegmantType } from "../utils";
import * as yup from "yup";

const { requiredError, urlValidationError } = commonErrors;

const remoteNotificationSchema = {
  deepLink: yup
    .string()
    .nullable()
    .url(urlValidationError)
    .test(
      "record valid",
      RemoteNotificationMessages.DeepLinkIsRequired,
      function (value: string): boolean {
        return (
          this.parent.userSegment !=
            UserSegmantType.usersNotParticipatedInCompetition.toString() ||
          (this.parent.userSegment ==
            UserSegmantType.usersNotParticipatedInCompetition.toString() &&
            value &&
            value?.trim() != "")
        );
      }
    )
    .test(
      "record valid",
      RemoteNotificationMessages.DeepLinkInvalidForCompetitions,
      function (value: string): boolean {
        return (
          this.parent.userSegment !=
            UserSegmantType.usersNotParticipatedInCompetition.toString() ||
          (this.parent.userSegment ==
            UserSegmantType.usersNotParticipatedInCompetition.toString() &&
            value &&
            value.includes("/competitions/"))
          // value == UserSegmantType.usersNotParticipatedInCompetition
        );
      }
    ),

  scheduldedAt: yup
    .object()
    .nullable()
    .test("record valid", requiredError, function (value): boolean {
      return value || this.parent.fcmToken;
    })
    .test(
      "valid-date",
      "Scheduled date must be within 30 days from now",
      function (value) {
        if (!value) {
          return true;
        }

        const selectedDate = moment(value);
        const thirtyDaysFromNow = moment().add(30, "days");

        return selectedDate.isSameOrBefore(thirtyDaysFromNow);
      }
    ),
  topic: yup
    .string()
    .nullable()
    .test(
      "record valid",
      RemoteNotificationMessages.cantSelectSegmentsAndGroups,
      function (value: string): boolean {
        return (
          !value ||
          (value &&
            (this.parent.userSegment === null ||
              this.parent.userSegment?.trim() === "" ||
              this.parent.userSegment?.trim() === undefined))
        );
      }
    ),
  userSegment: yup
    .string()
    .nullable()
    .test(
      "record valid",
      RemoteNotificationMessages.cantSelectSegmentsAndGroups,
      function (value: string): boolean {
        return (
          !value ||
          (value &&
            (this.parent.topic === null ||
              this.parent.topic?.trim() === "" ||
              this.parent.topic?.trim() === undefined))
        );
      }
    ),
  competitionId: yup
    .string()
    .nullable()
    .test(
      "record valid",
      commonErrors.requiredError,
      function (value: string): boolean {
        return (
          this.parent.userSegment !=
            UserSegmantType.usersNotParticipatedInCompetition ||
          (this.parent.userSegment ==
            UserSegmantType.usersNotParticipatedInCompetition &&
            !!value)
        );
      }
    ),
  category: yup
    .string()
    .nullable()
    .test(
      "record valid",
      commonErrors.requiredError,
      function (value: string): boolean {
        return (
          this.parent.userSegment !==
            UserSegmantType.usersWithCategorySpecified ||
          (this.parent.userSegment ===
            UserSegmantType.usersWithCategorySpecified &&
            !!value)
        );
      }
    ),
  pregnancyWeek: yup
    .string()
    .nullable()
    .test(
      "record valid",
      commonErrors.requiredError,
      function (value: string): boolean {
        return (
          this.parent.userSegment !==
            UserSegmantType.pregnanciesInOrAboveWeekNumber ||
          (this.parent.userSegment ===
            UserSegmantType.pregnanciesInOrAboveWeekNumber &&
            !!value)
        );
      }
    ),
  city: yup
    .string()
    .nullable()
    .test(
      "record valid",
      commonErrors.requiredError,
      function (value: string): boolean {
        return (
          this.parent.userSegment !== UserSegmantType.usersWithSelectedTown ||
          (this.parent.userSegment === UserSegmantType.usersWithSelectedTown &&
            !!value)
        );
      }
    ),
};

export { remoteNotificationSchema };
