import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMissions } from "../../context";
import { FormEdit, FormEditType } from "../../components/FormEdit";
import {
  BJSortableList,
  SortContentType,
} from "../../components/theme/molecules/BJSortableList";
import { arrayMove } from "../../utils/moveArrayItems";
import { MissionsService } from "../../services";

type SortProps = {
  oldIndex: number;
  newIndex: number;
};

export const ChangeMissionsList = () => {
  const { missions: allMissions, loading } = useMissions();
  const { handleSubmit } = useForm();
  const [isDirty, setIsDirty] = useState(false);
  const [missions, setMissions] = useState<Mission[]>([]);

  useEffect(() => {
    setMissions(allMissions);
  }, [allMissions]);

  const saveOrder = async () => {
    await MissionsService.updateSortOrder(missions);
    setIsDirty(false);
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortProps) => {
    if (oldIndex == newIndex) {
      return;
    }
    const sortableMissions = arrayMove([...missions], oldIndex, newIndex);
    setMissions(sortableMissions);
    setIsDirty(true);
  };

  return (
    <FormEdit
      enableSave={isDirty}
      title={"Change missions order"}
      editType={FormEditType.VIEW}
      loading={loading}
      onSubmit={handleSubmit(saveOrder)}
    >
      <BJSortableList
        items={missions.map(
          mission =>
            ({
              id: mission.id,
              title: mission.title,
              noImage: true,
            } as SortContentType)
        )}
        onSortEnd={onSortEnd}
      />
    </FormEdit>
  );
};
