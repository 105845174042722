import { firestoreNew, functions } from "../firebase";
import { onSnapshot, collection, doc } from "firebase/firestore";
import { cloudFunctionNames, firebaseCollectionNames } from "../utils";
import { httpsCallable } from "firebase/functions";
import { logException } from "../utils/exceptionLogger";
const tagsCollection = firebaseCollectionNames.tagsCollection.tags;
const tagsSub = firebaseCollectionNames.tagsCollection.commonSub;

const subscribeToTags = (
  callback: (err: Error | null, snapshot: Tags[]) => void
) => {
  return onSnapshot(
    doc(collection(firestoreNew, tagsCollection), tagsSub),
    doc => {
      callback(null, doc.data()?.content as Tags[]);
    },
    err => {
      callback(err, []);
    }
  );
};

const addTags = async (tagName: string) => {
  try {
    const crateTags = httpsCallable(functions, cloudFunctionNames.tags.addTag);
    const result = await crateTags({
      name: tagName,
    });
    return result.data;
  } catch (error) {
    logException(error);
    throw error;
  }
};

const deleteTags = async (tagName: string) => {
  try {
    const deleteTags = httpsCallable(
      functions,
      cloudFunctionNames.tags.deleteTag
    );
    const result = await deleteTags({
      name: tagName,
    });
    return result.data;
  } catch (error) {
    logException(error);
    throw error;
  }
};
const updateTags = async (tagName: string, oldName: string) => {
  try {
    const updateTags = httpsCallable(
      functions,
      cloudFunctionNames.tags.updateTag
    );
    const result = await updateTags({
      name: tagName,
      oldName: oldName,
    });
    return result.data;
  } catch (error) {
    logException(error);
    throw error;
  }
};

const generateTags = async (payload: GenerateTagsPayload) => {
  try {
    const generateTags = httpsCallable<
      GenerateTagsPayload,
      {
        tagWords: string[];
      }
    >(functions, cloudFunctionNames.tags.generateTags);
    const result = await generateTags(payload);
    return result.data.tagWords;
  } catch (error) {
    logException(error);
    throw error;
  }
};

const translateTags = async (payload: TranslateTagsPayload) => {
  try {
    const translateTags = httpsCallable<
      TranslateTagsPayload,
      { translatedTags: string[] }
    >(functions, cloudFunctionNames.tags.translateTags);

    const result = await translateTags(payload);
    return result.data.translatedTags;
  } catch (error) {
    logException(error);
    throw error;
  }
};

export const TagsService = {
  addTags,
  subscribeToTags,
  deleteTags,
  updateTags,
  generateTags,
  translateTags,
};
