import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useImages } from "../../context";
import { Sorter } from "../../components/theme/util/sorter";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import BJList from "../../components/theme/components/BJList";
import { changeorder, newRoute } from "../../routes";

type datasourceType = Image;

export const UploadImageListPage = () => {
  const navigate = useNavigate();
  const { images, loading } = useImages();
  const [originalTable, setOriginalTable] = useState<datasourceType[]>([]);

  useEffect(() => {
    const imageTableSource: datasourceType[] = images.map(image => ({
      ...image,
      key: image.id,
    }));
    setOriginalTable(imageTableSource);
  }, [images]);

  const handleNewOffer = () => {
    navigate(newRoute);
  };

  const onClickRow = (record: datasourceType) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const columns: BJColumnType<datasourceType>[] = [
    {
      title: "Internal name",
      dataIndex: "internalName",
      key: "internalName",
      width: 2,
      ellipsis: true,
      onFilter: (value, record) =>
        record.internalName.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Image url",
      dataIndex: "imageUrl",
      key: "imageUrl",
      width: 2,
      ellipsis: true,
      onFilter: (value, record) =>
        record.internalName.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  return (
    <BJList
      onChangeOrder={() => {
        navigate(changeorder);
      }}
      loading={loading}
      filterOnlyDisplayList
      addButtonCaption={"New Image"}
      title={"Image"}
      OriginalList={originalTable}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewOffer}
      recordCountSuffix={"Images"}
    />
  );
};
