import {
  createContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
} from "react";

import { BlogPostService } from "../services";
import { useCountry } from "./CountryContext";

type ContextState = {
  blogPosts: BlogPost[];
  loading: boolean;
  error: Error | null;
  getById: (id: BlogPost["id"]) => BlogPost | null;
  deleteBlogPost: (id: string) => Promise<void>;
  writeBlogPost: (data: BlogPost) => Promise<string>;
};

const BlogPostContext = createContext<ContextState>({
  blogPosts: [],
  loading: false,
  error: null,
  getById: () => null,
  deleteBlogPost: async () => null,
  writeBlogPost: async () => null,
});

export const BlogPostsProvider = ({ ...rest }) => {
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const blogpostsService = useMemo(
    () =>
      currentCountry?.abb ? new BlogPostService(currentCountry.abb) : null,
    [currentCountry?.abb]
  );

  useEffect(() => {
    if (!blogpostsService) {
      return;
    }

    setLoading(true);

    const unsub = blogpostsService.subscribe((_error, _blogPosts) => {
      setBlogPosts(_blogPosts);
      setError(_error);
      setLoading(false);
    });

    return unsub;
  }, [blogpostsService]);

  const getById = useCallback(
    (id: BlogPost["id"]) => {
      if (loading) return null;

      const _blogPost = blogPosts.find(a => a.id === id) || null;
      if (!_blogPost) return null;

      return _blogPost;
    },
    [loading, blogPosts]
  );

  const deleteBlogPost = useCallback(
    async (id: string) => await blogpostsService.delete(id),
    [blogpostsService]
  );

  const writeBlogPost = useCallback(
    async (data: BlogPost) => await blogpostsService.write(data),
    [blogpostsService]
  );

  const value = useMemo(
    () => ({
      blogPosts,
      loading,
      error,
      getById,
      deleteBlogPost,
      writeBlogPost,
    }),
    [blogPosts, loading, error, getById, deleteBlogPost, writeBlogPost]
  );

  return <BlogPostContext.Provider value={value} {...rest} />;
};

export const useBlogPosts = () => {
  const context = useContext(BlogPostContext);
  if (context === undefined) {
    throw new Error("useBlogPosts must be used within an BlogPostsProvider");
  }
  return context;
};

export const useBlogPost = (blogPostId: BlogPost["id"]) => {
  const [blogPost, setBlogPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState(true);

  const { getById, loading: loadingBlogPosts } = useBlogPosts();

  useEffect(() => {
    if (loadingBlogPosts) {
      return;
    }
    setBlogPost(getById(blogPostId));
    setLoading(false);
  }, [getById, blogPostId, loadingBlogPosts]);

  return { blogPost, loading };
};
