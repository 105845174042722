import { Switch, Typography } from "antd";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { PickerDisplayType, PickerType } from "../utils/timeUtils";

interface Props {
  selectedTimes: timeElementType[];
  onChange: (times: timeElementType[]) => void;
  pickerType: PickerType;
  selectAllCaption?: string;
  times: PickerDisplayType[];
  ignorePosition?: boolean;
  render?: () => JSX.Element;
}

export const WeeksOrMonthsPicker = ({
  selectedTimes: selectedTimes,
  onChange: onChange,
  selectAllCaption,
  times,
  ignorePosition,
  render,
}: Props) => {
  const [seletedTimes, setSelectedTimes] = useState<timeElementType[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    setSelectedTimes(selectedTimes);
    setSelectAll(selectedTimes.length === times.length);
  }, [selectedTimes, times.length]);

  //use to color the circle
  const hasAnyPosition = (timeNumber: number) => {
    const contains = selectedTimes?.some(x => x.no === timeNumber);
    return contains;
  };

  //use to color the position button (1,2)
  const hasPosition = (timeNumber: number, position: number) => {
    return position === 1
      ? selectedTimes?.some(x => x.no === timeNumber && x.first)
      : selectedTimes?.some(x => x.no === timeNumber && x.second);
  };

  const onPressTime = (timeNumber: number) => {
    let pressedTimeItem: timeElementType[] = [];
    if (seletedTimes.some(item => item.no === timeNumber)) {
      pressedTimeItem = seletedTimes.filter(item => item.no !== timeNumber);
    } else {
      const filtered = seletedTimes.filter(item => item.no !== timeNumber);
      pressedTimeItem = [
        ...filtered,
        { no: timeNumber, first: true, second: false },
      ];
    }
    setSelectedTimes(pressedTimeItem);
    onChange(pressedTimeItem);
  };

  const onPressPosition = (timeNumber: number, position: number) => {
    const circleRec = seletedTimes.find(x => x.no === timeNumber);
    let alteredCircleRecord: timeElementType[] = [];
    if (circleRec) {
      // if record is already in the list
      if (position === 1) {
        circleRec.first = !circleRec.first;
      } else {
        circleRec.second = !circleRec.second;
      }
      const filtered = seletedTimes.filter(x => x.no !== timeNumber);
      if (!circleRec.first && !circleRec.second) {
        alteredCircleRecord = [...filtered];
      } else {
        alteredCircleRecord = [...filtered, circleRec];
      }
      setSelectedTimes(alteredCircleRecord);
      onChange(alteredCircleRecord);
    } else {
      //if record is not there add it to collection
      const newSeletedTimesNew = [
        ...seletedTimes,
        { no: timeNumber, first: position === 1, second: position === 2 },
      ];
      setSelectedTimes(newSeletedTimesNew);
      onChange(newSeletedTimesNew);
    }
  };

  const selectAllItems = (checked: boolean) => {
    if (checked) {
      const allSelected: timeElementType[] = times.map(x => ({
        no: x.number,
        first: true,
        second: true,
      }));
      setSelectedTimes(allSelected);
      onChange(allSelected);
    } else {
      setSelectedTimes([]);
      onChange([]);
    }
  };

  return (
    <>
      <StyledHeader>
        {render && render()}
        <StyledSwitch>
          <Typography.Text>{selectAllCaption}</Typography.Text>
          <Switch checked={selectAll} onChange={selectAllItems} />
        </StyledSwitch>
      </StyledHeader>
      <List>
        {times.map(time => (
          <Item key={time.number}>
            <Circle
              selected={hasAnyPosition(time.number)}
              onClick={() => onPressTime(time.number)}
            >
              <span>{time.number}</span>
            </Circle>
            {!ignorePosition && (
              <PositionContainer>
                <PositionButton
                  selected={hasPosition(time.number, 1)}
                  onClick={() =>
                    !ignorePosition && onPressPosition(time.number, 1)
                  }
                >
                  1
                </PositionButton>
                <PositionButton
                  selected={hasPosition(time.number, 2)}
                  onClick={() =>
                    !ignorePosition && onPressPosition(time.number, 2)
                  }
                >
                  2
                </PositionButton>
              </PositionContainer>
            )}
          </Item>
        ))}
      </List>
    </>
  );
};

const PositionContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2px;
  border: 1px solid #ced4da;
  border-radius: 4px;
`;

const StyledHeader = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.625rem;
`;

const PositionButton = styled("div")<{ selected: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1rem;
  cursor: pointer;

  ${props =>
    props.selected &&
    css`
      background-color: ${props => props.theme.button.primary};
      color: ${props => props.theme.white};
    `}

  &:last-child {
    border-left: 1px solid #ced4da;
  }
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  place-items: start;
  gap: 0.375rem;
`;

const Item = styled.div`
  position: relative;
  grid-column: span 1;
  width: 100%;
`;

const Circle = styled("div")<{ selected: boolean }>`
  position: relative;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 100%;
  cursor: pointer;

  ${props =>
    props.selected &&
    css`
      background-color: ${props => props.theme.button.primary};
      color: ${props => props.theme.white};
      border-color: ${props => props.theme.button.primary};
    `}

  span {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &:before {
    content: "";
    display: block;
    width: 1px;
    height: 0;
    padding-bottom: 100%;
  }
`;

const StyledSwitch = styled.div`
  display: flex;
  column-gap: 1rem;
`;
