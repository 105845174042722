import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";

import { VerifierService } from "../services";
import { useCountry } from "./CountryContext";

type ContextState = {
  verifiers: Verifier[];
  loading: boolean;
  error: Error | null;
  getById: (id: Verifier["id"]) => Verifier | null;
};

const VerifiersContext = createContext<ContextState>({
  verifiers: [],
  loading: false,
  error: null,
  getById: () => null,
});

export const VerifiersProvider = ({ ...rest }) => {
  const [verifiers, setVerifiers] = useState<Verifier[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const { currentCountry } = useCountry();

  const verifierService = useMemo(
    () => new VerifierService(currentCountry?.abb),
    [currentCountry?.abb]
  );
  useEffect(() => {
    setLoading(true);

    const unsub = verifierService?.subscribe((_error, _verifiers) => {
      setVerifiers(_verifiers);
      setError(_error);
      setLoading(false);
    });

    return unsub;
  }, [verifierService]);

  const getById = useCallback(
    (id: Verifier["id"]) => {
      return verifiers.find(a => a.id === id) || null;
    },
    [verifiers]
  );

  const value = useMemo(
    () => ({ verifiers, loading, error, getById }),
    [verifiers, loading, error, getById]
  );

  return <VerifiersContext.Provider value={value} {...rest} />;
};

export const useVerifiers = () => {
  const context = React.useContext(VerifiersContext);
  if (context === undefined) {
    throw new Error("useVerifiers must be used within an VerifiersProvider");
  }
  return context;
};

export const useVerifier = (verifierId: Verifier["id"]) => {
  const [verifier, setVerifier] = useState<Verifier | null>(null);
  const [loading, setLoading] = useState(true);

  const { getById, loading: loadingVerifiers } = useVerifiers();

  useEffect(() => {
    if (loadingVerifiers) {
      return;
    }
    setVerifier(getById(verifierId));
    setLoading(false);
  }, [loadingVerifiers, getById, verifierId]);

  return { verifier, loading };
};
