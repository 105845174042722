import { useMemo, useReducer } from "react";
import { useCountry } from "../../context";

const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case ContentActions.ADDSECTION:
      return {
        ...state,
        addSection: payload,
      };
    case ContentActions.TOGGLEEDIT:
      return {
        ...state,
        addSection: payload,
      };
    case ContentActions.SORTCONTENT:
      return {
        ...state,
        sortSection: payload,
      };
    case ContentActions.EDITSECTION:
      return {
        ...state,
        editSection: payload.editSection,
      };
    case ContentActions.SETCONTENTLIST:
      return {
        ...state,
        contentMenu: payload,
      };
    case ContentActions.CONTENTUPDATED:
      return {
        ...state,
        addItem: null,
        editItem: null,
        editSection: null,
        addSection: null,
        sortSection: false,
        toggleEdit: false,
      };
    case ContentActions.EDITITEM:
      return {
        ...state,
        editItem: {
          editSection: payload.contentSection,
          editItem: payload.editItem,
        },
      };
    case ContentActions.ADDITEM:
      return {
        ...state,
        addItem: {
          addItem: payload.addItem,
          editSection: payload.editSection,
        },
      };
    default:
      return state;
  }
};

export const useManageContent = () => {
  const [state, dispatch] = useReducer(reducer, {});
  const { primaryLocale } = useCountry();

  const editSectionTitle = useMemo(() => {
    if (state.addItem) {
      return "Add item";
    } else if (state.editItem) {
      return (
        state.editItem?.editItem?.translations?.[primaryLocale.key]?.title || ""
      );
    } else if (state.editSection) {
      return state.editSection?.translations?.[primaryLocale.key]?.title || "";
    } else if (state.addSection) {
      return "Add section";
    } else if (state.sortSection) {
      return "Sort section";
    } else return "";
  }, [
    primaryLocale.key,
    state.addItem,
    state.addSection,
    state.editItem,
    state.editSection,
    state.sortSection,
  ]);
  return { state, dispatch, editSectionTitle };
};

type State = {
  saving?: boolean;
  editSection?: ContentMenu;
  toggleEdit?: boolean;
  addSection?: boolean;
  sortSection?: boolean;
  typing?: string;
  error?: string;
  loading?: boolean;
  searchableTags?: { value: string }[];
  options?: { value: string }[];
  allTags?: Tags[];
  contentTags?: { value: string }[];
  contentMenu?: ContentMenu[];
  editItem?: {
    editItem?: ContentItem;
    editSection?: ContentMenu;
  };
  addItem?: {
    addItem?: boolean;
    editSection?: ContentMenu;
  };
};

export enum ContentActions {
  ADDSECTION = "ADDSECTION",
  SORTCONTENT = "SORTCONTENT",
  EDITSECTION = "EDITSECTION",
  EDITITEM = "EDITITEM",
  ADDITEM = "ADDITEM",
  SETCONTENTLIST = "SETCONTENTLIST",
  CONTENTUPDATED = "CONTENTUPDATED",
  SAVING = "SAVING",
  OPTIONS = "OPTIONS",
  LOADING = "LOADING",
  ERROR = "ERROR",
  TOGGLEEDIT = "TOGGLEEDIT",
}

export type Action =
  | { type: ContentActions.TOGGLEEDIT; payload: boolean }
  | { type: ContentActions.ADDSECTION; payload: boolean }
  | { type: ContentActions.SORTCONTENT; payload: boolean }
  | {
      type: ContentActions.EDITSECTION;
      payload: { editSection?: ContentMenu };
    }
  | { type: ContentActions.SETCONTENTLIST; payload: ContentMenu[] }
  | { type: ContentActions.CONTENTUPDATED; payload: ContentMenu[] }
  | {
      type: ContentActions.EDITITEM;
      payload: {
        editItem: ContentItem;
        contentSection: ContentMenu;
      };
    }
  | {
      type: ContentActions.ADDITEM;
      payload: {
        addItem?: boolean;
        editSection?: ContentMenu;
      };
    };
