import { firestoreNew, functions } from "../firebase";
import { onSnapshot, collection, doc } from "firebase/firestore";
import { cloudFunctionNames, firebaseCollectionNames } from "../utils";
import { httpsCallable } from "firebase/functions";
import { logException } from "../utils/exceptionLogger";

interface Transcriptions {
  id: string;
  content: string;
}

const transcriptionsCollection =
  firebaseCollectionNames.transcriptionsCollection.transcriptions;
const transcriptionsSub =
  firebaseCollectionNames.transcriptionsCollection.commonSub;

const subscribeToTranscriptions = (
  callback: (err: Error | null, snapshot: Transcriptions[]) => void
) => {
  return onSnapshot(
    doc(collection(firestoreNew, transcriptionsCollection), transcriptionsSub),
    doc => {
      callback(null, doc.data()?.content as Transcriptions[]);
    },
    err => {
      callback(err, []);
    }
  );
};

const generateTranscription = async (payload: AITranscriptionsPayload) => {
  try {
    const generateTranscription = httpsCallable<
      AITranscriptionsPayload,
      {
        transcription: string;
      }
    >(functions, cloudFunctionNames.transcriptions.generateTranscription);
    // This is to avoid callback
    setTimeout(() => {
      generateTranscription(payload).catch(error => {
        logException(error);
      });
    }, 0);
    return true;
  } catch (error) {
    logException(error);
    throw error;
  }
};

export const AudioTranscriptionService = {
  subscribeToTranscriptions,
  generateTranscription,
};
