import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Row, Col, Typography, Select, Divider, List } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  valueOrNull,
  AspectRatio,
  formValidationError,
  DeepLinkType,
  CommonContentType,
  ContentListType,
  ContentTypeNames,
} from "../../utils";
import { FormEdit, FormEditType } from "./../../components/FormEdit";
import {
  useArticle,
  useArticles,
  useContentCollections,
  useCountry,
  useOffers,
  useSponsors,
  useVerifiers,
} from "../../context";
import { ArticleService } from "../../services";
import { commonErrors } from "../../language";
import { DropAndCrop } from "../../components/DropAndCrop";
import styled from "styled-components";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";
import BJFeaturedList from "../../components/theme/molecules/BJFeaturedList";
import {
  BJInputFormItem,
  BJSelectFormItem,
  BJSwitchFormItem,
  BJMdFormItem,
  BJTagsFormItem,
  BJSelectFormItemLevel,
} from "../../components/theme";
import { ImagesCollapse } from "../../components/ImagesCollapse";
import { transformToCommonContent } from "../../hooks/contentCollections/useFilterContent";
import { ContentSelector } from "../Popular/ContentSelector";
import { AiTranslator } from "../../components";
const { Paragraph, Title } = Typography;

export type FormValues = {
  categoryId: string;
  verifierId: string;
  sponsorId: string;
  title: string;
  audioUrl: string | null;
  videoUrl: string | null;
  offerId1: string | null;
  offerId2: string | null;
  promoted: boolean;
  translations: {
    [locale: string]: {
      title: string;
      intro: string;
      body: string;
      audioUrl: string | null;
      videoUrl: string | null;
      tagWords: string[];
      imageUrl: string | null;
      stretchedImageUrl: string | null;
      squareImageUrl: string | null;
      bannerImage1Url: string | null;
      bannerImage2Url: string | null;
      imageBlurhash?: string | null;
      stretchedBlurhash?: string | null;
      squareBlurhash?: string | null;
      banner1Blurhash?: string | null;
      banner2Blurhash?: string | null;
    };
  };
  featuredArticles: string[];
  level?: number;
};

type UploadProps = {
  url: string | null;
  locale: string;
  fieldName:
    | "imageUrl"
    | "stretchedImageUrl"
    | "squareImageUrl"
    | "bannerImage1Url"
    | "bannerImage2Url"
    | "imageBlurhash"
    | "stretchedBlurhash"
    | "squareBlurhash"
    | "banner1Blurhash"
    | "banner2Blurhash";
};

const { urlValidationError2: urlError, requiredError2: requiredError } =
  commonErrors;

const getSchema = (currentCountry: Country) =>
  yup.object().shape({
    categoryId: yup.string().required(`Category: ${requiredError}`),
    featuredArticles: yup.array().of(yup.string()),
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup
            .string()
            .required(
              `Title (${String(item.key).toUpperCase()}): ${requiredError}`
            ),
          imageUrl: yup.string().nullable().url(`Image URL: ${urlError}`),
          stretchedImageUrl: yup
            .string()
            .nullable()
            .url(
              `Stretched Image Url (${String(
                item.key
              ).toUpperCase()}): ${urlError}`
            ),
          squareImageUrl: yup
            .string()
            .nullable()
            .url(
              `Square Image Url (${String(
                item.key
              ).toUpperCase()}): ${urlError}`
            ),
          bannerImage1Url: yup
            .string()
            .nullable()
            .url(
              `Banner Image Url (${String(
                item.key
              ).toUpperCase()}): ${urlError}`
            ),
          bannerImage2Url: yup
            .string()
            .nullable()
            .url(
              `Banner 2 Image Url (${String(
                item.key
              ).toUpperCase()}): ${urlError}`
            ),
        });
        return acc;
      }, {} as any)
    ),
  });

export const ArticlePage = () => {
  const navigate = useNavigate();
  const { offers } = useOffers();
  const { sponsors } = useSponsors();
  const { verifiers } = useVerifiers();
  const { id } = useParams<string>();
  const { article, loading } = useArticle(id);
  const { articles, categories, articlesService } = useArticles();
  const { currentCountry, primaryLocale } = useCountry();
  const { getCCForContentId, updateCCWithContentId } = useContentCollections();
  const [content, setContent] = useState<CommonContentV2[]>([]);
  const [changeFeaturedListOrder, setChangeFeaturedListOrder] = useState(false);

  const {
    formState,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
    getValues,
  } = useForm<FormValues>({
    resolver: yupResolver(getSchema(currentCountry)),
    defaultValues: { promoted: false, featuredArticles: [] },
  });

  useEffect(() => {
    if (loading || article === null) {
      return;
    }
    reset({
      ...article,
      featuredArticles: article.featuredArticles ?? [],
      level: article.level ?? 0,
    });
  }, [article, loading, reset]);

  const includedContent = useMemo(() => {
    return (
      getCCForContentId(article?.id).map(
        value =>
          ({
            id: value.id,
            translations: value.translations,
            type: "contentCollection",
          } as CommonContentV2)
      ) ?? []
    );
  }, [article?.id, getCCForContentId]);

  const onSubmit = async (data: FormValues) => {
    const category = categories.find(x => x.id === data.categoryId);
    const [verifier] = verifiers.filter(({ id }) => id === data.verifierId);
    const [sponsor] = sponsors.filter(({ id }) => id === data.sponsorId);

    const translations: ArticleV2["translations"] = {};

    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        title: value?.title?.trim(),
        intro: value.intro,
        body: value.body,
        audioUrl: value.audioUrl,
        videoUrl: value.videoUrl,
        tagWords: value.tagWords,
        imageUrl: value.imageUrl,
        stretchedImageUrl: value.stretchedImageUrl,
        squareImageUrl: value.squareImageUrl,
        bannerImage1Url: value.bannerImage1Url,
        bannerImage2Url: value.bannerImage2Url,
        imageBlurhash: value.imageBlurhash,
        stretchedBlurhash: value.stretchedBlurhash,
        squareBlurhash: value.squareBlurhash,
        banner1Blurhash: value.banner1Blurhash,
        banner2Blurhash: value.banner2Blurhash,
      };
    }

    const updatedArticle: Omit<ArticleV2, "id"> = {
      categoryId: data.categoryId,
      offerId1: valueOrNull(data.offerId1),
      offerId2: valueOrNull(data.offerId2),
      imageUrl: valueOrNull(data.translations[primaryLocale.key].imageUrl),
      stretchedImageUrl: valueOrNull(
        data.translations[primaryLocale.key].stretchedImageUrl
      ),
      stretchedBlurhash: valueOrNull(
        data.translations[primaryLocale.key].stretchedBlurhash
      ),
      squareBlurhash: valueOrNull(
        data.translations[primaryLocale.key].squareBlurhash
      ),
      imageBlurhash: valueOrNull(
        data.translations[primaryLocale.key].imageBlurhash
      ),
      squareImageUrl: valueOrNull(
        data.translations[primaryLocale.key].squareImageUrl
      ),
      bannerImage1Url: valueOrNull(
        data.translations[primaryLocale.key].bannerImage1Url
      ),
      bannerImage2Url: valueOrNull(
        data.translations[primaryLocale.key].bannerImage2Url
      ),
      verifierId: valueOrNull(data.verifierId),
      sponsorId: valueOrNull(data.sponsorId),
      verifier: verifier ?? null,
      sponsor: sponsor ?? null,
      promoted: data.promoted ?? false,
      featuredArticles: valueOrNull(data.featuredArticles),
      restricted: category.restricted ?? false,
      translations,
      level: data.level,
    };

    if (article) {
      await articlesService?.update(id, updatedArticle);
      await updateCCWithContentId(
        content.map(({ id }) => id),
        transformToCommonContent(
          { id, ...updatedArticle } as unknown as CommonContent,
          0,
          CommonContentType.ARTICLE
        )
      );
    } else {
      const { id } = await articlesService.create(updatedArticle);
      await updateCCWithContentId(
        content.map(({ id }) => id),
        transformToCommonContent(
          { id, ...updatedArticle } as unknown as CommonContent,
          0,
          CommonContentType.ARTICLE
        )
      );
      return navigate(`../${id}`);
    }
  };

  const handleUploadedImageUrl = ({ url, locale, fieldName }: UploadProps) => {
    const temp = { ...getValues("translations") };
    temp[locale] = {
      ...temp[locale],
      [fieldName]: url,
    };

    if (locale) {
      temp["en"] = {
        ...temp["en"],
        [fieldName]: url,
      };
    }
    setValue("translations", temp, { shouldDirty: true });
  };

  const onRemove = async () => {
    if (article) {
      await articlesService.deleteArticle(article.id);
      navigate("./..", { replace: true });
    } else {
      throw new Error("Article not found");
    }
  };

  const mapToFeaturedArticle = (
    id: ArticleV2["id"],
    locale: Locale
  ): FeaturedItem => {
    const _article = articles.find(article => article.id === id);
    if (_article) {
      return {
        id: _article.id,
        title:
          _article.translations[locale.key]?.title ||
          _article.translations[primaryLocale.key]?.title,
        description:
          _article.translations[locale.key]?.intro ||
          _article.translations[primaryLocale.key]?.intro,
        imageUrl: _article.translations[primaryLocale.key]?.imageUrl,
      };
    }
  };

  const isDirty = !!Object.keys(formState.dirtyFields).length || !!content;

  const featuredArticles = watch("featuredArticles");
  const translations = watch("translations");

  const onSetTranslatedValue = (value: { data: string[] }) => {
    const data = value.data.map(item => item);
    const clonedTitle = data[0]
      .split(",")[0]
      ?.replace("[", "")
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      .trim();
    const clonedIntro = data[0]
      .split(",")[1]
      ?.replace("[", "")
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      .trim();
    const clonedBody = data[0]
      .split(",")[2]
      ?.replace("[", "")
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      .trim();

    let title;
    let intro;
    let body;

    //run function function if some are translated and some are not
    [
      translations?.[primaryLocale?.key]?.title,
      translations?.[primaryLocale?.key]?.intro,
      translations?.[primaryLocale?.key]?.body,
    ].forEach((item, index) => {
      if (data[0].includes("None")) {
        if (index === 0 && !clonedTitle.includes("None")) {
          title = clonedTitle;
        } else if (index === 1 && !clonedIntro.includes("None")) {
          intro = clonedIntro;
        } else if (index === 2 && !clonedBody.includes("None")) {
          body = clonedBody;
        }
      } else {
        if (index === 0 && item !== undefined) {
          title = clonedTitle;
        } else if (index === 1 && item !== undefined) {
          intro = clonedIntro;
        } else if (index === 2 && item !== undefined) {
          body = clonedBody;
        }
      }
    });
    setValue("translations.en.title", title);
    setValue("translations.en.intro", intro);
    setValue("translations.en.body", body);
  };

  return (
    <FormEdit
      onRemove={onRemove}
      hasValidationErrors={Object.keys(errors).length !== 0}
      enableSave={isDirty}
      title={article ? article.translations : "New Article"}
      id={article?.id}
      editType={article?.id ? FormEditType.EDIT : FormEditType.ADD}
      loading={loading}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      recordIdentifier={article?.id}
      deepLink={{
        type: DeepLinkType.Article,
        id: article?.id,
        countryCode: currentCountry?.abb,
        isArticle: true,
      }}
      localeSupported
      errors={errors as any}
    >
      {locale => (
        <Row gutter={20}>
          <Col span={12}>
            <BJInputFormItem
              label={`Title (${locale?.label ?? ""})`}
              fieldName={`translations.${locale.key}.title`}
              key={`translations.${locale.key}.title`}
              control={control}
              error={!!errors?.translations?.[locale.key]?.title}
              message={errors?.translations?.[locale.key]?.title?.message}
              required={true}
            />
            <ImagesCollapse
              title={`Image URL (${locale?.label ?? ""})`}
              key={`translations.${locale.key}.imageUrl`}
              config={{
                "Cover image 4:3": {
                  title: `Cover image 4:3 (${locale?.label ?? ""})`,
                  setUploadUrl: (url: string) =>
                    handleUploadedImageUrl({
                      url,
                      locale: locale.key,
                      fieldName: "imageUrl",
                    }),
                  setBlurhash: (url: string) =>
                    handleUploadedImageUrl({
                      url,
                      locale: locale.key,
                      fieldName: "imageBlurhash",
                    }),
                  uploadImage: ArticleService.uploadArticleImage,
                  initialUrl:
                    watch("translations")?.[locale.key]?.imageUrl ?? "",
                  lockedRatio: AspectRatio.FourToThree,
                  defaultCropBoxWidth: 300,
                  extra: "Best resolution for this would be 1280*960",
                },
                "Stretched image 2:1": {
                  title: `Stretched image 2:1 (${locale?.label ?? ""})`,
                  setUploadUrl: (url: string) =>
                    handleUploadedImageUrl({
                      url,
                      locale: locale.key,
                      fieldName: "stretchedImageUrl",
                    }),
                  setBlurhash: (url: string) =>
                    handleUploadedImageUrl({
                      url,
                      locale: locale.key,
                      fieldName: "stretchedBlurhash",
                    }),
                  uploadImage: ArticleService.uploadArticleImage,
                  initialUrl:
                    watch("translations")?.[locale.key]?.stretchedImageUrl ??
                    "",
                  lockedRatio: AspectRatio.TwoToOne,
                  defaultCropBoxWidth: 300,
                  extra: "Best resolution for this would be 1024*512",
                },
                "Square image 1:1": {
                  title: `Square image 1:1 (${locale?.label ?? ""})`,
                  setUploadUrl: (url: string) =>
                    handleUploadedImageUrl({
                      url,
                      locale: locale.key,
                      fieldName: "squareImageUrl",
                    }),
                  setBlurhash: (url: string) =>
                    handleUploadedImageUrl({
                      url,
                      locale: locale.key,
                      fieldName: "squareBlurhash",
                    }),
                  uploadImage: ArticleService.uploadArticleImage,
                  initialUrl:
                    watch("translations")?.[locale.key]?.squareImageUrl ?? "",
                  lockedRatio: AspectRatio.OneToOne,
                  defaultCropBoxWidth: 300,
                  extra: "Best resolution for this would be 512*512",
                },
              }}
            />

            <BJMdFormItem
              fieldName={`translations.${locale.key}.intro`}
              key={`translations.${locale.key}.intro`}
              label={`Intro (${locale?.label ?? ""})`}
              control={control}
              required={false}
            />

            <BJMdFormItem
              label={`Body (${locale?.label ?? ""})`}
              fieldName={`translations.${locale.key}.body`}
              key={`translations.${locale.key}.body`}
              control={control}
              required={false}
            />

            <Divider />

            <Row justify="space-between" align="middle">
              <Title level={5}>Add Featured Articles</Title>
              <StyledSelect
                showSearch
                placeholder="Select article to feature"
                optionFilterProp="children"
                value="def"
                onSelect={(v, o) => {
                  setValue("featuredArticles", [...featuredArticles, o.value], {
                    shouldDirty: true,
                  });
                }}
              >
                <Select.Option value="def" style={{ display: "none" }}>
                  Select article to feature
                </Select.Option>

                {featuredArticles &&
                  articles
                    .filter(
                      item => !featuredArticles.find(id => id === item.id)
                    )
                    .map(article => (
                      <Select.Option value={article.id} key={article.id}>
                        {article.translations[locale.key]?.title ||
                          article.translations[primaryLocale.key]?.title ||
                          article.title}
                      </Select.Option>
                    ))}
              </StyledSelect>
            </Row>
            <Row>
              <BJButton
                buttonType={
                  changeFeaturedListOrder
                    ? ButtonTypes.Primary
                    : ButtonTypes.Secondary
                }
                onClick={() => setChangeFeaturedListOrder(prev => !prev)}
              >
                Change Order
              </BJButton>
            </Row>
            <BJFeaturedList
              changeFeaturedListOrder={changeFeaturedListOrder}
              featuredItems={featuredArticles
                ?.map(id => mapToFeaturedArticle(id, locale))
                .filter(x => x)}
              setfeaturedItems={item => {
                setValue(
                  "featuredArticles",
                  item.map(i => i.id),
                  {
                    shouldDirty: true,
                  }
                );
              }}
              removeFeaturedItem={id => {
                setValue(
                  "featuredArticles",
                  featuredArticles.filter(_id => _id !== id),
                  {
                    shouldDirty: true,
                  }
                );
              }}
            />
            <Divider />

            <ContentSelector
              displayButtons={true}
              maxContentLimit={100}
              setManagedContent={setContent}
              initialContent={includedContent}
              contentListType={ContentListType.OnlyContentCollection}
              headerTitle={"Add Content Collections"}
              enableOrder={false}
            />
          </Col>
          <Col span={12}>
            <AiTranslator
              locale={locale}
              data={[
                translations?.sv?.title?.length > 0
                  ? translations?.sv?.title?.trim()
                  : undefined,
                translations?.sv?.intro?.length > 0
                  ? translations?.sv?.intro?.trim()
                  : undefined,
                translations?.sv?.body?.length > 0
                  ? translations?.sv?.body?.trim()
                  : undefined,
              ]}
              onSetValue={onSetTranslatedValue}
            />
            <BJSelectFormItem
              label={"Category"}
              fieldName={"categoryId"}
              size="large"
              control={control}
              error={!!errors?.categoryId}
              message={errors?.categoryId?.message}
              optionsList={categories?.map(c => ({
                value: c.id,
                key: c.id,
                display: c.translations[locale.key]?.title,
              }))}
              required
              includeEmpty
            />

            <BJSwitchFormItem
              label="Promote"
              fieldName={"promoted"}
              control={control}
            />

            <BJSelectFormItemLevel
              control={control}
              fieldName={"level"}
              error={!!errors?.level?.message}
              message={errors?.level?.message}
              required={false}
            />

            <BJTagsFormItem
              label={`Tag Words (${locale?.label ?? ""})`}
              key={`translations.${locale.key}.tagWords`}
              fieldName={`translations.${locale.key}.tagWords`}
              control={control}
              setValue={setValue}
              aiTagWordsPayload={{
                contentTitle: translations?.[locale.key]?.title,
                contentIntro: translations?.[locale.key]?.intro,
                contentBody: translations?.[locale.key]?.body,
                contentType: ContentTypeNames.articles,
                documentId: article?.id,
                language: locale.label,
              }}
              aiTranslateTagWordsPayload={{
                tagWords: translations?.[primaryLocale.key]?.tagWords,
                from: primaryLocale.label,
              }}
              existingTags={translations?.[locale.key]?.tagWords || []}
              locale={locale.key}
            />

            <BJSelectFormItem
              label={"Verifier"}
              fieldName={"verifierId"}
              size="large"
              control={control}
              error={!!errors?.verifierId}
              message={errors?.verifierId?.message}
              optionsList={verifiers?.map(v => ({
                value: v.id,
                key: v.id,
                display: v.name,
              }))}
              includeEmpty
            />

            <BJSelectFormItem
              fieldName={"sponsorId"}
              label={"Sponsor"}
              size="large"
              control={control}
              error={!!errors?.sponsorId}
              message={errors?.sponsorId?.message}
              optionsList={sponsors?.map(s => ({
                value: s.id,
                key: s.id,
                display: s.name,
              }))}
              includeEmpty
            />

            <BJInputFormItem
              label={`Audio URL (${locale?.label ?? ""})`}
              fieldName={`translations.${locale.key}.audioUrl`}
              key={`translations.${locale.key}.audioUrl`}
              control={control}
              error={!!errors?.translations?.[locale.key]?.audioUrl}
              message={errors?.translations?.[locale.key]?.audioUrl?.message}
            />

            <BJInputFormItem
              label={`Video URL (${locale?.label ?? ""})`}
              fieldName={`translations.${locale.key}.videoUrl`}
              key={`translations.${locale.key}.videoUrl`}
              control={control}
              error={!!errors?.translations?.[locale.key]?.videoUrl}
              message={errors?.translations?.[locale.key]?.videoUrl?.message}
            />

            <BJSelectFormItem
              label={"Offer 1"}
              fieldName={"offerId1"}
              size="large"
              extra="Is shown between the intro and the body text"
              control={control}
              error={!!errors?.offerId1}
              message={errors?.offerId1?.message}
              optionsList={offers?.map(s => ({
                value: s.id,
                key: s.id,
                display: s?.translations?.[locale.key]?.title || s?.title,
              }))}
              includeEmpty
            />

            <BJSelectFormItem
              label={"Offer 2"}
              fieldName={"offerId2"}
              size={"large"}
              extra="Is shown after body text"
              control={control}
              error={!!errors?.offerId2}
              message={errors?.offerId2?.message}
              optionsList={offers?.map(s => ({
                value: s.id,
                key: s.id,
                display: s?.translations?.[locale.key]?.title || s?.title,
              }))}
              includeEmpty
            />

            <List bordered>
              <List.Item>
                <Paragraph>
                  {`Banner Image 1 URL: ${
                    locale.label ? `(${locale.label})` : ""
                  }`}
                </Paragraph>
                <DropAndCrop
                  title={`Banner Image 1 URL: ${
                    locale.label ? `(${locale.label})` : ""
                  }`}
                  previewImageWidth={"10"}
                  setUploadUrl={(url: string) =>
                    handleUploadedImageUrl({
                      url,
                      locale: locale.key,
                      fieldName: "bannerImage1Url",
                    })
                  }
                  setBlurhash={(url: string) =>
                    handleUploadedImageUrl({
                      url,
                      locale: locale.key,
                      fieldName: "banner1Blurhash",
                    })
                  }
                  uploadImage={ArticleService.uploadArticleImage}
                  initialUrl={
                    watch("translations")?.[locale.key]?.bannerImage1Url ?? ""
                  }
                  lockedRatio={AspectRatio.FourToOne}
                  defaultCropBoxWidth={300}
                  key={`translations.${locale.key}.bannerImage1Url`}
                />
              </List.Item>

              <List.Item key={`translations.${locale.key}.bannerImage2Url`}>
                <Paragraph>
                  {`Banner Image 2 URL: ${
                    locale.label ? `(${locale.label})` : ""
                  }`}
                </Paragraph>
                <DropAndCrop
                  title={`Banner Image 2 URL: ${
                    locale.label ? `(${locale.label})` : ""
                  }`}
                  previewImageWidth={"10"}
                  initialUrl={
                    watch("translations")?.[locale.key]?.bannerImage2Url ?? ""
                  }
                  setUploadUrl={(url: string) =>
                    handleUploadedImageUrl({
                      url,
                      locale: locale.key,
                      fieldName: "bannerImage2Url",
                    })
                  }
                  setBlurhash={(url: string) =>
                    handleUploadedImageUrl({
                      url,
                      locale: locale.key,
                      fieldName: "banner2Blurhash",
                    })
                  }
                  uploadImage={ArticleService.uploadArticleImage}
                  lockedRatio={AspectRatio.FourToOne}
                  defaultCropBoxWidth={300}
                  key={`translations.${locale.key}.bannerImage2Url`}
                />
              </List.Item>
            </List>
          </Col>
        </Row>
      )}
    </FormEdit>
  );
};

const StyledSelect = styled(Select)`
  width: 12rem;
`;
