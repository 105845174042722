import { memo } from "react";
import { PopularByMonthsListPage } from "./PopularByMonthsListPage";
import { PopularByWeeksListPage } from "./PopularByWeeksListPage";

export const PopularListForChild = () => {
  return (
    <>
      <PopularByWeeksListPage title="Popular - Child Week" audience="child" />
      <PopularByMonthsListPage title="Popular - Child Month" />
    </>
  );
};
export const PopularListForPregnancy = () => {
  return (
    <PopularByWeeksListPage
      title="Popular - Pregnancy Week"
      audience="pregnancy"
    />
  );
};
