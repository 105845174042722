import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useArticles, useBlogPosts, useCountry, usePodcasts } from ".";
import { RecommendedService } from "../services/RecommendedService";
import { PopularType } from "../utils";
import { useFAQ } from "./FAQContext";

type DocumentReference<T> = firebase.default.firestore.DocumentReference<T>;
type DocumentData = firebase.default.firestore.DocumentData;

type ContextState = {
  recommendedContents: Popular[];
  loading: boolean;
  error: Error | null;
  getById: (id: Popular["id"]) => Popular | null;
  onCreate: (
    data: Partial<Popular>
  ) => Promise<DocumentReference<DocumentData>>;
  onUpdate: (id: string, data: Partial<Popular>) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
};

const RecommendedContext = createContext<ContextState>({
  recommendedContents: [],
  loading: false,
  error: null,
  getById: () => null,
  onCreate: () => null,
  onUpdate: () => null,
  onDelete: () => null,
});

export const RecommendedProvider = ({ ...rest }) => {
  const [recommendedContents, setRecommendedContents] = useState<Popular[]>([]);
  const [loadingRecommendedContents, setLoadingRecommendedContents] =
    useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const recommendedService = useMemo(
    () =>
      currentCountry?.abb ? new RecommendedService(currentCountry?.abb) : null,
    [currentCountry?.abb]
  );

  useEffect(() => {
    if (!recommendedService) {
      return;
    }

    setLoadingRecommendedContents(true);

    const unSubscribeRecommendedArticle = recommendedService?.subscribe(
      (_error, _recommendedContents) => {
        setRecommendedContents(_recommendedContents);
        setError(_error);
        setLoadingRecommendedContents(false);
      }
    );

    return () => {
      unSubscribeRecommendedArticle();
    };
  }, [recommendedService]);

  const getById = useCallback(
    (id: Popular["id"]) => {
      if (loadingRecommendedContents) return null;

      const _recommended = recommendedContents.find(a => a.id === id) || null;
      if (!_recommended) return null;

      return _recommended;
    },
    [loadingRecommendedContents, recommendedContents]
  );

  const loading = loadingRecommendedContents;

  const value = useMemo(
    () => ({
      recommendedContents,
      loading,
      error,
      getById,
      onCreate: recommendedService?.create,
      onUpdate: recommendedService?.update,
      onDelete: recommendedService?.delete,
    }),
    [
      recommendedContents,
      loading,
      error,
      getById,
      recommendedService?.create,
      recommendedService?.update,
      recommendedService?.delete,
    ]
  );

  return <RecommendedContext.Provider value={value} {...rest} />;
};

export const useRecommendedContents = () => {
  const context = useContext(RecommendedContext);

  if (context === undefined) {
    throw new Error(
      "useRecommendedArticleByMonths must be used within an RecommendedProvider"
    );
  }
  return context;
};

export const useRecommendedContentByMonths = () => {
  const { recommendedContents, loading } = useRecommendedContents();

  const _recommendedContentsByMonth = recommendedContents.filter(
    ({ type }) => type === PopularType.MONTH
  );
  return { recommendedContents: _recommendedContentsByMonth, loading };
};

export const useRecommendedContentByWeeks = () => {
  const { recommendedContents, loading } = useRecommendedContents();

  const _recommendedContentsByWeek = recommendedContents.filter(
    ({ type }) => type === PopularType.WEEK
  );
  return { recommendedContents: _recommendedContentsByWeek, loading };
};

export const useRecommendedContent = (recommendedContentId: Popular["id"]) => {
  const [recommendedContent, setRecommendedContent] = useState<Popular | null>(
    null
  );
  const [loading, setLoading] = useState(true);

  const { getById, loading: loadingRecommendedArticle } =
    useRecommendedContents();
  const { getById: getArticleById, loading: loadingArticles } = useArticles();
  const { getById: getBlogPostById, loading: loadingBlogPosts } =
    useBlogPosts();
  const {
    getEpisodeById: getPodcastEpisodeById,
    loading: loadingPodcastEpisode,
  } = usePodcasts();
  const { getById: getFaqById, loading: loadingFaq } = useFAQ();
  useEffect(() => {
    if (
      loadingRecommendedArticle ||
      loadingArticles ||
      loadingBlogPosts ||
      loadingPodcastEpisode ||
      loadingFaq
    ) {
      return;
    }
    const data = getById(recommendedContentId);
    if (data) {
      setRecommendedContent({
        ...data,
        content: data.content,
      });
    }
    setLoading(false);
  }, [
    loadingRecommendedArticle,
    loadingArticles,
    loadingBlogPosts,
    loadingPodcastEpisode,
    loadingFaq,
    getById,
    getArticleById,
    getBlogPostById,
    getPodcastEpisodeById,
    recommendedContentId,
    getFaqById,
  ]);

  return { recommendedContent, loading };
};
