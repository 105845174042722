import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { MiniJourneyService } from "../services/";
import { useCountry } from "./CountryContext";
import firebase from "../firebase";

type ContextState = {
  miniJourneys: MiniJourney[];
  loading: boolean;
  error: Error | null;
  getById: (id: MiniJourney["id"]) => MiniJourney | null;
  createMiniJourney: (
    miniJourney: MiniJourney,
    polls: MiniJourneyPoll[]
  ) => Promise<void>;
  updateMiniJourney: (
    id: string,
    miniJourney: MiniJourney,
    polls: MiniJourneyPoll[]
  ) => Promise<void>;
  deleteMiniJourney: (id: string) => Promise<void>;
  subscribeToSubmissions: (
    miniJourneyId: string,
    callback: (error: Error | null, data: MiniJourneySubmission[]) => void
  ) => any;
  uploadMiniJourneyImage: (file: Blob | ArrayBuffer, fileName: string) => any;
};

const MiniJourneyContext = createContext<ContextState>({
  miniJourneys: [],
  loading: false,
  error: null,
  getById: () => null,
  createMiniJourney: async () => null,
  updateMiniJourney: async () => null,
  deleteMiniJourney: async () => null,
  subscribeToSubmissions: async () => null,
  uploadMiniJourneyImage: async () => null,
});

export const MiniJourneysProvider = ({ ...rest }) => {
  const [miniJourneys, setMiniJourneys] = useState<MiniJourney[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const miniJourneyService = useMemo(
    () => new MiniJourneyService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    const unSubscribeMiniJourney = miniJourneyService.subscribe(
      (_error, _miniJourneys) => {
        setMiniJourneys(_miniJourneys);
        setError(_error);
        setLoading(false);
      }
    );

    return () => {
      unSubscribeMiniJourney();
    };
  }, [miniJourneyService]);

  const createMiniJourney = useCallback(
    async (miniJourney: MiniJourney, polls: MiniJourneyPoll[]) =>
      await miniJourneyService.create(miniJourney, polls),
    [miniJourneyService]
  );

  const updateMiniJourney = useCallback(
    async (id: string, miniJourney: MiniJourney, polls: MiniJourneyPoll[]) =>
      await miniJourneyService.update(id, miniJourney, polls),
    [miniJourneyService]
  );

  const deleteMiniJourney = useCallback(
    async (id: string) => await miniJourneyService.delete(id),
    [miniJourneyService]
  );

  const subscribeToSubmissions = useCallback(
    (
      miniJourneyId: string,
      callback: (error: Error | null, data: MiniJourneySubmission[]) => void
    ) => miniJourneyService.subscribeToSubmissions(miniJourneyId, callback),
    [miniJourneyService]
  );

  const uploadMiniJourneyImage = useCallback(
    async (file: Blob | ArrayBuffer, fileName: string) =>
      await miniJourneyService.uploadMiniJourneyImage(file, fileName),
    [miniJourneyService]
  );

  const getById = useCallback(
    (id: MiniJourney["id"]) => {
      const _miniJourney = miniJourneys.find(a => a.id === id);
      return _miniJourney || null;
    },
    [miniJourneys]
  );

  const value = useMemo(
    () => ({
      miniJourneys,
      loading,
      error,
      getById,
      createMiniJourney,
      updateMiniJourney,
      deleteMiniJourney,
      subscribeToSubmissions,
      uploadMiniJourneyImage,
    }),
    [
      miniJourneys,
      loading,
      error,
      getById,
      createMiniJourney,
      updateMiniJourney,
      deleteMiniJourney,
      subscribeToSubmissions,
      uploadMiniJourneyImage,
    ]
  );

  return <MiniJourneyContext.Provider value={value} {...rest} />;
};

export const useMiniJourneys = () => {
  const context = useContext(MiniJourneyContext);
  if (context === undefined) {
    throw new Error(
      "useMiniJourneys must be used within an MiniJourneysProvider"
    );
  }
  return context;
};

export const useMiniJourney = (id: MiniJourney["id"]) => {
  const [miniJourney, setMiniJourney] = useState<MiniJourney | null>(null);
  const [loading, setLoading] = useState(true);

  const { getById, loading: loadingMiniJourneys } = useMiniJourneys();

  useEffect(() => {
    if (loadingMiniJourneys) {
      return;
    }

    setMiniJourney(getById(id));
    setLoading(false);
  }, [getById, id, loadingMiniJourneys]);

  return { miniJourney, loading };
};
