import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContentCollections, useCountry } from "../../context";
import { Sorter } from "../../components/theme/util/sorter";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import BJList from "../../components/theme/components/BJList";
import { newRoute } from "../../routes";
import { Switch } from "antd";
import { DeepLinkForList } from "../../components/theme";
import { DeepLinkType } from "../../utils";

type DatasourceType = ContentCollection & {
  isVisible?: boolean;
  deepLink?: DeepLinkProps;
  title: string;
};

export const ContentCollectionList = () => {
  const navigate = useNavigate();
  const { contentCollections, loading } = useContentCollections();
  const [originalTable, setOriginalTable] = useState<DatasourceType[]>([]);
  const { currentCountry, primaryLocale } = useCountry();

  useEffect(() => {
    const contentCollectionTableSource: DatasourceType[] = contentCollections
      .sort(
        (a: ContentCollection, b: ContentCollection) =>
          a.sortOrder - b.sortOrder
      )
      .map(contentCollection => ({
        ...contentCollection,
        title: contentCollection?.translations[primaryLocale?.key]?.title,
        isVisible: isContentCollectionVisibleInMobile(contentCollection),
        key: contentCollection.id,
        deepLink: {
          type: DeepLinkType.ContentCollection,
          id: contentCollection.id,
          countryCode: currentCountry?.abb,
        },
      }));

    setOriginalTable(contentCollectionTableSource);
  }, [contentCollections, currentCountry?.abb, primaryLocale?.key]);

  const isContentCollectionVisibleInMobile = (
    contentCollection: ContentCollection
  ) => {
    return contentCollection.isActive;
  };

  const handleNewCollection = () => {
    navigate(newRoute);
  };

  const onClickRow = (record: DatasourceType) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const columns: BJColumnType<DatasourceType>[] = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },

    {
      title: "Published",
      dataIndex: "isVisible",
      key: "isVisible",
      width: 0.5,
      ellipsis: true,
      onFilter: value => !value,
      filters: [
        {
          text: "Visible",
          value: true,
        },
        {
          text: "Not visible",
          value: false,
        },
      ],
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (value: boolean) => (
        <Switch checked={value} size="small" disabled />
      ),
    },
    DeepLinkForList(),
  ];

  return (
    <BJList
      loading={loading}
      filterOnlyDisplayList
      addButtonCaption={"New Collection"}
      title={"Content collections"}
      OriginalList={originalTable}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewCollection}
      recordCountSuffix={"Collections"}
    />
  );
};
