import firebase, { firestore } from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";
import { uploadImage } from "./serviceUtils";

export class OffersService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  static offerBannerFileStoragePath = "offers/banners";
  static offerSignupFileStoragePath = "offers/signup";
  static offerFileStoragePath = "offers";

  static toOffer = (doc: firebase.firestore.DocumentData): OfferV2 => {
    const data = doc.data();

    return {
      id: doc.id,
      ...data,
      tagWords: data.tagWords ?? [],
      translations: data.translations ?? {},
    };
  };

  static toOfferSignUps = (
    doc: firebase.firestore.DocumentData
  ): OfferSignUp => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
    };
  };

  subscribe = (callback: (err: Error | null, offers: OfferV2[]) => void) =>
    firestore.collection(CollectionPaths.Offer[this.countryCode]).onSnapshot(
      snapshot => {
        const offers = snapshot.docs.map(OffersService.toOffer);
        callback(null, offers);
      },
      err => {
        logException(err);
        callback(err, []);
      }
    );

  create = (data: Omit<OfferV2, "id">) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Offer[this.countryCode])
      .add(data);

  update = (id: string, data: Omit<OfferV2, "id">) =>
    firestore
      .collection(CollectionPaths.Offer[this.countryCode])
      .doc(id)
      .update({ ...data });

  deleteOffer = (id: string) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Offer[this.countryCode])
      .doc(id)
      .delete();

  static uploadOfferBannerImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => {
    const url = uploadImage(file, this.offerBannerFileStoragePath, fileName);
    return url;
  };

  static uploadOfferSignUpImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => {
    const url = uploadImage(file, this.offerSignupFileStoragePath, fileName);
    return url;
  };

  static uploadOfferImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => {
    const url = uploadImage(file, this.offerFileStoragePath, fileName);
    return url;
  };

  updateSortOrder = (offers: OfferV2[]) => {
    const batch = firebase.firestore().batch();
    offers.forEach(offer => {
      const ref = firebase
        .firestore()
        .collection(CollectionPaths.Offer[this.countryCode])
        .doc(offer.id);
      batch.update(ref, { sortOrder: offer.sortOrder });
    });
    return batch.commit();
  };

  subscribeSignUpOffers = (
    callback: (err: Error | null, offers: OfferSignUp[]) => void
  ) =>
    firestore.collection("offerSignups").onSnapshot(
      snapshot => {
        const offers = snapshot.docs.map(OffersService.toOfferSignUps);
        callback(null, offers);
      },
      err => {
        console.log(err);
        callback(err, []);
      }
    );

  updateTopFiveOffers = async (offers: OfferV2[]) => {
    const batch = firestore.batch();
    const currentTopFiveSnapshot = await firestore
      .collection(CollectionPaths.TopFiveOffer[this.countryCode])
      .get();
    const selectedOfferIds = new Set(offers.map(offer => offer.id));
    currentTopFiveSnapshot.docs.forEach(doc => {
      if (!selectedOfferIds.has(doc.id)) {
        const offerRef = firestore
          .collection(CollectionPaths.TopFiveOffer[this.countryCode])
          .doc(doc.id);
        batch.delete(offerRef);
      }
    });
    offers.forEach((offer, index) => {
      const offerRef = firestore
        .collection(CollectionPaths.TopFiveOffer[this.countryCode])
        .doc(offer.id);
      batch.set(offerRef, { ...offer, sortOrder: index }, { merge: true });
    });

    await batch.commit();
  };

  getTopFiveOffers = async (): Promise<OfferV2[]> => {
    const snapshot = await firestore
      .collection(CollectionPaths.TopFiveOffer[this.countryCode])
      .get();
    return snapshot.docs.map(OffersService.toOffer);
  };
  clearTopFiveOffers = async () => {
    const snapshot = await firestore
      .collection(CollectionPaths.TopFiveOffer[this.countryCode])
      .get();
    const batch = firestore.batch();

    snapshot.docs.forEach(doc => {
      const offerRef = firestore
        .collection(CollectionPaths.TopFiveOffer[this.countryCode])
        .doc(doc.id);
      batch.delete(offerRef);
    });

    await batch.commit();
  };
}
