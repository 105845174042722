import { useNavigate } from "react-router-dom";
import { usePopularContentByMonths } from "../../context";
import BJList from "../../components/theme/components/BJList";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";
import { newRoute } from "../../routes";

type Props = {
  title?: string;
};

export const PopularByMonthsListPage: React.FC<Props> = ({
  title = "Popular",
}) => {
  const navigate = useNavigate();
  const { popularContents, loading } = usePopularContentByMonths();

  const onClickRow = (record: Popular) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const handleNewDietary = () => {
    navigate(newRoute);
  };

  const columns: BJColumnType<Popular>[] = [
    {
      title: "Months",
      dataIndex: "month",
      key: "month",
      width: 2,
      ellipsis: true,
      onFilter: (value, record) =>
        record.month!.toString().includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  return (
    <BJList
      filterOnlyDisplayList
      loading={loading}
      addButtonCaption={"New popular"}
      title={title}
      OriginalList={popularContents.map(x => ({ ...x, key: x.id }))}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewDietary}
      recordCountSuffix={"Popular"}
    />
  );
};
