import {
  type ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { EditOutlined } from "@ant-design/icons";
import { Row, Col, Card, Space } from "antd";

import { formatStringDate, generateFormError } from "../../../utils";
import { UserService } from "../../../services";
import { isValidEmail } from "../../../utils/isValidEmail";

import { FormModal } from "../../../components";
import BJInput from "../../../components/theme/atoms/BJInput";
import BJButton, { ButtonTypes } from "../../../components/theme/atoms/Button";
import { BJNotification } from "../../../components/theme/atoms/BJNotification";
import { NotificationType } from "../../../components/theme/util/notificationObj";
import { RowItem } from "./RowItem";

export type DisplayUser = {
  id?: string;
  name?: string;
  email?: string;
  city?: string;
  creationTime: string;
  dateOfBirth: string;
  partnerName: string;
  partnerId: string;
  locale: string;
  country: string;
  followersCount?: number;
};

export const UserInformation = ({ user }: { user: DisplayUser }) => {
  const [email, setEmail] = useState("");
  const [isEditEmail, toggleIsEditEmail] = useState(false);
  const [isResetPassword, toggleIsResetPassword] = useState(false);

  const onClickEditEmail = useCallback(() => {
    toggleIsEditEmail(true);
  }, []);

  const onHideEditModal = useCallback(() => {
    toggleIsEditEmail(false);
  }, []);

  const onChangeEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const onSubmitEmail = useCallback(async () => {
    await UserService.updateUserEmail(user.id, user.email, email);
    toggleIsEditEmail(false);
  }, [email, user.email, user.id]);

  const onClickResetPassword = useCallback(async () => {
    try {
      toggleIsResetPassword(true);
      await UserService.resetUserPassword(email);
      BJNotification({
        type: NotificationType.Success,
        message: "Success",
        description: `The reset password link has been sent to ${email}.`,
      });
    } catch (error) {
      const errorMessage = generateFormError(error);

      BJNotification({
        type: NotificationType.Error,
        message: "Failed",
        description: errorMessage,
      });
    } finally {
      toggleIsResetPassword(false);
    }
  }, [email]);

  const isSaveEnabled = useMemo(() => {
    return isValidEmail(email) && email !== user.email;
  }, [email, user.email]);

  useEffect(() => {
    setEmail(user.email);
  }, [user.email]);

  return (
    <Fragment>
      <FormModal
        onSubmit={onSubmitEmail}
        onHide={onHideEditModal}
        enableSave={isSaveEnabled}
        enableDelete={false}
        modalTitle="Edit Email"
        show={isEditEmail}
      >
        <BJInput
          placeholder="New email here"
          value={email}
          onChange={onChangeEmail}
        />
      </FormModal>
      <Card title="User Information" bordered={false}>
        <Row>
          <RowItem title="Name:" content={user?.name} />
          <RowItem title="Email:" content={user?.email} />
        </Row>
        <Row>
          <RowItem title="City:" content={user?.city} />
          <RowItem
            title="Created:"
            content={formatStringDate(user?.creationTime)}
          />
        </Row>
        <Row>
          <RowItem title="Date of Birth:" content={user?.dateOfBirth} />
        </Row>
        <Row style={{ marginTop: 16 }}>
          <Col span={8}>
            <Row gutter={4}>
              <Space>
                <BJButton
                  size="middle"
                  buttonType={ButtonTypes.Primary}
                  onClick={onClickEditEmail}
                >
                  <EditOutlined />
                  Edit email
                </BJButton>
                <BJButton
                  loading={isResetPassword}
                  size="middle"
                  buttonType={ButtonTypes.Delete}
                  onClick={onClickResetPassword}
                >
                  Reset password
                </BJButton>
              </Space>
            </Row>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};
