import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import firebase from "../firebase";
import { AppLinksService } from "../services";
import { useCountry } from "./CountryContext";

type ContextState = {
  loading: boolean;
  appLinks: AppLink[];
  error: Error | null;
  onCreate: (appLink: AppLink) => Promise<void>;
  onUpdate: (screenName: string, externalUrl: string) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
};

const AppLinksContext = createContext<ContextState>({
  appLinks: [],
  loading: false,
  error: null,
  onCreate: async () => null,
  onUpdate: async () => null,
  onDelete: async () => null,
});

export const AppLinksProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [appLinks, setAppLinks] = useState<AppLink[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const appLinksService = useMemo(
    () => new AppLinksService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    setLoading(true);
    const unsub = appLinksService.subscribe((_error, _appLinks) => {
      const convertedAppLinks = Object.keys(_appLinks).map(
        key => _appLinks[key]
      );
      setAppLinks(convertedAppLinks);
      setError(_error);
      setLoading(false);
    });

    return unsub;
  }, [appLinksService]);

  const onCreate = useCallback(
    async (appLink: AppLink): Promise<void> => {
      await appLinksService.create(appLink);
    },
    [appLinksService]
  );

  const onUpdate = useCallback(
    async (screenName: string, externalUrl: string) =>
      await appLinksService.update(screenName, externalUrl),
    [appLinksService]
  );
  const onDelete = useCallback(
    async (id: string) => await appLinksService.delete(id),
    [appLinksService]
  );

  const value = useMemo(
    () => ({
      appLinks,
      loading,
      error,
      onCreate,
      onUpdate,
      onDelete,
    }),
    [appLinks, loading, error, onCreate, onUpdate, onDelete]
  );

  return (
    <AppLinksContext.Provider value={value}>
      {children}
    </AppLinksContext.Provider>
  );
};

export const useAppLinks = () => {
  const context = React.useContext(AppLinksContext);
  if (context === undefined) {
    throw new Error("useAppLinks must be used within an AppLinksProvider");
  }
  return context;
};
