import firebase from "firebase/compat/app";

const sortOrderOnName = (a: string, b: string) => {
  const topMunicipalities = ["Stockholm", "Göteborg", "Malmö"];
  if (topMunicipalities.includes(a)) {
    return -1;
  } else if (topMunicipalities.includes(b)) {
    return 1;
  }

  return a.localeCompare(b, "en", { sensitivity: "base" });
};

const toMunicipalities = (doc: any): string[] => {
  return doc.data().names.sort(sortOrderOnName);
};

const getData = (): Promise<Search> =>
  firebase
    .firestore()
    .collection("search")
    .get()
    .then(res => {
      return {
        municipalities: {
          names: toMunicipalities(res.docs[1]),
        },
      };
    });

export const SearchService = { getData };
