import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { useCountry } from "./CountryContext";
import { FaqCategoryService, FAQService } from "../services";
import firebase from "../firebase";

type ContextState = {
  questions: FullQuestionV2[];
  categories: FaqCategory[];
  loading: boolean;
  error: Error | null;
  getById: (id: Question["id"]) => Question | null;
  updateFAQCategory: (category: FaqCategory[]) => Promise<void>;
  updateQuestion: (id: string, data: Omit<Question, "id">) => Promise<void>;
  createQuestion: (
    data: Omit<Question, "id">
  ) => Promise<firebase.firestore.DocumentReference | null>;
  deleteQuestion: (id: string) => Promise<void>;
  updateFaqSortOrder: (docId: string, sortOrder: number) => Promise<unknown>;
};

const FAQContext = createContext<ContextState>({
  questions: [],
  categories: [],
  loading: false,
  error: null,
  getById: () => null,
  updateFAQCategory: async () => null,
  updateQuestion: async () => null,
  createQuestion: async () => null,
  deleteQuestion: async () => null,
  updateFaqSortOrder: async () => null,
});

export const FAQProvider = ({ ...rest }) => {
  const [questionsData, setQuestionsData] = useState<QuestionV2[]>([]);
  const [categoriesData, setCategoriesData] = useState<FaqCategory[]>([]);
  const [questions, setQuestions] = useState<FullQuestionV2[]>([]);
  const [categories, setCategories] = useState<FaqCategory[]>([]);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const faqCategoryService = useMemo(
    () => new FaqCategoryService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  const faqService = useMemo(() => {
    return currentCountry?.abb ? new FAQService(currentCountry.abb) : null;
  }, [currentCountry]);

  useEffect(() => {
    setLoadingQuestions(true);
    setLoadingCategories(true);
    if (!faqService) {
      return;
    }

    const unsubscribeQuestions = faqService?.subscribe((_error, _question) => {
      setQuestionsData(_question);
      setError(_error);
      setLoadingQuestions(false);
    });

    const unsubscribeCategories = faqCategoryService.subscribeToCategories(
      (_error, _categories) => {
        setCategoriesData(_categories);
        setError(_error);
        setLoadingCategories(false);
      }
    );

    return () => {
      unsubscribeQuestions();
      unsubscribeCategories();
    };
  }, [faqCategoryService, faqService]);

  useEffect(() => {
    if (loadingQuestions || loadingCategories) {
      return;
    }

    const categoryMap = new Map<QuestionV2["id"], FaqCategory>();
    categoriesData?.forEach(category => {
      categoryMap.set(category.id, category);
    });

    const _questions: FullQuestionV2[] = questionsData.map(question => {
      return {
        ...question,
        category: categoryMap.get(question.categoryId) || null,
      };
    });
    setQuestions(_questions);
    setCategories(categoriesData);
  }, [loadingQuestions, loadingCategories, questionsData, categoriesData]);

  const updateFAQCategory = useCallback(
    async (category: FaqCategory[]) => {
      await faqCategoryService.update(category);
    },
    [faqCategoryService]
  );

  const getById = useCallback(
    (id: QuestionV2["id"]) => {
      const question = questions.find(a => a.id === id);
      return question || null;
    },
    [questions]
  );

  const updateQuestion = useCallback(
    async (id: string, data: Omit<Question, "id">) =>
      await faqService.update(id, data),
    [faqService]
  );

  const createQuestion = useCallback(
    async (data: Omit<Question, "id">) => await faqService.create(data),
    [faqService]
  );

  const deleteQuestion = useCallback(
    async (id: string) => await faqService.deleteQuestion(id),
    [faqService]
  );

  const updateFaqSortOrder = useCallback(
    async (docId: string, sortOrder: number) =>
      await faqService.updateFaqSortOrder(docId, sortOrder),
    [faqService]
  );

  const loading = loadingQuestions || loadingCategories;

  const value = useMemo(
    () => ({
      questions,
      categories,
      loading,
      error,
      getById,
      updateFAQCategory,
      updateQuestion,
      createQuestion,
      deleteQuestion,
      updateFaqSortOrder,
    }),
    [
      questions,
      categories,
      loading,
      error,
      getById,
      updateFAQCategory,
      updateQuestion,
      createQuestion,
      deleteQuestion,
      updateFaqSortOrder,
    ]
  );

  return <FAQContext.Provider value={value} {...rest} />;
};

export const useFAQ = () => {
  const context = useContext(FAQContext);
  if (context === undefined) {
    throw new Error("useFAQ must be used within an FAQProvider");
  }
  return context;
};

export const useQuestion = (questionId: QuestionV2["id"]) => {
  const [question, setQuestion] = useState<QuestionV2 | null>(null);
  const [loading, setLoading] = useState(true);

  const { getById, loading: loadingQuestion } = useFAQ();

  useEffect(() => {
    if (loadingQuestion) {
      return;
    }

    setQuestion(getById(questionId));
    setLoading(false);
  }, [loadingQuestion, getById, questionId]);

  return { question, loading };
};
