import { useCallback, useEffect, useState } from "react";
import { useArticles, useFAQ, useOffers } from "../context";
import { AppNavigationType } from "../utils";
import { useCountry } from "../context";

type InAppOptionProps = {
  key: string;
  value: string;
};

export const useFilteredList = (type: AppNavigationType) => {
  const { articles, loading: loadingArticles } = useArticles();
  const { questions, loading: loadingFaq } = useFAQ();
  const { offers, loading: loadingOffers } = useOffers();
  const [filterList, setFilterList] = useState<InAppOptionProps[]>([]);
  const loading = loadingArticles || loadingFaq || loadingOffers;
  const { primaryLocale } = useCountry();

  const getFilterableList = useCallback(() => {
    if (type === AppNavigationType.FAQ) {
      setFilterList(
        questions.map(q => ({
          key: q.id,
          value: q.translations[primaryLocale?.key]?.question,
        }))
      );
    } else if (type === AppNavigationType.ARTICLE) {
      setFilterList(
        articles.map(article => ({
          key: article.id,
          value: article.translations[primaryLocale.key]?.title,
        }))
      );
    } else if (type === AppNavigationType.OFFER) {
      setFilterList(
        offers.map(offer => ({
          key: offer.id,
          value: offer.translations[primaryLocale.key]?.title,
        }))
      );
    }
  }, [articles, offers, primaryLocale.key, questions, type]);

  useEffect(() => {
    if (loadingArticles || loadingFaq || loadingOffers) {
      return;
    }
    getFilterableList();
  }, [getFilterableList, loadingArticles, loadingFaq, loadingOffers, type]);

  return { filterList, loading };
};
