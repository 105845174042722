import { v4 as uuidv4 } from "uuid";
import firebase from "../firebase";
import { uploadImage } from "./serviceUtils";
import { CountryCodeService } from "./CountryCodeService";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";

const CONTENT = "content";
const mileStoneFileStoragePath = "milestones";
const mileStoneDayFileStoragePath = "milestones/days";
const weeksCollection = "week";
const monthsCollection = "month";

export class MilestonesService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  static toDays = (data: any): MilestoneDay => {
    return {
      id: data.id ?? uuidv4(),
      ...data,
    };
  };

  static toMilestone = (doc: any): Milestone => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      text: data.text ?? "",
      imageUrl: data.imageUrl ?? "",
      days: data.days?.map(MilestonesService.toDays) ?? [],
      imageUrlFruit: data.imageUrlFruit ?? null,
      imageUrlFetus: data.imageUrlFetus ?? null,
      weight: data.weight ?? "",
      length: data.length ?? "",
      weightUnit: data.weightUnit ?? "",
      lengthUnit: data.lengthUnit ?? "",
    };
  };

  uploadMilestoneImage = async (file: Blob | ArrayBuffer, fileName: string) => {
    const url = uploadImage(file, mileStoneFileStoragePath, fileName);
    return url;
  };

  uploadMilestoneDayImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => {
    const url = uploadImage(file, mileStoneDayFileStoragePath, fileName);
    return url;
  };

  subscribe = (callback: (err: Error | null, snapshot: Milestone[]) => void) =>
    firebase
      .firestore()
      .collection(CollectionPaths.PregnancyMilestones[this.countryCode])
      .onSnapshot(
        snapshot => {
          const docs = snapshot.empty
            ? []
            : snapshot.docs.map(MilestonesService.toMilestone);
          callback(null, docs);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  childWeeklySubscribe = (
    callback: (err: Error | null, snapshot: Milestone[]) => void
  ) =>
    firebase
      .firestore()
      .collection(CollectionPaths.ChildMilestones[this.countryCode])
      .doc(weeksCollection)
      .collection(CONTENT)
      .onSnapshot(
        snapshot => {
          const docs = snapshot.empty
            ? []
            : snapshot.docs.map(MilestonesService.toMilestone);
          callback(null, docs);
        },
        err => {
          callback(err, []);
        }
      );

  childMonthlySubscribe = (
    callback: (err: Error | null, snapshot: Milestone[]) => void
  ) =>
    firebase
      .firestore()
      .collection(CollectionPaths.ChildMilestones[this.countryCode])
      .doc(monthsCollection)
      .collection(CONTENT)
      .onSnapshot(
        snapshot => {
          const docs = snapshot.empty
            ? []
            : snapshot.docs.map(MilestonesService.toMilestone);
          callback(null, docs);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  create = (data: Milestone, childMilestone: boolean) =>
    childMilestone
      ? firebase
          .firestore()
          .collection(CollectionPaths.ChildMilestones[this.countryCode])
          .doc(data?.month ? monthsCollection : weeksCollection)
          .collection(CONTENT)
          .add(data)
      : firebase
          .firestore()
          .collection(CollectionPaths.PregnancyMilestones[this.countryCode])
          .add(data);

  update = (id: string, data: Milestone, childMilestone: boolean) =>
    childMilestone
      ? firebase
          .firestore()
          .collection(CollectionPaths.ChildMilestones[this.countryCode])
          .doc(data?.month ? monthsCollection : weeksCollection)
          .collection(CONTENT)
          .doc(id)
          .update({ ...data })
      : firebase
          .firestore()
          .collection(CollectionPaths.PregnancyMilestones[this.countryCode])
          .doc(id)
          .update({ ...data });

  deleteQuestion = (id: string, childMilestone: boolean, isMonth: boolean) =>
    childMilestone
      ? firebase
          .firestore()
          .collection(CollectionPaths.ChildMilestones[this.countryCode])
          .doc(isMonth ? monthsCollection : weeksCollection)
          .collection(CONTENT)
          .doc(id)
          .delete()
      : firebase
          .firestore()
          .collection(CollectionPaths.PregnancyMilestones[this.countryCode])
          .doc(id)
          .delete();
}
