import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ContentService } from "../services";
import {
  firebaseCollectionNames,
  getFormattedErrorMessage,
  getLanguages,
} from "../utils";

type ContextState = {
  contents: ContentItemType[];
  loading: boolean;
  error: Error | null;
};

const ContentContext = createContext<ContextState>({
  contents: [],
  loading: false,
  error: null,
});

export const ContentsProvider = ({ ...rest }) => {
  const [contents, setContents] = useState<ContentItemType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    return ContentService.subscribe((_error, _contentList) => {
      //special logic for pregnancyTips: till it moves out of content collection it should be gettable for Tips section event its marked as deleted in db
      const filteredContents = _contentList.filter(
        x =>
          !x.deleted ||
          x.id === firebaseCollectionNames.content.pregnancyTipsSub
      );
      setContents(filteredContents);
      setError(_error);
      setLoading(false);
    });
  }, []);

  const value = useMemo(
    () => ({ contents, loading, error }),
    [contents, loading, error]
  );

  return <ContentContext.Provider value={value} {...rest} />;
};

export const useContents = () => {
  const context = React.useContext(ContentContext);
  if (context === undefined) {
    throw new Error("useContents must be used within an ContentsProvider");
  }
  return context;
};

export const useContentDetails = (contentId: string) => {
  const [contentText, setContentText] = useState<any>(null);
  const [editorContent, setEditorContent] = useState<CommonContent | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null | string>(null);
  const [saving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const languages = getLanguages();
  const [selectedLanguage, setSelectedLanguage] = useState(
    languages.find(x => x.priority === 1)
  );
  const getEditorContent = useCallback(async () => {
    setLoading(true);
    try {
      const content = await ContentService.getEditorContent(
        contentId,
        selectedLanguage?.shortName
      );
      const data = content.data();
      setEditorContent(
        data
          ? { ...(data as CommonContent) }
          : ({ name: "", contentText: "" } as CommonContent)
      );
      setLoading(false);
    } catch (error) {
      const message = getFormattedErrorMessage(error);
      setError(message);
      setLoading(false);
      console.log(error);
    }
  }, [contentId, selectedLanguage]);

  useEffect(() => {
    getEditorContent();
  }, [contentId, selectedLanguage, getEditorContent]);

  const deleteContent = async () => {
    setDeleting(true);
    try {
      await ContentService.deleteContent(contentId);
      setDeleting(false);
    } catch (error) {
      const message = getFormattedErrorMessage(error);
      setError(message);
      setLoading(false);
      console.log(error);
    }
  };

  const flagDeleteContent = async () => {
    setDeleting(true);
    try {
      await ContentService.flagDeleteContent(contentId);
      setDeleting(false);
    } catch (error) {
      const message = getFormattedErrorMessage(error);
      setError(message);
      setLoading(false);
      console.log(error);
    }
  };

  return {
    contentText,
    setContentText,
    deleteContent,
    flagDeleteContent,
    loading,
    error,
    selectedLanguage,
    setSelectedLanguage,
    saving,
    deleting,
    editorContent,
    contentId,
  };
};

export const useTips = () => {
  const [enTips, setEnTips] = useState([]);
  const [svTips, setSvTips] = useState([]);
  const { loading, contents, error } = useContents();

  useEffect(() => {
    if (!loading) {
      const pregTips = contents.find(
        x => x.id === firebaseCollectionNames.content.pregnancyTipsSub
      ) as any;
      if (pregTips) {
        setSvTips(pregTips.sv as string[]);
        setEnTips(pregTips.en as string[]);
      }
    }
  }, [loading, contents]);

  return {
    loading,
    enTips,
    svTips,
    error,
  };
};
