import { Form, Modal, Typography } from "antd";
import {
  Control,
  Controller,
  Path,
  PathValue,
  UnpackNestedValue,
  UseFormSetValue,
} from "react-hook-form";
import BJButton, { ButtonTypes } from "../../atoms/Button";
import { AudioTranscriptionService } from "../../../../services";
import { useState } from "react";
import { BJFlex } from "../../../styled";
import { BJInputFormItem } from "./BJFormInput";
import { logException } from "../../../../utils/exceptionLogger";
import styled from "styled-components";

export const BJFormAudioTranscription = <T,>({
  error,
  message,
  label,
  required,
  control,
  fieldName,
  disabled,
  extra,
  setValue,
  aiTranscriptionPayload,
}: {
  error?: boolean;
  message?: string;
  label: string;
  required?: boolean;
  control: Control<T>;
  fieldName: Path<T>;
  existingTranscriptions?: string[] | undefined;
  autoFocus?: boolean;
  disabled?: boolean;
  extra?: string;
  type?: string;
  setValue?: UseFormSetValue<T>;
  aiTranscriptionPayload?: AITranscriptionsPayload;
}) => {
  const [generatingTranscriptions, setGeneratingTranscriptions] =
    useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onGenerate = async () => {
    setGeneratingTranscriptions(true);
    setIsModalVisible(true);
    try {
      AudioTranscriptionService.generateTranscription(aiTranscriptionPayload);
    } catch (error) {
      logException(error);
    } finally {
      setGeneratingTranscriptions(false);
    }
  };

  const StyledFormItem = styled(Form.Item)`
    padding-top: 20px; // Added upper padding
  `;

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <BJFlex>
          <p>
            Generating Transcription...
            <br /> <br />
            Please note that this process may take 1 to 10 minutes.
            <br /> <br />
            Feel free to close the episode and return later whenever you want
            without waiting.
          </p>
        </BJFlex>
      </Modal>
      <StyledFormItem
        name={fieldName}
        required={required}
        validateStatus={error && "error"}
        {...(message
          ? {
              help: (
                <Typography.Paragraph type="danger">
                  {message}
                </Typography.Paragraph>
              ),
            }
          : undefined)}
        {...(extra
          ? {
              extra: (
                <Typography.Paragraph type="warning">
                  {extra}
                </Typography.Paragraph>
              ) as JSX.Element,
            }
          : undefined)}
      >
        <Controller
          control={control}
          name={fieldName}
          render={({ field: { onChange, value } }) => (
            <BJInputFormItem
              control={control}
              fieldName={fieldName}
              label={label}
              required={required}
              error={error}
              message={message}
              disabled={disabled}
              extra={extra}
              rows={6}
              value={value as unknown as string | number | readonly string[]}
              onChange={e => {
                onChange(e);
              }}
            />
          )}
        />
        {setValue && aiTranscriptionPayload && (
          <BJButton
            buttonType={ButtonTypes.Primary}
            loading={generatingTranscriptions}
            onClick={onGenerate}
          >
            Generate Transcription
          </BJButton>
        )}
      </StyledFormItem>
    </>
  );
};
