import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";

export class GeneralBlogPostService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  subscribe = (
    callback: (err: Error | null, generalPosts: GeneralBlogPost[]) => void
  ) =>
    firebase
      .firestore()
      .collection(CollectionPaths.GeneralBlogPosts[this.countryCode])
      .onSnapshot(
        snapshot => {
          const generalBlogPosts = snapshot.docs.map(
            (doc: firebase.firestore.DocumentData) => {
              const data = doc.data();
              return {
                id: doc.id,
                ...data,
                translations: data.translations ?? {},
              };
            }
          );
          callback(null, generalBlogPosts);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  create = (mapped: BlogPost) =>
    firebase
      .firestore()
      .collection(CollectionPaths.GeneralBlogPosts[this.countryCode])
      .add({ ...mapped });

  update = (mapped: BlogPost) =>
    firebase
      .firestore()
      .collection(CollectionPaths.GeneralBlogPosts[this.countryCode])
      .doc(mapped.id)
      .update({ ...mapped });

  addOrUpdate = async (mapped: BlogPost) => {
    if (mapped.id) {
      await this.update(mapped);
      return mapped.id;
    } else {
      const result = await this.create(mapped);
      return result.id;
    }
  };

  delete = (id: string) =>
    firebase
      .firestore()
      .collection(CollectionPaths.GeneralBlogPosts[this.countryCode])
      .doc(id)
      .delete();
}
