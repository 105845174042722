import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ContentMenuService } from "../services";
import { useCountry } from "./CountryContext";

type ContextState = {
  contentMenu: ContentMenu[];
  loading: boolean;
  error: Error | null;
};

const ContentMenuContext = createContext<ContextState>({
  contentMenu: [],
  loading: false,
  error: null,
});

export const ContentMenuProvider = ({ ...rest }) => {
  const [contentMenuData, setContentMenuData] = useState<ContentMenu[]>([]);
  const [contentMenu, setContentMenu] = useState<ContentMenu[]>([]);
  const [loadingContentMenu, setLoadingContentMenu] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();
  const contentMenuService = useMemo(
    () =>
      currentCountry?.abb ? new ContentMenuService(currentCountry.abb) : null,
    [currentCountry?.abb]
  );

  useEffect(() => {
    if (!contentMenuService) {
      return;
    }

    setLoadingContentMenu(true);

    const unsubContentMenu = contentMenuService.subscribe(
      (_error, _contentMenu) => {
        setContentMenuData(_contentMenu);
        setError(_error);
        setLoadingContentMenu(false);
      }
    );

    return () => {
      unsubContentMenu();
    };
  }, [contentMenuService]);

  useEffect(() => {
    if (loadingContentMenu) {
      return;
    }
    const _contentMenu: ContentMenu[] = contentMenuData
      ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
      ?.map(contentMenu => {
        return {
          ...contentMenu,
        };
      });
    setContentMenu(_contentMenu);
  }, [loadingContentMenu, contentMenuData]);
  const loading = loadingContentMenu;

  const value = useMemo(
    () => ({ contentMenu, loading, error }),
    [contentMenu, loading, error]
  );

  return <ContentMenuContext.Provider value={value} {...rest} />;
};

export const useContentMenu = () => {
  const context = React.useContext(ContentMenuContext);
  if (context === undefined) {
    throw new Error(
      "useContentMenu must be used within an ContentMenuProvider"
    );
  }
  return context;
};
