import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { commonErrors } from "../../language";
import { FormModal } from "../../components";
import { BJInputFormItem } from "../../components/theme";
import {
  AspectRatio,
  DeepLinkType,
  capitalizeFirstLetter,
  formValidationError,
  getMaxSortOrder,
} from "../../utils";
import { v4 as uuidv4 } from "uuid";
import { Button, Col, Form, Row, Select, Tooltip, Typography } from "antd";
import { DropAndCrop } from "../../components/DropAndCrop";
import { AnswersList } from "../Polls/AnswerList";
import { AnswerModal } from "../Polls/AnswerModal";
import _ from "lodash";
import { AddArticlesModal } from "../Popular/modal/AddArticlesModal";
import {
  useArticles,
  useCountry,
  useMiniJourneys,
  useOffers,
  usePodcasts,
} from "../../context";
import styled from "styled-components";
import { ImagePresenter } from "../../components/ImagePresenter";
import { MdRemoveCircle } from "react-icons/md";
import { createDeepLink } from "../DeepLink/methods";
import { AddOfferModal } from "../Popular/modal/AddOffersModal";
import { AddPodcastEpisodeModal } from "../Popular/modal/AddPodcastEpisodeModal";
interface Props {
  show: boolean;
  onHide: () => void;
  task: MiniJourneyTaskFormData | null;
  onDelete: (id: MiniJourneyTaskFormData["id"]) => void;
  onAdd: (task: MiniJourneyTaskFormData) => void;
  onUpdate: (task: MiniJourneyTaskFormData) => void;
}

type FormValues = {
  type: MiniJourneyTaskType;
  contentType: MiniJourneyTaskContentType;
  image: string;
  blurhash?: string;
  url?: string;
  articleId?: string;
  translations: {
    [locale: string]: {
      externalTitle?: string;
      externalBody?: string;
      externalButtonText?: string;
    };
  };
  pollTranslations: {
    [locale: string]: {
      title?: string;
      body?: string;
    };
  };
};

const contentTypes: Record<MiniJourneyTaskContentType, string> = {
  article: "Article",
  externalLink: "External Link",
  offer: "Offer",
  podcast: "Podcast",
};

const types: Record<MiniJourneyTaskType, string> = {
  read: "Read",
  listen: "Listen",
  watch: "Watch",
  externalLink: "External link",
  subscribe: "Subscribe",
};

const contentTypeValidation = (
  contentType: MiniJourneyTaskContentType,
  type: MiniJourneyTaskType
) => {
  switch (contentType) {
    case "article":
      return type !== "externalLink" && type !== "subscribe";
    case "externalLink":
      return type === "externalLink";
    case "offer":
      return type === "subscribe";
    case "podcast":
      return type === "listen";
    default:
      return false;
  }
};

const { requiredError } = commonErrors;

const schema = (currentCountry: Country) => {
  return yup.object().shape({
    url: yup.string().when("contentType", {
      is: "externalLink",
      then: yup
        .string()
        .required(`URL: ${requiredError}`)
        .typeError(requiredError),
      otherwise: yup.string().nullable(),
    }),
    contentType: yup
      .string()
      .required(`Content Type: ${requiredError}`)
      .typeError(requiredError),
    type: yup
      .string()
      .required(`Task Type: ${requiredError}`)
      .typeError(requiredError),
    translations: yup.object().when("contentType", {
      is: "externalLink",
      then: schema =>
        schema.shape(
          currentCountry?.locales?.reduce((acc, item) => {
            acc[item.key] = yup.object().shape({
              externalTitle: yup
                .string()
                .required(
                  `External Title (${String(
                    item.key
                  ).toUpperCase()}): ${requiredError}`
                )
                .typeError(requiredError),
              externalBody: yup
                .string()
                .required(
                  `External Body (${String(
                    item.key
                  ).toUpperCase()}): ${requiredError}`
                )
                .typeError(requiredError),
              externalButtonText: yup.string().optional(),
            });
            return acc;
          }, {} as any)
        ),
      otherwise: schema => schema.optional(),
    }),
    pollTranslations: yup.object().when("contentType", {
      is: (value: MiniJourneyTaskContentType) => {
        return value === "article" || value === "podcast";
      },
      then: schema =>
        schema.shape(
          currentCountry?.locales?.reduce((acc, item) => {
            acc[item.key] = yup.object().shape({
              title: yup
                .string()
                .required(
                  `Poll Title (${String(
                    item.key
                  ).toUpperCase()}): ${requiredError}`
                )
                .typeError(requiredError),
            });
            return acc;
          }, {} as any)
        ),
      otherwise: schema => schema.optional(),
    }),
  });
};

export const TaskModal = ({
  show,
  onHide,
  onAdd,
  onDelete: onDeleteTask,
  onUpdate,
  task,
}: Props) => {
  const { currentCountry, primaryLocale } = useCountry();
  const { uploadMiniJourneyImage } = useMiniJourneys();
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm<FormValues>({
    resolver: yupResolver(schema(currentCountry)),
    mode: "all",
  });
  const { getById: getArticleById } = useArticles();
  const { getEpisodeById } = usePodcasts();
  const [error, setError] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(task?.image ?? null);
  const [blurhash, setBlurhash] = useState<string | null>(
    task?.blurhash ?? null
  );
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [selectAnswer, setSelectAnswer] = useState(null);
  const { offers } = useOffers();
  const [answerModalVisible, setAnswerModalVisible] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState<
    CommonContentV2[] | FullArticle[]
  >([]);
  const [selectedPodcast, setSelectedPodcast] = useState<
    PodcastEpisode[] | CommonContentV2[]
  >([]);
  const [selectedOffer, setSelectedOffer] = useState<CommonContentV2[]>([]);
  const [chooseArticleVisible, setChooseArticleVisible] = useState(false);
  const [choosePodcastVisible, setChoosePodcastVisible] = useState(false);
  const [chooseOfferVisible, setChooseOfferVisible] = useState(false);

  useEffect(() => {
    if (task) {
      reset({
        ...task,
        translations: task.translations,
        pollTranslations: task.pollTranslations || {},
        type: task.type,
        contentType: task.contentType,
        url: task.url,
      });
      setAnswers(task.answers || []);
      setImageUrl(task.image);
      setBlurhash(task.blurhash);
      if (task.contentType === "article" && task.articleId) {
        const article = getArticleById(task.articleId);
        setSelectedArticle(article ? [article] : []);
      }
      if (task.contentType === "podcast" && task.episodeId) {
        const podcastEpisode = getEpisodeById(task.episodeId);
        setSelectedPodcast(podcastEpisode ? [podcastEpisode] : []);
      }
      if (task.contentType === "offer" && task.offerId) {
        const offer = offers.find(o => o.id === task.offerId);
        setSelectedOffer(
          offer
            ? [
                {
                  ...offer,
                  key: offer.id,
                  ...Object.entries(offer.translations ?? {}).reduce(
                    (acc, [locale, values]) => (
                      (acc[`title${capitalizeFirstLetter(locale)}`] =
                        values.title),
                      acc
                    ),
                    {} as any
                  ),
                },
              ]
            : []
        );
      }
    } else {
      reset({
        type: null,
        contentType: null,
        url: null,
        translations: {},
        pollTranslations: {},
      });
      setAnswers([]);
      setImageUrl(null);
      setBlurhash(null);
      setSelectedArticle([]);
      setSelectedOffer([]);
      setSelectedPodcast([]);
    }
    setError(null);
  }, [task, reset, show]);

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const translations: MiniJourneyTask["translations"] = {};
    const pollTranslations: MiniJourneyPoll["translations"] = {};

    if (
      answers.find(a => a.correctAnswer === true) === undefined &&
      data.type !== "externalLink" &&
      data.type !== "subscribe"
    ) {
      throw new Error("Please select at least one correct answer");
    }

    //mj tasks translations
    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        externalTitle: value.externalTitle ?? "",
        externalBody: value.externalBody ?? "",
        externalButtonText: value.externalButtonText ?? "",
      };
    }

    //mj poll translations
    for (const [key, value] of Object.entries(data.pollTranslations)) {
      pollTranslations[key] = {
        title: value.title ?? "",
        body: value.body ?? "",
      };
    }
    const _task = {
      ...data,
      translations: data.contentType === "externalLink" ? translations : {},
      pollTranslations: ["article", "podcast"].includes(data.contentType)
        ? pollTranslations
        : {},
      id: task ? task.id : uuidv4(),
      image: imageUrl,
      blurhash: blurhash,
      url:
        data.contentType === "externalLink"
          ? data.url
          : data.contentType === "article"
          ? createDeepLink({
              type: DeepLinkType.Article,
              id: selectedArticle[0].id,
              countryCode: currentCountry?.abb,
            })
          : data.contentType === "podcast"
          ? createDeepLink({
              type: DeepLinkType.PodcastEpisode,
              id: selectedPodcast[0].id,
              countryCode: currentCountry?.abb,
              params: {
                podcastId: selectedPodcast[0].podcastId,
              },
            })
          : createDeepLink({
              type: DeepLinkType.Offer,
              id: selectedOffer[0].id,
              countryCode: currentCountry?.abb,
            }),
      articleId: data.contentType === "article" ? selectedArticle[0]?.id : null,
      offerId: data.contentType == "offer" ? selectedOffer[0]?.id : null,
      podcastId:
        data.contentType === "podcast" ? selectedPodcast[0]?.podcastId : null,
      episodeId: data.contentType === "podcast" ? selectedPodcast[0]?.id : null,
      answers,
    };
    if (task) {
      onUpdate(_task);
      onHide();
      return;
    }
    onAdd(_task);
    onHide();
  };

  const onDelete = async () => {
    if (task) {
      onDeleteTask(task.id);
    }
    onHide();
  };

  const handleTaskImageUrl = (url: string | null) => {
    setImageUrl(url);
  };

  const handleTaskBlurhash = (url: string | null) => {
    setBlurhash(url);
  };

  const addArticle = (article: CommonContent[]) => {
    setSelectedArticle(article);
    setChooseArticleVisible(false);
  };
  const addOffer = (_offer: CommonContent[]) => {
    setSelectedOffer(_offer);
    setChooseOfferVisible(false);
  };
  const addPodcast = (_podcast: CommonContent[]) => {
    setSelectedPodcast(_podcast);
    setChoosePodcastVisible(false);
  };

  const contentType = watch("contentType");

  useEffect(() => {
    const type = getValues("type");
    if (
      (contentType === "externalLink" && type !== "externalLink") ||
      (contentType === "article" &&
        (type === "externalLink" || type === "subscribe")) ||
      (contentType === "offer" && type !== "subscribe") ||
      (contentType === "podcast" && type !== "listen")
    ) {
      setValue("type", null);
    }
  }, [contentType]);

  const isDirty =
    (imageUrl || task?.image) &&
    (contentType === "externalLink" ||
      (answers?.length > 0 && selectedArticle?.length > 0) ||
      (contentType === "offer" && selectedOffer?.length > 0) ||
      (contentType === "podcast" && selectedPodcast?.length > 0));

  return (
    <FormModal
      onHide={onHide}
      messageOnSubmit={false}
      enableSave={isDirty}
      show={show}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      error={error}
      errors={errors as any}
      onDelete={onDelete}
      modalTitle={task?.url ?? "New Task"}
      enableDelete={!!task}
      size={"lg"}
      formId="task-modal"
      localeSupported
    >
      {locale => (
        <>
          <Form.Item label="Content type" name="contentType" required>
            <Controller
              control={control}
              name="contentType"
              render={({ field: { onChange, value } }) => (
                <Select onChange={onChange} value={value} size="large">
                  {Object.entries(contentTypes)?.map(([key, value], index) => (
                    <Select.Option value={key} key={index}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Form.Item>
          <Form.Item label="Task type" name="type" required>
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, value } }) => (
                <Select onChange={onChange} value={value} size="large">
                  {Object.entries(types)
                    ?.filter(type =>
                      contentTypeValidation(
                        contentType as MiniJourneyTaskContentType,
                        type[0] as MiniJourneyTaskType
                      )
                    )
                    .map(([key, value], index) => (
                      <Select.Option value={key} key={index}>
                        {value}
                      </Select.Option>
                    ))}
                </Select>
              )}
            />
          </Form.Item>
          {contentType === "externalLink" && (
            <BJInputFormItem
              {...register("url")}
              control={control}
              error={!!errors.url?.message}
              label={"Url"}
              message={errors.url?.message}
              fieldName={"url"}
              required
            />
          )}
          {contentType === "article" && (
            <Form.Item label="Article" name="article" required>
              <StyledArticleContainer>
                {selectedArticle?.length > 0 ? (
                  <Row align="middle">
                    <Col span={6}>
                      <ImagePresenter
                        disabled={true}
                        hideBorder={true}
                        width={"90%"}
                        imageUrl={
                          selectedArticle[0].translations[primaryLocale?.key]
                            ?.imageUrl
                        }
                      />
                    </Col>
                    <Col span={14}>
                      <Typography.Text strong>
                        {
                          selectedArticle[0]?.translations?.[primaryLocale?.key]
                            ?.title
                        }
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Link onClick={() => setSelectedArticle([])}>
                        <Tooltip placement="topLeft" title={"Remove content"}>
                          <MdRemoveCircle size={30} color={"red"} />
                        </Tooltip>
                      </Typography.Link>
                    </Col>
                  </Row>
                ) : (
                  <Button onClick={() => setChooseArticleVisible(true)}>
                    Add article
                  </Button>
                )}
              </StyledArticleContainer>
            </Form.Item>
          )}
          {contentType === "offer" && (
            <Form.Item label="Offer" name="offer" required>
              <StyledArticleContainer>
                {selectedOffer?.length > 0 ? (
                  <Row align="middle">
                    <Col span={6}>
                      <ImagePresenter
                        disabled={true}
                        hideBorder={true}
                        width={"90%"}
                        imageUrl={
                          selectedOffer[0].translations[primaryLocale?.key]
                            ?.imageUrl
                        }
                      />
                    </Col>
                    <Col span={14}>
                      <Typography.Text strong>
                        {
                          selectedOffer[0]?.translations?.[primaryLocale?.key]
                            ?.title
                        }
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Link onClick={() => setSelectedOffer([])}>
                        <Tooltip placement="topLeft" title={"Remove content"}>
                          <MdRemoveCircle size={30} color={"red"} />
                        </Tooltip>
                      </Typography.Link>
                    </Col>
                  </Row>
                ) : (
                  <Button onClick={() => setChooseOfferVisible(true)}>
                    Add Offer
                  </Button>
                )}
              </StyledArticleContainer>
            </Form.Item>
          )}

          {contentType === "podcast" && (
            <Form.Item label="Podcast" name="podcast" required>
              <StyledArticleContainer>
                {selectedPodcast?.length > 0 ? (
                  <Row align="middle">
                    <Col span={6}>
                      <ImagePresenter
                        disabled={true}
                        hideBorder={true}
                        width={"90%"}
                        imageUrl={selectedPodcast[0].imageUrl}
                      />
                    </Col>
                    <Col span={14}>
                      <Typography.Text strong>
                        {
                          selectedPodcast[0]?.translations?.[primaryLocale?.key]
                            ?.name
                        }
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Link onClick={() => setSelectedPodcast([])}>
                        <Tooltip placement="topLeft" title={"Remove content"}>
                          <MdRemoveCircle size={30} color={"red"} />
                        </Tooltip>
                      </Typography.Link>
                    </Col>
                  </Row>
                ) : (
                  <Button onClick={() => setChoosePodcastVisible(true)}>
                    Add Podcast
                  </Button>
                )}
              </StyledArticleContainer>
            </Form.Item>
          )}

          <Form.Item
            required
            label="Task Image"
            extra=""
            validateStatus={errors.image && "error"}
          >
            <DropAndCrop
              allowNaturalImageUpload={true}
              title="Taks Image"
              setUploadUrl={handleTaskImageUrl}
              setBlurhash={handleTaskBlurhash}
              uploadImage={uploadMiniJourneyImage}
              lockedRatio={AspectRatio.Free}
              previewImageWidth={"10"}
              defaultCropBoxWidth={300}
              initialUrl={imageUrl}
              extra={
                "Best resolution for this will be 720 x 640 or another 9:8"
              }
            />
          </Form.Item>
          {(contentType === "article" || contentType === "podcast") && (
            <Col md={24} lg={12}>
              <BJInputFormItem
                control={control}
                label={`Poll title (${locale.label})`}
                fieldName={`pollTranslations.${locale.key}.title`}
                key={`pollTranslations.${locale.key}.title`}
                error={!!errors?.pollTranslations?.[locale.key]?.title}
                message={errors?.pollTranslations?.[locale.key]?.title?.message}
                required
              />

              <BJInputFormItem
                control={control}
                label={`Poll body (${locale.label})`}
                fieldName={`pollTranslations.${locale.key}.body`}
                error={!!errors?.pollTranslations?.[locale.key]?.body}
                message={errors?.pollTranslations?.[locale.key]?.body?.message}
                key={`pollTranslations.${locale.key}.body`}
              />
              <AnswersList
                initialAnswers={answers ?? []}
                onAdd={() => setAnswerModalVisible(true)}
                onSelect={(answer: Answer) => {
                  setSelectAnswer(answer);
                }}
                onUpdate={(_answers: Answer[]) => {
                  setAnswers(_answers || []);
                }}
                withPercentage={false}
              />
            </Col>
          )}
          {contentType === "externalLink" && (
            <Col md={24}>
              <BJInputFormItem
                {...register(`translations.${locale.key}.externalTitle`)}
                control={control}
                error={
                  !!errors?.translations?.[locale.key]?.externalTitle?.message
                }
                label={`External link title (${locale.label})`}
                message={
                  errors?.translations?.[locale.key]?.externalTitle?.message
                }
                fieldName={`translations.${locale.key}.externalTitle`}
                key={`translations.${locale.key}.externalTitle`}
                required
              />
              <BJInputFormItem
                {...register(`translations.${locale.key}.externalBody`)}
                control={control}
                error={
                  !!errors?.translations?.[locale.key]?.externalBody?.message
                }
                label={`External link body (${locale.label})`}
                message={
                  errors?.translations?.[locale.key]?.externalBody?.message
                }
                fieldName={`translations.${locale.key}.externalBody`}
                key={`translations.${locale.key}.externalBody`}
                required
              />
              <BJInputFormItem
                {...register(`translations.${locale.key}.externalButtonText`)}
                control={control}
                error={
                  !!errors?.translations?.[locale.key]?.externalButtonText
                    ?.message
                }
                label={`External link button text (${locale.label})`}
                message={
                  errors?.translations?.[locale.key]?.externalButtonText
                    ?.message
                }
                fieldName={`translations.${locale.key}.externalButtonText`}
                key={`translations.${locale.key}.externalButtonText`}
              />
            </Col>
          )}
          <AddArticlesModal
            show={chooseArticleVisible}
            onHide={() => setChooseArticleVisible(false)}
            maxNoOfContentLimit={1}
            selectedContent={selectedArticle as CommonContent[]}
            onAdd={addArticle}
          />
          <AddOfferModal
            show={chooseOfferVisible}
            onHide={() => setChooseOfferVisible(false)}
            maxNoOfContentLimit={1}
            selectedContent={selectedOffer as CommonContent[]}
            onAdd={addOffer}
            offerType={["3", "4"]}
          />
          <AddPodcastEpisodeModal
            show={choosePodcastVisible}
            selectedContent={selectedPodcast as CommonContent[]}
            onHide={() => setChoosePodcastVisible(false)}
            onAdd={addPodcast}
          />
          <AnswerModal
            show={answerModalVisible || !!selectAnswer}
            onHide={() => {
              setAnswerModalVisible(false);
              setSelectAnswer(null);
            }}
            answer={selectAnswer}
            withPercentage={false}
            withCorrectAnswer={true}
            onAdd={_answer => {
              if (_answer) {
                const maxSort = getMaxSortOrder(answers ?? []);
                const newAnswer = { ..._answer, sortOrder: maxSort + 1 };
                setAnswers(answers ? [...answers, newAnswer] : [newAnswer]);
              }
            }}
            onDelete={id => {
              setAnswers(answers.filter(a => a.id !== id));
            }}
            onUpdate={_answer => {
              setAnswers(prev =>
                prev?.map(a => {
                  return a.id === _answer.id ? _answer : a;
                })
              );
            }}
          />
        </>
      )}
    </FormModal>
  );
};

const StyledArticleContainer = styled(Row)`
  margin-bottom: 20px;
`;
