import { useNavigate } from "react-router-dom";
import { usePopularContentByWeeks } from "../../context";
import { CenteredSpinner } from "../../components";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";
import BJList from "../../components/theme/components/BJList";
import { newRoute } from "../../routes";

type Props = {
  title?: string;
  audience?: "child" | "pregnancy" | null;
};

export const PopularByWeeksListPage: React.FC<Props> = ({
  title = "Popular",
  audience = null,
}) => {
  const navigate = useNavigate();
  const { popularContents, loading } = usePopularContentByWeeks(audience);

  const onClickRow = (record: Popular) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const handleNewDietary = () => {
    navigate(newRoute);
  };

  const columns: BJColumnType<Popular>[] = [
    {
      title: "Weeks",
      dataIndex: audience == "child" ? "childWeek" : "week",
      key: "month",
      width: 2,
      ellipsis: true,
      onFilter: (value, record) =>
        record.month!.toString().includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  if (loading) return <CenteredSpinner />;

  return (
    <BJList
      filterOnlyDisplayList
      addButtonCaption={"New popular"}
      title={title}
      OriginalList={popularContents.map(x => ({ ...x, key: x.id }))}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewDietary}
      recordCountSuffix={"Popular"}
    />
  );
};
