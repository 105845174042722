import { Form, Typography } from "antd";
import React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormWatch,
} from "react-hook-form";
import BJInput from "../../components/theme/atoms/BJInput";
import { FormValues, OfferImageUrlProps } from "./types";
import { DropAndCrop } from "../../components/DropAndCrop";
import { OffersService } from "../../services";
import { AspectRatio } from "../../utils";
import { BJInputFormItem } from "../../components/theme/molecules/formItems/BJFormInput";

type Props = {
  errors: FieldErrors<FormValues>;
  control: Control<FormValues, object>;
  watch: UseFormWatch<FormValues>;
  locale: Locale;
  handleImageUrl: (ev: OfferImageUrlProps) => void;
};

const OfferSignUpSuccess: React.FC<Props> = ({
  locale,
  errors,
  control,
  watch,
  handleImageUrl,
}) => {
  return (
    <>
      <Form.Item
        label={`Title (${locale?.label ?? ""})`}
        key={`translations.${locale.key}?.successSignUpOfferTitle`}
        validateStatus={
          errors?.translations?.[locale.key!]?.successSignUpOfferTitle &&
          "error"
        }
        extra={
          <Typography.Paragraph type="danger">
            {
              errors?.translations?.[locale.key!]?.successSignUpOfferTitle
                ?.message
            }
          </Typography.Paragraph>
        }
      >
        <Controller
          control={control}
          name={`translations.${locale.key}.successSignUpOfferTitle`}
          render={({ field }) => <BJInput {...field} />}
        />
      </Form.Item>
      <Form.Item
        label={`Subtitle (${locale?.label ?? ""})`}
        key={`translations.${locale.key}.successSignUpOfferSubTitle`}
        validateStatus={
          errors?.translations?.[locale.key!]?.successSignUpOfferSubTitle &&
          "error"
        }
        extra={
          <Typography.Paragraph type="danger">
            {
              errors?.translations?.[locale.key!]?.successSignUpOfferSubTitle
                ?.message
            }
          </Typography.Paragraph>
        }
      >
        <Controller
          control={control}
          name={`translations.${locale.key}.successSignUpOfferSubTitle`}
          render={({ field }) => <BJInput {...field} />}
        />
      </Form.Item>
      <Form.Item
        required={false}
        label={`Input Box Success Message (${locale?.label ?? ""})`}
        key={`translations.${locale.key}.successSignUpOfferInputMessage`}
        validateStatus={
          errors?.translations?.[locale.key!]?.successSignUpOfferInputMessage &&
          "error"
        }
        extra={
          <Typography.Paragraph type="danger">
            {
              errors?.translations?.[locale.key!]
                ?.successSignUpOfferInputMessage?.message
            }
          </Typography.Paragraph>
        }
      >
        <Controller
          control={control}
          name={`translations.${locale.key}.successSignUpOfferInputMessage`}
          render={({ field }) => <BJInput {...field} />}
        />
      </Form.Item>
      <BJInputFormItem
        control={control}
        error={!!errors?.translations?.[locale.key!]?.ctaBtnLabel}
        label={`CTA Button Label (${locale?.label ?? ""})`}
        message={errors?.translations?.[locale.key!]?.ctaBtnLabel?.message}
        fieldName={`translations.${locale.key}.ctaBtnLabel`}
        key={`translations.${locale.key}.ctaBtnLabel`}
      />
      <BJInputFormItem
        control={control}
        error={!!errors?.translations?.[locale.key!]?.ctaUrl}
        label={`CTA URL (${locale?.label ?? ""})`}
        message={errors?.translations?.[locale.key!]?.ctaUrl?.message}
        fieldName={`translations.${locale.key}.ctaUrl`}
        key={`translations.${locale.key}.ctaUrl`}
      />
      <Typography.Text>
        {`Success Image URL: ${locale.label ? `(${locale.label})` : ""}`}
      </Typography.Text>
      <DropAndCrop
        title={`Success Image URL: ${locale.label ? `(${locale.label})` : ""}`}
        setUploadUrl={(url: string) =>
          handleImageUrl({
            url,
            locale: locale.key,
            fieldName: "successImageUrl",
          })
        }
        setBlurhash={(blurhash: string) =>
          handleImageUrl({
            url: blurhash,
            locale: locale.key,
            fieldName: "successImageBlurhash",
          })
        }
        uploadImage={OffersService.uploadOfferSignUpImage}
        initialUrl={watch("translations")?.[locale.key]?.successImageUrl ?? ""}
        lockedRatio={AspectRatio.OneToOne}
        defaultCropBoxWidth={128}
        key={`translations.${locale.key}.successImageUrl`}
      />
    </>
  );
};

export default OfferSignUpSuccess;
