import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import { useAuth, useAuthToken } from "./context";
import { CMSRoutes } from "./utils/routes/CMSRoutes";
import { Providers } from "./Providers";
import { AdminRoutes } from "./utils/routes/AdminRoutes";
import "./styles/App.scss";
import { theme } from "./components/theme/util/theme";
import { BJMainLayout } from "./Layouts/MainLayout";
import { EditUserProfile } from "./pages/Profile/EditProfile";
import { ProfileLayout } from "./Layouts/ProfileLayout";
import { LoginPage } from "./pages";
import { AnalyticsRoutes } from "./utils/routes/AnalyticsRoutes";
import { PushNotificationsRoutes } from "./utils/routes/PushNotificationsRoutes";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./components";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { siteConsts } from "./utils";

Sentry.init({
  dsn: siteConsts.sentryDsn,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

export const App = () => {
  const { user, loading } = useAuth();
  const { isAdmin, isModerator, isAnalytic, isnotificationHandler } =
    useAuthToken();

  if (loading) {
    return null;
  }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Providers>
          <Routes>
            {!user ? (
              <Route path="/*" element={<LoginPage />} />
            ) : (
              <Route path="/*" element={<BJMainLayout />}>
                {isnotificationHandler && (
                  <Route
                    path="notifications/*"
                    element={<PushNotificationsRoutes />}
                  />
                )}
                {(isAdmin || isModerator || isnotificationHandler) && (
                  <Route path="cms/*" element={<CMSRoutes />} />
                )}
                {(isAdmin || isnotificationHandler) && (
                  <Route
                    path="notifications/*"
                    element={<PushNotificationsRoutes />}
                  />
                )}
                {(isAdmin || isModerator) && (
                  <Route path="/*" element={<Navigate to="/cms" />} />
                )}
                {isAnalytic && (
                  <Route path="/*" element={<Navigate to="/analytics" />} />
                )}
                {(isAdmin || isModerator) && (
                  <Route path="profile/*" element={<ProfileLayout />}>
                    <Route path={"edit"} element={<EditUserProfile />} />
                  </Route>
                )}
                {(isAdmin || isAnalytic) && (
                  <Route path="analytics/*" element={<AnalyticsRoutes />} />
                )}
                {isAdmin ? (
                  <Route path={"admin/*"} element={<AdminRoutes />} />
                ) : isModerator ? (
                  <Route path="*" element={<Navigate to="/cms" />} />
                ) : isAnalytic ? (
                  <Route path="*" element={<Navigate to="/analytics" />} />
                ) : isnotificationHandler ? (
                  <Route path="*" element={<Navigate to="/notifications" />} />
                ) : (
                  <Route path="/*" element={<LoginPage />} />
                )}
              </Route>
            )}
          </Routes>
        </Providers>
      </ThemeProvider>
    </Router>
  );
};
