import moment from "moment";
import {
  CommonContentType,
  DisplayUnitType,
  ScreenInMobile,
} from "./commonEnums";
import { transformToCommonContent } from "../hooks/contentCollections/useFilterContent";
import { v4 as uuidv4 } from "uuid";
import { formatDateStringToTimeStamp } from "./commonUtils";
import _ from "lodash";
import { MilestonesService, PollService } from "../services";
import { firebase as fb } from "../firebase";

type DownloadProps = {
  topic: "polls" | "milestones" | "dietary" | "checklist";
  polls?: Poll[];
  primaryLocale?: Locale;
  isMonthFilter?: boolean;
  getMilestonesByType?: () => Milestone[];
  dietaryAdvices?: FullDietaryAdvice[];
  categories?: DietaryAdviceCategory[];
  checklists?: Checklist[];
};

type DocumentReference<T> = firebase.default.firestore.DocumentReference<T>;
type DocumentData = firebase.default.firestore.DocumentData;

export type UploadProps = {
  topic: "polls" | "milestones" | "dietary" | "checklist";
  val?: string[][];
  articles?: FullArticle[];
  isMonthFilter?: boolean;
  pollService?: PollService;
  milestonesService?: MilestonesService;
  isChildPage?: boolean;
  categories?: DietaryAdviceCategory[];
  primaryLocale?: Locale;
  createDietaryAdvice?: (
    data: Omit<DietaryAdviceV2, "id">
  ) => Promise<fb.firestore.DocumentReference | null>;
  onCreate?: (
    data: Partial<Checklist>
  ) => Promise<DocumentReference<DocumentData>>;
  filterUnnessaryTags?: (tags: string[]) => string[];
};

//dynamic download csv handler
export const downloadCSV = ({
  topic,
  polls,
  primaryLocale,
  isMonthFilter,
  getMilestonesByType,
  dietaryAdvices,
  categories,
  checklists,
}: DownloadProps) => {
  switch (topic) {
    case "polls":
      return downloadPollsData(polls, primaryLocale);
    case "milestones":
      return downloadMilestonesData(
        isMonthFilter,
        getMilestonesByType,
        primaryLocale
      );
    case "dietary":
      return downloadDietaryData(dietaryAdvices, categories, primaryLocale);
    case "checklist":
      return downloadChecklistData(checklists, primaryLocale);
  }
};
//dynamic upload csv handler
export const uploadCSV = ({
  topic,
  val,
  articles,
  pollService,
  milestonesService,
  isChildPage,
  categories,
  primaryLocale,
  createDietaryAdvice,
  onCreate,
  filterUnnessaryTags,
}: UploadProps) => {
  switch (topic) {
    case "polls":
      return convertCSVPollToData(val, articles, pollService);
    case "milestones":
      return convertCSVMilestoneToData(val, milestonesService, isChildPage);
    case "dietary":
      return convertCSVDietaryToData(
        val,
        categories,
        primaryLocale,
        createDietaryAdvice
      );
    case "checklist":
      return convertCSVChecklistToData(val, onCreate, filterUnnessaryTags);
  }
};

const downloadPollsData = (polls: Poll[], primaryLocale: Locale) => {
  const csvData = [
    [
      "Translations",
      "With Percentage",
      "With CorrectAnswer",
      "Is MultiChoice",
      "Screens",
      "Is Active",
      "Answers",
      "Start Time",
      "End Time",
      "Scheduled At",
      "Extra",
      "ContentMap",
    ],
  ];
  polls.forEach(t => {
    //translations
    const translations = Object.keys(t?.translations ?? "")
      .sort((a, b) =>
        a === primaryLocale.key ? -1 : b === primaryLocale.key ? 1 : 0
      )
      .map(key => {
        return `${key}: title: ${
          t.translations[key]?.title ?? ""
        } \n ${key}: body: ${t.translations[key]?.body ?? ""}`;
      })
      .join("\n-------\n");

    //answers
    const answers = t?.answers?.reduce((acc, a) => {
      const trans = Object.keys(a.translations ?? "")
        .sort((a, b) =>
          a === primaryLocale.key ? -1 : b === primaryLocale.key ? 1 : 0
        )
        .map(key => {
          return `${key}: title: ${a.translations[key]?.title ?? ""}`;
        })
        .join("; ");

      const moreContentTrans = Object.keys(a.moreContent.translations ?? "")
        .sort((a, b) =>
          a === primaryLocale.key ? -1 : b === primaryLocale.key ? 1 : 0
        )
        .map(key => {
          return `${key}: title: ${
            a.moreContent.translations[key]?.title ?? ""
          }`;
        })
        .join("; ");

      const contentMap = Object.keys(a.moreContent.contentMap ?? "")
        .map(key => {
          return `contentID: ${key}, type: ${a.moreContent.contentMap[key].type}`;
        })
        .join("; ");

      const data = `${acc}${trans}** \n ${moreContentTrans} \n ~~${contentMap} \n-------\n`;

      return data;
    }, "");

    //screens
    const screens = t.screens?.reduce((acc, s) => {
      return `${acc}${s.position} - ${s.screen}\n`;
    }, "");

    //extra
    const extra = t.extra
      ? t.extra
          ?.map(e => {
            return `${e.audience} - ${e.range} - ${e.rangeUnit}\n`;
          })
          .join(", ")
      : "";

    csvData.push([
      translations.toString(),
      t.withPercentage?.toString(),
      t.withCorrectAnswer?.toString(),
      t.isMultiChoice?.toString(),
      screens,
      t.isActive?.toString(),
      answers,
      t.startTime ? moment(t.startTime).format("M/D/YY HH:mm") : "",
      t.endTime ? moment(t.endTime).format("M/D/YY HH:mm") : "",
      t.scheduledAt ? moment(t.scheduledAt).format("M/D/YY HH:mm") : "",
      extra,
    ]);
  });
  return csvData;
};

const downloadMilestonesData = (
  isMonthFilter: boolean,
  getMilestonesByType: () => Milestone[],
  primaryLocale: Locale
) => {
  const filter = isMonthFilter ? "Month" : "Week";
  const csvData = [
    [
      "Translations",
      filter,
      "Measurements",
      "Days",
      "Image Url",
      "ImageUrlFetus",
      "ImageUrlFruit",
      "Period",
      "Period Type",
      "Blurhash",
      "BlurhashFetus",
      "BlurhashFruit",
      "MonthOrWeek",
    ],
  ];

  (getMilestonesByType() as Milestone[]).forEach(t => {
    //translations
    const translations = Object.keys(t?.translations ?? "")
      .sort((a, b) =>
        a === primaryLocale.key ? -1 : b === primaryLocale.key ? 1 : 0
      )
      .map(key => {
        return `${key}: text:${
          t.translations[key]?.text ?? ""
        } \n ${key}: imageUrl:${
          t.translations[key]?.imageUrl ?? ""
        } \n ${key}: blurhash:${t.translations[key]?.blurhash ?? ""}`;
      })
      .join("\n-------\n");

    //week
    const week = t.week?.toString() ?? "";

    //month
    const month = t.month?.toString() ?? "";

    //days
    const days = t.days.reduce((acc, d) => {
      const translations = Object.keys(d.translations ?? "")
        .sort((a, b) =>
          a === primaryLocale.key ? -1 : b === primaryLocale.key ? 1 : 0
        )
        .map(key => {
          return `${key}: text: ${
            d.translations[key]?.text ?? ""
          } \n ${key}: imageUrl: ${d.translations[key]?.imageUrl ?? ""}`;
        })
        .join("\n+++++\n");

      return `${acc}${translations} \n*****\n day: ${d.day} \n imageUrl: ${d.imageUrl} \n text: ${d.text} \n-------\n`;
    }, "");

    //measurements
    const measurements = () => {
      const weight = t?.measurements?.weight;
      const length = t?.measurements?.length;
      const meta = t?.measurements?.meta;

      return `weight: displayUnit: ${weight?.displayUnit} - unit: ${weight?.unit} - value: ${weight?.value} 
      \n length: displayUnit: ${length?.displayUnit} - unit: ${length?.unit} - value: ${length?.value} 
      \n meta: originalSystem: ${meta?.originalSystem}`;
    };

    //imageUrl
    const imageUrl = t.imageUrl ?? "";

    //imageUrlFetus
    const imageUrlFetus = t.imageUrlFetus ?? "";

    //imageUrlFruit
    const imageUrlFruit = t.imageUrlFruit ?? "";

    //period
    const period = t.period ?? "";

    //periodType
    const periodType = t.periodType ?? "";

    //blurhash
    const blurhash = t.blurhash ?? "";

    //blurhashFetus
    const blurhashFetus = t.blurhashFetus ?? "";

    //blurhashFruit
    const blurhashFruit = t.blurhashFruit ?? "";

    const monthOrWeek = isMonthFilter ? "month" : "week";

    csvData.push([
      translations.toString(),
      !isMonthFilter ? week : month,
      measurements(),
      days,
      imageUrl,
      imageUrlFetus,
      imageUrlFruit,
      period.toString(),
      periodType,
      blurhash,
      blurhashFetus,
      blurhashFruit,
      monthOrWeek,
    ]);
  });
  return csvData;
};

const downloadDietaryData = (
  dietaryAdvices: FullDietaryAdvice[],
  categories: DietaryAdviceCategory[],
  primaryLocale: Locale
) => {
  const csvData = [
    ["Translations", "Category", "Indicator", "Promoted", "Import ID"],
  ];

  dietaryAdvices.forEach(t => {
    //translations
    const translations = Object.keys(t?.translations ?? "")
      .sort((a, b) =>
        a === primaryLocale.key ? -1 : b === primaryLocale.key ? 1 : 0
      )
      .map(key => {
        const tags =
          t.translations[key]?.tagWords?.map(tw => tw)?.join("* ") ?? "";

        return `${key}: name: ${
          t.translations[key]?.name ?? ""
        } \n ${key}: description: ${
          t.translations[key]?.description ?? ""
        } \n ${key}: tagWords: ${tags}`;
      })
      .join("\n-------\n");

    //category
    const categoryTitle = categories.filter(c => c.id === t.categoryId)[0]
      ?.translations?.[primaryLocale.key]?.title;

    //indicator
    const indicator = t.indicator ?? "";

    //promoted
    const promoted = t.promoted?.toString() ?? "";

    //importId
    const importId = t.importId ?? "";

    csvData.push([
      translations.toString(),
      categoryTitle,
      indicator,
      promoted,
      importId,
    ]);
  });
  return csvData;
};

const downloadChecklistData = (
  checklists: Checklist[],
  primaryLocale: Locale
) => {
  const csvData = [
    [
      "Translations",
      "CTAbuttonlink",
      "Sortorder",
      "Tags",
      "Promoted",
      "Types",
      "Sponsor",
      "Sponsor ID",
      "Tasks",
    ],
  ];

  checklists.forEach(t => {
    //translations
    const translations = Object.keys(t?.translations ?? "")
      .sort((a, b) =>
        a === primaryLocale.key ? -1 : b === primaryLocale.key ? 1 : 0
      )
      .map(key => {
        const tags =
          t.translations[key]?.tagWords?.map(tw => tw)?.join("* ") ?? "";

        return `${key}: name: ${
          t.translations[key]?.name ?? ""
        } \n ${key}: intro: ${
          t.translations[key]?.intro ?? ""
        } \n ${key}: ctaButtonText: ${
          t.translations[key]?.ctaButtonText ?? ""
        } \n ${key}: tagWords: ${tags}`;
      })
      ?.join("\n-------\n");

    //ctaButtonLink
    const ctaButtonLink = t.ctaButtonLink ?? "";

    //sort order
    const sortOrder = t.sortOrder ?? "";

    //tags
    const tags = t.tags?.map(tw => tw)?.join("\n") ?? "";

    //promoted
    const promoted = t.promoted?.toString() ?? "";

    //types
    const types = t.types?.map(tw => tw)?.join("\n") ?? "";

    //sponsor
    const sponsor = Object.keys(t.sponsor ?? "").map(key => {
      return `\n ${key}:: ${(t.sponsor as Sponsor)[key as keyof Sponsor]}`;
    });

    //sponsor ID
    const sponsorID = t.sponsorId ?? "";

    //tasks
    const tasks = t.tasks?.reduce((acc, task) => {
      const translations = Object.keys(task.translations ?? "")
        .sort((a, b) =>
          a === primaryLocale.key ? -1 : b === primaryLocale.key ? 1 : 0
        )
        .map(key => {
          return `${key}: text: ${
            task.translations[key]?.text ?? ""
          } \n ${key}: productButtonText: ${
            task.translations[key]?.productButtonText ?? ""
          } \n ${key}: description: ${
            task.translations[key]?.description ?? ""
          }`;
        })
        ?.join("\n+++++\n");

      return `${acc}${translations} \n*****\n productUrl:: ${
        task.productUrl ?? ""
      } \n sortOrder:: ${task.sortOrder ?? ""} \n itemType:: ${
        task.itemType ?? ""
      } \n image:: ${task.image} \n blurhash:: ${
        task.blurhash ?? ""
      } \n------------------------------\n`;
    }, "");

    csvData.push([
      translations,
      ctaButtonLink,
      sortOrder.toString(),
      tags,
      promoted,
      types,
      sponsor.toString(),
      sponsorID,
      tasks,
    ]);
  });

  return csvData;
};

const convertToTags = (v: string[]) => {
  const tags = v
    ?.filter(e => e !== " ")
    ?.map(t => {
      return t?.replace(/\s/g, "");
    });
  return tags;
};

//ConvertCSVData
export const convertToTranslations = (
  v: string[],
  topic?: UploadProps["topic"]
) => {
  const separate = v[0].split("\n-------\n");
  const t = separate.map(s => {
    let key;
    let title;
    let name;
    let intro;
    let body;
    let description;
    let tagWords: string[];
    let text;
    let ctaButtonText;
    let imageUrl;
    let blurhash;
    if (topic === "polls") {
      key = s.split(":")[0]?.replace(/\s/g, "");
      title = s.split(":")[2]?.replace("\n", "")?.includes(key)
        ? s.split(":")[2]?.replace("\n", "")?.replace(` ${key}`, "")?.trim()
        : s.split(":")[2]?.replace("\n", "")?.trim();
      body = s.split(":")[4]?.trim();

      return {
        [key]: {
          title: title,
          body: body,
        },
      };
    } else if (topic === "milestones") {
      key = s.split(":")[0]?.replace(/\s/g, "");
      text = s.split(":")[2]?.replace("\n", "")?.includes(key)
        ? s.split(":")[2]?.replace("\n", "")?.replace(` ${key}`, "")?.trim()
        : s.split(":")[2]?.replace("\n", "")?.trim();
      imageUrl = s.split(":")[5]?.replace("\n", "")?.includes(key)
        ? s.split(":")[5]?.replace("\n", "")?.replace(` ${key}`, "")?.trim()
        : s.split(":")[5]?.replace("\n", "")?.trim();
      blurhash = s.split(":")[7]?.trim();

      return {
        [key]: {
          text: text ?? "",
          imageUrl: `https:${imageUrl}` || "",
          blurhash: blurhash ?? "",
        },
      };
    } else if (topic === "dietary") {
      key = s.split(":")[0]?.replace(/\s/g, "");
      name = s.split(":")[2]?.replace("\n", "")?.includes(key)
        ? s.split(":")[2]?.replace("\n", "")?.replace(` ${key}`, "")?.trim()
        : s.split(":")[2]?.replace("\n", "")?.trim();
      description = s.split(":")[4]?.replace("\n", "")?.includes(key)
        ? s.split(":")[4]?.replace("\n", "")?.replace(` ${key}`, "")?.trim()
        : s.split(":")[4]?.replace("\n", "")?.trim();
      tagWords = convertToTags(s.split(":")[6]?.split("*"));

      return {
        [key]: {
          name: name ?? "",
          description: description ?? "",
          tagWords: tagWords ?? [],
        },
      };
    } else if (topic === "checklist") {
      key = s.split(":")[0]?.replace(/\s/g, "");
      name = s.split(":")[2]?.replace("\n", "")?.includes(key)
        ? s.split(":")[2]?.replace("\n", "")?.replace(` ${key}`, "")?.trim()
        : s.split(":")[2]?.replace("\n", "")?.trim();
      intro = s.split(":")[4]?.replace("\n", "")?.includes(key)
        ? s.split(":")[4]?.replace("\n", "")?.replace(` ${key}`, "")?.trim()
        : s.split(":")[4]?.replace("\n", "")?.trim();
      ctaButtonText = s.split(":")[6]?.replace("\n", "")?.includes(key)
        ? s.split(":")[6]?.replace("\n", "")?.replace(` ${key}`, "")?.trim()
        : s.split(":")[6]?.replace("\n", "")?.trim();
      tagWords = convertToTags(s.split(":")[8]?.split("*"));

      return {
        [key]: {
          name: name ?? "",
          intro: intro ?? "",
          ctaButtonText: ctaButtonText ?? "",
          tagWords: tagWords ?? [],
        },
      };
    }
  });

  const reduced = t.reduce((acc, t) => {
    return { ...acc, ...t };
  }, {} as any);

  return reduced;
};

const answersTranslations = (value: string[]) => {
  const answers = value[0].split(";");
  const a = answers.map(ans => {
    const key = ans.split(":")[0]?.replace(/\s/g, "");
    const title = ans.split(":")[2]?.replace(/\s/g, "")?.trim();

    return {
      [key]: {
        title: title,
      },
    };
  });

  const reduced: Answer["translations"] = a.reduce((acc, a) => {
    return { ...acc, ...a };
  });

  return reduced;
};

const moreContentTranslations = (value: string[]) => {
  const moreContent = value[1].split(";");

  const a = moreContent.map(mc => {
    const key = mc.split(":")[0]?.replace(/\s/g, "");
    const title = mc.split(":")[2]?.includes("~~")
      ? mc
          .split(":")[2]
          ?.replace("~~contentID", "")
          ?.replace("\n", "")
          ?.replace("~~", "")
          ?.trim()
      : mc.split(":")[2]?.trim();

    return {
      [key]: {
        title: title,
      },
    };
  });

  const reduced: Answer["moreContent"]["translations"] = a.reduce((acc, a) => {
    return { ...acc, ...a };
  });

  return reduced;
};

const contentMap = (value: string[], articles: FullArticle[]) => {
  const content = value[1].split("~~")[1];
  const result = content
    .split(";")
    .filter(c => c.includes("contentID"))
    .map(c => {
      const contentID = c
        .split(":")[1]
        ?.replace(", type", "")
        ?.replace(/\s/g, "");

      const type = c.split(":")[2]?.replace("\n", "")?.replace(/\s/g, "");
      if (!contentID || !type) {
        return;
      }

      switch (type) {
        case CommonContentType.ARTICLE:
          const article = articles.find(a => a.id === contentID);
          const commonContent = transformToCommonContent(
            article as any,
            0,
            CommonContentType.ARTICLE
          );
          return commonContent;
      }
    });

  return result;
};

const convertToAnswers = (v: string[], articles: FullArticle[]) => {
  const data = v[6].split("\n-------\n");
  data.pop();
  const main = data.map(d => {
    const value = d.split("**");

    //answers
    const answersTrans = answersTranslations(value);

    //morecontentTrans
    const moreContentTrans = moreContentTranslations(value);

    const content = contentMap(value, articles);

    const val: Answer = {
      id: uuidv4(),
      sortOrder: 0,
      percentageAnswered: 0,
      correctAnswer: false,
      translations: {
        ...answersTrans,
      },
      moreContent: {
        translations: {
          ...moreContentTrans,
        },
        content: content[0] !== undefined ? content : [],
        contentMap:
          content[0] !== undefined
            ? content.reduce((acc, item) => {
                acc[item?.id] = item;
                return acc;
              }, {} as { [key: string]: any })
            : {},
      },
    };

    return val;
  });

  return main;
};

const splitData = (data: string) => {
  return data?.split(":") ?? [];
};
const generateData = (data: Array<string>) => {
  const [, , displayUnit, unit, value] = data;

  return {
    displayUnit: displayUnit?.replace("- unit", "")?.trim() ?? "",
    unit: unit?.replace("- value", "")?.trim() ?? "",
    value: value?.trim() ? parseInt(value?.trim()) : 0,
  };
};
const convertToMeasurements = (v: string[]) => {
  const [weight, length, meta] = v[2]?.split("\n")?.filter(e => e !== "      ");
  const [, , metaData] = splitData(meta);
  return {
    weight: generateData(splitData(weight)),
    length: generateData(splitData(length)),
    meta: {
      originalSystem: metaData?.trim() ?? "",
    },
  } as Milestone["measurements"];
};

const convertCLTask = (v: string[]) => {
  const dataTrans = v[0].split("\n+++++\n");
  const values = v[1]?.replaceAll("\n", "").split("::") ?? [];
  //translations
  const trans = dataTrans.map(d => {
    const key = d.split(":")[0]?.replace(/\s/g, "");
    const text = d.split(":")[2]?.replace("\n", "")?.includes(key)
      ? d.split(":")[2]?.replace("\n", "")?.replace(` ${key}`, "")?.trim()
      : d.split(":")[2]?.replace("\n", "")?.trim();
    const productButtonText = d.split(":")[4]?.replace("\n", "")?.includes(key)
      ? d.split(":")[4]?.replace("\n", "")?.replace(` ${key}`, "")?.trim()
      : d.split(":")[4]?.replace("\n", "")?.trim();
    const description = d.split(":")[6]?.trim();
    return {
      [key]: {
        text: text ?? "",
        productButtonText: productButtonText ?? "",
        description: description ?? "",
      },
    };
  });

  const reducedTrans = trans.reduce((acc, t) => {
    return { ...acc, ...t };
  }, {} as any);

  //productUrl
  const productUrl = values[1]?.replace(/\s/g, "")?.includes("sortOrder")
    ? values[1]?.replace(` sortOrder`, "")?.trim()
    : values[1]?.trim();

  //sortOrder
  const sortOrder = values[2]?.replace(/\s/g, "")?.includes("itemType")
    ? parseInt(values[2]?.replace(` itemType`, "")?.trim())
    : parseInt(values[2]?.trim());

  //itemType
  const itemType = values[3]?.replace(/\s/g, "")?.includes("image")
    ? values[3]?.replace(` image`, "")?.trim()
    : values[3]?.trim();

  const image = values[4]?.replace(/\s/g, "")?.includes("blurhash")
    ? values[4]?.replace(` blurhash`, "")?.trim()
    : values[4]?.trim();

  const blurhash = values[5]?.trim();

  const val: ChecklistTask & {
    checked: boolean;
  } = {
    id: uuidv4(),
    checked: false,
    productUrl: productUrl ?? null,
    sortOrder: sortOrder ?? 999,
    itemType: itemType ?? "",
    image: image === "undefined" ? null : image,
    blurhash: blurhash ?? null,
    translations: reducedTrans,
  };

  return val;
};

const convertChecklistTasks = (v: string[]) => {
  const data = v[8].split("\n------------------------------\n");
  const main = data
    .filter(d => d !== " " && d !== "")
    .map(d => {
      const value = d.split("\n*****\n");

      const convertedChecklistTask = convertCLTask(value);
      return convertedChecklistTask;
    });

  return main;
};

const convertToExtras = (v: string[]) => {
  const result = v[10]?.split("\n,")?.reduce((acc, e) => {
    const value = e?.split(" - ");
    const range = value[1]?.split(",").map(r => parseInt(r));

    return [
      ...acc,
      {
        audience: value[0]?.replace(/\s/g, ""),
        range: range,
        rangeUnit: value[2]?.replace("\n", ""),
      },
    ];
  }, [] as any);

  return result;
};

//For milestones days translations
const convertToDaysTranslations = (v: string) => {
  //translation
  const trans = v
    ?.split("\n*****\n")[0]
    ?.split("\n+++++\n")
    .map(t => {
      const key = t?.split(":")[0]?.replace(/\s/g, "");
      const text = t?.split(":")[2]?.replace(key, "")?.trim();
      const imageUrl = t?.split(":")[5]?.trimEnd();

      return {
        [key]: {
          text: text,
          imageUrl: `https:${imageUrl}`,
        },
      };
    });

  const reduced: MilestoneDay["translations"] = trans.reduce((acc, t) => {
    return { ...acc, ...t };
  }, {} as any);

  return reduced;
};

//Convert to Days Array
const convertToDays = (v: string[]) => {
  const days = v[3]?.split("\n-------\n");
  days?.pop();

  const result = days.map(d => {
    const dayTrans = convertToDaysTranslations(d);

    const day = d
      ?.split("\n*****\n")[1]
      ?.split(":")[1]
      ?.replace("imageUrl", "")
      ?.trim();
    const imageUrl = `https:${d
      ?.split("\n*****\n")[1]
      ?.split(":")[3]
      ?.replace("text", "")
      .trim()}`;
    const text = d?.split("\n*****\n")[1]?.split(":")[4]?.trim();

    const val: MilestoneDay = {
      id: uuidv4(),
      day: parseInt(day),
      imageUrl: imageUrl,
      text: text,
      translations: dayTrans,
    };

    return val;
  });

  return result;
};

//convertCSVPollToData
export const convertCSVPollToData = (
  val: string[][],
  articles: FullArticle[],
  pollService: PollService
) => {
  const value = val.map(async v => {
    //translations
    const trans = convertToTranslations(v, "polls");
    //answers
    const answers = convertToAnswers(v, articles);
    //extras
    const extra = convertToExtras(v);

    const val = {
      translations: {
        ...trans,
      },
      withPercentage: v[1]?.toLowerCase().includes("true") ? true : false,
      withCorrectAnswer: v[2]?.toLowerCase().includes("true") ? true : false,
      isMultiChoice: v[3]?.toLowerCase().includes("true") ? true : false,
      isActive: v[5]?.toLowerCase().includes("true") ? true : false,
      answers: answers,
      startTime: v[7] ? formatDateStringToTimeStamp(v[7], false) : null,
      endTime: v[8] ? formatDateStringToTimeStamp(v[8], false) : null,
      scheduledAt: v[9] ? formatDateStringToTimeStamp(v[9], false) : null,
      extra: extra,
    };

    const _poll: Partial<Poll> = {
      translations: val.translations,
      withPercentage: val.withPercentage,
      withCorrectAnswer: val.withCorrectAnswer,
      isMultiChoice: val.isMultiChoice,
      screens: [
        { screen: ScreenInMobile.Child, position: 0 },
        { screen: ScreenInMobile.Pregnancy, position: 0 },
      ],
      isActive: val.isActive,
      answers: val.answers ?? [],
      startTime: val.startTime,
      endTime: val.endTime,
      scheduledAt: val.scheduledAt,
      extra: val.extra ?? [],
      segment: {
        type: !_.isEmpty(val.extra) ? val.extra[0].audience : null,
        rangeUnit: DisplayUnitType.Day,
        range: !_.isEmpty(val.extra) ? val.extra[0].range : null,
      },
    };

    await pollService?.create({
      ..._poll,
      createdAt: formatDateStringToTimeStamp(moment().toISOString(), false),
    });

    return _poll;
  });

  return value;
};

export const convertCSVMilestoneToData = (
  val: string[][],
  milestonesService: MilestonesService,
  isChildPage: boolean
) => {
  val.map(async v => {
    const trans = convertToTranslations(v, "milestones");

    const measurements = convertToMeasurements(v);
    const days = convertToDays(v);
    const imageUrl = v[4];
    const imageUrlFetus = v[5];
    const imageUrlFruit = v[6];
    const period = v[7] ? parseInt(v[7]) : 0;
    const periodType = v[8];
    const blurhash = v[9];
    const blurhashFetus = v[10];
    const blurhashFruit = v[11];
    const isMonth = v[12]?.toLowerCase().includes("month");

    const val = {
      translations: {
        ...trans,
      },
      week: !isMonth && v[1] ? parseInt(v[1]) : 0,
      month: isMonth && v[1] ? parseInt(v[1]) : 0,
      days: days,
      imageUrl: imageUrl,
      imageUrlFetus: imageUrlFetus,
      imageUrlFruit: imageUrlFruit,
      period: period,
      periodType: periodType,
      blurhash: blurhash,
      blurhashFetus: blurhashFetus,
      blurhashFruit: blurhashFruit,
    };

    const isChildMonth = isChildPage && isMonth;
    const isChildWeek = isChildPage && !isMonth;

    const milestoneData: Omit<Milestone, "translations"> = isChildMonth
      ? {
          month: val.month,
          imageUrl: val.imageUrl,
          blurhash: val.blurhash,
          days: val.days,
        }
      : isChildWeek
      ? {
          week: val.week,
          imageUrl: val.imageUrl,
          blurhash: val.blurhash,
          days: val.days,
        }
      : {
          week: val.week,
          days: val.days,
          imageUrl: val.imageUrl,
          imageUrlFetus: val.imageUrlFetus,
          imageUrlFruit: val.imageUrlFruit,
          blurhash: val.blurhash,
          blurhashFruit: val.blurhashFruit,
          blurhashFetus: val.blurhashFetus,
          weight: measurements.weight.value ?? 0,
          length: measurements.length.value ?? 0,
          weightUnit: measurements.weight.displayUnit ?? "",
          lengthUnit: measurements.length.displayUnit ?? "",
          measurements: measurements,
        };

    await milestonesService?.create(
      {
        ...milestoneData,
        translations: val.translations,
      },
      isChildPage
    );
  });
};

export const convertCSVDietaryToData = (
  val: string[][],
  categories: DietaryAdviceCategory[],
  primaryLocale: Locale,
  createDietaryAdvice: UploadProps["createDietaryAdvice"]
) => {
  val.map(async v => {
    //translations
    const trans = convertToTranslations(v, "dietary");
    //category ID
    const categoryId = categories?.filter(c =>
      c.translations?.[primaryLocale?.key]?.title.includes(v[1])
    )?.[0]?.id;
    //indicator
    const indicator = v[2] as DietaryAdviceV2["indicator"];
    //promoted
    const promoted = v[3]?.toLowerCase()?.includes("true");
    //importId
    const importId = v[4];

    const val = {
      translations: {
        ...trans,
      },
      categoryId: categoryId,
      indicator: indicator,
      promoted: promoted,
      importId: importId,
    };

    const _dietaryAdvice: Omit<DietaryAdviceV2, "id"> = {
      categoryId: val.categoryId,
      indicator: val.indicator,
      importId: val.importId,
      promoted: val.promoted ?? false,
      translations: val.translations,
    };

    await createDietaryAdvice(_dietaryAdvice);
  });
};

const convertCSVChecklistToData = (
  val: string[][],
  onCreateChecklist: UploadProps["onCreate"],
  filterUnnessaryTags: UploadProps["filterUnnessaryTags"]
) => {
  val.map(async v => {
    //translations
    const trans = convertToTranslations(v, "checklist");

    //ctaButtonLink
    const ctaButtonLink = v[1];

    //sort order
    const sortOrder = parseInt(v[2]);

    //tags
    const tags = v[3]
      .split("\n")
      ?.filter(e => e !== "")
      ?.map(t => {
        return t?.replace(/\s/g, "");
      });

    //promoted
    const promoted = v[4]?.toLowerCase().includes("true");

    //types
    const types = v[5]
      .split("\n")
      ?.filter(e => e !== "")
      ?.map(t => {
        return t?.replace(/\s/g, "");
      });

    //sponsor
    const sponsor = v[6]
      .split("\n")
      .filter(s => s !== "")
      .reduce((acc, s) => {
        const key = s.split("::")[0]?.replace(/\s/g, "");
        const value = s.split("::")[1]?.replace(",", "")?.trim();
        return { ...acc, [key]: value };
      }, {} as Sponsor);

    //sponsor ID
    const sponsorID = v[7];

    //tasks
    const tasks = convertChecklistTasks(v);

    const val = {
      translations: {
        ...trans,
      },
      ctaButtonLink: ctaButtonLink,
      sortOrder: sortOrder,
      tags: filterUnnessaryTags(tags),
      promoted: promoted ?? false,
      type: types[0] ?? "pregnancy",
      types: types,
      sponsor: sponsor ?? null,
      sponsorID: sponsorID ?? null,
      tasks: tasks ?? [],
    };

    const _checklist: Partial<Checklist> & {
      tag: string;
    } = {
      translations: val.translations,
      ctaButtonLink: val.ctaButtonLink,
      sortOrder: val.sortOrder,
      tag: val.tags[0] ?? "",
      tags: val.tags,
      promoted: val.promoted,
      types: val.types,
      type: val.type,
      sponsor: val.sponsor,
      sponsorId: val.sponsorID,
      tasks: val.tasks,
    };

    await onCreateChecklist(_checklist);
  });
};
