import { commonErrors, highlightsErrorMessages } from "../language";
import { AppNavigationType, onboarding } from "../utils";
import * as yup from "yup";

const titleMaxLength = 30;
const buttonTextMaxLength = 20;

const highlightsSchema = (currentCountry: Country) =>
  yup.object().shape({
    id: yup
      .string()
      .required(`Version id: ${commonErrors.requiredError}`)
      .test(
        "RecordNotInRange",
        "Please use major and minor versions only, eg: 3.50,2.0",
        function (value: string): boolean {
          const re = new RegExp(/^(0|[1-9]\d*)\.(0|[1-9]\d*){1}$/);
          const res = re.test(value);
          const isOnboardingText = value === onboarding;
          return res || isOnboardingText;
        }
      ),
    translations: yup.object().when("displayInAppMessage", {
      is: true,
      then: schema =>
        schema.shape(
          currentCountry?.locales.reduce(function (acc, item) {
            acc[item.key] = yup.object().shape({
              title: yup
                .string()
                .required(
                  `Title (${String(item.key).toUpperCase()}): ${
                    commonErrors.requiredError
                  }`
                ),
              buttonText: yup
                .string()
                .required(
                  `Button text (${String(item.key).toUpperCase()}): ${
                    commonErrors.requiredError
                  }`
                )
                .max(
                  titleMaxLength,
                  `Button text (${String(
                    item.key
                  ).toUpperCase()}): ${highlightsErrorMessages.recordMax(
                    buttonTextMaxLength
                  )}`
                ),
              description: yup
                .string()
                .required(
                  `Description (${String(item.key).toUpperCase()}): ${
                    commonErrors.requiredError
                  }`
                ),
            });
            return acc;
          }, {} as any)
        ),
      otherwise: schema => schema.optional(),
    }),
    imageUrl: yup
      .string()
      .nullable()
      .test(
        "record valid",
        `Image: ${commonErrors.requiredError}`,
        function (value: string): boolean {
          return (
            !this.parent.displayInAppMessage ||
            (this.parent.displayInAppMessage && !!value)
          );
        }
      ),
    contentId: yup
      .string()
      .nullable()
      .test(
        "record valid",
        `Title/Question: ${commonErrors.requiredError}`,
        function (value: string): boolean {
          return (
            !this.parent.displayInAppMessage ||
            this.parent.type === AppNavigationType.SIGNUP ||
            this.parent.type === AppNavigationType.EXTERNAL ||
            (!(
              this.parent.type === AppNavigationType.SIGNUP ||
              this.parent.type === AppNavigationType.EXTERNAL
            ) &&
              !!value)
          );
        }
      ),
    type: yup
      .string()
      .test(
        "record valid",
        `Type: ${commonErrors.requiredError}`,
        function (value: string): boolean {
          return !this.parent.displayInAppMessage || !!value;
        }
      ),
    externalUrl: yup
      .string()
      .url()
      .nullable()
      .test(
        "record valid",
        `External url: ${commonErrors.requiredError}`,
        function (value: string): boolean {
          return (
            !this.parent.displayInAppMessage ||
            (this.parent.type === AppNavigationType.EXTERNAL ? !!value : true)
          );
        }
      ),
  });

export { highlightsSchema };
