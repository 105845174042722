import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import firebase from "../firebase";
import { WeeklyInformationService } from "../services";
import { useCountry } from "./CountryContext";

type ContextState = {
  weeklyInformations: WeeklyInformation[];
  loading: boolean;
  error: Error | null;
  getById: (id: WeeklyInformation["id"]) => WeeklyInformation | null;
  createWeeklyInfo: (
    data: Omit<WeeklyInformation, "id">
  ) => Promise<firebase.firestore.DocumentReference | null>;
  updateWeeklyInfo: (
    id: WeeklyInformation["id"],
    data: Omit<WeeklyInformation, "id">
  ) => Promise<void>;
  deleteWeeklyInfo: (id: string) => Promise<void>;
};

const WeeklyInformationContext = createContext<ContextState>({
  weeklyInformations: [],
  loading: false,
  error: null,
  getById: () => null,
  createWeeklyInfo: () => null,
  updateWeeklyInfo: () => null,
  deleteWeeklyInfo: () => null,
});

export const WeeklyInformationProvider = ({ ...rest }) => {
  const [weeklyInformations, setWeeklyInformations] = useState<
    WeeklyInformation[]
  >([]);
  const [loadingWeeklyInformation, setLoadingWeeklyInformation] =
    useState(true);
  const [error, setError] = useState<Error | null>(null);

  const { currentCountry } = useCountry();

  const weeklyInformationService = useMemo(
    () =>
      currentCountry?.abb
        ? new WeeklyInformationService(currentCountry.abb)
        : null,
    [currentCountry?.abb]
  );

  useEffect(() => {
    setLoadingWeeklyInformation(true);

    const unsubscribeWeeklyInformation = weeklyInformationService?.subscribe(
      (_error, _weeklyInformation) => {
        setWeeklyInformations(_weeklyInformation);
        setError(_error);
        setLoadingWeeklyInformation(false);
      }
    );

    return () => {
      if (weeklyInformationService) {
        unsubscribeWeeklyInformation();
      }
    };
  }, [weeklyInformationService]);

  const getById = useCallback(
    (id: WeeklyInformation["id"]) => {
      const _weeklyInformation = weeklyInformations.find(a => a.id === id);
      return _weeklyInformation || null;
    },
    [weeklyInformations]
  );

  const createWeeklyInfo = useCallback(
    async (data: Omit<WeeklyInformation, "id">) =>
      await weeklyInformationService.create(data),
    [weeklyInformationService]
  );

  const updateWeeklyInfo = useCallback(
    async (id: WeeklyInformation["id"], data: Omit<WeeklyInformation, "id">) =>
      await weeklyInformationService.update(id, data),
    [weeklyInformationService]
  );

  const deleteWeeklyInfo = useCallback(
    async (id: string) => await weeklyInformationService.delete(id),
    [weeklyInformationService]
  );

  const value = useMemo(
    () => ({
      weeklyInformations,
      loading: loadingWeeklyInformation,
      error,
      getById,
      createWeeklyInfo,
      updateWeeklyInfo,
      deleteWeeklyInfo,
    }),
    [
      weeklyInformations,
      loadingWeeklyInformation,
      error,
      getById,
      createWeeklyInfo,
      updateWeeklyInfo,
      deleteWeeklyInfo,
    ]
  );

  return <WeeklyInformationContext.Provider value={value} {...rest} />;
};

export const useWeeklyInformation = () => {
  const context = React.useContext(WeeklyInformationContext);
  if (context === undefined) {
    throw new Error(
      "useWeeklyInformation must be used within an WeeklyInformationProvider"
    );
  }
  return context;
};

export const useWeekInformation = (
  weekInformationId: WeeklyInformation["id"]
) => {
  const [weekInformation, setWeekInformation] =
    useState<WeeklyInformation | null>(null);

  const {
    getById,
    loading: loadingWeeklyInformation,
    ...rest
  } = useWeeklyInformation();

  useEffect(() => {
    if (loadingWeeklyInformation) {
      return;
    }
    setWeekInformation(getById(weekInformationId));
  }, [loadingWeeklyInformation, getById, weekInformationId]);

  return {
    weekInformation,
    loading: loadingWeeklyInformation,
    ...rest,
  };
};
