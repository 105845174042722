import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import firebase from "../firebase";
import { useCountry } from "./CountryContext";

import {
  DietaryAdviceService,
  DietaryAdviceCategoryService,
  DietaryAdviceContentService,
} from "../services";

type ContextState = {
  dietaryAdvices: FullDietaryAdvice[];
  categories: DietaryAdviceCategory[];
  content: DietaryAdviceContent | null;
  loading: boolean;
  error: Error | null;
  getById: (id: DietaryAdviceV2["id"]) => DietaryAdviceV2 | null;
  updateDietaryAdvice: (
    id: DietaryAdviceV2["id"],
    data: Omit<DietaryAdviceV2, "id">
  ) => Promise<void>;
  createDietaryAdvice: (
    data: Omit<DietaryAdviceV2, "id">
  ) => Promise<firebase.firestore.DocumentReference | null>;
  deleteDietaryAdvice: (id: string) => Promise<void>;
};

const DietaryAdvicesContext = createContext<ContextState>({
  dietaryAdvices: [],
  categories: [],
  content: null,
  loading: false,
  error: null,
  getById: () => null,
  createDietaryAdvice: async () => null,
  updateDietaryAdvice: async () => null,
  deleteDietaryAdvice: async () => null,
});

export const DietaryAdvicesProvider = ({ ...rest }) => {
  const [dietaryAdvicesData, setDietaryAdvicesData] = useState<
    DietaryAdviceV2[]
  >([]);
  const [categoriesData, setCategoriesData] = useState<DietaryAdviceCategory[]>(
    []
  );
  const [dietaryAdvices, setDietaryAdvices] = useState<FullDietaryAdvice[]>([]);
  const [categories, setCategories] = useState<DietaryAdviceCategory[]>([]);
  const [content, setContent] = useState<DietaryAdviceContent | null>(null);
  const [loadingDietaryAdvices, setLoadingDietaryAdvices] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingContent, setLoadingContent] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const { currentCountry } = useCountry();

  const dietaryAdvicesService = useMemo(
    () =>
      currentCountry?.abb ? new DietaryAdviceService(currentCountry.abb) : null,
    [currentCountry?.abb]
  );
  const dietaryAdviceContentService = useMemo(
    () =>
      currentCountry?.abb
        ? new DietaryAdviceContentService(currentCountry.abb)
        : null,
    [currentCountry?.abb]
  );
  const dietaryAdviceCategoryService = useMemo(
    () =>
      currentCountry?.abb
        ? new DietaryAdviceCategoryService(currentCountry.abb)
        : null,
    [currentCountry?.abb]
  );

  useEffect(() => {
    setLoadingDietaryAdvices(true);
    setLoadingCategories(true);
    if (!dietaryAdvicesService) {
      return;
    }
    setLoadingContent(true);

    const unsubscribeDietaryAdvices = dietaryAdvicesService.subscribe(
      (_error, _dietaryAdvices) => {
        setDietaryAdvicesData(_dietaryAdvices);
        setError(_error);
        setLoadingDietaryAdvices(false);
      }
    );

    const unsubscribeCategories = dietaryAdviceCategoryService.subscribe(
      (_error, _categories) => {
        setCategoriesData(_categories);
        setError(_error);
        setLoadingCategories(false);
      }
    );

    const unsubscribeContents = dietaryAdviceContentService.subscribe(
      (_error, _content) => {
        setContent(_content);
        setError(_error);
        setLoadingContent(false);
      }
    );

    return () => {
      unsubscribeDietaryAdvices();
      unsubscribeCategories();
      unsubscribeContents();
    };
  }, [
    dietaryAdvicesService,
    dietaryAdviceCategoryService,
    dietaryAdviceContentService,
  ]);

  useEffect(() => {
    if (loadingDietaryAdvices || loadingCategories) {
      return;
    }

    const categoryMap = new Map<
      DietaryAdviceCategory["id"],
      DietaryAdviceCategory
    >();

    categoriesData.forEach(category => {
      categoryMap.set(category.id, category);
    });

    const _dietaryAdvices: FullDietaryAdvice[] = dietaryAdvicesData.map(
      dietaryAdvices => {
        return {
          ...dietaryAdvices,
          category: categoryMap.get(dietaryAdvices.categoryId) || null,
        };
      }
    );

    setDietaryAdvices(_dietaryAdvices);
    setCategories(categoriesData);
  }, [
    loadingDietaryAdvices,
    loadingCategories,
    dietaryAdvicesData,
    categoriesData,
  ]);

  const getById = useCallback(
    (id: DietaryAdvice["id"]) => {
      const dietaryAdvice = dietaryAdvices.find(a => a.id === id);
      return dietaryAdvice || null;
    },
    [dietaryAdvices]
  );

  const loading = loadingDietaryAdvices || loadingCategories || loadingContent;

  const updateDietaryAdvice = useCallback(
    async (id: DietaryAdviceV2["id"], data: Omit<DietaryAdviceV2, "id">) =>
      await dietaryAdvicesService.update(id, data),
    [dietaryAdvicesService]
  );

  const createDietaryAdvice = useCallback(
    async (data: Omit<DietaryAdviceV2, "id">) =>
      await dietaryAdvicesService.create(data),
    [dietaryAdvicesService]
  );

  const deleteDietaryAdvice = useCallback(
    async (id: string) => await dietaryAdvicesService.delete(id),
    [dietaryAdvicesService]
  );

  const value = useMemo(
    () => ({
      dietaryAdvices,
      categories,
      content,
      loading,
      error,
      getById,
      updateDietaryAdvice,
      createDietaryAdvice,
      deleteDietaryAdvice,
    }),
    [
      dietaryAdvices,
      categories,
      content,
      loading,
      error,
      getById,
      updateDietaryAdvice,
      createDietaryAdvice,
      deleteDietaryAdvice,
    ]
  );

  return <DietaryAdvicesContext.Provider value={value} {...rest} />;
};

export const useDietaryAdvices = () => {
  const context = React.useContext(DietaryAdvicesContext);
  if (context === undefined) {
    throw new Error(
      "useDietaryAdvices must be used within an DietaryAdvicesProvider"
    );
  }
  return context;
};

export const useDietaryAdvice = (dietaryAdviceId: DietaryAdviceV2["id"]) => {
  const [dietaryAdvice, setDietaryAdvice] = useState<DietaryAdviceV2 | null>(
    null
  );
  const [loading, setLoading] = useState(true);

  const { getById, loading: loadingDietaryAdvice } = useDietaryAdvices();

  useEffect(() => {
    if (loadingDietaryAdvice) {
      return;
    }

    setDietaryAdvice(getById(dietaryAdviceId));
    setLoading(false);
  }, [loadingDietaryAdvice, getById, dietaryAdviceId]);

  return { dietaryAdvice, loading };
};
