import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import {
  formatTimeStampToMoment,
  RemoteNotificationRecordType,
} from "../utils";
import { firebaseBucketConsts } from "../utils/consts";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";
import { uploadImage } from "./serviceUtils";

const tipsStoragePath: string = firebaseBucketConsts.remoteNotifications;

export class RemoteNotificationService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  persistError = async (firebaseRecordId: string | undefined, error: Error) => {
    if (firebaseRecordId) {
      await firebase
        .firestore()
        .collection(CollectionPaths.RemoteNotifications[this.countryCode])
        .doc(firebaseRecordId)
        .set(
          {
            status: RemoteNotificationRecordType.error,
            clientError: JSON.stringify(error),
          },
          { merge: true }
        );
    }
  };

  static toRemoteNotifications = (doc: any): NotificationInfo => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      scheduledAt: formatTimeStampToMoment(data.scheduledAt),
    };
  };

  subscribeToRemoteNotifications = (
    callback: (err: Error | null, snapshot: NotificationInfo[]) => void
  ) => {
    return firebase
      .firestore()
      .collection(CollectionPaths.RemoteNotifications[this.countryCode])
      .onSnapshot(
        snapshot => {
          const remoteNotifications = snapshot.docs.map(
            RemoteNotificationService.toRemoteNotifications
          );
          callback(null, remoteNotifications);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );
  };

  sendRemoteNotifiction = async (content: NotificationInfo) => {
    try {
      await this.saveNotificationInfo(
        content,
        content.scheduledAt
          ? RemoteNotificationRecordType.schedulePending
          : RemoteNotificationRecordType.pending
      );
    } catch (error) {
      this.persistError(content?.id, error as Error);
      logException(`Error occured while sending notification ${error}`);
      throw error;
    }
  };

  onRemoveScheduled = async (firebaseRecordId: string) => {
    await firebase
      .firestore()
      .collection(CollectionPaths.RemoteNotifications[this.countryCode])
      .doc(firebaseRecordId as string)
      .set(
        {
          status: RemoteNotificationRecordType.scheduleDeletePending,
        },
        { merge: true }
      );
  };

  saveNotificationInfo = async (
    content: NotificationInfo,
    type: RemoteNotificationRecordType
  ) => {
    const { id, ...rest } = content;
    if (!id) {
      const addedRecord = await firebase
        .firestore()
        .collection(CollectionPaths.RemoteNotifications[this.countryCode])
        .add({
          ...rest,
          status: type,
        });
      return addedRecord.id;
    } else {
      await firebase
        .firestore()
        .collection(CollectionPaths.RemoteNotifications[this.countryCode])
        .doc(id)
        .update({
          ...rest,
          status: type,
        });
      return id;
    }
  };

  uploadRemoteNotificationImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => {
    const url = uploadImage(file, tipsStoragePath, fileName);
    return url;
  };
}
