import { useEffect } from "react";
import { commonErrors } from "../../../language";
import { useForm } from "react-hook-form";
import { FormModal } from "../../../components";
import { formValidationError } from "../../../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { BJInputFormItem } from "../../../components/theme";
import { useCountry } from "../../../context";

interface Props {
  show: boolean;
  tryItListItem: TryItListItem | null;
  onHide: () => void;
  onUpdate: (task: TryItListItem) => void;
  onDelete: (id: TryItListItem["id"]) => void;
  onAdd: (task: TryItListItem) => void;
}

export const UpdateTryItListItemModal = ({
  show,
  tryItListItem,
  onHide,
  onUpdate,
  onDelete: onDeleteTask,
  onAdd,
}: Props) => {
  const { currentCountry } = useCountry();

  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          text: yup
            .string()
            .required(
              `Text (${String(item.key).toUpperCase()}): ${
                commonErrors.requiredError
              }`
            ),
        });
        return acc;
      }, {} as any)
    ),
  });

  const {
    handleSubmit,
    control,
    formState,
    reset,
    formState: { errors },
  } = useForm<TryItListItem>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      translations: tryItListItem?.translations,
    });
  }, [reset, show, tryItListItem]);

  const onSubmit = async (data: TryItListItem) => {
    if (tryItListItem !== null) {
      const updated = {
        ...tryItListItem,
        translations: data.translations,
      };
      onUpdate(updated);
    } else {
      const tryItListItem: TryItListItem = {
        id: uuidv4(),
        translations: data.translations,
        sortOrder: 999,
      };
      onAdd(tryItListItem);
    }
  };

  const onDelete = async () => {
    if (tryItListItem !== null) {
      onDeleteTask(tryItListItem.id);
    }
  };

  const isDirty = !!Object.keys(formState.dirtyFields).length;

  return (
    <FormModal
      onHide={onHide}
      enableSave={isDirty}
      show={show}
      messageOnSubmit={false}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      error={null}
      onDelete={onDelete}
      enableDelete={!!tryItListItem}
      modalSubTitle={tryItListItem ? `Id - ${tryItListItem?.id}` : ""}
      modalTitle="TryIt List Item"
      recordIdentifier={tryItListItem?.id}
      localeSupported
      errors={errors as any}
    >
      {locale => (
        <BJInputFormItem
          label={`Text (${locale?.label ?? ""})`}
          fieldName={`translations.${locale.key}.text`}
          key={`translations.${locale.key}.text`}
          control={control}
          error={!!errors?.translations?.[locale.key]?.text}
          message={errors?.translations?.[locale.key]?.text?.message}
          required={true}
        />
      )}
    </FormModal>
  );
};
