import styled from "styled-components";
import { Layout } from "antd";
import SiderNavigationBar from "../components/theme/components/SiderNavBar";
import { Content } from "antd/lib/layout/layout";
import { BJContainer } from "../components/theme/atoms/BJContainer";
import { FaPeopleArrows, FaTasks, FaMobileAlt, FaMedal } from "react-icons/fa";
import { MdDataUsage, MdOutlinePoll, MdPregnantWoman } from "react-icons/md";
import { IoTimerOutline } from "react-icons/io5";
import { Dashboard, FAQ, Offers, Influencer } from "../assets/icons/icons";
import { BsFillGeoAltFill, BsFunnel, BsBell, BsTools } from "react-icons/bs";
import { GiJourney } from "react-icons/gi";
import { BiCategory } from "react-icons/bi";
import { VscPreview } from "react-icons/vsc";
import { BiBookContent } from "react-icons/bi";
import { PiStudent } from "react-icons/pi";
import { Outlet } from "react-router";
import { ErrorFallback } from "../components";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import {
  offers,
  partnersDashboard,
  checklistDashboard,
  popularUsageChecklist,
  checklist,
  geo,
  geoAnalytics,
  influencerDashboard,
  screen,
  screenDiscover,
  faqDashboard,
  interestsDashboard,
  discoverSearch,
  offersBySource,
  offersByUsers,
  tools,
  birthPlan,
  engagementDashboard,
  pollAnalytics,
  usersPregnancy,
  users,
  popularContent,
  popular,
  recommendedContent,
  popularClicks,
  mainJourneyDashboard,
  appReviewModal,
  courses,
  loyalty,
  loyaltyGeneral,
  loyaltyComparison,
  bannersDashboard,
  dailyDoula,
  content,
  articleContent,
  contentUsage,
  timestamp,
  loyaltyProfileAndMissions,
  loyaltyLockedContentAndFeatures,
  timeSpentApp,
  timeSpentScreen,
  paidSocialDashboard,
} from "../routes/analyticsRouteConsts";

export const AnalyticsLayout = () => {
  const navigate = useNavigate();

  const menuItemList: MenuItemInfo[] = [
    {
      displayName: "Users",
      url: users,
      icon: <Dashboard />,
      subMenu: [
        //use in future
        // {
        //   key: usersChild,
        //   value: usersChild,
        //   displayName: "Users With children's",
        // },
        {
          key: usersPregnancy,
          value: usersPregnancy,
          displayName: "Users With pregnancy",
        },
      ],
      subMenuKey: users,
    },
    {
      displayName: "Partners",
      url: partnersDashboard,
      icon: <FaPeopleArrows />,
    },
    {
      displayName: "Offers",
      url: offers,
      icon: <Offers />,
      subMenu: [
        {
          key: "offersBySource",
          value: offersBySource,
          displayName: "By Source",
        },
        {
          key: "offersByUsers",
          value: offersByUsers,
          displayName: "By Users",
        },
      ],
      subMenuKey: offers,
    },
    {
      displayName: "Checklists",
      url: checklist,
      icon: <FaTasks />,
      subMenu: [
        {
          key: "checklistDashboard",
          value: checklistDashboard,
          displayName: "Checklist Analytics",
        },
        {
          key: "popularUsageChecklist",
          value: popularUsageChecklist,
          displayName: "Popular Usage",
        },
      ],
      subMenuKey: checklist,
    },
    {
      displayName: "FAQs",
      url: faqDashboard,
      icon: <FAQ />,
    },
    {
      displayName: "Banners",
      url: bannersDashboard,
      icon: <BiCategory />,
    },
    {
      displayName: "Courses",
      url: courses,
      icon: <PiStudent />,
    },
    {
      displayName: "Loyalty",
      url: loyalty,
      icon: <FaMedal />,
      subMenu: [
        {
          key: "loyaltyGeneral",
          value: loyaltyGeneral,
          displayName: "General",
        },
        {
          key: "loyaltyComparison",
          value: loyaltyComparison,
          displayName: "Comparison",
        },
        {
          key: "loyaltyProfileAndMissions",
          value: loyaltyProfileAndMissions,
          displayName: "Profile & missions",
        },
        {
          key: "loyaltyLockedContentAndFeatures",
          value: loyaltyLockedContentAndFeatures,
          displayName: "Locked content & features",
        },
      ],
      subMenuKey: loyalty,
    },
    {
      displayName: "Geo",
      url: geo,
      icon: <BsFillGeoAltFill />,
      subMenu: [
        {
          key: "geoAnalytics",
          value: geoAnalytics,
          displayName: "Geo Analytics",
        },
      ],
      subMenuKey: geo,
    },
    {
      displayName: "Influencer",
      url: influencerDashboard,
      icon: <Influencer />,
    },
    {
      displayName: "App Review Modal",
      url: appReviewModal,
      icon: <VscPreview />,
    },
    {
      displayName: "Screens",
      url: screen,
      icon: <FaMobileAlt />,
      subMenu: [
        {
          key: "screenDiscover",
          value: screenDiscover,
          displayName: "Discover",
        },
        {
          key: "discoverSearch",
          value: discoverSearch,
          displayName: "Discover search",
        },
      ],
      subMenuKey: screen,
    },
    {
      displayName: "Interests",
      url: interestsDashboard,
      icon: <BiCategory />,
    },
    {
      displayName: "Content",
      url: content,
      icon: <BiBookContent />,
      subMenu: [
        {
          key: "articleContent",
          value: articleContent,
          displayName: "Articles & Videos",
        },
        {
          key: "contentUsage",
          value: contentUsage,
          displayName: "Content Usage",
        },
      ],
      subMenuKey: content,
    },

    {
      displayName: "Paid Social",
      url: paidSocialDashboard,
      icon: <BsFunnel />,
    },
    {
      displayName: "Tools",
      url: tools,
      icon: <BsTools />,
      subMenu: [
        {
          key: "toolBirthPlan",
          value: birthPlan,
          displayName: "Birth Letter",
        },
      ],
      subMenuKey: tools,
    },
    {
      displayName: "Main Journey",
      url: mainJourneyDashboard,
      icon: <GiJourney />,
    },
    {
      displayName: "Engagement",
      url: engagementDashboard,
      icon: <MdDataUsage />,
    },
    {
      displayName: "Polls",
      url: pollAnalytics,
      icon: <MdOutlinePoll />,
    },
    {
      displayName: "Popular Content",
      url: popular,
      icon: <BiBookContent />,
      subMenu: [
        {
          key: "popularContent",
          value: popularContent,
          displayName: "Content",
        },
        {
          key: "popularClicks",
          value: popularClicks,
          displayName: "Clicks",
        },
      ],
      subMenuKey: popular,
    },
    {
      displayName: "Recommended Content",
      url: recommendedContent,
      icon: <BiBookContent />,
    },
    {
      displayName: "Daily Doula",
      url: dailyDoula,
      icon: <MdPregnantWoman />,
    },
    {
      displayName: "Time Spent",
      url: timestamp,
      subMenu: [
        {
          key: "timeSpentApp",
          value: timeSpentApp,
          displayName: "App",
        },
        {
          key: "timeSpentScreen",
          value: timeSpentScreen,
          displayName: "Screen",
        },
      ],
      icon: <IoTimerOutline />,
      subMenuKey: timestamp,
    },
  ];

  return (
    <Layout>
      <StyledLayout>
        <SiderNavigationBar items={menuItemList} />
        <StyledLayoutContent>
          <Content>
            <BJContainer>
              <Sentry.ErrorBoundary
                fallback={ErrorFallback}
                onReset={() => {
                  navigate("./");
                }}
              >
                <Outlet />
              </Sentry.ErrorBoundary>
            </BJContainer>
          </Content>
        </StyledLayoutContent>
      </StyledLayout>
    </Layout>
  );
};

const StyledLayout = styled(Layout)`
  background-color: ${props => props.theme.white};
`;

const StyledLayoutContent = styled(Layout)`
  padding: 1rem 1rem 1rem;
  margin-bottom: 2%;
`;
