import { NavLink, Outlet, useLocation } from "react-router-dom";
import { Layout, Space } from "antd";
import { ReactComponent as Logo } from "./../assets/logo-purpledark.svg";
import { ReactComponent as HeaderBackground } from "./../assets/header-background.svg";
import styles from "./../styles/header.module.scss";
import styled from "styled-components";
import {
  StyledHeaderMenuItem,
  StyledMenu,
} from "../components/theme/components/SiderNavBar";
import { useApplicationCommonContext, useAuth, useAuthToken } from "../context";
import BJButton from "../components/theme/atoms/Button";
import { cmsKey, GetCms } from "../routes";
import { GetAdmin } from "../routes/adminRoutePaths";
import { useEffect, useMemo, useState } from "react";
import { adminKey } from "../routes/adminRouteConsts";
import { Link } from "react-router-dom";
import { analyticsKey } from "../routes/analyticsRouteConsts";
import { GetAnalytics } from "../routes/analyticsRoutePaths";
import {
  pushNotifications,
  pushNotificationsKey,
} from "../routes/pushNotificationsRouteConsts";
import { getEnvironment } from "../utils";
import BJSelect from "../components/theme/atoms/BJSelect";
import { FaChevronDown } from "react-icons/fa";
import { useCountry } from "../context";
import { useDisplayCountrySelector } from "../hooks";

const { Header } = Layout;
const environment = getEnvironment();
const BJHeader = () => {
  const { signOut, authUser } = useAuth();
  const location = useLocation();
  const { setTableSearchQuery } = useApplicationCommonContext();
  const [selectedNav, setselectedNav] = useState<string>(cmsKey);
  const { isAdmin, isAnalytic, isModerator, isnotificationHandler } =
    useAuthToken();
  const { countries, currentCountry, setCountry, resetCountry } = useCountry();
  const isDisplayCountrySelector = useDisplayCountrySelector();

  useEffect(() => {
    const [, mainRoute] = location.pathname.split("/");
    setselectedNav(mainRoute);
  }, [location.pathname]);

  const resetTableSearch = () => {
    setTableSearchQuery({});
  };

  const countriesForSelector = useMemo(() => {
    return countries
      .filter(c => c.isActive)
      .map(country => ({
        value: country.abb,
        key: country.abb,
        label: country.name,
      }));
  }, [countries]);

  return (
    <Header className={styles.headerd}>
      <div className={styles.headerLogout}>
        <Space size={"large"}>
          {environment !== "PRODUCTION" && <div>{getEnvironment()}</div>}
          {isDisplayCountrySelector && (
            <BJSelect
              options={countriesForSelector}
              defaultValue={currentCountry?.abb}
              value={currentCountry?.abb}
              suffixIcon={<FaChevronDown />}
              size="large"
              onChange={setCountry}
            />
          )}
          <Link onClick={resetTableSearch} to={"./profile/edit"}>
            {authUser?.displayName ?? "Profile"}
          </Link>
          <BJButton
            size="large"
            rounded
            onClick={() => {
              resetCountry();
              signOut();
            }}
          >
            Logout
          </BJButton>
        </Space>
      </div>

      <HeaderBackground />
      <div className={styles.alignH}>
        <div>
          <Logo />
        </div>
        <MenuWrapper>
          <StyledMenu
            mode="horizontal"
            style={{ pointerEvents: "all" }}
            selectedKeys={[selectedNav]}
          >
            {(isAdmin || isModerator) && (
              <StyledHeaderMenuItem key={cmsKey}>
                <NavLink onClick={resetTableSearch} to={GetCms()}>
                  CMS
                </NavLink>
              </StyledHeaderMenuItem>
            )}
            {isAdmin && (
              <StyledHeaderMenuItem key={adminKey}>
                <NavLink onClick={resetTableSearch} to={GetAdmin()}>
                  Admin
                </NavLink>
              </StyledHeaderMenuItem>
            )}
            {(isAdmin || isAnalytic) && (
              <StyledHeaderMenuItem key={analyticsKey}>
                <NavLink onClick={resetTableSearch} to={GetAnalytics()}>
                  Analytics
                </NavLink>
              </StyledHeaderMenuItem>
            )}
            {(isAdmin || isnotificationHandler) && (
              <StyledHeaderMenuItem key={pushNotificationsKey}>
                <NavLink onClick={resetTableSearch} to={pushNotifications}>
                  Notifications
                </NavLink>
              </StyledHeaderMenuItem>
            )}
          </StyledMenu>
        </MenuWrapper>
      </div>
    </Header>
  );
};

export const BJMainLayout = () => {
  return (
    <>
      <BJHeader />
      <Outlet />
    </>
  );
};

const MenuWrapper = styled.div`
  z-index: 1;
  width: 50%;

  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    content: none !important;
  }
  &.ant-menu-horizontal,
  .ant-menu-item,
  .ant-menu-item-active {
    &:hover {
      background-color: ${props => props.theme.hover.menu} !important;
    }
  }

  .ant-menu-item-selected {
    background-color: ${props => props.theme.hover.menu} !important;
  }
`;
