import { useState, useEffect } from "react";
import { CommonContentType } from "../../utils";
import { Divider, List, Space, Typography } from "antd";
import { ContentListItem, ContentOrderList } from "./ContentOrderList";
import BJButton from "../../components/theme/atoms/Button";
import { BJFlex } from "../../components/styled";
import BJSelect from "../../components/theme/atoms/BJSelect";
import { FaChevronDown } from "react-icons/fa";

interface Props {
  initialContent: CommonContent[];
  onAdd: (type: CommonContentType) => void;
  onSave: (content: CommonContent[]) => void;
  onPressRemove: (content: CommonContent) => void;
  maxContentLimit: number;
  contentType: CommonContentType;
  displayButtons?: boolean;
  title?: string;
  contentOptions: {
    item: CommonContentType;
    label: string;
    value: CommonContentType;
  }[];
  enableOrder?: boolean;
}

export const ContentManager = ({
  initialContent,
  onAdd,
  onSave,
  onPressRemove,
  maxContentLimit,
  displayButtons,
  contentType,
  title,
  contentOptions,
  enableOrder,
}: Props) => {
  const [content, setContent] = useState<CommonContentV2[]>([]);
  const [isOrderMode, setIsOrderMode] = useState(false);

  useEffect(() => {
    const sortedContent = initialContent
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((t, i) => ({ ...t, sortOrder: i }));
    setContent(sortedContent);
  }, [initialContent]);

  return (
    <>
      <Typography.Paragraph>{title ?? "Add content"}</Typography.Paragraph>
      {isOrderMode ? (
        <>
          <ContentOrderList
            onClose={() => {
              setIsOrderMode(false);
            }}
            contentProp={[...content]}
            onSave={onSave}
          />
        </>
      ) : (
        <>
          <ContentHeader
            contentOptions={contentOptions}
            contentLength={content?.length ?? 0}
            displayLegacy={displayButtons}
            contentType={contentType}
            contentLimitReached={maxContentLimit === content.length}
            setIsOrder={(isOrder: boolean) => {
              setIsOrderMode(isOrder);
            }}
            onAddContent={onAdd}
            enableOrder={enableOrder}
          />
          {content?.length ? (
            <List bordered>
              {content.map(content => (
                <ContentListItem
                  key={content.id}
                  value={content}
                  removable={true}
                  onPressRemove={onPressRemove}
                />
              ))}
            </List>
          ) : (
            <></>
          )}
        </>
      )}
      <Divider />
    </>
  );
};

const ContentHeader = ({
  contentLimitReached,
  setIsOrder,
  onAddContent,
  contentType,
  displayLegacy,
  contentLength = 0,
  contentOptions,
  enableOrder = true,
}: {
  contentLimitReached: boolean;
  displayLegacy: boolean;
  setIsOrder: (isOrder: boolean) => void;
  onAddContent: (type: CommonContentType) => void;
  contentType: CommonContentType;
  contentLength?: number;
  contentOptions: {
    item: CommonContentType;
    label: string;
    value: CommonContentType;
  }[];
  enableOrder?: boolean;
}) => {
  return (
    <>
      <BJFlex justifyRight mBottom={1} mTop={1}>
        <Space align="end">
          {displayLegacy ? (
            contentOptions.map(x => (
              <BJButton
                key={x.value}
                disabled={contentLimitReached}
                onClick={() => onAddContent(x.value)}
              >
                {x.label}
              </BJButton>
            ))
          ) : (
            <BJSelect
              onChange={onAddContent}
              options={contentOptions}
              value={contentType ?? "Choose content"}
              defaultValue="Choose content"
              suffixIcon={<FaChevronDown />}
            />
          )}
          {enableOrder && contentLength > 0 && (
            <BJButton onClick={() => setIsOrder(true)}>Change order</BJButton>
          )}
        </Space>
      </BJFlex>
    </>
  );
};
