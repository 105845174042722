import { yupResolver } from "@hookform/resolvers/yup";
import { Space } from "antd";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { FormEdit, FormEditType } from "../../components/FormEdit";
import { BJInputFormItem } from "../../components/theme/molecules/formItems/BJFormInput";
import { BJSelectFormItem } from "../../components/theme/molecules/formItems/BJFormSelectItem";
import {
  usePopularContent,
  usePopularContentByMonths,
  usePopularContentByWeeks,
  usePopularContents,
} from "../../context";
import { curatedPageMessages } from "../../language";
import {
  ContentListType,
  PopularAudienceType,
  PopularType,
  childWeekValidationMaxValue,
  formValidationError,
  getFormattedErrorMessage,
  getOrdinal,
  isCuratedContentLimitExceeded,
  isEqualArrays,
  monthContentLimit,
  monthValidationMaxValue,
  monthValidationMinValue,
  weekContentLimit,
  weekValidationMaxValue,
} from "../../utils";
import { ContentSelector } from "./ContentSelector";

type FormValues = {
  monthOrWeek: number;
  popularType: "week" | "month";
  audienceType: "child" | "pregnancy";
};

export const PopularPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const { onCreate, onUpdate, onDelete } = usePopularContents();
  const { popularContent, loading } = usePopularContent(id);
  const { popularContents: monthlyArticles, loading: loadingPopularMonths } =
    usePopularContentByMonths();
  const { popularContents: weeklyArticles, loading: loadingPopularWeeks } =
    usePopularContentByWeeks();

  const [error, setError] = useState<string | null>(null);
  const [isContentLimitExceeded, setIsContentLimitExceeded] = useState(false);

  const [managedContent, setManagedContent] = useState<PopularContent[]>([]);
  const schema = yup.object().shape({
    popularType: yup
      .string()
      .required(curatedPageMessages.requiredValidationText),
    monthOrWeek: yup
      .number()
      .min(monthValidationMinValue, curatedPageMessages.numberTypeErrorMessage)
      .required(curatedPageMessages.requiredValidationText)
      .typeError(curatedPageMessages.numberTypeErrorMessage)
      .test(
        "RecordNotInRange",
        curatedPageMessages.numberNotInRangeErrorMessage,
        function (value: number | undefined): boolean {
          const popularType = this.parent.popularType;
          let notInRange = true;
          if (audienceType == PopularAudienceType.CHILD) {
            // for now we are not restricting 1,2 month entry due to backward compatibility, after update need to restrict it
            notInRange =
              popularType == PopularType.WEEK
                ? value! > childWeekValidationMaxValue
                : value! > monthValidationMaxValue;
          } else {
            notInRange =
              popularType === PopularType.MONTH
                ? value! > monthValidationMaxValue
                : value! > weekValidationMaxValue;
          }
          return !notInRange;
        }
      )
      .test(
        "recordAvailable",
        curatedPageMessages.recordAvailableError,
        function (value: number | undefined): boolean {
          const popularType = this.parent.popularType;
          if (popularContent !== null) return true;
          const audienceType = this.parent.audienceType;
          let notAvailable = false;
          if (popularType === PopularType.MONTH) {
            notAvailable = !monthlyArticles.some(
              x =>
                x.month === value! &&
                (x.audienceType ? x.audienceType === audienceType : true)
            );
          } else {
            notAvailable = !weeklyArticles.some(x => {
              if (audienceType == PopularAudienceType.CHILD) {
                return x.childWeek === value!;
              }
              return x.week === value!;
            });
          }
          return notAvailable;
        }
      ),
    audienceType: yup
      .string()
      .required(curatedPageMessages.requiredValidationText),
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    formState,
    control,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const popularType = watch("popularType");
  const audienceType = watch("audienceType");
  console.log("popularType...", popularType);

  useEffect(() => {
    if (popularContent) {
      reset({
        monthOrWeek:
          popularContent.type === PopularType.MONTH
            ? popularContent.month!
            : popularContent.audienceType == PopularAudienceType.CHILD
            ? popularContent.childWeek!
            : popularContent.week!,
        popularType: popularContent.type,
        audienceType:
          popularContent.audienceType ||
          (popularContent.type === PopularType.WEEK
            ? PopularAudienceType.PREGNANCY
            : PopularAudienceType.CHILD),
      });
    } else {
      reset({
        popularType: PopularType.MONTH,
        monthOrWeek: 0,
        audienceType: "child",
      });
    }
  }, [popularContent, reset]);

  useEffect(() => {
    const noOfContent = managedContent.length;
    const isLimitExceeded = isCuratedContentLimitExceeded(
      popularType,
      noOfContent
    );
    setIsContentLimitExceeded(isLimitExceeded);
    setError(
      isLimitExceeded ? curatedPageMessages.contentLimitErrorMessage : null
    );
  }, [managedContent.length, popularType]);

  const onSubmit: SubmitHandler<FormValues> = async data => {
    if (isContentLimitExceeded) {
      return;
    }
    try {
      const contentsData = managedContent
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(
          (
            {
              id,
              imageUrl,
              squareImageUrl,
              stretchedImageUrl,
              imageBlurhash,
              blurhash,
              stretchedBlurhash,
              squareBlurhash,
              type,
              podcastId,
              blogPostUrl,
              restricted,
              translations,
            },
            index
          ) => {
            const mappedContent: PopularContent = {
              id,
              imageUrl,
              squareImageUrl,
              stretchedImageUrl,
              imageBlurhash,
              blurhash,
              stretchedBlurhash,
              squareBlurhash,
              type,
              sortOrder: index,
              podcastId,
              blogPostUrl,
              restricted,
              translations,
            };
            return mappedContent;
          }
        );
      let monthWeekData = {};

      if (audienceType == PopularAudienceType.CHILD) {
        if (popularContent) {
          monthWeekData = {
            month: popularContent.month,
            week: null,
            childWeek: popularContent.childWeek || null,
            type: popularContent.type,
          };
        } else {
          monthWeekData = {
            month: popularType === PopularType.MONTH ? data.monthOrWeek : null,
            week: null,
            childWeek:
              popularType === PopularType.WEEK ? data.monthOrWeek : null,
            type: popularType,
          };
        }
      } else {
        monthWeekData = {
          month: null,
          week: data.monthOrWeek,
          childWeek: null,
          type: popularType,
        };
      }

      const dataToSend = {
        ...monthWeekData,
        audienceType: audienceType,
        content: contentsData,
        contentMap: contentsData.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {} as { [key: string]: CommonContentV2 }),
      };

      if (popularContent) {
        await onUpdate(popularContent.id, { ...dataToSend });
      } else {
        const { id: createContentId } = await onCreate({
          ...dataToSend,
        });
        return navigate(`../${createContentId}`);
      }
      setError(null);
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
      console.log(err);
    }
  };

  const onRemove = async () => {
    if (popularContent) {
      await onDelete(popularContent.id);
      return navigate("./..");
    }
  };

  const isDirty =
    !!Object.keys(formState.dirtyFields).length ||
    !isEqualArrays(popularContent?.content ?? [], managedContent);
  const title =
    popularContent?.type === PopularType.MONTH
      ? getOrdinal(popularContent?.month) + " Month"
      : getOrdinal(
          popularContent && (popularContent.week || popularContent?.childWeek)
        ) + " Week";

  return (
    <>
      <FormEdit
        onSubmit={handleSubmit(onSubmit, formValidationError)}
        onRemove={onRemove}
        error={error}
        enableSave={isDirty && !isContentLimitExceeded}
        title={popularContent ? title : "New popular"}
        id={popularContent?.id}
        editType={popularContent?.id ? FormEditType.EDIT : FormEditType.ADD}
        loading={loading || loadingPopularMonths || loadingPopularWeeks}
      >
        {!popularContent && (
          <Space size={"large"}>
            <BJSelectFormItem
              size="large"
              control={control}
              error={!!errors.popularType}
              label={"Popular type"}
              message={errors.popularType?.message}
              required={true}
              optionsList={
                audienceType == PopularAudienceType.CHILD
                  ? [
                      { key: "month", value: "month", display: "Month" },
                      { key: "week", value: "week", display: "Week" },
                    ]
                  : [{ key: "week", value: "week", display: "Week" }]
              }
              fieldName={"popularType"}
            />
            <BJInputFormItem
              control={control}
              error={!!errors.monthOrWeek}
              label={popularType === PopularType.WEEK ? "Week" : "Month"}
              message={errors.monthOrWeek?.message}
              required={true}
              autoFocus
              fieldName={"monthOrWeek"}
            />
            <BJSelectFormItem
              size="large"
              control={control}
              error={!!errors.audienceType}
              label={"Audience type"}
              message={errors.audienceType?.message}
              required={true}
              defaultValue={audienceType}
              optionsList={[
                {
                  key: "child",
                  value: PopularAudienceType.CHILD,
                  display: "Child",
                },
                {
                  key: "pregnancy",
                  value: PopularAudienceType.PREGNANCY,
                  display: "Pregnancy",
                },
              ]}
              handleChange={(value: string) => {
                if (value == "pregnancy") {
                  setValue("popularType", "week");
                }
              }}
              fieldName={"audienceType"}
            />
          </Space>
        )}
        <ContentSelector
          displayButtons={true}
          maxContentLimit={
            popularType === PopularType.WEEK
              ? weekContentLimit
              : monthContentLimit
          }
          setManagedContent={setManagedContent}
          initialContent={popularContent?.content ?? []}
          contentListType={ContentListType.PopularContent}
        />
      </FormEdit>
    </>
  );
};
