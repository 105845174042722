import React from "react";
import { BJInputFormItem } from "../../components/theme";
import { Control, FieldErrors } from "react-hook-form";
import { FormValues } from "./types";

type Props = {
  errors: FieldErrors<FormValues>;
  control: Control<FormValues, object>;
  locale: Locale;
};

const OfferConfirmationPopup: React.FC<Props> = ({
  locale,
  errors,
  control,
}) => {
  return (
    <>
      <BJInputFormItem
        required
        label={`Heading (${locale?.label ?? ""})`}
        fieldName={`confirmationPopup.translations.${locale.key}.heading`}
        key={`confirmationPopup.translations.${locale.key}.heading`}
        control={control}
        error={!!errors?.confirmationPopup?.translations[locale.key]?.heading}
        message={
          errors?.confirmationPopup?.translations[locale.key]?.heading?.message
        }
      />
      <BJInputFormItem
        required
        label={`Description (${locale?.label ?? ""})`}
        fieldName={`confirmationPopup.translations.${locale.key}.description`}
        key={`confirmationPopup.translations.${locale.key}.description`}
        control={control}
        error={
          !!errors?.confirmationPopup?.translations[locale.key]?.description
        }
        message={
          errors?.confirmationPopup?.translations[locale.key]?.description
            ?.message
        }
      />
      <BJInputFormItem
        label={`Discount Percentage `}
        fieldName={`confirmationPopup.discountPercentage`}
        key={`confirmationPopup.discountPercentage`}
        control={control}
        error={!!errors?.confirmationPopup?.discountPercentage}
        message={errors?.confirmationPopup?.discountPercentage?.message}
      />
      <BJInputFormItem
        required
        label={`Success Button Text (${locale?.label ?? ""})`}
        fieldName={`confirmationPopup.translations.${locale.key}.successButtonText`}
        key={`confirmationPopup.translations.${locale.key}.successButtonText`}
        control={control}
        error={
          !!errors?.confirmationPopup?.translations[locale.key]
            ?.successButtonText
        }
        message={
          errors?.confirmationPopup?.translations[locale.key]?.successButtonText
            ?.message
        }
      />
      <BJInputFormItem
        label={`Success Button Link`}
        fieldName={`confirmationPopup.successButtonLink`}
        key={`confirmationPopup.successButtonLink`}
        control={control}
        error={!!errors?.confirmationPopup?.successButtonLink}
        message={errors?.confirmationPopup?.successButtonLink?.message}
      />
    </>
  );
};

export default OfferConfirmationPopup;
