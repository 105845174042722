import moment from "moment";

const DAYS_PER_CALENDAR_MONTH = 30;
const DAYS_PER_PREGNANCY_MONTH = 28;

export const DATE_FORMATS = {
  STANDARD: "YYYY-MM-DD",
  TIME_WITH_ZONE: "YYYY-MM-DDTHH:mm:ssZ",
  FRIENDLY_DATE: "DD MMM YYYY", // "01 jan 2024"
};

export const getCurrentDate = ({
  currentDate,
}: {
  currentDate: moment.Moment;
}) => {
  return currentDate.endOf("date");
};

export const pregnancyLengths: PregnancyLength[] = [
  { id: "280-1", label: "280 (39+6)", days: 280 },
  { id: "280-2", label: "280 (40+0)", days: 280 },
  { id: "281", label: "281 (40+1)", days: 281 },
  { id: "282", label: "282 (40+2)", days: 282 },
  { id: "283", label: "283 (40+3)", days: 283 },
];

const pregnancyLength = (pregnancyLengthId: PregnancyLengthId) => {
  const length = pregnancyLengths.find(pl => pl.id === pregnancyLengthId);
  return length || pregnancyLengths[0];
};

export const is40plus0 = (pregnancyLength: PregnancyLength) => {
  return pregnancyLength.id === "280-1";
};

export const getLastPeriodDate = (
  pregnancyDays: number,
  dueDate: moment.Moment
) => {
  return dueDate.subtract(pregnancyDays, "days").endOf("day");
};

export const getOvulationDate = (lastPeriodDate: moment.Moment) => {
  return lastPeriodDate.add(2, "weeks");
};

export const daysLeft = (
  dueDate: moment.Moment,
  currentDate: moment.Moment
) => {
  return dueDate.diff(currentDate, "days");
};

export const daysPassed = (
  pregnancyDays: number,
  dueDate: moment.Moment,
  currentDate: moment.Moment
) => pregnancyDays - daysLeft(dueDate, currentDate);

// Weeks passed
export const weeksPassed = (_daysPassed: number, _is40plus0: boolean) => {
  const _weeksPassed = Math.floor((_daysPassed - (_is40plus0 ? 1 : 0)) / 7);
  return Math.max(0, _weeksPassed);
};

// Days passed in the current week
export const weekDaysPassed = (
  _daysPassed: number,
  _weeksPassed: number,
  _is40plus0: boolean
) => {
  return Math.max(0, _daysPassed - _weeksPassed * 7 - (_is40plus0 ? 1 : 0));
};

export const percentageCompleted = (
  _daysPassed: number,
  _totalDays: number
) => {
  return Math.round((_daysPassed / _totalDays) * 100 * 10) / 10;
};

export const percentageLeft = (_daysPassed: number, _totalDays: number) => {
  return Math.round((1 - _daysPassed / _totalDays) * 100 * 10) / 10;
};

export const calenderMonth = (
  currentDate: moment.Moment,
  ovulationDate: moment.Moment
) => {
  const daysDiff = currentDate.diff(ovulationDate, "days");
  return Math.floor(daysDiff / DAYS_PER_CALENDAR_MONTH) + 1;
};

export const pregnancyMonth = (_weeksPassed: number) => {
  const month = (_weeksPassed * 7) / DAYS_PER_PREGNANCY_MONTH + 1;
  return Math.floor(month);
};

export const pregnancyWeek = (_daysPassed: number, _is40plus0 = false) => {
  const _weeksPassed = Math.floor((_daysPassed - (_is40plus0 ? 1 : 0)) / 7);

  if (_daysPassed === 0 && _is40plus0) {
    return 1;
  }

  return _weeksPassed + 1;
};

const trimester = (_weeksCompleted: number) => {
  if (_weeksCompleted <= 13) {
    return 1;
  } else if (_weeksCompleted <= 27) {
    return 2;
  } else {
    return 3;
  }
};

export const daysUntilNextWeek = (_daysPassed: number) => {
  let _weeksPassed = Math.floor((_daysPassed - 1) / 7);
  _weeksPassed = Math.max(0, _weeksPassed);

  const _weekDaysPassed = Math.max(0, _daysPassed - _weeksPassed * 7 - 1);
  return 7 - _weekDaysPassed;
};

export const calendarDates = (dueDate: moment.Moment, fromWeek: number) => {
  const numberOfWeeks = 40; // 40 weeks + 6 days
  const endDate = dueDate.clone().subtract(numberOfWeeks - fromWeek, "weeks");
  const startDate = endDate.clone().subtract(6, "days");

  return {
    startDate,
    endDate,
  };
};

export const calculatePregnancy = ({
  pregnancy,
  currentDate,
}: {
  pregnancy: Pregnancy;
  currentDate: moment.Moment;
}): PregnancyDetails => {
  const _dueDate = getCurrentDate({
    currentDate: moment.utc(
      pregnancy.dueDateString
        ? pregnancy.dueDateString
        : pregnancy.dueDate.toISOString(),
      DATE_FORMATS.STANDARD
    ),
  });
  const _currentDate = getCurrentDate({ currentDate });
  const _pregnancyLength = pregnancyLength(pregnancy.pregnancyLengthId);

  const _is40plus0 = is40plus0(_pregnancyLength);

  const lastPeriodDate = getLastPeriodDate(
    _pregnancyLength.days,
    _dueDate.clone()
  );
  const ovulationDate = getOvulationDate(lastPeriodDate);

  const _daysPassed = daysPassed(
    _pregnancyLength.days,
    _dueDate.clone(),
    _currentDate
  );
  const _weeksPassed = weeksPassed(_daysPassed, _is40plus0);
  const _weekDaysPassed = weekDaysPassed(_daysPassed, _weeksPassed, _is40plus0);

  const _percentageCompleted = percentageCompleted(
    _daysPassed,
    _pregnancyLength.days
  );
  const _percentageLeft = percentageLeft(_daysPassed, _pregnancyLength.days);

  return {
    daysLeft: daysLeft(_dueDate.clone(), _currentDate),
    percentageCompleted: _percentageCompleted,
    percentageLeft: _percentageLeft,
    calendarMonth: calenderMonth(_currentDate, ovulationDate),
    pregnancyMonth: pregnancyMonth(_weeksPassed),
    pregnancyWeek: pregnancyWeek(_daysPassed, _is40plus0),
    pregnancyLength: _pregnancyLength,
    trimester: trimester(_weeksPassed),
    daysPassed: _daysPassed,
    daysUntilNextWeek: daysUntilNextWeek(_daysPassed),
    weeksPassed: _weeksPassed,
    weekDaysPassed: _weekDaysPassed,
    totalPregnancyDays: _pregnancyLength.days,
    dueDate: _dueDate,
    calendarDates,
    dueDateString:
      pregnancy.dueDateString ?? _dueDate.format(DATE_FORMATS.STANDARD),
  };
};
