import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppLinks } from "../../context";
import { Sorter } from "../../components/theme/util/sorter";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import BJList from "../../components/theme/components/BJList";
import { changeorder, newRoute } from "../../routes";

type DataSourceType = {
  screenName: string;
  externalUrl: string;
};

export const AppLinksList = () => {
  const navigate = useNavigate();
  const { appLinks, loading } = useAppLinks();
  const [originalTable, setOriginalTable] = useState<DataSourceType[]>([]);

  useEffect(() => {
    const appLinksTableSource: DataSourceType[] = appLinks.reduce(
      (acc, appLink) => {
        Object.keys(appLink.appLinks).forEach(key => {
          acc.push({
            screenName: key,
            externalUrl: appLink.appLinks[key].externalUrl,
          });
        });
        return acc;
      },
      [] as DataSourceType[]
    );

    setOriginalTable(appLinksTableSource);
  }, [appLinks]);

  const handleNewAppLink = () => {
    navigate(newRoute);
  };

  const onClickRow = (record: DataSourceType) => {
    return {
      onClick: () => {
        navigate(record.screenName);
      },
    };
  };

  const columns: BJColumnType<DataSourceType>[] = [
    {
      title: "Screen Name",
      dataIndex: "screenName",
      key: "screenName",
      width: 1,
      ellipsis: true,
      onFilter: (value, record) => record.screenName.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "External Url",
      dataIndex: "externalUrl",
      key: "externalUrl",
      width: 1,
      ellipsis: true,
      onFilter: (value, record) => record.externalUrl.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  return (
    <BJList
      onChangeOrder={() => {
        navigate(changeorder);
      }}
      loading={loading}
      filterOnlyDisplayList
      addButtonCaption={"New App Link"}
      title={"App Links"}
      OriginalList={originalTable}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewAppLink}
      recordCountSuffix={"App Links"}
    />
  );
};
