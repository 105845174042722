import { DatePicker, Switch } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BJList from "../../components/theme/components/BJList";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";
import { useCompetitions, useCountry } from "../../context";
import { changeorder, newRoute } from "../../routes";
import { isCurrentTimeWithinRange } from "../../utils/timeUtils";

type datasourceType = Competition & { isVisible?: boolean };

export const CompetitionsListPage = () => {
  const navigate = useNavigate();
  const { competitions, loading } = useCompetitions();
  const [originalTable, setOriginalTable] = useState<datasourceType[]>([]);
  const { primaryLocale } = useCountry();

  useEffect(() => {
    const competitionsTableSource: datasourceType[] = competitions.map(
      competitions => ({
        ...competitions,
        isVisible: isCompetitionVisibleInMobile(competitions),
        key: competitions.id,
        title: competitions.translations?.[primaryLocale.key]?.title,
      })
    );
    setOriginalTable(competitionsTableSource);
  }, [competitions, primaryLocale.key]);

  const isCompetitionVisibleInMobile = (competitions: Competition) => {
    return (
      competitions.isActive &&
      isCurrentTimeWithinRange(competitions.startDate, competitions.endDate)
    );
  };

  const handleNewCompetition = () => {
    navigate(newRoute);
  };

  const onClickRow = (record: datasourceType) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const columns: BJColumnType<datasourceType>[] = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 1,
      ellipsis: true,
      onFilter: (value, record) =>
        record?.translations?.[primaryLocale.key]?.title.includes(
          value as string
        ),
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (text, record) => {
        return (
          <>
            <div>{record?.translations?.[primaryLocale.key]?.title}</div>
          </>
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startTime",
      width: 0.4,
      ellipsis: true,
      onFilter: (value, record) => record.startDate.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (text, record) => {
        return (
          <>
            {record.startDate ? (
              <DatePicker
                disabled={true}
                format={"YYYY-MM-DD HH:mm"}
                showTime
                value={record?.startDate}
              />
            ) : (
              <span style={{ justifySelf: "center" }}>Time not selected</span>
            )}
          </>
        );
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endTime",
      width: 0.4,
      ellipsis: true,
      onFilter: (value, record) => record.endDate.includes(value as string),
      defaultSortOrder: "descend",
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (text, record) => {
        return (
          <>
            {record.endDate ? (
              <DatePicker
                disabled={true}
                format={"YYYY-MM-DD HH:mm"}
                showTime
                value={record.endDate}
              />
            ) : (
              <span style={{ justifySelf: "center" }}>Time not selected</span>
            )}
          </>
        );
      },
    },
    {
      title: "Sub title",
      dataIndex: "subTitle",
      key: "deepLink",
      width: 0.8,
      ellipsis: true,
      onFilter: (value, record) =>
        record?.translations?.[primaryLocale.key]?.subTitle.includes(
          value as string
        ),
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (text, record) => {
        return (
          <>
            <div>{record?.translations?.[primaryLocale.key]?.subTitle}</div>
          </>
        );
      },
    },
    {
      title: "Visible in mobile",
      dataIndex: "isVisible",
      key: "isActive",
      width: 0.3,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (value: boolean) => (
        <Switch checked={value} size="small" disabled />
      ),
    },
    {
      title: "Answers",
      dataIndex: "answerCount",
      key: "answerCount",
      width: 0.3,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      align: "right",
    },
  ];

  return (
    <BJList
      loading={loading}
      onChangeOrder={() => {
        navigate(changeorder);
      }}
      filterOnlyDisplayList
      addButtonCaption={"New Competition"}
      title={"Competition"}
      OriginalList={originalTable}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewCompetition}
      recordCountSuffix={"Competitions"}
    />
  );
};
