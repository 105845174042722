import firebase from "../firebase";
const CustomChecklistOffers = "checklists";

const get = (countryCode: string) => {
  return firebase
    .firestore()
    .collection(CustomChecklistOffers)
    .doc(countryCode)
    .get()
    .then(
      snapshot => {
        const data = snapshot.data();
        const customChecklistOffers = data?.customChecklistOffers ?? [];
        return customChecklistOffers;
      },
      err => {
        console.log(err);
        return [];
      }
    );
};

const update = (countryCode: string, data: CustomChecklistOffer[]) =>
  firebase
    .firestore()
    .collection(CustomChecklistOffers)
    .doc(countryCode)
    .update({
      customChecklistOffers: data,
    });

export const CustomChecklistOffersService = {
  get,
  update,
};
