import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ContentMenuService } from "../../../../services";
import { FormEdit, FormEditType } from "../../../../components/FormEdit";
import {
  BJSortableList,
  SortContentType,
  SortProps,
} from "../../../../components/theme/molecules/BJSortableList";
import { useContentMenu } from "../../../../context/ContentMenuContext";
import { swapSortOrder } from "../../../../utils";
import { useCountry } from "../../../../context";

interface Props {
  onCancel?: () => void;
  onUpdateDone?: () => void;
  sortingFunction: (sections: ContentMenu[]) => Promise<void>;
  sections: ContentMenu[];
  loading: boolean;
}

export const SortContentSection = ({
  onUpdateDone,
  sections,
  loading,
  sortingFunction,
}: Props) => {
  const { handleSubmit } = useForm();
  const [isDirty, setIsDirty] = useState(false);
  const [contents, setContents] = useState<ContentMenu[]>([]);
  const { currentCountry, primaryLocale } = useCountry();

  useEffect(() => {
    setContents(
      sections
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((content, index) => ({ ...content, sortOrder: index }))
    );
  }, [sections]);

  const saveOrder = async () => {
    await sortingFunction(contents);
    setIsDirty(false);
    onUpdateDone();
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortProps) => {
    if (oldIndex === newIndex) {
      return;
    }
    const sortableContents = swapSortOrder(contents, oldIndex, newIndex);
    setContents(sortableContents);
    setIsDirty(true);
  };

  return (
    <FormEdit
      renderButtonsOnFooter
      enableSave={isDirty}
      editType={FormEditType.VIEW}
      loading={loading}
      onSubmit={handleSubmit(saveOrder)}
    >
      <BJSortableList
        items={contents
          .sort((a: ContentMenu, b: ContentMenu) => a.sortOrder - b.sortOrder)
          .map(
            x =>
              ({
                id: x.id,
                title: x.translations?.[primaryLocale.key]?.title,
                noImage: true,
              } as SortContentType)
          )}
        onSortEnd={onSortEnd}
      />
    </FormEdit>
  );
};
