import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { MarkdownEditor, FormEdit, FormEditType } from "../../components";
import { useContentDetails } from "../../context";
import { ContentService, UpdateContentList } from "../../services";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Form, Row, Select, Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import BJInput from "../../components/theme/atoms/BJInput";
import { formValidationError, getLanguages } from "../../utils";

type FormValues = {
  name: string;
  displayName: string;
  contentText: string;
};

export const EditContent = () => {
  const navigate = useNavigate();
  const languages = getLanguages();
  const {
    formState,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
    control,
  } = useForm<FormValues>();
  const { id } = useParams<string>();
  const {
    contentText,
    setContentText,
    loading,
    selectedLanguage,
    setSelectedLanguage,
    flagDeleteContent,
    editorContent,
  } = useContentDetails(id);

  useEffect(() => {
    if (loading) return;
    reset({
      displayName: selectedLanguage!.displayName,
      name: editorContent?.name,
      contentText: editorContent?.contentText,
    });
  }, [loading, editorContent, reset, selectedLanguage]);

  const onRemove = async () => {
    await flagDeleteContent();
    navigate("./..");
  };

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const alteredData: UpdateContentList = {
      name: data.name,
      contentText,
      version: "1.0.0",
      time: Date.now(),
    };
    await ContentService.update(id, selectedLanguage?.shortName, alteredData);
    navigate("../");
  };

  const isDirty =
    !!Object.keys(formState.dirtyFields).length ||
    editorContent?.contentText !== contentText;
  const { displayName } = watch();

  return (
    <FormEdit
      onRemove={onRemove}
      enableSave={isDirty}
      title={editorContent ? "Edit Content" : "New Content"}
      editType={editorContent?.name ? FormEditType.EDIT : FormEditType.ADD}
      loading={loading}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      recordIdentifier={displayName}
    >
      <Row gutter={{ md: 20 }}>
        <Col md={24} lg={12}>
          <Form.Item
            label="Language"
            validateStatus={errors.name && "error"}
            extra={
              <Typography.Paragraph type="danger">
                {errors.name?.message}
              </Typography.Paragraph>
            }
          >
            <Controller
              control={control}
              defaultValue={selectedLanguage?.displayName}
              name="displayName"
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={e => {
                    onChange(e);
                    const getLanugeageByDisplayName = getLanguages().find(
                      x => x.displayName === e
                    );
                    setSelectedLanguage(getLanugeageByDisplayName);
                  }}
                  value={value}
                  size="large"
                >
                  {languages.map(language => (
                    <Select.Option
                      value={language.displayName}
                      key={language.shortName}
                    >
                      {language.displayName}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Form.Item>
          <Form.Item
            label="Name"
            validateStatus={errors.name && "error"}
            extra={
              <Typography.Paragraph type="danger">
                {errors.name?.message}
              </Typography.Paragraph>
            }
          >
            <Controller
              control={control}
              name="name"
              render={({ field }) => <BJInput {...field} />}
            />
          </Form.Item>
          <div>
            <Paragraph>Content</Paragraph>
            <MarkdownEditor
              name="contentText"
              narrow
              initialValue={editorContent?.contentText ?? ""}
              onChange={value => {
                setContentText(value);
              }}
            />
          </div>
        </Col>
      </Row>
    </FormEdit>
  );
};
