import { useCountry, useTipsNew } from "../../context";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import BJList from "../../components/theme/components/BJList";
import { useEffect, useState } from "react";
import { TipsModal } from "./TipsModal";
import { Sorter } from "../../components/theme/util/sorter";
import { TipsType, capitalizeFirstLetter } from "../../utils";

interface datasourceType extends Tips {
  [titleWithLocale: string]: string | undefined | Tips[keyof Tips];
}

interface TipsList {
  type: TipsType;
}

export const TipsList = ({ type }: TipsList) => {
  const { pregnancyTips, loadingPregnancyTips, childTips, loadingChildTips } =
    useTipsNew();

  const [selectedTip, setSelectedTip] = useState<datasourceType>(null);
  const [showTipModal, setShowTipModal] = useState(false);
  const [originalTable, setOriginalTable] = useState<datasourceType[]>([]);

  const { currentCountry } = useCountry();

  useEffect(() => {
    if (type === TipsType.Child) {
      if (loadingChildTips) {
        return;
      }
    }
    if (type === TipsType.Pregnancy) {
      if (loadingPregnancyTips) {
        return;
      }
    }
    const getTipsByType = () =>
      (type === TipsType.Child ? childTips : pregnancyTips) || [];

    const tipsTableSource: datasourceType[] = getTipsByType().map(tip => ({
      ...tip,
      ...Object.entries(tip.translations).reduce(
        (acc, [locale, values]) => (
          (acc[`tip${capitalizeFirstLetter(locale)}`] = values.tip), acc
        ),
        {} as any
      ),
    }));

    setOriginalTable(tipsTableSource);
  }, [
    childTips,
    currentCountry?.abb,
    loadingChildTips,
    loadingPregnancyTips,
    pregnancyTips,
    type,
  ]);

  useEffect(() => {
    if (selectedTip) {
      setShowTipModal(true);
    }
  }, [selectedTip]);

  const onClickRow = (record: datasourceType) => {
    return {
      onClick: () => {
        setSelectedTip(record);
      },
    };
  };

  const getLoading = () =>
    type === TipsType.Child ? loadingChildTips : loadingPregnancyTips;

  const columns: BJColumnType<datasourceType>[] = [
    {
      title: "Day",
      dataIndex: "day",
      key: "day",
      width: 0.5,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    ...currentCountry?.locales?.map(locale => ({
      title: `Tip (${locale?.label})`,
      dataIndex: `tip${capitalizeFirstLetter(locale?.key)}`,
      key: `tip${capitalizeFirstLetter(locale?.key)}`,
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    })),
  ];

  const downloadData = () => {
    const csvData = [
      [
        "day",
        ...currentCountry?.locales?.map(
          locale => `Tip (${capitalizeFirstLetter(locale?.label)})`
        ),
      ],
    ];

    originalTable.forEach(t => {
      csvData.push([
        t?.day?.toString(),
        ...currentCountry?.locales?.map(
          locale => t.translations[locale?.key]?.tip
        ),
      ]);
    });

    return csvData;
  };

  return (
    <>
      <BJList
        key={`tip-${currentCountry?.abb}$}`}
        addButtonCaption={"New Tip"}
        loading={getLoading()}
        filterOnlyDisplayList
        title={"Daily Doula"}
        OriginalList={originalTable}
        recordCountSuffix={"Tips"}
        columns={columns}
        onClickRow={onClickRow}
        onclick={() => {
          setSelectedTip(null);
          setShowTipModal(true);
        }}
        downloadData={downloadData()}
      />
      {showTipModal && (
        <TipsModal
          show={showTipModal}
          onHide={() => {
            setShowTipModal(false);
            setSelectedTip(null);
          }}
          tip={selectedTip}
          tipType={type}
        />
      )}
    </>
  );
};
