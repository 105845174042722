import { SetStateAction, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCountry, usePolls } from "../../context";
import { Sorter } from "../../components/theme/util/sorter";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import BJList from "../../components/theme/components/BJList";
import { newRoute } from "../../routes";
import { Col, Row, Switch } from "antd";
import moment from "moment";
import { BJContainer } from "../../components/theme";
import { FilterButton } from "../../components";
import { capitalizeFirstLetter, downloadCSV } from "../../utils";

type DataSourceType = Poll;
const audiences: AudienceType[] = ["pregnancy", "child", "partner", "all"];

export const PollsList = () => {
  const navigate = useNavigate();
  const { polls, loading } = usePolls();
  const [originalTable, setOriginalTable] = useState<DataSourceType[]>([]);
  const [audienceFilter, setAudienceFilter] = useState<IFilter>({
    data: audiences.map(a => ({
      text: a,
      value: a,
    })),
    selected: new Set(),
  });
  const { primaryLocale, currentCountry } = useCountry();
  const [scheduledFilter, setScheduledFilter] = useState<IFilter>({
    data: [
      {
        text: "Scheduled",
        value: "scheduled",
      },
      {
        text: "Not scheduled",
        value: "notscheduled",
      },
    ],
    selected: new Set(),
  });

  useEffect(() => {
    const pollsTableSource: DataSourceType[] = polls.map(poll => ({
      ...poll,
      ...Object.entries(poll?.translations ?? {}).reduce(
        (acc, [locale, values]) => (
          (acc[`title${capitalizeFirstLetter(locale)}`] = values.title), acc
        ),
        {} as any
      ),
      ...Object.entries(poll?.translations ?? {}).reduce(
        (acc, [locale, values]) => (
          (acc[`body${capitalizeFirstLetter(locale)}`] = values.body), acc
        ),
        {} as any
      ),
      isVisible: isVisibleInMobile(poll),
      audience: audience(poll),
      scheduled: isScheduled(poll),
      key: poll.id,
    }));
    if (audienceFilter.selected.size > 0) {
      setOriginalTable(prev => {
        const dataToFilter =
          scheduledFilter.selected.size > 0 ? prev : pollsTableSource;
        return dataToFilter.filter(t =>
          t.extra.some(e => audienceFilter.selected.has(e.audience))
        );
      });
    }
    if (scheduledFilter.selected.size > 0) {
      setOriginalTable(prev => {
        const dataToFilter =
          audienceFilter.selected.size > 0 ? prev : pollsTableSource;
        if (
          scheduledFilter.selected.has("scheduled") &&
          scheduledFilter.selected.has("notscheduled")
        ) {
          return dataToFilter;
        }

        if (scheduledFilter.selected.has("scheduled")) {
          return dataToFilter.filter(t => {
            if (!t.endTime || !t.startTime) {
              return false;
            }
            return true;
          });
        }
        if (scheduledFilter.selected.has("notscheduled")) {
          return dataToFilter.filter(t => {
            if (t.endTime || t.startTime) {
              return false;
            }
            return true;
          });
        }
        return dataToFilter;
      });
      return;
    }
    if (audienceFilter.selected.size > 0 || scheduledFilter.selected.size > 0) {
      return;
    }
    setOriginalTable(pollsTableSource);
  }, [polls, audienceFilter, scheduledFilter]);

  const handleNewPolls = () => {
    navigate(newRoute);
  };

  const isVisibleInMobile = (poll: Poll) => {
    return poll.isActive;
  };

  const isScheduled = (poll: Poll) => {
    return poll.startTime && poll.endTime
      ? `${moment(poll.startTime).format("M/D/YY HH:mm")} to ${moment(
          poll.endTime
        ).format("M/D/YY HH:mm")}`
      : " ";
  };

  const audience = (poll: Poll) => {
    const isNewStructure = !!poll.segment;
    if (poll.extra.length > 1) {
      return `${poll.extra.map(e => e.audience).join(", ")}`;
    }
    if (poll.segment.type === "all") return "all";
    if (isNewStructure)
      return `${poll.segment.type} : ${poll.segment.range} - ${poll.segment.rangeUnit}`;
    if (poll.extra[0].range) {
      return `${poll.extra[0].audience} : ${poll.extra[0].range} - ${poll.extra[0].rangeUnit} `;
    } else {
      return ` ${poll.extra[0].audience}`;
    }
  };

  const onClickRow = (record: DataSourceType) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const onChangeFilter = (
    value: string,
    setStateFn: (value: SetStateAction<IFilter>) => void
  ) => {
    setStateFn(prev => {
      const selected = prev.selected;
      if (selected.has(value)) {
        selected.delete(value);
        return {
          data: prev.data,
          selected: selected,
        };
      } else {
        selected.add(value);
        return {
          data: prev.data,
          selected: selected,
        };
      }
    });
  };

  const onCheckAll = () => {
    setAudienceFilter(prev => {
      const allSelected = prev.data.every(d => prev.selected.has(d.value));
      if (allSelected) {
        return {
          ...prev,
          selected: new Set(),
        };
      } else {
        return {
          ...prev,
          selected: new Set(prev.data.map(d => d.value)),
        };
      }
    });
  };

  const columns: BJColumnType<DataSourceType>[] = [
    {
      title: `Title (${primaryLocale?.label})`,
      dataIndex: `title${capitalizeFirstLetter(primaryLocale?.key)}`,
      key: `title${capitalizeFirstLetter(primaryLocale?.key)}`,
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: `Body (${primaryLocale?.label})`,
      dataIndex: `body${capitalizeFirstLetter(primaryLocale?.key)}`,
      key: `body${capitalizeFirstLetter(primaryLocale?.key)}`,
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Visible in mobile",
      dataIndex: "isVisible",
      key: "isVisible",
      width: 0.5,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (value: boolean) => (
        <Switch checked={value} size="small" disabled />
      ),
    },
    {
      title: "Audience",
      dataIndex: "audience",
      key: "audience",
      width: 0.8,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Scheduled",
      dataIndex: "scheduled",
      key: "scheduled",
      width: 0.8,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  const renderListFilters = () => {
    return (
      <BJContainer>
        <Row wrap gutter={[20, 20]}>
          <Col>
            <FilterButton
              label="Audience:"
              filter={audienceFilter}
              onPressItem={item => onChangeFilter(item, setAudienceFilter)}
              onCheckAll={() => onCheckAll()}
            />
          </Col>
          <Col>
            <FilterButton
              label="Scheduled:"
              filter={scheduledFilter}
              onPressItem={item => onChangeFilter(item, setScheduledFilter)}
              onCheckAll={() => onCheckAll()}
              disableCheckAll
            />
          </Col>
        </Row>
      </BJContainer>
    );
  };

  return (
    <BJList
      filterOnlyDisplayList
      showSizeChanger
      loading={loading}
      addButtonCaption={"New poll"}
      title={"Polls"}
      OriginalList={originalTable}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewPolls}
      filters={renderListFilters()}
      recordCountSuffix={"Polls"}
      downloadData={downloadCSV({ topic: "polls", polls, primaryLocale })}
      topic="polls"
      isUpload
    />
  );
};
