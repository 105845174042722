import { Col, Row, Form, Select, Switch, Input, Typography, Space } from "antd";
import { useEffect, useMemo } from "react";
import BJInput from "../../../components/theme/atoms/BJInput";
import { appIcons } from "../../../data/app-icons";
import { ArticleCategoryService } from "../../../services";
import { Controller, useForm } from "react-hook-form";
import { FormModal } from "../../../components";
import { commonErrors } from "../../../language";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeepLinkType, formValidationError } from "../../../utils";
import { useCountry } from "../../../context";
import { BJSelectFormItem } from "../../../components/theme";
import { BJSelectFormItemLevel } from "../../../components/theme/molecules/formItems/BJFormSelectItemLevel";

const templates = [
  "Health",
  "Birth",
  "Exercise",
  "Baby",
  "BreastFeeding",
  "Pregnancy",
];

const options = [
  { value: 0, key: "0", display: "0" },
  { value: 1, key: "1", display: "1" },
  { value: 2, key: "2", display: "2" },
  { value: 3, key: "3", display: "3" },
  { value: 4, key: "4", display: "4" },
];

interface Props {
  show: boolean;
  onHide: () => void;
  category?: ArticleCategory | null;
}

type FormValues = {
  template: articleCategoryTemplateTypes;
  icon: string;
  translations: {
    [locale: string]: {
      title: string;
      description: string;
    };
  };
  show: boolean;
  restricted: boolean;
  level?: number;
};

export const ArticleCategoryModal = ({
  show: showModal,
  onHide,
  category,
}: Props) => {
  const { currentCountry } = useCountry();

  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup
            .string()
            .required(
              `Title (${String(item.key).toUpperCase()}): ${
                commonErrors.requiredError2
              }`
            ),
        });
        return acc;
      }, {} as any)
    ),
    icon: yup.string().required(`Icon: ${commonErrors.requiredError2}`),
    level: yup.number().when("restricted", {
      is: true,
      then: schema => schema.required(`Level ${commonErrors.requiredError2}`),
      otherwise: schema => schema.optional(),
    }),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      template: category?.template ?? "Birth",
      icon: category?.icon ?? "",
      translations: category?.translations,
      show: category?.show,
      restricted: category?.restricted,
      level: category?.level ?? 0,
    });
  }, [category, reset, showModal]);

  const articleCategoryService = useMemo(
    () => new ArticleCategoryService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  const onSubmit = async (data: FormValues) => {
    const {
      icon,
      show,
      template,
      translations: formTranslations,
      restricted,
      level,
    } = data;

    const translations: FormValues["translations"] = {};

    for (const [key, value] of Object.entries(formTranslations)) {
      translations[key] = {
        title: value.title?.trim(),
        description: value.description?.trim() ?? "",
      };
    }

    const payload = {
      icon: icon,
      show: show ?? false,
      template: template,
      sortOrder: 0,
      translations,
      restricted: restricted ?? false,
      level: level ?? 0,
    };
    if (category) {
      await articleCategoryService.update({ ...payload, id: category.id });
    } else {
      await articleCategoryService.create(payload);
    }
  };

  const onDelete = async () => {
    if (category !== null) {
      await articleCategoryService.delete(category!.id);
    } else {
      throw new Error("Category not found");
    }
  };

  const isDirty = !!Object.keys(formState.dirtyFields).length;

  const restricted = watch("restricted");

  return (
    <FormModal
      onHide={onHide}
      enableSave={isDirty}
      show={showModal}
      onDelete={onDelete}
      enableDelete={!!category}
      modalSubTitle={category ? `ID - ${category?.id}` : ""}
      modalTitle={category ? category.translations : "Add new category"}
      recordIdentifier={category?.id}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      deepLink={{
        type: DeepLinkType.ArticleCategories,
        id: category?.id,
        displayLinkText: false,
        countryCode: currentCountry?.abb,
      }}
      localeSupported
      errors={errors as any}
    >
      {locale => (
        <>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item label="Template">
                <Controller
                  control={control}
                  name="template"
                  render={({ field }) => (
                    <Select {...field}>
                      <Select.Option value="">-</Select.Option>
                      {templates.map(template => (
                        <Select.Option value={template} key={template}>
                          {template}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Icon"
                extra="Icon is shown on the content screen"
                required
                help={
                  errors.icon && (
                    <Typography.Text type="danger">
                      {errors.icon?.message}
                    </Typography.Text>
                  )
                }
              >
                <Controller
                  control={control}
                  name="icon"
                  render={({ field: { onChange, value } }) => (
                    <Select onChange={onChange} value={value}>
                      <Select.Option value="">-</Select.Option>
                      {appIcons
                        .sort((a, b) => a.localeCompare(b))
                        .map(icon => (
                          <Select.Option value={icon} key={icon}>
                            {icon}
                          </Select.Option>
                        ))}
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={`Title (${locale?.label})`}
            required
            extra={
              <Typography.Paragraph type="danger">
                {errors?.translations?.[locale?.key]?.title?.message}
              </Typography.Paragraph>
            }
          >
            <Controller
              control={control}
              name={`translations.${locale?.key}.title`}
              key={`translations.${locale?.key}.title`}
              render={({ field: { onChange, value } }) => (
                <BJInput onChange={onChange} value={value} />
              )}
            />
          </Form.Item>
          <Form.Item label={`Description (${locale?.label})`}>
            <Controller
              control={control}
              name={`translations.${locale?.key}.description`}
              key={`translations.${locale?.key}.description`}
              render={({ field: { onChange, value } }) => (
                <Input.TextArea rows={4} onChange={onChange} value={value} />
              )}
            />
          </Form.Item>
          <Row>
            <Space size="large">
              <Form.Item label="Visible">
                <Controller
                  control={control}
                  name="show"
                  render={({ field: { onChange, value } }) => (
                    <Switch checked={value} onChange={onChange} />
                  )}
                />
              </Form.Item>
              <Form.Item label="Restricted">
                <Controller
                  control={control}
                  name="restricted"
                  render={({ field: { onChange, value } }) => (
                    <Switch checked={value} onChange={onChange} />
                  )}
                />
              </Form.Item>
              {restricted && (
                <BJSelectFormItemLevel
                  control={control}
                  error={!!errors?.level}
                  message={errors?.level?.message}
                  fieldName="level"
                />
              )}
            </Space>
          </Row>
        </>
      )}
    </FormModal>
  );
};
