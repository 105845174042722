import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";

import { PopularService } from "../services";
import { PopularType } from "../utils";
import { useArticles, usePodcasts, useBlogPosts, useCountry } from "./";
import { useFAQ } from "./FAQContext";

type DocumentReference<T> = firebase.default.firestore.DocumentReference<T>;
type DocumentData = firebase.default.firestore.DocumentData;

type ContextState = {
  popularContents: Popular[];
  loading: boolean;
  error: Error | null;
  getById: (id: Popular["id"]) => Popular | null;
  onCreate: (
    data: Partial<Popular>
  ) => Promise<DocumentReference<DocumentData>>;
  onUpdate: (id: string, data: Partial<Popular>) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
};

const PopularContext = createContext<ContextState>({
  popularContents: [],
  loading: false,
  error: null,
  getById: () => null,
  onCreate: () => null,
  onUpdate: () => null,
  onDelete: () => null,
});

export const PopularProvider = ({ ...rest }) => {
  const [popularContents, setPopularContents] = useState<Popular[]>([]);
  const [loadingPopularContents, setLoadingPopularContents] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const popularService = useMemo(
    () =>
      currentCountry?.abb ? new PopularService(currentCountry?.abb) : null,
    [currentCountry?.abb]
  );

  useEffect(() => {
    if (!popularService) {
      return;
    }

    setLoadingPopularContents(true);

    const unSubscribePopularArticle = popularService?.subscribe(
      (_error, _popularContents) => {
        setPopularContents(_popularContents);
        setError(_error);
        setLoadingPopularContents(false);
      }
    );

    return () => {
      unSubscribePopularArticle();
    };
  }, [popularService]);

  const getById = useCallback(
    (id: Popular["id"]) => {
      if (loadingPopularContents) return null;

      const _popular = popularContents.find(a => a.id === id) || null;
      if (!_popular) return null;

      return _popular;
    },
    [loadingPopularContents, popularContents]
  );

  const loading = loadingPopularContents;

  const value = useMemo(
    () => ({
      popularContents,
      loading,
      error,
      getById,
      onCreate: popularService?.create,
      onUpdate: popularService?.update,
      onDelete: popularService?.delete,
    }),
    [
      popularContents,
      loading,
      error,
      getById,
      popularService?.create,
      popularService?.update,
      popularService?.delete,
    ]
  );

  return <PopularContext.Provider value={value} {...rest} />;
};

export const usePopularContents = () => {
  const context = useContext(PopularContext);

  if (context === undefined) {
    throw new Error(
      "usePopularArticleByMonths must be used within an PopularProvider"
    );
  }
  return context;
};

export const usePopularContentByMonths = () => {
  const { popularContents, loading } = usePopularContents();

  const _popularContentsByMonth = popularContents.filter(
    ({ type }) => type === PopularType.MONTH
  );
  return { popularContents: _popularContentsByMonth, loading };
};

export const usePopularContentByWeeks = (
  audience?: "child" | "pregnancy" | null
) => {
  const { popularContents, loading } = usePopularContents();

  // old week Popular content will belongs to Pregnancy audience and doesn't have audienceType field
  const enableEmptyAudience = !(audience === "child");

  const _popularContentsByWeek = popularContents.filter(
    ({ type, audienceType }) =>
      (audience
        ? audienceType
          ? audienceType == audience
          : enableEmptyAudience
        : true) && type === PopularType.WEEK
  );
  return { popularContents: _popularContentsByWeek, loading };
};

export const usePopularContent = (popularContentId: Popular["id"]) => {
  const [popularContent, setPopularContent] = useState<Popular | null>(null);
  const [loading, setLoading] = useState(true);

  const { getById, loading: loadingPopularArticle } = usePopularContents();
  const { getById: getArticleById, loading: loadingArticles } = useArticles();
  const { getById: getBlogPostById, loading: loadingBlogPosts } =
    useBlogPosts();
  const {
    getEpisodeById: getPodcastEpisodeById,
    loading: loadingPodcastEpisode,
  } = usePodcasts();
  const { getById: getFaqById, loading: loadingFaq } = useFAQ();
  useEffect(() => {
    if (
      loadingPopularArticle ||
      loadingArticles ||
      loadingBlogPosts ||
      loadingPodcastEpisode ||
      loadingFaq
    ) {
      return;
    }
    const data = getById(popularContentId);
    if (data) {
      setPopularContent({
        ...data,
        content: data.content,
      });
    }
    setLoading(false);
  }, [
    loadingPopularArticle,
    loadingArticles,
    loadingBlogPosts,
    loadingPodcastEpisode,
    loadingFaq,
    getById,
    getArticleById,
    getBlogPostById,
    getPodcastEpisodeById,
    popularContentId,
    getFaqById,
  ]);

  return { popularContent, loading };
};
