import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ContentTypeNames,
  DeepLinkType,
  formValidationError,
  isEqualArrays,
  valueOrNull,
} from "../../utils";
import { useQuestion, useFAQ, useCountry } from "../../context";
import { FormEdit, FormEditType } from "../../components";
import { commonErrors } from "../../language";
import { Row, Col, Typography, Form, Select, Switch } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";
import BJFeaturedList from "../../components/theme/molecules/BJFeaturedList";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";
import {
  BJInputFormItem,
  BJMdFormItem,
  BJSelectFormItemLevel,
  BJTagsFormItem,
} from "../../components/theme";

const { Title } = Typography;

type FormValues = {
  categoryId: string;
  sortOrder: number;
  promoted: boolean;
  translations?: {
    [locale: string]: {
      question: string;
      answer: string;
      videoUrl: string | null;
      tagWords: string[];
    };
  };
  level?: number;
};

const { requiredError, urlValidationError, numberError } = commonErrors;

export const QuestionPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const { question, loading } = useQuestion(id);
  const {
    questions,
    categories,
    createQuestion,
    updateFaqSortOrder,
    updateQuestion,
    deleteQuestion,
  } = useFAQ();
  const { currentCountry, primaryLocale } = useCountry();

  const [changeFeaturedListOrder, setchangeFeaturedListOrder] = useState(false);
  const [featuredFaq, setfeaturedFaq] = useState<FeaturedItem[]>([]);

  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          question: yup
            .string()
            .required(
              `Question (${String(item.key).toUpperCase()}): ${requiredError}`
            ),
          answer: yup
            .string()
            .required(
              `Answer (${String(item.key).toUpperCase()}): ${requiredError}`
            ),
          videoUrl: yup
            .string()
            .url(`Video url: ${urlValidationError}`)
            .nullable(),
        });
        return acc;
      }, {} as any)
    ),
    categoryId: yup.string().required(`Category: ${requiredError}`),
    sortOrder: yup.number().typeError(`Sort order: ${numberError}`),
  });

  const {
    formState,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (loading) {
      return;
    }
    if (question) {
      reset({
        categoryId: question?.categoryId,
        sortOrder: question.sortOrder,
        promoted: question.promoted,
        translations: question?.translations,
        level: question?.level,
      });
    } else {
      const questionsWithValidSortOrder = questions.filter(
        question =>
          !isNaN(question.sortOrder) && question.sortOrder !== -Infinity
      );
      const maxSortOrder = questionsWithValidSortOrder.length
        ? Math.max(
            ...questionsWithValidSortOrder.map(question => question.sortOrder)
          )
        : 0;
      reset({
        categoryId: "",
        promoted: false,
        sortOrder: maxSortOrder + 1 ?? 0,
        translations: {},
        level: 0,
      });
    }
  }, [question, loading, reset, questions]);

  useEffect(() => {
    if (questions && question?.featuredQuestions) {
      const tempData: FeaturedItem[] = [];
      for (const art of question?.featuredQuestions) {
        const foundQuestion = questions.find(_artc => _artc.id === art);
        if (foundQuestion) {
          tempData.push(mapToFeaturedQuestion(foundQuestion));
        }
      }
      setfeaturedFaq(tempData);
    }
  }, [question?.featuredQuestions, questions]);

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const translations: Question["translations"] = {};

    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        question: value.question,
        answer: value.answer,
        videoUrl: valueOrNull(value.videoUrl),
        tagWords: value.tagWords,
      };
    }
    if (question) {
      const category = categories.find(x => x.id === data.categoryId);
      await updateQuestion(question.id, {
        categoryId: data.categoryId,
        sortOrder: Number(data.sortOrder),
        tags: question.tags,
        promoted: data.promoted ?? false,
        featuredQuestions: featuredFaq.map(faq => faq.id),
        restricted: category?.restricted ?? false,
        translations,
        level: data.level,
      });
      await updateFaqSortOrder(question.id, Number(data.sortOrder));
    } else {
      const { id } = await createQuestion({
        categoryId: data.categoryId,
        sortOrder: Number(data.sortOrder),
        tags: [],
        promoted: data.promoted ?? false,
        featuredQuestions: featuredFaq.map(faq => faq.id),
        restricted: false,
        translations,
        level: data.level,
      });
      await updateFaqSortOrder(id, Number(data.sortOrder));
      return navigate(`../${id}`);
    }
  };

  const onRemove = async () => {
    if (question) {
      await deleteQuestion(question.id);
      return navigate("./..");
    }
  };

  const removeFeaturedItem = (id: string) => {
    setfeaturedFaq(prev => prev.filter(faq => faq.id !== id));
  };

  const mapToFeaturedQuestion = (question: Question): FeaturedItem => ({
    id: question.id,
    title: question.translations[primaryLocale?.key]?.question,
    description: question.translations[primaryLocale?.key]?.answer,
  });

  const isDirty =
    !!Object.keys(formState.dirtyFields).length ||
    !isEqualArrays(
      question?.featuredQuestions ?? [],
      featuredFaq.map(article => article.id)
    );

  const translations = watch("translations");

  return (
    <FormEdit
      onRemove={onRemove}
      hasValidationErrors={Object.keys(errors).length !== 0}
      enableSave={isDirty}
      title={
        question
          ? question?.translations[primaryLocale?.key]?.question
          : "New Question"
      }
      id={question?.id}
      editType={question?.id ? FormEditType.EDIT : FormEditType.ADD}
      loading={loading}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      recordIdentifier={question?.id}
      deepLink={{
        type: DeepLinkType.FAQ,
        id: question?.id,
        countryCode: currentCountry?.abb,
      }}
      localeSupported
      errors={errors as any}
    >
      {locale => (
        <Row gutter={{ md: 20 }}>
          <Col md={24} lg={12}>
            <BJInputFormItem
              label={`Question (${locale?.label ?? ""})`}
              fieldName={`translations.${locale.key}.question`}
              key={`translations.${locale.key}.question`}
              control={control}
              error={!!errors?.translations?.[locale.key]?.question}
              message={errors?.translations?.[locale.key]?.question?.message}
              required={true}
            />

            <BJMdFormItem
              label={`Answer (${locale?.label ?? ""})`}
              fieldName={`translations.${locale.key}.answer`}
              key={`translations.${locale.key}.answer`}
              error={!!errors?.translations?.[locale.key]?.answer}
              message={errors?.translations?.[locale.key]?.answer?.message}
              control={control}
              required={true}
            />

            <ItemWrapper>
              <Form.Item label="Promote" key="promoted">
                <Controller
                  control={control}
                  name="promoted"
                  render={({ field: { onChange, value } }) => (
                    <Switch onChange={onChange} checked={value} />
                  )}
                />
              </Form.Item>
            </ItemWrapper>

            <Row justify="space-between" align="middle">
              <Title level={5}>Add Featured offer</Title>
              <StyledSelect
                showSearch
                placeholder="Select question to feature"
                value="def"
                onSelect={(v, o) => {
                  setfeaturedFaq(prev => [
                    ...prev,
                    mapToFeaturedQuestion(
                      questions.find(question => question.id === o.key)!
                    ),
                  ]);
                }}
              >
                <Select.Option value="def" style={{ display: "none" }}>
                  Select question to feature
                </Select.Option>

                {questions
                  .filter(item => !featuredFaq.find(fq => fq.id === item.id))
                  .map(question => (
                    <Select.Option
                      value={
                        question.translations[primaryLocale?.key]?.question
                      }
                      key={question.id}
                    >
                      {question.translations[primaryLocale?.key]?.question}
                    </Select.Option>
                  ))}
              </StyledSelect>
            </Row>
            <Row>
              <BJButton
                buttonType={
                  changeFeaturedListOrder
                    ? ButtonTypes.Primary
                    : ButtonTypes.Secondary
                }
                onClick={() => setchangeFeaturedListOrder(prev => !prev)}
              >
                Change Order
              </BJButton>
            </Row>
            <BJFeaturedList
              changeFeaturedListOrder={changeFeaturedListOrder}
              featuredItems={featuredFaq}
              removeFeaturedItem={removeFeaturedItem}
              setfeaturedItems={setfeaturedFaq}
            />
          </Col>

          <Col md={24} lg={12}>
            <Form.Item
              label="Category"
              name="categoryId"
              required
              validateStatus={errors.categoryId && "error"}
              extra={
                <Typography.Paragraph type="danger">
                  {errors.categoryId?.message}
                </Typography.Paragraph>
              }
            >
              <Controller
                control={control}
                name="categoryId"
                render={({ field: { onChange, value } }) => (
                  <Select onChange={onChange} value={value} size="large">
                    {categories?.map(category => (
                      <Select.Option value={category.id} key={category.id}>
                        {category.translations?.[primaryLocale?.key]?.title}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              />
            </Form.Item>

            <BJSelectFormItemLevel
              control={control}
              fieldName={"level"}
              error={!!errors?.level?.message}
              message={errors?.level?.message}
              required={false}
            />

            <BJInputFormItem
              label={"Sort order"}
              fieldName={"sortOrder"}
              key={"sortOrder"}
              control={control}
              error={!!errors?.sortOrder}
              message={errors?.sortOrder?.message}
              required={false}
            />
            <BJTagsFormItem
              label={`Tag Words (${locale?.label ?? ""})`}
              key={`translations.${locale.key}.tagWords`}
              fieldName={`translations.${locale.key}.tagWords`}
              control={control}
              setValue={setValue}
              aiTagWordsPayload={{
                contentTitle: translations?.[locale.key]?.question,
                contentBody: translations?.[locale.key]?.answer,
                contentType: ContentTypeNames.faq,
                documentId: question?.id,
                language: locale.label,
              }}
              aiTranslateTagWordsPayload={{
                tagWords: translations?.[primaryLocale.key]?.tagWords,
                from: primaryLocale.label,
              }}
              existingTags={translations?.[locale.key]?.tagWords || []}
              locale={locale.key}
            />

            <BJInputFormItem
              label={`Video URL (${locale?.label ?? ""})`}
              fieldName={`translations.${locale.key}.videoUrl`}
              key={`translations.${locale.key}.videoUrl`}
              control={control}
              error={!!errors?.translations?.[locale.key]?.videoUrl}
              message={errors?.translations?.[locale.key]?.videoUrl?.message}
            />
          </Col>
        </Row>
      )}
    </FormEdit>
  );
};

const ItemWrapper = styled.div`
  padding-top: 1rem;
`;

const StyledSelect = styled(Select)`
  width: 12rem;
`;
