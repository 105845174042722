import { useState, useEffect } from "react";
import moment from "moment";

export const useToday = (): moment.Moment => {
  const [currentDate, setDate] = useState<moment.Moment>(moment());

  useEffect(() => {
    const updateDateIfChanged = (): void => {
      const newDate = moment();
      if (!newDate.isSame(currentDate, "date")) {
        setDate(newDate);
      }
    };
    return () => updateDateIfChanged();
  }, [currentDate]);

  return currentDate;
};
