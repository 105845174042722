import { useEffect, useMemo, useState } from "react";
import { ToolsMenuSectionService } from "../services/ToolsMenuItemService";
import { useCountry } from "../context";
import _ from "lodash";

export const useToolsSections = () => {
  const { currentCountry } = useCountry();
  const [menuSections, setMenuSections] = useState<ContentMenu[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const menuSectionService = useMemo(
    () =>
      currentCountry?.abb
        ? new ToolsMenuSectionService(currentCountry.abb)
        : null,
    [currentCountry?.abb]
  );

  useEffect(() => {
    setLoading(true);
    const unsubMenuSection = menuSectionService?.subscribe(
      (_error, _menuItems) => {
        const menuItemsOrdered = _.orderBy(_menuItems, ["sortOrder"], ["asc"]);
        setMenuSections(menuItemsOrdered);
        setError(_error);
        setLoading(false);
      }
    );

    return () => {
      unsubMenuSection?.();
    };
  }, [menuSectionService]);

  const updateMenuSection = (
    id: ToolsBanner["id"],
    menuSection: Partial<ContentMenu>
  ) => menuSectionService?.update(id, menuSection);

  const createMenuSection = (menuSection: Partial<ContentMenu>) =>
    menuSectionService?.create(menuSection);

  const deleteMenuSection = (id: ContentMenu["id"]) =>
    menuSectionService?.delete(id);

  const updateMenuItems = async (id: string, data: ContentMenu) =>
    menuSectionService?.updateMenuItems(id, data);

  const updateSortOrder = async (sortedMenuSections: ContentMenu[]) =>
    menuSectionService.updateSortOrder(sortedMenuSections);

  return {
    menuSections,
    loading,
    error,
    createMenuSection,
    updateMenuSection,
    deleteMenuSection,
    updateSortOrder,
    updateMenuItems,
  };
};
