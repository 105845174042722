import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCountry, usePodcasts } from "../../context";
import { newRoute } from "../../routes";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";
import BJList from "../../components/theme/components/BJList";
import { DeepLinkForList } from "../../components/theme";
import { DeepLinkType, capitalizeFirstLetter } from "../../utils";

type datasourceType = { deepLink?: DeepLinkProps } & FullPodcast;
export const PodcastListPage = () => {
  const navigate = useNavigate();
  const { podcasts, loading } = usePodcasts();
  const [originalTable, setOriginalTable] = useState<datasourceType[]>([]);

  const { currentCountry, primaryLocale } = useCountry();

  useEffect(() => {
    const podCastTableSource: datasourceType[] = podcasts.map(podcast => ({
      ...podcast,
      title: podcast?.translations[primaryLocale?.key]?.title,
      deepLink: {
        type: DeepLinkType.Podcast,
        id: podcast?.id,
        countryCode: currentCountry?.abb,
      },
      key: podcast.id,
    }));
    setOriginalTable(podCastTableSource);
  }, [currentCountry?.abb, podcasts, primaryLocale]);

  const onClickRow = (record: datasourceType) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const handleNewRecord = () => {
    navigate(newRoute);
  };

  const columns: BJColumnType<datasourceType>[] = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 2,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: `Title (${capitalizeFirstLetter(primaryLocale?.key ?? "")})`,
      dataIndex: "title",
      key: "title",
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    DeepLinkForList(),
  ];

  return (
    <BJList
      loading={loading}
      filterOnlyDisplayList
      addButtonCaption={"New podcast"}
      title={"Podcasts"}
      OriginalList={originalTable}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewRecord}
    />
  );
};
