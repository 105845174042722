export const getZodiacSign = (date: Date | undefined) => {
  if (!date) {
    return;
  }

  const month = date.getMonth() + 1;
  const day = date.getDate();

  const zodiacSigns: string[] = [
    "Capricorn",
    "Aquarius",
    "Pisces",
    "Aries",
    "Taurus",
    "Gemini",
    "Cancer",
    "Leo",
    "Virgo",
    "Libra",
    "Scorpio",
    "Sagittarius",
  ];

  const dayLimits: number[] = [19, 18, 20, 19, 20, 20, 22, 22, 22, 22, 21, 21];
  return day <= dayLimits[month - 1]
    ? zodiacSigns[month - 1]
    : zodiacSigns[month % 12];
};
