import { useNavigate } from "react-router-dom";
import { useCountry, useWeeklyInformation } from "../../context";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";
import BJList from "../../components/theme/components/BJList";
import { newRoute } from "../../routes";
import { isEmpty, toString } from "lodash";

type datasourceType = WeeklyInformation;

export const WeeklyInformationListPage = () => {
  const { primaryLocale } = useCountry();
  const { weeklyInformations, loading } = useWeeklyInformation();
  const navigate = useNavigate();

  const onClickRow = (record: datasourceType) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const handleNewMileStone = () => {
    navigate(newRoute);
  };

  const columns: BJColumnType<datasourceType>[] = [
    {
      title: "Week",
      dataIndex: "week",
      key: "week",
      width: 2,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  const downloadData = () => {
    const csvData = [["Id", "Week", "Child", "Mother", "Partner"]];
    if (!isEmpty(weeklyInformations)) {
      weeklyInformations.forEach(t => {
        const childContent = t.child?.translations
          ? t.child?.translations[primaryLocale.key]?.content
          : "";
        const motherContent = t.mother?.translations
          ? t.mother?.translations[primaryLocale.key]?.content
          : "";
        const partnerContent = t.partner?.translations
          ? t.partner?.translations[primaryLocale.key]?.content
          : "";

        csvData.push([
          toString(t.id),
          toString(t.week),
          childContent?.replace(/"/g, '""'),
          motherContent?.replace(/"/g, '""'),
          partnerContent?.replace(/"/g, '""'),
        ]);
      });
    }
    return csvData;
  };

  return (
    <BJList
      loading={loading}
      filterOnlyDisplayList
      addButtonCaption={"Weekly"}
      title={"Weekly"}
      OriginalList={weeklyInformations.map(x => ({ ...x, key: x.id }))}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewMileStone}
      downloadData={downloadData()}
    />
  );
};
