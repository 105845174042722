import { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Sorter } from "../../components/theme/util/sorter";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import BJList from "../../components/theme/components/BJList";
import { newRoute } from "../../routes";
import { DatePicker } from "antd";
import { useCountry, useMiniJourneys } from "../../context";

type DataSourceType = MiniJourney;

export const MiniJourneyListPage = () => {
  const navigate = useNavigate();
  const { miniJourneys, loading } = useMiniJourneys();
  const { search } = useLocation();
  const [originalTable, setOriginalTable] = useState<DataSourceType[]>([]);
  const { primaryLocale } = useCountry();

  if (search) {
    // this is needed in case of updating only mini journey polls
    const params = new URLSearchParams(search);
    const update = params.get("update");
    if (update && window) {
      window.location = window.location.pathname as
        | Location
        | (string & Location);
    }
  }

  useEffect(() => {
    const miniJourneysTableSource: DataSourceType[] = miniJourneys.map(
      miniJourney => ({
        ...miniJourney,
        headerHeadline:
          miniJourney?.translations?.[primaryLocale.key]?.headerHeadline,
        introText: miniJourney?.translations?.[primaryLocale.key]?.introText,
        sponsorText:
          miniJourney?.translations?.[primaryLocale.key]?.sponsorText,
        key: miniJourney.id,
      })
    );

    setOriginalTable(miniJourneysTableSource);
  }, [miniJourneys, primaryLocale.key]);

  const handleNewPolls = () => {
    navigate(newRoute);
  };

  const onClickRow = (record: DataSourceType) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const columns: BJColumnType<DataSourceType>[] = [
    {
      title: "Internal Name",
      dataIndex: "internalName",
      key: "internalName",
      width: 1,
      ellipsis: true,
      onFilter: (value, record) =>
        record.internalName.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Header Headline",
      dataIndex: "headerHeadline",
      key: "headerHeadline",
      width: 1,
      ellipsis: true,
      onFilter: (value, record) =>
        record.translations?.[primaryLocale.key]?.headerHeadline.includes(
          value as string
        ),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      width: 0.5,
      ellipsis: true,
      onFilter: (value, record) => record.startTime.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (text, record) => {
        return (
          <>
            {record.startTime ? (
              <DatePicker
                disabled={true}
                format={"YYYY-MM-DD HH:mm"}
                showTime
                value={record.startTime}
              />
            ) : (
              <span style={{ justifySelf: "center" }}>Time not selected</span>
            )}
          </>
        );
      },
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      width: 0.5,
      ellipsis: true,
      onFilter: (value, record) => record.endTime.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (text, record) => {
        return (
          <>
            {record.endTime ? (
              <DatePicker
                disabled={true}
                format={"YYYY-MM-DD HH:mm"}
                showTime
                value={record.endTime}
              />
            ) : (
              <span style={{ justifySelf: "center" }}>Time not selected</span>
            )}
          </>
        );
      },
    },
  ];

  return (
    <BJList
      filterOnlyDisplayList
      showSizeChanger
      loading={loading}
      addButtonCaption={"New mini journey"}
      title={"Mini journeys"}
      OriginalList={originalTable}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewPolls}
      recordCountSuffix={"Mini journeys"}
    />
  );
};
