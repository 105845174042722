import { useEffect, useState } from "react";
import { usePostList } from "../../../hooks";
import { Sorter } from "../../../components/theme/util/sorter";
import { BJColumnType } from "../../../components/theme/molecules/BJTable";
import { BJSelectListModal } from "../../../components/theme/components/BJSelectListModal";
import { useFilterContent } from "../../../hooks/contentCollections/useFilterContent";
import {
  CommonContentType,
  capitalizeFirstLetter,
  getFormattedErrorMessage,
} from "../../../utils";
import { useCountry } from "../../../context";
interface Props {
  show: boolean;
  onHide: () => void;
  maxNoOfContentLimit: number;
  selectedContent: CommonContent[];
  onAdd: (content: CommonContent[]) => void;
}

export interface dataSourceType extends Post {
  Influencer: string | undefined;
}

export const AddBlogPostModal = ({
  show: showModal,
  onHide,
  maxNoOfContentLimit,
  selectedContent,
  onAdd,
}: Props) => {
  const { posts } = usePostList();
  const { currentCountry } = useCountry();

  const [dataSource, setDataSource] = useState<dataSourceType[]>([]);
  const {
    error,
    updatedContent,
    setError,
    selectedContentCollections,
    setSelectedContentCollections,
  } = useFilterContent<dataSourceType>(
    selectedContent,
    maxNoOfContentLimit,
    CommonContentType.BLOG,
    dataSource
  );

  useEffect(() => {
    const postTableSource: dataSourceType[] = posts?.map(post => ({
      ...post,
      key: post.id,
      Influencer: post?.influencer?.name,
      ...Object.entries(post.translations ?? {}).reduce(
        (acc, [locale, values]) => (
          (acc[`title${capitalizeFirstLetter(locale)}`] = values.title), acc
        ),
        {} as any
      ),
    }));
    setDataSource(postTableSource); //titles won't work for other posts yet
  }, [posts]);

  const onSubmit = async () => {
    try {
      onAdd(updatedContent);
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
    }
  };

  const columns: BJColumnType<dataSourceType>[] = [
    ...currentCountry?.locales
      ?.filter(locale => locale.primary)
      ?.map(locale => ({
        title: `Title (${locale?.label})`,
        dataIndex: `title${capitalizeFirstLetter(locale?.key)}`,
        key: `title${capitalizeFirstLetter(locale?.key)}`,
        width: 2,
        ellipsis: true,
        sorter: {
          compare: Sorter.DEFAULT,
        },
      })),
    {
      title: "Influencer",
      dataIndex: "Influencer",
      key: "Influencer",
      width: 1,
      ellipsis: true,

      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Week",
      dataIndex: "week",
      key: "week",
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  return (
    <BJSelectListModal
      recordCountSuffix="Blog posts"
      modalTitle={"Add Blog posts"}
      error={error!}
      show={showModal}
      onHide={onHide}
      disable={updatedContent.length > maxNoOfContentLimit}
      maxNoOfContentLimit={maxNoOfContentLimit}
      columns={columns}
      selectedRecords={selectedContentCollections}
      updateSelectedRecords={setSelectedContentCollections}
      dataSource={dataSource}
      onSubmit={onSubmit}
    ></BJSelectListModal>
  );
};
