import styled from "styled-components";

export const BJContainer = styled.div`
  width: 100%;
  padding-right: 0.938rem;
  padding-left: 0.938rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 1rem;
`;
