import { useEffect, useState } from "react";
import BJList from "../../components/theme/components/BJList";
import { BJColumnsType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";
import { useBlogCategories, useCountry } from "../../context";
import { CategoryModal } from "./CategoryModal";
import { Switch } from "antd";
import { DeepLinkType, capitalizeFirstLetter } from "../../utils";
export const BlogCategoryListPage = () => {
  const { blogCategories } = useBlogCategories();
  const [originalTable, setOriginalTable] = useState<BlogCategory[]>([]);
  const [showNewCategoryModal, setShowCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<BlogCategory | null>(
    null
  );
  const { currentCountry } = useCountry();

  const { locales } = currentCountry || {};

  useEffect(() => {
    const categoryTableSource: BlogCategory[] = blogCategories.map(
      category => ({
        ...category,
        ...Object.entries(category.translations).reduce(
          (acc, [locale, translation]) => (
            (acc[`name${capitalizeFirstLetter(locale)}`] = translation), acc
          ),
          {} as any
        ),
        key: category?.id,
        deepLink: { type: DeepLinkType.ArticleCategories, id: category?.id },
      })
    );
    setOriginalTable(categoryTableSource);
  }, [blogCategories]);

  useEffect(() => {
    if (selectedCategory) {
      setShowCategoryModal(true);
    }
  }, [selectedCategory]);

  const columns: BJColumnsType<BlogCategory> = [
    ...locales?.map(locale => ({
      title: `Name (${locale?.label})`,
      dataIndex: `name${capitalizeFirstLetter(locale?.key)}`,
      key: `name${capitalizeFirstLetter(locale?.key)}`,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    })),
    {
      key: "promoted",
      dataIndex: "promoted",
      title: "Promoted",
      align: "center",
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (value: boolean) => (
        <Switch checked={value} size="small" disabled />
      ),
    },
  ];

  const onClickRow = (record: BlogCategory) => {
    return {
      onClick: () => {
        setSelectedCategory(record);
      },
    };
  };

  return (
    <>
      <CategoryModal
        category={selectedCategory}
        show={showNewCategoryModal}
        onHide={() => {
          setShowCategoryModal(false);
          setSelectedCategory(null);
        }}
      />
      <BJList
        addButtonCaption={"New category"}
        title={"Blog Post Categories"}
        OriginalList={originalTable}
        columns={columns}
        recordCountSuffix={"Categories"}
        onClickRow={onClickRow}
        onclick={() => {
          setShowCategoryModal(true);
        }}
      />
    </>
  );
};
