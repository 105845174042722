import { useState } from "react";
import { FaGripLines } from "react-icons/fa";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { List, Space } from "antd";
import styled from "styled-components";
import { useCountry } from "../../context";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";

interface Props {
  initialItems: ChecklistOffer[];
  onCancel: () => void;
  onSave: (updatedArray: ChecklistOffer[]) => void;
}

export const OfferSortableList = ({
  initialItems,
  onCancel,
  onSave: onUpdateSortOrder,
}: Props) => {
  const { primaryLocale } = useCountry();
  const [isDirty, setIsDirty] = useState(false);
  const [items, setItems] = useState<ChecklistOffer[]>(
    initialItems
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((t, i) => ({ ...t, sortOrder: i } as ChecklistOffer))
  );

  const onSave = async () => {
    await onUpdateSortOrder(
      items.map(t => ({ id: t.id, sortOrder: t.sortOrder }))
    );
    setIsDirty(false);
    onCancel();
  };

  const OfferSortableItem = SortableElement(
    ({ value }: { value: ChecklistOffer & { offer: OfferV2 } }) => (
      <StyledListItem>
        <Space size={30}>
          <FaGripLines />
          <span>{value.offer.translations?.[primaryLocale?.key]?.title}</span>
        </Space>
      </StyledListItem>
    )
  );

  const OfferSortableList = SortableContainer(
    ({ items }: { items: Array<ChecklistOffer & { offer: OfferV2 }> }) => (
      <List bordered>
        {items.map((item, index) => (
          <OfferSortableItem key={item.id} index={index} value={item} />
        ))}
      </List>
    )
  );

  return (
    <>
      <Space>
        <BJButton
          buttonType={ButtonTypes.Delete}
          size="small"
          onClick={onCancel}
        >
          Cancel
        </BJButton>

        <BJButton
          disabled={!isDirty}
          size="small"
          buttonType={ButtonTypes.Save}
          onClick={onSave}
        >
          Save
        </BJButton>
      </Space>
      <Spacer size={20} />
      <OfferSortableList
        items={
          items.sort((a: any, b: any) => a.sortOrder - b.sortOrder) as Array<
            ChecklistOffer & { offer: OfferV2 }
          >
        }
        onSortEnd={({ oldIndex, newIndex }) => {
          if (oldIndex === newIndex) {
            return;
          }
          const _items = [...items];
          const sortOrder = _items[oldIndex].sortOrder;
          _items[oldIndex].sortOrder = _items[newIndex].sortOrder;
          _items[newIndex].sortOrder = sortOrder;
          setIsDirty(true);
          setItems(_items);
        }}
      />
    </>
  );
};

const StyledListItem = styled(List.Item)`
  cursor: move;
`;

const Spacer = styled.div<{ size: number }>`
  height: ${({ size = 1 }) => size}px;
`;
