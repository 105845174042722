import { Typography } from "antd";
import { useEffect, useMemo } from "react";
import styled from "styled-components";
import { useContentMenu } from "../../../context/ContentMenuContext";
import { ContentItemPage } from "./ContentItemPage";
import { ContentSectionPage } from "./ContentSectionPage";
import { ReactComponent as ContentEditButton } from "./../../../assets/icons/contentEdit.svg";
import { ContentItem } from "./Components/ContentItem";
import { SortContentSection } from "./Components/SortContentSection";
import { EditContentContainer } from "../EditContentContainer";
import { MobileDisplayContainer } from "./Components/MobileDisplayContainer";
import { AnimatePresence } from "framer-motion";
import {
  ContentActions,
  useManageContent,
} from "../../../hooks/contentCollections/useManageContent";
import { getMaxSortOrder, swapSortOrder } from "../../../utils";
import {
  BaseSortType,
  BJSortableList,
  SortProps,
} from "../../../components/theme";
import { ContentMenuService } from "../../../services";
import { GlobalWordSpacing } from "../../../components/GlobalStyle";
import { useCountry } from "../../../context";
interface Props {
  editMode?: boolean;
  icon?: string;
}

export const ContentMenuPage = () => {
  const { contentMenu, loading } = useContentMenu();
  const { state, dispatch, editSectionTitle } = useManageContent();

  const lastSortOrderInSection = useMemo(() => {
    if (loading) return -1;
    const max = getMaxSortOrder(contentMenu);
    return max;
  }, [contentMenu, loading]);

  const { currentCountry, primaryLocale } = useCountry();
  const contentMenuService = useMemo(
    () => new ContentMenuService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    if (loading) return;
    if (contentMenu?.length) {
      dispatch({
        type: ContentActions.SETCONTENTLIST,
        payload: contentMenu,
      });
    }
  }, [contentMenu, dispatch, loading]);

  const onupdateDone = () => {
    dispatch({
      type: ContentActions.CONTENTUPDATED,
      payload: null,
    });
  };

  const renderContentItem = ({
    contentSection,
  }: {
    contentSection: ContentMenu;
  }) => {
    return (
      <>
        <GlobalWordSpacing />
        <Content>
          {contentSection?.menuItems
            ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
            .map(item => {
              return (
                <ContentItem
                  onClick={() => {
                    onupdateDone();
                    dispatch({
                      type: ContentActions.EDITITEM,
                      payload: {
                        contentSection: contentSection,
                        editItem: item,
                      },
                    });
                  }}
                  key={`${
                    contentSection?.translations?.[primaryLocale.key]?.title
                  } ${item?.translations?.[primaryLocale.key]?.title} ${
                    item?.sortOrder
                  }`}
                  description={item.translations?.[primaryLocale.key]?.title}
                  icon={item.icon}
                />
              );
            })}
          {renderNewContentItem({ contentSection })}
        </Content>
      </>
    );
  };

  const onSortEnd = async ({ oldIndex, newIndex }: SortProps) => {
    if (oldIndex === newIndex) {
      return;
    }
    const sortableContents = swapSortOrder(
      state.contentMenu,
      oldIndex,
      newIndex
    );
    await contentMenuService.updateSortOrder(sortableContents);
  };

  const renderSectionTitle = (contentSection: ContentMenu) => {
    return contentSection?.translations?.[primaryLocale.key]?.title;
  };

  const renderItem = ({ contentSection }: { contentSection: ContentMenu }) => {
    return (
      <div key={contentSection.id}>
        <SectionDiv>
          <Typography.Title level={4}>
            {renderSectionTitle(contentSection)}
          </Typography.Title>
          <StyledContentEditButton
            onClick={() => {
              onupdateDone();
              dispatch({
                type: ContentActions.EDITSECTION,
                payload: { editSection: contentSection },
              });
            }}
          />
        </SectionDiv>
        {renderContentItem({ contentSection })}
      </div>
    );
  };

  const renderNewContentItem = ({
    contentSection,
  }: {
    contentSection: ContentMenu;
  }) => {
    return (
      <ContentItem
        isNewItem
        onClick={() => {
          onupdateDone();
          dispatch({
            type: ContentActions.ADDITEM,
            payload: {
              editSection: contentSection,
            },
          });
        }}
        key={"new item"}
        description={"Add item"}
        icon={"default icon"}
      />
    );
  };

  return (
    <ContentMenuContainer>
      <Typography.Title level={2}>{"Content menu"}</Typography.Title>
      <ContentContainer
        editMode={
          state.addSection ||
          !!state.editSection ||
          !!state.addItem ||
          !!state.editItem ||
          !!state.sortSection
        }
      >
        <MobileDisplayContainer
          onSort={() => {
            onupdateDone();
            dispatch({
              type: ContentActions.SORTCONTENT,
              payload: !state?.sortSection,
            });
          }}
          onAddSection={() => {
            onupdateDone();
            dispatch({
              type: ContentActions.ADDSECTION,
              payload: true,
            });
          }}
          loading={loading}
          body={
            <>
              {state?.contentMenu?.length && (
                <BJSortableList
                  disableDrag
                  onSortEnd={onSortEnd}
                  items={state?.contentMenu?.map(x => ({
                    ...x,
                    id: x.id,
                    title: x.translations?.[primaryLocale.key]?.title,
                    noImage: true,
                  }))}
                  render={(contentSection: BaseSortType) => {
                    const renderMenu = state?.contentMenu.find(
                      x => x.id === contentSection.id
                    );
                    return renderItem({ contentSection: renderMenu });
                  }}
                />
              )}
            </>
          }
        />
        <AnimatePresence>
          {(state.addSection ||
            state.editSection ||
            state.addItem ||
            state.editItem ||
            state.sortSection) && (
            <EditContentContainer
              onClose={onupdateDone}
              editSectionTitle={editSectionTitle}
              render={
                <>
                  {state?.sortSection && (
                    <SortContentSection
                      onUpdateDone={onupdateDone}
                      loading={loading}
                      sections={contentMenu}
                      sortingFunction={contentMenuService.updateSortOrder}
                    />
                  )}
                  {state.addSection || state.editSection ? (
                    <ContentSectionPage
                      maxSortOrder={lastSortOrderInSection}
                      onUpdateDone={onupdateDone}
                      contentMenu={state.editSection}
                      createSection={contentMenuService.create}
                      updateSection={contentMenuService.update}
                      deleteSection={contentMenuService.delete}
                    />
                  ) : state.editItem || state.addItem ? (
                    <ContentItemPage
                      updateMenuItems={
                        contentMenuService.updateContentMenuItems
                      }
                      onUpdateDone={onupdateDone}
                      contentItem={
                        state.editItem ? state?.editItem?.editItem : null
                      }
                      contentMenu={
                        state.editItem
                          ? state?.editItem?.editSection
                          : state.addItem?.editSection
                      }
                      contentType="content"
                    />
                  ) : (
                    <></>
                  )}
                </>
              }
            />
          )}
        </AnimatePresence>
      </ContentContainer>
    </ContentMenuContainer>
  );
};

const SectionDiv = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 1.25rem 0.625rem 0.313rem 0.625rem;
  cursor: pointer;
`;

const ContentContainer = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.editMode ? "start" : "center")};
`;

const ContentMenuContainer = styled.div`
  position: relative;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 6rem);
  grid-gap: 1rem;
  justify-content: center;
`;

const StyledContentEditButton = styled(ContentEditButton)`
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.5);
  }
`;
