import axios from "axios";
import firebase from "../firebase";
import { scrapped } from "../hooks/useScraper";
import { getYoutubeIdfromUrl } from "../utils/getUrlInformation";
import { siteConsts } from "./../utils/consts";
import { CountryCodeService } from "./CountryCodeService";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";

export class BlogPostService extends CountryCodeService {
  readonly blogPostFileStoragePath: string = "blogPost";

  constructor(locale: string) {
    super(locale);
  }

  subscribe = (callback: (err: Error | null, blogPosts: BlogPost[]) => void) =>
    firebase
      .firestore()
      .collection(CollectionPaths.BlogPosts[this.countryCode])
      .onSnapshot(
        (
          snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
        ) => {
          const blogPosts = snapshot.docs.map((doc: any) => ({
            id: doc.id,
            ...doc.data(),
          }));
          callback(null, blogPosts);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  create = (mapped: BlogPost) =>
    firebase
      .firestore()
      .collection(CollectionPaths.BlogPosts[this.countryCode])
      .add({
        ...mapped,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

  update = (mapped: BlogPost) =>
    firebase
      .firestore()
      .collection(CollectionPaths.BlogPosts[this.countryCode])
      .doc(mapped.id)
      .update({
        ...mapped,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

  write = async (mapped: BlogPost) => {
    if (mapped.id) {
      await this.update(mapped);
      return mapped.id;
    } else {
      const result = await this.create(mapped);
      return result.id;
    }
  };

  delete = (id: string) =>
    firebase
      .firestore()
      .collection(CollectionPaths.BlogPosts[this.countryCode])
      .doc(id)
      .delete();

  scrapeYoutubeVideo = async (url: string): Promise<scrapped> => {
    const youtubeId = getYoutubeIdfromUrl(url);
    if (youtubeId) {
      try {
        const response = await axios.get(siteConsts.youtubeApiV3(youtubeId));
        if (
          Array.isArray(response.data?.items) &&
          response.data?.items.length > 0
        ) {
          const [ytVideo] = response.data.items;
          return {
            title: ytVideo.snippet.localized.title,
            description: ytVideo.snippet.localized.description,
            imageUrl:
              ytVideo.snippet.thumbnails.high.url ??
              ytVideo.snippet.thumbnails.default.url,
          };
        }
        throw new Error("Provided URL has insufficient information");
      } catch (error) {
        logException(error);
        throw error;
      }
    } else {
      throw new Error("Please provide a valid youtube URL");
    }
  };
}
