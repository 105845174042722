import { useEffect, useState } from "react";
import {
  BJSortableList,
  SortContentType,
} from "../../../../components/theme/molecules/BJSortableList";
import { swapSortOrder } from "../../../../utils";
import { useCountry } from "../../../../context";

interface Props {
  onCancel?: () => void;
  contentSection: ContentMenu;
  onSorted: (sortedContent: ContentItem[]) => void;
}

type SortProps = {
  oldIndex: number;
  newIndex: number;
};

export const SortContentItem = ({ contentSection, onSorted }: Props) => {
  const [contents, setContents] = useState<ContentItem[]>([]);

  const { primaryLocale } = useCountry();

  useEffect(() => {
    setContents(
      contentSection?.menuItems
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((content, index) => ({ ...content, sortOrder: index }))
    );
  }, [contentSection?.menuItems]);

  const onSortEnd = ({ oldIndex, newIndex }: SortProps) => {
    if (oldIndex === newIndex) {
      return;
    }
    const sortableContents = swapSortOrder(contents, oldIndex, newIndex);
    setContents(sortableContents);
    onSorted(sortableContents);
  };

  return (
    <BJSortableList
      items={contents
        ?.sort((a: ContentItem, b: ContentItem) => a.sortOrder - b.sortOrder)
        ?.map(
          x =>
            ({
              id:
                x.id +
                Object.keys(x.translations).reduce(
                  (acc, key) => acc + x.translations?.[key].title,
                  ""
                ), // id should be unique
              title: x.translations?.[primaryLocale.key]?.title,
              noImage: true,
            } as SortContentType)
        )}
      onSortEnd={onSortEnd}
    />
  );
};
