import { useEffect, useMemo } from "react";
import { Form, Typography } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { SponsorService } from "../../../services";
import { FormModal } from "../../../components/FormModal";
import { commonErrors } from "../../../language";
import BJInput from "../../../components/theme/atoms/BJInput";
import { DropAndCrop } from "../../../components/DropAndCrop";
import { AspectRatio, formValidationError } from "../../../utils";
import { useCountry } from "../../../context";

type FormValues = {
  name: string;
  title: string;
  portrait: string | null;
  portraitBlurhash?: string;
};

interface Props {
  sponsor: Sponsor | null;
  show: boolean;
  onHide: () => void;
}

const schema = yup.object().shape({
  name: yup.string().required(commonErrors.requiredError),
  portrait: yup.string().required(commonErrors.requiredError),
});

export const SponsorModal = ({ sponsor, show, onHide }: Props) => {
  const {
    formState,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const { currentCountry } = useCountry();

  const sponsorService = useMemo(
    () => new SponsorService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    reset({ name: sponsor?.name ?? "", portrait: sponsor?.portrait ?? "" });
  }, [sponsor, reset, show]);

  const handleUploadedImageUrl = (url: string | null) => {
    setValue("portrait", url, { shouldDirty: true });
  };

  const handleUploadedBlurhash = (url: string | null) => {
    setValue("portraitBlurhash", url, { shouldDirty: true });
  };

  const onDelete = async () => {
    if (sponsor !== null) {
      await sponsorService.deleteSponsor(sponsor.id);
    } else {
      throw new Error("Sponsor not found");
    }
  };

  const onSubmit = async ({ name, portrait, portraitBlurhash }: FormValues) => {
    if (sponsor) {
      await sponsorService.update({
        id: sponsor.id,
        name,
        portrait,
        portraitBlurhash,
      });
    } else {
      await sponsorService.create({ name, portrait, portraitBlurhash });
    }
  };

  const isDirty = !!Object.keys(formState.dirtyFields).length;
  const portrait = watch("portrait");
  const name = watch("name");

  return (
    <FormModal
      onHide={onHide}
      enableSave={isDirty}
      show={show}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      error={null}
      onDelete={onDelete}
      enableDelete={!!sponsor}
      modalSubTitle={sponsor ? `Id - ${sponsor?.id}` : ""}
      modalTitle={sponsor ? sponsor.name : "Add new sponsor"}
      recordIdentifier={name}
    >
      <Form.Item
        label="Name"
        required
        validateStatus={errors.name && "error"}
        extra={
          <Typography.Paragraph type="danger">
            {errors.name?.message}
          </Typography.Paragraph>
        }
      >
        <Controller
          control={control}
          name="name"
          render={({ field }) => <BJInput {...field} />}
        />
      </Form.Item>
      <Form.Item
        label="Image URL"
        validateStatus={errors.portrait && "error"}
        required
        extra={
          <Typography.Paragraph type="danger">
            {errors.portrait?.message}
          </Typography.Paragraph>
        }
      >
        <DropAndCrop
          title="Banner image"
          initialUrl={portrait}
          setUploadUrl={handleUploadedImageUrl}
          uploadImage={sponsorService.uploadSponsorImage}
          lockedRatio={AspectRatio.Free}
          defaultCropBoxWidth={300}
          setBlurhash={handleUploadedBlurhash}
        />
      </Form.Item>
    </FormModal>
  );
};
