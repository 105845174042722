import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import {
  AudienceType,
  DisplayUnitType,
  getAudienceTypes,
  getDisplayUnits,
  getMaxRangeFor,
} from "../../../utils";
import { BJSelectFormItem } from "../molecules/formItems/BJFormSelectItem";
import { BJSlider } from "../molecules/BJSlider";
import { commonErrors } from "../../../language";
import { Alert } from "antd";
import BJButton, { ButtonTypes } from "../atoms/Button";

export interface BJAudienceValues {
  audience: AudienceType | null;
  rangeUnit: DisplayUnitType | null;
  range: [number, number] | null;
}

const { requiredError, rangeRequiredError } = commonErrors;

export const BJAudienceSchema = (required?: boolean) => {
  return {
    audience: required
      ? yup.string().required(requiredError)
      : yup.string().nullable(),
    rangeUnit: yup.string().when("audience", {
      is: (audience: AudienceType) => {
        return (
          audience === AudienceType.Child || audience === AudienceType.Pregnancy
        );
      },
      then: yup.string().required(requiredError),
      otherwise: yup.string().nullable(),
    }),
    range: yup
      .array()
      .of(yup.number())
      .when("audience", {
        is: (audience: AudienceType) => {
          return (
            audience === AudienceType.Child ||
            audience === AudienceType.Pregnancy
          );
        },
        then: yup.array().of(yup.number()).required(rangeRequiredError),
        otherwise: yup.array().nullable(),
      }),
  };
};

interface BJAudienceProps {
  required?: boolean;
  defaultAudience?: AudienceType;
  isAllowedDate?: boolean;
  showAlert?: boolean;
  handleSegmentSubmit?: (audience: BJAudienceValues) => void;
  customAudienceList?: {
    key: string | number;
    value: string | number;
    display: string;
    disabledOption?: boolean;
  }[];
  isNewStructure?: boolean;
}

/**
 * The BJAudience component is a reusable React component used for selecting audience
 * and range parameters in a form.
 *
 * @component
 * @param {Segmentation["extra"]} props.value - (optional) The value of the segmentation's "extra" property,
 * which contains data related to the audience and range parameters. This prop is used to set initial
 * values for the audience and rangeUnit fields.
 * @param {boolean} props.required - (optional) A boolean indicating whether the audience selection is required.
 * If set to true, the audience field will be marked as required.
 * @param {AudienceType} props.defaultAudience - (optional) The default audience type to be selected when the
 * component is rendered. This prop is used when no value is provided for the audience field.
 * @return {JSX.Element} A JSX element representing the BJAudience component.
 */

export const BJAudience = ({
  required = false,
  defaultAudience = AudienceType.All,
  isAllowedDate = false,
  showAlert,
  handleSegmentSubmit,
  customAudienceList,
  isNewStructure = false,
}: BJAudienceProps) => {
  const {
    formState: { errors },
    control,
    watch,
    setValue,
    setError,
    handleSubmit,
  } = useFormContext<BJAudienceValues>();

  const { audience, range, rangeUnit } = watch();
  const displayUnits = isNewStructure
    ? [
        {
          key: DisplayUnitType.Day,
          value: DisplayUnitType.Day,
          display: "Days",
        },
      ]
    : getDisplayUnits(audience);
  const max = getMaxRangeFor(audience, rangeUnit);

  useEffect(() => {
    if (audience === undefined) {
      setValue("audience", defaultAudience, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [audience, defaultAudience, setValue]);

  useEffect(() => {
    if (
      (audience === AudienceType.Child ||
        audience === AudienceType.Pregnancy) &&
      !isAllowedDate
    ) {
      const defaultUnit =
        audience === AudienceType.Child
          ? DisplayUnitType.Month
          : DisplayUnitType.Week;
      setValue("rangeUnit", defaultUnit, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [audience, isAllowedDate, setValue]);

  const onSubmit = async (data: BJAudienceValues) => {
    if (handleSegmentSubmit) {
      const subFormSchema = yup.object().shape({
        ...BJAudienceSchema(true),
      });
      try {
        await subFormSchema.validate(data);
        handleSegmentSubmit(data);
      } catch (error) {
        const errorObject = error as yup.ValidationError;

        if (
          errorObject?.path === "audience" ||
          errorObject?.path === "rangeUnit" ||
          errorObject?.path === "range"
        ) {
          setError(errorObject.path, {
            type: "manual",
            message:
              errorObject.path === "range" ? rangeRequiredError : requiredError,
          });
        }
      }
    }
  };

  const renderRangeUnit = () => {
    if (
      audience === AudienceType.All ||
      audience === AudienceType.Partner ||
      isAllowedDate === false
    ) {
      return;
    }
    return (
      <BJSelectFormItem
        defaultValue={DisplayUnitType.Day}
        size="large"
        control={control}
        error={!!errors?.rangeUnit}
        label="Range Unit"
        message={errors?.rangeUnit?.message}
        optionsList={displayUnits}
        fieldName="rangeUnit"
        required
      />
    );
  };

  const renderRangeSlider = () => {
    if (
      !audience ||
      audience === AudienceType.All ||
      audience === AudienceType.Partner
    ) {
      return;
    }
    const value = range || [0, 10];
    const disable = !rangeUnit;
    const errorMessage =
      (errors?.range as unknown as { message: string })?.message ||
      errors?.range?.map((e: any) => e.message).join(", ");
    return (
      <BJSlider
        required
        disabled={disable}
        onChange={(value: [number, number]) => {
          setValue("range", value, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }}
        selectedRange={range ? value : undefined}
        range
        value={value}
        step={1}
        defaultValue={[max - 10, max]}
        max={max}
        min={0}
        label={`Select ${rangeUnit || ""} range`}
        error={!!errors?.range}
        message={errorMessage}
      />
    );
  };

  const renderSubmitButton = () => {
    return (
      handleSegmentSubmit && (
        <BJButton
          size="small"
          buttonType={ButtonTypes.Primary}
          onClick={handleSubmit(onSubmit)}
        >
          Add Segment
        </BJButton>
      )
    );
  };

  return (
    <>
      {showAlert && (
        <Alert
          type="info"
          showIcon
          message={"The audience is support from app versions 2.30.0+"}
        />
      )}
      <BJSelectFormItem
        defaultValue={defaultAudience}
        size="large"
        control={control}
        error={!!errors?.audience}
        label={"Audience"}
        extra="will be accessible to all users within the designated audience."
        message={errors?.audience?.message}
        optionsList={customAudienceList || getAudienceTypes()}
        fieldName={"audience"}
        required={required}
        handleChange={selectedValue => {
          switch (selectedValue) {
            case AudienceType.All:
            case AudienceType.Partner:
              setValue("rangeUnit", null, {
                shouldDirty: true,
                shouldValidate: true,
              });
              setValue("range", null, {
                shouldDirty: true,
                shouldValidate: true,
              });
              break;
            case AudienceType.Child:
            case AudienceType.Pregnancy:
              setValue("rangeUnit", DisplayUnitType.Day, {
                shouldDirty: true,
                shouldValidate: true,
              });
              setValue("range", [0, 10], {
                shouldDirty: true,
                shouldValidate: true,
              });
              break;
          }
        }}
      />
      {renderRangeUnit()}
      {renderRangeSlider()}
      {renderSubmitButton()}
    </>
  );
};
