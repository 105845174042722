import firebase from "../firebase";
import { logException } from "../utils/exceptionLogger";

const subscribe = (
  callback: (err: Error | null, countries: Country[]) => void
) =>
  firebase
    .firestore()
    .collection("countries")
    .doc("countriesContent")
    .onSnapshot(
      snapshot => {
        const countries = snapshot.data().content as Country[];
        callback(null, countries);
      },
      err => {
        logException(err);
        callback(err, []);
      }
    );

const update = (country: Country) =>
  firebase
    .firestore()
    .collection("countries")
    .doc("countriesContent")
    .update({
      [`content.${country.abb}`]: country,
    });

const getCountries = () => {
  return firebase
    .firestore()
    .collection("search")
    .doc("countries")
    .get()
    .then(doc => doc.data().content as CountryData);
};

const getLanguages = () => {
  return firebase
    .firestore()
    .collection("search")
    .doc("languages")
    .get()
    .then(doc => doc.data().content as LanguageData);
};

const getMeasurementSystems = () => {
  return firebase
    .firestore()
    .collection("search")
    .doc("measurementSystems")
    .get()
    .then(doc => doc.data() as MeasurementSystemMap);
};

export const CountryService = {
  subscribe,
  update,
  getCountries,
  getLanguages,
  getMeasurementSystems,
};
