import { useEffect, useState } from "react";
import { useVerifiers } from "../../../context";
import { VerifierModal } from "./VerifierModal";
import BJList from "../../../components/theme/components/BJList";
import { Sorter } from "../../../components/theme/util/sorter";
import { BJColumnsType } from "../../../components/theme/molecules/BJTable";
import { useCountry } from "../../../context/CountryContext";

export const VerifierList = () => {
  const { verifiers } = useVerifiers();
  const { currentCountry } = useCountry();

  const [selectedVerifier, setSelectedVerifier] = useState<Verifier | null>(
    null
  );
  const [showAddVerifierModal, setShowAddVerifierModal] = useState(false);
  const [originalTable, setOriginalTable] = useState<Verifier[]>([]);

  useEffect(() => {
    setOriginalTable(verifiers);
  }, [verifiers]);

  const columns: BJColumnsType<Verifier> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 2,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  const onClickRow = (record: Verifier) => {
    return {
      onClick: () => {
        setSelectedVerifier(record);
      },
    };
  };

  return (
    <>
      <BJList
        key={`verifierList-${currentCountry.abb}`}
        addButtonCaption={"New Verifier"}
        title={"Verifiers"}
        OriginalList={originalTable}
        columns={columns}
        recordCountSuffix={"Verifiers"}
        onClickRow={onClickRow}
        onclick={() => {
          setShowAddVerifierModal(true);
        }}
      />
      <VerifierModal
        verifier={selectedVerifier}
        show={showAddVerifierModal || selectedVerifier !== null}
        onHide={() => {
          setSelectedVerifier(null);
          setShowAddVerifierModal(false);
        }}
      />
    </>
  );
};
