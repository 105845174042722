import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useCountry, useOffers } from "../../context";
import { OffersService } from "../../services";
import { FormEdit, FormEditType } from "../../components/FormEdit";
import {
  BJSortableList,
  SortContentType,
} from "../../components/theme/molecules/BJSortableList";
import { arrayMove } from "../../utils/moveArrayItems";

type SortProps = {
  oldIndex: number;
  newIndex: number;
};

const getOfferTitle = (offer: OfferV2, primaryLocale: Locale) => {
  return (
    offer?.translations?.[primaryLocale?.key]?.title ||
    offer?.title ||
    "No Title Available"
  );
};

export const ChangeOfferList = () => {
  const { offers: allOffers, loading: offersLoading } = useOffers();
  const { handleSubmit } = useForm();
  const [isDirty, setIsDirty] = useState(false);
  const [offers, setOffers] = useState<OfferV2[]>([]);

  const { currentCountry } = useCountry();
  const { primaryLocale } = useCountry();
  const offersService = useMemo(
    () => new OffersService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    setOffers(
      allOffers
        .sort((a: OfferV2, b: OfferV2) => a.sortOrder - b.sortOrder)
        .map((offer, index) => ({ ...offer, sortOrder: index }))
    );
  }, [allOffers]);

  const saveOrder = async () => {
    await offersService.updateSortOrder(
      offers.map((offers, index) => ({ ...offers, sortOrder: index }))
    );
    setIsDirty(false);
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortProps) => {
    if (oldIndex == newIndex) {
      return;
    }
    const sortableOffers = arrayMove([...offers], oldIndex, newIndex);
    setOffers(sortableOffers);
    setIsDirty(true);
  };
  return (
    <FormEdit
      enableSave={isDirty}
      title={"Change Offers order"}
      editType={FormEditType.VIEW}
      loading={offersLoading}
      onSubmit={handleSubmit(saveOrder)}
    >
      <BJSortableList
        items={offers.map(
          x =>
            ({
              id: x.id,
              title: getOfferTitle(x, primaryLocale),
              noImage: true,
            } as SortContentType)
        )}
        onSortEnd={onSortEnd}
      />
    </FormEdit>
  );
};
