import { useState } from "react";
import { BJFlex } from "../../../components/styled";
import { Divider, Space } from "antd";
import BJButton, { ButtonTypes } from "../../../components/theme/atoms/Button";
import { BJSortableList, SortProps } from "../../../components/theme";
import { useCountry } from "../../../context";
import { swapSortOrder } from "../../../utils";
import { arrayMove } from "@dnd-kit/sortable";

type Props = {
  onCancel: () => void;
  onSave: (features: LevelFeature[]) => void;
  initialFeatures: LevelFeature[];
};

export const ChangeFeaturesOrderList = ({
  initialFeatures,
  onCancel,
  onSave,
}: Props) => {
  const [orderedFeatures, setOrderedFeatures] =
    useState<LevelFeature[]>(initialFeatures);

  const { primaryLocale } = useCountry();

  const sortFeatures = ({ oldIndex, newIndex }: SortProps) => {
    if (oldIndex === newIndex) {
      return;
    }
    setOrderedFeatures(arrayMove(orderedFeatures, oldIndex, newIndex));
  };

  return (
    <BJFlex>
      <Space>
        <BJButton
          buttonType={ButtonTypes.Save}
          onClick={() => {
            onSave(orderedFeatures);
            onCancel();
          }}
        >
          Save
        </BJButton>
        <BJButton buttonType={ButtonTypes.Delete} onClick={onCancel}>
          Cancel
        </BJButton>
      </Space>
      <Divider />
      <BJSortableList
        items={orderedFeatures.map((item, index) => ({
          id: item.translations?.[primaryLocale?.key],
          title: item.translations?.[primaryLocale?.key],
          noImage: true,
        }))}
        onSortEnd={sortFeatures}
      />
    </BJFlex>
  );
};
