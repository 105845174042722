import { Space, Typography } from "antd";
import { BiPlusCircle } from "react-icons/bi";
import { GrSort } from "react-icons/gr";
import styled from "styled-components";
import { CenteredSpinner } from "../../../../components";
import BJButton, {
  ButtonTypes,
} from "../../../../components/theme/atoms/Button";
import { ContentWave as Wave } from "./ContentWave";
interface Props {
  editMode?: boolean;
  icon?: string;
}
export const MobileDisplayContainer = ({
  onAddSection,
  onSort,
  loading,
  body,
}: {
  onAddSection: () => void;
  onSort: () => void;
  loading: boolean;
  body: JSX.Element;
}) => {
  return (
    <MobileDisplay>
      <Wave
        content={
          <TitleContainer>
            <Typography.Title level={4}>{"Content sections"}</Typography.Title>
            <Space>
              <BJButton
                icon={<BiPlusCircle />}
                buttonType={ButtonTypes.Primary}
                loading={false}
                onClick={onAddSection}
                size="large"
              >
                {"Add Section"}
              </BJButton>
              <StyledSort onClick={onSort} />
            </Space>
          </TitleContainer>
        }
      />
      <Body>
        {loading && <CenteredSpinner />}
        {body}
      </Body>
    </MobileDisplay>
  );
};

const StyledSort = styled(GrSort)`
  cursor: pointer;
`;
const TitleContainer = styled.div<Props>`
  display: flex;
  margin: 0.625rem;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  background-image: ${props => `url(${props.icon})`};
  background-size: cover;
`;
const MobileDisplay = styled.div`
  position: relative;
  width: 24rem;
  border-radius: 0.625rem;
  border: 0.063rem solid;
  border-color: ${props => props.theme.stroke};
  background-color: ${props => props.theme.white};
`;

const Body = styled.div`
  margin: 0.625rem;
`;
