import { useEffect, useMemo, useState } from "react";
import { Row, Col } from "antd";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { DietaryAdviceCategoryService } from "../../../services";
import { yupResolver } from "@hookform/resolvers/yup";
import { commonErrors } from "../../../language";
import { FormModal } from "../../../components";
import { formValidationError } from "../../../utils";
import { useCountry } from "../../../context/CountryContext";
import { BJInputFormItem, BJSelectFormItem } from "../../../components/theme";

const types = ["Fish", "Vitamin", "Drink", "Cheese", "Meat", "Seafood"];

interface Props {
  category: DietaryAdviceCategory | null;
  show: boolean;
  onHide: () => void;
}

interface FormValues {
  type: "Fish" | "Vitamin" | "Drink" | "Cheese" | "Meat" | "Seafood";
  translations: {
    [locale: string]: {
      title: string;
    };
  };
}
const { requiredError } = commonErrors;

export const CategoryModal = ({ category, show: showModal, onHide }: Props) => {
  const [error, setError] = useState<string | null>(null);
  const { currentCountry } = useCountry();

  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup
            .string()
            .required(
              `Title (${String(item.key).toUpperCase()}): ${requiredError}`
            ),
        });
        return acc;
      }, {} as any)
    ),
    type: yup.string().required(`Type: ${requiredError}`),
  });

  const {
    formState,
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (category) {
      reset({
        type: category.type,
        translations: category?.translations,
      });
    } else {
      reset();
      setError(null);
    }
  }, [category, reset, showModal]);

  const dietaryAdviceCategoryService = useMemo(
    () => new DietaryAdviceCategoryService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  const onSubmit = async (data: FormValues) => {
    const translations: FormValues["translations"] = {};

    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        title: value.title.trim(),
      };
    }

    const payload = {
      type: data.type,
      translations,
    };

    if (category) {
      await dietaryAdviceCategoryService.update({
        id: category.id,
        ...payload,
      });
    } else {
      await dietaryAdviceCategoryService.create(payload);
    }
  };

  const onDelete = async () => {
    if (category !== null) {
      await dietaryAdviceCategoryService.delete(category.id);
    } else {
      throw new Error("Category not found");
    }
  };

  const isDirty = !!Object.keys(formState.dirtyFields).length;

  return (
    <FormModal
      onHide={onHide}
      enableSave={isDirty}
      show={showModal}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      error={error}
      onDelete={onDelete}
      enableDelete={!!category}
      modalSubTitle={category ? `ID - ${category?.id}` : ""}
      modalTitle={category ? category.translations : "Add new category"}
      recordIdentifier={category?.id}
      localeSupported
      errors={errors as any}
    >
      {locale => (
        <Row>
          <Col span={24}>
            <BJSelectFormItem
              label={"Type"}
              fieldName={"type"}
              size="large"
              control={control}
              error={!!errors?.type}
              message={errors?.type?.message}
              optionsList={types?.map(type => ({
                value: type,
                key: type,
                display: type,
              }))}
              required
              includeEmpty
            />
            <BJInputFormItem
              label={`Title (${locale?.label})`}
              fieldName={`translations.${locale?.key}.title`}
              key={`translations.${locale?.key}.title`}
              control={control}
              error={!!errors?.translations?.[locale.key]?.title}
              message={errors?.translations?.[locale.key]?.title?.message}
              required
            />
          </Col>
        </Row>
      )}
    </FormModal>
  );
};
