import { useEffect, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { DietaryAdviceContentService } from "../../../services";
import { useCountry, useDietaryAdvices } from "../../../context";
import { CenteredSpinner, FormEdit, FormEditType } from "../../../components";
import { Row, Col, Space } from "antd";
import styled from "styled-components";
import { formValidationError } from "../../../utils";
import { BJInputFormItem, BJMdFormItem } from "../../../components/theme";

type FormValues = {
  translations: {
    [locale: string]: {
      source: string;
      general: string;
    };
  };
};

export const DietaryAdviceContentPage = () => {
  const { content, loading } = useDietaryAdvices();
  const { currentCountry, primaryLocale } = useCountry();
  const { formState, handleSubmit, reset, control } = useForm<FormValues>({});

  useEffect(() => {
    if (loading || content === null) {
      return;
    }
    reset({
      ...content,
    });
  }, [content, loading, reset]);

  const dietaryAdviceContentService = useMemo(
    () => new DietaryAdviceContentService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const translations: FormValues["translations"] = {};

    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        source: value.source,
        general: value.general,
      };
    }

    if (content?.id) {
      await dietaryAdviceContentService.update({
        id: content.id,
        translations,
      });
    } else {
      await dietaryAdviceContentService.create({
        translations,
      });
    }
  };

  if (loading) {
    return <CenteredSpinner />;
  }

  if (content === null) {
    return <h1>Dietary advice content not found</h1>;
  }

  const isDirty = !!Object.keys(formState.dirtyFields).length;

  return (
    <FormEdit
      enableSave={isDirty}
      title={content ? content?.translations?.[primaryLocale.key].source : ""}
      id={content?.id}
      editType={content?.id ? FormEditType.EDIT : FormEditType.ADD}
      loading={loading}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      localeSupported
    >
      {locale => (
        <Row>
          <Col>
            <Space direction="vertical" size="large">
              <ItemWrapper>
                <BJMdFormItem
                  fieldName={`translations.${locale.key}.general`}
                  key={`translations.${locale.key}.general`}
                  label={`General (${locale?.label ?? ""})`}
                  control={control}
                  required={false}
                />
              </ItemWrapper>
              <BJInputFormItem
                fieldName={`translations.${locale.key}.source`}
                key={`translations.${locale.key}.source`}
                label={`Source (${locale?.label ?? ""})`}
                control={control}
                required={false}
              />
            </Space>
          </Col>
        </Row>
      )}
    </FormEdit>
  );
};

const ItemWrapper = styled.div`
  padding-top: 2rem;
`;
