import { Typography } from "antd";
import { BJColumnType, SortContentType, Sorter } from "../../components/theme";
import BJList from "../../components/theme/components/BJList";

const columns: BJColumnType<Flow>[] = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    width: 1,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
    width: 1,
    ellipsis: true,
    render: (text, record) =>
      record?.image && (
        <>
          <Typography.Link href={record?.image} target="_blank">
            <img
              src={record?.image}
              alt=""
              width={25}
              height={25}
              onClick={e => {
                e.stopPropagation();
              }}
            />
          </Typography.Link>
        </>
      ),
  },
];
const HighlightsFlowList = ({
  flowList,
  setSelectedFlow,
  setShowFlow,
  setSortedHighlights,
  setShowSortOrder,
}: {
  flowList: Flow[];
  setSelectedFlow: React.Dispatch<React.SetStateAction<Flow>>;
  setShowFlow: React.Dispatch<React.SetStateAction<boolean>>;
  setSortedHighlights: React.Dispatch<React.SetStateAction<SortContentType[]>>;
  setShowSortOrder: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const onClickRow = (record: Flow) => {
    return {
      onClick: () => {
        setSelectedFlow(record);
        setShowFlow(true);
      },
    };
  };
  return (
    <BJList
      onChangeOrder={() => {
        const contet: SortContentType[] = flowList.map(x => ({
          id: x.id,
          imageUrl: x.image,
          title: x.title,
        }));
        setSortedHighlights(contet);
        setShowSortOrder(true);
      }}
      filterOnlyDisplayList
      onclick={() => {
        setShowFlow(true);
      }}
      addButtonCaption={"New Flow"}
      OriginalList={flowList ?? []}
      columns={columns}
      onClickRow={onClickRow}
      recordCountSuffix={"record(s)"}
      defaultPageSize={5}
      hideSearch
    />
  );
};

export { HighlightsFlowList };
