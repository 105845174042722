import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { MissionsService } from "../services/MissionsService";

interface ContextState {
  missions: Mission[] | null;
  loading: boolean;
  error: Error | null;
  uploadMissionImage: (file: Blob | ArrayBuffer, fileName: string) => any;
  onCreate: (mission: Omit<Mission, "id">) => Promise<void>;
  onUpdate: (mission: Mission) => Promise<void>;
  onDelete: (missionId: Mission["id"]) => Promise<void>;
}

const MissionsContext = createContext<ContextState | undefined>(undefined);

export const MissionsProvider = ({ ...rest }) => {
  const [missions, setMissions] = useState<Mission[] | null>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    const unsub = MissionsService.subscribe((err, missionsData) => {
      setMissions(missionsData);
      setError(err);
      setLoading(false);
    });

    return () => {
      unsub();
    };
  }, []);

  const onCreate = async (mission: Omit<Mission, "id">) =>
    await MissionsService.create(mission);

  const onUpdate = async (mission: Mission) =>
    await MissionsService.update(mission);

  const onDelete = async (missionId: Mission["id"]) =>
    await MissionsService.delete(missionId);

  const uploadMissionImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => await MissionsService.uploadMissionImage(file, fileName);

  const value = useMemo(
    () => ({
      missions,
      loading,
      error,
      uploadMissionImage,
      onCreate,
      onUpdate,
      onDelete,
    }),
    [missions, loading, error]
  );

  return <MissionsContext.Provider value={value} {...rest} />;
};

export const useMissions = () => {
  const state = useContext(MissionsContext);

  if (!state) {
    throw new Error("useMissions should be used inside MissionsProvider");
  }

  return state;
};
