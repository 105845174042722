import { firestore } from "../firebase";
import { firebaseBucketConsts } from "../utils";
import { uploadImage } from "./serviceUtils";
import { CollectionPaths } from "../helper/collections";
import { CountryCodeService } from "./CountryCodeService";
import { logException } from "../utils/exceptionLogger";

export class HighlightsService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  static toHighlight = (doc: any): Highlight => {
    const data = doc.data();
    return data;
  };

  subscribe = (
    callback: (err: Error | null, highlights: Highlight[]) => void
  ) =>
    firestore
      .collection(CollectionPaths.Highlights[this.countryCode])
      .onSnapshot(
        snapshot => {
          const highlight = snapshot.docs.map(HighlightsService.toHighlight);
          callback(null, highlight);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  create = async (data: Highlight) => {
    return firestore
      .collection(CollectionPaths.Highlights[this.countryCode])
      .doc(data.id)
      .set(data);
  };

  update = (data: Highlight) => {
    return firestore
      .collection(CollectionPaths.Highlights[this.countryCode])
      .doc(data.id)
      .set({ ...data }, { merge: true });
  };

  delete = (id: Highlight["id"]) =>
    firestore
      .collection(CollectionPaths.Highlights[this.countryCode])
      .doc(id)
      .delete();

  uploadHighlightFlowImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => {
    const url = uploadImage(
      file,
      firebaseBucketConsts.highlightFileStoragePath,
      fileName
    );
    return url;
  };

  uploadInAppImage = async (file: Blob | ArrayBuffer, fileName: string) => {
    const url = uploadImage(
      file,
      firebaseBucketConsts.highlightFileStoragePath,
      fileName
    );
    return url;
  };
}
