import { Col, Row, Select, Tag } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router";
import { useTheme } from "styled-components";
import { BJFlex } from "../../components/styled";
import { BJContainer } from "../../components/theme";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";
import BJList from "../../components/theme/components/BJList";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";
import { useRemoteNotifications } from "../../context/RemoteNotificationContext";
import { newRoute } from "../../routes";
import {
  RemoteNotificationRecordType,
  capitalizeFirstLetter,
  formatRemoteNotificationMessage,
  formatTimeStampToMoment,
  getUserSegments,
} from "../../utils";
import { StyledButton } from "./style";
import { useCountry } from "../../context";

export const RemoteNotificationsList = () => {
  const { remoteNotifications, loading, error } = useRemoteNotifications();
  const navigate = useNavigate();
  const theme = useTheme();
  const { currentCountry } = useCountry();

  const [originalTable, setOriginalTable] = useState<
    RemoteNotificationListType[]
  >([]);

  const [groupedBy, setGroupedBy] = useState<string | undefined>();

  useEffect(() => {
    if (
      loading ||
      !remoteNotifications ||
      !(remoteNotifications.length > 0 || error)
    ) {
      return;
    }

    const notificationTableSource: RemoteNotificationListType[] =
      remoteNotifications
        .filter(x => !x?.to)
        .map(notification => ({
          ...notification.notification,
          id: notification?.id,
          status: notification?.status,
          userSegment: notification?.userSegment,
          topic: notification?.topic,
          triggerTime: formatTimeStampToMoment(notification?.triggerTime),
          successCount: notification?.successCount,
          faliureCount: notification?.faliureCount,
          scheduledAt: formatTimeStampToMoment(notification?.scheduledAt),
          ...Object.entries(
            notification?.notification?.translations ?? {}
          ).reduce(
            (acc, [locale, values]) => (
              (acc[`title${capitalizeFirstLetter(locale)}`] = values.title), acc
            ),
            {} as any
          ),
          ...Object.entries(
            notification?.notification?.translations ?? {}
          ).reduce(
            (acc, [locale, values]) => (
              (acc[`body${capitalizeFirstLetter(locale)}`] = values.body), acc
            ),
            {} as any
          ),
        }))
        .sort((a, b) => a.scheduledAt - b.scheduledAt) ?? [];

    const getSortedTableSource = (groupedBy: unknown) => {
      let tableSource = [...notificationTableSource];

      switch (groupedBy) {
        case "scheduled":
          tableSource = [...notificationTableSource].sort(
            (a, b) => a.scheduledAt - b.scheduledAt
          );
          break;
        case "completed":
          tableSource = [...notificationTableSource].sort(
            (a, b) => b.scheduledAt - a.scheduledAt
          );
          break;
        default:
          tableSource = [...notificationTableSource].sort((a, b) =>
            a.status === "scheduled" ? -1 : b.status === "scheduled" ? 1 : 0
          );
      }
      return tableSource;
    };

    setOriginalTable([
      ...getSortedTableSource(groupedBy).filter(
        ({ status }) => groupedBy === undefined || status === groupedBy
      ),
    ]);
  }, [error, loading, remoteNotifications, groupedBy]);

  const onClickRow = (record: RemoteNotificationListType) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const handleNewNotification = useCallback(() => {
    navigate(newRoute);
  }, [navigate]);

  const handleDuplicateRecord = (record: RemoteNotificationListType) => {
    navigate(newRoute, { state: { id: record.id, duplicate: true } });
  };

  const renderPrefix = useMemo(() => {
    return (
      <>
        <BJButton
          buttonType={ButtonTypes.Add}
          icon={<BsPlusCircleFill size={"1rem"} color={theme.button.primary} />}
          size="large"
          onClick={handleNewNotification}
        >
          Compose
        </BJButton>
      </>
    );
  }, [handleNewNotification, theme.button.primary]);

  const columns: BJColumnType<RemoteNotificationListType>[] = [
    ...currentCountry?.locales
      ?.filter(locale => locale.primary)
      .map(locale => ({
        title: `Title (${locale?.label})`,
        dataIndex: `title${capitalizeFirstLetter(locale?.key)}`,
        key: `title${capitalizeFirstLetter(locale?.key)}`,
        width: 1,
        ellipsis: true,
        sorter: {
          compare: Sorter.DEFAULT,
        },
      })),
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 0.6,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (text, record) => (
        <NotificationStatusInfo status={record.status} />
      ),
      fixed: "left",
    },
    {
      title: "Schedule time",
      dataIndex: "scheduldedAt",
      key: "schedule_time",
      width: 0.75,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (text, notification) =>
        notification.scheduledAt && (
          <div>{notification.scheduledAt.format("YYYY/MM/DD HH:mm")}</div>
        ),
      fixed: "left",
    },
    ...currentCountry?.locales
      ?.filter(locale => locale.primary)
      .map(locale => ({
        title: `Body (${locale?.label})`,
        dataIndex: `body${capitalizeFirstLetter(locale?.key)}`,
        key: `body${capitalizeFirstLetter(locale?.key)}`,
        width: 1,
        ellipsis: true,
        sorter: {
          compare: Sorter.DEFAULT,
        },
      })),
    ...currentCountry?.locales
      ?.filter(locale => locale.primary)
      .map(locale => ({
        title: `Type`,
        dataIndex: "type",
        key: "type",
        width: 1.5,
        ellipsis: true,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        onFilter: (
          value: string | number | boolean,
          record: NotificationInfo
        ) =>
          record.notification.translations?.[locale.key]?.title.includes(
            value as string
          ),
        render: (text: string, record: NotificationInfo) => (
          <span>
            {record?.topic
              ? "Topic"
              : record?.userSegment
              ? `Segment (${
                  getUserSegments().find(
                    ({ key }) => key === record?.userSegment
                  )?.display || "-"
                })`
              : "token"}
          </span>
        ),
      })),
    {
      title: "Success Count",
      dataIndex: "successCount",
      key: "successCount",
      width: 0.5,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (text, record) => {
        return (
          <span>
            {record?.topic
              ? ""
              : record?.successCount
              ? record.successCount
              : 0}
          </span>
        );
      },
    },
    ...currentCountry?.locales
      ?.filter(locale => locale.primary)
      .map(locale => ({
        title: "Failure Count",
        dataIndex: "faliureCount",
        key: "faliureCount",
        width: 0.5,
        ellipsis: true,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        onFilter: (
          value: string | number | boolean,
          record: NotificationInfo
        ) =>
          record.notification.translations?.[locale.key]?.title.includes(
            value as string
          ),
        render: (text: string, record: NotificationInfo) => (
          <span>
            {record?.topic
              ? ""
              : record?.faliureCount
              ? record.faliureCount
              : 0}
          </span>
        ),
      })),
    {
      title: "Action",
      dataIndex: "duplicate",
      key: "duplicate",
      width: 0.5,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (text, record) => (
        <BJFlex>
          <BJButton
            key={"duplicate"}
            onClick={e => {
              e.stopPropagation();
              handleDuplicateRecord(record);
            }}
            buttonType={ButtonTypes.Save}
            disabled={false}
            loading={false}
            size="small"
            htmlType="button"
          >
            Duplicate
          </BJButton>
        </BJFlex>
      ),
    },
  ];

  const onChangedGroupedBy = (e: string) => setGroupedBy(e);

  return (
    <BJList
      scrollX={1500}
      filters={
        <>
          <BJContainer>
            <Row wrap gutter={[20, 20]}>
              <Col>
                <Select
                  allowClear
                  placeholder="Group by:"
                  onChange={onChangedGroupedBy}
                  options={[
                    {
                      label: "Pending",
                      value: RemoteNotificationRecordType.pending,
                    },
                    {
                      label: "Draft",
                      value: RemoteNotificationRecordType.draft,
                    },
                    {
                      label: "Scheduled",
                      value: RemoteNotificationRecordType.scheduled,
                    },
                    {
                      label: "Completed",
                      value: RemoteNotificationRecordType.completed,
                    },
                    {
                      label: "Error",
                      value: RemoteNotificationRecordType.error,
                    },
                  ]}
                />
              </Col>
            </Row>
          </BJContainer>
        </>
      }
      loading={loading}
      filterOnlyDisplayList
      addButtonCaption={"Compose"}
      title={"Remote notifications"}
      OriginalList={originalTable}
      columns={columns}
      onClickRow={onClickRow}
      prefix={renderPrefix}
      onclick={handleNewNotification}
      recordCountSuffix={"RemoteNotification"}
    />
  );
};

const NotificationStatusInfo = ({
  status,
}: {
  status: RemoteNotificationRecordType;
}) => {
  const composedNotification = useMemo(
    () => formatRemoteNotificationMessage(status),
    [status]
  );

  return (
    <Tag color={composedNotification?.color}>
      {composedNotification?.shortMessage}
    </Tag>
  );
};
