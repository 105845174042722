import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { UserBirthStoriesService } from "../services";

type ContextState = {
  birthStories: UserBirthStory[];
  fullBirthStories: FullUserBirthStory[];
  loading: boolean;
  error: Error | null;
  getById: (id: UserBirthStory["id"]) => UserBirthStory | null;
  getChildById: (childId: string) => UserBirthStory | null;
};

const UserBirthStoriesContext = createContext<ContextState>({
  birthStories: [],
  fullBirthStories: [],
  loading: false,
  error: null,
  getById: () => null,
  getChildById: () => null,
});

export const UserBirthStoriesProvider = ({ ...rest }) => {
  const [birthStories, setBirthStories] = useState<UserBirthStory[]>([]);
  const [fullBirthStories, setFullBirthStories] = useState<
    FullUserBirthStory[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    const unsub = UserBirthStoriesService.subscribe((_error, _birthStories) => {
      setBirthStories(_birthStories);
      setError(_error);
    });

    return unsub;
  }, []);

  useEffect(() => {
    (async () => {
      const fullBirthStories = await Promise.all(
        birthStories.map(async birthStory => {
          // const user = await UserService.getUser(birthStory.userId);

          return {
            ...birthStory,
            name: "",
            // country: user?.country ?? "se",
            // locale: user?.locale ?? "sv",
          } as FullUserBirthStory;
        })
      );
      setFullBirthStories(fullBirthStories);
      setLoading(false);
    })();
  }, [birthStories]);

  const getById = useCallback(
    (id: UserBirthStory["id"]) => {
      return birthStories.find(a => a.id === id) || null;
    },
    [birthStories]
  );

  const getChildById = useCallback(
    (childId: string) => {
      return birthStories.find(a => a.childId === childId) || null;
    },
    [birthStories]
  );

  const value = useMemo(
    () => ({
      birthStories,
      getChildById,
      fullBirthStories,
      loading,
      error,
      getById,
    }),
    [birthStories, getChildById, fullBirthStories, loading, error, getById]
  );

  return <UserBirthStoriesContext.Provider value={value} {...rest} />;
};

export const useUserBirthStories = () => {
  const context = React.useContext(UserBirthStoriesContext);
  if (context === undefined) {
    throw new Error(
      "useUserBirthStories must be used within an UserBirthStoriesProvider"
    );
  }
  return context;
};
