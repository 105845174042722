import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";

export class RecommendedService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  static toRecommended = (doc: any): Popular => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      type: data.week == null ? "month" : "week",
    };
  };

  subscribe = (
    callback: (err: Error | null, recommendedData: Popular[]) => void
  ) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Recommended[this.countryCode])
      .onSnapshot(
        snapshot => {
          const _recommendedData = snapshot.docs.map(
            RecommendedService.toRecommended
          );
          callback(null, _recommendedData);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  create = (data: Partial<Popular>) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Recommended[this.countryCode])
      .add(data);

  update = (id: Popular["id"], data: Partial<Popular>) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Recommended[this.countryCode])
      .doc(id)
      .update({ ...data });

  delete = (id: Popular["id"]) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Recommended[this.countryCode])
      .doc(id)
      .delete();
}
