import {
  createContext,
  useEffect,
  useMemo,
  useState,
  useContext,
  useCallback,
} from "react";
import { RemoteNotificationService } from "../services";
import { useCountry } from "./CountryContext";
import { RemoteNotificationRecordType } from "../utils";

type ContextState = {
  remoteNotifications: NotificationInfo[];
  loading: boolean;
  error: Error | null;
  getById: (id: NotificationInfo["id"]) => NotificationInfo | null;
  saveNotificationInfo: (
    content: NotificationInfo,
    type: RemoteNotificationRecordType
  ) => Promise<string>;
  onRemoveScheduled: (firebaseRecordId: string) => Promise<void>;
  sendRemoteNotifiction: (content: NotificationInfo) => Promise<void>;
  uploadRemoteNotificationImage: (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => Promise<string>;
};

const RemoteNotificationContext = createContext<ContextState>({
  loading: false,
  error: null,
  remoteNotifications: [],
  getById: () => null,
  saveNotificationInfo: () => null,
  onRemoveScheduled: () => null,
  sendRemoteNotifiction: () => null,
  uploadRemoteNotificationImage: () => null,
});

export const RemoteNotificationProvider = ({ ...rest }) => {
  const [remoteNotifications, setRemoteNotifications] = useState<
    NotificationInfo[]
  >([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();
  const remoteNotificationService = useMemo(
    () =>
      currentCountry?.abb
        ? new RemoteNotificationService(currentCountry.abb)
        : null,
    [currentCountry?.abb]
  );

  useEffect(() => {
    if (!remoteNotificationService) {
      return;
    }

    setLoading(true);
    const unsubRemoteNotifications =
      remoteNotificationService.subscribeToRemoteNotifications(
        (_error, remoteNotifications) => {
          setRemoteNotifications(remoteNotifications);
          setError(_error);
          setLoading(false);
        }
      );
    return () => {
      unsubRemoteNotifications();
    };
  }, [remoteNotificationService]);

  const getById = useCallback(
    (id: NotificationInfo["id"]) => {
      const remoteNotification = remoteNotifications.find(a => a?.id === id);
      return remoteNotification;
    },
    [remoteNotifications]
  );

  const value = useMemo(
    () => ({
      loading,
      error,
      remoteNotifications,
      getById,
      saveNotificationInfo: remoteNotificationService?.saveNotificationInfo,
      onRemoveScheduled: remoteNotificationService?.onRemoveScheduled,
      sendRemoteNotifiction: remoteNotificationService?.sendRemoteNotifiction,
      uploadRemoteNotificationImage:
        remoteNotificationService?.uploadRemoteNotificationImage,
    }),
    [
      loading,
      error,
      remoteNotifications,
      getById,
      remoteNotificationService?.saveNotificationInfo,
      remoteNotificationService?.onRemoveScheduled,
      remoteNotificationService?.sendRemoteNotifiction,
      remoteNotificationService?.uploadRemoteNotificationImage,
    ]
  );

  return <RemoteNotificationContext.Provider value={value} {...rest} />;
};

export const useRemoteNotifications = () => {
  const context = useContext(RemoteNotificationContext);
  if (context === undefined) {
    throw new Error(
      "useRemoteNotification must be used within an NotificationProvider"
    );
  }
  return context;
};
