import { Navigate, Route, Routes } from "react-router-dom";
import { RemoteNotificationsList } from "../../pages/RemoteNotification";
import RemoteNotificationPage from "../../pages/RemoteNotification/RemoteNotificationPage";
import { PushNotificationsLayout } from "../../Layouts/PushNotificationsLayout";
import { DeepLink } from "../../pages/DeepLink";

export const PushNotificationsRoutes = () => (
  <Routes>
    <Route path={`/`} element={<PushNotificationsLayout />}>
      <Route path={""} element={<Navigate to="remote" />} />
      <Route path={"/remote"}>
        <Route path={""} element={<RemoteNotificationsList />} />
        <Route path={"new"} element={<RemoteNotificationPage />} />
        <Route path={":id"} element={<RemoteNotificationPage />} />
      </Route>
      <Route path={"deepLink"} element={<DeepLink />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  </Routes>
);
