import firebase, { firestore } from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { formatTimeStampToMoment } from "../utils";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";
import { uploadImage } from "./serviceUtils";

export class BannersService extends CountryCodeService {
  readonly bannerFileStoragePath: string = "banners";

  constructor(locale: string) {
    super(locale);
  }

  toBanner = (doc: any): Banner => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      startTime: formatTimeStampToMoment(data.startTime),
      endTime: formatTimeStampToMoment(data.endTime),
      translations: data.translations ?? {},
    };
  };

  subscribe = (callback: (err: Error | null, banners: Banner[]) => void) =>
    firestore.collection(CollectionPaths.Banners[this.countryCode]).onSnapshot(
      snapshot => {
        const banners = snapshot.docs.map(this.toBanner);
        callback(null, banners);
      },
      err => {
        logException(err);
        callback(err, []);
      }
    );

  create = (data: Banner) =>
    firestore.collection(CollectionPaths.Banners[this.countryCode]).add(data);

  update = (id: string, data: Banner) =>
    firestore
      .collection(CollectionPaths.Banners[this.countryCode])
      .doc(id)
      .update(data);

  deleteBanner = (id: string) =>
    firestore
      .collection(CollectionPaths.Banners[this.countryCode])
      .doc(id)
      .delete();

  uploadBannerImage = async (file: Blob | ArrayBuffer, fileName: string) =>
    uploadImage(file, this.bannerFileStoragePath, fileName);

  updateSortOrder = (banners: Banner[]) => {
    const batch = firebase.firestore().batch();
    banners.forEach(banner => {
      const ref = firestore
        .collection(CollectionPaths.Banners[this.countryCode])
        .doc(banner.id);
      batch.update(ref, { sortOrder: banner.sortOrder, priority: "" });
    });
    return batch.commit();
  };
}
