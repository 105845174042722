import {
  createContext,
  useEffect,
  useMemo,
  useState,
  useContext,
  useCallback,
} from "react";
import { TagsService } from "../services";

type ContextState = {
  tags: Tags[];
  loadingTags: boolean;
  error: Error | null;
  filterUnnessaryTags: (tagNames: string[]) => string[];
};

const TagsContext = createContext<ContextState>({
  tags: [],
  loadingTags: false,
  error: null,
  filterUnnessaryTags: null,
});

export const TagsProvider = ({ ...rest }) => {
  const [tags, setTags] = useState<Tags[]>([]);
  const [loadingTags, setLoadingTags] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoadingTags(true);

    return TagsService.subscribeToTags((_error, _categories) => {
      setTags(_categories);
      setError(_error);
      setLoadingTags(false);
    });
  }, []);

  const filterUnnessaryTags = useCallback(
    (tagNames: string[]) => {
      return tagNames?.filter(x => tags.find(y => y.name === x));
    },
    [tags]
  );

  const value = useMemo(
    () => ({
      tags: tags ?? [],
      loadingTags: loadingTags,
      error,
      filterUnnessaryTags,
    }),
    [tags, loadingTags, error, filterUnnessaryTags]
  );

  return <TagsContext.Provider value={value} {...rest} />;
};

export const useTags = () => {
  const context = useContext(TagsContext);
  if (context === undefined) {
    throw new Error("useTags must be used within an TagsProvider");
  }
  return context;
};
