import { Typography } from "antd";
import { useEffect, useMemo } from "react";
import styled from "styled-components";
import { useToolsSections } from "../../hooks";
import { ContentItemPage } from "../ContentCollections/ContentMenu/ContentItemPage";
import { ContentSectionPage } from "../ContentCollections/ContentMenu/ContentSectionPage";
import { ReactComponent as ContentEditButton } from "../../assets/icons/contentEdit.svg";
import { ContentItem } from "../ContentCollections/ContentMenu/Components/ContentItem";
import { SortContentSection } from "../ContentCollections/ContentMenu/Components/SortContentSection";
import { EditContentContainer } from "../ContentCollections/EditContentContainer";
import { MobileDisplayContainer } from "../ContentCollections/ContentMenu/Components/MobileDisplayContainer";
import { AnimatePresence } from "framer-motion";
import {
  ContentActions,
  useManageContent,
} from "../../hooks/contentCollections/useManageContent";
import { getMaxSortOrder, swapSortOrder } from "../../utils";
import {
  BaseSortType,
  BJSortableList,
  SortProps,
} from "../../components/theme";
import { GlobalWordSpacing } from "../../components/GlobalStyle";
import { useCountry } from "../../context";
interface Props {
  editMode?: boolean;
  icon?: string;
}

export const ToolsMenuSectionsPage = () => {
  const {
    menuSections,
    loading,
    updateSortOrder,
    createMenuSection,
    updateMenuSection,
    deleteMenuSection,
    updateMenuItems,
  } = useToolsSections();
  const { state, dispatch, editSectionTitle } = useManageContent();

  const lastSortOrderInSection = useMemo(() => {
    if (loading) return -1;
    const max = getMaxSortOrder(menuSections);
    return max;
  }, [menuSections, loading]);

  const { primaryLocale } = useCountry();

  useEffect(() => {
    if (loading) return;
    if (menuSections?.length) {
      dispatch({
        type: ContentActions.SETCONTENTLIST,
        payload: menuSections,
      });
    }
  }, [menuSections, dispatch, loading]);

  const onupdateDone = () => {
    dispatch({
      type: ContentActions.CONTENTUPDATED,
      payload: null,
    });
  };

  const renderContentItem = ({ menuSection }: { menuSection: ContentMenu }) => {
    return (
      <>
        <GlobalWordSpacing />
        <Content>
          {menuSection?.menuItems
            ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
            .map(item => {
              return (
                <ContentItem
                  onClick={() => {
                    onupdateDone();
                    dispatch({
                      type: ContentActions.EDITITEM,
                      payload: {
                        contentSection: menuSection,
                        editItem: item,
                      },
                    });
                  }}
                  key={`${
                    menuSection?.translations?.[primaryLocale.key]?.title
                  } ${item?.translations?.[primaryLocale.key]?.title} ${
                    item?.sortOrder
                  }`}
                  description={item.translations?.[primaryLocale.key]?.title}
                  icon={item.icon}
                />
              );
            })}
          {renderNewContentItem({ menuSection })}
        </Content>
      </>
    );
  };

  const onSortEnd = async ({ oldIndex, newIndex }: SortProps) => {
    if (oldIndex === newIndex) {
      return;
    }
    const sortableContents = swapSortOrder(
      state.contentMenu,
      oldIndex,
      newIndex
    );
    await updateSortOrder(sortableContents);
  };

  const renderSectionTitle = (contentSection: ContentMenu) => {
    return contentSection?.translations?.[primaryLocale.key]?.title;
  };

  const renderItem = ({ menuSection }: { menuSection: ContentMenu }) => {
    return (
      <div key={menuSection.id}>
        <SectionDiv>
          <Typography.Title level={4}>
            {renderSectionTitle(menuSection)}
          </Typography.Title>
          <StyledContentEditButton
            onClick={() => {
              onupdateDone();
              dispatch({
                type: ContentActions.EDITSECTION,
                payload: { editSection: menuSection },
              });
            }}
          />
        </SectionDiv>
        {renderContentItem({ menuSection })}
      </div>
    );
  };

  const renderNewContentItem = ({
    menuSection,
  }: {
    menuSection: ContentMenu;
  }) => {
    return (
      <ContentItem
        isNewItem
        onClick={() => {
          onupdateDone();
          dispatch({
            type: ContentActions.ADDITEM,
            payload: {
              editSection: menuSection,
            },
          });
        }}
        key={"new item"}
        description={"Add item"}
        icon={"default icon"}
      />
    );
  };

  return (
    <ContentMenuContainer>
      <Typography.Title level={2}>{"Tools menu"}</Typography.Title>
      <ContentContainer
        editMode={
          state.addSection ||
          !!state.editSection ||
          !!state.addItem ||
          !!state.editItem ||
          !!state.sortSection
        }
      >
        <MobileDisplayContainer
          onSort={() => {
            onupdateDone();
            dispatch({
              type: ContentActions.SORTCONTENT,
              payload: !state?.sortSection,
            });
          }}
          onAddSection={() => {
            onupdateDone();
            dispatch({
              type: ContentActions.ADDSECTION,
              payload: true,
            });
          }}
          loading={loading}
          body={
            <>
              {!!state?.contentMenu?.length && !loading && (
                <BJSortableList
                  disableDrag
                  onSortEnd={onSortEnd}
                  items={state?.contentMenu?.map(x => ({
                    ...x,
                    id: x.id,
                    title: x.translations?.[primaryLocale.key]?.title,
                    noImage: true,
                    menuItems: [
                      ...x.menuItems,
                      ...(x.experimentalMenuItems ?? []).map(expItem => ({
                        ...expItem,
                        isExperimentalTool: true,
                      })),
                    ],
                  }))}
                  render={(contentSection: BaseSortType) => {
                    const mappedMenu = state?.contentMenu.map(cItem => ({
                      ...cItem,
                      menuItems: [
                        ...cItem.menuItems,
                        ...(cItem.experimentalMenuItems ?? []).map(expItem => ({
                          ...expItem,
                          isExperimentalTool: true,
                        })),
                      ],
                    }));
                    const renderMenu = mappedMenu.find(
                      x => x.id === contentSection.id
                    );
                    return renderItem({ menuSection: renderMenu });
                  }}
                />
              )}
            </>
          }
        />
        <AnimatePresence>
          {(state.addSection ||
            state.editSection ||
            state.addItem ||
            state.editItem ||
            state.sortSection) && (
            <EditContentContainer
              onClose={onupdateDone}
              editSectionTitle={editSectionTitle}
              render={
                <>
                  {state?.sortSection && (
                    <SortContentSection
                      onUpdateDone={onupdateDone}
                      sections={menuSections}
                      loading={loading}
                      sortingFunction={updateSortOrder}
                    />
                  )}
                  {state.addSection || state.editSection ? (
                    <ContentSectionPage
                      maxSortOrder={lastSortOrderInSection}
                      onUpdateDone={onupdateDone}
                      contentMenu={state.editSection}
                      createSection={createMenuSection}
                      updateSection={updateMenuSection}
                      deleteSection={deleteMenuSection}
                    />
                  ) : state.editItem || state.addItem ? (
                    <ContentItemPage
                      updateMenuItems={updateMenuItems}
                      onUpdateDone={onupdateDone}
                      contentItem={
                        state.editItem ? state?.editItem?.editItem : null
                      }
                      contentMenu={
                        state.editItem
                          ? state?.editItem?.editSection
                          : state.addItem?.editSection
                      }
                      contentType="tools"
                    />
                  ) : (
                    <></>
                  )}
                </>
              }
            />
          )}
        </AnimatePresence>
      </ContentContainer>
    </ContentMenuContainer>
  );
};

const SectionDiv = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 1.25rem 0.625rem 0.313rem 0.625rem;
  cursor: pointer;
`;

const ContentContainer = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.editMode ? "start" : "center")};
`;

const ContentMenuContainer = styled.div`
  position: relative;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 6rem);
  grid-gap: 1rem;
  justify-content: center;
`;

const StyledContentEditButton = styled(ContentEditButton)`
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.5);
  }
`;
