import firebase, { firestore } from "../firebase";
import { uploadImage } from "./serviceUtils";

const imageFileStoragePath = "images";
const imageCollectionRef = firestore.collection("images");

const toImage = (doc: any): Image => {
  const data = doc.data();
  return {
    id: doc.id,
    ...data,
  };
};
const updateSortOrder = (uploadImage: Image[]) => {
  const batch = firebase.firestore().batch();
  uploadImage.forEach(uploadImage => {
    const ref = imageCollectionRef.doc(uploadImage.id);
    batch.update(ref, { sortOrder: uploadImage.sortOrder, priority: "" });
  });
  return batch.commit();
};

const subscribe = (callback: (err: Error | null, images: Image[]) => void) =>
  imageCollectionRef.onSnapshot(
    snapshot => {
      const images = snapshot.docs.map(toImage);
      callback(null, images);
    },
    err => {
      console.log(err);
      callback(err, []);
    }
  );

const create = (data: Image) => imageCollectionRef.add(data);

const update = (id: string, data: Image) => {
  return imageCollectionRef.doc(id).update(data);
};

const deleteImage = (id: string) => imageCollectionRef.doc(id).delete();

const uploadImageImage = async (file: Blob | ArrayBuffer, fileName: string) => {
  const url = uploadImage(file, imageFileStoragePath, fileName);
  return url;
};

export const ImagesService = {
  subscribe,
  create,
  update,
  delete: deleteImage,
  uploadImageImage,
  updateSortOrder,
};
