import { firestore } from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { CountryCodeService } from "./CountryCodeService";

interface AppLink {
  appLinks?: {
    [screenName: string]: {
      externalUrl: string;
    };
  };
}

const appLinkContentDocRef = (countryCode: string) =>
  firestore.doc(CollectionPaths.AppLinks[countryCode]);

export class AppLinksService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  toAppLink = (doc: any): AppLink => {
    const appLinks = doc.appLinks;
    const appLink: AppLink = {
      appLinks: {},
    };
    if (appLinks) {
      Object.keys(appLinks).forEach(key => {
        appLink.appLinks[key] = {
          externalUrl: appLinks[key].externalUrl,
        };
      });
    }
    return appLink;
  };

  subscribe = (
    callback: (err: Error | null, appLinks: Record<string, AppLink>) => void
  ) => {
    return appLinkContentDocRef(this.countryCode).onSnapshot(
      doc => {
        if (doc.exists) {
          const data = doc.data();
          const appLinks = this.toAppLink(data);
          callback(null, { appLinks: appLinks });
        } else {
          callback(null, {});
        }
      },
      err => {
        console.error("Error fetching app links:", err);
        callback(err, {});
      }
    );
  };

  create = (data: AppLink) =>
    appLinkContentDocRef(this.countryCode).set(
      { appLinks: { ...data } },
      { merge: true }
    );

  update = async (screenName: string, externalUrl: string) => {
    return appLinkContentDocRef(this.countryCode).update({
      [`appLinks.${screenName}.externalUrl`]: externalUrl,
    });
  };

  delete = async (screenName: string) => {
    const docRef = appLinkContentDocRef(this.countryCode);
    const doc = await docRef.get();
    if (doc.exists) {
      const data = doc.data() as AppLink;
      if (data.appLinks && data.appLinks[screenName]) {
        delete data.appLinks[screenName];
        await docRef.update({ appLinks: data.appLinks });
      }
    }
  };
}
