import { SortOrder } from "antd/lib/table/interface";

const startsWithNumber = (str: any) => {
  return /^\d/.test(str);
};

const defaultSort = <T>(aa: T, bb: T) => {
  if (aa === bb) return 0;
  if (!aa) return 1;
  if (!bb) return -1;

  const a = aa?.toString();
  const b = bb?.toString();

  const aIsAlphabetical = a.localeCompare("a") >= 0,
    bIsAlphabetical = b.localeCompare("a") >= 0;

  if (startsWithNumber(a) && startsWithNumber(b)) {
    const diff =
      (a.charAt(0) as unknown as number) - (b.charAt(0) as unknown as number);

    return diff ? diff / Math.abs(diff) : 0;
  }
  if (!aIsAlphabetical && bIsAlphabetical) {
    return 1;
  }

  if (aIsAlphabetical && !bIsAlphabetical) {
    return -1;
  }

  if (!aIsAlphabetical && startsWithNumber(b)) {
    return 1;
  }

  if (!bIsAlphabetical && startsWithNumber(a)) {
    return -1;
  }

  return a.localeCompare(b, "sv");
};

const numberSort = <T>(a: T, b: T) => (a > b ? 1 : -1);

export const Sorter = {
  DEFAULT: defaultSort,
  NUMBER: numberSort,
};
