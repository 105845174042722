import { firestore } from "../firebase";
import { formatTimeStampToMoment } from "../utils";
import { CountryCodeService } from "./CountryCodeService";
import { uploadImage } from "./serviceUtils";
import { CollectionPaths } from "../helper/collections";

const appTakeOverFileStoragePath = "appTakeOver";
const appTakeOverCollectionRef = (countryCode: string) =>
  firestore.collection(CollectionPaths.AppTakeOver[countryCode]);

export class AppTakeOversService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  toAppTakeOver = (doc: any): AppTakeOver => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      translations: data.translations,
      startTime: formatTimeStampToMoment(data.startTime),
      endTime: formatTimeStampToMoment(data.endTime),
    };
  };

  subscribe = (
    callback: (err: Error | null, appTakeOvers: AppTakeOver[]) => void
  ) =>
    appTakeOverCollectionRef(this.countryCode).onSnapshot(
      snapshot => {
        const appTakeOvers = snapshot.docs.map(this.toAppTakeOver);
        callback(null, appTakeOvers);
      },
      err => {
        console.log(err);
        callback(err, []);
      }
    );

  create = (data: AppTakeOver) =>
    appTakeOverCollectionRef(this.countryCode).add(data);

  update = (id: string, data: AppTakeOver) => {
    return appTakeOverCollectionRef(this.countryCode).doc(id).update(data);
  };

  delete = (id: string) =>
    appTakeOverCollectionRef(this.countryCode).doc(id).delete();

  uploadAppTakeOverImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => {
    const url = uploadImage(file, appTakeOverFileStoragePath, fileName);
    return url;
  };
}
