import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  Row,
  Col,
  Typography,
  Form,
  DatePicker,
  Switch,
  Space,
  List,
  Divider,
} from "antd";
import { useAppTakeOver, useAppTakeOvers } from "../../context";
import {
  AspectRatio,
  AudienceType,
  DisplayUnitType,
  formatDateStringToTimeStamp,
  formValidationError,
  isEqualArrays,
  swapSortOrder,
} from "../../utils";
import { FormEdit, FormEditType } from "../../components";
import { DropAndCrop } from "../../components/DropAndCrop";
import { commonErrors } from "../../language";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  BJAudience,
  BJAudienceSchema,
  BJAudienceValues,
  BJInputFormItem,
  BJSortableList,
  SortProps,
} from "../../components/theme";
import moment from "moment";
import { cms } from "../../routes/routeConsts";
import { BJDeeplinkFormInput } from "../../components/theme/molecules/formItems/BJDeeplinkFormInput";
import { BJMdFormItem } from "../../components/theme/molecules/formItems/BJFormMarkdown";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";
import { UpdateInfoBoxItemModal } from "./UpdateInfoBoxItemModal";
import styled from "styled-components";
import { BJInputNumberFormItem } from "../../components/theme/molecules/formItems/BJInputNumberFormItem";
import { useCountry } from "../../context";

interface FormValues extends BJAudienceValues {
  translations: {
    [locale: string]: {
      title: string;
      subTitle?: string;
      description: string;
      ctaButtonText: string;
      disclaimer?: string;
      infoBox?: { title?: string; items: Item[] };
      imageUrl?: string;
    };
  };
  companyLogo: string;
  price: number;
  discountPrice: number;
  discount: number;
  isActive: boolean;
  deepLink: string;
  isEnableInfoBox: boolean;
  startEnd?: [any, any]; //design limitation for complex objects in react-hook forms...
  maxDisplayCount: number;
  frequency: number;
  isPriceEnabled: boolean;
  isDiscountPriceEnabled: boolean;
  isPercentageEnabled: boolean;
  isHero: boolean;
  isStrikeText: boolean;
}

const { requiredError, positiveNumberError, urlValidationError } = commonErrors;
const { RangePicker } = DatePicker;

export const AppTakeOverPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const { appTakeOver, loading: loadingAppTakeOvers } = useAppTakeOver(id);
  const { appTakeOvers, onUpdate, onCreate, onDelete, uploadAppTakeOverImage } =
    useAppTakeOvers();
  const { currentCountry, primaryLocale } = useCountry();

  const schema = yup.object().shape({
    maxDisplayCount: yup
      .number()
      .nullable()
      .required(`Max display count: ${requiredError}`)
      .test("Is positive", positiveNumberError, value => value > 0),
    frequency: yup.number().nullable().required(`Frequency: ${requiredError}`),
    deepLink: yup
      .string()
      .required(`CTA Deep link: ${requiredError}`)
      .url(`CTA Deep link: ${urlValidationError}`),
    isPriceEnabled: yup.boolean().nullable(),
    isDiscountPriceEnabled: yup.boolean().nullable(),
    isPercentageEnabled: yup.boolean().nullable(),

    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup
            .string()
            .required(
              `Title (${String(item.key).toUpperCase()}): ${requiredError}`
            ),
          ctaButtonText: yup
            .string()
            .required(
              `CTA Button Text (${String(
                item.key
              ).toUpperCase()}): ${requiredError}`
            ),
          description: yup
            .string()
            .required(
              `Description (${String(
                item.key
              ).toUpperCase()}): ${requiredError}`
            ),
          imageUrl: yup
            .string()
            .required(
              `AppTakeOver image (${String(
                item.key
              ).toUpperCase()}): ${requiredError}`
            )
            .url(
              `AppTakeOver image (${String(
                item.key
              ).toUpperCase()}): ${urlValidationError}`
            )
            .nullable(),
        });
        return acc;
      }, {} as any)
    ),
    discount: yup
      .number()
      .nullable()
      .when("isPercentageEnabled", {
        is: false,
        then: yup.number().nullable(),
        otherwise: yup
          .number()
          .nullable()
          .required(`Discount percentage: ${requiredError}`),
      }),
    price: yup
      .number()
      .nullable()
      .when("isPriceEnabled", {
        is: false,
        then: yup.number().nullable(),
        otherwise: yup.number().nullable().required(`Price: ${requiredError}`),
      }),
    discountPrice: yup
      .number()
      .nullable()
      .when("isDiscountPriceEnabled", {
        is: false,
        then: yup.number().nullable(),
        otherwise: yup
          .number()
          .nullable()
          .required(`Discount price: ${requiredError}`),
      }),
    ...BJAudienceSchema(false),
  });

  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      isDiscountPriceEnabled: false,
      isPercentageEnabled: false,
      isPriceEnabled: false,
      isEnableInfoBox: false,
    },
  });
  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    getValues,
  } = methods;

  const [isOrderMode, setIsOrderMode] = useState(false);
  const [showAddFeature, setShowAddFeature] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Item | null>(null);
  const [items, setFeatures] = useState<{ [locale: string]: Item[] }>({});
  //old items state is used for dirty checking comparisons
  const [prevItems, setPrevItems] = useState<{ [locale: string]: Item[] }>({});
  const [sortedFeatures, setSortedFeatures] = useState<Item[]>([]);

  useEffect(() => {
    if (loadingAppTakeOvers || appTakeOver === null) {
      return;
    }
    const startTime = appTakeOver.startTime
      ? moment(appTakeOver.startTime)
      : null;
    const endTime = appTakeOver.endTime ? moment(appTakeOver.endTime) : null;

    for (const [key, value] of Object.entries(appTakeOver.translations)) {
      const item = value.infoBox?.items ?? [];
      setFeatures(old => ({ ...old, [key]: item }));
      setPrevItems(old => ({ ...old, [key]: item }));
    }

    reset({
      ...appTakeOver,
      startEnd: [startTime, endTime],
      audience: appTakeOver.extra
        ? (appTakeOver.extra[0].audience as AudienceType)
        : null,
      rangeUnit: appTakeOver.extra
        ? (appTakeOver.extra[0].rangeUnit as DisplayUnitType)
        : null,
      range: appTakeOver.extra ? appTakeOver.extra[0].range : null,
    });
    setValue("isPriceEnabled", !!appTakeOver.price);
    setValue("isDiscountPriceEnabled", !!appTakeOver.discountPrice);
    setValue("isPercentageEnabled", !!appTakeOver.discount);
  }, [appTakeOver, reset, loadingAppTakeOvers, setValue]);

  const onSubmit = async (data: FormValues) => {
    const translations: AppTakeOver["translations"] = {};
    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        title: value.title,
        subTitle: value.subTitle ?? "",
        description: value.description,
        ctaButtonText: value.ctaButtonText,
        disclaimer: value.disclaimer ?? "",
        infoBox: {
          title: value?.infoBox?.title ?? "",
          items: items && items[key] ? items[key] : [],
        },
        imageUrl: value.imageUrl ?? "",
      };
    }

    const alreadyActiveModal = appTakeOvers.find(x => x.isActive);
    if (
      alreadyActiveModal &&
      alreadyActiveModal?.id !== appTakeOver?.id &&
      data.isActive
    ) {
      throw new Error(
        "Already another record is activated, disable it to activate this"
      );
    }
    const startTime = data.startEnd?.[0]
      ? formatDateStringToTimeStamp(data.startEnd?.[0].toString(), true)
      : null;
    const endTime = data.startEnd?.[1]
      ? formatDateStringToTimeStamp(data.startEnd?.[1].toString(), true)
      : null;

    const updateData: Omit<AppTakeOver, "id"> = {
      discount: data.discount,
      isActive: data.isActive,
      startTime: startTime ?? null,
      endTime: endTime ?? null,
      deepLink: data.deepLink,
      maxDisplayCount: data.maxDisplayCount,
      frequency: data.frequency,
      companyLogo: data.companyLogo,
      price: data.price,
      discountPrice: data.discountPrice,
      isEnableInfoBox: data.isEnableInfoBox,
      isHero: data.isHero,
      isStrikeText: data.isStrikeText,
      extra: [
        {
          range: data.range,
          rangeUnit: data.rangeUnit,
          audience: data.audience,
        },
      ],
      translations,
      // keep this until we force update 2.35.0 or latest
      imageUrl: data.translations[primaryLocale.key].imageUrl,
    };
    if (appTakeOver) {
      await onUpdate(appTakeOver.id, updateData);
    } else {
      const { id } = await onCreate(updateData);
      return navigate(`../${id}`);
    }
  };

  const onRemove = async () => {
    if (appTakeOver) {
      await onDelete(appTakeOver.id);
      navigate("./..", { replace: true });
    }
  };

  const handleAppTakeOverImageUrl = ({
    url,
    locale,
  }: {
    url: string | null;
    locale: string;
  }) => {
    const temp = { ...getValues("translations") };
    temp[locale] = {
      ...temp[locale],
      imageUrl: url,
    };
    setValue("translations", temp, { shouldDirty: true });
  };

  const handleCompanyLogoImageUrl = (url: string | null) => {
    setValue("companyLogo", url, { shouldDirty: true });
  };

  const copyToNotificationDeepLink = (link: string) => {
    setValue("deepLink", link, { shouldDirty: true });
  };

  const onSortEnd = async ({ oldIndex, newIndex }: SortProps) => {
    if (oldIndex === newIndex) {
      return;
    }
    const sortableContents = swapSortOrder(sortedFeatures, oldIndex, newIndex);
    setSortedFeatures(sortableContents);
  };

  const onAddTask = (task: Item, locale: Locale) => {
    const sortOrderNumericArray = items[locale.key].map(
      ({ sortOrder }) => sortOrder
    );
    const highestSortOrder = sortOrderNumericArray.length
      ? Math.max(...sortOrderNumericArray)
      : 0;
    task.sortOrder = highestSortOrder + 1;
    setFeatures(old => {
      const newFeature = [...old[locale.key], task];
      return { ...old, [locale.key]: newFeature };
    });
    setShowAddFeature(false);
  };

  const onUpdateTask = (task: Item, locale: Locale) => {
    setFeatures(old => ({
      ...old,
      [locale.key]: old[locale.key].map(t =>
        t?.sortOrder === task?.sortOrder ? task : t
      ),
    }));
    setSelectedTask(null);
  };

  const onSave = async (locale: Locale) => {
    setFeatures({ ...items, [locale.key]: sortedFeatures });
    setIsOrderMode(false);
  };

  const onCancel = () => {
    setIsOrderMode(false);
  };

  const onDeleteTask = (id: Item["sortOrder"], locale: Locale) => {
    setFeatures(old => ({
      ...old,
      [locale.key]: old[locale.key].filter(t => t?.sortOrder !== id),
    }));
    setSelectedTask(null);
  };

  const renderItems = (locale: Locale) => {
    return (
      <>
        {isOrderMode ? (
          <>
            <Space>
              <BJButton
                buttonType={ButtonTypes.Delete}
                size="small"
                onClick={onCancel}
              >
                Cancel
              </BJButton>

              <BJButton
                size="small"
                buttonType={ButtonTypes.Save}
                onClick={() => onSave(locale)}
              >
                Save
              </BJButton>
            </Space>

            <StyledDiv>
              <BJSortableList
                onSortEnd={onSortEnd}
                items={sortedFeatures
                  ?.sort((a, b) => a.sortOrder - b.sortOrder)
                  .map(x => ({
                    ...x,
                    id: x.sortOrder,
                  }))}
              />
            </StyledDiv>
          </>
        ) : (
          <>
            <div>
              <p>Features</p>
              <Space>
                <BJButton
                  size="small"
                  buttonType={ButtonTypes.Save}
                  onClick={() => {
                    setShowAddFeature(true);
                  }}
                >
                  Add
                </BJButton>
                {items[locale.key]?.length !== 0 ? (
                  <BJButton
                    size="small"
                    buttonType={ButtonTypes.Primary}
                    onClick={() => {
                      setIsOrderMode(true);
                      setSortedFeatures(items[locale.key]);
                    }}
                  >
                    Change order
                  </BJButton>
                ) : null}
              </Space>
            </div>
            <List>
              {items[locale.key] &&
                items[locale.key].map(item => (
                  <StyledListItem
                    onClick={() => {
                      setSelectedTask(item);
                      setShowAddFeature(true);
                    }}
                    key={item?.sortOrder}
                  >
                    {item?.title}
                  </StyledListItem>
                ))}
            </List>
          </>
        )}
      </>
    );
  };

  const isDirty =
    !!Object.keys(dirtyFields).length ||
    !isEqualArrays(Object.values(prevItems), Object.values(items));

  const appTakeOverTitle = watch(`translations.${primaryLocale.key}.title`);
  const frequencyKey = watch("frequency");

  const lengthOfField = {
    title: 60,
    subTitle: 150,
  };

  return (
    <FormProvider {...methods}>
      <FormEdit
        backRoutePath={`${cms}/${"appTakeOvers"}`}
        onRemove={onRemove}
        hasValidationErrors={Object.keys(errors).length !== 0}
        enableSave={isDirty}
        title={
          appTakeOver
            ? appTakeOver?.translations?.[primaryLocale.key]?.title
            : "New AppTakeOver"
        }
        id={appTakeOver?.id}
        editType={appTakeOver?.id ? FormEditType.EDIT : FormEditType.ADD}
        loading={loadingAppTakeOvers}
        onSubmit={handleSubmit(onSubmit, formValidationError)}
        recordIdentifier={appTakeOverTitle}
        localeSupported
        errors={errors as any}
      >
        {locale => (
          <>
            <Row gutter={{ md: 20 }}>
              <Col md={24} lg={12}>
                <BJInputFormItem
                  control={control}
                  error={!!errors?.translations?.[locale.key]?.title}
                  label={`Title (${locale.label}) ${
                    watch(`translations.${locale.key}.title`)?.length ?? 0
                  }/${lengthOfField.title}`}
                  fieldName={`translations.${locale.key}.title`}
                  key={`translations.${locale.key}.title`}
                  message={errors?.translations?.[locale.key]?.title?.message}
                  required={true}
                  autoFocus
                  maxLength={lengthOfField.title}
                />
                <BJInputFormItem
                  control={control}
                  error={!!errors?.translations?.[locale.key]?.subTitle}
                  label={`Sub Title (${locale.label}) ${
                    watch(`translations.${locale.key}.subTitle`)?.length ?? 0
                  }/${lengthOfField.subTitle}`}
                  message={
                    errors?.translations?.[locale.key]?.subTitle?.message
                  }
                  autoFocus
                  fieldName={`translations.${locale.key}.subTitle`}
                  key={`translations.${locale.key}.subTitle`}
                  maxLength={lengthOfField.subTitle}
                />
                <BJMdFormItem
                  control={control}
                  error={!!errors?.translations?.[locale.key]?.description}
                  label={`Description (${locale.label})`}
                  message={
                    errors?.translations?.[locale.key]?.description?.message
                  }
                  required={true}
                  fieldName={`translations.${locale.key}.description`}
                  key={`translations.${locale.key}.description`}
                />
                <BJInputFormItem
                  control={control}
                  error={!!errors?.translations?.[locale.key]?.ctaButtonText}
                  label={`CTA button text (${locale.label})`}
                  message={
                    errors?.translations?.[locale.key]?.ctaButtonText?.message
                  }
                  autoFocus
                  fieldName={`translations.${locale.key}.ctaButtonText`}
                  key={`translations.${locale.key}.ctaButtonText`}
                  required={true}
                />
                <BJInputFormItem
                  control={control}
                  error={!!errors?.translations?.[locale.key]?.disclaimer}
                  label={`Disclaimer (${locale.label})`}
                  message={
                    errors?.translations?.[locale.key]?.disclaimer?.message
                  }
                  autoFocus
                  fieldName={`translations.${locale.key}.disclaimer`}
                  key={`translations.${locale.key}.disclaimer`}
                />
                <BJDeeplinkFormInput
                  control={control}
                  error={!!errors.deepLink}
                  label={"CTA Deep link"}
                  message={errors.deepLink?.message}
                  required
                  fieldName={"deepLink"}
                  title="AppTakeOver"
                  copyToNotificationDeepLink={copyToNotificationDeepLink}
                />
                <Form.Item
                  required
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span style={{ marginRight: "10px" }}>
                        {`AppTakeOver image ${
                          locale.label ? `(${locale.label})` : ""
                        }`}
                      </span>

                      <span style={{ marginLeft: "25px" }}>
                        <Controller
                          control={control}
                          name="isHero"
                          render={({ field: { onChange, value } }) => (
                            <Switch onChange={onChange} checked={value} />
                          )}
                        />
                        Is Hero
                      </span>
                    </div>
                  }
                  extra=""
                  validateStatus={
                    errors?.translations &&
                    errors?.translations?.[locale.key]?.imageUrl &&
                    "error"
                  }
                  help={
                    errors?.translations &&
                    errors.translations[locale?.key]?.imageUrl && (
                      <Typography.Paragraph type="danger">
                        {errors.translations[locale.key].imageUrl.message}
                      </Typography.Paragraph>
                    )
                  }
                >
                  <DropAndCrop
                    extra="Please upload a image with size of 3:2 ratio"
                    title={`AppTakeOver image ${
                      locale.label ? `(${locale.label})` : ""
                    }`}
                    initialUrl={
                      watch("translations")?.[locale.key]?.imageUrl ?? ""
                    }
                    setUploadUrl={(url: string) =>
                      handleAppTakeOverImageUrl({
                        url,
                        locale: locale.key,
                      })
                    }
                    uploadImage={uploadAppTakeOverImage}
                    lockedRatio={AspectRatio.ThreeToTwo}
                    defaultCropBoxWidth={300}
                    defaultCropBoxHeight={200}
                    croppable={true}
                  />
                </Form.Item>
                <Form.Item
                  label="Company logo"
                  extra=""
                  validateStatus={errors.companyLogo && "error"}
                  help={
                    <Typography.Paragraph type="danger">
                      {errors.companyLogo?.message}
                    </Typography.Paragraph>
                  }
                >
                  <DropAndCrop
                    title="Company logo"
                    extra="Please upload a  image with size of 3:1 ratio"
                    initialUrl={appTakeOver?.companyLogo}
                    setUploadUrl={handleCompanyLogoImageUrl}
                    uploadImage={uploadAppTakeOverImage}
                    lockedRatio={AspectRatio.FourToOne}
                    defaultCropBoxWidth={300}
                    defaultCropBoxHeight={100}
                    croppable={true}
                  />
                </Form.Item>
              </Col>
              <Col md={24} lg={12}>
                <Form.Item label="Start | End" key="startEnd" required>
                  <Controller
                    name="startEnd"
                    control={control}
                    defaultValue={[
                      moment(Date.now()),
                      moment(Date.now()).add(1, "month"),
                    ]}
                    render={({ field: { onChange, value } }) => (
                      <RangePicker
                        format={"YYYY-MM-DD"}
                        defaultValue={[moment(Date.now()), moment(Date.now())]}
                        value={value}
                        onChange={onChange}
                        size={"large"}
                      />
                    )}
                  />
                </Form.Item>
                <StyledContainer>
                  <BJInputNumberFormItem
                    control={control}
                    error={!!errors?.maxDisplayCount}
                    label={"Max display count"}
                    message={errors.maxDisplayCount?.message}
                    required={true}
                    min={1}
                    autoFocus
                    showInfo
                    extra={
                      "Maximum number of modal displays with in start and end time"
                    }
                    precision={0}
                    fieldName={"maxDisplayCount"}
                  />
                  <BJInputNumberFormItem
                    key={frequencyKey}
                    control={control}
                    showInfo
                    isDecimal={false}
                    min={0}
                    error={!!errors?.frequency}
                    extra={"How frequent a user sees the modal (in days)"}
                    label={"Frequency (in days)"}
                    precision={0}
                    message={errors.frequency?.message}
                    required={true}
                    autoFocus
                    fieldName={"frequency"}
                  />
                </StyledContainer>
                <StyledContainer>
                  <Form.Item label="Display Price" key="isPriceEnabled">
                    <Controller
                      control={control}
                      name="isPriceEnabled"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Switch
                            onChange={e => {
                              onChange(e);
                              setValue("price", null);
                            }}
                            checked={value}
                            defaultChecked={!!appTakeOver?.price}
                          />
                        );
                      }}
                    />
                  </Form.Item>

                  {watch("isPriceEnabled") && (
                    <>
                      <BJInputNumberFormItem
                        control={control}
                        error={!!errors?.price}
                        label={"Price (KR)"}
                        message={errors.price?.message}
                        autoFocus
                        fieldName={"price"}
                      />
                    </>
                  )}
                  {watch("isPriceEnabled") && (
                    <>
                      <Form.Item label="Strikethrough" key="isStrikeText">
                        <Controller
                          control={control}
                          name="isStrikeText"
                          render={({ field: { onChange, value } }) => (
                            <Switch onChange={onChange} checked={value} />
                          )}
                        />
                      </Form.Item>
                    </>
                  )}
                </StyledContainer>

                <StyledContainer>
                  <Form.Item
                    label="Display Discount Price"
                    key="isDiscountPriceEnabled"
                  >
                    <Controller
                      control={control}
                      name="isDiscountPriceEnabled"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Switch
                            onChange={e => {
                              onChange(e);
                              setValue("discountPrice", null);
                            }}
                            checked={value}
                            defaultChecked={!!appTakeOver?.discountPrice}
                          />
                        );
                      }}
                    />
                  </Form.Item>

                  {watch("isDiscountPriceEnabled") && (
                    <>
                      <BJInputNumberFormItem
                        control={control}
                        error={!!errors?.discountPrice}
                        label={"Discount Price (KR)"}
                        message={errors.discountPrice?.message}
                        autoFocus
                        fieldName={"discountPrice"}
                      />
                    </>
                  )}
                </StyledContainer>

                <StyledContainer>
                  <Form.Item
                    label="Display Percentage"
                    key="isPercentageEnabled"
                  >
                    <Controller
                      control={control}
                      name="isPercentageEnabled"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Switch
                            onChange={e => {
                              onChange(e);
                              setValue("discount", null);
                            }}
                            checked={value}
                            defaultChecked={!!appTakeOver?.discount}
                          />
                        );
                      }}
                    />
                  </Form.Item>
                  {watch("isPercentageEnabled") && (
                    <>
                      <BJInputNumberFormItem
                        control={control}
                        isPercentage
                        error={!!errors?.discount}
                        label={"Discount Percentage"}
                        message={errors.discount?.message}
                        autoFocus
                        fieldName={"discount"}
                      />
                    </>
                  )}
                </StyledContainer>

                <Form.Item label="Active" key="isActive">
                  <Controller
                    control={control}
                    name="isActive"
                    render={({ field: { onChange, value } }) => (
                      <Switch onChange={onChange} checked={value} />
                    )}
                  />
                </Form.Item>

                <Form.Item label="Enable Info Box" key="isEnableInfoBox">
                  <Controller
                    control={control}
                    name="isEnableInfoBox"
                    render={({ field: { onChange, value } }) => (
                      <Switch onChange={onChange} checked={value} />
                    )}
                  />
                </Form.Item>
                {watch("isEnableInfoBox") && (
                  <>
                    <Divider>Info box</Divider>
                    <BJInputFormItem
                      control={control}
                      error={
                        !!errors?.translations?.[locale.key]?.infoBox?.title
                      }
                      label={`Title (${locale.label})`}
                      message={
                        errors?.translations?.[locale.key]?.infoBox?.title
                          ?.message
                      }
                      autoFocus
                      key={`translations.${locale.key}.infoBox.title`}
                      fieldName={`translations.${locale.key}.infoBox.title`}
                    />
                    {renderItems(locale)}
                  </>
                )}
                <BJAudience showAlert={true} />
              </Col>
            </Row>
            <UpdateInfoBoxItemModal
              locale={locale}
              onAdd={onAddTask}
              show={selectedTask !== null || showAddFeature}
              task={selectedTask}
              onDelete={onDeleteTask}
              onHide={() => {
                setSelectedTask(null);
                setShowAddFeature(false);
              }}
              onUpdate={onUpdateTask}
            />
          </>
        )}
      </FormEdit>
    </FormProvider>
  );
};

const StyledListItem = styled(List.Item)`
  cursor: pointer;
`;

const StyledDiv = styled.div`
  padding-top: 1rem;
`;
const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 2rem;
`;
