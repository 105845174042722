import { useEffect, useState } from "react";
import { useHighlights } from "../../context";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";
import BJList from "../../components/theme/components/BJList";
import { HighlightModal } from ".";

export const HighlightListPage = () => {
  const { highlights, loading } = useHighlights();
  const [originalTable, setOriginalTable] = useState<Highlight[]>([]);
  const [showAddCategoryModal, setShowAddHighlightModal] = useState(false);
  const [selectedHighlight, setSelectedHighlight] = useState<Highlight | null>(
    null
  );
  useEffect(() => {
    const highlightTableSource: Highlight[] = highlights.map(highlight => ({
      ...highlight,
      key: highlight.id,
      id: highlight.id,
    }));
    setOriginalTable(highlightTableSource);
  }, [highlights]);

  const onClickRow = (record: Highlight) => {
    return {
      onClick: () => {
        setSelectedHighlight(record);
        setShowAddHighlightModal(true);
      },
    };
  };

  const handleNewHighlight = () => {
    setShowAddHighlightModal(true);
  };

  const columns: BJColumnType<Highlight>[] = [
    {
      title: "Version Id",
      dataIndex: "id",
      key: "id",
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 4,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  return (
    <>
      <BJList
        loading={loading}
        filterOnlyDisplayList
        addButtonCaption={"New Highlight"}
        title={"Highlights"}
        OriginalList={originalTable}
        columns={columns}
        onClickRow={onClickRow}
        onclick={handleNewHighlight}
        recordCountSuffix={"Highlights"}
      />
      <HighlightModal
        showModal={showAddCategoryModal}
        onHide={() => {
          setSelectedHighlight(null);
          setShowAddHighlightModal(false);
        }}
        highlight={selectedHighlight}
      ></HighlightModal>
    </>
  );
};
