import { BJColumnsType } from "../../components/theme/molecules/BJTable";
import { useNavigate } from "react-router-dom";
import { useContents } from "../../context";
import BJList from "../../components/theme/components/BJList";
import { Sorter } from "../../components/theme/util/sorter";
import { newRoute } from "../../routes";
import { firebaseCollectionNames } from "../../utils";

export const ContentList = () => {
  const navigate = useNavigate();
  const { contents, loading } = useContents();

  const onClickRow = (record: ContentItemType) => {
    return {
      onClick: () => {
        navigate(record.id.toString());
      },
    };
  };

  const handleNewContent = () => {
    navigate(newRoute);
  };

  const columns: BJColumnsType<ContentItemType> = [
    {
      title: "Name",
      dataIndex: "originalName",
      key: "id",
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  return (
    <BJList
      loading={loading}
      filterOnlyDisplayList
      addButtonCaption={"New Content"}
      title={"Content"}
      OriginalList={contents
        .filter(x => x.id !== firebaseCollectionNames.content.pregnancyTipsSub)
        .map(x => ({ ...x, key: x.id }))}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewContent}
      recordCountSuffix={"Content"}
    />
  );
};
