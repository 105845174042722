import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ImagesService } from "../services";

type ContextState = {
  images: Image[];
  loading: boolean;
  error: Error | null;
};

const ImagesContext = createContext<ContextState>({
  images: [],
  loading: false,
  error: null,
});

export const ImagesProvider = ({ ...rest }) => {
  const [images, setImages] = useState<Image[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);

    const unsub = ImagesService.subscribe((_error, _images) => {
      setImages(_images);
      setError(_error);
      setLoading(false);
    });

    return unsub;
  }, []);

  const value = useMemo(
    () => ({ images, loading, error }),
    [images, loading, error]
  );

  return <ImagesContext.Provider value={value} {...rest} />;
};

export const useImages = () => {
  const context = React.useContext(ImagesContext);
  if (context === undefined) {
    throw new Error("useImages must be used within an imagesProvider");
  }
  return context;
};

export const useImage = (imageId: Image["id"]) => {
  const [image, setImage] = useState<Image | null>(null);
  const [loading, setLoading] = useState(true);

  const { images, loading: loadingImages } = useImages();

  const getById = useCallback(
    (id: Image["id"]) => {
      const image = images.find(o => o.id === id);
      return image || null;
    },
    [images]
  );

  useEffect(() => {
    if (loadingImages) {
      return;
    }

    setImage(getById(imageId));
    setLoading(false);
  }, [loadingImages, getById, imageId]);

  return { image, loading };
};
