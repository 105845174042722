import { Modal } from "antd";
import { DeepLink } from "../pages/DeepLink";

type BJMainDeepLinkModalProps = {
  isVisible: boolean;
  modalActions: { hide: () => void; show: () => void };
  copyToHOC: (link: string) => void;
  copyContentIdToHOC?: (contentId: string) => void;
  title?: string;
};

export const BJMainDeepLinkModal = ({
  copyToHOC,
  copyContentIdToHOC,
  isVisible,
  modalActions,
  title,
}: BJMainDeepLinkModalProps) => {
  return (
    <Modal
      visible={isVisible}
      width={window.screen.width}
      centered
      onCancel={() => modalActions.hide()}
      footer={null}
    >
      <DeepLink
        fromHOC={{ isVisible: true, title: title ?? "Notifications" }}
        copyToHOC={copyToHOC}
        copyContentIdToHOC={copyContentIdToHOC}
      />
    </Modal>
  );
};
