import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBanners } from "../../context";
import { Sorter } from "../../components/theme/util/sorter";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import BJList from "../../components/theme/components/BJList";
import { changeorder, newRoute } from "../../routes";
import { DatePicker, Switch } from "antd";
import { isCurrentTimeWithinRange } from "../../utils/timeUtils";
import { ScreenInMobileToReadable } from "../../helper/enum";

type datasourceType = Banner & { isVisible?: boolean };

export const BannersListPage = () => {
  const navigate = useNavigate();
  const { banners, loading } = useBanners();
  const [originalTable, setOriginalTable] = useState<datasourceType[]>([]);

  useEffect(() => {
    const bannerTableSource: datasourceType[] = banners
      .sort((a: Banner, b: Banner) => a.sortOrder - b.sortOrder)
      .map(banner => ({
        ...banner,
        isVisible: isBannerVisibleInMobile(banner),
        key: banner.id,
      }));

    setOriginalTable(bannerTableSource);
  }, [banners]);

  const isBannerVisibleInMobile = (banner: Banner) => {
    return (
      banner.isActive &&
      isCurrentTimeWithinRange(banner.startTime, banner.endTime)
    );
  };

  const handleNewOffer = () => {
    navigate(newRoute);
  };

  const onClickRow = (record: datasourceType) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const columns: BJColumnType<datasourceType>[] = [
    {
      title: "Internal name",
      dataIndex: "internalName",
      key: "internalName",
      width: 1,
      ellipsis: true,
      onFilter: (value, record) =>
        record.internalName.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      width: 0.5,
      ellipsis: true,
      onFilter: (value, record) => record.startTime.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (text, record) => {
        return (
          <>
            {record.startTime ? (
              <DatePicker
                disabled={true}
                format={"YYYY-MM-DD HH:mm"}
                showTime
                value={record.startTime}
              />
            ) : (
              <span style={{ justifySelf: "center" }}>Time not selected</span>
            )}
          </>
        );
      },
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      width: 0.5,
      ellipsis: true,
      onFilter: (value, record) => record.endTime.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (text, record) => {
        return (
          <>
            {record.endTime ? (
              <DatePicker
                disabled={true}
                format={"YYYY-MM-DD HH:mm"}
                showTime
                value={record.endTime}
              />
            ) : (
              <span style={{ justifySelf: "center" }}>Time not selected</span>
            )}
          </>
        );
      },
    },
    {
      title: "Deep link",
      dataIndex: "deepLink",
      key: "deepLink",
      width: 1,
      ellipsis: true,
      onFilter: (value, record) =>
        record.internalName.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Visible Screens",
      dataIndex: "screens",
      key: "screens",
      width: 0.5,
      ellipsis: true,
      render: (text, record) => {
        return (
          <>
            {record.screens ? (
              <span style={{ justifySelf: "center" }}>
                {record.screens
                  .map(({ screen }) => ScreenInMobileToReadable(screen))
                  .join(",")}
              </span>
            ) : (
              <span style={{ justifySelf: "center" }}>Time not selected</span>
            )}
          </>
        );
      },
    },
    {
      title: "Visible in mobile",
      dataIndex: "isVisible",
      key: "isVisible",
      width: 0.5,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (value: boolean) => (
        <Switch checked={value} size="small" disabled />
      ),
    },
  ];

  return (
    <BJList
      onChangeOrder={() => {
        navigate(changeorder);
      }}
      loading={loading}
      filterOnlyDisplayList
      addButtonCaption={"New Banner"}
      title={"Banner"}
      OriginalList={originalTable}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewOffer}
      recordCountSuffix={"Banners"}
    />
  );
};
