import { Col, Row, Form, Select, Switch, Input, Typography, Space } from "antd";
import { useEffect } from "react";
import BJInput from "../../../components/theme/atoms/BJInput";
import { appIcons } from "../../../data/app-icons";
import { Controller, useForm } from "react-hook-form";
import { FormModal } from "../../../components";
import { commonErrors } from "../../../language";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formValidationError } from "../../../utils";
import { useCountry, useFAQ } from "../../../context";
import { v4 as uuidv4 } from "uuid";
import { BJSelectFormItem } from "../../../components/theme";
import { BJSelectFormItemLevel } from "../../../components/theme/molecules/formItems/BJFormSelectItemLevel";

const templates = [
  "Health",
  "Birth",
  "Exercise",
  "Baby",
  "BreastFeeding",
  "Pregnancy",
];

const options = [
  { value: 0, key: "0", display: "0" },
  { value: 1, key: "1", display: "1" },
  { value: 2, key: "2", display: "2" },
  { value: 3, key: "3", display: "3" },
  { value: 4, key: "4", display: "4" },
];

interface Props {
  show: boolean;
  onHide: () => void;
  category?: FaqCategory | null;
}

type FormValues = {
  template: FaqCategory["template"];
  icon: string;
  translations: {
    [locale: string]: {
      title: string;
      description?: string;
    };
  };
  show: boolean;
  restricted: boolean;
  level?: number;
};

const FAQ_CATEGORY_TYPES = ["pregnancy", "child"] as const;

export const FaqCategoryModal = ({
  show: showModal,
  onHide,
  category,
}: Props) => {
  const { categories, questions, loading, updateFAQCategory } = useFAQ();
  const { currentCountry, primaryLocale } = useCountry();

  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup
            .string()
            .required(
              `Title (${String(item.key).toUpperCase()}): ${
                commonErrors.requiredError
              }`
            ),
        });
        return acc;
      }, {} as any)
    ),
    level: yup.number().when("restricted", {
      is: true,
      then: schema => schema.required(`Level ${commonErrors.requiredError2}`),
      otherwise: schema => schema.optional(),
    }),
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (loading) return;
    reset({
      template: category?.template ?? "Birth",
      icon: category?.icon ?? "",
      translations: category?.translations,
      show: category?.show,
      restricted: category?.restricted,
      level: category?.level,
    });
  }, [category, reset, showModal, loading]);

  const onSubmit = async (data: FormValues) => {
    const { icon, show, template, restricted, level } = data;

    const translations: FaqCategory["translations"] = {};

    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        title: value.title?.trim(),
        description: value.description ?? "",
      };
    }

    const payload = {
      id: category ? category.id : uuidv4(),
      icon: icon,
      show: show ?? false,
      template: template,
      sortOrder: 0,
      translations,
      restricted: restricted ?? false,
      level: level ?? 0,
    };

    if (category) {
      const x: FaqCategory[] = categories?.map(x => {
        if (x.id === category.id) {
          return payload;
        }
        return x;
      });
      await updateFAQCategory(x);
    } else {
      const mapped = categories ? [...categories, payload] : [payload];
      await updateFAQCategory(mapped);
    }
  };

  const onDelete = async () => {
    if (category !== null) {
      if (questions?.find(x => x.categoryId === category.id)) {
        throw new Error("Some Faq's are referencing this category");
      }
      //must restrict deleting if faq's are referencing the category
      const filteredItems = categories.filter(x => x.id !== category.id);
      await updateFAQCategory(filteredItems);
    } else {
      throw new Error("Category not found");
    }
  };

  const isDirty = !!Object.keys(formState.dirtyFields).length;
  const title = watch(`translations.${primaryLocale.key}.title`);

  const restricted = watch("restricted");

  return (
    <FormModal
      onHide={onHide}
      enableSave={isDirty}
      show={showModal}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      onDelete={onDelete}
      enableDelete={!!category}
      modalSubTitle={category ? `ID - ${category?.id}` : ""}
      modalTitle={
        category
          ? category?.translations?.[primaryLocale.key]?.title
          : "Add new category"
      }
      recordIdentifier={title}
      localeSupported
      errors={errors as any}
    >
      {locale => (
        <>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item label="Template">
                <Controller
                  control={control}
                  name="template"
                  render={({ field: { onChange, value } }) => (
                    <Select onChange={onChange} value={value}>
                      <Select.Option value="">-</Select.Option>
                      {templates.map(template => (
                        <Select.Option value={template} key={template}>
                          {template}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Icon"
                required
                help={
                  errors.icon && (
                    <Typography.Text type="danger">
                      {errors?.icon?.message}
                    </Typography.Text>
                  )
                }
              >
                <Controller
                  control={control}
                  name="icon"
                  render={({ field: { onChange, value } }) => (
                    <Select onChange={onChange} value={value}>
                      <Select.Option value="">-</Select.Option>
                      {appIcons
                        .sort((a, b) => a.localeCompare(b))
                        .map(icon => (
                          <Select.Option value={icon} key={icon}>
                            {icon}
                          </Select.Option>
                        ))}
                    </Select>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={`Title (${locale?.label ?? ""})`}
            required
            extra={
              <Typography.Paragraph type="danger">
                {errors?.translations?.[locale?.key]?.title?.message}
              </Typography.Paragraph>
            }
          >
            <Controller
              control={control}
              name={`translations.${locale?.key}.title`}
              key={`translations.${locale?.key}.title`}
              render={({ field: { onChange, value } }) => (
                <BJInput onChange={onChange} value={value} />
              )}
            />
          </Form.Item>
          <Form.Item label={`Description (${locale?.label ?? ""})`}>
            <Controller
              control={control}
              name={`translations.${locale?.key}.description`}
              key={`translations.${locale?.key}.description`}
              render={({ field: { onChange, value } }) => (
                <Input.TextArea rows={4} onChange={onChange} value={value} />
              )}
            />
          </Form.Item>
          <Row>
            <Space size="large">
              {/* visible variable has no meaning in the faq context, But this might be utilized as similar to article categories.
          Thus keeping it here. */}
              {/* <Form.Item label="Visible">
            <Controller
              control={control}
              name="show"
              render={({ field: { onChange, value } }) => (
                <Switch checked={value} onChange={onChange} />
              )}
            />
          </Form.Item> */}
              <Form.Item label="Restricted">
                <Controller
                  control={control}
                  name="restricted"
                  render={({ field: { onChange, value } }) => (
                    <Switch checked={value} onChange={onChange} />
                  )}
                />
              </Form.Item>
              {restricted && (
                <BJSelectFormItemLevel
                  control={control}
                  error={!!errors?.level}
                  message={errors?.level?.message}
                  fieldName="level"
                />
              )}
            </Space>
          </Row>
        </>
      )}
    </FormModal>
  );
};
