import { createContext, useEffect, useMemo, useState, useContext } from "react";
import { TipsService } from "../services";
import { useCountry } from "./CountryContext";

type ContextState = {
  pregnancyTips: Tips[];
  childTips: Tips[];
  loadingPregnancyTips: boolean;
  loadingChildTips: boolean;
  error: Error | null;
  updatePregnancyTips: (tips: Tips[]) => void;
  updateChildTips: (tips: Tips[]) => void;
  uploadTipsImage: (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => Promise<string>;
};

const TipsContext = createContext<ContextState>({
  pregnancyTips: [],
  childTips: [],
  loadingPregnancyTips: false,
  loadingChildTips: false,
  error: null,
  updatePregnancyTips: null,
  updateChildTips: null,
  uploadTipsImage: null,
});

export const TipsProvider = ({ ...rest }) => {
  const [pregnancyTips, setPregnancyTips] = useState<Tips[]>([]);
  const [childTips, setChildTips] = useState<Tips[]>([]);
  const [loadingPregnancyTips, setLoadingPregnancyTips] = useState(true);
  const [loadingChildTips, setLoadingloadingChildTips] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const tipsService = useMemo(() => {
    return currentCountry?.abb ? new TipsService(currentCountry.abb) : null;
  }, [currentCountry]);

  useEffect(() => {
    setLoadingPregnancyTips(true);
    setLoadingloadingChildTips(true);
    const unsubscribeChildTips = tipsService?.subscribeToChildTips(
      (_error, _tips) => {
        setChildTips(_tips);
        setError(_error);
        setLoadingloadingChildTips(false);
      }
    );
    const unsubscribePregnancyTips = tipsService?.subscribeToPregnancyTips(
      (_error, _tips) => {
        setPregnancyTips(_tips);
        setError(_error);
        setLoadingPregnancyTips(false);
      }
    );
    return () => {
      unsubscribeChildTips?.();
      unsubscribePregnancyTips?.();
    };
  }, [tipsService]);

  const value = useMemo(
    () => ({
      pregnancyTips,
      loadingPregnancyTips,
      error,
      childTips,
      loadingChildTips,
      updatePregnancyTips: tipsService?.updatePregnancyTips,
      updateChildTips: tipsService?.updateChildTips,
      uploadTipsImage: tipsService?.uploadTipsImage,
    }),
    [
      childTips,
      error,
      loadingChildTips,
      loadingPregnancyTips,
      pregnancyTips,
      tipsService?.updateChildTips,
      tipsService?.updatePregnancyTips,
      tipsService?.uploadTipsImage,
    ]
  );

  return <TipsContext.Provider value={value} {...rest} />;
};

export const useTipsNew = () => {
  const context = useContext(TipsContext);
  if (context === undefined) {
    throw new Error("useF must be used within an TipsProvider");
  }
  return context;
};
