import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Sorter } from "../../components/theme/util/sorter";
import BJList from "../../components/theme/components/BJList";
import { useCountry, useFAQ } from "../../context";
import { newRoute } from "../../routes";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { Switch } from "antd";
import { DeepLinkType } from "../../utils";
import { DeepLinkForList } from "../../components/theme";

interface datasourceType extends FullQuestion {
  categoryTitle: string | undefined;
  restricted: boolean;
  deepLink?: DeepLinkProps;
}

export const FAQListPage = () => {
  const navigate = useNavigate();
  const { questions, loading } = useFAQ();
  const [originalTable, setOriginalTable] = useState<datasourceType[]>([]);
  const { currentCountry, primaryLocale } = useCountry();

  useEffect(() => {
    const articleTableSource: datasourceType[] = questions.map(question => ({
      ...question,
      key: question.id,
      categoryTitle:
        question?.category?.translations?.[primaryLocale.key]?.title,
      restricted: question?.restricted,
      sortOrder: question?.sortOrder,
      deepLink: {
        type: DeepLinkType.FAQ,
        id: question?.id,
        countryCode: currentCountry?.abb,
      },
      question: question?.translations?.[primaryLocale.key]?.question,
    }));
    setOriginalTable(articleTableSource);
  }, [questions, primaryLocale.key, currentCountry?.abb]);

  const onClickRow = (record: datasourceType) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const handleNewRecord = () => {
    navigate(newRoute);
  };

  const columns: BJColumnType<datasourceType>[] = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      width: 2,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Category",
      dataIndex: "categoryTitle",
      key: "category",
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Restricted",
      dataIndex: "restricted",
      key: "restricted",
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (value: boolean) => (
        <Switch checked={value} disabled size="small" />
      ),
    },
    {
      title: "Sort order",
      dataIndex: "sortOrder",
      key: "sortOrder",
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    DeepLinkForList(),
  ];

  const downloadData = () => {
    const csvData = [["Id", "Question", "Answer"]];
    questions.forEach(t => {
      csvData.push([
        t.id.toString(),
        t.translations[primaryLocale?.key]?.question.replace(/"/g, '""'),
        t.translations[primaryLocale?.key]?.answer?.replace(/"/g, '""'),
      ]);
    });
    return csvData;
  };

  return (
    <BJList
      loading={loading}
      filterOnlyDisplayList
      addButtonCaption={"New question"}
      title={"FAQ"}
      OriginalList={originalTable}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewRecord}
      recordCountSuffix={"FAQ"}
      downloadData={downloadData()}
    />
  );
};
