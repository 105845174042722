import { useEffect, useState } from "react";
import { Sorter } from "../../components/theme/util/sorter";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import BJList from "../../components/theme/components/BJList";
import { useCountry, useMissions } from "../../context";
import { MissionModal } from "./MissionModal";
import { changeorder } from "../../routes";
import { useNavigate } from "react-router-dom";

export const MissionsListPage = () => {
  const navigate = useNavigate();
  const { missions, loading } = useMissions();
  const [originalTable, setOriginalTable] = useState<Mission[]>([]);
  const { primaryLocale } = useCountry();
  const [showAddMissionModal, setShowAddMissionModal] = useState(false);
  const [selectedMission, setSelectedMission] = useState<Mission | null>(null);

  useEffect(() => {
    const missionsTableSource: Mission[] = missions.map(mission => ({
      ...mission,
      title: mission.title,
    }));

    setOriginalTable(missionsTableSource);
  }, [missions, primaryLocale.key]);

  const onClickRow = (record: Mission) => {
    return {
      onClick: () => {
        setSelectedMission(record);
      },
    };
  };

  const columns: BJColumnType<Mission>[] = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Subledger",
      dataIndex: "subledger",
      key: "subledger",
      width: 1,
      ellipsis: true,
      onFilter: (value, record) => record.subledger.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
      filters: originalTable.map(data => ({
        text: data.subledger,
        value: data.subledger,
      })),
    },
  ];

  return (
    <>
      <BJList
        filterOnlyDisplayList
        showSizeChanger
        loading={loading}
        addButtonCaption={"New mission"}
        title={"Missions"}
        OriginalList={originalTable}
        columns={columns}
        onClickRow={onClickRow}
        onclick={() => {
          setShowAddMissionModal(true);
        }}
        recordCountSuffix={"missions"}
        onChangeOrder={() => {
          navigate(changeorder);
        }}
      />
      <MissionModal
        mission={selectedMission}
        show={showAddMissionModal || selectedMission !== null}
        onHide={() => {
          setSelectedMission(null);
          setShowAddMissionModal(false);
        }}
      />
    </>
  );
};
