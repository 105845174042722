import { useEffect, useState } from "react";
import { useCountry, useFAQ } from "../../../context";
import { FaqCategoryModal } from "./FaqCategoryModal";
import BJList from "../../../components/theme/components/BJList";
import { BJColumnsType } from "../../../components/theme/molecules/BJTable";
import { Sorter } from "../../../components/theme/util/sorter";
import { Switch } from "antd";
import { capitalizeFirstLetter } from "../../../utils";

interface datasourceType extends FaqCategory {
  [titleWithLocale: string]:
    | string
    | undefined
    | DeepLinkProps
    | FaqCategory[keyof FaqCategory];
}

export const FaqCategoryList = () => {
  const { categories } = useFAQ();
  const { currentCountry } = useCountry();
  const [originalTable, setOriginalTable] = useState<datasourceType[]>([]);

  useEffect(() => {
    const categoryTableSource: datasourceType[] = categories?.map(category => ({
      ...category,
      ...Object.entries(category?.translations).reduce(
        (acc, [locale, values]) => (
          (acc[`title${capitalizeFirstLetter(locale)}`] = values?.title), acc
        ),
        {} as any
      ),
      key: category?.id,
    }));
    setOriginalTable(categoryTableSource);
  }, [categories]);

  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<FaqCategory | null>(
    null
  );

  const columns: BJColumnsType<datasourceType> = [
    ...currentCountry?.locales?.map(locale => ({
      title: `Name (${locale?.label})`,
      dataIndex: `title${capitalizeFirstLetter(locale?.key)}`,
      key: `title${capitalizeFirstLetter(locale?.key)}`,
      width: 1,
      ellipsis: true,
      onFilter: (value: string | number | boolean, record: datasourceType) =>
        record.id.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    })),
    {
      title: "Restricted",
      dataIndex: "restricted",
      key: "restricted",
      width: 0.5,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (value: boolean) => (
        <Switch checked={value} size="small" disabled />
      ),
    },
  ];

  const onClickRow = (record: datasourceType) => {
    return {
      onClick: () => {
        setSelectedCategory(record);
      },
    };
  };

  useEffect(() => {
    if (selectedCategory) {
      setShowAddCategoryModal(true);
    }
  }, [selectedCategory]);

  return (
    <>
      <BJList
        addButtonCaption={"New category"}
        title={"Categories"}
        OriginalList={originalTable}
        columns={columns}
        recordCountSuffix={"Categories"}
        onClickRow={onClickRow}
        onclick={() => {
          setShowAddCategoryModal(true);
        }}
      />
      <FaqCategoryModal
        show={showAddCategoryModal}
        onHide={() => {
          setShowAddCategoryModal(false);
          setSelectedCategory(null);
        }}
        category={selectedCategory}
      />
    </>
  );
};
