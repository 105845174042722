export const offerTypeSchemes = ({
  offerType,
  translations,
  button,
  confirmationPopup,
}: {
  offerType: string;
  translations: OfferV2["translations"];
  button: OfferV2["button"];
  confirmationPopup: OfferV2["confirmationPopup"];
}) => {
  if (offerType === "1") {
    return [
      translations?.sv?.title?.length > 0
        ? translations?.sv?.title?.trim()
        : undefined,
      translations?.sv?.intro?.length > 0
        ? translations?.sv?.intro?.trim()
        : undefined,
      translations?.sv?.body?.length > 0
        ? translations?.sv?.body?.trim()
        : undefined,
      button?.translations?.sv?.text?.length > 0
        ? button?.translations?.sv?.text?.trim()
        : undefined,
    ];
  } else if (offerType === "3") {
    return [
      translations?.sv?.title?.length > 0
        ? translations?.sv?.title?.trim()
        : undefined,
      translations?.sv?.intro?.length > 0
        ? translations?.sv?.intro?.trim()
        : undefined,
      translations?.sv?.body?.length > 0
        ? translations?.sv?.body?.trim()
        : undefined,
      button?.translations?.sv?.text?.length > 0
        ? button?.translations?.sv?.text?.trim()
        : undefined,
      translations?.sv?.successSignUpOfferTitle?.length > 0
        ? translations?.sv?.successSignUpOfferTitle?.trim()
        : undefined,
      translations?.sv?.successSignUpOfferSubTitle?.length > 0
        ? translations?.sv?.successSignUpOfferSubTitle?.trim()
        : undefined,
      translations?.sv?.successSignUpOfferInputMessage?.length > 0
        ? translations?.sv?.successSignUpOfferInputMessage?.trim()
        : undefined,
      translations?.sv?.ctaBtnLabel?.length > 0
        ? translations?.sv?.ctaBtnLabel?.trim()
        : undefined,
    ];
  } else if (offerType === "4") {
    return [
      translations?.sv?.title?.length > 0
        ? translations?.sv?.title?.trim()
        : undefined,
      translations?.sv?.intro?.length > 0
        ? translations?.sv?.intro?.trim()
        : undefined,
      translations?.sv?.body?.length > 0
        ? translations?.sv?.body?.trim()
        : undefined,
      button?.translations?.sv?.text?.length > 0
        ? button?.translations?.sv?.text?.trim()
        : undefined,
      confirmationPopup?.translations?.sv?.heading?.length > 0
        ? confirmationPopup?.translations?.sv?.heading?.trim()
        : undefined,
      confirmationPopup?.translations?.sv?.description?.length > 0
        ? confirmationPopup?.translations?.sv?.description?.trim()
        : undefined,
      confirmationPopup?.translations?.sv?.successButtonText?.length > 0
        ? confirmationPopup?.translations?.sv?.successButtonText?.trim()
        : undefined,
    ];
  } else {
    return [
      translations?.sv?.title?.length > 0
        ? translations?.sv?.title?.trim()
        : undefined,
      translations?.sv?.intro?.length > 0
        ? translations?.sv?.intro?.trim()
        : undefined,
      translations?.sv?.body?.length > 0
        ? translations?.sv?.body?.trim()
        : undefined,
      button?.translations?.sv?.text?.length > 0
        ? button?.translations?.sv?.text?.trim()
        : undefined,
    ];
  }
};
