import { AutoComplete } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import FormItem from "antd/lib/form/FormItem";
import {
  Control,
  Controller,
  Path,
  PathValue,
  UnpackNestedValue,
} from "react-hook-form";

type Props<T, K extends Path<T>> = {
  name: K;
  control: UnpackNestedValue<PathValue<T, K>> extends string | number
    ? Control<T>
    : never;
  options?: string[];
  label?: string;
  error?: string;
  required?: boolean;
  size?: SizeType;
};

export const BJAutoCompleteFormItem = <T, K extends Path<T>>({
  control,
  name,
  label,
  error,
  required,
  options,
  size,
}: Props<T, K>) => {
  return (
    <FormItem
      label={label}
      name={name}
      required={required}
      validateStatus={error && "error"}
    >
      <Controller<T>
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <AutoComplete
            options={options.map(option => ({ value: option }))}
            value={value as string}
            onChange={onChange}
            size={size}
          />
        )}
      />
    </FormItem>
  );
};
