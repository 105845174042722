import { Card } from "antd";
import { Fragment, useCallback, useMemo, useState } from "react";

import { Sorter } from "../../../components/theme/util/sorter";
import { UserService } from "../../../services";

import BJTable, {
  BJColumnType,
} from "../../../components/theme/molecules/BJTable";
import BJButton, { ButtonTypes } from "../../../components/theme/atoms/Button";
import { BJNotification, NotificationType } from "../../../components/theme";

export type BirthPlanProps = {
  docId: string;
  user: {
    uid: string;
    email: string;
    locale: string;
    country: string;
  };
  name?: string;
  partnerName?: string;
  estimatedDueDate?: string;
  chosenMaternityWard?: string;
  socialSecurityNumber?: string;
  supportPartnerName?: string;
};

const columns: BJColumnType<BirthPlanProps>[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Partner",
    dataIndex: "partnerName",
    key: "partnerName",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Estimated Due Date",
    dataIndex: "estimatedDueDate",
    key: "estimatedDueDate",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Maternity Ward",
    dataIndex: "chosenMaternityWard",
    key: "chosenMaternityWard",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Social Security Number",
    dataIndex: "socialSecurityNumber",
    key: "socialSecurityNumber",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Support Partner",
    dataIndex: "supportPartnerName",
    key: "supportPartnerName",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
];

export const BirthPlansInformation = ({
  birthplans,
}: {
  birthplans: BirthPlanProps[];
}) => {
  const [indexSendingPDF, toggleIndexSendingPDF] = useState(-1);

  const onClickResendAttachment = useCallback(
    async (birthplan: BirthPlanProps, index: number) => {
      try {
        toggleIndexSendingPDF(index);

        await UserService.onSendAttachments({
          ...birthplan.user,
          docId: birthplan.docId,
        });

        BJNotification({
          type: NotificationType.Success,
          message: "Success",
          description: "Birthplan has been sent",
        });
      } catch (error) {
        BJNotification({
          type: NotificationType.Error,
          message: "Failed",
          description: (error as Error).message,
        });
      } finally {
        toggleIndexSendingPDF(-1);
      }
    },
    []
  );

  const columnsWithRender = useMemo(() => {
    return [
      ...columns,
      {
        title: "Action",
        dataIndex: "docId",
        key: "docId",
        width: 2,
        ellipsis: true,
        render(_: unknown, record: BirthPlanProps, index: number) {
          const onClickAttachment = () => {
            onClickResendAttachment(record, index);
          };

          return (
            <BJButton
              loading={indexSendingPDF === index}
              size="middle"
              buttonType={ButtonTypes.Primary}
              onClick={onClickAttachment}
              block
              style={{ whiteSpace: "normal", height: "auto" }}
            >
              Resend attachment
            </BJButton>
          );
        },
      },
    ];
  }, [indexSendingPDF, onClickResendAttachment]);

  return (
    <Card title="Birth Plans Information" bordered={false}>
      {birthplans.length ? (
        <Fragment>
          <BJTable
            hideRecordCount
            pagination={false}
            columns={columnsWithRender}
            dataSource={birthplans}
          />
        </Fragment>
      ) : (
        <Fragment />
      )}
    </Card>
  );
};
