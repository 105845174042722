import firebase from "../firebase";
import { formatTimeStampToMoment } from "../utils";
import { DocumentData, QueryDocumentSnapshot } from "@firebase/firestore-types";
import { CountryCodeService } from "./CountryCodeService";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";

export class PollService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  static toPoll = (doc: QueryDocumentSnapshot<DocumentData>): Poll => {
    const data = doc.data();
    return {
      id: doc.id,
      withPercentage: data.withPercentage,
      withCorrectAnswer: data.withCorrectAnswer,
      isMultiChoice: data.isMultiChoice,
      answers: data.answers,
      screens: data.screens,
      extra: data.extra,
      isActive: data.isActive,
      startTime: formatTimeStampToMoment(data.startTime),
      endTime: formatTimeStampToMoment(data.endTime),
      scheduledAt: formatTimeStampToMoment(data.scheduledAt),
      createdAt: formatTimeStampToMoment(data.createdAt),
      deletedAt: formatTimeStampToMoment(data.deletedAt),
      translations: data.translations,
      segment: data.segment,
    };
  };

  subscribe = (callback: (err: Error | null, polls: Poll[]) => void) => {
    return firebase
      .firestore()
      .collection(CollectionPaths.Polls[this.countryCode])
      .onSnapshot(
        async snapshot => {
          const _poll = snapshot.docs.map(PollService.toPoll);
          callback(null, _poll);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );
  };

  create = (data: Partial<Poll>) => {
    return firebase
      .firestore()
      .collection(CollectionPaths.Polls[this.countryCode])
      .add({ ...data });
  };

  update = (id: Poll["id"], data: Partial<Poll>) => {
    return firebase
      .firestore()
      .collection(CollectionPaths.Polls[this.countryCode])
      .doc(id)
      .update({ ...data });
  };

  deletePoll = (id: Poll["id"]) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Polls[this.countryCode])
      .doc(id)
      .delete();
}
