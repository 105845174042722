import {
  admin as url,
  adminUsers,
  adminForceUpdate,
  adminManageUser,
  adminSignupOffers,
} from "./adminRouteConsts";

const usersRoute = `${url}/${adminUsers}/`;
const adminForceUpdateRoute = `${url}/${adminForceUpdate}/`;
const adminManageUserRoute = `${url}/${adminManageUser}/`;
const adminSignupOffersRoute = `${url}/${adminSignupOffers}/`;

export function GetAdmin() {
  return url;
}

export function getDashBoardRoute() {
  return GetAdmin();
}

export function getAdminUserRoute() {
  return `${usersRoute}`;
}

export function GetAdminDashBoardRoute() {
  return GetAdmin();
}

export function getAdminForceUpdateRoute() {
  return `${adminForceUpdateRoute}`;
}

export function getAdminManageUsersRoute() {
  return `${adminManageUserRoute}`;
}

export function getAdminSignupOffersRouteRoute() {
  return `${adminSignupOffersRoute}`;
}
