import firebase, { firestore } from "../firebase";
import { formatTimeStampToMoment } from "../utils";
import { uploadImage } from "./serviceUtils";
import { collection, getCountFromServer } from "@firebase/firestore";
import { DocumentData, QueryDocumentSnapshot } from "@firebase/firestore-types";
import { CollectionPaths } from "../helper/collections";
import { CountryCodeService } from "./CountryCodeService";

export class CompetitionsService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  toCompetition = (
    doc: QueryDocumentSnapshot<DocumentData>,
    answerCount: number
  ): Competition => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      isActive: data.isActive,
      answerCount: answerCount,
      startDate: formatTimeStampToMoment(data.startDate),
      endDate: formatTimeStampToMoment(data.endDate),
      translations: data.translations ?? {},
    };
  };

  getAnswerCount = async (id: string) => {
    try {
      const path = `${
        CollectionPaths.Competitions[this.countryCode]
      }/${id}/answers`;
      const answersRef = collection(firestore, path);
      const querySnapshot = await getCountFromServer(answersRef);
      return querySnapshot.data().count;
    } catch (e) {
      console.error(e);
    }
  };

  subscribe = (
    callback: (err: Error | null, competitions: Competition[]) => void
  ) => {
    return firebase
      .firestore()
      .collection(CollectionPaths.Competitions[this.countryCode])
      .onSnapshot(
        async snapshot => {
          const competitions = await Promise.all(
            snapshot.docs.map(async item => {
              const answerCount = await this.getAnswerCount(item.id);
              return this.toCompetition(item, answerCount);
            })
          );
          callback(null, competitions);
        },
        err => {
          console.log(err);
          callback(err, []);
        }
      );
  };

  create = (data: Competition) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Competitions[this.countryCode])
      .add(data);

  update = (id: Competition["id"], data: Competition) => {
    return firebase
      .firestore()
      .collection(CollectionPaths.Competitions[this.countryCode])
      .doc(id)
      .update(data);
  };

  delete = (id: Competition["id"]) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Competitions[this.countryCode])
      .doc(id)
      .delete();

  uploadCompetitionImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => {
    const url = uploadImage(
      file,
      CollectionPaths.Competitions[this.countryCode],
      fileName
    );
    return url;
  };

  updateSortOrder = (competitions: Competition[]) => {
    const batch = firebase.firestore().batch();
    competitions.forEach(competition => {
      const ref = firebase
        .firestore()
        .collection(CollectionPaths.Competitions[this.countryCode])
        .doc(competition.id);
      batch.update(ref, { sortOrder: competition.sortOrder, priority: "" });
    });
    return batch.commit();
  };

  getAnswersForCompetition = async (
    competitionId: CompetitionAnswers["competitionId"]
  ) => {
    try {
      const querySnapshot = await firestore
        .collection(
          `${
            CollectionPaths.Competitions[this.countryCode]
          }/${competitionId}/answers`
        )
        .get();

      const competitionAnswers: CompetitionAnswers[] = querySnapshot.docs.map(
        (
          x: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
        ) => {
          if (x.exists) {
            const data = x.data();
            const answer: CompetitionAnswers = {
              answers: data.answers.map((y: any) => ({
                answer: y.answer,
                questionId: y.questionId,
                userId: x.id,
                date: data?.date,
              })),
              userId: x.id,
              competitionId: data?.competitionId,
              date: formatTimeStampToMoment(data?.date) ?? "",
            };
            return answer;
          } else {
            return null;
          }
        }
      );
      return competitionAnswers;
    } catch (Error) {
      console.log(Error);
      return [];
    }
  };
}
