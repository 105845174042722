import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";
import { uploadImage } from "./serviceUtils";

const sponsorFileStoragePath = "verified-by";

export class VerifierService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  //V2
  toVerifier(doc: any): Verifier {
    const data = doc.data();

    return {
      id: doc.id,
      ...data,
    };
  }

  create(data: Omit<Verifier, Verifier["id"]>) {
    firebase
      .firestore()
      .collection(CollectionPaths.Verifiers[this.countryCode])
      .add(data);
  }

  subscribe = (callback: (err: Error | null, verifiers: Verifier[]) => void) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Verifiers[this.countryCode])
      .onSnapshot(
        snapshot => {
          const verifiers = snapshot.docs.map(this.toVerifier);
          callback(null, verifiers);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  update = (data: Verifier) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Verifiers[this.countryCode])
      .doc(data.id)
      .update(data);

  deleteVerifier = (id: string) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Verifiers[this.countryCode])
      .doc(id)
      .delete();

  uploadVerifierImage = async (file: Blob | ArrayBuffer, fileName: string) => {
    const url = await uploadImage(file, sponsorFileStoragePath, fileName);
    return url;
  };
}
