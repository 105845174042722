import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { BannersService } from "../services";
import { useCountry } from "./CountryContext";

type ContextState = {
  banners: Banner[];
  loading: boolean;
  error: Error | null;
  bannersService: BannersService | null;
};

const BannersContext = createContext<ContextState>({
  banners: [],
  loading: false,
  error: null,
  bannersService: null,
});

export const BannersProvider = ({ ...rest }) => {
  const [banners, setBanners] = useState<Banner[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const bannersService = useMemo(
    () => (currentCountry?.abb ? new BannersService(currentCountry.abb) : null),
    [currentCountry?.abb]
  );

  useEffect(() => {
    if (!bannersService) {
      return;
    }

    setLoading(true);

    const unsub = bannersService.subscribe((_error, _banners) => {
      setBanners(_banners);
      setError(_error);
      setLoading(false);
    });

    return unsub;
  }, [bannersService]);

  const value = useMemo(
    () => ({ banners, loading, error, bannersService }),
    [banners, loading, error, bannersService]
  );

  return <BannersContext.Provider value={value} {...rest} />;
};

export const useBanners = () => {
  const context = React.useContext(BannersContext);
  if (context === undefined) {
    throw new Error("useBanners must be used within an bannersProvider");
  }
  return context;
};

export const useBanner = (bannerId: Banner["id"]) => {
  const [banner, setBanner] = useState<Banner | null>(null);
  const [loading, setLoading] = useState(true);

  const { banners, loading: loadingBanners, bannersService } = useBanners();

  const getById = useCallback(
    (id: Banner["id"]) => {
      const banner = banners.find(o => o.id === id);
      return banner || null;
    },
    [banners]
  );

  useEffect(() => {
    if (loadingBanners) {
      return;
    }

    setBanner(getById(bannerId));
    setLoading(false);
  }, [loadingBanners, getById, bannerId]);

  return { banner, loading, bannersService };
};
