import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";
import { uploadImage } from "./serviceUtils";

const verifierFileStoragePath = "verified-by";

export class SponsorService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  toSponsor = (doc: any): Sponsor => {
    const data = doc.data();

    return {
      id: doc.id,
      ...data,
    };
  };

  create = (data: Omit<Sponsor, Sponsor["id"]>) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Sponsors[this.countryCode])
      .add(data);

  subscribe = (callback: (err: Error | null, sponsors: Sponsor[]) => void) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Sponsors[this.countryCode])
      .onSnapshot(
        snapshot => {
          const sponsors = snapshot.docs.map(this.toSponsor);
          callback(null, sponsors);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  update = (data: Sponsor) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Sponsors[this.countryCode])
      .doc(data.id)
      .update(data);

  deleteSponsor = (id: string) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Sponsors[this.countryCode])
      .doc(id)
      .delete();

  uploadSponsorImage = async (file: Blob | ArrayBuffer, fileName: string) => {
    const url = await uploadImage(file, verifierFileStoragePath, fileName);
    return url;
  };
}
