import { useEffect, useState } from "react";
import { useContentCollections, useCountry } from "../../../context";
import { getFormattedErrorMessage, CommonContentType } from "../../../utils";
import { Sorter } from "../../../components/theme/util/sorter";
import { BJColumnType } from "../../../components/theme/molecules/BJTable";
import { BJSelectListModal } from "../../../components/theme/components/BJSelectListModal";
import { useFilterContent } from "../../../hooks/contentCollections/useFilterContent";

interface Props {
  show: boolean;
  excludedContentSelectionId?: string;
  onHide: () => void;
  maxNoOfContentLimit: number;
  selectedContent: CommonContent[];
  onAdd: (content: CommonContent[]) => void;
}

interface DatasourceType extends ContentCollection {
  title: string;
  description: string;
}

export const AddContentCollectionsModal = ({
  show: showModal,
  excludedContentSelectionId,
  onHide,
  maxNoOfContentLimit,
  selectedContent,
  onAdd,
}: Props) => {
  const { contentCollections, loading } = useContentCollections();
  const [dataSource, setDataSource] = useState<DatasourceType[]>([]);
  const { primaryLocale } = useCountry();

  useEffect(() => {
    if (loading) return;
    const contentCollectionTableSource: DatasourceType[] = contentCollections
      .filter(x => x.id !== excludedContentSelectionId)
      .map(contentCollection => ({
        ...contentCollection,
        key: contentCollection.id,
        title: contentCollection.translations[primaryLocale?.key]?.title,
        description:
          contentCollection.translations[primaryLocale?.key]?.description,
      }));
    setDataSource(contentCollectionTableSource);
  }, [contentCollections, excludedContentSelectionId, loading, primaryLocale]);

  const {
    error,
    updatedContent,
    setError,
    selectedContentCollections,
    setSelectedContentCollections,
  } = useFilterContent<DatasourceType>(
    selectedContent,
    maxNoOfContentLimit,
    CommonContentType.CONTENTCOLLECTION,
    dataSource
  );

  const onSubmit = async () => {
    try {
      onAdd(updatedContent);
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
    }
  };

  const columns: BJColumnType<DatasourceType>[] = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 2,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  return (
    <BJSelectListModal
      show={showModal}
      error={error!}
      onHide={onHide}
      modalWidth={600}
      modalTitle={"Add content collection"}
      recordCountSuffix={"Content collection(s)"}
      disable={updatedContent.length > maxNoOfContentLimit}
      selectedRecords={selectedContentCollections}
      updateSelectedRecords={setSelectedContentCollections}
      maxNoOfContentLimit={maxNoOfContentLimit}
      columns={columns}
      dataSource={dataSource}
      onSubmit={onSubmit}
    />
  );
};
