import { CommonContentType } from "../utils";

export const formatPayload = (
  content: RecommendedContent | CollectionContent,
  index: number,
  array: (RecommendedContent | CollectionContent)[],
  contentCollections?: ContentCollection[]
) => {
  const trans: CommonContentV2["translations"] = {};

  const {
    id,
    imageUrl,
    squareImageUrl,
    stretchedImageUrl,
    type,
    blurhash,
    sortOrder,
    podcastId,
    restricted,
    blogPostUrl,
    verifier,
    company,
    sponsor,
    translations,
    level,
    contentCount,
  } = content;

  for (const [key, value] of Object.entries(translations)) {
    trans[key] = {
      title: value.title ?? "",
      description: value.description ?? "",
      body: value.body ?? "",
      duration: value.duration,
      answer: value.answer,
      question: value.question,
      name: value.name,
      intro: value.intro,
      contentText: value.contentText,
      imageUrl: value.imageUrl,
      imageBlurhash: value?.imageBlurhash,
      squareBlurhash: value?.squareBlurhash,
      squareImageUrl: value?.squareImageUrl,
      stretchedImageUrl: value.stretchedImageUrl,
      stretchedBlurhash: value?.stretchedBlurhash,
    };
  }

  let commonData = {
    id,
    imageUrl,
    squareImageUrl,
    stretchedImageUrl,
    blurhash,
    type,
    sortOrder,
    restricted,
    verifier,
    translations: trans,
    level: level ?? 0,
    ...(contentCount && { contentCount }),
  };

  if (type === CommonContentType.BLOG) {
    commonData = {
      ...commonData,
      ...{ blogPostUrl },
    };
  } else if (type === CommonContentType.PODCAST) {
    commonData = {
      ...commonData,
      ...{ podcastId },
    };
  } else if (type === CommonContentType.OFFER) {
    commonData = {
      ...commonData,
      ...{ sponsor, company },
    };
  } else if (type === CommonContentType.ARTICLE) {
    commonData = {
      ...commonData,
      ...{ sponsor },
    };
  } else if (
    type === CommonContentType.CONTENTCOLLECTION &&
    contentCollections
  ) {
    if (!contentCount) {
      const contentCollectionLength = contentCollections.find(
        cc => cc.id === id
      ).content.length;
      if (contentCollectionLength) {
        commonData = {
          ...commonData,
          ...{ contentCount: contentCollectionLength },
        };
      }
    }
  }

  return commonData;
};
