import { useForm } from "react-hook-form";
import { FormModal } from "../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  AspectRatio,
  MissionUnavailable,
  formValidationError,
  valueOrNull,
} from "../../utils";
import { Col, Form, Row, Typography } from "antd";
import {
  BJInputFormItem,
  BJMdFormItem,
  BJSelectCountryFormItem,
  BJSelectFormItem,
} from "../../components/theme";
import { useEffect, useState } from "react";
import { BJSelectLanguageFormItem } from "../../components/theme/molecules/formItems/BJFormSelectLanguageFormItem";
import { DropAndCrop } from "../../components/DropAndCrop";
import { useCountry, useMissions } from "../../context";
import { commonErrors } from "../../language";
import { isEmpty } from "lodash";
import { BJStarsFormItem } from "../../components/theme/molecules/formItems/BJFormStars";
import { MissionsService } from "../../services";

const { requiredError } = commonErrors;

interface MissionModalProps {
  show: boolean;
  onHide: () => void;
  mission: Mission | null;
}

interface FormValues {
  subledger: string;
  stars: number[];
  title: string;
  translations: {
    [key: string]: {
      description: string;
      unavailableMessage?: string;
    };
  };
  country: string;
  language: string;
  imageUrl: string;
  blurhash?: string;
  headerImageUrl: string;
  headerBlurhash?: string;
  deeplink: {
    [country: string]: string;
  };
  unavailable: MissionUnavailable;
}

const selectOptions = [
  {
    key: MissionUnavailable.Never,
    value: MissionUnavailable.Never,
    display: "Never",
  },
  {
    key: MissionUnavailable.NoChild,
    value: MissionUnavailable.NoChild,
    display: "Users without a child",
  },
  {
    key: MissionUnavailable.NoPregnancyAndChildOlder,
    value: MissionUnavailable.NoPregnancyAndChildOlder,
    display: "Users with child older than 3 months and no pregnancy",
  },
  {
    key: MissionUnavailable.IsPartner,
    value: MissionUnavailable.IsPartner,
    display: "Following users",
  },
];

export const MissionModal = ({ show, onHide, mission }: MissionModalProps) => {
  const { uploadMissionImage, onCreate, onUpdate, onDelete } = useMissions();
  const { countries, currentCountry, primaryLocale } = useCountry();
  const [activeCountry, setActiveCountry] = useState(
    currentCountry.abb.toLowerCase()
  );
  const [activeLanguage, setActiveLanguage] = useState(primaryLocale?.key);

  const schema = yup.object().shape({
    subledger: yup.string().required(`Subledger: ${requiredError}`),
    title: yup.string().required(`Title: ${requiredError}`),
    stars: yup
      .array(yup.string().matches(/^\d+$/, "Stars: Value must be a number"))
      .min(1, "Stars: number of values should be from 1 to 3")
      .max(3, "Stars: number of values should be from 1 to 3"),
    imageUrl: yup
      .string()
      .required(`Image Url: ${requiredError}`)
      .url(`Image Url: ${commonErrors.urlValidationError}`)
      .nullable(),
    headerImageUrl: yup
      .string()
      .required(`Header image Url: ${requiredError}`)
      .url(`Header image Url: ${commonErrors.urlValidationError}`)
      .nullable(),
    deeplink: yup.object().shape(
      Object.entries(countries).reduce((acc, [key, value]) => {
        acc[value.abb] = yup
          .string()
          .url(`Deeplink (${String(value).toUpperCase()}): Should be a url`)
          .nullable();
        return acc;
      }, {} as any)
    ),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
    setValue,
    watch,
  } = useForm<FormValues>({ resolver: yupResolver(schema) });
  const [subledgerOptions, setSubledgerOptions] = useState<
    {
      key: string;
      value: string;
      display: string;
    }[]
  >([]);

  useEffect(() => {
    MissionsService.getSubledgerOptions().then(subledgers =>
      setSubledgerOptions(
        subledgers.map(subledger => ({
          key: subledger,
          value: subledger,
          display: subledger,
        }))
      )
    );
  }, []);

  useEffect(() => {
    setActiveCountry(currentCountry.abb.toLowerCase());
    reset({
      ...mission,
      title: mission?.title ?? "",
      subledger: mission?.subledger ?? "",
      stars: mission?.stars ?? [],
      translations: mission?.translations ?? {},
      imageUrl: mission?.imageUrl,
      blurhash: mission?.blurhash,
      headerImageUrl: mission?.headerImageUrl,
      headerBlurhash: mission?.headerBlurhash,
      deeplink: mission?.deeplink ?? null,
      unavailable:
        (mission?.unavailable as MissionUnavailable) ??
        MissionUnavailable.Never,
    });
  }, [mission, reset, show]);

  const onRemove = async () => {
    if (mission) {
      await onDelete(mission.id);
    }
  };

  const onSubmit = async (data: FormValues) => {
    const deeplink: FormValues["deeplink"] = {};
    for (const [key, value] of Object.entries(data.deeplink)) {
      if (value) {
        deeplink[key] = value;
      }
    }
    const translations: FormValues["translations"] = {};
    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        description: value.description ?? "",
        unavailableMessage: value.unavailableMessage ?? "",
      };
    }

    const updateData: Omit<Mission, "id"> = {
      subledger: data.subledger,
      title: data.title,
      deeplink: !isEmpty(deeplink) ? deeplink : null,
      imageUrl: valueOrNull(data.imageUrl),
      blurhash: valueOrNull(data.blurhash),
      headerImageUrl: valueOrNull(data.headerImageUrl),
      headerBlurhash: valueOrNull(data.headerBlurhash),
      stars: data.stars.map(star => Number(star)),
      unavailable: data.unavailable,
      translations,
    };

    if (mission) {
      await onUpdate({ id: mission.id, ...updateData });
    } else {
      await onCreate(updateData);
    }
  };
  const isDirty = !!Object.keys(dirtyFields).length;
  const { unavailable } = watch();

  const handleImageUrl = (url: string | null) => {
    setValue("imageUrl", url, { shouldDirty: true });
  };
  const handleHeaderImageUrl = (url: string | null) => {
    setValue("headerImageUrl", url, { shouldDirty: true });
  };
  const handleBlurhash = (url: string | null) => {
    setValue("blurhash", url, { shouldDirty: true });
  };
  const handleHeaderBlurhash = (url: string | null) => {
    setValue("headerBlurhash", url, { shouldDirty: true });
  };

  return (
    <FormModal
      formId={"missions"}
      key={"missions"}
      onHide={onHide}
      enableSave={isDirty}
      show={show}
      size="xl"
      onDelete={onRemove}
      enableDelete={!!mission}
      modalSubTitle={mission ? `ID - ${mission?.id}` : ""}
      modalTitle={mission ? mission.title : "Add new mission"}
      recordIdentifier={mission?.id}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      errors={errors as any}
    >
      <Row className="mt-4" gutter={{ md: 20 }}>
        <Col span={12}>
          <Row>
            <Col span={8}>
              <BJSelectCountryFormItem
                control={control}
                error={!!errors?.country}
                message={errors?.country?.message}
                fieldName={"country"}
                handleChange={newValue =>
                  setActiveCountry(newValue.toLowerCase())
                }
              />
            </Col>
          </Row>

          <BJInputFormItem
            label={`Deeplink (${activeCountry ?? ""})`}
            fieldName={`deeplink.${activeCountry}`}
            key={`deeplink.${activeCountry}`}
            error={!!errors?.deeplink?.[activeCountry]}
            message={errors?.deeplink?.[activeCountry]?.message}
            control={control}
            disabled={false}
          />
          <Row>
            <Col span={8}>
              <BJSelectLanguageFormItem
                control={control}
                error={!!errors?.country}
                message={errors?.country?.message}
                fieldName={"language"}
                handleChange={newValue => setActiveLanguage(newValue)}
                selectedCountry={countries.find(
                  country => country.abb === activeCountry
                )}
              />
            </Col>
          </Row>
          <BJMdFormItem
            fieldName={`translations.${activeLanguage}.description`}
            key={`translations.${activeLanguage}.description`}
            label={`Description (${activeLanguage.toUpperCase()})`}
            control={control}
            required={false}
          />
          <BJSelectFormItem
            defaultValue={MissionUnavailable.Never}
            control={control}
            error={!!errors.unavailable}
            label={"Unavailable for user segment"}
            message={errors.unavailable?.message}
            required={true}
            size={"large"}
            optionsList={selectOptions}
            fieldName={"unavailable"}
          />
          {unavailable !== MissionUnavailable.Never && (
            <BJMdFormItem
              fieldName={`translations.${activeLanguage}.unavailableMessage`}
              key={`translations.${activeLanguage}.unavailableMessage`}
              label={`Unavailable message (${activeLanguage.toUpperCase()})`}
              control={control}
            />
          )}
        </Col>
        <Col span={12}>
          <BJInputFormItem
            label={"Title"}
            fieldName={"title"}
            key={"title"}
            control={control}
            error={!!errors?.title}
            message={errors?.title?.message}
            required
          />
          <BJStarsFormItem
            label="Stars"
            key="stars"
            fieldName={"stars"}
            control={control}
            placeholder="Completed events for levels"
            error={!!errors?.stars}
            errorField={errors?.stars}
            required
          />
          <BJSelectFormItem
            key={subledgerOptions.length}
            defaultValue={subledgerOptions[0]}
            size="large"
            control={control}
            error={!!errors?.subledger?.message}
            label="Subledger"
            message={errors?.subledger?.message}
            optionsList={subledgerOptions}
            fieldName={"subledger"}
            required
          />
          <Form.Item
            label="Image"
            required
            extra=""
            validateStatus={errors.imageUrl && "error"}
            help={
              <Typography.Paragraph type="danger">
                {errors.imageUrl?.message}
              </Typography.Paragraph>
            }
          >
            <DropAndCrop
              title="Image"
              extra="Please upload an image with size of 1:1 ratio"
              initialUrl={mission?.imageUrl}
              setUploadUrl={handleImageUrl}
              setBlurhash={handleBlurhash}
              uploadImage={uploadMissionImage}
              lockedRatio={AspectRatio.OneToOne}
              defaultCropBoxWidth={100}
              defaultCropBoxHeight={100}
              croppable={true}
            />
          </Form.Item>
          <Form.Item
            label="Header Image"
            required
            extra=""
            validateStatus={errors.headerImageUrl && "error"}
            help={
              <Typography.Paragraph type="danger">
                {errors.headerImageUrl?.message}
              </Typography.Paragraph>
            }
          >
            <DropAndCrop
              title="Header Image"
              extra="Please upload a banner image with size of 382x168"
              initialUrl={mission?.headerImageUrl}
              setUploadUrl={handleHeaderImageUrl}
              setBlurhash={handleHeaderBlurhash}
              uploadImage={uploadMissionImage}
              lockedRatio={AspectRatio.Free}
              defaultCropBoxWidth={300}
              defaultCropBoxHeight={100}
              croppable={true}
            />
          </Form.Item>
        </Col>
      </Row>
    </FormModal>
  );
};
