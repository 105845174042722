import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { CountryCodeService } from "./CountryCodeService";

export class ChildBlogPostService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }
  subscribe = (
    callback: (err: Error | null, blogPosts: ChildBlogPost[]) => void
  ) =>
    firebase
      .firestore()
      .collection(CollectionPaths.ChildBlogPosts[this.countryCode])
      .onSnapshot(
        snapshot => {
          const blogPosts = snapshot.docs.map(
            (doc: firebase.firestore.DocumentData) => {
              const data = doc.data();
              return {
                id: doc.id,
                ...data,
                translations: data.translations ?? {},
              };
            }
          );
          callback(null, blogPosts);
        },
        err => {
          console.log(err);
          callback(err, []);
        }
      );

  create = (data: BlogPost) =>
    firebase
      .firestore()
      .collection(CollectionPaths.ChildBlogPosts[this.countryCode])
      .add({
        ...data,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

  update = (data: BlogPost) =>
    firebase
      .firestore()
      .collection(CollectionPaths.ChildBlogPosts[this.countryCode])
      .doc(data.id)
      .update({
        ...data,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

  write = async (data: BlogPost) => {
    if (data.id) {
      await this.update(data);
      return data.id;
    } else {
      const result = await this.create(data);
      return result.id;
    }
  };

  delete = (id: ChildBlogPost["id"]) =>
    firebase
      .firestore()
      .collection(CollectionPaths.ChildBlogPosts[this.countryCode])
      .doc(id)
      .delete();
}
