import { Form, Typography } from "antd";
import React from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { FormValues } from "./types";
import BJInput from "../../components/theme/atoms/BJInput";

type Props = {
  errors: FieldErrors<FormValues>;
  control: Control<FormValues, object>;
};

const OfferSignUpTerms: React.FC<Props> = ({ errors, control }) => {
  return (
    <>
      <Form.Item
        label="Terms (label)"
        validateStatus={errors.button?.link && "error"}
        extra={
          <Typography.Paragraph type="danger">
            {errors.terms?.message}
          </Typography.Paragraph>
        }
      >
        <Controller
          control={control}
          name="terms"
          render={({ field }) => <BJInput {...field} />}
        />
      </Form.Item>
      <Form.Item
        label="URL"
        validateStatus={errors.termUrl && "error"}
        extra={
          <Typography.Paragraph type="danger">
            {errors.termUrl?.message}
          </Typography.Paragraph>
        }
      >
        <Controller
          control={control}
          name="termUrl"
          render={({ field }) => <BJInput {...field} />}
        />
      </Form.Item>
      <Form.Item
        label="Target"
        validateStatus={errors.termsTarget && "error"}
        extra={
          <Typography.Paragraph type="danger">
            {errors.termsTarget?.message}
          </Typography.Paragraph>
        }
      >
        <Controller
          control={control}
          name="termsTarget"
          render={({ field }) => <BJInput {...field} />}
        />
      </Form.Item>
    </>
  );
};

export default OfferSignUpTerms;
