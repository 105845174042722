import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { defaultPeriod } from "../pages";
import { MilestonesService } from "../services";
import { useCountry } from "./CountryContext";

type ContextState = {
  milestonesPregnancy: Milestone[];
  milestonesChildWeek: Milestone[];
  milestonesChildMonth: Milestone[];
  loadingMilestonesPregnancy: boolean;
  loadingMilestonesChildWeek: boolean;
  loadingMilestonesChildMonth: boolean;
  error: Error | null;
  getById: (
    id: Milestone["id"],
    periodType: Milestone["periodType"],
    isChildPage: boolean
  ) => Milestone | null;
  milestonesService: MilestonesService | null;
};

const MilestonesContext = createContext<ContextState>({
  milestonesPregnancy: [],
  milestonesChildWeek: [],
  milestonesChildMonth: [],
  loadingMilestonesPregnancy: false,
  loadingMilestonesChildWeek: false,
  loadingMilestonesChildMonth: false,
  error: null,
  getById: () => null,
  milestonesService: null,
});

export const MilestonesProvider = ({ ...rest }) => {
  const [milestonesPregnancy, setMilestonesPregnancy] = useState<Milestone[]>(
    []
  );
  const [milestonesChildWeek, setMilestonesChildWeek] = useState<Milestone[]>(
    []
  );
  const [milestonesChildMonth, setMilestonesChildMonth] = useState<Milestone[]>(
    []
  );
  const [loadingMilestonesPregnancy, setLoadingMilestonesPregnancy] =
    useState(true);
  const [loadingMilestonesChildWeek, setLoadingMilestonesChildWeek] =
    useState(true);
  const [loadingMilestonesChildMonth, setLoadingMilestonesChildMonth] =
    useState(true);
  const [error, setError] = useState<Error | null>(null);

  const { currentCountry } = useCountry();

  const milestonesService = useMemo(() => {
    return currentCountry?.abb
      ? new MilestonesService(currentCountry.abb)
      : null;
  }, [currentCountry]);

  useEffect(() => {
    setLoadingMilestonesPregnancy(true);
    setLoadingMilestonesChildWeek(true);
    setLoadingMilestonesChildMonth(true);

    const unsubscribeMilestonesPregnancy = milestonesService?.subscribe(
      (_error, _milestones) => {
        setMilestonesPregnancy(_milestones);
        setError(_error);
        setLoadingMilestonesPregnancy(false);
      }
    );
    const unsubscribeMilestonesChildWeek =
      milestonesService?.childWeeklySubscribe((_error, _milestones) => {
        setMilestonesChildWeek(_milestones);
        setError(_error);
        setLoadingMilestonesChildWeek(false);
      });
    const unsubscribeMilestonesChildMonthly =
      milestonesService?.childMonthlySubscribe((_error, _milestones) => {
        setMilestonesChildMonth(_milestones);
        setError(_error);
        setLoadingMilestonesChildMonth(false);
      });

    return () => {
      unsubscribeMilestonesPregnancy?.();
      unsubscribeMilestonesChildWeek?.();
      unsubscribeMilestonesChildMonthly?.();
    };
  }, [milestonesService]);

  const getById = useCallback(
    (
      id: Milestone["id"],
      periodType: Milestone["periodType"],
      isChildPage: boolean
    ) => {
      const milestone =
        isChildPage && periodType === defaultPeriod
          ? milestonesChildWeek
          : isChildPage && periodType !== defaultPeriod
          ? milestonesChildMonth
          : milestonesPregnancy;
      const _podcast = milestone.find(a => a.id === id);
      return _podcast || null;
    },
    [milestonesPregnancy, milestonesChildWeek, milestonesChildMonth]
  );

  const value = useMemo(
    () => ({
      milestonesPregnancy,
      milestonesChildWeek,
      milestonesChildMonth,
      loadingMilestonesPregnancy,
      loadingMilestonesChildWeek,
      loadingMilestonesChildMonth,
      error,
      getById,
      milestonesService,
    }),
    [
      milestonesPregnancy,
      milestonesChildWeek,
      milestonesChildMonth,
      loadingMilestonesPregnancy,
      loadingMilestonesChildWeek,
      loadingMilestonesChildMonth,
      error,
      getById,
      milestonesService,
    ]
  );

  return <MilestonesContext.Provider value={value} {...rest} />;
};

export const useMilestones = () => {
  const context = useContext(MilestonesContext);
  if (context === undefined) {
    throw new Error("useMilestones must be used within an MilestonesProvider");
  }
  return context;
};

export const useMilestone = (
  milestoneId: Milestone["id"],
  periodType: Milestone["periodType"],
  isChildPage?: boolean
) => {
  const [milestone, setMilestone] = useState<Milestone | null>(null);
  const [loading, setLoading] = useState(true);

  const {
    getById,
    loadingMilestonesPregnancy,
    loadingMilestonesChildWeek,
    loadingMilestonesChildMonth,
  } = useMilestones();

  useEffect(() => {
    if (
      loadingMilestonesPregnancy ||
      loadingMilestonesChildWeek ||
      loadingMilestonesChildMonth
    ) {
      return;
    }

    setMilestone(getById(milestoneId, periodType, isChildPage));
    setLoading(false);
  }, [
    loadingMilestonesPregnancy,
    loadingMilestonesChildWeek,
    loadingMilestonesChildMonth,
    getById,
    milestoneId,
    periodType,
    isChildPage,
  ]);

  return { milestone, loading };
};
