import { Row, Switch, Typography } from "antd";
import { useEffect, useState } from "react";
import { BJFilterSelect } from "./theme/atoms/BJFilterSelect";
interface Props {
  label: string;
  placeholder?: string;
  defaultValue?: string;
  filter: IFilter;
  disableCheckAll?: boolean;
  onPressItem: (value: string) => void;
  onCheckAll?: (allChecked: boolean) => void;
  single?: boolean;
}

export const FilterButton = ({
  label,
  filter,
  disableCheckAll,
  onPressItem,
  onCheckAll,
  defaultValue,
  single,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<any>(
    defaultValue ? [defaultValue] : []
  );
  const selectAll = (checked: boolean) =>
    checked
      ? (setSelectedValue(filter.data.map(item => item.text)),
        onCheckAll && onCheckAll(checked))
      : (setSelectedValue([]), onCheckAll && onCheckAll(checked));

  useEffect(() => {
    if (filter.selected.size === 0) {
      setSelectedValue(defaultValue ? [defaultValue] : []);
    }
  }, [filter.selected.size]);

  return (
    <BJFilterSelect
      size="large"
      mode={single ? null : "multiple"}
      bordered={false}
      showSearch
      maxTagCount={2}
      showArrow
      options={filter.data.map(item => ({
        value: item.text,
        key: item.value,
      }))}
      dropdownRender={menu => (
        <div>
          {!disableCheckAll ? (
            <Row justify="space-around" align="middle">
              <Typography.Paragraph>Select All</Typography.Paragraph>
              <Switch onChange={selectAll} size="small" />
            </Row>
          ) : null}
          {menu}
        </div>
      )}
      onSelect={(value, option) => onPressItem(option.key! as string)}
      onDeselect={(value, option) => onPressItem(option.key! as string)}
      onChange={value => setSelectedValue(value)}
      value={selectedValue}
      placeholder={label}
    />
  );
};
