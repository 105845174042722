import React, { SetStateAction } from "react";
import { Divider, Tabs } from "antd";
import { Control, UseFormSetValue } from "react-hook-form";
import { FormValues } from "./types";
import { WeeksOrMonthsPicker } from "../../components";
import { BJSwitchFormItem } from "../../components/theme";
import { PickerDisplayType, PickerType } from "../../utils/timeUtils";

type Props = {
  control: Control<FormValues, object>;
  setValue: UseFormSetValue<FormValues>;
  displayOfferText?: string;
  pickerWeeks: PickerDisplayType[];
  pickerMonths: PickerDisplayType[];
  monthChildOffer: timeElementType[];
  weekPregnancyOffer: timeElementType[];
  setWeekPregnancyOffer: (value: SetStateAction<timeElementType[]>) => void;
  setMonthChildOffer: (value: SetStateAction<timeElementType[]>) => void;
};

const { TabPane } = Tabs;

const OfferPregnancyChild: React.FC<Props> = ({
  control,
  setValue,
  displayOfferText,
  pickerWeeks,
  pickerMonths,
  monthChildOffer,
  weekPregnancyOffer,
  setWeekPregnancyOffer,
  setMonthChildOffer,
}) => {
  return (
    <>
      <Divider orientation="left">
        Offers on pregnancy weeks/ Child months
      </Divider>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Pregnancy weeks" key="1">
          <WeeksOrMonthsPicker
            render={() => (
              <BJSwitchFormItem
                horizontal
                control={control}
                label={displayOfferText}
                fieldName={"showPregnancyOffer"}
              />
            )}
            times={pickerWeeks}
            selectAllCaption={"Select all weeks"}
            pickerType={PickerType.weeks}
            selectedTimes={weekPregnancyOffer ?? []}
            onChange={value => {
              setWeekPregnancyOffer(value);
              setValue("dirty", true, { shouldDirty: true });
            }}
          />
        </TabPane>
        <TabPane tab="Child months" key="2">
          <WeeksOrMonthsPicker
            render={() => (
              <BJSwitchFormItem
                horizontal
                control={control}
                label={displayOfferText}
                fieldName={"showChildMonthOffer"}
              />
            )}
            times={pickerMonths}
            selectAllCaption={"Select all months"}
            pickerType={PickerType.months}
            selectedTimes={monthChildOffer ?? []}
            onChange={value => {
              setMonthChildOffer(value);
              setValue("dirty", true, { shouldDirty: true });
            }}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default OfferPregnancyChild;
