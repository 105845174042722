import firebase from "../firebase";

const update = (
  id: "android" | "ios",
  lowestAllowedVersion: string,
  enabled: boolean
) =>
  firebase
    .firestore()
    .collection("forceUpdate")
    .doc(id)
    .update({ enabled, lowestAllowedVersion });

export const ForceUpdateService = {
  update,
};
