import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Row, Col } from "antd";
import { useAppLinks } from "../../context";
import { AppLinksScreens, formValidationError } from "../../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { cms } from "../../routes/routeConsts";
import { FormEdit, FormEditType } from "../../components";
import { BJInputFormItem, BJSelectFormItem } from "../../components/theme";
import { commonErrors } from "../../language";

interface FormValues {
  screenName: string;
  externalUrl: string;
}
const { requiredError } = commonErrors;

const schema = yup.object().shape({
  screenName: yup.string().required(() => `Screen Name: ${requiredError}`),
  externalUrl: yup.string().required(() => `External URL: ${requiredError}`),
});

export const AppLinkPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const {
    appLinks,
    loading: loadingAppLink,
    onCreate,
    onDelete,
    onUpdate,
  } = useAppLinks();
  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      screenName: "",
      externalUrl: "",
    },
  });
  const {
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
    control,
  } = methods;

  useEffect(() => {
    if (loadingAppLink || appLinks === null) {
      return;
    }

    const existingAppLink = appLinks[0]?.appLinks[id];
    if (existingAppLink) {
      reset({
        screenName: id,
        externalUrl: existingAppLink.externalUrl,
      });
    } else {
      console.error(`App link with id ${id} not found`);
    }
  }, [loadingAppLink, reset, id, appLinks]);

  const onSubmit = async (data: FormValues) => {
    try {
      if (id) {
        await onUpdate(id, data.externalUrl as any);
      } else {
        await onCreate({
          [data.screenName]: {
            externalUrl: data.externalUrl,
          },
        });
      }
      navigate(`${cms}/appLinks`);
    } catch (error) {
      console.error("Error creating/updating app link:", error);
    }
  };

  const onRemove = async () => {
    if (id) {
      await onDelete(id);
      navigate("./..", { replace: true });
    }
  };

  const onSaveEnabled = dirtyFields.screenName || dirtyFields.externalUrl;

  return (
    <FormProvider {...methods}>
      <FormEdit
        backRoutePath={`${cms}/appLinks`}
        onRemove={onRemove}
        hasValidationErrors={Object.keys(errors).length !== 0}
        enableSave={!loadingAppLink && onSaveEnabled}
        title={id ? "Edit App Link" : "New App Link"}
        id={id}
        editType={id ? FormEditType.EDIT : FormEditType.ADD}
        loading={loadingAppLink}
        onSubmit={handleSubmit(onSubmit, formValidationError)}
        recordIdentifier={id || ""}
        localeSupported={false}
        errors={errors as any}
      >
        {() => (
          <>
            <Row gutter={{ md: 20 }}>
              <Col md={24} lg={12}>
                <BJSelectFormItem
                  control={control}
                  error={!!errors.screenName}
                  message={errors.screenName?.message}
                  label="Screen Name"
                  required
                  fieldName="screenName"
                  optionsList={Object.values(AppLinksScreens)
                    .filter(
                      screenName =>
                        !appLinks.some(
                          appLink =>
                            appLink.appLinks && appLink.appLinks[screenName]
                        )
                    )
                    .map(screenName => ({
                      key: screenName,
                      value: screenName,
                      display: screenName,
                    }))}
                  disabled={!!id}
                />
                <BJInputFormItem
                  control={control}
                  error={!!errors.externalUrl}
                  label="External URL"
                  fieldName="externalUrl"
                  message={errors.externalUrl?.message}
                />
              </Col>
            </Row>
          </>
        )}
      </FormEdit>
    </FormProvider>
  );
};
