import { Select } from "antd";
import styled from "styled-components";

import { capitalizeFirstLetter } from "../../../utils";
import { Typography } from "antd";
import { SelectValue } from "antd/lib/select";
interface Props {
  tags: string[];
  onChange: (tags: string[]) => void;
}

export const BJTagsInput = ({ tags, onChange }: Props) => {
  const handleChange = (values: SelectValue) => {
    onChange((values as string[]).map(value => capitalizeFirstLetter(value)));
  };
  return (
    <>
      <StyledSelect
        placeholder={"Separate tags by pressing comma -> ( , )"}
        size="large"
        mode="tags"
        onChange={handleChange}
        tokenSeparators={[","]}
        value={tags}
      />
      <WordWrapper>
        <Typography.Text type="secondary">{`Separate tags by pressing comma -> ( , )`}</Typography.Text>
        <Typography.Paragraph type="success">{`If you need to paste bulk tags, paste tags that are separated from a ( , ) in the input box`}</Typography.Paragraph>
      </WordWrapper>
    </>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selection-item {
    background: ${props => props.theme.primary};
  }

  .ant-select-selection-item-remove {
    display: flex;
    align-items: center;
  }
`;

const WordWrapper = styled.div`
  padding-left: 0.4rem;
  padding-top: 0.1rem;
  margin-bottom: 0.6rem;
`;
