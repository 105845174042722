import { useEffect, useMemo, useState } from "react";
import { Col, Row, Form, Input, Checkbox, Button, Spin, Space } from "antd";
import { CenteredSpinner } from "../../../components";

import { firebase } from "../../../firebase";
import { ForceUpdateService } from "../../../services";
import { logException } from "../../../utils/exceptionLogger";

type ForceUpdate = {
  enabled: boolean;
  lowestAllowedVersion: string;
};

const ForceUpdatePage = () => {
  // iOS
  const [forceUpdateIOS, setForceUpdateIOS] = useState<ForceUpdate | null>(
    null
  );
  const [lowestAlloweVersionIOS, setLowestAllowedVersionIOS] = useState("");
  const [enabledIOS, setEnabledIOS] = useState(false);
  const [loadingIOS, setLoadingIOS] = useState(true);
  const [isSavingIOS, setIsSavingIOS] = useState(false);

  // Android
  const [forceUpdateAndroid, setForceUpdateAndroid] =
    useState<ForceUpdate | null>(null);
  const [lowestAlloweVersionAndroid, setLowestAllowedVersionAndroid] =
    useState("");
  const [enabledAndroid, setEnabledAndroid] = useState(false);
  const [loadingAndroid, setLoadingAndroid] = useState(true);
  const [isSavingAndroid, setIsSavingAndroid] = useState(false);

  const isValid = (value: string) => {
    return /^\d{1,2}\.\d{1,2}\.\d{1,3}$/.test(value);
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("forceUpdate")
      .doc("android")
      .onSnapshot(snapshot => {
        const _forceUpdate = snapshot.data();
        if (_forceUpdate) {
          setForceUpdateAndroid({
            enabled: _forceUpdate.enabled || false,
            lowestAllowedVersion: _forceUpdate.lowestAllowedVersion || "",
          });
        }
        setLoadingAndroid(false);
      });
  }, []);

  useEffect(() => {
    if (forceUpdateIOS) {
      setLowestAllowedVersionIOS(forceUpdateIOS.lowestAllowedVersion);
      setEnabledIOS(forceUpdateIOS.enabled);
    }
  }, [forceUpdateIOS]);

  useEffect(() => {
    if (forceUpdateAndroid) {
      setLowestAllowedVersionAndroid(forceUpdateAndroid.lowestAllowedVersion);
      setEnabledAndroid(forceUpdateAndroid.enabled);
    }
  }, [forceUpdateAndroid]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("forceUpdate")
      .doc("ios")
      .onSnapshot(snapshot => {
        const _forceUpdate = snapshot.data();
        if (_forceUpdate) {
          setForceUpdateIOS({
            enabled: _forceUpdate.enabled || false,
            lowestAllowedVersion: _forceUpdate.lowestAllowedVersion || "",
          });
        }
        setLoadingIOS(false);
      });
  }, []);

  const onSaveIOS = async () => {
    setIsSavingIOS(true);
    try {
      await ForceUpdateService.update(
        "ios",
        lowestAlloweVersionIOS,
        enabledIOS
      );
    } catch (error) {
      logException(error);
    } finally {
      setIsSavingIOS(false);
    }
  };

  const onSaveAndroid = async () => {
    setIsSavingAndroid(true);
    try {
      await ForceUpdateService.update(
        "android",
        lowestAlloweVersionAndroid,
        enabledAndroid
      );
    } catch (error) {
      logException(error);
    } finally {
      setIsSavingAndroid(false);
    }
  };

  const iOSIsDirty = useMemo(
    () =>
      forceUpdateIOS?.lowestAllowedVersion !== lowestAlloweVersionIOS ||
      forceUpdateIOS.enabled !== enabledIOS,
    [forceUpdateIOS, lowestAlloweVersionIOS, enabledIOS]
  );

  const androidIsDirty = useMemo(
    () =>
      forceUpdateAndroid?.lowestAllowedVersion !== lowestAlloweVersionAndroid ||
      forceUpdateAndroid.enabled !== enabledAndroid,
    [forceUpdateAndroid, lowestAlloweVersionAndroid, enabledAndroid]
  );

  if (loadingIOS || loadingAndroid) {
    return <CenteredSpinner />;
  }

  return (
    <Row justify="space-around">
      <Col span={8} flex="start">
        <h1>iOS</h1>
        <h6>Lowest Allowed Version</h6>
        <br />
        <Form.Item>
          <Input
            size="large"
            value={lowestAlloweVersionIOS}
            onChange={e => setLowestAllowedVersionIOS(e.target.value)}
          />
        </Form.Item>
        <Space size="large">
          <Checkbox
            checked={enabledIOS}
            onChange={() => setEnabledIOS(!enabledIOS)}
          >
            Enabled
          </Checkbox>
          <Button
            type="primary"
            disabled={
              !iOSIsDirty || !isValid(lowestAlloweVersionIOS) || isSavingIOS
            }
            onClick={onSaveIOS}
          >
            {isSavingIOS ? <Spin /> : "Save"}
          </Button>
        </Space>
      </Col>
      <Col span={8} flex="end">
        <h1>Android</h1>
        <h6>Lowest Allowed Version</h6>
        <br />
        <Form.Item>
          <Input
            size="large"
            value={lowestAlloweVersionAndroid}
            pattern="\d{1,2}\.\d{1,2}\.\d{1,3}"
            onChange={e => setLowestAllowedVersionAndroid(e.target.value)}
          />
        </Form.Item>
        <Space size="large">
          <Checkbox
            checked={enabledAndroid}
            onChange={() => setEnabledAndroid(!enabledAndroid)}
          >
            Enabled
          </Checkbox>
          <Button
            type="primary"
            disabled={
              !androidIsDirty ||
              !isValid(lowestAlloweVersionAndroid) ||
              isSavingAndroid
            }
            onClick={onSaveAndroid}
          >
            {isSavingAndroid ? <Spin /> : "Save"}
          </Button>
        </Space>
      </Col>
    </Row>
  );
};
export default ForceUpdatePage;
