import React from "react";
import { Form, Typography } from "antd";
import { DropAndCrop } from "../../components/DropAndCrop";
import { OffersService } from "../../services";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { FormValues, OfferImageUrlProps } from "./types";
import { AspectRatio } from "../../utils";
import BJInput from "../../components/theme/atoms/BJInput";
import { BJDeeplinkFormInput } from "../../components/theme/molecules/formItems/BJDeeplinkFormInput";

type Props = {
  locale: Locale;
  offer: OfferV2;
  errors: FieldErrors<FormValues>;
  control: Control<FormValues, object>;
  watch: UseFormWatch<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  handleImageUrl: (event: OfferImageUrlProps) => void;
};

const OfferImageBannerVideo: React.FC<Props> = props => {
  const { locale, offer, errors, control, watch, setValue, handleImageUrl } =
    props;

  return (
    <>
      <Typography.Text>
        {`Banner Image URL: ${locale.label ? `(${locale.label})` : ""}`}
      </Typography.Text>
      <DropAndCrop
        title={`Banner Image URL: ${locale.label ? `(${locale.label})` : ""}`}
        setUploadUrl={(url: string) =>
          handleImageUrl({
            url,
            locale: locale.key,
            fieldName: "bannerImageUrl",
          })
        }
        setBlurhash={(blurhash: string) =>
          handleImageUrl({
            url: blurhash,
            locale: locale.key,
            fieldName: "bannerImageBlurhash",
          })
        }
        uploadImage={OffersService.uploadOfferBannerImage}
        initialUrl={watch("translations")?.[locale.key]?.bannerImageUrl ?? ""}
        lockedRatio={AspectRatio.FourToOne}
        defaultCropBoxWidth={300}
        key={`translations.${locale.key}.bannerImageUrl`}
      />

      <Typography.Text>
        {`Image URL: ${locale.label ? `(${locale.label})` : ""}`}
      </Typography.Text>
      <DropAndCrop
        title={`Image URL: ${locale.label ? `(${locale.label})` : ""}`}
        setUploadUrl={(url: string) =>
          handleImageUrl({
            url,
            locale: locale.key,
            fieldName: "imageUrl",
          })
        }
        setBlurhash={(blurhash: string) =>
          handleImageUrl({
            url: blurhash,
            locale: locale.key,
            fieldName: "imageBlurhash",
          })
        }
        uploadImage={OffersService.uploadOfferImage}
        initialUrl={watch("translations")?.[locale.key]?.imageUrl ?? ""}
        lockedRatio={AspectRatio.FourToThree}
        fileNamePrefix={offer?.id}
        defaultCropBoxWidth={300}
        key={`translations.${locale.key}.imageUrl`}
      />

      <Form.Item
        label="Video url"
        validateStatus={errors.video && "error"}
        extra={
          <Typography.Paragraph type="danger">
            {errors.video?.message}
          </Typography.Paragraph>
        }
      >
        <Controller
          control={control}
          name="video"
          render={({ field }) => <BJInput {...field} />}
        />
      </Form.Item>

      <BJDeeplinkFormInput
        control={control}
        error={!!errors.bannerDeepLink}
        label={"Deep Link / External Link"}
        message={errors.bannerDeepLink?.message}
        fieldName={"bannerDeepLink"}
        title="Banner"
        copyToNotificationDeepLink={(link: string) => {
          setValue("bannerDeepLink", link, { shouldDirty: true });
        }}
      />
    </>
  );
};

export default OfferImageBannerVideo;
