import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { HighlightsService } from "../services";
import { useCountry } from "./CountryContext";

type ContextState = {
  highlights: Highlight[];
  loading: boolean;
  error: Error | null;
  getById: (id: Highlight["id"]) => Highlight | null;
  createHighlight: (data: Highlight) => Promise<void>;
  updateHighlight: (data: Highlight) => Promise<void>;
  deleteHighlight: (id: Highlight["id"]) => Promise<void>;
  uploadHighlightFlowImage: (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => Promise<string>;
  uploadInAppImage: (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => Promise<string>;
};

const HighlightsContext = createContext<ContextState>({
  highlights: [],
  loading: false,
  error: null,
  getById: () => null,
  createHighlight: async () => null,
  updateHighlight: async () => null,
  deleteHighlight: async () => null,
  uploadHighlightFlowImage: async () => null,
  uploadInAppImage: async () => null,
});

export const HighlightsProvider = ({ ...rest }) => {
  const [highlightsData, setHighlightsData] = useState<Highlight[]>([]);
  const [highlights, setHighlights] = useState<Highlight[]>([]);
  const [loadingHighlights, setLoadingHighlights] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const highlightsService = useMemo(
    () => new HighlightsService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    setLoadingHighlights(true);
    const unsubHighlights = highlightsService.subscribe((error, highlights) => {
      setHighlightsData(highlights);
      setError(error);
      setLoadingHighlights(false);
    });

    return () => {
      unsubHighlights();
    };
  }, [highlightsService]);

  const createHighlight = useCallback(
    async (data: Highlight) => await highlightsService.create(data),
    [highlightsService]
  );

  const updateHighlight = useCallback(
    async (data: Highlight) => await highlightsService.update(data),
    [highlightsService]
  );

  const deleteHighlight = useCallback(
    async (id: Highlight["id"]) => await highlightsService.delete(id),
    [highlightsService]
  );

  const uploadHighlightFlowImage = useCallback(
    async (file: Blob | ArrayBuffer, fileName: string) =>
      await highlightsService.uploadHighlightFlowImage(file, fileName),
    [highlightsService]
  );

  const uploadInAppImage = useCallback(
    async (file: Blob | ArrayBuffer, fileName: string) =>
      await highlightsService.uploadInAppImage(file, fileName),
    [highlightsService]
  );

  useEffect(() => {
    if (loadingHighlights) {
      return;
    }
    const highlights: Highlight[] = highlightsData.map(highlight => {
      return {
        ...highlight,
      };
    });
    setHighlights(highlights);
  }, [loadingHighlights, highlightsData]);

  const getById = useCallback(
    (id: Highlight["id"]) => {
      const highlight = highlights.find(a => a.id === id);
      return highlight || null;
    },
    [highlights]
  );

  const loading = loadingHighlights;

  const value = useMemo(
    () => ({
      highlights,
      loading,
      error,
      getById,
      createHighlight,
      updateHighlight,
      deleteHighlight,
      uploadHighlightFlowImage,
      uploadInAppImage,
    }),
    [
      highlights,
      loading,
      error,
      getById,
      createHighlight,
      updateHighlight,
      deleteHighlight,
      uploadHighlightFlowImage,
      uploadInAppImage,
    ]
  );

  return <HighlightsContext.Provider value={value} {...rest} />;
};

export const useHighlights = () => {
  const context = React.useContext(HighlightsContext);
  if (context === undefined) {
    throw new Error("useHighlights must be used within an HighlightsProvider");
  }
  return context;
};

export const useHighlight = (highlightId: Highlight["id"]) => {
  const [highlight, setHighlight] = useState<Highlight | null>(null);
  const [loading, setLoading] = useState(true);

  const { getById, loading: loadingHighlights } = useHighlights();

  useEffect(() => {
    if (loadingHighlights) {
      return;
    }
    if (highlightId) {
      setHighlight(getById(highlightId));
    }
    setLoading(false);
  }, [loadingHighlights, getById, highlightId]);

  return { highlight, loading };
};
