import { BJColumnType } from "../../components/theme/molecules/BJTable";
import BJList from "../../components/theme/components/BJList";
import { useEffect, useState } from "react";
import { TagsModal } from "./TagsModal";
import { Sorter } from "../../components/theme/util/sorter";
import { useTags } from "../../context/TagsContext";

interface datasourceType extends Tags {
  test?: string;
}

export const TagsList = () => {
  const { tags, loadingTags: loading } = useTags();
  const [selectedTag, setSelectedTag] = useState<datasourceType>(null);
  const [showTagModal, setShowTagModal] = useState(false);
  const [originalTable, setOriginalTable] = useState<datasourceType[]>([]);

  useEffect(() => {
    if (loading) {
      return;
    }
    const tagsTableSource: datasourceType[] = [...tags];

    setOriginalTable(tagsTableSource);
  }, [loading, tags]);

  const onClickRow = (record: datasourceType) => {
    return {
      onClick: () => {
        setSelectedTag(record);
      },
    };
  };

  const columns: BJColumnType<datasourceType>[] = [
    {
      title: "Tag",
      dataIndex: "name",
      key: "name",
      width: 0.5,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  return (
    <>
      <BJList
        addButtonCaption={"New Tag"}
        loading={loading}
        filterOnlyDisplayList
        title={"Tags"}
        OriginalList={originalTable}
        recordCountSuffix={"Tags"}
        columns={columns}
        onClickRow={onClickRow}
        onclick={() => {
          setSelectedTag(null);
          setShowTagModal(true);
        }}
      />
      <TagsModal
        show={!!selectedTag || showTagModal}
        onHide={() => {
          setSelectedTag(null);
          setShowTagModal(false);
        }}
        tag={selectedTag}
      />
    </>
  );
};
