import { useEffect, useState } from "react";
import { commonErrors } from "../../../language";
import { useForm, Controller } from "react-hook-form";
import { BJMainDeepLinkModal, FormModal } from "../../../components";
import {
  AppNavigationType,
  AspectRatio,
  formValidationError,
} from "../../../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";
import {
  BJInputFormItem,
  BJMdFormItem,
  BJSelectFormItem,
} from "../../../components/theme";
import BJInput from "../../../components/theme/atoms/BJInput";
import { FaExternalLinkAlt } from "react-icons/fa";
import { IconWrapper } from "../../../components/styled/BJCommonStyles";
import { useCountry } from "../../../context";
import { useTranslation } from "react-i18next";
import { DropAndCrop } from "../../../components/DropAndCrop";
import { useChecklists } from "../../../hooks/useChecklists";

interface Props {
  show: boolean;
  task: ChecklistTask | null;
  onHide: () => void;
  onUpdate: (task: ChecklistTask) => void;
  onDelete: (id: ChecklistTask["id"]) => void;
  onAdd: (task: ChecklistTask) => void;
}

export const UpdateTaskModal = ({
  show,
  task,
  onHide,
  onUpdate,
  onDelete: onDeleteTask,
  onAdd,
}: Props) => {
  const [isDLVisible, setisDLVisible] = useState(false);
  const { currentCountry, primaryLocale } = useCountry();
  const { i18n } = useTranslation();

  const { uploadChecklistTaskImage } = useChecklists();

  const schema = yup.object().shape({
    productUrl: yup.string().nullable().url(commonErrors.urlValidationError),
    image: yup.string().nullable().url(commonErrors.urlValidationError),
    blurhash: yup.string().nullable(),
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          text: yup
            .string()
            .required(
              `Name (${String(item.key).toUpperCase()}): ${
                commonErrors.requiredError2
              }`
            ),
          description: yup.string().test({
            test: function (value) {
              const image = watch("image");
              if (image && !value) {
                return this.createError({
                  message: `Description (${String(
                    item.key
                  ).toUpperCase()}): is required when image is uploaded`,
                });
              }
              return true;
            },
          }),
          productButtonText: yup
            .string()
            .max(
              10,
              `Button Text (${item.key.toUpperCase()}) Must be less than 10 characters`
            ),
        });
        return acc;
      }, {} as any)
    ),
  });

  const {
    handleSubmit,
    control,
    watch,
    formState,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ChecklistTask>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (task !== null) {
      reset({
        translations: task.translations ?? {},
        productUrl: task.productUrl ?? null,
        image: task.image ?? null,
        itemType: task.itemType,
        blurhash: task?.blurhash,
      });
    } else {
      reset({
        translations: {
          [primaryLocale.key]: {
            text: "",
            description: "",
            productButtonText: "",
          },
        },
        image: null,
        productUrl: null,
        blurhash: null,
      });
    }
  }, [reset, show, task, primaryLocale]);

  const onSubmit = async (data: ChecklistTask) => {
    if (task !== null) {
      const updated = {
        ...task,
        translations: data?.translations,
        productUrl:
          data?.itemType !== AppNavigationType.INVITE ? data.productUrl : null,
        itemType: data?.itemType,
        image: data?.image,
        blurhash: data?.blurhash,
      };
      onUpdate(updated);
    } else {
      const task: ChecklistTask = {
        id: uuidv4(),
        translations: data.translations,
        productUrl: data.productUrl ?? "",
        sortOrder: 999,
        itemType: data?.itemType,
        image: data?.image ?? null,
        blurhash: data?.blurhash,
      };
      onAdd(task);
    }
  };

  const onDelete = async () => {
    if (task !== null) {
      onDeleteTask(task.id);
    }
  };

  const DeepLinkModalActions = {
    show: () => {
      setisDLVisible(true);
    },
    hide: () => {
      setisDLVisible(false);
    },
  };

  const handleUploadedImageBlurhash = (url: string | null, locale: string) => {
    setValue("blurhash", url, { shouldDirty: true });
  };
  const isDirty = !!Object.keys(formState.dirtyFields).length;
  const recIdentifier = watch(`translations.${primaryLocale.key}.text`);
  const contentType = watch("itemType");

  return (
    <FormModal
      onHide={onHide}
      enableSave={isDirty}
      show={show}
      messageOnSubmit={false}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      error={null}
      onDelete={onDelete}
      enableDelete={!!task}
      modalSubTitle={task ? `Id - ${task?.id}` : ""}
      modalTitle={task ? task.translations[i18n.language]?.text : "Update task"}
      recordIdentifier={recIdentifier}
      localeSupported
      size="lg"
      errors={errors as any}
    >
      {locale => (
        <>
          <BJInputFormItem
            label={`Name (${locale?.label}):`}
            fieldName={`translations.${locale?.key}.text`}
            key={`translations.${locale?.key}.text`}
            control={control}
            error={!!errors?.translations?.[locale.key]?.text}
            message={errors?.translations?.[locale.key]?.text?.message}
          />
          <BJMdFormItem
            label={`Description (${locale?.label ?? ""})`}
            fieldName={`translations.${locale.key}.description`}
            key={`translations.${locale.key}.description`}
            control={control}
            error={!!errors?.translations?.[locale.key]?.description}
            message={errors?.translations?.[locale.key]?.description?.message}
          />

          <Form.Item
            label={"Task Image"}
            validateStatus={errors.image && "error"}
            help={
              <Typography.Paragraph type="danger">
                {errors.image?.message}
              </Typography.Paragraph>
            }
          >
            <Controller
              control={control}
              name={"image"}
              render={({ field: { onChange } }) => (
                <DropAndCrop
                  allowNaturalImageUpload={true}
                  title={"Task Image"}
                  initialUrl={task?.image}
                  setUploadUrl={url => onChange(url)}
                  setBlurhash={url =>
                    handleUploadedImageBlurhash(url, locale?.key)
                  }
                  uploadImage={uploadChecklistTaskImage}
                  lockedRatio={AspectRatio.Free}
                  defaultCropBoxWidth={100}
                  defaultCropBoxHeight={100}
                  croppable={true}
                />
              )}
            />
          </Form.Item>

          <BJSelectFormItem
            size="large"
            control={control}
            error={!!errors.itemType}
            label={"Type"}
            message={null}
            extra={"Choose invite for `Invite your partner` button"}
            handleChange={(value: string) => {
              if (value === AppNavigationType.INVITE) {
                setValue("productUrl", null, {
                  shouldDirty: true,
                });
              }
            }}
            optionsList={[
              {
                key: AppNavigationType.EXTERNAL,
                value: AppNavigationType.EXTERNAL,
                display: "External",
              },
              {
                key: AppNavigationType.INVITE,
                value: AppNavigationType.INVITE,
                display: "Invite",
              },
              {
                key: AppNavigationType.DEEP_LINK,
                value: AppNavigationType.DEEP_LINK,
                display: "Deep Link",
              },
            ]}
            fieldName={"itemType"}
          />
          {contentType && contentType !== AppNavigationType.INVITE && (
            <Form.Item
              label="Product URL"
              validateStatus={errors.productUrl && "error"}
              extra={
                <Typography.Paragraph type="danger">
                  {errors.productUrl?.message}
                </Typography.Paragraph>
              }
            >
              <Controller
                control={control}
                name="productUrl"
                render={({ field: { name, value, onChange } }) => (
                  <BJInput
                    name={name}
                    value={value ?? ""}
                    onChange={onChange}
                    suffix={
                      contentType === AppNavigationType.DEEP_LINK && (
                        <IconWrapper
                          onClick={() => DeepLinkModalActions.show()}
                          style={{ paddingTop: 0, paddingBottom: 5 }}
                        >
                          <FaExternalLinkAlt />
                        </IconWrapper>
                      )
                    }
                  />
                )}
              />
            </Form.Item>
          )}

          {contentType && (
            <Form.Item
              label={`Product Button Text (${locale?.label}):`}
              validateStatus={
                errors?.translations &&
                errors?.translations[locale.key]?.productButtonText &&
                "error"
              }
              extra={
                <Typography.Paragraph type="danger">
                  {errors?.translations &&
                    errors?.translations[locale.key]?.productButtonText
                      ?.message}
                </Typography.Paragraph>
              }
            >
              <Controller
                control={control}
                name={`translations.${locale?.key}.productButtonText`}
                key={`translations.${locale?.key}.productButtonText`}
                render={({ field: { name, value, onChange } }) => (
                  <BJInput name={name} value={value!} onChange={onChange} />
                )}
              />
            </Form.Item>
          )}
          <BJMainDeepLinkModal
            isVisible={isDLVisible}
            modalActions={DeepLinkModalActions}
            copyToHOC={link => {
              setValue("productUrl", link);
              setisDLVisible(false);
            }}
          />
        </>
      )}
    </FormModal>
  );
};
