import {
  Col,
  Row,
  Typography,
  Switch,
  Form,
  Divider,
  AutoComplete,
} from "antd";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormModal } from "../../components";
import {
  AspectRatio,
  formValidationError,
  AppNavigationType,
} from "../../utils";
import { HighlightFlowModal } from "./HighlightsFlowModal";
import { useHighlights } from "../../context/HighlightsContext";
import { DropAndCrop } from "../../components/DropAndCrop";
import {
  BJSortableList,
  SortContentType,
} from "../../components/theme/molecules/BJSortableList";
import { arrayMove } from "react-sortable-hoc";
import BJButton from "../../components/theme/atoms/Button";
import { BJFlex } from "../../components/styled";
import { BJInputFormItem, BJSelectFormItem } from "../../components/theme";
import { BJMdFormItem } from "../../components/theme/molecules/formItems/BJFormMarkdown";
import { useFilteredList } from "../../hooks/useFilteredList";
import { highlightsSchema } from "../../schemas";
import { HighlightsFlowList } from "./HighlightsFlowList";
import { useCountry } from "../../context";

interface Props {
  showModal: boolean;
  onHide: () => void;
  highlight?: Highlight | null;
}

type FormValues = {
  id: string;
  title: string;
  description: string;
  flow: Flow[];
  type: string;
  externalUrl: string;
  translations: {
    [locale: string]: {
      buttonText: string;
      title: string;
      description: string;
    };
  };
  imageUrl: string;
  blurhash?: string;
  contentId: string;
  displayInAppMessage: boolean;
};

type SortProps = {
  oldIndex: number;
  newIndex: number;
};

const highlightOptions = [
  {
    key: AppNavigationType.FAQ,
    value: AppNavigationType.FAQ,
    display: AppNavigationType.FAQ,
  },
  {
    key: AppNavigationType.ARTICLE,
    value: AppNavigationType.ARTICLE,
    display: AppNavigationType.ARTICLE,
  },
  {
    key: AppNavigationType.OFFER,
    value: AppNavigationType.OFFER,
    display: AppNavigationType.OFFER,
  },
  {
    key: AppNavigationType.EXTERNAL,
    value: AppNavigationType.EXTERNAL,
    display: AppNavigationType.EXTERNAL,
  },
  {
    key: AppNavigationType.SIGNUP,
    value: AppNavigationType.SIGNUP,
    display: "sign up",
  },
];

export const HighlightModal = ({ showModal, onHide, highlight }: Props) => {
  const { currentCountry } = useCountry();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(highlightsSchema(currentCountry)),
  });

  const [showFlow, setShowFlow] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState<Flow>(null);
  const [flowList, setFlowList] = useState<Flow[]>([]);
  const [isFlowListDirty, setIsFlowListDirty] = useState(false);
  const [contentType, setContentType] = useState<AppNavigationType>(
    AppNavigationType.ARTICLE
  );
  const { filterList, loading } = useFilteredList(contentType);
  const {
    highlights,
    createHighlight,
    updateHighlight,
    deleteHighlight,
    uploadInAppImage,
  } = useHighlights();

  const [sortedHighlights, setSortedHighlights] = useState<SortContentType[]>(
    []
  );
  const [showSortOrder, setShowSortOrder] = useState(false);

  useEffect(() => {
    if (loading) return;
    reset({
      ...highlight,
      id: highlight?.id ?? "",
      description: highlight?.description ?? "",
      contentId: highlight?.inAppMessage?.contentId,
      externalUrl: highlight?.inAppMessage?.externalUrl,
      translations: highlight?.inAppMessage?.translations ?? {},
      type: highlight?.inAppMessage?.type,
      imageUrl: highlight?.inAppMessage?.imageUrl,
      blurhash: highlight?.inAppMessage?.blurhash,
    });
    setContentType(highlight?.inAppMessage?.type as AppNavigationType);
    setSortedHighlights([]);
    setShowSortOrder(false);
    const indexMapped: Flow[] = mapFlowList(highlight?.flow);
    setFlowList(indexMapped);
  }, [highlight, loading, reset, showModal]);

  const onSubmit = async (data: FormValues) => {
    const { id, description } = data;
    const translations: InAppMessage["translations"] = {};

    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        title: value.title ?? "",
        description: value.description ?? "",
        buttonText: value.buttonText ?? "",
      };
    }
    const inAppInfo = {
      type: data.type,
      contentId: data.contentId,
      imageUrl: data.imageUrl,
      blurhash: data.blurhash,
      externalUrl: data.externalUrl,
      translations,
    };
    const payload: Highlight = {
      id,
      description,
      flow: flowList ?? [],
      inAppMessage: inAppInfo,
      displayInAppMessage: data.displayInAppMessage,
    };

    if (highlight) {
      await updateHighlight({
        ...payload,
        id: payload.id,
      });
    } else {
      if (highlights?.some(x => x.id === id)) {
        throw new Error(
          "This version id already exists, Try editing the record"
        );
      }
      await createHighlight(payload);
    }
  };

  const onDelete = async () => {
    if (highlight !== null) {
      await deleteHighlight(highlight?.id);
    } else {
      throw new Error("Highlight record not found");
    }
  };

  const onDeleteFlow = async (flow: Flow) => {
    const filteredFlowList = flowList.filter(x => x.id !== flow.id);
    const mappedFlowList = mapFlowList(filteredFlowList);
    setFlowList(mappedFlowList);
    setIsFlowListDirty(true);
  };

  const mapFlowList = (flowList: Flow[]) => {
    return flowList?.map((x, index) => ({ ...x, id: index, key: index }));
  };

  const handleInAppImageUrl = (url: string | null) => {
    setValue("imageUrl", url, { shouldDirty: true });
  };

  const handleInAppBlurhash = (url: string | null) => {
    setValue("blurhash", url, { shouldDirty: true });
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortProps) => {
    if (oldIndex === newIndex) {
      return;
    }
    const swappedArray = arrayMove([...sortedHighlights], oldIndex, newIndex);
    const mappedFlowList: Flow[] = swappedArray.map(x => {
      return flowList?.find(y => y.id === x.id);
    });
    setFlowList(mappedFlowList);
    setSortedHighlights(swappedArray);
  };

  const isDirty =
    !!Object.keys(formState.dirtyFields).length ||
    isFlowListDirty ||
    sortedHighlights?.length > 0;
  const title = watch("title");

  const onUpdateFlow = (updatedFlow: Flow) => {
    if (updatedFlow?.id > -1) {
      const mapped = flowList.map((x, index) => {
        if (x.id === updatedFlow.id)
          return { ...updatedFlow, id: index, key: index };
        return { ...x, id: index, key: index };
      });
      setFlowList(mapped);
    } else {
      const newFlow = [...(flowList ?? []), updatedFlow];
      const mapped = mapFlowList(newFlow);
      setFlowList(mapped);
    }
    setIsFlowListDirty(true);
    setSelectedFlow(null);
  };

  const { type, displayInAppMessage } = watch();

  const defaultContentIdValue =
    filterList?.find(x => x.key === highlight?.inAppMessage?.contentId)
      ?.value ?? "";
  return (
    <>
      <FormModal
        formId={"highlight"}
        size="xl"
        onHide={onHide}
        key={"highlight"}
        enableSave={isDirty}
        show={showModal}
        onDelete={onDelete}
        enableDelete={!!highlight}
        modalTitle={highlight ? highlight?.id : "Add new highlight"}
        recordIdentifier={title}
        onSubmit={handleSubmit(onSubmit, formValidationError)}
        errors={errors as any}
        localeSupported
      >
        {locale => (
          <Row gutter={24}>
            <Col span={12}>
              <BJInputFormItem
                fieldName={"id"}
                label="Version Id"
                key={`id`}
                message={errors.id?.message}
                error={!!errors.id}
                disabled={!!highlight}
                control={control}
                autoFocus
                required
              />
              <BJMdFormItem
                fieldName={`description`}
                label={`Description`}
                control={control}
              />
              <Col span={24}>
                <Divider orientation="center">Highlights flow</Divider>

                {!showSortOrder ? (
                  <HighlightsFlowList
                    flowList={flowList}
                    setSelectedFlow={setSelectedFlow}
                    setShowFlow={setShowFlow}
                    setShowSortOrder={setShowSortOrder}
                    setSortedHighlights={setSortedHighlights}
                  />
                ) : (
                  <>
                    <BJFlex justifyRight flex-direction={"row"}>
                      <BJButton
                        onClick={() => {
                          setShowSortOrder(false);
                        }}
                      >
                        Back
                      </BJButton>
                    </BJFlex>
                    <BJSortableList
                      items={sortedHighlights}
                      onSortEnd={onSortEnd}
                    />
                  </>
                )}
              </Col>
            </Col>
            <Col span={12}>
              <Divider orientation="left"> In app Message</Divider>
              <Row>
                <Col span={24}>
                  <Form.Item label="Enable in app messaging" key="external">
                    <Controller
                      control={control}
                      name="displayInAppMessage"
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          onChange={e => {
                            onChange(e);
                          }}
                          checked={value}
                        />
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <BJInputFormItem
                    fieldName={`translations.${locale?.key}.title`}
                    label={`Title (${locale?.label ?? ""})`}
                    key={`translations.${locale?.key}.title`}
                    message={errors?.translations?.[locale.key]?.title?.message}
                    error={!!errors?.translations?.[locale.key]?.title}
                    disabled={!displayInAppMessage}
                    control={control}
                    required
                  />
                </Col>
                <Col span={12}>
                  <BJInputFormItem
                    fieldName={`translations.${locale?.key}.buttonText`}
                    label={`Button text (${locale?.label ?? ""})`}
                    key={`translations.${locale?.key}.buttonText`}
                    message={
                      errors?.translations?.[locale.key]?.buttonText?.message
                    }
                    error={!!errors?.translations?.[locale.key]?.buttonText}
                    disabled={!displayInAppMessage}
                    control={control}
                    required={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <BJMdFormItem
                    fieldName={`translations.${locale?.key}.description`}
                    label={`Description (${locale?.label ?? ""})`}
                    key={`translations.${locale?.key}.description`}
                    message={
                      errors?.translations?.[locale.key]?.description?.message
                    }
                    error={!!errors?.translations?.[locale.key]?.description}
                    disabled={!displayInAppMessage}
                    control={control}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Image"
                    validateStatus={errors?.imageUrl?.message && "error"}
                    {...(errors?.imageUrl?.message
                      ? {
                          help: (
                            <Typography.Paragraph type="danger">
                              {errors?.imageUrl?.message}
                            </Typography.Paragraph>
                          ),
                        }
                      : undefined)}
                    required
                  >
                    <Controller
                      control={control}
                      name="title"
                      render={() => (
                        <DropAndCrop
                          disabled={!displayInAppMessage}
                          title="In app image"
                          setUploadUrl={handleInAppImageUrl}
                          setBlurhash={handleInAppBlurhash}
                          uploadImage={uploadInAppImage}
                          initialUrl={highlight?.inAppMessage?.imageUrl}
                          lockedRatio={AspectRatio.Free}
                        />
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <BJSelectFormItem
                    size="large"
                    disabled={!displayInAppMessage}
                    control={control}
                    required={true}
                    error={!!errors.type}
                    label={"Type"}
                    extra="select type, select none if you don't want any types"
                    message={errors.type?.message}
                    defaultValue={highlight?.inAppMessage?.type}
                    handleChange={(value: string) => {
                      setContentType(value as AppNavigationType);
                      setValue("contentId", null, {
                        shouldDirty: true,
                      });
                      setValue("externalUrl", null, {
                        shouldDirty: true,
                      });
                    }}
                    optionsList={highlightOptions}
                    fieldName={"type"}
                  />
                </Col>
                {!(type === AppNavigationType.SIGNUP) && (
                  <Col span={16}>
                    {type !== AppNavigationType.EXTERNAL ? (
                      <Form.Item
                        label="Title/ Question"
                        key="content"
                        required={true}
                        validateStatus={errors.contentId && "error"}
                        {...(errors.contentId
                          ? {
                              help: (
                                <Typography.Paragraph type="danger">
                                  {errors.contentId.message}
                                </Typography.Paragraph>
                              ),
                            }
                          : {})}
                        extra={
                          <Typography.Paragraph type="warning">
                            {"select title/ question"}
                          </Typography.Paragraph>
                        }
                      >
                        <Controller
                          control={control}
                          name="contentId"
                          render={({ field: { onChange } }) => (
                            <AutoComplete
                              size="large"
                              allowClear
                              disabled={!displayInAppMessage}
                              notFoundContent={"not found"}
                              backfill
                              defaultValue={defaultContentIdValue}
                              key={defaultContentIdValue}
                              options={filterList}
                              placeholder={`type title name to select `}
                              filterOption={(inputValue, option) =>
                                option!.value &&
                                option!.value
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              }
                              onSelect={(value, option) => {
                                onChange(option!.key);
                              }}
                              onChange={value => {
                                const x = filterList?.find(
                                  x => x.value === value
                                );
                                onChange(x?.key);
                              }}
                            />
                          )}
                        />
                      </Form.Item>
                    ) : (
                      <BJInputFormItem
                        control={control}
                        error={!!errors.externalUrl}
                        label={"External url"}
                        extra="enter external url"
                        message={errors.externalUrl?.message}
                        fieldName={"externalUrl"}
                        disabled={!displayInAppMessage}
                        required={true}
                      />
                    )}
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        )}
      </FormModal>
      {showFlow && (
        <HighlightFlowModal
          onDeleteFlow={onDeleteFlow}
          onUpdateFlow={onUpdateFlow}
          showFlowModal={showFlow}
          onHideFlowModal={() => {
            setShowFlow(false);
            setSelectedFlow(null);
          }}
          flow={selectedFlow}
        />
      )}
    </>
  );
};
