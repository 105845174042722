import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useAuthToken } from "./AuthContext";
import { CountryService } from "../services/CountryService";

const SELECTED_COUNTRY_KEY = "SELECTED_COUNTRY";

interface ContextState {
  countries?: Country[];
  allCountries?: Country[];
  currentCountry?: Country;
  primaryLocale?: Locale;
  setCountry?: (countryAbb: string) => void;
  resetCountry: () => void;
}

const CountryContext = createContext<ContextState>({
  countries: [],
  allCountries: [],
  currentCountry: undefined,
  setCountry: () => null,
  resetCountry: () => null,
});

export const CountryProvider = ({ ...rest }) => {
  const { token } = useAuthToken();
  const [countries, setCountries] = useState<Country[]>([]);
  const [allCountries, setAllCountries] = useState<Country[]>([]);
  const [currentCountry, setCurrentCountry] = useState<Country>();

  useEffect(() => {
    const country = localStorage.getItem(SELECTED_COUNTRY_KEY);
    setCurrentCountry(country ? JSON.parse(country) : undefined);
  }, []);

  useEffect(() => {
    if (!token) {
      return;
    }

    CountryService.subscribe((error, countries) => {
      const allowedCountries = token.claims.countries?.ALL
        ? (Object.values(countries) as unknown as Country[])
        : Object.values(countries).filter(
            country => token.claims.countries?.[country.abb.toUpperCase()]
          );
      setCountries(allowedCountries);
      setAllCountries(Object.values(countries));
    });
  }, [token]);

  useEffect(() => {
    if (!currentCountry && countries.length) {
      setCurrentCountry(countries.find(({ isActive }) => isActive));
    }
  }, [countries, currentCountry]);

  const setCountry = useCallback(
    (countryAbb: string) => {
      const country = countries.find(country => country.abb === countryAbb);
      localStorage.setItem(SELECTED_COUNTRY_KEY, JSON.stringify(country));
      setCurrentCountry(country);
    },
    [countries]
  );

  const resetCountry = useCallback(() => {
    localStorage.removeItem(SELECTED_COUNTRY_KEY);
    setCurrentCountry(undefined);
  }, [setCurrentCountry]);

  const primaryLocale = useMemo(() => {
    return currentCountry?.locales.find(locale => locale.primary);
  }, [currentCountry]);

  const value = useMemo(
    () => ({
      countries,
      allCountries,
      currentCountry,
      setCountry,
      resetCountry,
      primaryLocale,
    }),
    [
      countries,
      allCountries,
      currentCountry,
      setCountry,
      resetCountry,
      primaryLocale,
    ]
  );

  return <CountryContext.Provider value={value} {...rest} />;
};

export const useCountry = () => useContext(CountryContext);
