import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";

import { SponsorService } from "../services";
import { useCountry } from "./CountryContext";

type ContextState = {
  sponsors: Sponsor[];
  loading: boolean;
  error: Error | null;
  getById: (id: Sponsor["id"]) => Sponsor | null;
};

const SponsorsContext = createContext<ContextState>({
  sponsors: [],
  loading: false,
  error: null,
  getById: () => null,
});

export const SponsorsProvider = ({ ...rest }) => {
  const [sponsors, setSponsors] = useState<Sponsor[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const { currentCountry } = useCountry();

  const sponsorService = useMemo(
    () => new SponsorService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    setLoading(true);
    const unsub = sponsorService.subscribe((_error, _sponsors) => {
      setSponsors(_sponsors);
      setError(_error);
      setLoading(false);
    });

    return unsub;
  }, [sponsorService]);

  const getById = useCallback(
    (id: Sponsor["id"]) => {
      return sponsors.find(a => a.id === id) || null;
    },
    [sponsors]
  );

  const value = useMemo(
    () => ({ sponsors, loading, error, getById }),
    [sponsors, loading, error, getById]
  );

  return <SponsorsContext.Provider value={value} {...rest} />;
};

export const useSponsors = () => {
  const context = React.useContext(SponsorsContext);
  if (context === undefined) {
    throw new Error("useSponsors must be used within an SponsorsProvider");
  }
  return context;
};

export const useSponsor = (sponsorId: Sponsor["id"]) => {
  const [sponsor, setSponsor] = useState<Sponsor | null>(null);
  const [loading, setLoading] = useState(true);

  const { getById, loading: loadingSponsors } = useSponsors();

  useEffect(() => {
    if (loadingSponsors) {
      return;
    }
    setSponsor(getById(sponsorId));
    setLoading(false);
  }, [loadingSponsors, getById, sponsorId]);

  return { sponsor, loading };
};
