import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Row, Col, Typography, Form } from "antd";
import { useImage } from "../../context";
import { ImagesService } from "../../services";
import { AspectRatio, formValidationError } from "../../utils";
import { FormEdit, FormEditType } from "../../components";
import { DropAndCrop } from "../../components/DropAndCrop";
import { commonErrors } from "../../language";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BJInputFormItem } from "../../components/theme";
import { cms, images } from "../../routes/routeConsts";

type FormValues = {
  imageUrl?: string;
  blurhash?: string;
  internalName: string;
};
const { requiredError } = commonErrors;

const schema = yup.object().shape({
  internalName: yup.string().required(requiredError),
  imageUrl: yup
    .string()
    .required(commonErrors.requiredError)
    .url(commonErrors.urlValidationError),
});

export const UploadImagePage = () => {
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const { image, loading: loadingImages } = useImage(id);
  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
  } = useForm<FormValues>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (loadingImages || image === null) {
      return;
    }

    reset({
      ...image,
    });
  }, [image, reset, loadingImages]);

  const onSubmit = async (data: FormValues) => {
    const updateData: Image = {
      imageUrl: data.imageUrl ?? "",
      internalName: data.internalName,
      blurhash: data.blurhash ?? "",
    };

    console.log("updateData: ", updateData);

    if (image) {
      await ImagesService.update(image.id, updateData);
    } else {
      const { id } = await ImagesService.create(updateData);
      return navigate(`../${id}`);
    }
  };

  const onRemove = async () => {
    if (image) {
      await ImagesService.delete(image.id);
      return navigate("./..");
    }
  };

  const handleImageImageUrl = (url: string | null) => {
    setValue("imageUrl", url, { shouldDirty: true });
  };

  const handleImageBlurhash = (url: string | null) => {
    setValue("blurhash", url, { shouldDirty: true });
  };

  const isDirty = !!Object.keys(dirtyFields).length;

  const { internalName } = watch();

  return (
    <FormEdit
      onRemove={onRemove}
      backRoutePath={`${cms}/${images}`}
      hasValidationErrors={Object.keys(errors).length !== 0}
      enableSave={isDirty}
      title={image ? image?.internalName : "New Image"}
      id={image?.id}
      editType={image?.id ? FormEditType.EDIT : FormEditType.ADD}
      loading={loadingImages}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      recordIdentifier={internalName}
    >
      <Row gutter={{ md: 20 }}>
        <Col md={24} lg={12}>
          <BJInputFormItem
            control={control}
            error={!!errors?.internalName}
            label={"Internal name"}
            message={errors.internalName?.message}
            required={true}
            autoFocus
            fieldName={"internalName"}
          />
          <Form.Item
            required
            label="Image image"
            extra=""
            validateStatus={errors.imageUrl && "error"}
            help={
              <Typography.Paragraph type="danger">
                {errors.imageUrl?.message}
              </Typography.Paragraph>
            }
          >
            <DropAndCrop
              title="Image Image"
              allowNaturalImageUpload
              initialUrl={image?.imageUrl}
              setUploadUrl={handleImageImageUrl}
              uploadImage={ImagesService.uploadImageImage}
              lockedRatio={AspectRatio.Free}
              defaultCropBoxWidth={100}
              defaultCropBoxHeight={100}
              setBlurhash={handleImageBlurhash}
            />
          </Form.Item>
          <BJInputFormItem
            control={control}
            error={!!errors?.internalName}
            label={"Image url"}
            message={errors.internalName?.message}
            fieldName={"imageUrl"}
            allowCopy={true}
            disabled
          />
        </Col>
      </Row>
    </FormEdit>
  );
};
