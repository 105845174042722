import { useEffect, useState } from "react";
import { Col, Row, Tabs } from "antd";
import {
  BJContainer,
  BJInputFormItem,
  BJSelectFormItem,
} from "../../components/theme";
import { useNavigate, useParams } from "react-router-dom";
import { useCountry, useVerifiers, useWeekInformation } from "../../context";
import {
  ContentListType,
  formValidationError,
  getOrdinal,
  isEqualArrays,
  recommendedContentTitle,
  valueOrNull,
} from "../../utils";
import { ConfirmationModal, FormEdit, FormEditType } from "../../components";
import { useForm } from "react-hook-form";
import { ContentSelector } from "../Popular/ContentSelector";
import { BJMdFormItem } from "../../components/theme/molecules/formItems/BJFormMarkdown";
import styled from "styled-components";
import { formatPayload } from "../../helper/recommendedContent";

type FormValues = {
  week: number;
  child: {
    verifierId?: string;
    translations?: {
      [key: string]: {
        audioUrl: string;
        content: string;
      };
    };
  };
  mother: {
    verifierId?: string;
    translations?: {
      [key: string]: {
        audioUrl: string;
        content: string;
      };
    };
  };
  partner: {
    verifierId?: string;
    translations?: {
      [key: string]: {
        audioUrl: string;
        content: string;
      };
    };
  };
};

export const WeekInformationPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const {
    weekInformation,
    loading,
    createWeeklyInfo,
    updateWeeklyInfo,
    deleteWeeklyInfo,
    weeklyInformations,
  } = useWeekInformation(id);
  const { verifiers, getById: getVerifierById } = useVerifiers();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { primaryLocale } = useCountry();
  const {
    handleSubmit,
    watch,
    formState,
    formState: { errors },
    reset,
    control,
  } = useForm<FormValues>();

  const [managedContent, setManagedContent] = useState<RecommendedContent[]>(
    []
  );
  const [motherRecommendedContent, setMotherRecommendedContent] = useState<
    RecommendedContent[]
  >([]);
  const [partnerRecommendedContent, setPartnerRecommendedContent] = useState<
    RecommendedContent[]
  >([]);

  useEffect(() => {
    if (!weekInformation) {
      return;
    }
    setMotherRecommendedContent(weekInformation?.mother?.recommendedContent);
    setPartnerRecommendedContent(weekInformation?.partner?.recommendedContent);
    reset({
      ...weekInformation,
      child: {
        ...weekInformation.child,
        verifierId: weekInformation.child?.verifier?.id,
      },
      mother: {
        ...weekInformation.mother,
        verifierId: weekInformation.mother?.verifier?.id,
      },
      partner: {
        ...weekInformation.partner,
        verifierId: weekInformation.partner?.verifier?.id,
      },
    });
  }, [reset, weekInformation, primaryLocale]);

  const calculateVersion = (version: string | null) =>
    version !== null
      ? `${parseFloat(weekInformation?.child.version) + 0.1}`
      : "1.0.0";

  const onSubmit = async (formData: FormValues) => {
    const currentTime = Date.now();
    const childTranslations = {
      ...formData.child?.translations,
    };
    const motherTranslations = {
      ...formData.mother?.translations,
    };
    const partnerTranslations = {
      ...formData.partner?.translations,
    };

    for (const [key, value] of Object.entries(childTranslations)) {
      childTranslations[key] = {
        audioUrl: valueOrNull(value?.audioUrl),
        content: value?.content ?? "",
      };
    }

    for (const [key, value] of Object.entries(motherTranslations)) {
      motherTranslations[key] = {
        audioUrl: valueOrNull(value?.audioUrl),
        content: value?.content ?? "",
      };
    }

    for (const [key, value] of Object.entries(partnerTranslations)) {
      partnerTranslations[key] = {
        audioUrl: valueOrNull(value?.audioUrl),
        content: value?.content ?? "",
      };
    }

    const typeData = {
      time: currentTime,
      version: calculateVersion(weekInformation?.child?.version),
      verifier: getVerifierById(formData?.child?.verifierId),
      recommendedContent: managedContent.map(formatPayload),
      recommendedContentMap: managedContent?.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {} as { [key: string]: CommonContentV2 }),

      translations: childTranslations ?? {},
    };
    const motherData = {
      time: currentTime,
      version: calculateVersion(weekInformation?.mother?.version),
      verifier: getVerifierById(formData?.mother?.verifierId),
      recommendedContent: motherRecommendedContent.map(formatPayload),
      recommendedContentMap: motherRecommendedContent?.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {} as { [key: string]: CommonContentV2 }),
      translations: motherTranslations ?? {},
    };

    const partnerData = {
      time: currentTime,
      version: calculateVersion(weekInformation?.partner?.version),
      verifier: getVerifierById(formData?.partner?.verifierId),
      recommendedContent: partnerRecommendedContent.map(formatPayload),
      recommendedContentMap: partnerRecommendedContent?.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {} as { [key: string]: CommonContentV2 }),
      translations: partnerTranslations ?? {},
    };

    const data = {
      week: formData.week,
      child: typeData,
      mother: motherData,
      partner: partnerData,
    };

    if (weekInformation?.id) {
      await updateWeeklyInfo(weekInformation.id, {
        ...weekInformation,
        ...data,
      });
    } else {
      const { id } = await createWeeklyInfo(data);
      return navigate(`../${id}`);
    }
  };

  const onRemove = async () => {
    if (weekInformation) {
      await deleteWeeklyInfo(weekInformation.id);
      return navigate("./..");
    }
  };

  const isDirty =
    !!Object.keys(formState.dirtyFields).length ||
    !isEqualArrays(
      weekInformation?.child?.recommendedContent ?? [],
      managedContent
    ) ||
    !isEqualArrays(
      weekInformation?.mother.recommendedContent ?? [],
      motherRecommendedContent
    ) ||
    !isEqualArrays(
      weekInformation?.partner?.recommendedContent ?? [],
      partnerRecommendedContent
    );

  const childAudioUrl = (locale: string) =>
    watch(`child.translations.${locale}.audioUrl`) ?? "";
  const motherAudioUrl = (locale: string) =>
    watch(`mother.translations.${locale}.audioUrl`) ?? "";
  const partnerAudioUrl = (locale: string) =>
    watch(`partner.translations.${locale}.audioUrl`) ?? "";

  return (
    <BJContainer>
      <ConfirmationModal
        show={showDeleteModal}
        text="Do you really want to delete this week information? This action cannot be undone."
        onHide={() => setShowDeleteModal(false)}
        onConfirm={onRemove}
      />
      <FormEdit
        localeSupported
        onRemove={onRemove}
        hasValidationErrors={Object.keys(errors).length !== 0}
        enableSave={isDirty}
        title={
          weekInformation
            ? getOrdinal(weekInformation.week) + " Week"
            : "New week"
        }
        id={weekInformation?.id}
        editType={weekInformation?.id ? FormEditType.EDIT : FormEditType.ADD}
        loading={loading}
        onSubmit={handleSubmit(onSubmit, formValidationError)}
        recordIdentifier={weekInformation?.id}
        errors={errors as any}
      >
        {locale => (
          <>
            <Col span={6}>
              <BJSelectFormItem
                control={control}
                error={!!errors?.week}
                label={"Week"}
                message={errors?.week?.message}
                optionsList={Array.from({ length: 42 }, (_, i) => i + 1)
                  .filter(_v => !weeklyInformations.some(v => v.week === _v))
                  .map(_v => ({
                    key: _v,
                    value: _v,
                    display: `${_v} Week`,
                  }))}
                fieldName={"week"}
                required
              />
            </Col>
            <Tabs defaultActiveKey="child" id="week-information-tabs">
              <Tabs.TabPane key="child" tab="Child">
                <Row align={"middle"} gutter={4}>
                  <Col span={16}>
                    <BJInputFormItem
                      size={"large"}
                      control={control}
                      error={
                        !!errors?.child?.translations[locale.key]?.audioUrl
                      }
                      label={`Audio Url (${locale?.label ?? ""})`}
                      message={
                        errors?.child?.translations[locale.key]?.audioUrl
                          ?.message
                      }
                      fieldName={`child.translations.${locale.key}.audioUrl`}
                      key={`child.translations.${locale.key}.audioUrl`}
                      autoFocus
                    />
                  </Col>
                  <Col span={6}>
                    <StyledAudio
                      controls
                      src={childAudioUrl(locale.key) ?? ""}
                    />
                  </Col>
                </Row>
                <Row gutter={6}>
                  <Col span={6}>
                    <BJSelectFormItem
                      includeEmpty
                      control={control}
                      error={!!errors.child?.verifierId}
                      label={"Verifier"}
                      message={errors.child?.verifierId?.message}
                      optionsList={verifiers.map(_verifier => ({
                        key: _verifier.id,
                        value: _verifier.id,
                        display: _verifier.name,
                      }))}
                      fieldName={"child.verifierId"}
                    />
                  </Col>
                </Row>

                <BJMdFormItem
                  control={control}
                  error={!!errors?.child?.translations[locale.key]?.content}
                  label={`Child content (${locale?.label ?? ""})`}
                  message={
                    errors?.child?.translations[locale.key]?.content?.message
                  }
                  fieldName={`child.translations.${locale.key}.content`}
                  key={`child.translations.${locale.key}.content`}
                />
                <ContentSelector
                  headerTitle={recommendedContentTitle}
                  displayButtons={false}
                  setManagedContent={setManagedContent}
                  initialContent={weekInformation?.child?.recommendedContent}
                  contentListType={ContentListType.RecommendedContent}
                />
              </Tabs.TabPane>
              <Tabs.TabPane key="mother" tab="Mother">
                <Row align={"middle"} gutter={4}>
                  <Col span={16}>
                    <BJInputFormItem
                      size={"large"}
                      control={control}
                      error={
                        !!errors?.mother?.translations[locale.key]?.audioUrl
                      }
                      label={`Audio Url (${locale?.label ?? ""})`}
                      message={
                        errors?.mother?.translations[locale.key]?.audioUrl
                          ?.message
                      }
                      fieldName={`mother.translations.${locale.key}.audioUrl`}
                      key={`mother.translations.${locale.key}.audioUrl`}
                      autoFocus
                    />
                  </Col>
                  <Col span={6}>
                    <StyledAudio
                      controls
                      src={motherAudioUrl(locale.key) ?? ""}
                    />
                  </Col>
                </Row>

                <Row gutter={6}>
                  <Col span={6}>
                    <BJSelectFormItem
                      includeEmpty
                      control={control}
                      error={!!errors.mother?.verifierId}
                      label={"Verifier"}
                      message={errors.mother?.verifierId?.message}
                      optionsList={verifiers.map(_verifier => ({
                        key: _verifier.id,
                        value: _verifier.id,
                        display: _verifier.name,
                      }))}
                      fieldName={"mother.verifierId"}
                    />
                  </Col>
                </Row>

                <BJMdFormItem
                  control={control}
                  error={!!errors?.mother?.translations[locale.key]?.content}
                  label={`Mother content (${locale?.label ?? ""})`}
                  message={
                    errors?.mother?.translations[locale.key].content?.message
                  }
                  fieldName={`mother.translations.${locale.key}.content`}
                  key={`mother.translations.${locale.key}.content`}
                />
                <ContentSelector
                  headerTitle={recommendedContentTitle}
                  displayButtons={false}
                  setManagedContent={setMotherRecommendedContent}
                  initialContent={weekInformation?.mother?.recommendedContent}
                  contentListType={ContentListType.RecommendedContent}
                />
              </Tabs.TabPane>
              <Tabs.TabPane key="partner" tab="Partner">
                <Row align={"middle"} gutter={4}>
                  <Col span={16}>
                    <BJInputFormItem
                      size={"large"}
                      control={control}
                      error={
                        !!errors?.partner?.translations[locale.key]?.audioUrl
                      }
                      label={`Audio Url (${locale?.label ?? ""})`}
                      message={
                        errors?.partner?.translations[locale.key]?.audioUrl
                          ?.message
                      }
                      fieldName={`partner.translations.${locale.key}.audioUrl`}
                      key={`partner.translations.${locale.key}.audioUrl`}
                      autoFocus
                    />
                  </Col>
                  <Col span={6}>
                    <StyledAudio
                      controls
                      src={partnerAudioUrl(locale.key) ?? ""}
                    />
                  </Col>
                </Row>

                <Row gutter={6}>
                  <Col span={6}>
                    <BJSelectFormItem
                      includeEmpty
                      control={control}
                      error={!!errors.partner?.verifierId}
                      label={"Verifier"}
                      message={errors.partner?.verifierId?.message}
                      optionsList={verifiers.map(_verifier => ({
                        key: _verifier.id,
                        value: _verifier.id,
                        display: _verifier.name,
                      }))}
                      fieldName={"partner.verifierId"}
                    />
                  </Col>
                </Row>

                <BJMdFormItem
                  control={control}
                  error={!!errors?.partner?.translations[locale.key]?.content}
                  label={`Partner content (${locale?.label ?? ""})`}
                  message={
                    errors?.partner?.translations[locale.key]?.content?.message
                  }
                  fieldName={`partner.translations.${locale.key}.content`}
                  key={`partner.translations.${locale.key}.content`}
                />
                <ContentSelector
                  headerTitle={recommendedContentTitle}
                  displayButtons={false}
                  setManagedContent={setPartnerRecommendedContent}
                  initialContent={weekInformation?.partner?.recommendedContent}
                  contentListType={ContentListType.RecommendedContent}
                />
              </Tabs.TabPane>
            </Tabs>
          </>
        )}
      </FormEdit>
    </BJContainer>
  );
};

const StyledAudio = styled.audio`
  margin-top: 0.8rem;
`;
