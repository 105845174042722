import { firestore } from "../firebase";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";

const toLevelsData = (
  doc: firebase.default.firestore.DocumentSnapshot
): LoyaltyContent => {
  const data = doc.data();
  return {
    levelInformations:
      data?.levelInformations?.map((item: LevelInformation) => ({
        ...item,
        features: item?.features ?? [],
      })) ?? [],
    loyaltyPopups: data?.loyaltyPopups ?? {},
  };
};

export class LoyaltyService extends CountryCodeService {
  constructor(locale: string) {
    super(locale);
  }

  subscribe = (
    callback: (err: Error | null, content: LoyaltyContent | null) => void
  ) =>
    firestore
      .collection("loyaltyContent")
      .doc(this.countryCode)
      .onSnapshot(
        snapshot => {
          callback(null, toLevelsData(snapshot));
        },
        error => {
          logException(`Error subscribing to levels data: ${error}`);
          callback(error, null);
        }
      );

  upsert = (data: Partial<LoyaltyContent>) => {
    firestore
      .collection("loyaltyContent")
      .doc(this.countryCode)
      .set(data, { merge: true });
  };
}
