import { useEffect, useState } from "react";
import { useCountry, useOffers } from "../../../context";
import { commonErrors } from "../../../language";
import {
  getFormattedErrorMessage,
  CommonContentType,
  capitalizeFirstLetter,
} from "../../../utils";
import { Sorter } from "../../../components/theme/util/sorter";
import { BJColumnType } from "../../../components/theme/molecules/BJTable";
import { BJSelectListModal } from "../../../components/theme/components/BJSelectListModal";
import { useFilterContent } from "../../../hooks/contentCollections/useFilterContent";

interface Props {
  show: boolean;
  onHide: () => void;
  maxNoOfContentLimit: number;
  selectedContent: CommonContent[];
  onAdd: (content: CommonContent[]) => void;
  offerType?: OfferPropType;
  includePreviousContent?: boolean; // or retainExistingContent, preserveSelectedContent, appendToExistingContent, mergeWithExistingContent
}

type OfferPropType = string[] | null;

interface DataSourceType extends Offer {
  key: string;
}

export const AddOfferModal = ({
  show: showModal,
  onHide,
  maxNoOfContentLimit,
  selectedContent,
  onAdd,
  offerType = null,
  includePreviousContent = true,
}: Props) => {
  const { offers } = useOffers();
  const { currentCountry } = useCountry();
  const [dataSource, setDataSource] = useState<DataSourceType[]>([]);
  const {
    updatedContent,
    selectedContentCollections,
    setSelectedContentCollections,
  } = useFilterContent<DataSourceType>(
    selectedContent,
    maxNoOfContentLimit,
    CommonContentType.OFFER,
    dataSource
  );
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setDataSource(() => {
      const offerList: DataSourceType[] = [];
      offers.forEach(_offer => {
        if (offerType && !offerType.includes(_offer.type)) return;
        offerList.push({
          ..._offer,
          key: _offer.id,
          ...Object.entries(_offer.translations ?? {}).reduce(
            (acc, [locale, values]) => (
              (acc[`title${capitalizeFirstLetter(locale)}`] = values.title), acc
            ),
            {} as any
          ),
        });
      });
      return offerList;
    });
  }, [offers, offerType]);

  useEffect(() => {
    setError(
      maxNoOfContentLimit < selectedContentCollections.length
        ? commonErrors.contentLimitError
        : null
    );
  }, [maxNoOfContentLimit, selectedContentCollections]);

  const onSubmit = async () => {
    try {
      onAdd(
        includePreviousContent
          ? updatedContent
          : (selectedContentCollections as CommonContent[])
      );
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
    }
  };

  const columns: BJColumnType<DataSourceType>[] = [
    ...currentCountry?.locales
      ?.filter(locale => locale.primary)
      ?.map(locale => ({
        title: `Title (${locale?.label})`,
        dataIndex: `title${capitalizeFirstLetter(locale?.key)}`,
        key: `title${capitalizeFirstLetter(locale?.key)}`,
        width: 1,
        ellipsis: true,
        sorter: {
          compare: Sorter.DEFAULT,
        },
      })),
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  return (
    <BJSelectListModal
      recordCountSuffix={"Offers"}
      modalTitle={"Add Offers"}
      error={error!}
      show={showModal}
      onHide={onHide}
      disable={selectedContentCollections.length > maxNoOfContentLimit}
      maxNoOfContentLimit={maxNoOfContentLimit}
      columns={columns}
      selectedRecords={selectedContentCollections}
      updateSelectedRecords={setSelectedContentCollections}
      dataSource={dataSource}
      onSubmit={onSubmit}
    />
  );
};
