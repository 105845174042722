import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { OffersService } from "../services";
import { useCountry } from "./CountryContext";

type ContextState = {
  offers: OfferV2[];
  offerSignUps: OfferSignUp[];
  loading: boolean;
  loadingOfferSignup: boolean;
  error: Error | null;
  offersService: OffersService | null;
};

const OffersContext = createContext<ContextState>({
  offers: [],
  offerSignUps: [],
  loading: false,
  loadingOfferSignup: false,
  error: null,
  offersService: null,
});

export const OffersProvider = ({ ...rest }) => {
  const [offers, setOffers] = useState<OfferV2[]>([]);
  const [offerSignUps, setOfferSignUps] = useState<OfferSignUp[]>([]);
  const [offerSignUpData, setOfferSignUpData] = useState<OfferSignUp[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingOfferSignup, setLoadingOfferSignUp] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const offersService = useMemo(
    () => (currentCountry?.abb ? new OffersService(currentCountry.abb) : null),
    [currentCountry?.abb]
  );

  useEffect(() => {
    setLoading(true);
    setLoadingOfferSignUp(true);

    if (!offersService) {
      return;
    }

    const unsub = offersService.subscribe((_error, _offers) => {
      setOffers(_offers);
      setError(_error);
      setLoading(false);
    });

    const unsub2 = offersService.subscribeSignUpOffers((_error, signups) => {
      setOfferSignUpData(signups);
      setLoadingOfferSignUp(false);
    });

    return () => {
      unsub();
      unsub2();
    };
  }, [offersService]);

  useEffect(() => {
    if (loading || loadingOfferSignup) {
      return;
    }

    const offerMap = new Map<OfferV2["id"], OfferV2>();
    offers.forEach(offer => {
      offerMap.set(offer.id, offer);
    });

    const _signUpOffers: OfferSignUp[] = offerSignUpData.map(signup => {
      return {
        ...signup,
        offer: offerMap.get(signup.offerId) || null,
      };
    });
    setOfferSignUps(_signUpOffers);
  }, [loading, loadingOfferSignup, offers, offerSignUpData]);

  const value = useMemo(
    () => ({
      offers,
      offerSignUps,
      loading,
      loadingOfferSignup,
      error,
      offersService,
    }),
    [offers, offerSignUps, loading, loadingOfferSignup, error, offersService]
  );

  return <OffersContext.Provider value={value} {...rest} />;
};

export const useOffers = () => {
  const context = React.useContext(OffersContext);
  if (context === undefined) {
    throw new Error("useOffers must be used within an OffersProvider");
  }
  return context;
};

export const useOffer = (offerId: OfferV2["id"]) => {
  const [offer, setOffer] = useState<OfferV2 | null>(null);
  const [loading, setLoading] = useState(true);

  const { offers, loading: loadingOffers } = useOffers();

  const getById = useCallback(
    (id: OfferV2["id"]) => {
      const _offer = offers.find(o => o.id === id);
      return _offer || null;
    },
    [offers]
  );

  useEffect(() => {
    if (loadingOffers) {
      return;
    }

    setOffer(getById(offerId));
    setLoading(false);
  }, [loadingOffers, getById, offerId]);

  return { offer, loading };
};
