import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { GeneralBlogPostService } from "../services";
import firebase from "../firebase";
import { useCountry } from "./CountryContext";

type ContextState = {
  generalBlogPosts: GeneralBlogPost[];
  loading: boolean;
  error: Error | null;
  updateGeneralBlogPost: (data: BlogPost) => Promise<void>;
  createGeneralBlogPost: (
    data: BlogPost
  ) => Promise<firebase.firestore.DocumentReference | null>;
  deleteGeneralBlogPost: (id: string) => Promise<void>;
  addOrUpdateGeneralBlogPost: (data: BlogPost) => Promise<string>;
};

const GeneralBlogPostContext = createContext<ContextState>({
  generalBlogPosts: [],
  loading: false,
  error: null,
  createGeneralBlogPost: async () => null,
  updateGeneralBlogPost: async () => null,
  deleteGeneralBlogPost: async () => null,
  addOrUpdateGeneralBlogPost: async () => null,
});

export const GeneralBlogPostsProvider = ({ ...rest }) => {
  const [generalBlogPosts, setGeneralBlogPosts] = useState<GeneralBlogPost[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const generalBlogPostService = useMemo(
    () => new GeneralBlogPostService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    setLoading(true);

    const unsub = generalBlogPostService.subscribe(
      (_error, _generalBlogPosts) => {
        setGeneralBlogPosts(_generalBlogPosts);
        setError(_error);
        setLoading(false);
      }
    );

    return unsub;
  }, [generalBlogPostService]);

  const updateGeneralBlogPost = useCallback(
    async (data: BlogPost) => await generalBlogPostService.update(data),
    [generalBlogPostService]
  );

  const createGeneralBlogPost = useCallback(
    async (data: BlogPost) => await generalBlogPostService.create(data),
    [generalBlogPostService]
  );

  const addOrUpdateGeneralBlogPost = useCallback(
    async (data: BlogPost) => await generalBlogPostService.addOrUpdate(data),
    [generalBlogPostService]
  );

  const deleteGeneralBlogPost = useCallback(
    async (id: string) => await generalBlogPostService.delete(id),
    [generalBlogPostService]
  );

  const value = useMemo(
    () => ({
      generalBlogPosts: generalBlogPosts,
      loading,
      error,
      updateGeneralBlogPost,
      createGeneralBlogPost,
      addOrUpdateGeneralBlogPost,
      deleteGeneralBlogPost,
    }),
    [
      generalBlogPosts,
      loading,
      error,
      updateGeneralBlogPost,
      createGeneralBlogPost,
      addOrUpdateGeneralBlogPost,
      deleteGeneralBlogPost,
    ]
  );

  return <GeneralBlogPostContext.Provider value={value} {...rest} />;
};

export const useGeneralBlogPosts = () => {
  const context = React.useContext(GeneralBlogPostContext);
  if (context === undefined) {
    throw new Error(
      "useGeneralBlogPosts must be used within an GeneralBlogPostsProvider"
    );
  }
  return context;
};

export const useGeneralBlogPost = (
  generalBlogPostId: GeneralBlogPost["id"]
) => {
  const { generalBlogPosts, loading } = useGeneralBlogPosts();

  const [generalBlogPost, setGeneralBlogPost] =
    useState<GeneralBlogPost | null>(null);

  useEffect(() => {
    const _generalBlogPost = generalBlogPosts.find(
      s => s.id === generalBlogPostId
    );
    if (_generalBlogPost !== undefined) {
      setGeneralBlogPost(_generalBlogPost);
    }
  }, [generalBlogPosts, generalBlogPostId]);

  return { generalBlogPost, loading };
};
