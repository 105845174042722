import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Outlet } from "react-router-dom";
import { LoyaltyService } from "../services";
import { logException } from "../utils/exceptionLogger";
import { useCountry } from "./CountryContext";

type ContextState = {
  levelInformations: LoyaltyContent["levelInformations"];
  loyaltyPopups: LoyaltyContent["loyaltyPopups"];
  loading: boolean;
  upsertLevelsData: (data: Partial<LoyaltyContent>) => void;
};

const LoyaltyContext = createContext<ContextState>(undefined);

export const LoyaltyProvider = () => {
  const { currentCountry } = useCountry();
  const [levelInformations, setLevelInformations] = useState<
    LevelInformation[]
  >([]);
  const [loyaltyPopups, setLoyaltyPopups] = useState<
    LoyaltyContent["loyaltyPopups"]
  >({});
  const [loading, setLoading] = useState(true);

  const loyaltyService = useMemo(
    () => new LoyaltyService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    setLoading(true);
    const unsubscribe = loyaltyService.subscribe((_, data) => {
      if (data) {
        setLevelInformations(data.levelInformations);
        setLoyaltyPopups(data.loyaltyPopups);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, [loyaltyService]);

  const upsertLevelsData = useCallback(
    async (data: Partial<LoyaltyContent>) => {
      try {
        await loyaltyService.upsert(data);
      } catch (e) {
        logException(`Error upserting levels data: ${e}`);
      }
    },
    [loyaltyService]
  );

  const value = useMemo(
    () => ({
      levelInformations,
      loyaltyPopups,
      loading,
      upsertLevelsData,
    }),
    [levelInformations, loyaltyPopups, loading, upsertLevelsData]
  );

  return (
    <LoyaltyContext.Provider value={value}>
      <Outlet />
    </LoyaltyContext.Provider>
  );
};

export const useLoyalty = () => {
  const context = useContext(LoyaltyContext);

  if (!context) {
    throw new Error("useLoyalty must be used within a LoyaltyProvider");
  }

  return context;
};
