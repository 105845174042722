import { Control, Path } from "react-hook-form";
import { BJSelectFormItem } from "./BJFormSelectItem";

export const BJSelectFormItemLevel = <T,>({
  error,
  message,
  control,
  fieldName,
  required,
  noLevelDisabled,
  handleChange,
}: {
  error: boolean;
  message: string;
  control: Control<T>;
  fieldName: Path<T>;
  required?: boolean;
  handleChange?: (value: string) => void;
  noLevelDisabled?: boolean;
}) => {
  const options = [
    {
      value: 0,
      key: "0",
      display: "No level selected",
      disabledOption: noLevelDisabled,
    },
    { value: 1, key: "1", display: "Newbie" },
    { value: 2, key: "2", display: "Explorer" },
    { value: 3, key: "3", display: "Genius" },
    { value: 4, key: "4", display: "Legend" },
  ];
  return (
    <BJSelectFormItem
      size="large"
      control={control}
      error={error}
      label="Loyalty Level"
      message={message}
      optionsList={options}
      fieldName={fieldName}
      required={required}
      handleChange={handleChange}
    />
  );
};
