import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";

export class MonthlyInformationService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }
  toMonthlyInformation = (
    doc: firebase.firestore.DocumentData
  ): MonthInformation => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
    };
  };

  subscribe = (
    callback: (
      err: Error | null,
      monthlyInformation: MonthInformation[]
    ) => void
  ) =>
    firebase
      .firestore()
      .collection(CollectionPaths.MonthlyInformation[this.countryCode])
      .onSnapshot(
        snapshot => {
          const monthlyInformation = snapshot.docs.map(
            this.toMonthlyInformation
          );
          callback(null, monthlyInformation);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  create = (data: MonthInformation) =>
    firebase
      .firestore()
      .collection(CollectionPaths.MonthlyInformation[this.countryCode])
      .add(data);

  update = (data: MonthInformation) =>
    firebase
      .firestore()
      .collection(CollectionPaths.MonthlyInformation[this.countryCode])
      .doc(data.id)
      .update({ ...data });

  delete = (id: string) =>
    firebase
      .firestore()
      .collection(CollectionPaths.MonthlyInformation[this.countryCode])
      .doc(id)
      .delete();
}
