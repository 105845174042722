import { Form, Typography } from "antd";
import { Control, Controller, FieldError, Path } from "react-hook-form";
import { Select } from "antd";
import styled from "styled-components";

import { SelectValue } from "antd/lib/select";

export const BJStarsFormItem = <T,>({
  error,
  errorField,
  label,
  required,
  control,
  fieldName,
  extra,
  placeholder,
}: {
  error?: boolean;
  errorField?: FieldError | FieldError[];
  label: string;
  required?: boolean;
  control: Control<T>;
  fieldName: Path<T>;
  disabled?: boolean;
  extra?: string;
  placeholder?: string;
  type?: string;
}) => {
  const errorMessage = Array.isArray(errorField)
    ? errorField?.find(field => field?.message).message
    : errorField?.message;

  return (
    <Form.Item
      label={label}
      name={fieldName}
      required={required}
      validateStatus={error && "error"}
      {...(errorField
        ? {
            help: (
              <Typography.Paragraph type="danger">
                {errorMessage}
              </Typography.Paragraph>
            ),
          }
        : undefined)}
      {...(extra
        ? {
            extra: (
              <Typography.Paragraph type="warning">
                {extra}
              </Typography.Paragraph>
            ) as JSX.Element,
          }
        : undefined)}
    >
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, value } }) => (
          <>
            <StyledSelect
              placeholder={
                placeholder ?? "Separate stars by pressing comma -> ( , )"
              }
              size="large"
              mode="tags"
              onChange={(values: SelectValue) => {
                const filteredValues = (values as string[]).every(
                  item => !isNaN(+item)
                )
                  ? (values as string[]).sort((a, b) => Number(a) - Number(b))
                  : values;
                onChange(filteredValues as string[]);
              }}
              tokenSeparators={[","]}
              value={value as string[]}
            />
            <WordWrapper>
              <Typography.Text type="secondary">{`Separate stars by pressing comma -> ( , )`}</Typography.Text>
              <Typography.Paragraph type="warning">{`Value must be a number`}</Typography.Paragraph>
            </WordWrapper>
          </>
        )}
      />
    </Form.Item>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selection-item {
    background: ${props => props.theme.primary};
  }

  .ant-select-selection-item-remove {
    display: flex;
    align-items: center;
  }
`;

const WordWrapper = styled.div`
  padding-left: 0.4rem;
  padding-top: 0.1rem;
`;
