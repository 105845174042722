import { useEffect, useState } from "react";
import { useArticles } from "../../../context";
import { getFormattedErrorMessage, CommonContentType } from "../../../utils";
import { Sorter } from "../../../components/theme/util/sorter";
import { BJColumnType } from "../../../components/theme/molecules/BJTable";
import { BJSelectListModal } from "../../../components/theme/components/BJSelectListModal";
import { useFilterContent } from "../../../hooks/contentCollections/useFilterContent";
import { useCountry } from "../../../context";
import { capitalizeFirstLetter } from "../../../utils";

interface Props {
  show: boolean;
  onHide: () => void;
  maxNoOfContentLimit: number;
  selectedContent: CommonContent[];
  onAdd: (content: CommonContent[]) => void;
}

interface dataSourceType extends FullArticle {
  [titleWithLocale: string]: string | FullArticle[keyof FullArticle];
}

export const AddArticlesModal = ({
  show: showModal,
  onHide,
  maxNoOfContentLimit,
  selectedContent,
  onAdd,
}: Props) => {
  const { articles, categories } = useArticles();
  const { currentCountry } = useCountry();

  const [dataSource, setDataSource] = useState<dataSourceType[]>([]);
  const {
    error,
    updatedContent,
    setError,
    selectedContentCollections,
    setSelectedContentCollections,
  } = useFilterContent<dataSourceType>(
    selectedContent,
    maxNoOfContentLimit,
    CommonContentType.ARTICLE,
    dataSource
  );

  useEffect(() => {
    const articleTableSource: dataSourceType[] = articles.map(article => {
      return {
        ...article,
        ...Object.entries(article.translations ?? {}).reduce(
          (acc, [locale, values]) => (
            (acc[`title${capitalizeFirstLetter(locale)}`] = values.title), acc
          ),
          {} as any
        ),
        key: article.id,
        ...Object.entries(article.category?.translations ?? {}).reduce(
          (acc, [locale, values]) => (
            (acc[`category${capitalizeFirstLetter(locale)}`] = values.title),
            acc
          ),
          {} as any
        ),
      };
    });
    setDataSource(articleTableSource);
  }, [articles]);

  const onSubmit = async () => {
    try {
      onAdd(updatedContent);
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
    }
  };

  const columns: BJColumnType<dataSourceType>[] = [
    ...currentCountry?.locales
      ?.filter(locale => locale.primary)
      ?.map(locale => ({
        title: `Title (${locale?.label})`,
        dataIndex: `title${capitalizeFirstLetter(locale?.key)}`,
        key: `title${capitalizeFirstLetter(locale?.key)}`,
        width: 1,
        ellipsis: true,
        sorter: {
          compare: Sorter.DEFAULT,
        },
      })),
    ...currentCountry?.locales
      ?.filter(locale => locale.primary)
      ?.map(locale => ({
        title: `Category (${locale?.label})`,
        dataIndex: `category${capitalizeFirstLetter(locale?.key)}`,
        key: `category${capitalizeFirstLetter(locale?.key)}`,
        width: 1,
        ellipsis: true,
        onFilter: (value: string | number | boolean, record: dataSourceType) =>
          record.category.id.includes(value as string),
        filters: categories.map(category => ({
          text: category.translations?.[locale?.key]?.title,
          value: category.id,
        })),
        sorter: {
          compare: Sorter.DEFAULT,
        },
      })),
  ];

  return (
    <BJSelectListModal
      show={showModal}
      error={error!}
      onHide={onHide}
      modalTitle={"Add articles"}
      recordCountSuffix={"Articles"}
      selectedRecords={selectedContentCollections}
      updateSelectedRecords={setSelectedContentCollections}
      maxNoOfContentLimit={maxNoOfContentLimit}
      columns={columns}
      dataSource={dataSource}
      disable={updatedContent.length > maxNoOfContentLimit}
      onSubmit={onSubmit}
    />
  );
};
