import { useEffect, useState } from "react";
import { useCountry, useFAQ } from "../../../context";
import {
  getFormattedErrorMessage,
  CommonContentType,
  capitalizeFirstLetter,
} from "../../../utils";
import { Sorter } from "../../../components/theme/util/sorter";
import { BJColumnType } from "../../../components/theme/molecules/BJTable";
import { BJSelectListModal } from "../../../components/theme/components/BJSelectListModal";
import { useFilterContent } from "../../../hooks/contentCollections/useFilterContent";

interface Props {
  show: boolean;
  onHide: () => void;
  maxNoOfContentLimit: number;
  selectedContent: CommonContentV2[];
  onAdd: (content: CommonContentV2[]) => void;
}

interface DataSourceType {
  id: FullQuestionV2["id"];
  question: FullQuestionV2["translations"][string]["question"];
  category: string | undefined;
}

export const AddFaqsModal = ({
  show: showModal,
  onHide,
  maxNoOfContentLimit,
  selectedContent,
  onAdd,
}: Props) => {
  const [dataSource, setDataSource] = useState<DataSourceType[]>([]);
  const { questions } = useFAQ();
  const {
    error,
    updatedContent,
    setError,
    selectedContentCollections,
    setSelectedContentCollections,
  } = useFilterContent<DataSourceType>(
    selectedContent,
    maxNoOfContentLimit,
    CommonContentType.FAQ,
    dataSource
  );
  const { currentCountry } = useCountry();

  useEffect(() => {
    const faqTableSource: DataSourceType[] = questions?.map(faq => ({
      ...faq,
      ...Object.entries(faq.translations ?? {}).reduce(
        (acc, [locale, values]) => (
          (acc[`question${capitalizeFirstLetter(locale)}`] = values.question),
          acc
        ),
        {} as any
      ),
      key: faq.id,
      ...Object.entries(faq.category?.translations ?? {}).reduce(
        (acc, [locale, values]) => (
          (acc[`category${capitalizeFirstLetter(locale)}`] = values.title), acc
        ),
        {} as any
      ),
    }));
    setDataSource(faqTableSource);
  }, [questions]);

  const onSubmit = async () => {
    try {
      onAdd(updatedContent);
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
    }
  };

  const columns: BJColumnType<DataSourceType>[] = [
    ...currentCountry?.locales
      ?.filter(locale => locale.primary)
      .map(locale => ({
        title: `Question (${locale?.label})`,
        dataIndex: `question${capitalizeFirstLetter(locale?.key)}`,
        key: `question${capitalizeFirstLetter(locale?.key)}`,
        width: 1,
        ellipsis: true,
        sorter: {
          compare: Sorter.DEFAULT,
        },
      })),
    ...currentCountry?.locales
      ?.filter(locale => locale.primary)
      .map(locale => ({
        title: `Category (${locale?.label})`,
        dataIndex: `category${capitalizeFirstLetter(locale?.key)}`,
        key: `category${capitalizeFirstLetter(locale?.key)}`,
        width: 1,
        ellipsis: true,
        sorter: {
          compare: Sorter.DEFAULT,
        },
      })),
  ];

  return (
    <BJSelectListModal
      show={showModal}
      error={error!}
      onHide={onHide}
      modalTitle={"Add faq"}
      recordCountSuffix={"Faq"}
      selectedRecords={selectedContentCollections}
      updateSelectedRecords={setSelectedContentCollections}
      maxNoOfContentLimit={maxNoOfContentLimit}
      columns={columns}
      dataSource={dataSource}
      disable={updatedContent.length > maxNoOfContentLimit}
      onSubmit={onSubmit}
    />
  );
};
