import { Col, Row, Typography } from "antd";

type RowItemProps = {
  title: string;
  content: string;
};

export const RowItem = ({ title, content }: RowItemProps) => {
  return (
    <Col span={8}>
      <Row gutter={4}>
        <Col>
          <Typography.Text strong>{title}</Typography.Text>
        </Col>
        <Col>{content}</Col>
      </Row>
    </Col>
  );
};
