import { useEffect, useMemo, useState } from "react";
import { useCountry } from "../context";
import { ToolsMenuBannerService } from "../services";
import _ from "lodash";

export const useToolsBanner = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [banners, setBanners] = useState<ToolsBanner[]>([]);
  const { currentCountry } = useCountry();

  const bannersService = useMemo(
    () =>
      currentCountry?.abb
        ? new ToolsMenuBannerService(currentCountry.abb)
        : null,
    [currentCountry?.abb]
  );

  useEffect(() => {
    const unsubBanners = bannersService?.subscribe((_error, _banners) => {
      const bannersOrdered = _.orderBy(_banners, ["sortOrder"], ["asc"]);
      setBanners(bannersOrdered);
      setError(_error);
      setLoading(false);
    });

    return () => {
      unsubBanners?.();
    };
  }, [bannersService]);

  const updateBanner = (id: ToolsBanner["id"], banner: ToolsBanner) =>
    bannersService?.update(id, banner);

  const createBanner = (banner: Omit<ToolsBanner, "id">) =>
    bannersService?.create(banner);

  const deleteBanner = (id: ToolsBanner["id"]) =>
    bannersService?.deleteBanner(id);

  const uploadBannerImage = async (
    file: Blob | ArrayBuffer,
    fileName: string
  ) => bannersService?.uploadBannerImage(file, fileName);

  return {
    loading,
    error,
    banners,
    createBanner,
    deleteBanner,
    uploadBannerImage,
    updateBanner,
  };
};
