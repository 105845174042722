import {
  commonErrors,
  highlightsErrorMessages,
  tipsErrorMessages,
} from "../language";
import {
  AppNavigationType,
  dayValidationMaxValue,
  dayValidationMinValue,
} from "../utils";
import * as yup from "yup";
import { TipsNavigationType } from "../pages/Tips/TipsModal";

const titleMaxLength = 30;
const buttonTextMaxLength = 20;

const tipsSchema = yup.object().shape({
  buttonTextEn: yup
    .string()
    .nullable()
    .max(titleMaxLength, highlightsErrorMessages.recordMax(buttonTextMaxLength))
    .test(
      "record valid",
      commonErrors.requiredError,
      function (value: string): boolean {
        return (
          !this.parent.enableNavigationAction ||
          (this.parent.enableNavigationAction && !!value)
        );
      }
    ),
  buttonTextSw: yup
    .string()
    .nullable()
    .max(
      buttonTextMaxLength,
      highlightsErrorMessages.recordMax(buttonTextMaxLength)
    )
    .test(
      "record valid",
      commonErrors.requiredError,
      function (value: string): boolean {
        return (
          !this.parent.enableNavigationAction ||
          (this.parent.enableNavigationAction && !!value)
        );
      }
    ),
  tip: yup.string().required(commonErrors.requiredError),
  tipSw: yup.string().required(commonErrors.requiredError),
  day: yup
    .number()
    .typeError("Please speicfy a number")
    .max(dayValidationMaxValue, tipsErrorMessages.recordMaxMin)
    .min(dayValidationMinValue, tipsErrorMessages.recordMaxMin)
    .required(commonErrors.requiredError),
  contentId: yup
    .string()
    .nullable()
    .test(
      "record valid",
      commonErrors.requiredError,
      function (value: string): boolean {
        return (
          !this.parent.enableNavigationAction ||
          this.parent.navigationType === TipsNavigationType.DeepLink ||
          this.parent.type === AppNavigationType.EXTERNAL ||
          !!value?.length
        );
      }
    ),
  type: yup
    .string()
    .nullable()
    .test(
      "record valid",
      commonErrors.requiredError,
      function (value: string): boolean {
        return (
          !this.parent.enableNavigationAction ||
          this.parent.navigationType === TipsNavigationType.DeepLink ||
          this.parent.type === AppNavigationType.EXTERNAL ||
          !!value?.length
        );
      }
    ),
  externalUrl: yup
    .string()
    .url()
    .nullable()
    .test(
      "record valid",
      commonErrors.requiredError,
      function (value: string): boolean {
        return (
          !this.parent.enableNavigationAction ||
          this.parent.navigationType === TipsNavigationType.DeepLink ||
          this.parent.type !== AppNavigationType.EXTERNAL ||
          !!value?.length
        );
      }
    ),
  deepLink: yup
    .string()
    .url()
    .nullable()
    .test(
      "record valid",
      commonErrors.requiredError,
      function (value: string): boolean {
        return (
          !this.parent.enableNavigationAction ||
          this.parent.navigationType === TipsNavigationType.Content ||
          !!value?.length
        );
      }
    ),
});

export { tipsSchema };
