import { useEffect, useState } from "react";
import { usePostList } from "./usePostList";

export const usePost = (postId: Post["id"]) => {
  const { posts, loading } = usePostList();

  const [post, setPost] = useState<Post | null>(null);

  useEffect(() => {
    if (loading) {
      return;
    }

    const _post = posts.find(p => p.id === postId);
    if (_post !== undefined) {
      setPost(_post);
    }
  }, [loading, postId, posts]);

  return { post, loading };
};
