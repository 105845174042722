import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import {
  useForm,
  Controller,
  FormProvider,
  useFieldArray,
} from "react-hook-form";
import { Row, Col, Typography, Select, Form, Switch, Collapse } from "antd";
import { useCountry, useOffer, useOffers, useSponsors } from "../../context";
import {
  AudienceType,
  ContentTypeNames,
  DeepLinkType,
  formValidationError,
  offerPriorityUptoCount,
  offerTypeSchemes,
  valueOrNull,
} from "../../utils";
import { AiTranslator, FormEdit, FormEditType } from "../../components";
import BJInput from "../../components/theme/atoms/BJInput";
import { commonErrors } from "../../language";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import BJFeaturedList from "../../components/theme/molecules/BJFeaturedList";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";
import { getMonths, getWeeks } from "../../utils/timeUtils";
import {
  BJInputFormItem,
  BJMdFormItem,
  BJTagsFormItem,
} from "../../components/theme";
import {
  BJAudienceMultiple,
  BJAudienceMultipleSchema,
  convertExtraToSegments,
  convertSegmentsToExtra,
} from "../../components/theme/components/BJAudienceMultiple";
import { FormValues, OfferImageUrlProps } from "./types";
import OfferChecklist from "./OfferChecklist";
import OfferPregnancyChild from "./OfferPregnancyChild";
import OfferInputs from "./OfferInputs";
import isEmpty from "lodash/isEmpty";
import OfferImageBannerVideo from "./OfferImageBannerVideo";
import OfferSignUpTerms from "./OfferSignUpTerms";
import OfferSignUpSuccess from "./OfferSignUpSuccess";
import OfferSimpleButtonCTA from "./OfferSimpleButtonCTA";
import OfferConfirmationPopup from "./OfferConfirmationPop";

const { Title } = Typography;
const displayOfferText = "Display Offer";
const { Panel } = Collapse;

type OfferPriority = {
  id: string;
  value: string;
  allowed?: boolean;
};

const { requiredError } = commonErrors;

const getSchema = (currentCountry: Country) =>
  yup.object().shape({
    inputs: yup.array().when("type", {
      is: (type: string) => type == "3" || type == "4",
      then: yup
        .array()
        .min(1)
        .required("At least one input is required")
        .of(
          yup.object().shape({
            translations: yup.object().shape(
              currentCountry?.locales.reduce((acc, item) => {
                acc[item.key] = yup.object().shape({
                  value: yup
                    .string()
                    .required(
                      `Input Value (${String(
                        item.key
                      ).toUpperCase()}): ${requiredError}`
                    ),
                });
                return acc;
              }, {} as any)
            ),
          })
        ),
      otherwise: yup
        .array()
        .nullable()
        .of(
          yup.object().shape({
            translations: yup.object().shape(
              currentCountry?.locales.reduce((acc, item) => {
                acc[item.key] = yup.object().shape({
                  value: yup
                    .string()
                    .required(
                      `Input Value (${String(
                        item.key
                      ).toUpperCase()}): ${requiredError}`
                    ),
                });
                return acc;
              }, {} as any)
            ),
          })
        ), // Strip the array if type is not '3'
    }),
    type: yup.string().required(`Type: ${requiredError}`),
    translations: yup.object().when("type", {
      is: (type: string) => type == "4" || type == "3",
      then: yup.object().when("type", {
        is: "4",
        then: yup.object().shape(
          currentCountry?.locales.reduce((acc, item) => {
            acc[item.key] = yup.object().shape({
              title: yup
                .string()
                .required(
                  `Title (${String(item.key).toUpperCase()}): ${requiredError}`
                )
                .nullable(),
              couponCode: yup
                .string()
                .required(
                  `Coupon Code (${String(
                    item.key
                  ).toUpperCase()}): ${requiredError}`
                )
                .nullable(),
            });
            return acc;
          }, {} as any)
        ),
        otherwise: yup.object().shape(
          currentCountry?.locales.reduce((acc, item) => {
            acc[item.key] = yup.object().shape({
              title: yup
                .string()
                .required(
                  `Title (${String(item.key).toUpperCase()}): ${requiredError}`
                ),
              successSignUpOfferTitle: yup
                .string()
                .required(
                  `Success Message Title (${String(
                    item.key
                  ).toUpperCase()}): ${requiredError}`
                ),
              successSignUpOfferInputMessage: yup.string().nullable(),
            });
            return acc;
          }, {} as any)
        ),
      }),
      otherwise: yup.object().shape(
        currentCountry?.locales.reduce((acc, item) => {
          acc[item.key] = yup.object().shape({
            title: yup
              .string()
              .required(
                `Title (${String(item.key).toUpperCase()}): ${requiredError}`
              ),
          });
          return acc;
        }, {} as any)
      ),
    }),
    ...BJAudienceMultipleSchema(false),
    featuredOffers: yup.array().of(yup.string()),
    sponsorId: yup.string().required(`Sponsor : ${requiredError}`),
    button: yup.object().when("type", {
      is: (type: string) => type != null && type != "2",
      then: yup
        .object()
        .shape({
          translations: yup.object().shape(
            currentCountry?.locales.reduce((acc, item) => {
              acc[item.key] = yup.object().shape({
                text: yup
                  .string()
                  .required(
                    `Button Text (${String(
                      item.key
                    ).toUpperCase()}): ${requiredError}`
                  )
                  .nullable(),
              });
              return acc;
            }, {} as any)
          ),
        })
        .nullable(),
      otherwise: yup.object().nullable(),
    }),
    confirmationPopup: yup.object().when("type", {
      is: "4",
      then: yup.object().shape({
        translations: yup.object().shape(
          currentCountry?.locales.reduce((acc, item) => {
            acc[item.key] = yup.object().shape({
              heading: yup
                .string()
                .required(
                  `Heading (${String(
                    item.key
                  ).toUpperCase()}): ${requiredError}`
                ),
              description: yup
                .string()
                .required(
                  `Description (${String(
                    item.key
                  ).toUpperCase()}): ${requiredError}`
                ),
              successButtonText: yup
                .string()
                .required(
                  `Success Button Text (${String(
                    item.key
                  ).toUpperCase()}): ${requiredError}`
                ),
            });
            return acc;
          }, {} as any)
        ),
      }),
      otherwise: yup.object().nullable(),
    }),
  });

export const OfferPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const { offer, loading } = useOffer(id);
  const {
    offers: offerList,
    loading: loadingOffers,
    offersService,
  } = useOffers();
  const { weeks: pickerWeeks } = getWeeks();
  const { months: pickerMonths } = getMonths();
  const { sponsors } = useSponsors();

  const [weekPregnancyOffer, setWeekPregnancyOffer] = useState<
    timeElementType[]
  >([]);
  const [monthChildOffer, setMonthChildOffer] = useState<timeElementType[]>([]);
  const [checkListPregnancyOffer, setCheckListPregnancyOffer] = useState<
    timeElementType[]
  >([]);
  const [checkListChildOffer, setCheckListChildOffer] = useState<
    timeElementType[]
  >([]);

  const { offers } = useOffers();
  const [changeFeaturedListOrder, setchangeFeaturedListOrder] = useState(false);
  const [topOffers, setTopOffers] = useState<OfferV2[]>([]);

  const { currentCountry, primaryLocale } = useCountry();

  const methods = useForm<FormValues>({
    resolver: yupResolver(getSchema(currentCountry)),
    defaultValues: {
      featuredOffers: [],
      button: { link: "" },
      confirmationPopup: { discountPercentage: "" },
    },
  });

  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    getValues,
  } = methods;

  const {
    fields: inputFields,
    append,
    remove: onRemoveOfferInput,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "inputs", // unique name for your Field Array
  });

  const { audiences, priority, translations, button, confirmationPopup } =
    watch();

  const otherOfferList = useMemo(
    () =>
      offerList.filter(
        otherOffer => otherOffer.id !== offer?.id && otherOffer?.priority
      ),
    [offerList, offer]
  );

  const priorityOrder: OfferPriority[] = useMemo(() => {
    const offerAudienceTypes =
      (audiences && audiences.map(el => el?.audience)) || [];
    const allowedPriority = Array.from(
      { length: offerPriorityUptoCount },
      (_, i) => ({
        value: `Priority ${i + 1}`,
        id: `${i + 1}`,
        allowed: !otherOfferList.find(otherOffer => {
          const otherOfferAudience =
            otherOffer?.extra?.map(el => el?.audience) || [];
          if (+otherOffer.priority === i + 1) {
            // if we found some other offer having the same priority
            if (otherOfferAudience.includes(AudienceType.All)) {
              // if some other offer has ALL Audience  - then the priority is not allowed
              return true;
            } else if (
              offerAudienceTypes.includes(AudienceType.All) &&
              (otherOfferAudience.includes(AudienceType.Child) ||
                otherOfferAudience.includes(AudienceType.Pregnancy))
            ) {
              // if this offer has ALL Audience and some other offer has Pregance/Child Audience - then its not allowed
              return true;
            } else if (
              (otherOfferAudience.includes(AudienceType.Pregnancy) &&
                offerAudienceTypes?.includes(AudienceType.Pregnancy)) ||
              (otherOfferAudience.includes(AudienceType.Child) &&
                offerAudienceTypes?.includes(AudienceType.Child))
            ) {
              // if some other offer has Child/Pregnancy and this offer also has Child/Pregnancy respectively  - then the priority is not allowed
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }),
      })
    );
    // priority &&
    // allowedPriority[priority - 1] &&
    // !allowedPriority[priority - 1]?.allowed &&
    //  setValue("priority", null);
    // if the applied priority is not allowed for the offer ( according to the selected audience ) , then we reset it.
    return allowedPriority;
  }, [offer, offerList, audiences, priority]);

  const fetchTopFiveOffers = async () => {
    try {
      const topFiveOffers = await offersService.getTopFiveOffers();
      const sortedTopFiveOffers = topFiveOffers.sort(
        (a, b) => a?.sortOrder - b?.sortOrder
      );
      setTopOffers(sortedTopFiveOffers);
    } catch (err) {
      console.error("Error fetching top five offers:", err);
    }
  };

  useEffect(() => {
    if (loading || loadingOffers || offer === null) {
      return;
    }
    reset({
      type: offer.type || "1",
      company: offer.company || "",
      bannerDeepLink: offer.bannerDeepLink ?? "",
      video: offer.video || "",
      title: offer.title || "",
      button: offer.button || { link: "", translations: {} },
      inputs: offer.inputs ?? [],
      terms: offer.terms ?? "",
      termsTarget: offer.termsTarget ?? "",
      termUrl: offer.termUrl ?? "",
      promoted: offer.promoted,
      priority: offer.priority || null,
      showPregnancyOffer: offer?.pregnancyWeekOffer?.showOffer,
      showChildMonthOffer: offer?.childMonthOffer?.showOffer,
      showCheckListPregnancyOffer: offer?.checkListPregnancyOffer?.showOffer,
      showCheckListChildOffer: offer?.checkListChildOffer?.showOffer,
      sponsorId: offer.sponsorId || "",
      hideSponsorLogo: offer.hideSponsorLogo,
      audiences: convertExtraToSegments(offer?.extra),
      translations: offer.translations,
      featuredOffers: offer.featuredOffers ?? [],
      confirmationPopup: offer?.confirmationPopup || {
        discountPercentage: "",
        translations: {},
      },
    });

    setMonthChildOffer(offer?.childMonthOffer?.months);
    setWeekPregnancyOffer(offer?.pregnancyWeekOffer?.weeks);
    setCheckListChildOffer(offer?.checkListChildOffer?.months);
    setCheckListPregnancyOffer(offer?.checkListPregnancyOffer?.weeks);
    fetchTopFiveOffers();
  }, [offer, loading, reset, loadingOffers, offersService]);

  const onSubmit = async () => {
    return handleSubmit(onSubmitData, formValidationError)();
  };

  const onSubmitData = async (data: FormValues) => {
    try {
      const checkListWeeks: timeElementType[] = checkListPregnancyOffer?.map(
        x => ({
          no: x.no,
        })
      );
      const checkListMonths: timeElementType[] = checkListChildOffer?.map(
        x => ({
          no: x.no,
        })
      );

      const sponsor = sponsors.find(({ id }) => id === data.sponsorId);

      const translations: OfferV2["translations"] = {};

      for (const [key, value] of Object.entries(data.translations)) {
        translations[key] = {
          title: value?.title?.trim(),
          intro: value?.intro,
          body: value?.body,
          tagWords: value?.tagWords,
          imageUrl: value?.imageUrl,
          bannerImageUrl: value.bannerImageUrl,
          successSignUpOfferTitle: value?.successSignUpOfferTitle ?? "",
          successSignUpOfferSubTitle: value?.successSignUpOfferSubTitle ?? "",
          successSignUpOfferInputMessage:
            value?.successSignUpOfferInputMessage ?? "",
          bannerImageBlurhash: value.bannerImageBlurhash ?? "",
          imageBlurhash: value.imageBlurhash ?? "",
          successImageUrl: value.successImageUrl ?? "",
          successImageBlurhash: value.successImageBlurhash ?? "",
          couponCode: value.couponCode ?? "",
          ctaUrl: value.ctaUrl ?? "",
          ctaBtnLabel: value.ctaBtnLabel ?? "",
        };
      }

      const updateData: Omit<OfferV2, "id"> = {
        type: data.type || "1",
        company: data.company ?? "",
        image: data.translations[primaryLocale.key]?.imageUrl ?? "",
        banner: data.translations[primaryLocale.key]?.bannerImageUrl ?? "",
        bannerDeepLink: data.bannerDeepLink ?? "",
        video: data.video ?? "",
        button: {
          translations: data.button?.translations ?? {},
          text: data.button?.translations[primaryLocale.key]?.text ?? "",
          link: data.button?.link ?? "",
        },
        terms: data.terms ?? "",
        termsTarget: data.termsTarget ?? "",
        termUrl: data.termUrl ?? "",
        inputs: data.inputs?.map(input => ({
          name: input.name,
          value: input.translations[primaryLocale.key]?.value,
          translations: input.translations,
        })),
        childMonthOffer: {
          showOffer: data.showChildMonthOffer,
          months: monthChildOffer,
        },
        pregnancyWeekOffer: {
          showOffer: data.showPregnancyOffer,
          weeks: weekPregnancyOffer,
        },
        checkListChildOffer: {
          showOffer: data.showCheckListChildOffer,
          months: checkListMonths,
        },
        checkListPregnancyOffer: {
          showOffer: data.showCheckListPregnancyOffer,
          weeks: checkListWeeks,
        },
        showOnChecklist: false,
        promoted: data.promoted ?? false,
        priority: Number(data.priority) || null,
        featuredOffers: valueOrNull(data.featuredOffers),
        sponsorId: sponsor?.id ?? null,
        sponsor: sponsor ?? null,
        hideSponsorLogo: data.hideSponsorLogo ?? false,
        extra: convertSegmentsToExtra(data.audiences),
        translations,
        confirmationPopup: {
          translations: data?.confirmationPopup?.translations ?? {},
          discountPercentage: data?.confirmationPopup?.discountPercentage ?? "",
          successButtonLink: data?.confirmationPopup?.successButtonLink ?? "",
        },
      };

      const isTopOfferMatch = topOffers.some(topOffer => topOffer.id === id);
      if (offer) {
        await offersService.update(offer.id, updateData);
        // If the offer is part of the topOffers, update the topOffers array with the new data
        if (isTopOfferMatch) {
          const updatedTopOffers = topOffers.map(topOffer =>
            topOffer.id === offer.id ? { ...topOffer, ...updateData } : topOffer
          );
          setTopOffers(updatedTopOffers);
          await offersService.updateTopFiveOffers(
            updatedTopOffers.map((offer, index) => ({
              ...offer,
              sortOrder: index,
            }))
          );
        }
      } else {
        const { id } = await offersService.create(updateData);
        return navigate(`../${id}`);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const onRemove = async () => {
    if (offer) {
      await offersService.deleteOffer(offer.id);
      return navigate("./..");
    }
  };

  const handleImageUrl = ({ url, locale, fieldName }: OfferImageUrlProps) => {
    const temp = { ...getValues("translations") };
    temp[locale] = {
      ...temp[locale],
      [fieldName]: url,
    };
    setValue("translations", temp, { shouldDirty: true });
  };

  const mapToFeaturedOffer = (
    id: OfferV2["id"],
    locale: Locale
  ): FeaturedItem => {
    const _offer = offers.find(offer => offer.id === id);
    if (_offer) {
      return {
        id: _offer.id,
        title: _offer.translations[locale.key]?.title,
        imageUrl: _offer.image,
        description: _offer.translations[locale.key]?.intro,
      };
    }
  };

  const onAddOfferInput = () => {
    const offerInputs = getValues("inputs");
    const inputs = !isEmpty(offerInputs) ? [...offerInputs] : [];
    append({
      name: `input${inputs.length + 1}`,
      value: "",
      translations: {
        [primaryLocale.key]: {
          value: "",
        },
      },
    });
  };

  const isDirty = !!Object.keys(dirtyFields).length;

  const getOfferTypes = () => {
    return [
      { name: "Simple Offer", value: "1", key: 1 },
      { name: "H&M Offer", value: "2", key: 2 },
      { name: "Sign up Offer", value: "3", key: 3 },
      { name: "BabyShop Offer", value: "4", key: 4 },
      { name: "Lf Offer", value: "5", key: 5 },
    ];
  };

  const featuredOffers = watch("featuredOffers");
  const offerType = watch("type");
  const onSetTranslatedValue = (value: { data: string[] }) => {
    const data = value.data.map(item => item);
    const clonedTitle = data[0]
      .split(",")[0]
      ?.replace("[", "")
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      .trim();
    const clonedIntro = data[0]
      .split(",")[1]
      ?.replace("[", "")
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      .trim();
    const clonedBody = data[0]
      .split(",")[2]
      ?.replace("[", "")
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      .trim();
    const clonedButtonText = data[0]
      .split(",")[3]
      ?.replace("[", "")
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      .trim();
    const clonedSignUpOfferTitle = data[0]
      .split(",")[4]
      ?.replace("[", "")
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      .trim();
    const clonedSignUpOfferSubTitle = data[0]
      .split(",")[5]
      ?.replace("[", "")
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      .trim();
    const clonedSignUpOfferInputMessage = data[0]
      .split(",")[6]
      ?.replace("[", "")
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      .trim();
    const clonedCtaBtnLabel = data[0]
      .split(",")[7]
      ?.replace("[", "")
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      .trim();
    const clonedHeading = data[0]
      .split(",")[4]
      ?.replace("[", "")
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      .trim();
    const clonedDescription = data[0]
      .split(",")[5]
      ?.replace("[", "")
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      .trim();
    const clonedSuccessButtonText = data[0]
      .split(",")[6]
      ?.replace("[", "")
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      .trim();

    let title;
    let intro;
    let body;
    let buttonText;
    let signUpOfferTitle;
    let signUpOfferSubTitle;
    let signUpOfferInputMessage;
    let ctaBtnLabel;
    let heading;
    let description;
    let successButtonText;

    const filteredTranslations = () => {
      if (!offerType) {
        return [];
      }
      if (offerType === "1") {
        return [button?.translations?.[primaryLocale?.key]?.text];
      } else if (offerType === "3") {
        return [
          button?.translations?.[primaryLocale?.key]?.text,
          translations?.[primaryLocale?.key]?.successSignUpOfferTitle,
          translations?.[primaryLocale?.key]?.successSignUpOfferSubTitle,
          translations?.[primaryLocale?.key]?.successSignUpOfferInputMessage,
          translations?.[primaryLocale?.key]?.ctaBtnLabel,
        ];
      } else if (offerType === "4") {
        return [
          button?.translations?.[primaryLocale?.key]?.text,
          confirmationPopup?.translations?.[primaryLocale?.key]?.heading,
          confirmationPopup?.translations?.[primaryLocale?.key]?.description,
          confirmationPopup?.translations?.[primaryLocale?.key]
            ?.successButtonText,
        ];
      }
    };

    //run function if some are translated and some are not
    [
      translations?.[primaryLocale?.key]?.title,
      translations?.[primaryLocale?.key]?.intro,
      translations?.[primaryLocale?.key]?.body,
      ...filteredTranslations(),
    ].forEach((item, index) => {
      if (data[0].includes("None")) {
        if (index === 0 && !clonedTitle.includes("None")) {
          title = clonedTitle;
        } else if (index === 1 && !clonedIntro.includes("None")) {
          intro = clonedIntro;
        } else if (index === 2 && !clonedBody.includes("None")) {
          body = clonedBody;
        } else if (index === 3 && !clonedButtonText.includes("None")) {
          buttonText = clonedButtonText;
        } else if (
          index === 4 &&
          offerType === "3" &&
          !clonedSignUpOfferTitle.includes("None")
        ) {
          signUpOfferTitle = clonedSignUpOfferTitle;
        } else if (
          index === 5 &&
          offerType === "3" &&
          !clonedSignUpOfferSubTitle.includes("None")
        ) {
          signUpOfferSubTitle = clonedSignUpOfferSubTitle;
        } else if (
          index === 6 &&
          offerType === "3" &&
          !clonedSignUpOfferInputMessage.includes("None")
        ) {
          signUpOfferInputMessage = clonedSignUpOfferInputMessage;
        } else if (
          index === 7 &&
          offerType === "3" &&
          !clonedCtaBtnLabel.includes("None")
        ) {
          ctaBtnLabel = clonedCtaBtnLabel;
        } else if (
          index === 4 &&
          offerType === "4" &&
          !clonedHeading.includes("None")
        ) {
          heading = clonedHeading;
        } else if (
          index === 5 &&
          offerType === "4" &&
          !clonedDescription.includes("None")
        ) {
          description = clonedDescription;
        } else if (
          index === 6 &&
          offerType === "4" &&
          !clonedSuccessButtonText.includes("None")
        ) {
          successButtonText = clonedSuccessButtonText;
        }
      } else {
        if (index === 0 && item !== undefined) {
          title = clonedTitle;
        } else if (index === 1 && item !== undefined) {
          intro = clonedIntro;
        } else if (index === 2 && item !== undefined) {
          body = clonedBody;
        } else if (index === 3 && item !== undefined) {
          buttonText = clonedButtonText;
        } else if (index === 4 && offerType === "3" && item !== undefined) {
          signUpOfferTitle = clonedSignUpOfferTitle;
        } else if (index === 5 && offerType === "3" && item !== undefined) {
          signUpOfferSubTitle = clonedSignUpOfferSubTitle;
        } else if (index === 6 && offerType === "3" && item !== undefined) {
          signUpOfferInputMessage = clonedSignUpOfferInputMessage;
        } else if (index === 7 && offerType === "3" && item !== undefined) {
          ctaBtnLabel = clonedCtaBtnLabel;
        } else if (index === 4 && offerType === "4" && item !== undefined) {
          heading = clonedHeading;
        } else if (index === 5 && offerType === "4" && item !== undefined) {
          description = clonedDescription;
        } else if (index === 6 && offerType === "4" && item !== undefined) {
          successButtonText = clonedSuccessButtonText;
        }
      }
    });
    setValue("translations.en.title", title);
    setValue("translations.en.intro", intro);
    setValue("translations.en.body", body);

    if (offerType === "1") {
      setValue("button.translations.en.text", buttonText);
    } else if (offerType === "3") {
      setValue("button.translations.en.text", buttonText);
      setValue("translations.en.successSignUpOfferTitle", signUpOfferTitle);
      setValue(
        "translations.en.successSignUpOfferSubTitle",
        signUpOfferSubTitle
      );
      setValue(
        "translations.en.successSignUpOfferInputMessage",
        signUpOfferInputMessage
      );
      setValue("translations.en.ctaBtnLabel", ctaBtnLabel);
    } else if (offerType === "4") {
      setValue("button.translations.en.text", buttonText);
      setValue("confirmationPopup.translations.en.heading", heading);
      setValue("confirmationPopup.translations.en.description", description);
      setValue(
        "confirmationPopup.translations.en.successButtonText",
        successButtonText
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <FormEdit
        onRemove={onRemove}
        hasValidationErrors={Object.keys(errors).length !== 0}
        enableSave={isDirty}
        title={offer ? offer?.translations : "New Offer"}
        id={offer?.id}
        editType={offer?.id ? FormEditType.EDIT : FormEditType.ADD}
        loading={loading}
        onSubmit={onSubmit}
        recordIdentifier={offer?.id}
        deepLink={{
          type: DeepLinkType.Offer,
          id: offer?.id,
          countryCode: currentCountry?.abb,
        }}
        localeSupported
        errors={errors as any}
      >
        {locale => (
          <Row gutter={{ md: 20 }}>
            <Col md={24} lg={12}>
              <BJInputFormItem
                label={`Title (${locale?.label ?? ""})`}
                fieldName={`translations.${locale.key}.title`}
                key={`translations.${locale.key}.title`}
                control={control}
                error={!!errors?.translations?.[locale.key]?.title}
                message={errors?.translations?.[locale.key]?.title?.message}
                required={true}
              />
              <BJMdFormItem
                fieldName={`translations.${locale.key}.intro`}
                key={`translations.${locale.key}.intro`}
                label={`Intro (${locale?.label ?? ""})`}
                control={control}
                required={false}
              />
              <BJMdFormItem
                label={`Body (${locale?.label ?? ""})`}
                fieldName={`translations.${locale.key}.body`}
                key={`translations.${locale.key}.body`}
                control={control}
                required={false}
              />
              <ItemWrapper>
                <Row>
                  <Col span={4}>
                    <Form.Item label="Promote" key="promoted">
                      <Controller
                        control={control}
                        name="promoted"
                        render={({ field: { onChange, value } }) => (
                          <Switch onChange={onChange} checked={value} />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Priority" name="priority">
                      <Controller
                        control={control}
                        name="priority"
                        render={({ field }) => (
                          <Select {...field} size="middle">
                            <Select.Option value={""}>none</Select.Option>
                            {priorityOrder.map(priority => (
                              <Select.Option
                                disabled={!priority?.allowed}
                                value={priority.id}
                                key={priority.id}
                              >
                                {priority.value}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </ItemWrapper>

              <Row justify="space-between" align="middle">
                <Title level={5}>Add Featured offer</Title>
                <StyledSelect
                  showSearch
                  placeholder="Select offer to feature"
                  filterOption={(input, option) => {
                    if (
                      option?.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    ) {
                      return true;
                    }
                    return false;
                  }}
                  value="def"
                  onSelect={(v, o) => {
                    setValue("featuredOffers", [...featuredOffers, o.value], {
                      shouldDirty: true,
                    });
                  }}
                >
                  <Select.Option value="def" style={{ display: "none" }}>
                    Select offer to feature
                  </Select.Option>
                  {featuredOffers &&
                    offers
                      .filter(
                        item => !featuredOffers.find(id => id === item.id)
                      )
                      .map(offer => (
                        <Select.Option value={offer.id} key={offer.id}>
                          {offer.translations[locale.key]?.title}
                        </Select.Option>
                      ))}
                </StyledSelect>
              </Row>
              <Row>
                <BJButton
                  buttonType={
                    changeFeaturedListOrder
                      ? ButtonTypes.Primary
                      : ButtonTypes.Secondary
                  }
                  onClick={() => setchangeFeaturedListOrder(prev => !prev)}
                >
                  Change Order
                </BJButton>
              </Row>
              <BJFeaturedList
                changeFeaturedListOrder={changeFeaturedListOrder}
                featuredItems={featuredOffers?.map(id =>
                  mapToFeaturedOffer(id, locale)
                )}
                setfeaturedItems={item => {
                  setValue(
                    "featuredOffers",
                    item.map(i => i.id),
                    {
                      shouldDirty: true,
                    }
                  );
                }}
                removeFeaturedItem={id => {
                  setValue(
                    "featuredOffers",
                    featuredOffers.filter(_id => _id !== id),
                    {
                      shouldDirty: true,
                    }
                  );
                }}
              />
            </Col>
            <Col md={24} lg={12}>
              <AiTranslator
                locale={locale}
                data={offerTypeSchemes({
                  offerType,
                  translations,
                  button,
                  confirmationPopup,
                })}
                onSetValue={onSetTranslatedValue}
              />
              <Form.Item
                label="Company"
                validateStatus={errors.company && "error"}
                extra={
                  <Typography.Paragraph type="danger">
                    {errors.company?.message}
                  </Typography.Paragraph>
                }
              >
                <Controller
                  control={control}
                  name="company"
                  render={({ field }) => <BJInput {...field} />}
                />
              </Form.Item>
              <Form.Item label="Sponsor" name="sponsorId" required>
                <Controller
                  control={control}
                  name="sponsorId"
                  render={({ field: { onChange, value } }) => (
                    <Select onChange={onChange} value={value} size="large">
                      {sponsors.map(sponsor => (
                        <Select.Option value={sponsor.id} key={sponsor.id}>
                          {sponsor.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                />
              </Form.Item>
              <Form.Item
                label="Hide sponsor from the list views"
                name="hideSponsorLogo"
              >
                <Controller
                  control={control}
                  name="hideSponsorLogo"
                  render={({ field: { onChange, value } }) => (
                    <Switch onChange={onChange} checked={value} />
                  )}
                />
              </Form.Item>
              <BJAudienceMultiple />

              <BJTagsFormItem
                label={`Tag Words (${locale?.label ?? ""})`}
                key={`translations.${locale.key}.tagWords`}
                fieldName={`translations.${locale.key}.tagWords`}
                control={control}
                setValue={setValue}
                aiTagWordsPayload={{
                  contentTitle: translations?.[locale.key]?.title,
                  contentIntro: translations?.[locale.key]?.intro,
                  contentBody: translations?.[locale.key]?.body,
                  contentType: ContentTypeNames.offers,
                  documentId: offer?.id,
                  language: locale.label,
                }}
                aiTranslateTagWordsPayload={{
                  tagWords: translations?.[primaryLocale.key]?.tagWords,
                  from: primaryLocale.label,
                }}
                existingTags={translations?.[locale.key]?.tagWords || []}
                locale={locale.key}
              />
              <Form.Item label="Type" name="type">
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      onChange={onChange}
                      value={
                        getOfferTypes().find(offer => offer.value === value)
                          ?.name || ""
                      }
                      size="large"
                    >
                      {getOfferTypes().map(type => {
                        return (
                          <Select.Option value={type.value} key={type.key}>
                            {type.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  )}
                />
              </Form.Item>
              <Form.Item label="" name="accordion">
                <Collapse expandIconPosition="right" accordion>
                  <Panel header="Image, banner &amp; video" key="1">
                    <OfferImageBannerVideo
                      locale={locale}
                      offer={offer}
                      errors={errors}
                      control={control}
                      watch={watch}
                      setValue={setValue}
                      handleImageUrl={handleImageUrl}
                    />
                  </Panel>

                  {offerType !== "2" && (
                    <Panel header="Button / CTA" key="2">
                      <OfferSimpleButtonCTA
                        errors={errors}
                        locale={locale}
                        control={control}
                      />
                    </Panel>
                  )}
                  {(offerType == "3" || offerType == "4") && (
                    <Panel header="Inputs" key="4">
                      <OfferInputs
                        errors={errors}
                        inputs={inputFields}
                        control={control}
                        onAdd={onAddOfferInput}
                        onRemove={onRemoveOfferInput}
                        locale={locale}
                      />
                    </Panel>
                  )}
                  {(offerType == "3" || offerType == "4") && (
                    <Panel header="Terms" key="3">
                      <OfferSignUpTerms errors={errors} control={control} />
                    </Panel>
                  )}
                  {offerType === "3" && (
                    <>
                      <Panel header="Success Message" key="5">
                        <OfferSignUpSuccess
                          errors={errors}
                          locale={locale}
                          control={control}
                          watch={watch}
                          handleImageUrl={handleImageUrl}
                        />
                      </Panel>
                    </>
                  )}
                  {offerType == "4" && (
                    <Panel header="Coupon Code" key="6">
                      <BJInputFormItem
                        required={true}
                        label={`Coupon Code (${locale?.label ?? ""})`}
                        fieldName={`translations.${locale.key}.couponCode`}
                        key={`translations.${locale.key}.couponCode`}
                        control={control}
                      />
                    </Panel>
                  )}
                  {offerType == "4" && (
                    <Panel header="Confirmation Popup" key="7">
                      <OfferConfirmationPopup
                        errors={errors}
                        locale={locale}
                        control={control}
                      />
                    </Panel>
                  )}
                </Collapse>
              </Form.Item>

              <OfferChecklist
                control={control}
                setValue={setValue}
                displayOfferText={displayOfferText}
                pickerWeeks={pickerWeeks}
                pickerMonths={pickerMonths}
                checkListChildOffer={checkListChildOffer}
                checkListPregnancyOffer={checkListPregnancyOffer}
                setCheckListChildOffer={setCheckListChildOffer}
                setCheckListPregnancyOffer={setCheckListPregnancyOffer}
              />
              <br />
              <OfferPregnancyChild
                control={control}
                setValue={setValue}
                displayOfferText={displayOfferText}
                pickerWeeks={pickerWeeks}
                pickerMonths={pickerMonths}
                monthChildOffer={monthChildOffer}
                weekPregnancyOffer={weekPregnancyOffer}
                setWeekPregnancyOffer={setWeekPregnancyOffer}
                setMonthChildOffer={setMonthChildOffer}
              />
            </Col>
          </Row>
        )}
      </FormEdit>
    </FormProvider>
  );
};

const ItemWrapper = styled.div`
  padding-top: 1rem;
`;

const StyledSelect = styled(Select)`
  width: 12rem;
`;
