import styled from "styled-components";
import { ReactComponent as WaveSvg } from "./../../../../assets/wave.svg";

export const ContentWave = ({ content }: { content: JSX.Element }) => {
  return (
    <>
      <WaveRectangle>{content}</WaveRectangle>
      <WaveTidal>
        <WaveSvg />
      </WaveTidal>
    </>
  );
};
const WaveRectangle = styled.div`
  background: ${props => props.theme.primary};
  height: 4.75rem;
  padding-top: 0.625rem;
  display: flex;
  align-items: center;
  border-radius: 0.625rem 0.625rem 0 0;
`;

const WaveTidal = styled.div`
  margin-top: -0.3rem;
`;
