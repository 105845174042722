import { Form, Typography } from "antd";
import { Dispatch, SetStateAction, useEffect } from "react";
import { FaSync } from "react-icons/fa";
import useScraper from "../hooks/useScraper";
import BJInput from "./theme/atoms/BJInput";
import BJButton, { ButtonTypes } from "./theme/atoms/Button";
import styled from "styled-components";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { PostFormValues } from "../pages/Post/PostPage";

type scrapper<PostFormValues> = {
  parenterrors: DeepMap<PostFormValues, FieldError>;
  parentcontrol: Control<PostFormValues>;
  parentReset: UseFormReset<PostFormValues>;
  initialUrl: string;
  getValues: UseFormGetValues<PostFormValues>;
  setValue: UseFormSetValue<PostFormValues>;
  watch: UseFormWatch<PostFormValues>;
  setScrapped: Dispatch<SetStateAction<boolean>>;
  locale: Locale;
};

export const Scraper = ({
  parenterrors,
  parentcontrol,
  parentReset,
  getValues,
  setValue,
  watch,
  setScrapped,
  locale,
}: scrapper<PostFormValues>) => {
  const { scrapped, setScrapeUrl, scrapeLoading, error } = useScraper();

  useEffect(() => {
    if (!scrapeLoading && !error) {
      if (scrapped?.imageUrl) {
        setValue("imageUrl", scrapped.imageUrl);
      }
      if (scrapped?.title) {
        setValue(`translations.${locale.key}.title`, scrapped.title);
      }
      if (scrapped?.description) {
        setValue(
          `translations.${locale.key}.description`,
          scrapped.description
        );
      }
    }
    if (scrapped === null) {
      parentReset({ ...getValues(), imageUrl: "" });
    }
  }, [
    scrapped,
    scrapeLoading,
    parentReset,
    setValue,
    error,
    getValues,
    locale.key,
  ]);

  const updateScraper = async () => {
    const url = getValues("url");
    setScrapeUrl(url);
    setScrapped(true);
  };

  const { url } = watch();

  return (
    <Form.Item
      label="Link to post"
      validateStatus={"error"}
      extra={
        <Typography.Paragraph>
          Please DO NOT enter short URLs
        </Typography.Paragraph>
      }
      help={
        (parenterrors.url || error) && (
          <Typography.Paragraph type="danger">
            {error?.message}
            <StyledSpan>{parenterrors.url?.message}</StyledSpan>
          </Typography.Paragraph>
        )
      }
      required
    >
      <Controller
        control={parentcontrol}
        name="url"
        render={({ field: { onChange, value } }) => (
          <BJInput
            onChange={onChange}
            value={value}
            placeholder={"Enter an url"}
          />
        )}
      />
      <BJButton
        style={{ marginTop: "0.25rem" }}
        buttonType={ButtonTypes.Primary}
        onClick={updateScraper}
        disabled={scrapeLoading || !(url?.length > 0)}
        loading={scrapeLoading}
      >
        <FaSync className="mr-2" size={12} />
        <span>Get image, title and description from link</span>
      </BJButton>
    </Form.Item>
  );
};

const StyledSpan = styled.span`
  margin-left: "1rem";
`;
