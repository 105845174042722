import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useImages } from "../../context";
import { ImagesService } from "../../services";
import { FormEdit, FormEditType } from "../../components/FormEdit";
import {
  BJSortableList,
  SortContentType,
} from "../../components/theme/molecules/BJSortableList";
import { arrayMove } from "../../utils/moveArrayItems";
import styled from "styled-components";

type SortProps = {
  oldIndex: number;
  newIndex: number;
};

export const ChangeUploadImageList = () => {
  const { images: allUploadImage, loading: uploadImageLoading } = useImages();
  const { handleSubmit } = useForm();
  const [isDirty, setIsDirty] = useState(false);
  const [uploadImage, setUploadImage] = useState<Image[]>([]);

  useEffect(() => {
    setUploadImage(
      allUploadImage
        .sort((a: Image, b: Image) => a.sortOrder - b.sortOrder)
        .map((uploadImage, index) => ({ ...uploadImage, sortOrder: index }))
    );
  }, [allUploadImage]);

  const saveOrder = async () => {
    await ImagesService.updateSortOrder(
      uploadImage.map((uploadImage, index) => ({
        ...uploadImage,
        sortOrder: index,
      }))
    );
    setIsDirty(false);
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortProps) => {
    if (oldIndex === newIndex) {
      return;
    }
    const sortableUploadImage = arrayMove([...uploadImage], oldIndex, newIndex);
    setUploadImage(sortableUploadImage);
    setIsDirty(true);
  };

  return (
    <FormEdit
      enableSave={isDirty}
      title={"Change Image order"}
      editType={FormEditType.VIEW}
      loading={uploadImageLoading}
      onSubmit={handleSubmit(saveOrder)}
    >
      <ItemWrapper>
        <BJSortableList
          items={uploadImage.map(
            uploadImage =>
              ({
                id: uploadImage.id,
                title: uploadImage.internalName,
                noImage: true,
              } as SortContentType)
          )}
          onSortEnd={onSortEnd}
        />
      </ItemWrapper>
    </FormEdit>
  );
};

const ItemWrapper = styled.div`
  user-select: none;
  -webkit-user-select: none;
`;
