import { Layout } from "antd";
import styled from "styled-components";
import { Outlet, useNavigate } from "react-router";
import SiderNavigationBar from "../components/theme/components/SiderNavBar";
import { BJContainer } from "../components/theme/atoms/BJContainer";
import { GrUserAdmin } from "react-icons/gr";
import { ErrorFallback } from "../components";
import * as Sentry from "@sentry/react";
const { Content } = Layout;

const menuItems = () => {
  const menuItemList: MenuItemInfo[] = [
    { displayName: "Edit Profile", url: `edit`, icon: <GrUserAdmin /> },
  ];
  return menuItemList;
};

export const ProfileLayout = () => {
  const navigate = useNavigate();
  const items: MenuItemInfo[] = menuItems();

  return (
    <Layout>
      <StyledLayout>
        <SiderNavigationBar items={items} />
        <StyledLayoutContent>
          <Content>
            <BJContainer>
              <Sentry.ErrorBoundary
                fallback={ErrorFallback}
                onReset={() => {
                  navigate("./");
                  // reset the state of your app so the error doesn't happen again
                }}
              >
                <Outlet />
              </Sentry.ErrorBoundary>
            </BJContainer>
          </Content>
        </StyledLayoutContent>
      </StyledLayout>
    </Layout>
  );
};

const StyledLayout = styled(Layout)`
  background-color: ${props => props.theme.white};
  height: 100vh;
`;

const StyledLayoutContent = styled(Layout)`
  padding: 1rem 1rem 1rem;
`;
