import firebase from "../firebase";
import { firebaseCollectionNames } from "../utils";
import { logException } from "../utils/exceptionLogger";
const { stories } = firebaseCollectionNames;

const toUserBirthStories = (
  doc: firebase.firestore.QueryDocumentSnapshot
): UserBirthStory => {
  const data = doc.data();
  return {
    id: doc.id,
    childId: data.childId,
    childBirthType: data.childBirthType,
    gestationalWeek: data.gestationalWeek,
    hours: data.hours,
    dob: data.dob?.toDate(),
    birthTime: data.birthTime?.toDate(),
    length: data.length,
    weight: data.weight,
    deliveryRating: data.deliveryRating,
    title: data.title,
    body: data.body,
    images: data.images,
    status: data.status,
    type: data.type,
    userId: doc.ref.parent.parent?.id,
  };
};

const subscribe = (
  callback: (err: Error | null, birthStories: UserBirthStory[]) => void
) =>
  firebase
    .firestore()
    .collectionGroup(stories)
    .onSnapshot(
      snapshot => {
        const birthStories = snapshot.docs.map(toUserBirthStories);
        callback(null, birthStories);
      },
      err => {
        console.log("birthStories", err);
        logException(err);
        callback(err, []);
      }
    );

export const UserBirthStoriesService = {
  subscribe,
};
