import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ToolsMenuSectionService } from "../services/ToolsMenuItemService";
import { useCountry } from "./CountryContext";

export type Tool = {
  menuItems: MenuItem[];
  id: string;
  translations: { [locale: string]: { title: string; description: string } };
};

interface MenuItem {
  id: string;
  translations?: {
    [locale: string]: {
      description: string;
      title: string;
    };
  };
}

type ContextState = {
  tools: Tool[];
  loading: boolean;
  error: Error | null;
  toolsService: ToolsMenuSectionService | null;
};

const ToolsContext = createContext<ContextState>({
  tools: [],
  loading: false,
  error: null,
  toolsService: null,
});

export const ToolsProvider = ({ ...rest }) => {
  const [tools, setTools] = useState<Tool[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const toolsService = useMemo(
    () =>
      currentCountry?.abb
        ? new ToolsMenuSectionService(currentCountry.abb)
        : null,
    [currentCountry?.abb]
  );

  useEffect(() => {
    setLoading(true);
    if (!toolsService) {
      console.warn("Tools service is not available.");
      setLoading(false);
      return;
    }

    const unsubscribe = toolsService.subscribe((err, _menuItems) => {
      if (err) {
        setError(err);
        setLoading(false);
        return;
      }

      const tools = toolsService.getTools(_menuItems);
      setTools(tools);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [toolsService, currentCountry?.abb]);

  const value = useMemo(
    () => ({
      tools,
      loading,
      error,
      toolsService,
    }),
    [tools, loading, error, toolsService]
  );

  return <ToolsContext.Provider value={value} {...rest} />;
};

export const useTools = () => {
  const context = React.useContext(ToolsContext);
  if (context === undefined) {
    throw new Error("useTools must be used within a ToolsProvider");
  }
  return context;
};

export const useTool = (toolId: string) => {
  const [tool, setTool] = useState<Tool | null>(null);
  const [loading, setLoading] = useState(true);

  const { tools, loading: loadingTools } = useTools();

  const getToolById = useCallback(
    (id: string) => {
      const _tool = tools.find(tool => tool.id === id);
      return _tool || null;
    },
    [tools]
  );

  useEffect(() => {
    if (loadingTools) {
      return;
    }

    setTool(getToolById(toolId));
    setLoading(false);
  }, [loadingTools, getToolById, toolId]);

  return { tool, loading };
};

export { useCountry };
