import { Alert } from "antd";
import { CenteredSpinner } from "./CenteredSpinner";
import { useState } from "react";
import { httpsCallable, HttpsCallableResult } from "firebase/functions";
import { functions } from "../firebase";
import { cloudFunctionNames } from "../utils";
import BJButton, { ButtonTypes } from "./theme/atoms/Button";
import styled from "styled-components";
import { useCountry } from "../context";

type AiTranslatorProps = {
  locale: Locale;
  data: Array<string>;
  onSetValue: (value: { data: string[] }) => void;
  copyNonTranslation?: () => void;
  showCopyButton?: boolean;
  hideTranslation?: boolean;
};

export const AiTranslator = ({
  locale,
  data,
  onSetValue,
  copyNonTranslation,
  showCopyButton = false,
  hideTranslation = false,
}: AiTranslatorProps) => {
  const { currentCountry } = useCountry();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [noData, setNoData] = useState(false);

  const btns = [
    {
      key: "translate",
      label: "Translate",
      primary: true,
    },
    {
      key: "copy",
      label: "Copy Values",
      primary: false,
    },
  ];

  const onSelect = (btn: string) => {
    switch (btn) {
      case "translate":
        onTranslate();
        break;
      case "copy":
        onCopy();
        break;
    }
  };

  const onTranslate = async () => {
    setLoading(true);
    setError(false);
    setNoData(false);
    const createTranslateCallable = httpsCallable(
      functions,
      cloudFunctionNames.aitranslator
    );

    try {
      await createTranslateCallable({
        data: {
          from: "Swedish",
          to: "English",
          input_text: data,
        },
      }).then((result: HttpsCallableResult<any>) => {
        setLoading(false);
        if (result.data.data === "Service is unavailable at the moment.") {
          setError(true);
          setErrorMessage(result.data.data);
        } else {
          onSetValue(JSON.parse(result.data.data));
        }
      });
    } catch (err) {
      setError(true);
      setErrorMessage(JSON.stringify(err));
      setLoading(false);
      console.log(err);
    }
  };
  //TODO: Add copy to clipboard functionality
  const onCopy = () => {
    return copyNonTranslation
      ? (error && setError(false), copyNonTranslation())
      : (setError(true), setErrorMessage("No function provided."));
  };

  return (
    currentCountry?.abb === "se" &&
    locale?.key === "en" && (
      <>
        {error && (
          <StyledSpan>
            <Alert message={errorMessage} type="error" showIcon />
          </StyledSpan>
        )}
        {noData && (
          <StyledSpan>
            <Alert
              message="Please enter swedish data"
              type="warning"
              showIcon
            />
          </StyledSpan>
        )}
        <StyledSpan>
          {loading ? (
            <CenteredSpinner />
          ) : (
            btns
              .filter(e => {
                if (hideTranslation && !showCopyButton) {
                  return null;
                }

                if (!showCopyButton) {
                  return e.key !== "copy";
                }
                if (hideTranslation) {
                  return e.key !== "translate";
                }

                return e;
              })
              .sort((a, b) =>
                a.primary === b.primary ? 0 : b.primary ? 1 : -1
              )
              .map(e => {
                return (
                  <span key={e?.key} style={{ marginRight: 8 }}>
                    <BJButton
                      key={e.key}
                      buttonType={
                        e.primary ? ButtonTypes.Primary : ButtonTypes.Secondary
                      }
                      size="small"
                      onClick={() => onSelect(e.key)}
                    >
                      {e.label}
                    </BJButton>
                  </span>
                );
              })
          )}
        </StyledSpan>
      </>
    )
  );
};

const StyledSpan = styled("span")`
  display: flex;
  margin-bottom: 10px;
`;
