import { Form, List, Typography } from "antd";
import React from "react";
import BJInput from "../../components/theme/atoms/BJInput";
import styled from "styled-components";
import { FaTimesCircle } from "react-icons/fa";
import { theme } from "../../components/theme";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
} from "react-hook-form";
import { FormValues } from "./types";

type Props = {
  inputs: FieldArrayWithId<FormValues, "inputs", "id">[];
  errors: FieldErrors<FormValues>;
  control: Control<FormValues, object>;
  onAdd?: () => void | Promise<void>;
  onRemove?: (index: number) => void | Promise<void>;
  locale: Locale;
};

const OfferInputs: React.FC<Props> = ({
  inputs,
  errors,
  onAdd,
  onRemove,
  control,
  locale,
}) => {
  return (
    <div>
      {inputs &&
        inputs.map((item, index) => {
          return (
            <StyledListGroupItem key={item.id}>
              <StyledInput>
                <Form.Item
                  key={`offer_input_${item.id}`}
                  label={`Input ${index + 1}: (${locale?.label ?? ""})`}
                  name={item?.name}
                  validateStatus={errors?.inputs && "error"}
                  extra={
                    errors?.inputs &&
                    errors?.inputs?.[index]?.translations[locale.key]
                      ?.value && (
                      <Typography.Paragraph type="danger">
                        Input {index + 1} value is required
                      </Typography.Paragraph>
                    )
                  }
                >
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <BJInput {...field} placeholder="Value" />
                    )}
                    name={`inputs.${index}.translations.${locale.key}.value`}
                    key={`inputs.${index}.translations.${locale.key}.value`}
                  />
                </Form.Item>
              </StyledInput>

              <StyledRemove onClick={() => onRemove(index)}>
                <FaTimesCircle size={16} color={theme.red} />
              </StyledRemove>
            </StyledListGroupItem>
          );
        })}
      <BJButton size="small" buttonType={ButtonTypes.Primary} onClick={onAdd}>
        Add Input
      </BJButton>
    </div>
  );
};

const StyledListGroupItem = styled(List.Item)`
  flex-direction: row;
  padding: 0;
`;

const StyledInput = styled.div`
  width: 100%;
`;

const StyledRemove = styled.div`
  cursor: pointer;
  width: 10%;
  display: flex;
  justify-content: center;
`;

export default OfferInputs;
