import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { commonErrors } from "../../language";
import { FormModal } from "../../components";
import {
  BJInputFormItem,
  BJMdFormItem,
  BJSelectFormItem,
} from "../../components/theme";
import {
  AspectRatio,
  BannerDisplayableSegment,
  formValidationError,
} from "../../utils";
import { v4 as uuidv4 } from "uuid";
import { Form, Select } from "antd";
import { DropAndCrop } from "../../components/DropAndCrop";
import { useCountry, useMiniJourneys } from "../../context";

interface Props {
  show: boolean;
  onHide: () => void;
  reward: MiniJourneyReward | null;
  onDelete: (id: MiniJourneyReward["id"]) => void;
  onAdd: (reward: MiniJourneyReward) => void;
  onUpdate: (reward: MiniJourneyReward) => void;
}

type FormValues = {
  translations: {
    [locale: string]: {
      title: string;
      description: string;
      buttonText?: string;
    };
  };
  type: MiniJourneyRewardType;
  reward: string;
  externalLink?: string;
  couponCode?: string;
  successPopupImage?: string;
  successPopupblurhash?: string;
  userSegment: BannerDisplayableSegment;
};

const rewardTypes: Record<MiniJourneyRewardType, string> = {
  coupon: "Coupon",
  externalLink: "External Link",
};

const { requiredError, urlValidationError2: urlError } = commonErrors;

export const RewardModal = ({
  show,
  onHide,
  onAdd,
  reward,
  onUpdate,
  onDelete: onDeleteReward,
}: Props) => {
  const { currentCountry } = useCountry();
  const { uploadMiniJourneyImage } = useMiniJourneys();

  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup
            .string()
            .required(
              `Title (${String(item.key).toUpperCase()}): ${requiredError}`
            ),
          description: yup
            .string()
            .required(
              `Description (${String(
                item.key
              ).toUpperCase()}): ${requiredError}`
            ),
          buttonText: yup.string().optional(),
        });
        return acc;
      }, {} as any)
    ),
    reward: yup.string().required(`Reward: ${requiredError}`),
    type: yup.string().required(`Type: ${requiredError}`).nullable(),
    userSegment: yup.string().nullable(),
    externalLink: yup.string().optional(),
    couponCode: yup.string().optional(),
  });

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState,
    setValue,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const [error, setError] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(
    reward?.successPopupImage ?? null
  );
  const [blurhash, setBlurhash] = useState<string | null>(
    reward?.successPopupblurhash ?? null
  );

  useEffect(() => {
    if (reward) {
      reset(reward);
      setImageUrl(reward?.successPopupImage ?? null);
      setBlurhash(reward?.successPopupblurhash ?? null);
    } else {
      reset({
        ...reward,
        type: null,
        reward: "",
        externalLink: "",
        couponCode: "",
        userSegment: null,
      });
      setImageUrl(null);
      setBlurhash(null);
    }
    setError(null);
  }, [reward, reset, show]);

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const translations: MiniJourneyReward["translations"] = {};

    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        title: value.title,
        description: value.description,
        buttonText: value.buttonText ?? "",
      };
    }

    const _reward: MiniJourneyReward = {
      id: reward ? reward.id : uuidv4(),
      targetSegment: {
        segment: data.userSegment,
      },
      translations: translations,
      reward: data.reward,
      type: data.type,
      externalLink: data.externalLink,
      couponCode: data.couponCode,
      successPopupImage: imageUrl,
      successPopupblurhash: blurhash,
    };

    if (reward) {
      onUpdate(_reward);
      onHide();
      return;
    }
    onAdd(_reward);
    onHide();
  };

  const onDelete = async () => {
    if (reward) {
      onDeleteReward(reward.id);
    }
    onHide();
  };

  const handleSuccessPopupImageUrl = (url: string | null) => {
    setImageUrl(url);
  };

  const handleSuccessPopupBlurhash = (url: string | null) => {
    setBlurhash(url);
  };

  return (
    <FormModal
      onHide={onHide}
      messageOnSubmit={false}
      enableSave={true}
      show={show}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      // error={error}
      errors={errors as any}
      onDelete={onDelete}
      modalTitle={reward?.reward ?? "New Reward"}
      enableDelete={!!reward}
      size={"lg"}
      localeSupported
    >
      {locale => (
        <>
          <BJInputFormItem
            {...register(`translations.${locale.key}.title`)}
            control={control}
            error={!!errors?.translations?.[locale.key]?.title?.message}
            label={`Title (${locale.label})`}
            message={errors?.translations?.[locale.key]?.title?.message}
            fieldName={`translations.${locale.key}.title`}
            key={`translations.${locale.key}.title`}
            required
          />
          <BJMdFormItem
            {...register(`translations.${locale.key}.description`)}
            control={control}
            error={!!errors?.translations?.[locale.key]?.description?.message}
            label={`Description (${locale.label})`}
            message={errors?.translations?.[locale.key]?.description?.message}
            fieldName={`translations.${locale.key}.description`}
            key={`translations.${locale.key}.description`}
            disabled={false}
            required
          />
          <BJInputFormItem
            {...register("reward")}
            control={control}
            error={!!errors.reward?.message}
            label={"Reward"}
            message={errors.reward?.message}
            fieldName={"reward"}
            required
          />
          <Form.Item label="Type" name="type" required>
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, value } }) => (
                <Select onChange={onChange} value={value} size="large">
                  {Object.entries(rewardTypes).map(([key, value], index) => (
                    <Select.Option value={key} key={index}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Form.Item>
          <BJInputFormItem
            {...register("couponCode")}
            control={control}
            error={!!errors.couponCode?.message}
            label={"Coupon Code"}
            message={errors.couponCode?.message}
            fieldName={"couponCode"}
          />
          <BJInputFormItem
            {...register("externalLink")}
            control={control}
            error={!!errors.externalLink?.message}
            label={"External Link"}
            message={errors.externalLink?.message}
            fieldName={"externalLink"}
          />
          <BJInputFormItem
            {...register(`translations.${locale.key}.buttonText`)}
            control={control}
            error={!!errors?.translations?.[locale.key]?.buttonText?.message}
            label={`Reward button text (${locale.label})`}
            message={errors?.translations?.[locale.key]?.buttonText?.message}
            fieldName={`translations.${locale.key}.buttonText`}
            key={`translations.${locale.key}.buttonText`}
          />
          <Form.Item
            required
            label="Success Popup Image"
            validateStatus={errors.successPopupImage && "error"}
            extra=""
          >
            <DropAndCrop
              initialUrl={imageUrl}
              allowNaturalImageUpload={true}
              title="Success Popup Image"
              setUploadUrl={handleSuccessPopupImageUrl}
              setBlurhash={handleSuccessPopupBlurhash}
              uploadImage={uploadMiniJourneyImage}
              lockedRatio={AspectRatio.Free}
              previewImageWidth={"10"}
              defaultCropBoxWidth={300}
              extra={"Best resolution for this will be 1200 x 400"}
            />
          </Form.Item>
          <BJSelectFormItem
            required
            disabled={false}
            defaultValue={BannerDisplayableSegment.allUsers}
            size="large"
            control={control}
            error={!!errors.userSegment}
            label={"User segment"}
            extra="Message will be published all users in selected segment"
            message={errors.userSegment?.message}
            optionsList={[
              {
                key: BannerDisplayableSegment.allUsers,
                value: BannerDisplayableSegment.allUsers,
                display: "All users",
              },
            ]} //this will change in the future, for now we only want all users
            fieldName={"userSegment"}
          />
        </>
      )}
    </FormModal>
  );
};
