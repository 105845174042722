import firebase from "../firebase";
import {
  formatDateStringToTimeStamp,
  formatTimeStampToDateString,
} from "../utils";
import { CountryCodeService } from "./CountryCodeService";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";

export class PodcastEpisodeService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  static toPodcastEpisode =
    (podcastId: Podcast["id"]) =>
    (doc: any): PodcastEpisode => {
      const data = doc.data();
      return {
        id: doc.id,
        podcastId,
        ...data,
        translations: Object.entries(
          data.translations as PodcastEpisode["translations"]
        ).reduce((acc, [key, value]) => {
          return (acc[key] = { ...value, tagWords: value.tagWords || [] }), acc;
        }, {} as PodcastEpisode["translations"]),
        publishDate: formatTimeStampToDateString(data.publishDate),
      };
    };

  getEpisodesCollection = (podcastId: string) =>
    `${CollectionPaths.Podcasts[this.countryCode]}/${podcastId}/episodes`;

  subscribe = (
    podcastId: Podcast["id"],
    callback: (err: Error | null, episodes: PodcastEpisode[]) => void
  ) =>
    firebase
      .firestore()
      .collection(this.getEpisodesCollection(podcastId))
      .onSnapshot(
        async snapshot => {
          const _episodes = snapshot.docs.map(
            PodcastEpisodeService.toPodcastEpisode(podcastId)
          );
          callback(null, _episodes);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  getEpisodes = (podcastId: Podcast["id"]) => {
    return new Promise<PodcastEpisode[]>((resolve, reject) => {
      firebase
        .firestore()
        .collection(this.getEpisodesCollection(podcastId))
        .get()
        .then(snapshot => {
          const _episodes = snapshot.docs.map(
            PodcastEpisodeService.toPodcastEpisode(podcastId)
          );
          resolve(_episodes);
        })
        .catch(error => {
          logException(error);
          reject(error);
        });
    });
  };

  create = (podcastId: Podcast["id"], data: PodcastEpisode) => {
    const publishDate = formatDateStringToTimeStamp(data.publishDate, true);

    return firebase
      .firestore()
      .collection(this.getEpisodesCollection(podcastId))
      .add({ ...data, publishDate });
  };

  update = (
    podcastId: Podcast["id"],
    podcastEpisodeId: PodcastEpisode["id"],
    data: PodcastEpisode
  ) => {
    const publishDate = formatDateStringToTimeStamp(data.publishDate, true);
    return firebase
      .firestore()
      .collection(this.getEpisodesCollection(podcastId))
      .doc(podcastEpisodeId)
      .update({ ...data, publishDate });
  };

  delete = (podcastId: Podcast["id"], podcastEpisodeId: PodcastEpisode["id"]) =>
    firebase
      .firestore()
      .collection(this.getEpisodesCollection(podcastId))
      .doc(podcastEpisodeId)
      .delete();

  updateSortOrders = (podcastId: Podcast["id"], episodes: PodcastEpisode[]) => {
    const batch = firebase.firestore().batch();
    episodes.forEach(episode => {
      const ref = firebase
        .firestore()
        .collection(this.getEpisodesCollection(podcastId))
        .doc(episode.id);
      batch.update(ref, { sortOrder: episode.sortOrder });
    });
    return batch.commit();
  };

  updatePodcastImageUrl = (
    podcastId: Podcast["id"],
    episodes: PodcastEpisode[],
    imageUrl: string,
    blurhash: string
  ) => {
    const batch = firebase.firestore().batch();
    episodes.forEach(episode => {
      const ref = firebase
        .firestore()
        .collection(this.getEpisodesCollection(podcastId))
        .doc(episode.id);
      batch.update(ref, { imageUrl: imageUrl, blurhash });
    });
    return batch.commit();
  };
}
