import { Col, Row } from "antd";
import { useEffect } from "react";
import { TagsService } from "../../services";
import { useForm } from "react-hook-form";
import { FormModal } from "../../components";
import { yupResolver } from "@hookform/resolvers/yup";
import { formValidationError } from "../../utils";
import { useTags } from "../../context";
import { BJInputFormItem } from "../../components/theme/molecules/formItems/BJFormInput";
import * as yup from "yup";
import { commonErrors } from "../../language";
import { useTranslation } from "react-i18next";
interface Props {
  show: boolean;
  onHide: () => void;
  tag?: Tags;
}

type FormValues = {
  name: string;
};

export const TagsModal = ({ show: showModal, onHide, tag }: Props) => {
  const { t } = useTranslation();
  const { tags, loadingTags: loading } = useTags();
  const tagsSchema = yup.object().shape({
    name: yup
      .string()
      .required(commonErrors.requiredError)
      .test(
        "Record already exists",
        t("tags:duplicateTag"),
        function (value: string): boolean {
          return !tags.some(
            x =>
              x.name.toLocaleLowerCase().trim() ===
              value.toLocaleLowerCase().trim()
          );
        }
      ),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(tagsSchema),
  });

  useEffect(() => {
    if (loading) return;
    reset({
      name: tag?.name ?? "",
    });
  }, [loading, reset, tag]);

  const onSubmit = async (data: FormValues) => {
    if (tag) {
      await TagsService.updateTags(data.name, tag.name.trim());
    } else {
      await TagsService.addTags(data.name.trim());
    }
  };

  const onDelete = async () => {
    await TagsService.deleteTags(tag.name);
  };

  const isDirty = !!Object.keys(formState.dirtyFields).length;

  return (
    <FormModal
      onDelete={onDelete}
      enableDelete={!!tag}
      onHide={onHide}
      enableSave={isDirty}
      show={showModal}
      size="sm"
      modalTitle={tag?.name ?? "Add tag"}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
    >
      <>
        <Row gutter={24}>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <BJInputFormItem
                  control={control}
                  error={!!errors.name}
                  label={"Tag"}
                  message={errors.name?.message}
                  required={true}
                  autoFocus
                  fieldName={"name"}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    </FormModal>
  );
};
