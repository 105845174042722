import React from "react";
import { BJInputFormItem } from "../../components/theme";
import { Form, Typography } from "antd";
import { Control, Controller, FieldErrors } from "react-hook-form";
import BJInput from "../../components/theme/atoms/BJInput";
import { FormValues } from "./types";

type Props = {
  errors: FieldErrors<FormValues>;
  control: Control<FormValues, object>;
  locale: Locale;
};

const OfferSimpleButtonCTA: React.FC<Props> = ({ locale, errors, control }) => {
  return (
    <>
      <BJInputFormItem
        label={`Text (${locale?.label ?? ""})`}
        fieldName={`button.translations.${locale.key}.text`}
        key={`button.translations.${locale.key}.text`}
        control={control}
        error={!!errors?.button?.translations[locale.key]?.text}
        message={errors?.button?.translations[locale.key]?.text?.message}
        required
      />
      <Form.Item
        label="Link"
        validateStatus={errors.button?.link && "error"}
        extra={
          <Typography.Paragraph type="danger">
            {errors.button?.link?.message}
          </Typography.Paragraph>
        }
      >
        <Controller
          control={control}
          name="button.link"
          render={({ field }) => <BJInput {...field} />}
        />
      </Form.Item>
    </>
  );
};

export default OfferSimpleButtonCTA;
