import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CenteredSpinner } from "../../components";
import { CompetitionsProvider, UserBirthStoriesProvider } from "../../context";
import { AdminLayout } from "../../Layouts";
import { Compose } from "../../Providers";

const BackofficeUser = React.lazy(
  () => import("../../pages/Admin/ManageBackofficeUsers/BackofficeUsersList")
);

const CompetitionsPage = React.lazy(
  () => import("../../pages/Admin/Competitions/CompetitionsPage")
);
const CompetitionsListPage = React.lazy(
  () => import("../../pages/Admin/Competitions/CompetitionsList")
);

const UserSearchPage = React.lazy(
  () => import("../../pages/Admin/UserSearch/UserSearchPage")
);
const ForceUpdatePage = React.lazy(
  () => import("../../pages/Admin/ForceUpdate/ForceUpdatePage")
);

const MiniJourneysPage = React.lazy(
  () => import("../../pages/Admin/MiniJourneys/MiniJourneysPage")
);
const MiniJourneysListPage = React.lazy(
  () => import("../../pages/Admin/MiniJourneys/MiniJourneysList")
);
const OfferSignUpListPage = React.lazy(
  () => import("../../pages/Admin/OfferSignUps/OfferSignUpsListPage")
);
const CountryAndLocalesPage = React.lazy(
  () => import("../../pages/Admin/CountryAndLocales/CountryAndLocales")
);

const BirthStoriesListPage = React.lazy(
  () => import("../../pages/Admin/UserBirthStories/UserBirthStoriesList")
);

const DailyDoulaOperationCenterPage = React.lazy(
  () =>
    import(
      "../../pages/Admin/DailyDoulaOperationCenter/dailyDoulaOperationCenter"
    )
);

export const AdminRoutes = () => (
  <React.Suspense fallback={<CenteredSpinner />}>
    <Compose components={[CompetitionsProvider, UserBirthStoriesProvider]}>
      <Routes>
        <Route path={`/`} element={<AdminLayout />}>
          <Route path={""} element={<Navigate to="backoffice-users" />} />
          <Route path={"backoffice-users"} element={<BackofficeUser />} />
          <Route path={"users"} element={<UserSearchPage />} />
          <Route path={"minijourneys"}>
            <Route path={""} element={<MiniJourneysListPage />} />
            <Route path={":id"} element={<MiniJourneysPage />} />
          </Route>
          <Route path={"competitions"}>
            <Route path={""} element={<CompetitionsListPage />} />
            <Route path={":id"} element={<CompetitionsPage />} />
          </Route>
          <Route path={"force-update"} element={<ForceUpdatePage />} />
          <Route path={"signupoffer"} element={<OfferSignUpListPage />} />
          <Route path={"country-locales"} element={<CountryAndLocalesPage />} />
          <Route path={"birth-stories"} element={<BirthStoriesListPage />} />
          <Route
            path={"daily-doula-operation-center"}
            element={<DailyDoulaOperationCenterPage />}
          />
          <Route path="*" element={<Navigate to="/admin" />} />
        </Route>
      </Routes>
    </Compose>
  </React.Suspense>
);
