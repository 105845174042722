import { collection, doc, setDoc } from "firebase/firestore";
import firebase, { firestore } from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { CountryCodeService } from "./CountryCodeService";
import { uploadImage } from "./serviceUtils";
import { logException } from "../utils/exceptionLogger";

const toolsMenuBannerFileStoragePath = "tools-menu-banner";

export class ToolsMenuBannerService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  toBanner = (
    doc: firebase.firestore.QueryDocumentSnapshot<ToolsBanner>
  ): ToolsBanner => {
    const data = doc.data();

    return {
      id: doc.id,
      ...data,
    };
  };

  create = async (data: Omit<ToolsBanner, ToolsBanner["id"]>) => {
    const newBannerRef = doc(
      collection(firestore, CollectionPaths.ToolsMenuBanner[this.countryCode])
    );
    return await setDoc(newBannerRef, { id: newBannerRef.id, ...data });
  };

  subscribe = (callback: (err: Error | null, banners: ToolsBanner[]) => void) =>
    firestore
      .collection(CollectionPaths.ToolsMenuBanner[this.countryCode])
      .withConverter<ToolsBanner>(null)
      .onSnapshot(
        snapshot => {
          const banners = snapshot.docs.map(this.toBanner);
          callback(null, banners);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  update = (id: ToolsBanner["id"], data: ToolsBanner) =>
    firebase
      .firestore()
      .collection(CollectionPaths.ToolsMenuBanner[this.countryCode])
      .doc(id)
      .update({ ...data });

  deleteBanner = (id: ToolsBanner["id"]) =>
    firebase
      .firestore()
      .collection(CollectionPaths.ToolsMenuBanner[this.countryCode])
      .doc(id)
      .delete();

  uploadBannerImage = async (file: Blob | ArrayBuffer, fileName: string) => {
    const url = await uploadImage(
      file,
      toolsMenuBannerFileStoragePath,
      fileName
    );
    return url;
  };
}
