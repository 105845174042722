import { Button, Modal } from "antd";
import { FaFileUpload } from "react-icons/fa";
import styled from "styled-components";
import { formatFileSize, useCSVReader } from "react-papaparse";
import Text from "antd/lib/typography/Text";

type UploadCSVProps = {
  onUploadAccepted: (results: any) => void;
  isVisible: boolean;
  modalActions: { hide: () => void; show: () => void };
};

export const UploadCSVModal = ({
  onUploadAccepted,
  isVisible,
  modalActions,
}: UploadCSVProps) => {
  const { CSVReader } = useCSVReader();

  //truncate
  const truncate = (str: string, n: number) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <Modal
      visible={isVisible}
      centered
      width={window.screen.width * 0.3}
      onCancel={() => modalActions.hide()}
      footer={null}
    >
      <UploadContainer>
        <CSVReader
          onUploadAccepted={(results: any) => {
            onUploadAccepted(results);
          }}
          config={{ worker: true }}
          nodrag
        >
          {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            getRemoveFileProps,
            Remove,
          }: any) => (
            <>
              <div {...getRootProps()}>
                {acceptedFile ? (
                  <AcceptedFileContainer>
                    <ProgressBarContainer>
                      <ProgressBar />
                    </ProgressBarContainer>
                    <FileContainer>
                      <span>{truncate(acceptedFile.name, 25)}</span>
                      <span>{formatFileSize(acceptedFile.size)}</span>
                      <div {...getRemoveFileProps()}>
                        <Remove color={"red"} {...getRemoveFileProps()} />
                      </div>
                    </FileContainer>
                  </AcceptedFileContainer>
                ) : (
                  <Button
                    size="large"
                    type="primary"
                    style={{
                      display: "flex",
                    }}
                  >
                    <TextStyle>Upload CSV File</TextStyle>
                    <FaFileUpload size={24} />
                  </Button>
                )}
              </div>
            </>
          )}
        </CSVReader>
      </UploadContainer>
    </Modal>
  );
};

const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ProgressBarContainer = styled.div`
  margin-bottom: 10px;
`;

const AcceptedFileContainer = styled.div`
  width: ${window.screen.width * 0.2}px;
  align-items: center;
  justify-content: center;
`;

const FileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextStyle = styled(Text)`
  color: white;
  margin-right: 5px;
`;
