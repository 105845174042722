import { useEffect, useState } from "react";
import { Col, Row, Space, Tabs, Typography } from "antd";
import styled from "styled-components";
import { useChecklists } from "../../context";
import { CheckListType } from "../../utils";
import { ChecklistsSortableList } from "./ChecklistsSortableList";
import { logException } from "../../utils/exceptionLogger";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const { Paragraph } = Typography;

const tabs = [
  {
    title: "Pregnancy",
    type: CheckListType.pregnancy,
  },
  {
    title: "Child 0-1 years",
    type: CheckListType.infant,
  },
  {
    title: "Child 1-2 years",
    type: CheckListType.children,
  },
];

export const ChangeChecklistOrderList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { checklists: allChecklists, onUpdate } = useChecklists();
  const [checklists, setChecklists] = useState<
    Array<
      Checklist & {
        translations: { [key: string]: { key: string; title: string } };
      }
    >
  >(null);

  useEffect(() => {
    const modifiedChecklist = allChecklists
      .map(c => {
        const translations: any = {};

        for (const [key, value] of Object.entries(c.translations)) {
          translations[key] = {
            ...value,
            title: value?.name || "",
            key: value?.name || "",
          };
        }
        return {
          ...c,
          translations,
        };
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
    setChecklists(modifiedChecklist);
    setLoading(false);
  }, [allChecklists]);

  const onUpdateSortOrder = (checklists: Checklist[]) => {
    try {
      checklists.map(
        async c => await onUpdate(c.id, { sortOrder: c.sortOrder })
      );
    } catch (error) {
      logException(error);
      console.log("error while updating checklists", error);
    }
  };

  if (loading) {
    return <Paragraph>Loading...</Paragraph>;
  }

  return (
    <>
      <Spacer size={20} direction="horizontal">
        <BJButton
          size="middle"
          buttonType={ButtonTypes.Primary}
          onClick={() => navigate("/cms/checklists/checklists")}
        >
          <FaArrowLeft />
          &nbsp; Done / Go back
        </BJButton>
      </Spacer>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
          <Tabs type="card" className="custom-offers">
            {tabs.map((tab, index) => (
              <Tabs.TabPane tab={tab.title} key={index}>
                <StyledTabContentContainer>
                  <ChecklistsSortableList
                    initialItems={checklists?.filter(c => c.type === tab.type)}
                    onSave={onUpdateSortOrder}
                  />
                </StyledTabContentContainer>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

const StyledTabContentContainer = styled.div`
  border: 1px solid #f4f4f4;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
`;

const Spacer = styled(Space)`
  ${({ direction, size }) =>
    direction === "horizontal"
      ? `margin-bottom: ${size}px;`
      : `margin-right: ${size}px;`}
`;
