import { Card, Row } from "antd";

import { formatDate, yesOrNo } from "../../../utils";

import { RowItem } from "./RowItem";

export type OtherProps = {
  versionNumber: string;
  buildNumber: number;
  loyaltyEnabled: boolean;
  timeZone: string;
  locale: string;
  signUpDate: {
    _seconds: number;
    _nanoseconds: number;
  };
  isOnboarded: boolean;
  isFirstLogin: boolean;
  uid: string;
  fcm: string;
  followingType?: string;
};

export const OtherInformation = (props: OtherProps) => {
  return (
    <Card title="Other Information" bordered={false}>
      <Row>
        <RowItem
          title="Loyalty enabled:"
          content={yesOrNo(props?.loyaltyEnabled)}
        />
        <RowItem title="Timezone:" content={props.timeZone} />
        <RowItem title="Locale:" content={props.locale} />
      </Row>
      <Row>
        <RowItem title="Sign-up date:" content={formatDate(props.signUpDate)} />
        <RowItem title="Onboarded:" content={yesOrNo(props.isOnboarded)} />
        <RowItem title="First login:" content={yesOrNo(props.isFirstLogin)} />
      </Row>
      <Row>
        <RowItem title="User id:" content={props.uid} />
        <RowItem title="Version:" content={props.versionNumber} />
        <RowItem title="Build:" content={props.buildNumber.toString()} />
      </Row>
    </Card>
  );
};
