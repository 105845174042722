import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, Typography, Form, Switch } from "antd";
import {
  useContentCollection,
  useContentCollections,
  useCountry,
} from "../../context";
import { ContentCollectionsService } from "../../services";
import {
  AspectRatio,
  ContentListType,
  DeepLinkType,
  capitalizeFirstLetter,
  formValidationError,
  isEqualArrays,
} from "../../utils";
import { FormEdit, FormEditType } from "../../components";
import { DropAndCrop } from "../../components/DropAndCrop";
import { commonErrors, contentCollectionPageMessages } from "../../language";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BJInputFormItem } from "../../components/theme";
import { cms } from "../../routes/routeConsts";
import { ContentSelector } from "../Popular/ContentSelector";
import { formatPayload } from "../../helper/recommendedContent";
import { BJFormLabelInfo } from "../../components/theme/atoms/BJInfo";
import { BJSelectFormItemLevel } from "../../components/theme/molecules/formItems/BJFormSelectItemLevel";

type FormValues = {
  imageUrl?: string;
  blurhash?: string;
  translations: {
    [key: string]: {
      title: string;
      description?: string;
    };
  };
  isActive: boolean;
  level: number;
};
const { requiredError } = commonErrors;

export const ContentCollectionPage = () => {
  const navigate = useNavigate();
  const { currentCountry } = useCountry();
  const { id } = useParams<string>();
  const {
    contentCollection,
    loading: loadingContentCollections,
    contentCollectionsService,
  } = useContentCollection(id);
  const { contentCollections } = useContentCollections();
  const [contentCollectionItems, setContentCollectionItems] = useState<
    CommonContentV2[]
  >([]);

  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup
            .string()
            .required(
              `Title (${String(item.key).toUpperCase()}): ${requiredError}`
            ),
        });
        return acc;
      }, {} as any)
    ),
  });

  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm<FormValues>({ resolver: yupResolver(schema) });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (loadingContentCollections || contentCollection === null) {
      return;
    }
    reset({
      ...contentCollection,
      level: contentCollection.level ?? 0,
    });
    setError(null);
  }, [contentCollection, reset, loadingContentCollections]);

  const onSubmit = async (data: FormValues) => {
    const contentItems = contentCollectionItems.map((cc, index, array) =>
      formatPayload(cc, index, array, contentCollections)
    );
    const updateData: ContentCollection = {
      imageUrl: data.imageUrl ?? "",
      blurhash: data.blurhash ?? "",
      translations: data.translations,
      isActive: data.isActive,
      contentMap: contentItems.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {} as { [key: string]: CommonContentV2 }),
      content: contentItems,
      level: data.level ?? 0,
    };

    if (!contentCollectionItems.length) {
      setError(contentCollectionPageMessages.contentRquiredText);
      throw new Error(contentCollectionPageMessages.contentRquiredText);
    }
    if (contentCollection) {
      await contentCollectionsService.update(contentCollection.id, updateData);
    } else {
      const { id } = await contentCollectionsService.create(updateData);
      return navigate(`../${id}`);
    }
  };

  const onRemove = async () => {
    if (contentCollection) {
      await contentCollectionsService.delete(contentCollection.id);
      return navigate(`${cms}/${contentCollections}`);
    }
  };
  const handleContentCollectionImageUrl = (url: string | null) => {
    setValue("imageUrl", url, { shouldDirty: true });
  };

  const handleContentCollectionBlurhash = (url: string | null) => {
    setValue("blurhash", url, { shouldDirty: true });
  };

  const isDirty =
    !!Object.keys(dirtyFields).length ||
    !isEqualArrays(contentCollection?.content ?? [], contentCollectionItems);

  return (
    <FormEdit
      error={error}
      errors={errors as any[]}
      backRoutePath={`${cms}/${contentCollections}/${contentCollections}`}
      onRemove={onRemove}
      hasValidationErrors={Object.keys(errors).length !== 0}
      enableSave={isDirty}
      title={
        contentCollection
          ? contentCollection?.translations
          : "New content collection"
      }
      id={contentCollection?.id}
      editType={contentCollection?.id ? FormEditType.EDIT : FormEditType.ADD}
      loading={loadingContentCollections}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      recordIdentifier={contentCollection?.id}
      deepLink={{
        type: DeepLinkType.ContentCollection,
        id: contentCollection?.id,
        countryCode: currentCountry?.abb,
      }}
      localeSupported
    >
      {locale => (
        <>
          <Row gutter={{ md: 20 }}>
            <Col md={24} lg={12}>
              <BJInputFormItem
                key={`translations.${locale?.key}.title`}
                control={control}
                error={!!errors?.translations?.[locale?.key]?.title?.message}
                label={`Title (${capitalizeFirstLetter(locale?.key)})`}
                message={errors?.translations?.[locale?.key]?.title?.message}
                required={true}
                autoFocus
                fieldName={`translations.${locale?.key}.title`}
              />
              <BJInputFormItem
                key={`translations.${locale?.key}.description`}
                rows={4}
                control={control}
                error={
                  !!errors?.translations?.[locale?.key]?.description?.message
                }
                label={`Description (${capitalizeFirstLetter(locale?.key)})`}
                message={
                  errors?.translations?.[locale?.key]?.description?.message
                }
                fieldName={`translations.${locale?.key}.description`}
              />
            </Col>
            <Col md={24} lg={12}>
              <Form.Item
                label={
                  <BJFormLabelInfo
                    info={
                      "Please upload an image with a 1:1 aspect ratio, preferably 800x800 pixels"
                    }
                    label={"Content collection image"}
                  />
                }
                validateStatus={errors.imageUrl && "error"}
                help={
                  <Typography.Paragraph type="danger">
                    {errors.imageUrl?.message}
                  </Typography.Paragraph>
                }
              >
                <DropAndCrop
                  allowNaturalImageUpload={true}
                  title="Content collection image"
                  initialUrl={contentCollection?.imageUrl}
                  setUploadUrl={handleContentCollectionImageUrl}
                  uploadImage={
                    ContentCollectionsService.uploadContentCollectionImage
                  }
                  lockedRatio={AspectRatio.OneToOne}
                  defaultCropBoxWidth={100}
                  defaultCropBoxHeight={100}
                  setBlurhash={handleContentCollectionBlurhash}
                />
              </Form.Item>
              <Row gutter={{ md: 0 }}>
                <Col lg={5}>
                  <Form.Item label="Published" key="active">
                    <Controller
                      control={control}
                      name="isActive"
                      render={({ field: { onChange, value } }) => (
                        <Switch onChange={onChange} checked={value} />
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col lg={5}>
                  <BJSelectFormItemLevel
                    control={control}
                    error={!!errors?.level}
                    message={errors?.level?.message}
                    fieldName="level"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <ContentSelector
            excludedContentCollectionId={contentCollection?.id}
            headerTitle={"Select content"}
            displayButtons
            setManagedContent={setContentCollectionItems}
            initialContent={contentCollection?.contentMap}
            contentListType={ContentListType.AllContent}
          />
        </>
      )}
    </FormEdit>
  );
};
