import { useState, useEffect, useCallback, useMemo } from "react";
import { FaGripLines } from "react-icons/fa";
import { Button, List, Spin, Space, Row } from "antd";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { PodcastEpisodeService } from "../../services";
import styled from "styled-components";
import { useCountry } from "../../context";

interface Props {
  initialEpisodes: PodcastEpisode[];
  podcastId: FullPodcast["id"];
  onAdd: () => void;
  onSelect: (episode: PodcastEpisode) => void;
}

export const EpisodeList = ({
  initialEpisodes,
  podcastId,
  onAdd: onAddEpisode,
  onSelect,
}: Props) => {
  const [isDirty, setIsDirty] = useState(false);
  const [episodes, setEpisodes] = useState<PodcastEpisode[]>([]);
  const [isOrderMode, setIsOrderMode] = useState(false);
  const [saving, setSaving] = useState(false);

  const { primaryLocale, currentCountry } = useCountry();

  const podcastEpisodeService = useMemo(
    () => new PodcastEpisodeService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  const setSortedEpisodes = useCallback(() => {
    const sortedEpisodes = initialEpisodes
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((t, i) => ({ ...t, sortOrder: i }));
    setEpisodes(sortedEpisodes);
  }, [initialEpisodes]);

  useEffect(() => {
    setSortedEpisodes();
  }, [setSortedEpisodes]);

  const onSave = useCallback(async () => {
    setSaving(true);
    await podcastEpisodeService.updateSortOrders(podcastId, episodes);
    setIsDirty(false);
    setIsOrderMode(false);
    setSaving(false);
  }, [episodes, podcastId]);
  return (
    <>
      {isOrderMode ? (
        <>
          <h6>Change episodes order</h6>
          <Row>
            <Space size="middle">
              <Button
                size="middle"
                onClick={() => {
                  setSortedEpisodes();
                  setIsOrderMode(false);
                }}
                disabled={saving}
              >
                Cancel
              </Button>
              {isDirty ? (
                <Button type="primary" size="middle" onClick={onSave}>
                  {saving ? <Spin size="small" /> : "Save"}
                </Button>
              ) : (
                <Button disabled size="middle">
                  No changes
                </Button>
              )}
            </Space>
          </Row>
          <br />

          <SortableList
            items={episodes.sort((a, b) => a.sortOrder - b.sortOrder)}
            onSortEnd={({ oldIndex, newIndex }) => {
              if (oldIndex === newIndex) {
                return;
              }
              const _episodes = [...episodes];

              const sortOrder = _episodes[oldIndex].sortOrder;
              _episodes[oldIndex].sortOrder = _episodes[newIndex].sortOrder;
              _episodes[newIndex].sortOrder = sortOrder;

              setEpisodes(_episodes);
              setIsDirty(true);
            }}
          />
        </>
      ) : (
        <>
          <div>
            <h6>Episodes</h6>
            <div>
              <Space size="middle">
                <Button size="middle" type="primary" onClick={onAddEpisode}>
                  Add
                </Button>
                {episodes && (
                  <Button size="middle" onClick={() => setIsOrderMode(true)}>
                    Change order
                  </Button>
                )}
              </Space>
            </div>
            <br />
          </div>
          <ListBorder>
            <List>
              {episodes.map(episode => (
                <StyledListItem
                  onClick={() => onSelect(episode)}
                  key={episode.id}
                >
                  {episode?.translations[primaryLocale?.key]?.name}
                </StyledListItem>
              ))}
            </List>
          </ListBorder>
        </>
      )}
    </>
  );
};

const SortableItem = SortableElement(({ value }: { value: PodcastEpisode }) => {
  const { primaryLocale } = useCountry();
  return (
    <List.Item>
      <span>{value?.translations[primaryLocale?.key]?.name}</span>
      <FaGripLines />
    </List.Item>
  );
});

const SortableList = SortableContainer(
  ({ items }: { items: PodcastEpisode[] }) => (
    <ListBorder>
      <List>
        {items.map((item, index) => (
          <SortableItem key={item.id} index={index} value={item} />
        ))}
      </List>
    </ListBorder>
  )
);
const ListBorder = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`;

const StyledListItem = styled(List.Item)`
  cursor: pointer;
`;
