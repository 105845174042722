import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppTakeOvers } from "../../context";
import { Sorter } from "../../components/theme/util/sorter";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import BJList from "../../components/theme/components/BJList";
import { changeorder, newRoute } from "../../routes";
import { DatePicker, Switch } from "antd";
import { isCurrentTimeWithinRange } from "../../utils/timeUtils";
import { useCountry } from "../../context";

type datasourceType = AppTakeOver & { isVisible?: boolean };

export const AppTakeOverListPage = () => {
  const navigate = useNavigate();
  const { appTakeOvers, loading } = useAppTakeOvers();
  const [originalTable, setOriginalTable] = useState<datasourceType[]>([]);
  const { primaryLocale } = useCountry();

  useEffect(() => {
    const appTakeOverTableSource: datasourceType[] = appTakeOvers.map(
      appTakeOver => {
        return {
          ...appTakeOver,
          title: appTakeOver?.translations?.[primaryLocale.key]?.title,
          key: appTakeOver?.id,
          isVisible: isAppTakeOverVisibleInMobile(appTakeOver),
        };
      }
    );

    setOriginalTable(appTakeOverTableSource);
  }, [appTakeOvers, primaryLocale]);

  const isAppTakeOverVisibleInMobile = (appTakeOver: AppTakeOver) => {
    return (
      appTakeOver.isActive &&
      isCurrentTimeWithinRange(appTakeOver.startTime, appTakeOver.endTime)
    );
  };

  const handleNewOffer = () => {
    navigate(newRoute);
  };

  const onClickRow = (record: datasourceType) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const columns: BJColumnType<datasourceType>[] = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 1,
      ellipsis: true,
      onFilter: (value, record) =>
        record.translations?.[primaryLocale.key]?.title.includes(
          value as string
        ),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      width: 0.5,
      ellipsis: true,
      onFilter: (value, record) => record.startTime.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (text, record) => {
        return (
          <>
            {record.startTime ? (
              <DatePicker
                disabled={true}
                format={"YYYY-MM-DD HH:mm"}
                showTime
                value={record.startTime}
              />
            ) : (
              <span style={{ justifySelf: "center" }}>Time not selected</span>
            )}
          </>
        );
      },
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      width: 0.5,
      ellipsis: true,
      onFilter: (value, record) => record.endTime.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (text, record) => {
        return (
          <>
            {record.endTime ? (
              <DatePicker
                disabled={true}
                format={"YYYY-MM-DD HH:mm"}
                showTime
                value={record.endTime}
              />
            ) : (
              <span style={{ justifySelf: "center" }}>Time not selected</span>
            )}
          </>
        );
      },
    },
    {
      title: "CTA Deep link",
      dataIndex: "deepLink",
      key: "deepLink",
      width: 1,
      ellipsis: true,
      onFilter: (value, record) => record.deepLink.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Visible in mobile",
      dataIndex: "isVisible",
      key: "isVisible",
      width: 0.5,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (value: boolean) => (
        <Switch checked={value} size="small" disabled />
      ),
    },
  ];

  return (
    <BJList
      onChangeOrder={() => {
        navigate(changeorder);
      }}
      loading={loading}
      filterOnlyDisplayList
      addButtonCaption={"New AppTakeOver"}
      title={"AppTakeOver"}
      OriginalList={originalTable}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNewOffer}
      recordCountSuffix={"AppTakeOvers"}
    />
  );
};
