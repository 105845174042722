import { Button, Col, List, Row } from "antd";
import { BiDownArrow, BiUpArrow } from "react-icons/bi";
import styled from "styled-components";

import { useCountry } from "../../context";
import { useToolsBanner } from "../../hooks/useToolsBanner";
import { useState } from "react";
import { AddNewToolsBannerModal } from "./AddNewToolsBannerModal";

export const ToolsBannerListPage = () => {
  const [selectedBannerId, setSelectedBannerId] = useState<
    ToolsBanner["id"] | null
  >(null);
  const { banners, updateBanner, deleteBanner } = useToolsBanner();
  const { primaryLocale } = useCountry();

  const [showAddBannerModal, setShowAddBannerModal] = useState<boolean>(false);

  const onHandleAddBanner = () => {
    setShowAddBannerModal(true);
  };

  const onHandleRemove = (id: ToolsBanner["id"]) => {
    deleteBanner(id);
  };

  const onHandleUp = (id: ToolsBanner["id"]) => {
    const banner = banners.find(b => b.id === id);
    const prevBanner = banners.find(b => b.sortOrder === banner.sortOrder - 1);
    if (!prevBanner) return;
    prevBanner.sortOrder = prevBanner.sortOrder + 1;
    banner.sortOrder = banner.sortOrder - 1;
    updateBanner(id, banner);
    updateBanner(prevBanner.id, prevBanner);
  };

  const onHandleDown = (id: ToolsBanner["id"]) => {
    const banner = banners.find(b => b.id === id);
    const nextBanner = banners.find(b => b.sortOrder === banner.sortOrder + 1);
    if (!nextBanner) return;
    nextBanner.sortOrder = nextBanner.sortOrder - 1;
    banner.sortOrder = banner.sortOrder + 1;
    updateBanner(id, banner);
    updateBanner(nextBanner.id, nextBanner);
  };

  return (
    <>
      <AddNewToolsBannerModal
        bannerId={selectedBannerId}
        banners={banners}
        show={showAddBannerModal || !!selectedBannerId}
        onHide={() => {
          setShowAddBannerModal(false);
          setSelectedBannerId(null);
        }}
        onAdd={() => {
          setShowAddBannerModal(false);
        }}
      />
      <Row gutter={[16, 16]}>
        <StyledRow justify="space-between" align="middle">
          <h3>Tools Banners</h3>
          <Button type="primary" onClick={onHandleAddBanner}>
            Add Banner
          </Button>
        </StyledRow>
        <Col span={24}>
          <List>
            {banners?.map((banner, idx) => (
              <List.Item key={banner.id}>
                <ImageWrapper onClick={() => setSelectedBannerId(banner.id)}>
                  <Image
                    src={banner.translations?.[primaryLocale.key]?.imageUrl}
                  />
                </ImageWrapper>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ width: 120 }}
                >
                  <Button onClick={() => onHandleRemove(banner.id)}>
                    Remove
                  </Button>
                  <StyledFlexCol>
                    <Button
                      disabled={idx == 0}
                      icon={<BiUpArrow />}
                      type="text"
                      onClick={() => onHandleUp(banner.id)}
                    />
                    <Button
                      disabled={idx == banners.length - 1}
                      icon={<BiDownArrow />}
                      type="text"
                      onClick={() => onHandleDown(banner.id)}
                    />
                  </StyledFlexCol>
                </Row>
              </List.Item>
            ))}
          </List>
        </Col>
      </Row>
    </>
  );
};

const StyledRow = styled(Row)`
  flex: 1;
`;

const StyledFlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageWrapper = styled.a`
  border: 1px dashed #ccc;
`;

const Image = styled.img`
  width: 468x;
  height: 120px;
  object-fit: stretch;
  padding: 10px;
`;
