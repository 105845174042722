import { useEffect, useState } from "react";
import { useSponsors } from "../../../context";
import { SponsorModal } from "./SponsorModal";
import { Sorter } from "../../../components/theme/util/sorter";
import { BJColumnsType } from "../../../components/theme/molecules/BJTable";
import BJList from "../../../components/theme/components/BJList";
import { useCountry } from "../../../context/CountryContext";

export const SponsorList = () => {
  const { sponsors } = useSponsors();
  const { currentCountry } = useCountry();

  const [showAddSponsorModal, setShowAddSponsorModal] = useState(false);
  const [selectedSponsor, setSelectedSponsor] = useState<Sponsor | null>(null);
  const [originalTable, setOriginalTable] = useState<Sponsor[]>([]);

  useEffect(() => {
    setOriginalTable(sponsors);
  }, [sponsors]);

  const onClickRow = (record: Sponsor) => {
    return {
      onClick: () => {
        setSelectedSponsor(record);
      },
    };
  };

  const columns: BJColumnsType<Sponsor> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 2,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  return (
    <>
      <BJList
        key={`sponsorList-${currentCountry.abb}`}
        addButtonCaption={"New Sponsor"}
        title={"Sponsors"}
        OriginalList={originalTable}
        columns={columns}
        recordCountSuffix={"Sponsors"}
        onClickRow={onClickRow}
        onclick={() => {
          setShowAddSponsorModal(true);
        }}
      />
      <SponsorModal
        sponsor={selectedSponsor}
        show={showAddSponsorModal || selectedSponsor !== null}
        onHide={() => {
          setSelectedSponsor(null);
          setShowAddSponsorModal(false);
        }}
      />
    </>
  );
};
