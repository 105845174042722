import { useCallback, useEffect, useMemo, useState } from "react";
import { BlogPostService, scrapeData } from "../services";
import { scraperMessages } from "../utils";
import { getUrlInformation } from "../utils/getUrlInformation";
import { useCountry } from "../context";

export type scrapped = {
  imageUrl: string;
  title: string;
  description: string;
};
const useScraper = () => {
  const [scrapeLoading, setScrapeLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [scrapped, setScrapped] = useState<scrapped>();
  const [scrapeUrl, setScrapeUrl] = useState<string>(null);
  const { currentCountry } = useCountry();

  const blogpostService = useMemo(
    () =>
      currentCountry?.abb ? new BlogPostService(currentCountry.abb) : null,
    [currentCountry?.abb]
  );

  const toScrapped = (data: scrapped) => {
    if (data.title === scraperMessages.limitOver) {
      setError({ message: data.title } as Error);
    } else if (data.title === scraperMessages.privateAccount) {
      setError({ message: data.title } as Error);
    } else {
      if (data) {
        setScrapped(data);
      }
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setError(null);
      setScrapeLoading(true);
      toScrapped(
        getUrlInformation(scrapeUrl).hostname.includes("youtube")
          ? await blogpostService.scrapeYoutubeVideo(scrapeUrl)
          : await scrapeData(scrapeUrl)
      );
    } catch (error) {
      console.log(error);
      setError(error as Error);
      setScrapped(null);
    } finally {
      setScrapeLoading(false);
    }
  }, [blogpostService, scrapeUrl]);

  useEffect(() => {
    if (scrapeUrl?.length > 0) {
      fetchData();
    }
  }, [fetchData, scrapeUrl]);

  return {
    scrapped,
    scrapeLoading,
    error,
    setScrapeUrl,
  };
};

export default useScraper;
