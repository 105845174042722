import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";

export class PopularService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static toPopular = (doc: any): Popular => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      type: data?.type || (data.month == null ? "week" : "month"),
    };
  };

  subscribe = (callback: (err: Error | null, popularData: Popular[]) => void) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Popular[this.countryCode])
      .onSnapshot(
        snapshot => {
          const _popularData = snapshot.docs.map(PopularService.toPopular);
          callback(null, _popularData);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );

  create = (data: Partial<Popular>) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Popular[this.countryCode])
      .add(data);

  update = (id: Popular["id"], data: Partial<Popular>) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Popular[this.countryCode])
      .doc(id)
      .update({ ...data });

  delete = (id: Popular["id"]) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Popular[this.countryCode])
      .doc(id)
      .delete();
}
