import type { DocumentData } from "firebase/firestore";
import { firebaseCollectionNames } from "./../utils/consts";
import { firestore } from "../firebase";
const contentCollection = firebaseCollectionNames.content.content;
const SUBCOLLECTION = firebaseCollectionNames.content.editorContent;

const toContentList = (doc: DocumentData): ContentItemType => {
  const data = doc.data();
  return {
    id: doc.id,
    ...data,
  };
};

const subscribe = (
  callback: (err: Error | null, contentList: ContentItemType[]) => void
) =>
  firestore.collection(contentCollection).onSnapshot(
    snapshot => {
      const contentList = snapshot.docs.map(toContentList);
      callback(null, contentList);
    },
    err => {
      console.log(err);
      callback(err, []);
    }
  );

const update = async (
  id: string,
  language: string,
  data: UpdateContentList
) => {
  return getSubContentRef(id)
    .doc(language)
    .set({ ...data }, { merge: true });
};

const getSubContentRef = (id: string) =>
  firestore.collection(contentCollection).doc(id).collection(SUBCOLLECTION);

const getEditorContent = (id: string, language: string) =>
  getSubContentRef(id).doc(language).get();

const addNewContent = async (name: string) => {
  const contentDocRef = firestore.collection(contentCollection).doc();

  contentDocRef.set({
    originalName: name,
    createdAt: new Date(),
  });

  return contentDocRef.id;
};

const deleteContent = (id: string) =>
  firestore.collection(contentCollection).doc(id).delete();

const flagDeleteContent = (id: string, deleted = true) =>
  firestore.collection(contentCollection).doc(id).set(
    {
      deleted,
    },
    {
      merge: true,
    }
  );

export const ContentService = {
  subscribe,
  update,
  getContentRef: getSubContentRef,
  getEditorContent,
  addNewContent,
  deleteContent,
  flagDeleteContent,
};

export type UpdateContentList = {
  name: string;
  contentText: string;
  version: string;
  time: number;
};
