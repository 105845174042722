import { useEffect, useState } from "react";
import { useDietaryAdvices } from "../../../context";
import { CenteredSpinner } from "../../../components";
import BJList from "../../../components/theme/components/BJList";
import { BJColumnType } from "../../../components/theme/molecules/BJTable";
import { Sorter } from "../../../components/theme/util/sorter";
import { CategoryModal } from "./CategoryModal";
import { useCountry } from "../../../context/CountryContext";
import { capitalizeFirstLetter } from "../../../utils";

export const DietaryCategoryList = () => {
  const { categories, loading } = useDietaryAdvices();
  const { currentCountry } = useCountry();

  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] =
    useState<DietaryAdviceCategory | null>(null);

  const [originalTable, setOriginalTable] = useState<DietaryAdviceCategory[]>(
    []
  );

  useEffect(() => {
    const dietaryCategoryTableSource: DietaryAdviceCategory[] = categories.map(
      category => ({
        ...category,
        ...Object.entries(category.translations).reduce(
          (acc, [locale, values]) => (
            (acc[`title${capitalizeFirstLetter(locale)}`] = values.title), acc
          ),
          {} as any
        ),
      })
    );
    setOriginalTable(dietaryCategoryTableSource);
  }, [categories]);

  const onClickRow = (record: DietaryAdviceCategory) => {
    return {
      onClick: () => {
        setSelectedCategory(record);
      },
    };
  };

  const handleNewDietary = () => {
    setShowAddCategoryModal(true);
  };

  const columns: BJColumnType<DietaryAdviceCategory>[] = [
    ...currentCountry?.locales?.map(locale => ({
      title: `Name (${locale?.label})`,
      dataIndex: `title${capitalizeFirstLetter(locale?.key)}`,
      key: `title${capitalizeFirstLetter(locale?.key)}`,
      width: 1,
      ellipsis: true,
      onFilter: (
        value: string | number | boolean,
        record: DietaryAdviceCategory
      ) => record.translations?.[locale.key]?.title.includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    })),
  ];
  useEffect(() => {
    if (selectedCategory) {
      setShowAddCategoryModal(true);
    }
  }, [selectedCategory]);
  if (loading) return <CenteredSpinner />;

  return (
    <>
      <CategoryModal
        category={selectedCategory}
        show={showAddCategoryModal}
        onHide={() => {
          setSelectedCategory(null);
          setShowAddCategoryModal(false);
        }}
      />
      <BJList
        filterOnlyDisplayList
        addButtonCaption={"New Category"}
        title={"Categories"}
        OriginalList={originalTable}
        columns={columns}
        onClickRow={onClickRow}
        onclick={handleNewDietary}
        recordCountSuffix={"Categories"}
      />
    </>
  );
};
