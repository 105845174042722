import { v4 as uuid } from "uuid";

import { firestore } from "../firebase";
import { logException } from "../utils/exceptionLogger";
import { uploadImage } from "./serviceUtils";

const docRef = firestore.collection("missions").doc("content");
const missionsFileStoragePath = "missions";

const subscribe = (callback: (error: Error | null, data: Mission[]) => void) =>
  docRef.onSnapshot(
    snapshot => callback(null, snapshot.data()?.content ?? []),
    error => {
      logException(error);
      callback(error, []);
    }
  );

const create = async (mission: Omit<Mission, "id">) => {
  const content = ((await docRef.get()).data()?.content ?? []) as Mission[];

  content.push({
    id: uuid(),
    ...mission,
  });

  return docRef.update({
    content,
  });
};

const update = async (
  updatedMission: Pick<Mission, "id"> & Partial<Mission>
) => {
  const content = ((await docRef.get()).data()?.content ?? []) as Mission[];

  const missionToUpdateIndex = content.findIndex(
    mission => mission.id === updatedMission.id
  );

  if (missionToUpdateIndex >= 0) {
    content[missionToUpdateIndex] = {
      ...content[missionToUpdateIndex],
      ...updatedMission,
    };
  }

  return docRef.update({
    content,
  });
};

const deleteMission = async (missionId: Mission["id"]) => {
  const content = ((await docRef.get()).data()?.content ?? []) as Mission[];

  const missionToDeleteIndex = content.findIndex(
    mission => mission.id === missionId
  );

  if (missionToDeleteIndex >= 0) {
    content.splice(missionToDeleteIndex, 1);
  }

  return docRef.update({
    content,
  });
};

const uploadMissionImage = async (
  file: Blob | ArrayBuffer,
  fileName: string
) => {
  const url = uploadImage(file, missionsFileStoragePath, fileName);
  return url;
};

const updateSortOrder = (missions: Mission[]) => {
  return docRef.update({
    content: missions,
  });
};

const getSubledgerOptions = async () => {
  const doc = await firestore.collection("search").doc("subledgers").get();
  return (doc.data().content ?? []) as string[];
};

export const MissionsService = {
  subscribe,
  create,
  update,
  delete: deleteMission,
  uploadMissionImage,
  updateSortOrder,
  getSubledgerOptions,
};
