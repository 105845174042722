import { Avatar, List } from "antd";
import React, { FC } from "react";
import styled, { useTheme } from "styled-components";
import { BJSortableList } from "./BJSortableList";
import { FaTimesCircle } from "react-icons/fa";
import { arrayMove } from "../../../utils/moveArrayItems";

type SortProps = {
  oldIndex: number;
  newIndex: number;
};

const BJFeaturedList: FC<BJFeaturedListProps> = ({
  changeFeaturedListOrder,
  featuredItems,
  removeFeaturedItem,
  setfeaturedItems,
}) => {
  const theme = useTheme();
  const onSortEnd = ({ oldIndex, newIndex }: SortProps) => {
    if (oldIndex === newIndex) {
      return;
    }
    const swappedArray = arrayMove([...featuredItems], oldIndex, newIndex);
    setfeaturedItems(swappedArray);
  };

  return (
    <ListWrapper>
      {changeFeaturedListOrder ? (
        <BJSortableList items={featuredItems} onSortEnd={onSortEnd} />
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={featuredItems}
          renderItem={(item: any, index) => (
            <List.Item
              actions={[
                <FaTimesCircle
                  key={`bjfl${index}`}
                  onClick={() => removeFeaturedItem(item.id)}
                  color={theme.red}
                  size={22}
                  cursor="pointer"
                />,
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar src={item.imageUrl} />}
                title={item.title}
                description={item.description}
              />
            </List.Item>
          )}
        />
      )}
    </ListWrapper>
  );
};

interface BJFeaturedListProps {
  changeFeaturedListOrder: boolean;
  featuredItems: FeaturedItem[];
  removeFeaturedItem?: (id: string) => void;
  setfeaturedItems: (items: FeaturedItem[]) => void;
}

const ListWrapper = styled(List)`
  height: 22rem;
  overflow: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export default BJFeaturedList;
