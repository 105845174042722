import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { CountryCodeService } from "./CountryCodeService";
export interface BlogCategory {
  id?: string;
  translations: {
    [locale: string]: string;
  };
  promoted: boolean;
}

class BlogCategoriesService extends CountryCodeService {
  constructor(locale: string) {
    super(locale);
  }

  create(data: BlogCategory) {
    return firebase
      .firestore()
      .collection(CollectionPaths.BlogCategories[this.countryCode])
      .add({ ...data });
  }

  subscribe(callback: (err: Error | null, categories: any) => void) {
    return firebase
      .firestore()
      .collection(CollectionPaths.BlogCategories[this.countryCode])
      .onSnapshot(
        snapshot => {
          const categories = snapshot.docs.map(doc => this.toBlogCategory(doc));

          callback(null, categories);
        },
        err => {
          console.log(err);
          callback(err, []);
        }
      );
  }

  update(data: BlogCategory) {
    return firebase
      .firestore()
      .collection(CollectionPaths.BlogCategories[this.countryCode])

      .doc(data.id)
      .update({ ...data });
  }

  delete(id: string) {
    return firebase
      .firestore()
      .collection(CollectionPaths.BlogCategories[this.countryCode])
      .doc(id)
      .delete();
  }

  private toBlogCategory(doc: any): BlogCategory {
    const data = doc.data();
    return {
      id: doc.id,
      translations: data.translations,
      promoted: data.promoted,
    };
  }
}

export default BlogCategoriesService;
