import { useEffect, useMemo, useState } from "react";
import { Form, Row, Col, Space, Avatar, Typography } from "antd";
import { useForm } from "react-hook-form";
import { useArticles } from "../../context";
import { ArticleService } from "../../services";
import { Select } from "antd";
import { FormEdit, FormEditType } from "../../components/FormEdit";
import { CenteredSpinner } from "../../components";
import styled from "styled-components";
import { BJSortableList } from "../../components/theme/molecules/BJSortableList";
import { arrayMove } from "../../utils/moveArrayItems";
import { useCountry } from "../../context";
import { FaGripLines } from "react-icons/fa";

const { Paragraph } = Typography;
interface Props {
  onCancel?: () => void;
}

type SortProps = {
  oldIndex: number;
  newIndex: number;
};

export const ChangeOrderList = (_: Props) => {
  const {
    articles: allArticles,
    categories,
    loading: articlesLoading,
  } = useArticles();
  const { handleSubmit } = useForm();
  const [isDirty, setIsDirty] = useState(false);
  const [articles, setArticles] = useState<ArticleV2[]>([]);
  const [category, setCategory] = useState<ArticleCategory | null>(null);
  const { currentCountry, primaryLocale } = useCountry();

  useEffect(() => {
    if (categories.length > 0) {
      setCategory(categories[0]);
    }
  }, [categories]);

  useEffect(() => {
    setArticles(
      allArticles
        .filter(article => article.categoryId === category?.id)
        .sort((a: any, b: any) => a.sortOrder - b.sortOrder)
        .map((article, index) => ({ ...article, sortOrder: index }))
    );
  }, [allArticles, category]);

  const articlesService = useMemo(
    () => (currentCountry?.abb ? new ArticleService(currentCountry.abb) : null),
    [currentCountry?.abb]
  );

  const saveOrder = async () => {
    await articlesService.updateSortOrders(
      articles.map((item, index) => ({ ...item, sortOrder: index }))
    );
    setIsDirty(false);
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortProps) => {
    if (oldIndex === newIndex) {
      return;
    }
    const sortableArticles = arrayMove([...articles], oldIndex, newIndex);
    setArticles(sortableArticles);
    setIsDirty(true);
  };

  if (articlesLoading) return <CenteredSpinner />;

  return (
    <FormEdit
      enableSave={isDirty}
      title={"Change articles order"}
      editType={FormEditType.VIEW}
      loading={articlesLoading}
      onSubmit={handleSubmit(saveOrder)}
    >
      <Row>
        <Col>
          <Form.Item className="d-inline-block">
            <StyledSelect
              style={{ width: "12rem" }}
              defaultValue={
                categories.length > 0
                  ? categories[0].translations[primaryLocale.key].title
                  : ""
              }
              size="large"
              onChange={e => {
                const category = categories.find(c => c.id === e);
                if (category) {
                  setCategory(category);
                }
              }}
            >
              {categories.map(category => (
                <option key={category.id} value={category.id}>
                  {category.translations[primaryLocale.key].title}
                </option>
              ))}
            </StyledSelect>
          </Form.Item>
        </Col>
      </Row>
      <BJSortableList
        items={articles}
        onSortEnd={onSortEnd}
        render={value => {
          return (
            <StyledListItem key={value.id}>
              <FaGripLines />
              {!value?.translations[primaryLocale.key].imageUrl && (
                <Avatar src={value?.translations[primaryLocale.key].imageUrl} />
              )}
              <Paragraph>
                {value.translations[primaryLocale.key].title || ""}
              </Paragraph>
            </StyledListItem>
          );
        }}
      />
    </FormEdit>
  );
};

const StyledSelect = styled(Select)`
  width: 2rem;
`;

const StyledListItem = styled(Space)`
  cursor: move;
  z-index: 1;
  margin-bottom: 1rem;
  width: 100%;
`;
