export const monthContentLimit = 5;
export const weekContentLimit = 4;

export const isCuratedContentLimitExceeded = (
  contentType: "week" | "month",
  noOfContent: number
) => {
  const isContentLimitExceeded =
    contentType === "week"
      ? noOfContent > monthContentLimit
      : noOfContent > weekContentLimit;
  return isContentLimitExceeded;
};
