import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Checkbox, Col, Form, Radio, Row, Typography } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  AspectRatio,
  BlogTypes,
  formValidationError,
  ContentType,
  ContentTypeNames,
} from "../../utils";
import {
  useBirthStories,
  useBlogCategories,
  useInfluencers,
  useGeneralBlogPosts,
  useCountry,
  useChildBlogPosts,
  useBlogPosts,
} from "../../context";
import {
  AiTranslator,
  CheckboxButton,
  FormEdit,
  FormEditType,
} from "../../components";
import { usePost, usePostList } from "../../hooks";
import { commonErrors } from "../../language";
import { b64toBlob, onToggleCategory } from "./helpers";
import { Scraper } from "../../components/Scraper";
import { DropAndCrop } from "../../components/DropAndCrop";
import { getMonths, getWeeks } from "../../utils/timeUtils";
import {
  BJInputFormItem,
  BJSelectFormItem,
  BJSwitchFormItem,
  BJTags,
  BJTagsFormItem,
} from "../../components/theme";
import { getInstaPostIdFromUrl } from "../../utils/getUrlInformation";
import { uploadBlogPostImage } from "../../utils/blogpost/blogPostImage";

const {
  urlValidationError: urlError,
  requiredError,
  urlShouldHttps,
} = commonErrors;

export enum Featured {
  None = 0,
  First,
  Second,
  Third,
  Fourth,
  Fifth,
}

const types = [
  {
    value: BlogTypes.BlogPost,
    display: "Pregnancy (Week)",
  },
  {
    value: BlogTypes.ChildBlogPost,
    display: "Child (Month)",
  },
  {
    value: BlogTypes.BirthStory,
    display: "Birth story",
  },
  {
    value: BlogTypes.GeneralBlogPost,
    display: "Blog posts (General)",
  },
];

export type PostFormValues = {
  influencerId?: string;
  url?: string;
  imageUrl?: string;
  blurhash?: string;
  week?: number;
  month?: number;
  featured?: Featured;
  promoted?: boolean;
  categoryIds?: string[];
  type?: string;
  authorName?: string;
  hideSponsorLogo?: boolean;
  translations?: {
    [locale: string]: {
      title: string;
      description: string;
      tagWords: string[];
    };
  };
  isVlog?: boolean;
};

const urlRegExp = new RegExp(
  /^(https:\/\/)[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/
);

export const PostPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const { weeks } = getWeeks();
  const { months } = getMonths();
  const { influencers } = useInfluencers();
  const { post, loading } = usePost(id);
  const { posts } = usePostList();

  const { addOrUpdateGeneralBlogPost, deleteGeneralBlogPost } =
    useGeneralBlogPosts();
  const { writeChildBlogPost, deleteChildBlogPost } = useChildBlogPosts();
  const { writeBirthStory, deleteBirthStory } = useBirthStories();
  const { deleteBlogPost, writeBlogPost } = useBlogPosts();

  const { blogCategories } = useBlogCategories();
  const a = useLocation().search;
  const queryString = useMemo(() => new URLSearchParams(a), [a]);
  const { primaryLocale, currentCountry } = useCountry();

  const [tags, setTags] = useState<string[]>([]);
  const [categoryToggle, setCategoryToggle] = useState(false);
  const [scrapped, setScrapped] = useState(false);

  const mapQueryParam = (paramVal: string) => {
    if (paramVal === "pregnancy") {
      return BlogTypes.BlogPost;
    } else if (paramVal === "birth") {
      return BlogTypes.BirthStory;
    } else if (paramVal === "child") {
      return BlogTypes.ChildBlogPost;
    } else if (paramVal === "general") {
      return BlogTypes.GeneralBlogPost;
    }
  };

  const blogType = post?.type ?? mapQueryParam(queryString.get("type"));

  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          title: yup
            .string()
            .required(
              `Title (${String(item.key).toUpperCase()}): ${requiredError}`
            ),
          description: yup
            .string()
            .required(
              `Description (${String(
                item.key
              ).toUpperCase()}): ${requiredError}`
            ),
        });
        return acc;
      }, {} as any)
    ),
    url: yup
      .string()
      .nullable()
      .url(`Link to post: ${urlError}`)
      .required(`Link to post: ${requiredError}`)
      .matches(urlRegExp, urlShouldHttps),
    featured: yup.string().required(`Featured: ${requiredError}`),
    type: yup.string().required(`Type: ${requiredError}`),
    month: yup.number().when("type", {
      is: BlogTypes.ChildBlogPost,
      then: yup.number().required(`Month: ${requiredError}`),
      otherwise: yup.number().nullable(),
    }),
    week: yup.number().when("type", {
      is: BlogTypes.BlogPost,
      then: yup.number().required(`Week: ${requiredError}`),
      otherwise: yup.number().nullable(),
    }),
  });

  const {
    formState,
    handleSubmit,
    watch,
    getValues,
    reset,
    control,
    formState: { errors },
    setValue,
    register,
  } = useForm<PostFormValues>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (loading) {
      return;
    }
    reset({
      ...post,
    });
    setCategoryToggle(post?.categories?.length > 0);
    setScrapped(false);
  }, [post, loading, influencers, reset, register]);

  useEffect(() => {
    setValue("type", blogType, { shouldValidate: true });
  }, [blogType, post, queryString, setValue]);

  const onRemove = async () => {
    if (post === null) {
      throw new Error("Record not found");
    }

    switch (post?.type) {
      case BlogTypes.BlogPost:
        await deleteBlogPost(post?.id);
        break;
      case BlogTypes.ChildBlogPost:
        await deleteChildBlogPost(post?.id);
        break;
      case BlogTypes.BirthStory:
        await deleteBirthStory(post?.id);
        break;
      case BlogTypes.GeneralBlogPost:
        await deleteGeneralBlogPost(post?.id);
        break;
    }
    return navigate("./..", { replace: true });
  };

  const contentByQueryParam = (paramVal: string) => {
    if (paramVal === "pregnancy") {
      return ContentType.Pregnancy;
    } else if (paramVal === "birth") {
      return ContentType.Birth;
    } else if (paramVal === "child") {
      return ContentType.Child;
    } else if (paramVal === "general") {
      return ContentType.General;
    }
  };

  const isInstagram = (url: string) => url.includes("www.instagram.com");

  const uploadInstagramImage = async (url: string, imageUrl: string) => {
    if (imageUrl) {
      const cleanedImageUrl = imageUrl.replace(/\s/g, "");

      if (/^data:image\/\w+;base64,/.test(cleanedImageUrl)) {
        const file = b64toBlob(cleanedImageUrl.split(",")[1]);
        if (file) {
          const uploadedUrl = await uploadBlogPostImage(
            file,
            `instaImg${getInstaPostIdFromUrl(url)}`
          );
          return uploadedUrl;
        } else {
          throw new Error("Something went wrong, please scrape the URL again");
        }
      } else {
        throw new Error("Invalid Base64 encoding in imageUrl");
      }
    } else {
      throw new Error("Image URL is null or undefined");
    }
  };

  const onSubmit: SubmitHandler<PostFormValues> = async data => {
    const {
      influencerId,
      url,
      imageUrl,
      week,
      month,
      featured,
      promoted,
      categoryIds,
      blurhash,
      isVlog,
    } = data;
    try {
      const translations: BlogPost["translations"] = {};
      for (const [key, value] of Object.entries(data.translations)) {
        translations[key] = {
          title: value.title?.trim(),
          description: value.description?.trim(),
          tagWords: value.tagWords,
        };
      }

      const updatedUrl =
        isInstagram(url) && scrapped
          ? await uploadInstagramImage(url, imageUrl)
          : imageUrl;

      const mapped: BlogPost = {
        id: post?.id,
        influencerId,
        url,
        imageUrl: updatedUrl,
        blurhash,
        featured: Number(featured),
        promoted: promoted ?? false,
        categories: categoryIds,
        translations,
        isVlog,
      };

      let createdPostId: string | number;
      switch (post?.type || data.type) {
        case BlogTypes.BlogPost:
          const mappedBlog = { ...mapped, week: Number(week) };
          createdPostId = await writeBlogPost(mappedBlog);
          break;
        case BlogTypes.ChildBlogPost:
          const mappedChild = { ...mapped, month: Number(month) };
          createdPostId = await writeChildBlogPost(mappedChild);
          break;
        case BlogTypes.BirthStory:
          createdPostId = await writeBirthStory(mapped);
          break;
        case BlogTypes.GeneralBlogPost:
          const mappedGeneral = {
            ...mapped,
            influencerId: categoryIds?.length > 0 ? null : influencerId,
            categoryIds: categoryIds,
            authorName: categoryIds?.length > 0 ? data.authorName : null,
          };
          createdPostId = await addOrUpdateGeneralBlogPost(mappedGeneral);
          break;
        default:
          break;
      }
      setScrapped(false);
      if (createdPostId) {
        return navigate(`./../${createdPostId}`);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const { week, month, imageUrl, categoryIds, type, translations } = watch();

  const featureValidator = useMemo(() => {
    return post?.type === BlogTypes.BlogPost
      ? posts.filter(
          post =>
            post?.featured &&
            post?.featured !== 0 &&
            post?.type === BlogTypes.BlogPost &&
            post?.week === Number(week)
        )
      : posts.filter(
          post =>
            post?.featured &&
            post?.featured !== 0 &&
            post?.type === BlogTypes.ChildBlogPost &&
            post?.month === Number(month)
        );
  }, [posts, week, post?.type, month]);

  const handleUploadedImageUrl = (url: string | null) => {
    setValue("imageUrl", url, { shouldDirty: true });
  };

  const handleUploadedBlurhash = (url: string | null) => {
    setValue("blurhash", url, { shouldDirty: true });
  };

  const isDirty = !!Object.keys(formState.dirtyFields).length;

  const onSetTranslatedValue = (value: { data: string[] }) => {
    const data = value.data.map(item => item);

    let title;
    let description;

    const clonedTitle = data[0]
      .split(",")[0]
      ?.replace("[", "")
      ?.replace(/['"]+/g, "")
      .trim();
    const clonedDescription = data[0]
      .split(",")[1]
      ?.replace("]", "")
      ?.replace(/['"]+/g, "")
      .trim();

    [
      translations?.[primaryLocale?.key]?.title,
      translations?.[primaryLocale?.key]?.description,
    ].forEach((item, index) => {
      if (data[0].includes("None")) {
        if (index === 0 && !clonedTitle.includes("None")) {
          title = clonedTitle;
        } else if (index === 1 && !clonedDescription.includes("None")) {
          description = clonedDescription;
        }
      } else {
        if (index === 0 && item !== undefined) {
          title = clonedTitle;
        } else if (index === 1 && item !== undefined) {
          if (clonedDescription === undefined) {
            description = data[0];
            return;
          }
          description = clonedDescription;
        }
      }
    });

    setValue("translations.en.title", title);
    setValue("translations.en.description", description);
  };

  return (
    <FormEdit
      onRemove={onRemove}
      hasValidationErrors={Object.keys(errors).length !== 0}
      enableSave={isDirty}
      title={
        post
          ? `${post?.translations?.[primaryLocale.key]?.title} - ${
              post?.typeName
            }`
          : "New post"
      }
      id={post?.id}
      editType={post?.id ? FormEditType.EDIT : FormEditType.ADD}
      loading={loading}
      recordIdentifier={
        post?.translations
          ? post?.translations?.[primaryLocale.key]?.title
          : post?.id
      }
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      deepLink={{
        type: post?.type ?? type,
        id: post?.id,
        countryCode: currentCountry?.abb,
      }}
      localeSupported
      errors={errors as any}
    >
      {locale => (
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Scraper
              setValue={setValue}
              initialUrl={post?.imageUrl}
              parenterrors={errors}
              parentcontrol={control}
              parentReset={reset}
              getValues={getValues}
              watch={watch}
              setScrapped={setScrapped}
              locale={locale}
            />
            <ItemWrapper size={1}>
              <Form.Item
                validateStatus={errors.imageUrl && "error"}
                help={
                  <Typography.Paragraph type="danger">
                    {errors.imageUrl?.message}
                  </Typography.Paragraph>
                }
              >
                <Typography.Text>Image URL</Typography.Text>
                <DropAndCrop
                  previewImageWidth={"20"}
                  title="Banner image"
                  initialUrl={imageUrl}
                  setUploadUrl={handleUploadedImageUrl}
                  setBlurhash={handleUploadedBlurhash}
                  uploadImage={uploadBlogPostImage}
                  lockedRatio={AspectRatio.OneToOne}
                  defaultCropBoxWidth={300}
                />
              </Form.Item>
            </ItemWrapper>
            <BJInputFormItem
              label={`Title (${locale?.label ?? ""})`}
              fieldName={`translations.${locale.key}.title`}
              key={`translations.${locale.key}.title`}
              control={control}
              error={!!errors?.translations?.[locale.key]?.title}
              message={errors?.translations?.[locale.key]?.title?.message}
              required
            />

            <BJInputFormItem
              label={`Description (${locale?.label ?? ""})`}
              fieldName={`translations.${locale.key}.description`}
              key={`translations.${locale.key}.description`}
              control={control}
              rows={4}
              error={!!errors?.translations?.[locale.key]?.description}
              message={errors?.translations?.[locale.key]?.description?.message}
              required
            />

            <BJSwitchFormItem
              label="Promote"
              fieldName={"promoted"}
              control={control}
            />
          </Col>
          <Col span={12}>
            <AiTranslator
              locale={locale}
              data={[
                translations?.sv?.title?.length > 0
                  ? translations?.sv?.title?.trim()
                  : undefined,
                translations?.sv?.description?.length > 0
                  ? translations?.sv?.description?.trim()
                  : undefined,
              ]}
              onSetValue={onSetTranslatedValue}
            />
            <BJSelectFormItem
              label={"Type"}
              fieldName={"type"}
              size="large"
              control={control}
              error={!!errors?.type}
              message={errors?.type?.message}
              optionsList={types?.map(type => ({ ...type, key: type.value }))}
              required
              disabled={!!post?.id}
            />

            {type === BlogTypes.BlogPost ? (
              <BJSelectFormItem
                label={"Week"}
                fieldName={"week"}
                size="large"
                control={control}
                error={!!errors?.week}
                message={errors?.week?.message}
                optionsList={weeks?.map(({ name, number }) => ({
                  value: number,
                  key: number,
                  display: name,
                }))}
                required
                placeholder="Choose a week"
                includeEmpty
              />
            ) : (
              type === BlogTypes.ChildBlogPost && (
                <BJSelectFormItem
                  label={"Month"}
                  fieldName={"month"}
                  size="large"
                  control={control}
                  error={!!errors?.month}
                  message={errors?.month?.message}
                  optionsList={months?.map(({ name, number }) => ({
                    value: number,
                    key: number,
                    display: name,
                  }))}
                  required
                  placeholder="Choose a month"
                  includeEmpty
                />
              )
            )}

            <BJSelectFormItem
              label={"Featured"}
              fieldName={"featured"}
              size="large"
              control={control}
              error={!!errors?.featured}
              message={errors?.featured?.message}
              optionsList={Object.keys(Featured)
                .filter(key => isNaN(Number(key)))
                .map((item, index) => ({
                  value: index,
                  key: index,
                  display: item,
                  disabledOption: !!featureValidator.find(
                    post => post?.featured === index
                  ),
                }))}
              placeholder="Choose a post feature position"
              includeEmpty
              required
            />
            {type === BlogTypes.GeneralBlogPost && (
              <Radio.Group
                onChange={e => {
                  setCategoryToggle(!e.target.value);
                }}
                value={!categoryToggle ? 1 : 0}
                optionType="button"
                buttonStyle="solid"
                options={[
                  { label: "Influencer", value: 1 },
                  { label: "Category", value: 0 },
                ]}
              />
            )}
            {!categoryToggle && (
              <BJSelectFormItem
                label={"Influencer"}
                fieldName={"influencerId"}
                size="large"
                control={control}
                error={!!errors?.influencerId}
                message={errors?.influencerId?.message}
                optionsList={influencers
                  .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                  )
                  .map(influencer => ({
                    key: influencer.id,
                    value: influencer.id,
                    display: influencer.name,
                  }))}
                placeholder="Choose an influencer"
                includeEmpty
              />
            )}
            {categoryToggle && blogCategories && (
              <>
                <Form.Item>
                  <CategoriesGrid>
                    {blogCategories.map(category => (
                      <CheckboxButton
                        key={category.id}
                        label={category.translations[locale.key]}
                        checked={categoryIds?.some(x => x === category.id)}
                        onChange={() =>
                          setValue(
                            "categoryIds",
                            onToggleCategory(
                              category,
                              getValues("categoryIds") ?? []
                            )
                          )
                        }
                      />
                    ))}
                  </CategoriesGrid>
                </Form.Item>
                <BJInputFormItem
                  label={`Author`}
                  fieldName={`authorName`}
                  key={`authorName`}
                  control={control}
                  error={!!errors?.authorName}
                  message={errors?.authorName?.message}
                  required={false}
                />
              </>
            )}
            <BJTagsFormItem
              label={`Tag Words (${locale?.label ?? ""})`}
              key={`translations.${locale.key}.tagWords`}
              fieldName={`translations.${locale.key}.tagWords`}
              control={control}
              setValue={setValue}
              aiTagWordsPayload={{
                contentTitle: translations?.[locale.key]?.title,
                contentIntro: translations?.[locale.key]?.description,
                contentType: ContentTypeNames.blogposts,
                documentId: post?.id,
                language: locale.label,
              }}
              aiTranslateTagWordsPayload={{
                tagWords: translations?.[primaryLocale.key]?.tagWords,
                from: primaryLocale.label,
              }}
              existingTags={translations?.[locale.key]?.tagWords || []}
              locale={locale.key}
            />
            <BJTags
              contentTags={tags}
              onChange={setTags}
              contentType={contentByQueryParam(type)}
            />

            <Controller
              control={control}
              name="isVlog"
              render={({ field: { onChange, value } }) => (
                <Checkbox onChange={onChange} checked={value}>
                  Vlog
                </Checkbox>
              )}
            />
          </Col>
        </Row>
      )}
    </FormEdit>
  );
};

const ItemWrapper = styled.div<ItemWrapperProps>`
  padding-top: ${props => (props.size ? props.size + "rem" : "2rem")};
`;
interface ItemWrapperProps {
  size?: number;
}

export const CategoriesGrid = styled.div`
  display: grid;
  grid-row-gap: 0.75rem;
  grid-column-gap: 1rem;
  margin-top: 0.625rem;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
`;
