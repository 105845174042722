import { Card, Row } from "antd";
import { useEffect, useState } from "react";

import { yesOrNo } from "../../../utils";
import {
  CONFIG_KEYS,
  getRemoteConfig,
} from "../../../services/RemoteConfigService";

import { RowItem } from "./RowItem";

type NotificationProps = {
  topics: Record<string, boolean>;
  enabled: boolean;
};

type Translation = "da" | "en" | "no" | "sv";

type CIONotificationConfig = {
  categoryId: "pushNotifications" | "emailNotifications";
  topics: Array<{
    isSubscribed: boolean;
    topicId: string;
    translations: Record<Translation, { name: string }>;
  }>;
};

type NotificationStateConfig = {
  topicId: string;
  isSubscribe: boolean;
  title: string;
};

export type NotificationSettingsProps = {
  fcm: string;
  notificationSettings: {
    emailNotifications: NotificationProps;
    pushNotifications: NotificationProps;
  };
};

export const NotificationInformation = ({
  fcm,
  notificationSettings,
}: NotificationSettingsProps) => {
  const [pushNotifConfig, setPushNotifConfig] = useState<
    Array<NotificationStateConfig>
  >([]);
  const [emailNotifConfig, setEmailNotifConfig] = useState<
    Array<NotificationStateConfig>
  >([]);

  useEffect(() => {
    const initNotificationRemoteConfig = async () => {
      const cioConfig = (await getRemoteConfig(
        CONFIG_KEYS.CIO_NOTIFICATION_TOPICS
      )) as Array<CIONotificationConfig>;
      const pushNotif = cioConfig.find(
        cio => cio.categoryId === "pushNotifications"
      );
      const emailNotif = cioConfig.find(
        cio => cio.categoryId === "emailNotifications"
      );

      if (notificationSettings) {
        const { emailNotifications, pushNotifications } = notificationSettings;

        const fomatConfig = (
          settingsTopics: NotificationProps["topics"],
          configTopics: CIONotificationConfig["topics"]
        ) => {
          return Object.keys(settingsTopics).map(topic => {
            const config = configTopics?.find(
              ({ topicId }) => topicId === topic
            );

            return {
              topicId: config?.topicId,
              isSubscribe: settingsTopics[topic],
              title: config?.translations.en.name || topic,
            };
          });
        };

        setPushNotifConfig(
          fomatConfig(pushNotifications.topics, pushNotif?.topics)
        );
        setEmailNotifConfig(
          fomatConfig(emailNotifications.topics, emailNotif?.topics)
        );
      }
    };

    initNotificationRemoteConfig();
  }, [notificationSettings]);

  return (
    <Card title="Notifications Information" bordered={false}>
      <Card title="Push notification" style={{ marginBottom: 20 }}>
        <Row>
          {pushNotifConfig.length ? (
            pushNotifConfig.map(({ topicId, title, isSubscribe }) => (
              <RowItem
                key={topicId}
                title={`${title}: `}
                content={yesOrNo(isSubscribe)}
              />
            ))
          ) : (
            <RowItem title="Enabled: " content={yesOrNo(false)} />
          )}
        </Row>
      </Card>
      <Card title="Email updates" style={{ marginBottom: 20 }}>
        <Row>
          {emailNotifConfig.length ? (
            emailNotifConfig.map(({ topicId, title, isSubscribe }) => (
              <RowItem
                key={topicId}
                title={`${title}: `}
                content={yesOrNo(isSubscribe)}
              />
            ))
          ) : (
            <RowItem title="Enabled: " content={yesOrNo(false)} />
          )}
        </Row>
      </Card>
      <RowItem title="FCM: " content={fcm} />
    </Card>
  );
};
