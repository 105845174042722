import { Typography } from "antd";
import { motion } from "framer-motion";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";

const variants = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
  hidden: {
    scale: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export const EditContentContainer = ({
  onClose,
  editSectionTitle,
  render,
}: {
  onClose: () => void;
  editSectionTitle: string;
  render: JSX.Element;
}) => {
  return (
    <ContentContainer
      variants={variants}
      key="content-container"
      initial="hidden"
      exit={{ opacity: 0 }}
      animate="visible"
    >
      <StyledTitle>
        <Typography.Title level={4}>{editSectionTitle}</Typography.Title>
      </StyledTitle>
      <Close onClick={onClose}>
        <AiOutlineClose size={15} />
      </Close>
      <StyledEditForm>{render}</StyledEditForm>
    </ContentContainer>
  );
};

const ContentContainer = styled(motion.div)`
  margin-left: 1.25rem;
  width: 50%;
  border-radius: 0.625rem;
  border: 0.063rem solid;
  height: 50%;
  border-color: ${props => props.theme.stroke};
  position: relative;
`;

const StyledEditForm = styled.div`
  padding-left: 1rem;
  padding: 1rem;
`;

const Close = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  cursor: pointer;
`;

const StyledTitle = styled.div`
  border-bottom: 0.063rem solid;
  border-color: ${props => props.theme.stroke};
  padding-left: 1rem;
  padding-top: 1rem;
`;
