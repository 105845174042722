import { firestore } from "../firebase";
import { CollectionPaths } from "../helper/collections";
import { firebaseCollectionNames } from "../utils";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";
const { content } = firebaseCollectionNames.categoryItems;

export class FaqCategoryService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  subscribeToCategories = (
    callback: (err: Error | null, snapshot: FaqCategory[]) => void
  ) => {
    return firestore
      .collection(CollectionPaths.FaqCategories[this.countryCode])
      .doc(content)
      .onSnapshot(
        doc => {
          callback(null, doc.data()?.content as FaqCategory[]);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );
  };

  update = (data: FaqCategory[]) => {
    return firestore
      .collection(CollectionPaths.FaqCategories[this.countryCode])
      .doc(content)
      .set(
        { content: data },
        {
          merge: true,
        }
      );
  };
}
