import { useState, useEffect } from "react";
import { useCountry, useInfluencers } from "../../context";
import { InfluencerModal } from "./InfluencerModal";
import BJList from "../../components/theme/components/BJList";
import { Sorter } from "../../components/theme/util/sorter";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { BJNotification, NotificationType } from "../../components/theme";

export const InfluencerListPage = () => {
  const { influencers, loading } = useInfluencers();
  const [selectedInfluencer, setSelectedInfluencer] =
    useState<Influencer | null>(null);
  const [showAddInfluencer, setShowAddInfluencer] = useState(false);
  const { currentCountry } = useCountry();

  const onClickRow = (record: Influencer) => {
    return {
      onClick: () => {
        setSelectedInfluencer(record);
      },
    };
  };

  useEffect(() => {
    if (selectedInfluencer) {
      setShowAddInfluencer(true);
    }
  }, [selectedInfluencer]);

  const handleNewInfluencer = () => {
    if (currentCountry.abb === "NO") {
      BJNotification({
        type: NotificationType.Warning,
        message: "This Featured Not enable yet",
        description:
          "For this region, adding influencers is not supported yet.",
      });
    } else {
      setShowAddInfluencer(true);
    }
  };

  const columns: BJColumnType<Influencer>[] = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 2,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 1,
      ellipsis: true,
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  return (
    <>
      <BJList
        loading={loading}
        filterOnlyDisplayList
        addButtonCaption={"New Influencer"}
        title={"Influencer"}
        OriginalList={influencers.map(x => ({ ...x, key: x.id }))}
        columns={columns}
        onClickRow={onClickRow}
        onclick={handleNewInfluencer}
        recordCountSuffix={"Articles"}
      />
      <InfluencerModal
        show={showAddInfluencer || !!selectedInfluencer}
        influencer={selectedInfluencer}
        onHide={() => {
          setShowAddInfluencer(false);
          setSelectedInfluencer(null);
        }}
      />
    </>
  );
};
