import { useNavigate } from "react-router-dom";
import BJList from "../../components/theme/components/BJList";
import { BJColumnType } from "../../components/theme/molecules/BJTable";
import { Sorter } from "../../components/theme/util/sorter";
import { useRecommendedContentByMonths } from "../../context/RecommendedContext";
import { newRoute } from "../../routes";

export const RecommendedByMonthsListPage = () => {
  const navigate = useNavigate();
  const { recommendedContents, loading } = useRecommendedContentByMonths();

  const onClickRow = (record: Popular) => {
    return {
      onClick: () => {
        navigate(record.id);
      },
    };
  };

  const handleNavigate = () => {
    navigate(newRoute);
  };

  const columns: BJColumnType<Popular>[] = [
    {
      title: "Months",
      dataIndex: "month",
      key: "month",
      width: 2,
      ellipsis: true,
      onFilter: (value, record) =>
        record.month!.toString().includes(value as string),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
  ];

  return (
    <BJList
      filterOnlyDisplayList
      loading={loading}
      addButtonCaption={"New recommeded"}
      title={"Recommended"}
      OriginalList={recommendedContents.map(x => ({ ...x, key: x.id }))}
      columns={columns}
      onClickRow={onClickRow}
      onclick={handleNavigate}
      recordCountSuffix={"Recommended"}
    />
  );
};
