import { useEffect, useState } from "react";
import SimpleMDE from "simplemde";
import MDEditor, {
  commands,
  ICommand,
  TextAreaTextApi,
  TextState,
} from "@uiw/react-md-editor";
import styled from "styled-components";

type MarkdownEditorProps = {
  name: string;
  initialValue: string;
  onChange: (value: string) => void;
  narrow?: boolean;
  disabled?: boolean;
};

type MarkdownEditor2Props = {
  name: string;
  initialValue: string;
  onChange: (value: string) => void;
  customCommands?: ICommand[];
  narrow?: boolean;
  disabled?: boolean;
};

export const MarkdownEditor = ({
  name,
  initialValue,
  onChange,
  narrow = false,
}: MarkdownEditorProps) => {
  const [editor, setEditor] = useState<SimpleMDE | null>(null);

  useEffect(() => {
    if (editor !== null && editor.value() !== initialValue) {
      editor.value(initialValue);
    }
  }, [editor, initialValue]);

  useEffect(() => {
    if (editor !== null) {
      return;
    }
    const _editor = new SimpleMDE({
      element: document.getElementById(name) as HTMLElement,
      initialValue,
      spellChecker: false,
      toolbar: [
        "bold",
        "italic",
        "heading-1",
        "heading-2",
        "heading-3",
        "|",
        "quote",
        "unordered-list",
        "ordered-list",
        "|",
        "link",
        "image",
        "|",
        "preview",
        "side-by-side",
        "fullscreen",
        "|",
        "guide",
      ],
    });
    setEditor(_editor);
    onChange(initialValue);
  }, [editor, name, initialValue, onChange]);

  useEffect(() => {
    if (editor === null) {
      return;
    }

    editor.codemirror.on("change", () => {
      onChange(editor.value());
    });
  }, [editor, onChange]);

  return (
    <div className={narrow ? "narrow-markdown-editor" : undefined}>
      <textarea id={name} />
    </div>
  );
};

const lineBreakCommand: ICommand = {
  name: "line-break",
  keyCommand: "line-break",
  buttonProps: { "aria-label": "Insert line break" },
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={16}
      viewBox="40 -960 960 960"
      width={16}
      fill="currentColor"
    >
      <path d="M360-160v-240q-83 0-141.5-58.5T160-600q0-83 58.5-141.5T360-800h360v80h-80v560h-80v-560H440v560h-80Z" />
    </svg>
  ),
  execute: (state: TextState, api: TextAreaTextApi) => {
    const modifiedText = state.selectedText
      ? `\\\n${state.selectedText}`
      : "\\\n";
    api.replaceSelection(modifiedText);
  },
};

export const MarkdownEditor2 = ({
  name,
  initialValue,
  onChange,
  customCommands = [],
  ...rest
}: MarkdownEditor2Props) => {
  const defaultCommands = [
    commands.bold,
    commands.italic,
    commands.strikethrough,
    commands.divider,
    commands.title1,
    commands.title2,
    commands.title3,
    commands.divider,
    commands.quote,
    commands.unorderedListCommand,
    commands.orderedListCommand,
    commands.code,
    commands.divider,
    commands.link,
    commands.image,
    commands.divider,
    lineBreakCommand,
  ];

  return (
    <BJMarkdown
      {...rest}
      name={name}
      initialValue={initialValue}
      preview={"live"}
      extraCommands={[
        commands.codePreview,
        commands.codeEdit,
        commands.codeLive,
        commands.fullscreen,
      ]}
      commands={[...defaultCommands, ...customCommands]}
      value={initialValue}
      onChange={e => {
        onChange(e);
      }}
    />
  );
};

const BJMarkdown = styled(MDEditor)<MarkdownEditor2Props>`
  font-family: "Circular Std Book, Circular Std, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji";
  background-color: ${props =>
    props.disabled ? props.theme.disabled : props.theme.white};
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  opacity: ${props => (props.disabled ? 0.6 : 1)};
`;
