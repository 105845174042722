import { uploadImage } from ".";
import { CountryCodeService } from "./CountryCodeService";
import { CollectionPaths } from "../helper/collections";
import firebase from "../firebase";
import { logException } from "../utils/exceptionLogger";
export class TipsService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  static tipsStoragePath = "tips";

  subscribeToPregnancyTips = (
    callback: (err: Error | null, snapshot: Tips[]) => void
  ) => {
    return firebase
      .firestore()
      .collection(CollectionPaths.PregnancyTips[this.countryCode])
      .onSnapshot(
        snapshot => {
          callback(
            null,
            (snapshot.docs.at(0)?.data?.()?.content ?? []) as Tips[]
          );
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );
  };

  subscribeToChildTips = (
    callback: (err: Error | null, snapshot: Tips[]) => void
  ) => {
    return firebase
      .firestore()
      .collection(CollectionPaths.ChildTips[this.countryCode])
      .onSnapshot(
        snapshot => {
          callback(
            null,
            (snapshot.docs.at(0)?.data?.()?.content ?? []) as Tips[]
          );
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );
  };

  updatePregnancyTips = (data: Tips[]) => {
    const collection = firebase
      .firestore()
      .collection(CollectionPaths.PregnancyTips[this.countryCode]);

    collection.get().then(snapshot => {
      if (snapshot.docs.length > 0) {
        collection.doc(snapshot.docs[0].id).update({ content: data });
      } else {
        collection.add({ content: data });
      }
    });
  };

  updateChildTips = (data: Tips[]) => {
    const collection = firebase
      .firestore()
      .collection(CollectionPaths.ChildTips[this.countryCode]);

    collection.get().then(snapshot => {
      if (snapshot.docs.length > 0) {
        collection.doc(snapshot.docs[0].id).update({ content: data });
      } else {
        collection.add({ content: data });
      }
    });
  };

  uploadTipsImage = async (file: Blob | ArrayBuffer, fileName: string) => {
    const url = uploadImage(file, TipsService.tipsStoragePath, fileName);
    return url;
  };
}
