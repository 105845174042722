import React, { createContext, useEffect, useMemo, useState } from "react";

import { InfluencerService } from "../services";
import { useCountry } from "./CountryContext";

type ContextState = {
  influencers: Influencer[];
  influencerMap: Map<Influencer["id"], Influencer>;
  loading: boolean;
  error: Error | null;
};

const InfluencersContext = createContext<ContextState>({
  influencers: [],
  influencerMap: new Map(),
  loading: false,
  error: null,
});

export const InfluencersProvider = ({ ...rest }) => {
  const [influencers, setInfluencers] = useState<Influencer[]>([]);
  const [influencerMap, setInfluencerMap] = useState<
    Map<Influencer["id"], Influencer>
  >(new Map());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const { currentCountry } = useCountry();

  const influencerService = useMemo(() => {
    return currentCountry?.abb
      ? new InfluencerService(currentCountry?.abb)
      : null;
  }, [currentCountry]);

  useEffect(() => {
    setLoading(true);

    const unsub = influencerService?.subscribe((_error, _influencers) => {
      setInfluencers(_influencers);
      setInfluencerMap(new Map(_influencers.map(i => [i.id, i])));
      setError(_error);
      setLoading(false);
    });

    return unsub;
  }, [influencerService]);

  const value = useMemo(
    () => ({ influencers, influencerMap, loading, error }),
    [influencers, influencerMap, loading, error]
  );

  return <InfluencersContext.Provider value={value} {...rest} />;
};

export const useInfluencers = () => {
  const context = React.useContext(InfluencersContext);
  if (context === undefined) {
    throw new Error(
      "useInfluencers must be used within an InfluencersProvider"
    );
  }
  return context;
};
