import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import {
  PodcastService,
  PodcastEpisodeService,
  PodcastSeasonsService,
} from "../services";
import { getFormattedErrorMessage } from "../utils";
import { useCountry } from "./CountryContext";

type DocumentReference<T> = firebase.default.firestore.DocumentReference<T>;
type DocumentData = firebase.default.firestore.DocumentData;

type ContextState = {
  podcasts: FullPodcast[];
  loading: boolean;
  error: Error | null;
  getById: (id: FullPodcast["id"]) => FullPodcast | null;
  getEpisodesById: (id: FullPodcast["id"]) => PodcastEpisode[] | null;
  getEpisodeById: (id: PodcastEpisode["id"]) => PodcastEpisode | null;
  createPodcast: (data: Podcast) => Promise<DocumentReference<DocumentData>>;
  updatePodcastImageUrl: (
    podcastId: string,
    episodes: PodcastEpisode[],
    imageUrl: string,
    blurhash: string
  ) => Promise<void>;
};

const PodcastsContext = createContext<ContextState>({
  podcasts: [],
  loading: false,
  error: null,
  getById: () => null,
  getEpisodesById: () => null,
  getEpisodeById: () => null,
  createPodcast: async () => null,
  updatePodcastImageUrl: async () => null,
});

export const PodcastsProvider = ({ ...rest }) => {
  const [podcastData, setPodcastData] = useState<Podcast[]>([]);
  const [podcastEpisodes, setPodcastEpisodes] = useState<PodcastEpisode[]>([]);
  const [podcasts, setPodcasts] = useState<FullPodcast[]>([]);
  const [loadingPodcastData, setLoadingPodcastData] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const podcastService = useMemo(
    () => new PodcastService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  const podcastSeasonsService = useMemo(
    () => new PodcastSeasonsService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  const podcastEpisodeService = useMemo(
    () => new PodcastEpisodeService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    setLoadingPodcastData(true);
    const unSubscribePodcast = podcastService.subscribe((_error, _podcasts) => {
      setPodcastData(_podcasts);
      setError(_error);
      setLoadingPodcastData(false);
    });

    return () => {
      unSubscribePodcast();
    };
  }, [podcastService]);

  useEffect(() => {
    if (loadingPodcastData) {
      return;
    }

    setPodcasts(podcastData as FullPodcast[]);

    const getFullPodcast = async () => {
      const _podcasts = await Promise.all(
        podcastData.map(async (podcast): Promise<FullPodcast> => {
          const episodes = await podcastEpisodeService.getEpisodes(podcast.id);
          const seasons = await podcastSeasonsService.getSeasons(podcast.id);
          setPodcastEpisodes(old => [...old, ...episodes]);
          return {
            ...podcast,
            episodes: episodes,
            seasons: seasons,
          };
        })
      );
      setPodcasts(_podcasts);
    };
    getFullPodcast();
  }, [loadingPodcastData, podcastData]);

  const getById = useCallback(
    (id: FullPodcast["id"]) => {
      const _podcast = podcasts.find(a => a.id === id);
      return _podcast || null;
    },
    [podcasts]
  );

  const getEpisodesById = useCallback(
    (id: FullPodcast["id"]) => {
      const _podcast = getById(id);
      return _podcast === null ? null : _podcast.episodes || null;
    },
    [getById]
  );

  const getEpisodeById = useCallback(
    (id: PodcastEpisode["id"]) => {
      const _episode = podcastEpisodes.find(a => a.id === id);
      return _episode || null;
    },
    [podcastEpisodes]
  );

  const createPodcast = useCallback(
    async (data: Podcast) => await podcastService.create(data),
    [podcastService]
  );

  const updatePodcastImageUrl = useCallback<
    ContextState["updatePodcastImageUrl"]
  >(
    async (podcastId, episodes, imageUrl, blurhash) =>
      podcastEpisodeService.updatePodcastImageUrl(
        podcastId,
        episodes,
        imageUrl,
        blurhash
      ),
    []
  );

  const loading = loadingPodcastData;

  const value = useMemo(
    () => ({
      podcasts,
      loading,
      error,
      getById,
      getEpisodesById,
      getEpisodeById,
      createPodcast,
      updatePodcastImageUrl,
    }),
    [
      podcasts,
      loading,
      error,
      getById,
      getEpisodesById,
      getEpisodeById,
      createPodcast,
      updatePodcastImageUrl,
    ]
  );

  return <PodcastsContext.Provider value={value} {...rest} />;
};

export const usePodcasts = () => {
  const context = useContext(PodcastsContext);
  if (context === undefined) {
    throw new Error("usePodcasts must be used within an PodcastsProvider");
  }
  return context;
};

export const usePodcast = (podcastId: Podcast["id"]) => {
  const [podcast, setPodcast] = useState<FullPodcast | null>();
  const [episodes, setEpisodes] = useState<PodcastEpisode[]>();
  const [seasons, setSeasons] = useState<PodcastSeason[]>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null | string>(null);

  const { currentCountry } = useCountry();

  const podcastService = useMemo(
    () => new PodcastService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  const podcastSeasonsService = useMemo(
    () => new PodcastSeasonsService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  const podcastEpisodeService = useMemo(
    () => new PodcastEpisodeService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  const { loading: loadingPodcasts, getById } = usePodcasts();

  useEffect(() => {
    if (loadingPodcasts) {
      return;
    }
    const _podcast = getById(podcastId);
    setPodcast(_podcast);

    const unSubscribeEpisodes = podcastEpisodeService.subscribe(
      podcastId,
      (_error, _episodes) => {
        setEpisodes(_episodes);
        setError(_error);
      }
    );

    const unSubscribeSeason = podcastSeasonsService.subscribe(
      podcastId,
      (_error, _seasons) => {
        setSeasons(_seasons);
        setError(_error);
      }
    );
    setLoading(false);

    return () => {
      unSubscribeEpisodes();
      unSubscribeSeason();
    };
  }, [loadingPodcasts, getById, podcastId, podcastSeasonsService]);

  const update = async (data: Podcast) => {
    if (!podcast) {
      return;
    }
    try {
      await podcastService.update(podcastId, data);
    } catch (error) {
      const message = getFormattedErrorMessage(error);
      setError(message);
      console.log(error);
    }
  };

  const deletePodcast = async () => {
    if (!podcast) {
      return;
    }
    try {
      await podcastService.delete(podcastId);
    } catch (error) {
      const message = getFormattedErrorMessage(error);
      setError(message);
      console.log(error);
    }
  };

  const addEpisode = async (data: PodcastEpisode) => {
    if (!podcast) {
      return;
    }
    try {
      await podcastEpisodeService.create(podcastId, data);
    } catch (error) {
      const message = getFormattedErrorMessage(error);
      setError(message);
      console.log(error);
    }
  };

  const updateEpisode = async (
    episodeId: PodcastEpisode["id"],
    data: PodcastEpisode
  ) => {
    if (!podcast) {
      return;
    }
    try {
      await podcastEpisodeService.update(podcastId, episodeId, data);
    } catch (error) {
      const message = getFormattedErrorMessage(error);
      setError(message);
      console.log(error);
    }
  };

  const deleteEpisode = async (episodeId: PodcastEpisode["id"]) => {
    if (!podcast) {
      return;
    }
    try {
      await podcastEpisodeService.delete(podcastId, episodeId);
    } catch (error) {
      const message = getFormattedErrorMessage(error);
      setError(message);
      console.log(error);
    }
  };

  const addSeason = async (data: Omit<PodcastSeason, "id">) => {
    if (!podcast) {
      return;
    }
    try {
      await podcastSeasonsService.create(podcastId, data);
    } catch (error) {
      const message = getFormattedErrorMessage(error);
      setError(message);
      console.log(error);
    }
  };

  const updateSeason = async (
    seasonId: PodcastSeason["id"],
    data: Partial<PodcastSeason>
  ) => {
    if (!podcast) {
      return;
    }
    try {
      await podcastSeasonsService.update(podcastId, seasonId, data);
    } catch (error) {
      const message = getFormattedErrorMessage(error);
      setError(message);
      console.log(message);
    }
  };

  const deleteSeason = async (seasonId: PodcastEpisode["id"]) => {
    if (!podcast) {
      return;
    }
    try {
      await podcastSeasonsService.delete(podcastId, seasonId);
    } catch (error) {
      const message = getFormattedErrorMessage(error);
      setError(message);
      console.log(error);
    }
  };

  return {
    podcast,
    episodes,
    seasons,
    loading,
    error,
    update,
    deletePodcast,
    addEpisode,
    updateEpisode,
    deleteEpisode,
    addSeason,
    updateSeason,
    deleteSeason,
  };
};
