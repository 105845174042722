import { Divider, Modal, Row } from "antd";
import { FaInstagram, FaLink, FaCopy } from "react-icons/fa";
import { IconWrapper } from "./styled/BJCommonStyles";
import styled from "styled-components";
import BJCopy from "./theme/atoms/BJCopy";
import { OneLinkTypes } from "../utils";
import { useState } from "react";
import BJInput from "./theme/atoms/BJInput";
import BJButton, { ButtonTypes } from "./theme/atoms/Button";
import { BJNotification, NotificationType } from "./theme";

interface ModalProps {
  deepLink: string;
  dynamicLink: string;
  oneLinks?: { [key in OneLinkTypes]?: string };
  show: boolean;
  onHide: (e: React.MouseEvent<HTMLElement>) => void;
  isArticle?: boolean;
}

export const DeeplinkCopyModal = ({
  show,
  deepLink,
  dynamicLink,
  oneLinks,
  onHide,
  isArticle,
}: ModalProps) => {
  const [fallbackModal, setFallbackModal] = useState(false);
  const [fallbackURL, setFallbackURL] = useState("");
  const [emailLink, setEmailLink] = useState("");
  const onCloseModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setFallbackModal(false);
  };

  const renderModal = () => {
    const confirm = () => {
      const encodedFallbackURL = encodeURIComponent(fallbackURL);
      const link = `${emailLink}&af_web_dp=${encodedFallbackURL}`;
      navigator.clipboard.writeText(link);
      BJNotification({
        type: NotificationType.Success,
        message: "Copied to clipboard",
        description: `${link} copied`,
      });
      setFallbackModal(false);
    };
    return (
      <Modal
        title={"Add fallback url for desktop"}
        visible={fallbackModal}
        onCancel={onCloseModal}
        destroyOnClose={true}
        footer={
          <Row justify="end">
            <BJButton
              buttonType={ButtonTypes.Secondary}
              onClick={() => setFallbackModal(false)}
            >
              Cancel
            </BJButton>
            <BJButton buttonType={ButtonTypes.Save} onClick={confirm}>
              Confirm!
            </BJButton>
          </Row>
        }
        width={600}
        mask={true}
      >
        <BJInput
          placeholder="Enter web site url"
          value={fallbackURL}
          onChange={e => setFallbackURL(e.target.value)}
        />
      </Modal>
    );
  };
  return (
    <div onClick={e => e.stopPropagation()}>
      <Modal
        title={"Click to copy"}
        visible={show}
        onCancel={onHide}
        destroyOnClose={true}
        footer={null}
        width={600}
        mask={true}
      >
        <ButtonsWrapper>
          <ButtonWrapper>
            <IconWrapper disabled={false}>
              <BJCopy textToCopy={deepLink} onClick={onHide}>
                <FaLink size={35} className="mr-2" />
              </BJCopy>
            </IconWrapper>
            <span>Deep Link</span>
          </ButtonWrapper>
          <ButtonWrapper>
            <IconWrapper disabled={false}>
              <BJCopy textToCopy={dynamicLink} onClick={onHide}>
                <FaInstagram size={35} className="mr-3" />
              </BJCopy>
            </IconWrapper>
            <span>Instagram Link</span>
          </ButtonWrapper>
        </ButtonsWrapper>
        <Divider />
        <ButtonsWrapper>
          {oneLinks &&
            Object.entries(oneLinks).map(([key, value]) =>
              value ? (
                <ButtonWrapper key={key}>
                  <IconWrapper disabled={false}>
                    {key === "Email-to-app" && isArticle ? (
                      <IconWrapper
                        hoverEffect
                        onClick={() => {
                          setFallbackModal(true);
                          setEmailLink(value);
                        }}
                      >
                        <FaCopy size={35} />
                      </IconWrapper>
                    ) : (
                      <BJCopy textToCopy={value} onClick={onHide}>
                        <FaCopy size={35} />
                      </BJCopy>
                    )}
                  </IconWrapper>
                  <span>{key}</span>
                </ButtonWrapper>
              ) : null
            )}
        </ButtonsWrapper>
        {renderModal()}
      </Modal>
    </div>
  );
};

const ButtonsWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 0.625rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  span {
    margin-top: 0.625rem;
  }
`;
