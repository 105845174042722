import { useState } from "react";
import { FaGripLines } from "react-icons/fa";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";
import { List, Space } from "antd";
import styled from "styled-components";
import { useCountry } from "../../context";

interface Props {
  initialTasks: ChecklistTask[];
  onCancel: () => void;
  onSave: (tasks: ChecklistTask[]) => void;
}

export const ChangeTaskOrderList = ({
  initialTasks,
  onCancel,
  onSave: onSaveTasks,
}: Props) => {
  const { primaryLocale } = useCountry();
  const [isDirty, setIsDirty] = useState(false);
  const [tasks, setTasks] = useState<ChecklistTask[]>(
    initialTasks
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((t, i) => ({ ...t, sortOrder: i }))
  );

  const onSave = async () => {
    onSaveTasks(tasks);
    setIsDirty(false);
  };

  const SortableItem = SortableElement(
    ({ value }: { value: ChecklistTask }) => (
      <StyledListItem>
        <Space size={30}>
          <FaGripLines />
          <span>{value.translations?.[primaryLocale?.key]?.text}</span>
        </Space>
      </StyledListItem>
    )
  );

  const SortableList = SortableContainer(
    ({ items }: { items: ChecklistTask[] }) => (
      <List bordered>
        {items.map((item, index) => (
          <SortableItem key={item.id} index={index} value={item} />
        ))}
      </List>
    )
  );

  return (
    <>
      <div className="d-flex justify-content-between align-items-end mb-2">
        <div className="d-flex align-items-center">
          <p className="mb-0">Change tasks order</p>
        </div>
        <Space>
          <BJButton
            buttonType={ButtonTypes.Delete}
            size="small"
            onClick={onCancel}
          >
            Cancel
          </BJButton>

          <BJButton
            disabled={!isDirty}
            size="small"
            buttonType={ButtonTypes.Save}
            onClick={onSave}
          >
            Save
          </BJButton>
        </Space>
      </div>

      <SortableList
        items={tasks.sort((a: any, b: any) => a.sortOrder - b.sortOrder)}
        onSortEnd={({ oldIndex, newIndex }) => {
          if (oldIndex === newIndex) {
            return;
          }
          const _tasks = [...tasks];
          const sortOrder = _tasks[oldIndex].sortOrder;
          _tasks[oldIndex].sortOrder = _tasks[newIndex].sortOrder;
          _tasks[newIndex].sortOrder = sortOrder;
          setTasks(_tasks);
          setIsDirty(true);
        }}
      />
    </>
  );
};

const StyledListItem = styled(List.Item)`
  cursor: move;
`;
