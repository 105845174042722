import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCountry, usePodcasts } from "../../../context";
import { SubmitHandler, useForm } from "react-hook-form";
import { episodeModalMessages } from "../../../language";
import {
  formValidationError,
  getFormattedErrorMessage,
  getMax,
  CommonContentType,
} from "../../../utils";
import { FormModal } from "../../../components";
import { ImagePresenter } from "../../../components/ImagePresenter";
import { BJSelectFormItem } from "../../../components/theme/molecules/formItems/BJFormSelectItem";
import styled from "styled-components";

interface Props {
  show: boolean;
  selectedContent: CommonContentV2[];
  onHide: () => void;
  onAdd: (updatedContent: CommonContentV2[]) => void;
}

type FormValues = {
  imageUrl: string;
  podcastEpisodeId: string;
  podCastId: string;
};

export const AddPodcastEpisodeModal = ({
  show,
  selectedContent,
  onHide,
  onAdd,
}: Props) => {
  const { primaryLocale } = useCountry();

  const schema = yup.object().shape({
    podcastEpisodeId: yup
      .string()
      .required(episodeModalMessages.podcastEpisodeRequired),
  });

  const [podcast, setPodcast] = useState<FullPodcast | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { podcasts } = usePodcasts();

  const {
    handleSubmit,
    control,
    reset,
    formState,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      imageUrl: "",
      podCastId: undefined,
      podcastEpisodeId: undefined,
    },
  });

  const validateSelected = (podcastEpisodeId: string) => {
    if (selectedContent.find(({ id }) => id === podcastEpisodeId)) {
      setError(episodeModalMessages.podcastExistsError);
    } else {
      setError(null);
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const { podcastEpisodeId } = data;
    try {
      validateSelected(podcastEpisodeId);
      const podcastEpisode = podcast?.episodes?.find(
        ({ id }) => id === podcastEpisodeId
      );
      let highestSortOrder = getMax(selectedContent, "sortOrder");

      const translationKeysArray: (keyof CommonContentV2["translations"][string])[] =
        [
          "duration",
          "title",
          "description",
          "answer",
          "blurhash",
          "question",
          "name",
          "body",
          "intro",
          "contentText",
        ];

      const transformTranslations: CommonContentV2["translations"] = {};
      Object.entries(podcastEpisode.translations).forEach(([key, value]) => {
        const { name, ...rest } = value;
        const transformData: CommonContentV2["translations"][string] = {
          ...rest,
          title: name,
        };

        const filteredTransformData = Object.fromEntries(
          translationKeysArray
            .filter(key => key in transformData)
            .map(key => [key, transformData[key]])
        );

        transformTranslations[key] = filteredTransformData;
      });

      const data: CommonContentV2 & { duration?: PodcastEpisode["duration"] } =
        {
          id: podcastEpisodeId,
          blurhash: podcast.blurhash,
          imageUrl: podcast?.imageUrl,
          sortOrder: ++highestSortOrder,
          type: CommonContentType.PODCAST,
          podcastId: podcast?.id,
          duration: podcastEpisode?.duration,
          translations: transformTranslations,
        } as CommonContentV2;

      onAdd([...selectedContent, data]);
      resetOnExit();
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
    }
  };

  const resetOnExit = () => {
    setPodcast(null);
    setError(null);
    reset({ podCastId: undefined });
  };

  const handleChange = (selectedValue: string) => {
    setError(null);
    const selectedPodCast = podcasts.find(x => x.id === selectedValue);
    if (selectedPodCast) {
      setPodcast(selectedPodCast);
    }
    reset({ podcastEpisodeId: undefined });
  };

  const handleEpisodeChange = (selectedValue: string) => {
    validateSelected(selectedValue);
  };

  const isValid = !!podcast && !!Object.keys(formState.dirtyFields).length;

  return (
    <FormModal
      formId="add-podcast-episode-modal"
      onHide={() => {
        resetOnExit();
        onHide();
      }}
      enableSave={isValid}
      show={show}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      error={error}
      enableDelete={false}
      modalSubTitle={""}
      modalTitle={"Add new podcast episode"}
      messageOnSubmit={false}
    >
      <BJSelectFormItem
        control={control}
        error={!!errors.podCastId}
        label={"Podcast"}
        message={errors.podCastId?.message}
        required={true}
        handleChange={handleChange}
        optionsList={podcasts?.map(podCast => ({
          key: podCast.id,
          value: podCast.id,
          display: podCast?.translations[primaryLocale?.key]?.title,
        }))}
        fieldName={"podCastId"}
      />
      <BJSelectFormItem
        control={control}
        error={!!errors.podcastEpisodeId}
        label={"Episode"}
        message={errors.podcastEpisodeId?.message}
        required={true}
        handleChange={handleEpisodeChange}
        optionsList={podcast?.episodes?.map(episode => ({
          key: episode.id,
          value: episode.id,
          display: episode?.translations[primaryLocale?.key]?.name,
        }))}
        fieldName={"podcastEpisodeId"}
      />
      <StyledImage>
        {podcast?.imageUrl && (
          <ImagePresenter
            width={"10rem"}
            hideBorder
            imageUrl={podcast?.imageUrl}
          />
        )}
      </StyledImage>
    </FormModal>
  );
};

const StyledImage = styled.div`
  display: flex;
  justify-content: center;
`;
