import { useEffect, useMemo } from "react";
import { Form, Typography } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { VerifierService } from "../../../services";
import { commonErrors } from "../../../language";
import { FormModal } from "../../../components/FormModal";
import BJInput from "../../../components/theme/atoms/BJInput";
import { DropAndCrop } from "../../../components/DropAndCrop";
import { AspectRatio, formValidationError } from "../../../utils";
import { useCountry } from "../../../context";

interface Props {
  verifier: Verifier | null;
  show: boolean;
  onHide: () => void;
}
type FormValues = {
  name: string;
  translations: {
    [locale: string]: {
      title: string;
    };
  };
  portrait: string | null;
  portraitBlurhash?: string;
};

const schema = yup.object().shape({
  name: yup.string().required(`Name: ${commonErrors.requiredError2}`),
  portrait: yup
    .string()
    .required(`Image URL: ${commonErrors.requiredError2}`)
    .url(`Image URL: ${commonErrors.urlValidationError2}`),
});

export const VerifierModal = ({ verifier, show, onHide }: Props) => {
  const {
    formState,
    reset,
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const { currentCountry } = useCountry();

  const verifierService = useMemo(
    () => new VerifierService(currentCountry?.abb),
    [currentCountry?.abb]
  );

  useEffect(() => {
    reset({
      name: verifier?.name ?? "",
      portrait: verifier?.portrait ?? "",
      translations: verifier?.translations,
    });
  }, [verifier, reset, show]);

  const handleUploadedImageUrl = (url: string | null) => {
    setValue("portrait", url, { shouldDirty: true });
  };

  const handleUploadedBlurhash = (url: string | null) => {
    setValue("portraitBlurhash", url, { shouldDirty: true });
  };

  const onDelete = async () => {
    if (verifier !== null) {
      await verifierService.deleteVerifier(verifier.id);
    } else {
      throw new Error("Verifier not found");
    }
  };

  const onSubmit = async (data: FormValues) => {
    const translations: FormValues["translations"] = {};
    for (const [key, value] of Object.entries(data?.translations)) {
      translations[key] = {
        title: value?.title?.trim(),
      };
    }
    if (verifier) {
      await verifierService.update({
        id: verifier.id,
        name: data.name,
        translations,
        portrait: data.portrait,
        portraitBlurhash: data.portraitBlurhash,
      });
    } else {
      await verifierService.create({
        name: data.name,
        translations,
        portrait: data.portrait,
        portraitBlurhash: data.portraitBlurhash,
      });
    }
  };

  const isDirty = !!Object.keys(formState.dirtyFields).length;
  const portrait = watch("portrait");
  const name = watch("name");

  return (
    <FormModal
      onHide={onHide}
      enableSave={isDirty}
      show={show}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      onDelete={onDelete}
      enableDelete={!!verifier}
      modalSubTitle={verifier ? `ID - ${verifier?.id}` : ""}
      modalTitle={verifier ? verifier.name : "Add new verifier"}
      recordIdentifier={name}
      localeSupported
      errors={errors as any}
    >
      {locale => (
        <>
          <Form.Item
            label="Name"
            required
            validateStatus={errors.name && "error"}
            extra={
              <Typography.Paragraph type="danger">
                {errors.name?.message}
              </Typography.Paragraph>
            }
          >
            <Controller
              control={control}
              name="name"
              render={({ field: { value, onChange } }) => (
                <BJInput value={value} onChange={onChange} />
              )}
            />
          </Form.Item>
          <Form.Item label={`Title (${locale?.label})`}>
            <Controller
              control={control}
              name={`translations.${locale?.key}.title`}
              key={`translations.${locale?.key}.title`}
              render={({ field: { onChange, value } }) => (
                <BJInput onChange={onChange} value={value} />
              )}
            />
          </Form.Item>
          <Form.Item
            required
            label="Image URL"
            validateStatus={errors.portrait && "error"}
            extra={
              <Typography.Paragraph type="danger">
                {errors.portrait?.message}
              </Typography.Paragraph>
            }
          >
            <DropAndCrop
              title="Banner image"
              initialUrl={portrait}
              setUploadUrl={handleUploadedImageUrl}
              setBlurhash={handleUploadedBlurhash}
              uploadImage={verifierService.uploadVerifierImage}
              lockedRatio={AspectRatio.OneToOne}
              defaultCropBoxWidth={300}
            />
          </Form.Item>
        </>
      )}
    </FormModal>
  );
};
