import { Controller, useForm, useWatch } from "react-hook-form";
import { FormEdit, FormEditType } from "../../../components";
import { BJMdFormItem, BJSelectFormItemLevel } from "../../../components/theme";
import { useCountry, useLoyalty } from "../../../context";
import { useEffect, useState } from "react";
import _ from "lodash";
import { Col, Row } from "antd";
import BJList from "../../../components/theme/components/BJList";
import { LevelFeatureModal } from "./LevelFeatureModal";
import { ChangeFeaturesOrderList } from "./ChangeFeaturesOrderList";

type FormState = {
  level: number;
  levelInformations: LevelInformation[];
};

export const LevelInformationForm = () => {
  const { levelInformations, loading, upsertLevelsData } = useLoyalty();
  const { primaryLocale } = useCountry();
  const [ordering, setOrdering] = useState(false);
  const [showFeatureModal, setShowFeatureModal] = useState(false);
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState<number>();
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    resetField,
  } = useForm<FormState>({
    mode: "onBlur",
    defaultValues: {
      level: 0,
    },
  });

  const level = useWatch({ control, name: "level" });

  const onSubmit = ({ levelInformations }: FormState) => {
    upsertLevelsData({
      levelInformations,
    });
  };

  useEffect(() => {
    if (!loading) {
      resetField("levelInformations", {
        defaultValue: _.cloneDeep(levelInformations),
      });
    }
  }, [loading, levelInformations]);

  const columnSchema = [
    {
      title: "Title",
      dataIndex: ["translations", primaryLocale.key],
      key: "title",
    },
  ];

  return (
    <FormEdit
      loading={loading}
      enableSave={isDirty}
      editType={FormEditType.EDIT}
      title={`${level ? `Level ${level} info` : "Select a level"}`}
      localeSupported
      onSubmit={handleSubmit(onSubmit)}
      hideBackButton
    >
      {locale => (
        <>
          <BJSelectFormItemLevel
            control={control}
            error={!!errors.level}
            message={errors.level?.message}
            fieldName="level"
            noLevelDisabled
          />
          {!!level && (
            <Row gutter={20}>
              <Col span={12}>
                <BJMdFormItem
                  control={control}
                  error={
                    !!errors.levelInformations?.[level - 1]?.translations?.[
                      locale?.key
                    ]?.lockedContent
                  }
                  message={
                    errors.levelInformations?.[level - 1]?.translations?.[
                      locale?.key
                    ]?.lockedContent.message
                  }
                  fieldName={`levelInformations.${level - 1}.translations.${
                    locale?.key
                  }.lockedContent`}
                  key={`levelInformations.${level - 1}.translations.${
                    locale?.key
                  }.lockedContent`}
                  label={`Locked content (${locale?.label})`}
                />
                <BJMdFormItem
                  control={control}
                  error={
                    !!errors.levelInformations?.[level - 1]?.translations?.[
                      locale?.key
                    ]?.makingProgressContent
                  }
                  message={
                    errors.levelInformations?.[level - 1]?.translations?.[
                      locale?.key
                    ]?.makingProgressContent.message
                  }
                  fieldName={`levelInformations.${level - 1}.translations.${
                    locale?.key
                  }.makingProgressContent`}
                  key={`levelInformations.${level - 1}.translations.${
                    locale?.key
                  }.makingProgressContent`}
                  label={`Making progress content (${locale?.label})`}
                />
                <BJMdFormItem
                  control={control}
                  error={
                    !!errors.levelInformations?.[level - 1]?.translations?.[
                      locale?.key
                    ]?.reachedContent
                  }
                  message={
                    errors.levelInformations?.[level - 1]?.translations?.[
                      locale?.key
                    ]?.reachedContent.message
                  }
                  fieldName={`levelInformations.${level - 1}.translations.${
                    locale?.key
                  }.reachedContent`}
                  key={`levelInformations.${level - 1}.translations.${
                    locale?.key
                  }.reachedContent`}
                  label={`Reached content (${locale?.label})`}
                />
              </Col>
              <Col span={12}>
                <Controller
                  control={control}
                  name={`levelInformations.${level - 1}.features`}
                  key={`levelInformations.${level - 1}.features`}
                  render={({ field: { value, onChange } }) =>
                    !ordering ? (
                      <>
                        <BJList
                          hideRecordCount
                          title={"Features"}
                          hideSearch
                          filterOnlyDisplayList
                          OriginalList={value}
                          columns={columnSchema}
                          onClickRow={item => ({
                            onClick: () => {
                              setSelectedFeatureIndex(
                                value.findIndex(v => v.id === item.id)
                              ),
                                setShowFeatureModal(true);
                            },
                          })}
                          addButtonCaption={"New feature"}
                          onclick={() => {
                            setShowFeatureModal(true);
                          }}
                          onChangeOrder={() => setOrdering(true)}
                        />
                        <LevelFeatureModal
                          show={showFeatureModal}
                          onHide={() => {
                            setShowFeatureModal(false);
                            setSelectedFeatureIndex(undefined);
                          }}
                          onDelete={() => {
                            const newValue = [...value];
                            newValue.splice(selectedFeatureIndex, 1);
                            onChange(newValue);
                          }}
                          onSubmit={feature => {
                            const newValue = [...(value ?? [])];
                            if (selectedFeatureIndex === undefined) {
                              newValue.push(feature);
                              onChange(newValue);
                            } else {
                              newValue[selectedFeatureIndex] = feature;
                              onChange(newValue);
                            }
                          }}
                          levelShortcutFeature={
                            value && value[selectedFeatureIndex]
                          }
                        />
                      </>
                    ) : (
                      <ChangeFeaturesOrderList
                        initialFeatures={value}
                        onCancel={() => setOrdering(false)}
                        onSave={onChange}
                      />
                    )
                  }
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </FormEdit>
  );
};
