import styled from "styled-components";
import QRCode from "react-qr-code";
import { List } from "antd";

export const InputWrapper = styled.div`
  margin-left: 0.5rem;
`;

export const QRGenerator = styled(QRCode)`
  margin-top: 1rem;
`;

export const PodcastEpisodeList = styled(List)`
  height: 400px;
  overflow: scroll;
  margin-top: 2rem;
  width: 500px;
`;
