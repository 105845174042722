import { uploadImage } from "../../services/serviceUtils";

const blogPostFileStoragePath = "blogPost";

export const uploadBlogPostImage = async (
  file: Blob | ArrayBuffer,
  fileName: string
) => {
  const url = uploadImage(file, blogPostFileStoragePath, fileName);
  return url;
};
