import { Card, Row, Typography } from "antd";

import { Sorter } from "../../../components/theme";

import { type DisplayUser } from "./UserInformation";
import { type OtherProps } from "./OtherInformation";
import { type NotificationSettingsProps } from "./NotificationInformation";
import BJTable, {
  BJColumnType,
} from "../../../components/theme/molecules/BJTable";
import { RowItem } from "./RowItem";

type Partner = (DisplayUser & OtherProps & NotificationSettingsProps) | null;

export type SharedAccountProps = {
  partners: Array<Partner>;
  partnerDetails: Partner;
};

const columns: BJColumnType<DisplayUser>[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 1,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "User Id",
    dataIndex: "userId",
    key: "userId",
    width: 2,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 2,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Following Type",
    dataIndex: "followingType",
    key: "followingType",
    width: 2,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Locale",
    dataIndex: "locale",
    key: "locale",
    width: 1,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Timezone",
    dataIndex: "timeZone",
    key: "timeZone",
    width: 1,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Partner Name",
    dataIndex: "partnerName",
    key: "partnerName",
    width: 2,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
];

export const SharedAccountInformation = ({
  partners,
  partnerDetails,
}: SharedAccountProps) => {
  return (
    <Card title="Partners Information" bordered={false}>
      <div style={{ marginBottom: 20 }}>
        <RowItem
          title="Account type:"
          content={partnerDetails ? "Follower" : "Main"}
        />
      </div>
      {partnerDetails && (
        <Card title="Partner account" style={{ marginBottom: 20 }}>
          <Row>
            <RowItem
              title="Account type:"
              content={partnerDetails.partnerId ? "Follower" : "Main"}
            />
            <RowItem
              title="Followers count:"
              content={`${partnerDetails.followersCount || 0}`}
            />
          </Row>
          <Row>
            <RowItem title="Name:" content={partnerDetails.name} />
            <RowItem title="Email:" content={partnerDetails.email} />
            <RowItem title="User Id:" content={partnerDetails.id} />
          </Row>
          <Row>
            <RowItem title="City:" content={partnerDetails.city} />
            <RowItem title="Country:" content={partnerDetails.country} />
            <RowItem
              title="Version & Build number:"
              content={`${partnerDetails.versionNumber || ""}.${
                partnerDetails.buildNumber || ""
              }`}
            />
          </Row>
        </Card>
      )}
      {Boolean(partners?.length) && (
        <>
          <div style={{ marginBottom: 12 }}>
            <Typography.Text strong>Followers</Typography.Text>
          </div>
          <BJTable
            hideRecordCount
            pagination={false}
            columns={columns}
            dataSource={partners}
          />
        </>
      )}
    </Card>
  );
};
