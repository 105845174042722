import { useState, useEffect, useCallback } from "react";
import { FaGripLines } from "react-icons/fa";
import { Button, List, Spin, Space, Row } from "antd";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import styled from "styled-components";
import { useCountry } from "../../context";

interface Props {
  initialAnswers: Answer[];
  withPercentage: boolean;
  onAdd: () => void;
  onSelect: (answer: Answer) => void;
  onUpdate: (answers: Answer[]) => void;
}

export const AnswersList = ({
  initialAnswers,
  onAdd: onAddAnswer,
  withPercentage,
  onSelect,
  onUpdate,
}: Props) => {
  const [isDirty, setIsDirty] = useState(false);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [isOrderMode, setIsOrderMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const { primaryLocale } = useCountry();
  const setSortedPolls = useCallback(() => {
    const sortedPolls = initialAnswers
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((t, i) => ({ ...t, sortOrder: i }));
    setAnswers(sortedPolls);
  }, [initialAnswers]);

  useEffect(() => {
    setSortedPolls();
  }, [setSortedPolls]);

  const onSave = useCallback(async () => {
    setSaving(true);
    onUpdate(answers);
    setIsDirty(false);
    setIsOrderMode(false);
    setSaving(false);
  }, [answers, onUpdate]);

  return (
    <>
      {isOrderMode ? (
        <>
          <h4>Change answers order</h4>
          <Row>
            <Space size="middle">
              <Button
                size="middle"
                onClick={() => {
                  setSortedPolls();
                  setIsOrderMode(false);
                }}
                disabled={saving}
              >
                Cancel
              </Button>
              {isDirty ? (
                <Button type="primary" size="middle" onClick={onSave}>
                  {saving ? <Spin size="small" /> : "Save"}
                </Button>
              ) : (
                <Button disabled size="middle">
                  No changes
                </Button>
              )}
            </Space>
          </Row>
          <br />

          <SortableList
            items={answers.sort((a, b) => a.sortOrder - b.sortOrder)}
            onSortEnd={({ oldIndex, newIndex }) => {
              if (oldIndex === newIndex) {
                return;
              }
              const _answers = [...answers];

              const sortOrder = _answers[oldIndex].sortOrder;
              _answers[oldIndex].sortOrder = _answers[newIndex].sortOrder;
              _answers[newIndex].sortOrder = sortOrder;

              setAnswers(_answers);
              setIsDirty(true);
            }}
          />
        </>
      ) : (
        <>
          <div>
            <h4>Answers</h4>
            <div>
              <Space size="middle">
                <Button size="middle" type="primary" onClick={onAddAnswer}>
                  Add
                </Button>
                {answers && (
                  <Button size="middle" onClick={() => setIsOrderMode(true)}>
                    Change order
                  </Button>
                )}
              </Space>
            </div>
            <br />
          </div>
          <ListBorder>
            <List>
              {answers.map(answer => (
                <StyledListItem
                  onClick={() => onSelect(answer)}
                  key={answer.id}
                >
                  <span>
                    {answer.translations?.[primaryLocale?.key]?.title}
                  </span>
                  {withPercentage && <span>{answer.percentageAnswered} %</span>}
                  <span />
                </StyledListItem>
              ))}
            </List>
          </ListBorder>
        </>
      )}
    </>
  );
};

const SortableItem = SortableElement(({ value }: { value: Answer }) => (
  <List.Item>
    <span>{value.translations.sv.title}</span>
    <span>{value.percentageAnswered}</span>
    <FaGripLines />
  </List.Item>
));

const SortableList = SortableContainer(({ items }: { items: Answer[] }) => (
  <ListBorder>
    <List>
      {items.map((item, index) => (
        <SortableItem key={item.id} index={index} value={item} />
      ))}
    </List>
  </ListBorder>
));
const ListBorder = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`;

const StyledListItem = styled(List.Item)`
  cursor: pointer;
`;
