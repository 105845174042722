import firebase, { firestore } from "../firebase";
import { CountryCodeService } from "./CountryCodeService";
import { CollectionPaths } from "../helper/collections";

export class BirthStoryService extends CountryCodeService {
  constructor(countryCode: string) {
    super(countryCode);
  }

  static toBirthStory = (doc: firebase.firestore.DocumentData): BirthStory => {
    const data = doc.data();

    return {
      id: doc.id,
      ...data,
      translations: data.translations ?? {},
    };
  };

  subscribe = (callback: (err: Error | null, offers: BirthStory[]) => void) =>
    firestore
      .collection(CollectionPaths.BirthStory[this.countryCode])
      .onSnapshot(
        snapshot => {
          const birthStories = snapshot.docs.map(
            BirthStoryService.toBirthStory
          );
          callback(null, birthStories);
        },
        err => {
          console.log(err);
          callback(err, []);
        }
      );

  create = (data: BlogPost) =>
    firestore.collection(CollectionPaths.BirthStory[this.countryCode]).add({
      ...data,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

  update = (data: BlogPost) =>
    firestore
      .collection(CollectionPaths.BirthStory[this.countryCode])
      .doc(data.id)
      .update({
        ...data,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

  write = async (data: BlogPost) => {
    if (data.id) {
      await this.update(data);
      return data.id;
    } else {
      const result = await this.create(data);
      return result.id;
    }
  };

  delete = (id: string) =>
    firestore
      .collection(CollectionPaths.BirthStory[this.countryCode])
      .doc(id)
      .delete();
}
