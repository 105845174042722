import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import firebase from "../firebase";
import { BirthStoryService } from "../services";
import { useCountry } from "./CountryContext";

type ContextState = {
  birthStories: BirthStory[];
  loading: boolean;
  error: Error | null;
  updateBirthStory: (data: BlogPost) => Promise<void>;
  createBirthStory: (
    data: BlogPost
  ) => Promise<firebase.firestore.DocumentReference | null>;
  deleteBirthStory: (id: string) => Promise<void>;
  writeBirthStory: (data: BlogPost) => Promise<string>;
};

const BirthStoryContext = createContext<ContextState>({
  birthStories: [],
  loading: false,
  error: null,
  createBirthStory: async () => null,
  updateBirthStory: async () => null,
  deleteBirthStory: async () => null,
  writeBirthStory: async () => null,
});

export const BirthStoriesProvider = ({ ...rest }) => {
  const [birthStories, setBirthStories] = useState<BirthStory[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { currentCountry } = useCountry();

  const birthStoryService = useMemo(
    () =>
      currentCountry?.abb ? new BirthStoryService(currentCountry.abb) : null,
    [currentCountry?.abb]
  );

  useEffect(() => {
    if (!birthStoryService) {
      return;
    }
    setLoading(true);

    const unsub = birthStoryService.subscribe((_error, _birthStories) => {
      setBirthStories(_birthStories);
      setError(_error);
      setLoading(false);
    });

    return unsub;
  }, [birthStoryService]);

  const updateBirthStory = useCallback(
    async (data: BlogPost) => await birthStoryService.update(data),
    [birthStoryService]
  );

  const createBirthStory = useCallback(
    async (data: BlogPost) => await birthStoryService.create(data),
    [birthStoryService]
  );

  const deleteBirthStory = useCallback(
    async (id: string) => await birthStoryService.delete(id),
    [birthStoryService]
  );

  const writeBirthStory = useCallback(
    async (data: BlogPost) => await birthStoryService.write(data),
    [birthStoryService]
  );

  const value = useMemo(
    () => ({
      birthStories,
      loading,
      error,
      createBirthStory,
      updateBirthStory,
      deleteBirthStory,
      writeBirthStory,
    }),
    [
      birthStories,
      loading,
      error,
      createBirthStory,
      updateBirthStory,
      deleteBirthStory,
      writeBirthStory,
    ]
  );

  return <BirthStoryContext.Provider value={value} {...rest} />;
};

export const useBirthStories = () => {
  const context = React.useContext(BirthStoryContext);
  if (context === undefined) {
    throw new Error(
      "useBirthStories must be used within an BirthStoriesProvider"
    );
  }
  return context;
};

export const useBirthStory = (birthStoryId: BirthStory["id"]) => {
  const { birthStories, loading } = useBirthStories();

  const [birthStory, setBirthStory] = useState<BirthStory | null>(null);

  useEffect(() => {
    const _birthStory = birthStories.find(s => s.id === birthStoryId);
    if (_birthStory !== undefined) {
      setBirthStory(_birthStory);
    }
  }, [birthStories, birthStoryId]);

  return { birthStory, loading };
};
