import { BJInputFormItem } from "../../components/theme/molecules/formItems/BJFormInput";
import { Col, Row } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { commonErrors } from "../../language";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { FormEdit, FormEditType } from "../../components";
import { formValidationError } from "../../utils";
import { auth } from "../../firebase";
import { useAuth } from "../../context";

const { requiredError } = commonErrors;

const schema = yup.object().shape({
  displayName: yup.string().required(requiredError),
});

type FormValues = {
  displayName: string;
  password: string;
};

export const EditUserProfile = () => {
  const { updateName, authUser } = useAuth();
  const {
    formState,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      displayName: authUser?.displayName,
    },
  });

  const onSubmit = async (data: FormValues) => {
    try {
      const { displayName, password } = data;
      await auth.currentUser.updateProfile({
        displayName,
      });
      await updateName(displayName);
      await auth.currentUser.updatePassword(password);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const isDirty = !!Object.keys(formState.dirtyFields).length;

  return (
    <FormEdit
      hasValidationErrors={Object.keys(errors).length !== 0}
      enableSave={isDirty}
      title={"Edit Profile"}
      editType={FormEditType.ADD}
      loading={false}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
    >
      <Row>
        <Col lg={12} span={24} style={{ margin: "auto" }}>
          <BJInputFormItem
            control={control}
            error={!!errors?.displayName}
            label={"Name"}
            message={errors.displayName?.message}
            required={true}
            autoFocus
            fieldName={"displayName"}
          />
          <BJInputFormItem
            control={control}
            error={!!errors.password}
            label={"Password"}
            message={errors.password?.message}
            required={true}
            autoFocus
            type={"password"}
            placeHoder={
              "Add new password to change, keep empty if do not want to update"
            }
            fieldName={"password"}
            extra={"Password must be at least 6 characters long"}
          />
        </Col>
      </Row>
    </FormEdit>
  );
};
