import { Control, Path } from "react-hook-form";
import { BJSelectFormItem } from "./BJFormSelectItem";
import { useCountry } from "../../../../context";

export const BJSelectLanguageFormItem = <T,>({
  error,
  message,
  control,
  fieldName,
  required,
  handleChange,
  selectedCountry,
}: {
  error: boolean;
  message: string;
  control: Control<T>;
  fieldName: Path<T>;
  required?: boolean;
  handleChange?: (value: string) => void;
  selectedCountry?: Country;
}) => {
  const { countries } = useCountry();
  const localesForSelect = selectedCountry
    ? selectedCountry.locales
    : countries.flatMap(country => country.locales);
  const selectOptions = localesForSelect.map(locale => ({
    key: locale.key,
    value: locale.key,
    display: locale.label,
  }));

  return (
    <BJSelectFormItem
      size="large"
      control={control}
      error={error}
      label="Language"
      message={message}
      optionsList={selectOptions}
      fieldName={fieldName}
      required={!!required}
      handleChange={handleChange}
      defaultValue={localesForSelect.find(locale => locale.primary)?.key}
    />
  );
};
