import { useState } from "react";
import { FaGripLines } from "react-icons/fa";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { List, Space } from "antd";
import styled from "styled-components";
import { useCountry } from "../../context";
import BJButton, { ButtonTypes } from "../../components/theme/atoms/Button";

interface Props {
  initialItems: Checklist[];
  onSave: (updatedArray: Checklist[]) => void;
}

export const ChecklistsSortableList = ({
  initialItems,
  onSave: onUpdateSortOrder,
}: Props) => {
  const { primaryLocale } = useCountry();
  const [isDirty, setIsDirty] = useState(false);
  const [items, setItems] = useState<Checklist[]>(
    initialItems
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((t, i) => ({ ...t, sortOrder: i }))
  );

  const onSave = async () => {
    await onUpdateSortOrder(items.map(i => ({ ...i, sortOrder: i.sortOrder })));
    setIsDirty(false);
  };

  const SortableItem = SortableElement(({ value }: { value: string }) => (
    <StyledListItem>
      <Space size={30}>
        <FaGripLines />
        <span>{value}</span>
      </Space>
    </StyledListItem>
  ));

  const SortableList = SortableContainer(
    ({ items }: { items: Checklist[] }) => (
      <List bordered>
        {items.map((item, index) => (
          <SortableItem
            key={item.id}
            index={index}
            value={item.translations?.[primaryLocale.key]?.name}
          />
        ))}
      </List>
    )
  );

  return (
    <>
      <Space>
        <BJButton
          disabled={!isDirty}
          size="small"
          buttonType={ButtonTypes.Save}
          onClick={onSave}
        >
          Save
        </BJButton>
      </Space>
      <Spacer size={20} />
      <SortableList
        items={items.sort((a, b) => a.sortOrder - b.sortOrder)}
        onSortEnd={({ oldIndex, newIndex }) => {
          if (oldIndex === newIndex) {
            return;
          }
          const _items = [...items];
          const sortOrder = _items[oldIndex].sortOrder;
          _items[oldIndex].sortOrder = _items[newIndex].sortOrder;
          _items[newIndex].sortOrder = sortOrder;
          setIsDirty(true);
          setItems(_items);
        }}
      />
    </>
  );
};

const StyledListItem = styled(List.Item)`
  cursor: move;
`;

const Spacer = styled.div<{ size: number }>`
  height: ${({ size = 1 }) => size}px;
`;
