import { Card, Space } from "antd";
import { Fragment, useCallback, useMemo, useState } from "react";

import { Sorter } from "../../../components/theme/util/sorter";
import { UserService } from "../../../services";
import { generateServerError, yesOrNo } from "../../../utils";
import { commonErrors, commonSuccessMessages } from "../../../language";

import BJTable, {
  BJColumnType,
} from "../../../components/theme/molecules/BJTable";
import BJButton, { ButtonTypes } from "../../../components/theme/atoms/Button";
import { BJNotification, NotificationType } from "../../../components/theme";

type CustomChecklistTask = {
  id: string;
  text: string;
  sortOrder: number;
  isCustom: boolean;
};

type UserTask = {
  id: string;
  taskId: string;
  checklistId: string;
  checked: boolean;
  disabled: boolean;
};

export type ChecklistsProps = {
  customChecklists: CustomChecklistTask[];
  userTasks: UserTask[];
};

const customChecklistsColumns: BJColumnType<CustomChecklistTask>[] = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 2,
    ellipsis: true,
  },
];

const userTasksColumns: BJColumnType<
  Omit<UserTask, "checked"> & { checked: string }
>[] = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Checklist Id",
    dataIndex: "checklistId",
    key: "checklistId",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
  {
    title: "Completed",
    dataIndex: "checked",
    key: "checked",
    width: 2,
    ellipsis: true,
    sorter: {
      compare: Sorter.DEFAULT,
    },
  },
];

export const ChecklistsInformation = ({
  userId,
  checklists,
}: {
  userId: string;
  checklists: ChecklistsProps;
}) => {
  const [loading, toggleLoading] = useState(false);

  const onClickResetChecklist = useCallback(async () => {
    try {
      toggleLoading(true);
      await UserService.resetChecklists(userId);
      BJNotification({
        type: NotificationType.Success,
        message: commonSuccessMessages.resetSucessMessage,
        description: "Record has been reset successfully.",
      });
    } catch (error) {
      const serverErrorMessage = generateServerError(error);

      BJNotification({
        type: NotificationType.Error,
        message: commonErrors.resetRecordError,
        description: serverErrorMessage,
      });
    } finally {
      toggleLoading(false);
    }
  }, [userId]);

  const userTasks = useMemo(() => {
    if (checklists) {
      return checklists.userTasks?.map(userTask => ({
        ...userTask,
        checked: yesOrNo(userTask.checked),
      }));
    }

    return [];
  }, [checklists]);

  if (checklists) {
    return (
      <Card title="Checklists Information" bordered={false}>
        <BJButton
          loading={loading}
          size="middle"
          disabled={
            checklists.customChecklists?.length < 1 &&
            checklists.userTasks?.length < 1
          }
          buttonType={ButtonTypes.Primary}
          onClick={onClickResetChecklist}
          style={{ marginBottom: 24 }}
        >
          Reset checklists
        </BJButton>
        <Space>
          <Card title="Custom checklists" bordered={false}>
            <BJTable
              hideRecordCount
              pagination={false}
              columns={customChecklistsColumns}
              dataSource={checklists.customChecklists}
            />
          </Card>
          <Card title="User tasks" bordered={false}>
            <BJTable
              hideRecordCount
              pagination={false}
              columns={userTasksColumns}
              dataSource={userTasks}
            />
          </Card>
        </Space>
      </Card>
    );
  }

  return <Fragment />;
};
