import firebase from "../firebase";
import { CollectionPaths } from "../helper/collections";
import {
  formatDateStringToTimeStamp,
  formatTimeStampToDateString,
} from "../utils";
import { logException } from "../utils/exceptionLogger";
import { CountryCodeService } from "./CountryCodeService";

export class PodcastService extends CountryCodeService {
  constructor(locale: string) {
    super(locale);
  }

  static defaultArtwork =
    "https://dummyimage.com/300x300/000/fff.jpg&text=Bild+saknas";

  static toPodcast = (doc: any): Podcast => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      translations: Object.entries(
        data.translations as Podcast["translations"]
      ).reduce((acc, [key, value]) => {
        return (acc[key] = { ...value, tagWords: value.tagWords || [] }), acc;
      }, {} as Podcast["translations"]),
      artwork: data.imageUrl || PodcastService.defaultArtwork,
      publishDate: formatTimeStampToDateString(data.publishDate),
    };
  };

  subscribe = (callback: (err: Error | null, podcasts: Podcast[]) => void) => {
    return firebase
      .firestore()
      .collection(CollectionPaths.Podcasts[this.countryCode])
      .onSnapshot(
        async snapshot => {
          const _podcastData = snapshot.docs.map(PodcastService.toPodcast);
          callback(null, _podcastData);
        },
        err => {
          logException(err);
          callback(err, []);
        }
      );
  };

  create = (data: Podcast) => {
    const publishDate = formatDateStringToTimeStamp(data.publishDate, true);
    return firebase
      .firestore()
      .collection(CollectionPaths.Podcasts[this.countryCode])
      .add({ ...data, publishDate });
  };

  update = (id: Podcast["id"], data: Podcast) => {
    const publishDate = formatDateStringToTimeStamp(data.publishDate, true);
    return firebase
      .firestore()
      .collection(CollectionPaths.Podcasts[this.countryCode])
      .doc(id)
      .update({ ...data, publishDate });
  };
  delete = (id: Podcast["id"]) =>
    firebase
      .firestore()
      .collection(CollectionPaths.Podcasts[this.countryCode])
      .doc(id)
      .delete();
}
