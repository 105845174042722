import { useEffect } from "react";
import { commonErrors } from "../../language";
import { useForm } from "react-hook-form";
import { FormModal } from "../../components";
import { formValidationError } from "../../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { BJInputFormItem } from "../../components/theme";
import { useCountry } from "../../context";

interface Props {
  show: boolean;
  question: CompetitionQuestion | null;
  onHide: () => void;
  onUpdate: (question: CompetitionQuestion) => void;
  onDelete: (id: CompetitionQuestion["id"]) => void;
  onAdd: (question: CompetitionQuestion) => void;
}

type FormValues = {
  translations?: {
    [locale: string]: {
      question: string;
    };
  };
};

export const UpdateQuestionModal = ({
  show,
  question,
  onHide,
  onUpdate,
  onDelete: onDeletequestion,
  onAdd,
}: Props) => {
  const { currentCountry, primaryLocale } = useCountry();

  const schema = yup.object().shape({
    translations: yup.object().shape(
      currentCountry?.locales.reduce((acc, item) => {
        acc[item.key] = yup.object().shape({
          question: yup
            .string()
            .required(
              `Question (${String(item.key).toUpperCase()}): ${
                commonErrors.requiredError
              }`
            ),
        });
        return acc;
      }, {} as any)
    ),
  });

  const {
    handleSubmit,
    control,
    formState,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (question !== null) {
      reset({
        ...question,

        // text: question?.translations?.en?.question,
        // productUrl: question.productUrl ?? null,
        // productButtonText: question.productButtonText,
        // itemType: question.itemType,
      });
    } else {
      reset();
      //reset({ text: "", productUrl: null, productButtonText: "" });
    }
  }, [reset, show, question]);

  const onSubmit = async (data: FormValues) => {
    const translations: CompetitionQuestion["translations"] = {};

    for (const [key, value] of Object.entries(data.translations)) {
      translations[key] = {
        question: value.question,
      };
    }
    const updated: CompetitionQuestion = {
      id: question?.id,
      type: question?.type,
      translations,
    };
    if (question !== null) {
      onUpdate(updated);
    } else {
      const question: CompetitionQuestion = { ...updated, id: uuidv4() };
      onAdd(question);
    }
  };

  const onDelete = async () => {
    if (question !== null) {
      onDeletequestion(question.id);
    }
  };

  const isDirty = !!Object.keys(formState.dirtyFields).length;
  const contentType = "test1"; // watch("itemType");
  return (
    <FormModal
      onHide={onHide}
      enableSave={isDirty}
      show={show}
      messageOnSubmit={false}
      onSubmit={handleSubmit(onSubmit, formValidationError)}
      error={null}
      onDelete={onDelete}
      enableDelete={!!question}
      modalSubTitle={question ? `Id - ${question?.id}` : ""}
      modalTitle={
        question
          ? question?.translations?.[primaryLocale.key]?.question
          : "Update question"
      }
      recordIdentifier={question?.id}
      localeSupported
      errors={errors as any}
    >
      {locale => (
        <BJInputFormItem
          label={`Question (${locale?.label ?? ""})`}
          fieldName={`translations.${locale.key}.question`}
          key={`translations.${locale.key}.question`}
          control={control}
          error={!!errors?.translations?.[locale.key]?.question}
          message={errors?.translations?.[locale.key]?.question?.message}
          rows={2}
          required
          autoFocus
        />
      )}
    </FormModal>
  );
};
