import { useEffect, useState } from "react";
import { useDietaryAdvices } from "../../../context";
import { getFormattedErrorMessage, CommonContentType } from "../../../utils";
import { Sorter } from "../../../components/theme/util/sorter";
import { BJColumnType } from "../../../components/theme/molecules/BJTable";
import { BJSelectListModal } from "../../../components/theme/components/BJSelectListModal";
import { useFilterContent } from "../../../hooks/contentCollections/useFilterContent";
import { useCountry } from "../../../context/CountryContext";
import { capitalizeFirstLetter } from "../../../utils";

interface Props {
  show: boolean;
  onHide: () => void;
  maxNoOfContentLimit: number;
  selectedContent: CommonContent[];
  onAdd: (content: CommonContent[]) => void;
}

interface dataSourceType extends FullDietaryAdvice {
  [titleWithLocale: string]:
    | string
    | FullDietaryAdvice[keyof FullDietaryAdvice];
}

export const AddDietaryAdvicesModal = ({
  show: showModal,
  onHide,
  maxNoOfContentLimit,
  selectedContent,
  onAdd,
}: Props) => {
  const { dietaryAdvices, loading } = useDietaryAdvices();
  const { currentCountry } = useCountry();
  const { categories } = useDietaryAdvices();
  const [dataSource, setDataSource] = useState<dataSourceType[]>([]);

  const {
    error,
    updatedContent,
    setError,
    selectedContentCollections,
    setSelectedContentCollections,
  } = useFilterContent<dataSourceType>(
    selectedContent,
    maxNoOfContentLimit,
    CommonContentType.DIETARYADVICE,
    dataSource
  );

  useEffect(() => {
    if (loading) return;
    const dietaryAdviceTableSource: dataSourceType[] = dietaryAdvices.map(
      dietaryAdvice => ({
        ...dietaryAdvice,
        ...Object.entries(dietaryAdvice.translations).reduce(
          (acc, [locale, values]) => (
            (acc[`name${capitalizeFirstLetter(locale)}`] = values.name), acc
          ),
          {} as any
        ),
        key: dietaryAdvice.id,
        ...Object.entries(dietaryAdvice?.category?.translations).reduce(
          (acc, [locale, values]) => (
            (acc[`category${capitalizeFirstLetter(locale)}`] = values.title),
            acc
          ),
          {} as any
        ),
      })
    );
    setDataSource(dietaryAdviceTableSource);
  }, [dietaryAdvices, loading]);

  const onSubmit = async () => {
    try {
      onAdd(updatedContent);
    } catch (err) {
      const message = getFormattedErrorMessage(err);
      setError(message);
    }
  };

  const columns: BJColumnType<dataSourceType>[] = [
    ...currentCountry?.locales
      ?.filter(locale => locale.primary)
      .map(locale => ({
        title: `Title (${locale?.label})`,
        dataIndex: `name${capitalizeFirstLetter(locale?.key)}`,
        key: `name${capitalizeFirstLetter(locale?.key)}`,
        width: 2,
        ellipsis: true,
        sorter: {
          compare: Sorter.DEFAULT,
        },
      })),
    ...currentCountry?.locales
      ?.filter(locale => locale.primary)
      .map(locale => ({
        title: `Category (${locale?.label})`,
        dataIndex: `category${capitalizeFirstLetter(locale?.key)}`,
        key: `category${capitalizeFirstLetter(locale?.key)}`,
        width: 1,
        ellipsis: true,
        onFilter: (value: string | number | boolean, record: dataSourceType) =>
          record.category.id.includes(value as string),
        filters: categories.map(category => ({
          text: category.translations?.[locale?.key]?.title,
          value: category.id,
        })),
        sorter: {
          compare: Sorter.DEFAULT,
        },
      })),
  ];

  return (
    <BJSelectListModal
      show={showModal}
      error={error!}
      onHide={onHide}
      modalWidth={600}
      modalTitle={"Add dietary Advices"}
      recordCountSuffix={"Dietary advice(s)"}
      disable={updatedContent.length > maxNoOfContentLimit}
      maxNoOfContentLimit={maxNoOfContentLimit}
      columns={columns}
      selectedRecords={selectedContentCollections}
      updateSelectedRecords={setSelectedContentCollections}
      dataSource={dataSource}
      onSubmit={onSubmit}
    />
  );
};
